import React, {useEffect, useState} from "react";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {HeadlessBrowserService} from "../../service/HeadlessBrowserService";
import {toDateTimeString} from "../../utils/TimeUtil";
import {InputSwitch} from "primereact/inputswitch";
import {InputText} from "primereact/inputtext";
import {getKeyValue} from "../../utils/Utils";
import {Dropdown} from "primereact/dropdown";

const HeadlessBrowser = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);

    const [showHeadlessBrowserDeleteModal, setHeadlessBrowserDeleteModal] = useState(false);
    const [showHeadlessBrowserAdditionModal, setHeadlessBrowserAdditionModal] = useState(false);
    const [showHeadlessBrowserUpdateModal, setHeadlessBrowserUpdateModal] = useState(false);
    const [rowData, setRowData] = useState(null);


    const [loading, setLoading] = useState(false);

    const headlessBrowserService = new HeadlessBrowserService();

    const typeOptions = [
        {value:'selenium', label:'Selenium'},
        {value:'puppeteer', label:'Puppeteer'},
        {value:'kamatera_selenium', label:'Kamatera Selenium'},
    ];

    useEffect ( ()=>{
        search();
    }, []);

    function getNewHeadlessBrowser() {
        return {url:null, type:null, heroku_api_key: null, heroku_email: null}
    }

    const onChange = (e) => {
        const { key, value } = getKeyValue(e);
        setRowData({...rowData, [key] : value});
    };

    const search = () => {
        setLoading(true);
        headlessBrowserService.getHeadlessBrowsers().then(data => {
            setItems(data);
            setLoading(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const addHeadlessBrowser = () => {
        headlessBrowserService.addHeadlessBrowser(rowData).then(added_expense=>{
            let filteredItems = items.filter(i => i.id !== added_expense.id);
            filteredItems.unshift(added_expense);
            setItems(filteredItems);
            setRowData(getNewHeadlessBrowser());
            setHeadlessBrowserAdditionModal(false);
            toast.current.show({severity: 'success', summary: t('actions.add'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setHeadlessBrowserAdditionModal(false);
            setRowData(getNewHeadlessBrowser());
        });

    }

    const updateExpense = () => {
        headlessBrowserService.updateHeadlessBrowser(rowData).then(()=>{
            let filteredItems = items.filter(i => i.id !== rowData.id);
            filteredItems.unshift(rowData);
            setItems(filteredItems);
            setRowData(getNewHeadlessBrowser());
            setHeadlessBrowserUpdateModal(false);
            toast.current.show({severity: 'success', summary: t('actions.update'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setHeadlessBrowserUpdateModal(false);
            setRowData(getNewHeadlessBrowser());
        });

    }

    const deleteHeadlessBrowser = () => {
        headlessBrowserService.deleteHeadlessBrowser(rowData.id).then(()=>{
            let filteredItems = items.filter(i => i.id !== rowData.id);
            setItems(filteredItems);
            setRowData(getNewHeadlessBrowser());
            setHeadlessBrowserDeleteModal(false);
            toast.current.show({severity: 'success', summary: t('actions.remove'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setHeadlessBrowserDeleteModal(false);
            setRowData(getNewHeadlessBrowser());
        });

    }

    const changeIsActive = (rowData) => {
        headlessBrowserService.activeOrDeactivate(rowData.id, rowData.is_active).then(() => {
            let item = items.find(item => item.id === rowData.id)
            item.is_active = !rowData.is_active;
            item.scraping_request_id = null;
            item.is_allocated = false;
            item.allocated_at = null;
            setItems([...items])
            toast.current.show({severity: 'success', summary: rowData.is_active ? t('product.activated') : t('product.deactivated'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const modalYesNo = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" className="p-button-danger" onClick={deleteHeadlessBrowser} />
            <Button label={t('yes_no.no')} icon="pi pi-times" className="p-button-warning" onClick={() => {setHeadlessBrowserDeleteModal(false); setRowData(getNewHeadlessBrowser());}} />
        </div>
    );

    const modalHeadlessBrowserAddCancelButtons = (
        <div>
            <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-warning" onClick={() => {setHeadlessBrowserAdditionModal(false);}} />
            <Button label={t('actions.save')} icon="pi pi-plus" className="p-button-success" onClick={addHeadlessBrowser} />
        </div>
    );

    const modalHeadlessBrowserUpdateCancelButtons = (
        <div>
            <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-warning" onClick={() => {setHeadlessBrowserUpdateModal(false);}} />
            <Button label={t('actions.update')} icon="pi pi-save" className="p-button-success" onClick={updateExpense} />
        </div>
    );

    const modalHeadlessBrowserAddition = (
        <div className="p-grid alignCenter p-fluid">
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="url" name="url" value={rowData?.url} onChange={onChange}/>
                    <label htmlFor="url">{t('headless_browser.url')}</label>
                </span>
            </div>

            <div className="p-col-12">
                <span className="p-float-label">
                    <Dropdown id="type" name="type" value={rowData?.type} options={typeOptions} onChange={onChange} />
                    {/*<InputText id="type" name="type" value={rowData?.type} onChange={onChange}/>*/}
                    <label htmlFor="type">{t('headless_browser.type')}</label>
                </span>
            </div>

            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="heroku_api_key" name="heroku_api_key" value={rowData?.heroku_api_key} onChange={onChange}/>
                    <label htmlFor="heroku_api_key">{t('headless_browser.heroku_api_key')}</label>
                </span>
            </div>

            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="heroku_email" name="heroku_email" value={rowData?.heroku_email} onChange={onChange}/>
                    <label htmlFor="heroku_email">{t('headless_browser.heroku_email')}</label>
                </span>
            </div>

        </div>
    );

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <div className="p-grid p-align-center">
                <InputSwitch checked={rowData.is_active} onChange={() => changeIsActive(rowData)} tooltipOptions={{position: 'bottom'}} tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } style={{marginRight:"0.5em"}} />
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-1" tooltip={t('actions.edit')} tooltipOptions={{position: 'bottom'}} onClick={() => {setRowData(rowData); setHeadlessBrowserUpdateModal(true);}} style={{marginRight:"0.5em"}}/>
                <Button icon="pi pi-trash" className="p-button-danger" tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => {setRowData(rowData); setHeadlessBrowserDeleteModal(true);}}/>
            </div>
        </React.Fragment>;
    }

    const dateTemplate = (rowData) => <span>{toDateTimeString(rowData.allocated_at)}</span>;

    return <React.Fragment>


        <Dialog header={t('dialogs.confirm_headless_browser_delete')} footer={modalYesNo} visible={showHeadlessBrowserDeleteModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setHeadlessBrowserDeleteModal(false); setRowData(getNewHeadlessBrowser());}}>
            {t('dialogs.confirm_headless_browser_delete_msg1')}
        </Dialog>

        <Dialog header={t('dialogs.headless_browser_addition')} footer={modalHeadlessBrowserAddCancelButtons} visible={showHeadlessBrowserAdditionModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setHeadlessBrowserAdditionModal(false);}}>
            {modalHeadlessBrowserAddition}
        </Dialog>

        <Dialog header={t('dialogs.expense_update')} footer={modalHeadlessBrowserUpdateCancelButtons} visible={showHeadlessBrowserUpdateModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setHeadlessBrowserUpdateModal(false); setRowData(getNewHeadlessBrowser());}}>
            {modalHeadlessBrowserAddition}
        </Dialog>

        <div className="p-grid">
            <div className="p-col-1" style={{textAlign:"left"}}>
                <Button label={t('actions.add')} icon="pi pi-plus" className="p-button-success" onClick={() => {setHeadlessBrowserAdditionModal(true);}} />
            </div>
            <div className="p-col-11" style={{textAlign:"right"}}>
            </div>
        </div>

         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <DataTable value={items}
                        emptyMessage={t('actions.empty_message')}
                        sortField="url" sortOrder={1}
                        paginator={true} rows={100}>
                 <Column body={IndexTemplate} headerStyle={{width:'3em'}} />
                 <Column field="url" header={t('headless_browser.url')} />
                 <Column field="type" header={t('headless_browser.type')} />
                 <Column field="heroku_email" header={t('headless_browser.heroku_email')} />
                 <Column field="scraping_request_id" header={t('scraping_request.id')} />
                 <Column field="allocated_at"  body={dateTemplate} header={t('headless_browser.allocated_at')} />
                 <Column field="duration" header={t('headless_browser.allocation_duration')} />
                 <Column header={t('table_headers.action')} body={actionTemplate} headerStyle={{textAlign:'center', width:'11em'}}/>
             </DataTable>}

    </React.Fragment>;
};
export default HeadlessBrowser;
