import {AuthService} from "./AuthService";

export class ProductSuggestionService {

    url = `product_suggestions/`;

    getStatistics(campaign_id)
    {
        return AuthService.apiGet(this.url + "statistics/", {params: {campaign_id: campaign_id}}).then(res => res.data.data);
    }
}
