import React, {useEffect, useState} from "react";

import {
    exportFile,
    formatCurrency,
    formatPercentage,
    getKeyValue, getProductDetailLink
} from "../../utils/Utils";
import {toDateString, toDateTimeString} from "../../utils/TimeUtil";
import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {DataView} from "primereact/dataview";
import {Calendar} from "primereact/calendar";

import {ExportService} from "../../service/ExportService";
import {Button} from "primereact/button";
import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {AutoComplete} from "primereact/autocomplete";
import ScrapeStatus from "../Product/ScrapeStatus";
import {ScraperService} from "../../service/ScraperService";
import {CampaignContext} from "../../App";

const DropShippingLoss = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [dropShippingLoss, setDropShippingLoss] = useState([]);
    const [scrapingRequest, setScrapingRequest] = useState([]);
    const [date, setDate] = useState(toDateString(new Date()));

    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);
    const [product_name, setProductName] = useState("");
    const [productSuggestions, setProductSuggestions] = useState(null);
    const dashboardService = new DashboardService();
    const exportService = new ExportService();
    const scraperService = new ScraperService();

    useEffect ( ()=>{
        search();
    },[selectedCampaign, date]);

    const suggestProducts = (event) => {
        if (dropShippingLoss != null) {
            let results = dropShippingLoss.filter((item) => {
                return item.name.toLowerCase().startsWith(event.query.toLowerCase());
            }).map(item => item.name);

            setProductSuggestions(results);
        }
    };

    const search = () => {
        if (selectedCampaign) {
            setLoading(true);

            dashboardService.getDropShippingLoss(selectedCampaign.id, date).then(dropShippingLoss=>{
                setDropShippingLoss(dropShippingLoss);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });

            scraperService.getLastScrapingRequest(selectedCampaign.id, 'drop_shipping_loss').then(scrapingRequests=>{
                setScrapingRequest(scrapingRequests[0])
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    }

    const onExport = () => {
        setLoadingExcelExport(true);
        exportService.exportDropShippingLoss(selectedCampaign.id, date).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.drop_shipping_loss') + "_" + toDateString(date) + ".xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    }

    const previousDate = () => {
        setDate(toDateString((new Date(date)).setDate((new Date(date)).getDate()-1)));
    }
    const nextDate = () => {
        setDate(toDateString((new Date(date)).setDate((new Date(date)).getDate()+1)));
    }
    const selectDate = (e) => {
        setDate(toDateString(e.value));
    }

    const ownPriceTemplate = (rowData) => {
        return <div className="p-grid p-align-center">
            <a target="_blank" rel="noopener noreferrer" href={rowData.own_url}>
            {formatCurrency(rowData.own_value, rowData.currency)}
            </a>
        </div>;
    };

    const dropShippingPriceTemplate = (rowData) => {
        return <div className="p-grid p-align-center">
            <a target="_blank" rel="noopener noreferrer" href={rowData.drop_shipping_url}>
            {formatCurrency(rowData.drop_shipping_value, rowData.currency)}
            </a>
            ( {formatCurrency(rowData.drop_shipping_original_value, rowData.drop_shipping_original_currency)} )
        </div>;
    };

    const dropShippingCostTemplate = (rowData) => {
        return <div className="p-grid p-align-center">
            {formatCurrency(rowData.drop_shipping_cost, rowData.currency)}
        </div>;
    };

    const differenceTemplate = (rowData) => {
        return <div className="p-grid p-align-center">
            {formatPercentage(rowData.difference)}
        </div>;
    };

    const dateTemplate = (rowData) => {
        return toDateTimeString(rowData.scraped_time);
    };

    const filtered_dropShippingLoss = dropShippingLoss.filter(item=> (product_name==="" || item.name.toLowerCase().indexOf(product_name.toLowerCase()) !== -1));

    const productTemplate = (rowData) => {

        return rowData ?
            <div className="p-grid p-fluid" style={{border:"1px solid #c8c8c8", margin:"0.5em"}}>
                <div className="p-col-4 p-grid">
                    <div className="p-col-6">
                        <a target="_blank" rel="noopener noreferrer" href={rowData.own_url}>
                            <img referrerPolicy='no-referrer' className="img-fluid" src={rowData.image} alt=""/>
                        </a>
                    </div>
                    <div className="p-col-6">
                        <div className="p-col-12">
                            {getProductDetailLink(rowData.product_app_url.split("id=")[1], rowData.name ? rowData.name : t('scraping_request.product_id'))}
                        </div>
                        <div className="p-col-12">
                            <span><b>{t('product.asin') + " : " }</b>{rowData.asin}</span>
                        </div>
                    </div>
                </div>
                <div className="p-col-8">
                    <DataTable value={[rowData]} responsive={true}>
                        <Column body={ownPriceTemplate} header={t('product_detail.price')}/>
                        <Column body={dropShippingPriceTemplate} header={t('product_detail.drop_shipping_price')}/>
                        <Column body={dropShippingCostTemplate} header={t('product_detail.drop_shipping_cost')}/>
                        <Column body={differenceTemplate} header={t('product_detail.drop_shipping_loss')}/>
                        <Column body={dateTemplate} header={t('product_detail.date')}/>
                    </DataTable>
                </div>
            </div> : "";
    };

    const header = <div className="p-grid">
        <div className="p-col-4 p-fluid">
            <label htmlFor="product_name">{t('product.product_name')}</label>
            <AutoComplete id="product_name" dropdown={true} value={product_name} onChange={(e) => {setProductName(getKeyValue(e).value)}}
                          suggestions={productSuggestions} completeMethod={suggestProducts}/>

        </div>

    </div>;

    const createScrapingRequest = () => {
        dashboardService.getDropShippingLossPrices(selectedCampaign.id, toDateString(date)).then((scrapingRequest) => {
            setScrapingRequest(scrapingRequest)
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    return <React.Fragment>

        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.drop_shipping_loss')}</h3>
                <hr></hr>
            </div>
            <div className="p-col-12">
                <Button tooltip={t('actions.previous')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left"
                        className="p-button-outlined" onClick={previousDate} style={{marginRight:'0.5em'}} />
                <Calendar id="date" name="date" readOnlyInput={true} value={new Date(date)} onChange={selectDate} showIcon={true} dateFormat="yy-mm-dd" style={{marginRight:'0.5em'}} />
                <Button tooltip={t('actions.next')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right"
                        className="p-button-outlined" onClick={nextDate} style={{marginRight:'0.5em'}}/>
                <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success p-mr-1" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
                <ScrapeStatus type="price" scraping_request={scrapingRequest}
                              campaign_id={selectedCampaign.id}
                              product_id={null}
                              competitor_url={null}
                              newRequest={null}
                              newRequestCompleted={createScrapingRequest} />
            </div>
            <div className="p-col-12">
                {loading ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div> :
                    <DataView value={filtered_dropShippingLoss} paginator={true} rows={50} layout="grid"
                              header={header} itemTemplate={productTemplate}/>}

            </div>
        </div>

    </React.Fragment>;
};
export default DropShippingLoss;
