import React, {useEffect, useState} from "react";
import {
    exportFile,
    formatCurrency,
    getKeyValue,
    getProductDetailLink
} from "../../utils/Utils";
import {toDateString, toDateStringIgnoringTimezone} from "../../utils/TimeUtil";
import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {ExportService} from "../../service/ExportService";
import {Dropdown} from "primereact/dropdown";
import {CampaignContext} from "../../App";
import {Calendar} from "primereact/calendar";

const DailyPriceComparison = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [items, setItems] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);

    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    const [startDate, setStartDate] = useState(toDateStringIgnoringTimezone(new Date()));
    const [endDate, setEndDate] = useState(toDateStringIgnoringTimezone(new Date()));

    const previousDate = () => {
        let previous_day = (new Date(startDate)).getDate() - 1
        let previous_date = new Date(new Date(startDate).setDate(previous_day));
        setStartDate(toDateStringIgnoringTimezone(previous_date));
        setEndDate(toDateStringIgnoringTimezone(previous_date));
    }

    const nextDate = () => {
        let next_day = (new Date(startDate)).getDate() + 1
        let next_date = new Date(new Date(startDate).setDate(next_day));
        setStartDate(toDateStringIgnoringTimezone(next_date));
        setEndDate(toDateStringIgnoringTimezone(next_date));
    }

    const selectDate = (e) => {
        setStartDate(toDateStringIgnoringTimezone(e.value));
    }

    const selectEndDate = (e) => {
        setEndDate(toDateStringIgnoringTimezone(e.value));
    }

    useEffect ( ()=>{

    },[selectedCampaign]);

    const search = () => {
        if (selectedCampaign && startDate && endDate) {
            setLoading(true);
            dashboardService.getDailyPriceComparison(selectedCampaign.id, startDate, endDate).then(data=>{
                setItems(data);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const onExport = () => {
        setLoadingExcelExport(true);
        exportService.exportDailyPriceComparisonSummary(selectedCampaign.id, startDate, endDate).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.daily_price_comparison') + "_" + toDateString(new Date()) + ".xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const nameTemplate = (rowData) => {
        return getProductDetailLink(rowData.product_id, rowData.product_name);
    };

    const ownPriceTemplate = (rowData) => {
        return rowData.map_owner_value ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.map_owner_value, rowData.map_owner_currency)}
        </div> :
            rowData.owner_value ? <div className="p-grid p-justify-end">
                {formatCurrency(rowData.owner_value, rowData.owner_currency)}
            </div> : '';
    };

    const minPriceTemplate = (rowData) => {
        return rowData.min_value ? <div className="p-grid p-justify-end">
                {formatCurrency(rowData.min_value, rowData.currency)}
            </div> : '';
    };

    const maxPriceTemplate = (rowData) => {
        return rowData.max_value ? <div className="p-grid p-justify-end">
                {formatCurrency(rowData.max_value, rowData.currency)}
            </div> : '';
    };

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.daily_price_comparison')}</h3>
                <hr></hr>
            </div>

            {loading ?
                <div className="p-col-12" style={{textAlign:'center'}}>
                    <Loading/>
                </div> :
                <div className="p-col-12 p-grid p-fluid">
                    <div className="p-col-1">
                        <span className="p-float-label">
                            <Button tooltip={t('actions.previous')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left" className="p-button-outlined" onClick={previousDate} style={{marginRight:'0.5em'}} />
                        </span>
                    </div>
                    <div className="p-col-2">
                        <span className="p-float-label">
                            <Calendar id="start_date" name="start_date" readOnlyInput={true} value={new Date(startDate)} onChange={selectDate} showIcon={true} dateFormat="yy-mm-dd" style={{marginRight:'0.5em'}} />
                        </span>
                    </div>

                    <div className="p-col-2">
                        <span className="p-float-label">
                            <Calendar id="end_date" name="end_date" readOnlyInput={true} value={new Date(endDate)} onChange={selectEndDate} showIcon={true} dateFormat="yy-mm-dd" style={{marginRight:'0.5em'}} />
                        </span>
                    </div>

                    <div className="p-col-1">
                        <span className="p-float-label">
                            <Button tooltip={t('actions.next')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right" className="p-button-outlined" onClick={nextDate} style={{marginRight:'0.5em'}}/>
                        </span>
                    </div>

                    <div className="p-col-2 p-mt-2">
                        <Button icon="pi pi-search" className="p-button-outlined p-button-info" label={t('actions.search')} onClick={search} loading={loadingExcelExport} />
                    </div>

                    <div className="p-col-3 p-mt-2">
                        <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
                    </div>

                    <div className="p-col-12">
                        <DataTable value={items} scrollable scrollHeight={(window.innerHeight - 430)+"px"}
                                   emptyMessage={t('actions.empty_message')}
                                   paginator={true} rows={100}>
                            <Column field="range" header={t('weekly_price_comparison.range')} headerStyle={{width: '150px'}} sortable filter/>
                            <Column field="product_code" header={t('weekly_price_comparison.product_code')} headerStyle={{width: '150px'}} sortable filter/>
                            <Column field="product_name" body={nameTemplate} header={t('weekly_price_comparison.product_name')} headerStyle={{width: '300px'}} sortable filter/>
                            <Column field="category" header={t('weekly_price_comparison.category')} headerStyle={{width: '200px'}} sortable filter/>
                            <Column field="sub_category" header={t('weekly_price_comparison.sub_category')} headerStyle={{width: '200px'}} sortable filter/>
                            <Column field="seller_name" header={t('weekly_price_comparison.seller_name')} headerStyle={{width: '150px'}} sortable filter/>
                            <Column field="owner_value" body={ownPriceTemplate} header={t('weekly_price_comparison.owner_value')} headerStyle={{width: '100px', textAlign:"right"}}/>
                            <Column field="min_value" body={minPriceTemplate} header={t('weekly_price_comparison.min_value')} headerStyle={{width: '100px', textAlign:"right"}}/>
                            <Column field="max_value" body={maxPriceTemplate} header={t('weekly_price_comparison.max_value')} headerStyle={{width: '100px', textAlign:"right"}}/>
                            <Column field="min_difference_rate" header={t('weekly_price_comparison.min_difference_rate')} headerStyle={{width: '100px'}}/>
                            <Column field="max_difference_rate" header={t('weekly_price_comparison.max_difference_rate')} headerStyle={{width: '100px'}}/>
                        </DataTable>
                    </div>
                </div>
                }
        </div>

    </React.Fragment>;
};
export default DailyPriceComparison;
