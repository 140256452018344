import React, {useState} from 'react';
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import {AccountService} from "../../service/AccountService";
import {getKeyValue} from "../../utils/Utils";
import {AppContext} from "../../HomePage";

const ChangePassword = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const accountService = new AccountService();

    const[old_password, set_old_password] = useState("");
    const[new_password, set_new_password] = useState("");
    const[password_again, set_password_again] = useState("");
    const[disabled, setDisabled] = useState(false);

    const save = () => {
        if (disabled) {
            return;
        }
        setDisabled(true);

        if (new_password !== password_again)
        {
            setDisabled(false);
            toast.current.show({severity: "error", summary: t('message_detail.error'), detail: t('validations.password_no_match')});
            return;
        }
        accountService.changeMyPassword(old_password, new_password).then(user_updated=>{
            toast.current.show({severity: 'success', summary: t('actions.change_password'), detail: t('message_detail.successful')});
            setDisabled(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setDisabled(false);
        });
    };

    return (
        <div className="card card-w-title alignCenter p-fluid">
            <div className="p-grid">
                <div className="p-col-12">
                    <h4>{t('actions.change_password')}</h4>
                </div>

                <div className="p-col-12">
                    <span className="p-float-label">
                        <Password id="old_password" name="old_password" value={old_password} onChange={(e)=> {set_old_password(getKeyValue(e).value);}} />
                        <label htmlFor="old_password">{t('user.old_password')}</label>
                    </span>
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <Password id="new_password" name="new_password" value={new_password} onChange={(e)=> {set_new_password(getKeyValue(e).value);}} />
                        <label htmlFor="new_password">{t('user.new_password')}</label>
                    </span>
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <Password id="password_again" name="password_again" value={password_again} onChange={(e)=> {set_password_again(getKeyValue(e).value);}} />
                        <label htmlFor="password_again">{t('user.password_again')}</label>
                    </span>
                </div>

                <div className="p-col-12">
                    <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save}/>
                </div>
            </div>
        </div>
    );
};
export default ChangePassword;
