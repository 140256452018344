import React, {useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {AppContext} from "../HomePage";

const CopyToClipboard = (props) => {

    const {t} = React.useContext(AppContext);
    const [tooltip, setTooltip] = useState(t('actions.copy'));
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(()=> {
            timeoutRef.current = null;
            setTooltip(t('actions.copy'));
        },1000);
    },[tooltip]);

    const copy = () => {
        navigator.clipboard.writeText(props.textToCopy).then(() => {
            setTooltip(t('actions.copied'));
        });
    };

    if (props.isButton) {
        return <Button className="p-button-outlined p-button-info" label={props.buttonLabel} icon="pi pi-copy" onClick={copy}/>;
    }
    else {
        return <Button className="p-button-outlined p-button-info imageButton preventSelect" label={tooltip} tooltipOptions={{position:"bottom"}} icon="pi pi-copy" onClick={copy}/>;
    }
};
export default CopyToClipboard;
