import React, {useEffect, useState} from "react";

import {
    exportFile,
    formatCurrency,
    getKeyValue, getProductDetailLink, isCampaignDropShipping, toTitleCase
} from "../../utils/Utils";
import {toDateStringIgnoringTimezone, toDateTimeString} from "../../utils/TimeUtil";


import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {DataView} from "primereact/dataview";
import {Calendar} from "primereact/calendar";


import {ExportService} from "../../service/ExportService";
import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";
import {InputText} from "primereact/inputtext";
import {Loading, StockTemplate} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {SelectButton} from "primereact/selectbutton";
import {CampaignContext} from "../../App";



const PriceStockChanges = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [changes, setChanges] = useState([]);
    const [date, setDate] = useState(toDateStringIgnoringTimezone(new Date()));

    const [product_name, setProductName] = useState("");
    const [competitor_name, setCompetitorName] = useState("");
    const [sellerFilter, setSellerFilter] = useState("all");
    const [productSuggestions, setProductSuggestions] = useState(null);
    const [changeType, setChangeType] = useState('price');
    const changeTypeOptions = [
        {label: t('dashboard.price_changes'), value: 'price'},
        {label: t('dashboard.stock_changes'), value: 'stock'}
    ];


    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);

    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect(() =>{
        search(changeType);
    }, [selectedCampaign, date]);

    const suggestProducts = (event) => {
        if (changes != null) {
            let results = changes.filter((change) => {
                return change.name.toLowerCase().startsWith(event.query.toLowerCase());
            }).map(item => item.name);

            setProductSuggestions(results);
        }
    };

    const search = (changeType) => {
        if (selectedCampaign) {
            setLoading(true);
            setChangeType(changeType)

            dashboardService.getPriceStockChanges(selectedCampaign.id, date).then(changes=>{
                setChanges(changes);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const onExport = () => {
        setLoadingExcelExport(true);
        exportService.exportPriceStockChanges(selectedCampaign.id, toDateStringIgnoringTimezone(date), changeType).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.changes') + "_" + toDateStringIgnoringTimezone(date) + ".xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const previousDate = () => {
        setDate(toDateStringIgnoringTimezone((new Date(date)).setDate((new Date(date)).getDate()-1)));
    }
    const nextDate = () => {
        setDate(toDateStringIgnoringTimezone((new Date(date)).setDate((new Date(date)).getDate()+1)));
    }
    const selectDate = (e) => {
        setDate(toDateStringIgnoringTimezone(e.value));
    }

    const nameTemplate = (rowData) => {
        return<a target="_blank" rel="noopener noreferrer" href={rowData.product_url}>{rowData.name}</a>;
    };

    const priceTemplate = (rowData) => {

        let directionColor = rowData.price_changed ? (rowData.increased ? "green" : "red") : "black";
        let direction = rowData.price_changed ? (rowData.increased ? " ↑" : " ↓") : "";
        let priceColor = rowData.price_changed ? (rowData.higher ? "green" : "red") : "black";

        return <span style={{color: priceColor}}>{formatCurrency(rowData.value, rowData.currency)}
            <span style={{color: directionColor, fontSize:"1.7em"}}>{direction}</span>
        </span>;
    };

    const previousPriceTemplate = (rowData) => {
        return <span>{formatCurrency(rowData.old_value, rowData.currency)}</span>;
    };

    const priceChange = (rowData) => {
        return <span>{formatCurrency(rowData.value - rowData.old_value, rowData.currency)}</span>;
    };

    const stockTemplate = (rowData) => {
        let stockColor = rowData.stock_changed ? (rowData.stock > rowData.old_stock ? "green" : "red") : "black";
        return <span style={{color: stockColor}}>{rowData.stock}</span>;
    };

    const currentStockTemplate = (rowData) => {
        return StockTemplate(rowData.stock);
    };

    const previousStockTemplate = (rowData) => {
        return <span>{rowData.old_stock}</span>;
    };

    const stockChange = (rowData) => {
        return <span>{rowData.old_stock >= rowData.stock ? rowData.old_stock - rowData.stock : ""}</span>;
    };

    const isActiveOptions = [
        {name: t('actions.all'), value: "all"},
        {name: t('actions.only_seller'), value: "seller"},
        {name: t('actions.only_drop_shipping'), value: "drop_shipping"}
    ];

    const priceChangeTemplate = (rowData) => {

        let states = rowData && rowData.competitor_states ?
            rowData.competitor_states.filter(change_state=> change_state.price_changed === true &&
                (competitor_name === "" || change_state.name.toLowerCase().indexOf(competitor_name.toLowerCase()) !== -1) &&
                (sellerFilter === "all" || (sellerFilter === "seller" && change_state.is_drop_shipping === false) || (sellerFilter === "drop_shipping" && change_state.is_drop_shipping === true))) : [];

        let variantList = null;
        if (rowData.properties) {
            variantList = rowData.properties.map(property => {
                if (property.value !== "")
                    return <div key={property.name}><b>{toTitleCase(property.name.replace("_", " "))} : </b>{property.value}</div>;
            });
        }

        return states.length ? <div className="p-grid p-fluid" style={{border:"1px solid #c8c8c8", margin:"0.5em"}}>
                <div className="p-col-4 p-grid">
                    <div className="p-col-6">
                        <a target="_blank" rel="noopener noreferrer" href={rowData.product_url}>
                            <img referrerPolicy='no-referrer' className="img-fluid" src={rowData.image} alt=""/>
                        </a>
                    </div>
                    <div className="p-col-6">
                        <div className="p-col-12">
                            {getProductDetailLink(rowData.product_app_url.split("id=")[1], rowData.name ? rowData.name : t('scraping_request.product_id'))}
                            {variantList}
                        </div>
                        <div className="p-col-12">
                            <span><b>{t('product_detail.price') + " : " }</b>{formatCurrency(rowData.value, rowData.currency)}</span>
                        </div>
                        <div className="p-col-12">
                            <span><b>{t('product_detail.date') + " : " }</b>{toDateTimeString(rowData.scraped_time)}</span>
                        </div>
                    </div>
                </div>
                <div className="p-col-8">
                    <DataTable value={states} responsive={true}>
                        <Column body={nameTemplate} header={t('product_detail.competitor')}/>
                        <Column body={priceTemplate} header={t('product_detail.price')}/>
                        <Column body={previousPriceTemplate} header={t('product_detail.previous_price')}/>
                        <Column body={currentStockTemplate} header={t('product_detail.stock')}/>
                    </DataTable>
                </div>
            </div> : "";
    };

    const stockChangeTemplate = (rowData) => {

        let states = rowData && rowData.competitor_states ?
            rowData.competitor_states.filter(change_state=> change_state.stock_changed === true &&
                (competitor_name === "" || change_state.name.toLowerCase().indexOf(competitor_name.toLowerCase()) !== -1) &&
                (sellerFilter === "all" || (sellerFilter === "seller" && change_state.is_drop_shipping === false) || (sellerFilter === "drop_shipping" && change_state.is_drop_shipping === true))) : [];

        let variantList = null;
        if (rowData.properties) {
            variantList = rowData.properties.map(property => {
                if (property.value !== "")
                    return <div key={property.name}><b>{toTitleCase(property.name.replace("_", " "))} : </b>{property.value}</div>;
            });
        }

        return states.length ? <div className="p-grid p-fluid" style={{border:"1px solid #c8c8c8", margin:"0.5em"}}>
            <div className="p-col-4 p-grid">
                <div className="p-col-6">
                    <a target="_blank" rel="noopener noreferrer" href={rowData.product_url}>
                        <img referrerPolicy='no-referrer' className="img-fluid" src={rowData.image} alt=""/>
                    </a>
                </div>
                <div className="p-col-6">
                    <div className="p-col-12">
                        {getProductDetailLink(rowData.product_app_url.split("id=")[1], rowData.name ? rowData.name : t('scraping_request.product_id'))}
                        {variantList}
                    </div>
                    <div className="p-col-12">
                        <span><b>{t('product_detail.price') + " : " }</b>{formatCurrency(rowData.value, rowData.currency)}</span>
                    </div>
                    <div className="p-col-12">
                        <span><b>{t('product_detail.date') + " : " }</b>{toDateTimeString(rowData.scraped_time)}</span>
                    </div>
                </div>
            </div>
            <div className="p-col-8">
                <DataTable value={states} responsive={true}>
                    <Column body={nameTemplate} header={t('product_detail.competitor')}/>
                    <Column body={priceTemplate} header={t('product_detail.price')}/>
                    <Column body={stockTemplate} header={t('product_detail.stock')}/>
                    <Column body={previousStockTemplate} header={t('product_detail.previous_stock')}/>
                    <Column body={stockChange} header={t('product_detail.difference')}/>
                </DataTable>
            </div>
        </div> : "";
    };

    const filtered_changes = changes.filter(change=> (
        ((changeType === "price" && change.competitor_states.some(change_state=> change_state.price_changed === true)) ||
            (changeType !== "price" && change.competitor_states.some(change_state=> change_state.stock_changed === true))) &&
        (product_name==="" || change.name.toLowerCase().indexOf(product_name.toLowerCase()) !== -1)));

    const header = <div className="p-grid p-fluid">
        <div className="p-col-4">
            <label htmlFor="product_name">{t('product.product_name')}</label>
            <AutoComplete id="product_name" dropdown={true} value={product_name} onChange={(e) => {setProductName(getKeyValue(e).value)}}
                          suggestions={productSuggestions} completeMethod={suggestProducts}/>

        </div>
        <div className="p-col-4">
            <label htmlFor="competitor_name">{t('product_detail.competitor')}</label>
            <InputText id="competitor_name" value={competitor_name} onChange={(e) => {setCompetitorName( getKeyValue(e).value)}}/>
        </div>

        {isCampaignDropShipping(selectedCampaign) &&
        <div className="p-col-4">
            <SelectButton value={sellerFilter}
                          optionLabel="name" options={isActiveOptions}
                          onChange={(e) => {setSellerFilter( getKeyValue(e).value)}}
                          style={{ marginTop:'0.5em'}}/>
        </div>
        }

    </div>;

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.price_changes')}</h3>
                <hr></hr>
            </div>
            <div className="p-col-9">
                <Button tooltip={t('actions.previous')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left"
                        className="p-button-outlined p-mr-1" onClick={previousDate} />
                <Calendar id="date" name="date" readOnlyInput={true} value={new Date(date)} onChange={selectDate} showIcon={true} dateFormat="yy-mm-dd" className="p-mr-1" />
                <Button tooltip={t('actions.next')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right"
                        className="p-button-outlined p-mr-1" onClick={nextDate}/>
                <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
            </div>
            <div className="p-col-3">
                <SelectButton value={changeType} optionLabel="label" options={changeTypeOptions}
                              onChange={(e) => {search(e.value)}}
                              style={{ marginRight:'.5em', float: 'right'}}/>
            </div>

            <div className="p-col-12">
                {loading ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div> :
                    <DataView value={filtered_changes} paginator={true} rows={50} layout="grid" header={header}
                              emptyMessage={t('actions.empty_message')}
                              itemTemplate={changeType === "price" ? priceChangeTemplate : stockChangeTemplate}/>}
            </div>

        </div>

    </React.Fragment>;

};
export default PriceStockChanges;
