import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import {RadioButton} from "primereact/radiobutton";
import {Bar} from "react-chartjs-2";

import {
    formatCurrency,
    formatCurrencyText, getDatePrice,
    getHostName,
    getKeyValue, getLastPrice, isAdmin, isCampaignManufacturer, isPreventSelect, isShippingPricesHandledSeparately,
    urlValidator, user
} from "../../utils/Utils";
import {toDateString, toDateTimeString, getWeeksAgo, toDateStringIgnoringTimezone} from "../../utils/TimeUtil";

import {OverlayPanel} from "primereact/overlaypanel";
import ScrapeStatus from "./ScrapeStatus";
import {InputSwitch} from "primereact/inputswitch";
import {StockTemplate, IndexTemplate} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {Sidebar} from "primereact/sidebar";
import {InputNumber} from "primereact/inputnumber";
import {CampaignContext} from "../../App";
import CompetitorBulkOperations from "./CompetitorBulkOperations";
import CompetitorViolationAction from "./CompetitorViolationAction";
import {Tag} from "primereact/tag";
import {ProductService} from "../../service/ProductService";
import {Dropdown} from "primereact/dropdown";
import {UserService} from "../../service/UserService";
import {Calendar} from "primereact/calendar";

const Competitor = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const {campaigns} = React.useContext(CampaignContext);

    const op = useRef(null);

    let selectedProduct = {...props.selectedProduct};
    let selectedCampaign = {...campaigns.find(c=> c.id===props.selectedProduct.campaign_id)};

    const [product_url, setProductUrl] = useState("");
    const [formErrors, setFormErrors] = useState({
        product_url: t('message_detail.required'),
    });
    const [expandedCompetitor, setExpandedCompetitor] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddPriceModal, setShowAddPriceModal] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [date, setDate] = useState(toDateStringIgnoringTimezone(new Date()));
    const [showViolationActionModal, setShowViolationActionModal] = useState(false);

    const [selectedCompetitor, setSelectedCompetitor] = useState(null);
    const [newPrice, setNewPrice] = useState(null);

    const [showVariants, setShowVariants] = useState(false);
    const [detailData, setDetailData] = useState(null);
    const [showBulkOperations, setShowBulkOperations] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showCampaignInactiveCompetitors, setShowCampaignInactiveCompetitors] = useState(false);

    const productService = new ProductService();
    const userService = new UserService();

    useEffect ( ()=>{
        selectedProduct = {...props.selectedProduct};
        selectedCampaign = {...campaigns.find(c=> c.id===props.selectedProduct.campaign_id)};
    },[props.selectedProduct]);

    const getCurrencies = (rowData) => {

        setSelectedCompetitor(rowData);
        let newPrice = {
            "competitor_url": rowData.product_url,
            "added_price" : 0,
            "added_sale_price" : 0,
            "added_stock" : 1,
            "added_currency" : null,
            "added_date": new Date()
        };

        if (rowData?.prices.length > 0) {
            let lastPrice = rowData.prices[rowData.prices.length - 1];

            newPrice = {
                "competitor_url": rowData.product_url,
                "added_price" : lastPrice?.value,
                "added_sale_price" : lastPrice?.sale_value,
                "added_stock" : lastPrice?.stock,
                "added_currency" : lastPrice?.currency,
                "added_date": new Date()
            };

        }
        setNewPrice({...newPrice});

        if (currencies.length === 0) {
            userService.getCurrencies().then(data=>{
                setCurrencies(data);
            });
        }
        setShowAddPriceModal(true);
    };

    const selectDate = (e) => {
        setDate(toDateStringIgnoringTimezone(e.value));
    }

    const addDialog = () => {
        setDialogVisible(true);
        setFormErrors({product_url: t('message_detail.required')});
    };

    const addCompetitor = () => {
        let urls = [];
        urls.push({product_url: product_url});
        props.addCompetitor(urls, formErrors);
        setDialogVisible(false);
        setProductUrl("");
    };
    const changeIsDynamicPricingActive = ( rowData) => {
        const product_urls = [];
        product_urls.push(rowData.product_url);
        props.changeCompetitorIsDynamicPricingActive(product_urls, rowData.is_dynamic_pricing_active);
    };
    const changeIsActive = (rowData) => {
        const product_urls = [];
        product_urls.push(rowData.product_url);
        props.changeCompetitorIsActive(product_urls, rowData.is_active);
    };
    const deleteCompetitor = () => {

        const product_urls = [];
        competitors.filter(p=> (selectedCompetitor.product_url === p.product_url || selectedCompetitor.product_url === p.parent_url ||
            (selectedCompetitor.parent_url && (selectedCompetitor.parent_url === p.product_url || selectedCompetitor.parent_url === p.parent_url)))
        ).forEach(p=>{
            product_urls.push(p.product_url);
        })

        props.deleteCompetitor(product_urls).then(()=>{
            setShowDeleteConfirmation(false);
            setSelectedCompetitor(null);
        });

    };
    const updateCompetitor = () => {
        let data = {"competitor_url": selectedCompetitor.product_url,
            "multiplier": selectedCompetitor.multiplier,
            "discount_type": selectedCompetitor.discount_type,
            "manual_shipping_price": selectedCompetitor.manual_shipping_price,
            "manual_shipping_price_threshold": selectedCompetitor.manual_shipping_price_threshold}
        props.updateCompetitor(data);
        setShowEditModal(false);
        setSelectedCompetitor(null);
    };
    const addCompetitorPrice = () => {
        productService.addCompetitorPrice(selectedProduct.id, newPrice).then((data) => {
            setShowAddPriceModal(false);
            selectedCompetitor.prices.push(data);
            setSelectedCompetitor({...selectedCompetitor});
            toast.current.show({severity: 'success', summary: t('product_detail.edit_competitor'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setShowAddPriceModal(false);
            setError(error);
            setError(null);
        });

    };

    const deleteCompetitorPrice = (rowData, competitor_url) => {
        let data = {"competitor_url": competitor_url, "created_at": rowData.created_at}
        productService.deleteCompetitorPrice(selectedProduct.id, data).then(() => {
            toast.current.show({severity: 'success', summary: t('product_detail.edit_competitor'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    };
    const matchVariant = (e) => {
        let variantData = e.value;
        props.matchCompetitorVariant(selectedCompetitor.product_url, variantData.properties);
        setTimeout( () => {
            setShowVariants(false);
        }, 1000);
    };

    const actionTemplate = (rowData, props) => {
        return <React.Fragment>
            <Button icon="pi pi-trash" className="p-button-outlined p-button-rounded p-button-danger p-mr-1"
                    tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => {deleteCompetitorPrice(rowData, props.field);}}/>
        </React.Fragment> ;
    };

    const competitorRowExpansionTemplate = (rowData) => {

        const c = selectedCampaign?.competitors?.filter(c=> getHostName(c.url).includes(getHostName(rowData.product_url)))

        return (
            <div className="p-grid p-fluid" style={{"margin":"1em 0em"}}>

                {c && c.length > 0 && <div className="p-grid p-card p-col-12 p-nogutter" >
                    <div className="p-col-3 p-text-bold">{t('campaign.competitors.email')}</div>
                    <div className="p-col-3">{c[0].email}</div>
                    <div className="p-col-3 p-text-bold">{t('campaign.competitors.phone')}</div>
                    <div className="p-col-3">{c[0].phone}</div>
                    <div className="p-col-3 p-text-bold">{t('campaign.competitors.address')}</div>
                    <div className="p-col-3">{c[0].address}</div>
                    <div className="p-col-3 p-text-bold">{t('campaign.competitors.note')}</div>
                    <div className="p-col-3">{c[0].note}</div>
                </div>
                }

                <div className="p-card p-col-12 p-mt-1">
                    <Bar id="comparison" data={competitorChartData(rowData)} options={competitorChartOptions()} height={200}/>
                </div>

                <div className="p-card p-col-12 p-mt-1">
                    <div className="p-grid p-col-12 p-nogutter" >
                        <div className="p-col-11 p-text-bold"><h5>{t('product_detail.last_checks')}</h5></div>
                        {isAdmin() && <div className="p-col-1" style={{textAlign: 'right'}}><Button icon="pi pi-plus" data-tour="step12" className="p-button-outlined p-button-rounded p-button-success p-mr-1" tooltipOptions={{position: 'bottom'}} tooltip={t('actions.add_price')} onClick={() => {getCurrencies(rowData); }}/></div>}
                    </div>
                    <DataTable value={rowData.prices}
                               paginator={true}
                               rows={5}
                               responsive={true}
                               sortField="created_at" sortOrder={-1}>
                        <Column field="created_at" header={t('product_detail.date')} body={priceDateTemplate} />
                        <Column field="value" header={t('product_detail.price')} headerStyle={{width:'15em'}} body={priceValueTemplate}/>
                        <Column header={t('product.list_price')} headerStyle={{textAlign:"center", width:'10%'}} field="sale_value" body={priceSaleValueTemplate}/>
                        {showDiscountRate() && <Column header={t('product.discount_rate')} headerStyle={{textAlign:"center", width:'10%'}} field="discount_rate" body={priceDiscountRateTemplate}/>}
                        <Column field="stock" header={t('product_detail.stock')} headerStyle={{width:'8em'}} body={priceStockTemplate}/>
                        {isAdmin() && <Column body={actionTemplate} field={rowData.product_url} style={{textAlign: 'center', width:"13em"}} />}
                    </DataTable>
                </div>
            </div>);
    };
    const competitorChartData = (rowData) => {
        let startDate = rowData.prices && rowData.prices.length > 0 ? new Date(rowData.prices[0].created_at) : new Date(getWeeksAgo(2));
        let endDate = new Date();
        let days = [];

        let dataCompetitor = [];
        let dataCompetitorStock = [];
        if (rowData) {

            while (startDate < endDate)
            {
                const endDateString = toDateString(endDate);
                days.unshift(endDateString);

                let price = getDatePrice(rowData.prices, endDateString);

                if (price && price.value) {
                    dataCompetitor.push({x: endDateString, y: parseFloat(price.value), list_price: parseFloat(price.sale_value), discount_rate: parseFloat(price.discount_rate), currency: price.currency});
                }
                else {
                    dataCompetitor.push({x: endDateString});
                }

                if (price) {
                    dataCompetitorStock.push({x:endDateString, y:(price.stock)});
                }
                else {
                    dataCompetitorStock.push({x:endDateString});
                }

                endDate.setDate(endDate.getDate() - 1);
            }
        }

        let dataset = [ {
            type: 'line',
            label: t('product_detail.price'),
            borderColor: "red",
            borderWidth: 2,
            fill: false,
            data: dataCompetitor,
            lineTension: 0,
            yAxisID:'y',
        },
            {
                type: 'line',
                label: t('product_detail.stock'),
                borderColor: "green",
                borderWidth: 2,
                fill: false,
                data: dataCompetitorStock,
                lineTension: 0,
                yAxisID:'y1',
            }];


        return {
            labels: days,
            datasets: dataset
        };
    };

    const competitorChartOptions = () => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend:{
                labels: {
                    filter: function(legendItem) {
                        return  !(legendItem.datasetIndex === 1);
                    }
                }
            },
            scales: {
                y: {
                    display: true,
                    ticks: {
                        fontSize: 10
                    }
                },
                y1: {
                    display: true,
                    grid:{
                        display: false,
                    },
                    ticks: {
                        fontSize: 10,

                    }
                }

            },
            plugins: {
                labels: false,
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    titleFontSize:20,
                    bodyFontSize:15,
                    bodySpacing:5,
                    displayColors:true,
                    callbacks:{
                        label: function(item){
                            if (item.dataset.label === t('product_detail.price'))
                                return item.dataset.label + " : " + formatCurrencyText(item.raw.y, item.raw.currency)
                                    + (item.raw.list_price && item.raw.list_price !== item.raw.y ? " / "
                                    + (item.raw.discount_rate ? "(%" + item.raw.discount_rate.toFixed(2) + ") " : "")
                                    + formatCurrencyText(item.raw.list_price, item.raw.currency) : "");
                            else
                                return item.dataset.label + " : " + item.raw.y;
                        }
                    },
                },
            }

        };
    };

    const priceDateTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{t('product_detail.date')}</span>
            {toDateTimeString(rowData.created_at)}
        </div>;
    };
    const priceValueTemplate = (rowData) => {
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product_detail.price')}</span>
            {formatCurrency(rowData.value, rowData.currency)}
        </div>;
    };
    const priceSaleValueTemplate = (rowData) => {
        let sale_value = rowData && rowData.sale_value ?
            (formatCurrencyText(rowData.sale_value, rowData.currency)) :
            null;

        return rowData.sale_value !== rowData.value &&
            <div className="p-text-light p-text-nowrap" style={{fontSize:"0.9em"}}>
                <span className="strikethrough-diagonal">{sale_value}</span>
            </div>;
    };

    const priceDiscountRateTemplate = (rowData) => {
        return rowData?.discount_rate;
    }
    const priceStockTemplate  = (rowData) => {
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product_detail.stock')}</span>
            {StockTemplate(rowData.stock)}
        </div>;
    };

    const isAuthorized = (rowData) => {
        if (isCampaignManufacturer(selectedCampaign))
            if (rowData.product_url === "")
                return;
            if (selectedCampaign.competitors.some(c=> getHostName(c.url).includes(getHostName(rowData.product_url)) && (c.is_authorized || (!c.sellers || c.sellers.some(s=> s.seller_name === rowData.name && s.is_authorized)))))
                return <Tag style={{maxWidth: "10em", minWidth: "3em", textAlign: 'center'}} severity="success" value={t("campaign.competitors.authorized")} />;
            else
                return <Tag style={{maxWidth: "10em", minWidth: "3em", textAlign: 'center'}} severity="danger" value={t("campaign.competitors.not_authorized")} />;
    }

    const competitorProductUrlTemplate = (rowData) => {

        let l = document.createElement("a");
        l.href = rowData.product_url;

        return <div>
            <span className="p-column-title">{t('table_headers.name')}</span>
            <div className="p-grid">

                {isCampaignManufacturer(selectedCampaign) &&
                    selectedCampaign.competitors?.some(c=> c.is_authorized || c.sellers?.some(s=> s.is_authorized) ) &&
                <div className="p-col-fixed" style={{ width: '80px'}}>
                    {isAuthorized(rowData)}
                </div>}
                <div className="p-col">

            <div onMouseEnter={(e) => {
                     setDetailData(rowData);
                     op.current.toggle(e);}}
                 onMouseLeave={(e) => op.current.toggle(e)}>
                <a target="_blank" rel="noopener noreferrer" href={rowData.product_url}
                   style={{color: (rowData.is_mine ? 'seagreen' : rowData.is_map ? '#c63737' : rowData.is_mrp ? '#c63737' : 'midnightblue')}}>
                    <span className="p-mb-2">
                        {rowData.is_map ? t('price_violation.map') :
                            rowData.is_mrp ? t('price_violation.mrp') :
                                rowData.name && rowData.name !== "" ? rowData.name : getHostName(rowData.product_url)}
                    </span>
                </a>
            </div>
            {rowData.product_name || rowData.variants && rowData.variants.length !== 0 ?
                <div  style={{fontSize:"0.8em"}}>
                    {rowData.product_name && rowData.product_name}
                    {rowData.variants && rowData.variants.length !== 0 ?
                        <Button icon="pi pi-images" className={(rowData.selected_variant ? "p-button-success" : "p-button-danger") + " p-button-text imageButton p-justify-right"} tooltip={t('product_detail.variant_available')} onClick={() => {setSelectedCompetitor({...rowData}); setShowVariants(true);}}/>
                        : null}
                    {rowData.variants && rowData.variants.length !== 0 &&
                    rowData.selected_variant ? (rowData.selected_variant && rowData.selected_variant.length !== 0) &&
                        rowData.selected_variant.map(property => {
                            return <div>
                                <b>{property.name + " : "}</b>{property.value}
                            </div>;}) : null
                    }
                </div>
                :
                null
            }
            {/*<b className="pi pi-external-link"></b>*/}
            {/*<div className="pi pi-chart-bar" title="Show History"/>
            <div className="pi pi-home" data-toggle="tooltip" title="Own Site" style={{display: (is_mine ? 'inline' : 'none') }} />*/}
                </div>
            </div>
        </div>;
    };

    const competitorLastModifiedTemplate = (rowData) => {
        const lastPrice = getLastPrice(rowData.prices);
        return <div className="p-text-left">
            <span className="p-column-title">{t('table_headers.last_check')}</span>
            {lastPrice && toDateTimeString(lastPrice.created_at)}
        </div>
    };

    const competitorDiscountRateTemplate = (rowData) => {
        const lastPrice = getLastPrice(rowData.prices);

        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.discount_rate')}</span>
            {lastPrice?.discount_rate}
        </div>;
    }

    const competitorPriceTemplate = (rowData) =>{
        const lastPrice = getLastPrice(rowData.prices);

        const lastShippingPrice = getLastPrice(rowData.shipping_prices);

        let price = lastPrice ? (formatCurrency(lastPrice.value, lastPrice.currency)) : "";
        let tooltip = lastPrice && lastPrice.original_value ?
            (formatCurrencyText(lastPrice.original_value, lastPrice.original_currency)) :
            null;

        let sale_value = lastPrice && lastPrice.sale_value ?
            (formatCurrencyText(lastPrice.sale_value, lastPrice.currency)) :
            null;

        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product_detail.price')}</span>
            {sale_value && lastPrice.sale_value !== lastPrice.value &&
            <div className="p-text-light p-text-nowrap" style={{fontSize:"0.9em"}}>
                <span className="strikethrough-diagonal">{sale_value}</span>
            </div>}
            <div className="p-text-nowrap p-text-right">{price}
                {rowData.multiplier && rowData.multiplier !== 1 && <div style={{fontSize: '10px', fontWeight: 'bold', color: '#d06868'}}>( x{rowData.multiplier + " "}<i className="pi pi-bars" style={{'fontSize': '1em'}}/> )</div>}
                {isShippingPricesHandledSeparately(selectedCampaign) && lastShippingPrice && lastShippingPrice.value ? <div style={{fontSize: '10px', fontWeight: 'bold', color: '#d06868'}}>( +{lastShippingPrice.value + " "}<i className="pi pi-shopping-cart" style={{'fontSize': '1em'}}/> )</div> : ""}
                {tooltip && <Button icon="pi pi-refresh" className="imageButton" tooltip={tooltip} style={{color: 'maroon'}}/>}
            </div>
        </div>;
    };

    const competitorShippingPriceTemplate = (rowData) =>{

        const lastPrice = getLastPrice(rowData.prices);
        const lastShippingPrice = getLastPrice(rowData.shipping_prices);
        let shipping_price = lastPrice && lastShippingPrice ? (formatCurrency(lastShippingPrice.value, lastPrice.currency)) : "";

        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product_detail.shipping_price')}</span>
            {shipping_price}
        </div>;
    };

    // const competitorReviewsTemplate = (rowData) =>{
    //     let rating = ReviewRating(rowData, t)
    //     return <div className="p-text-right">
    //         <span className="p-column-title">{t('product_detail.reviews')}</span>
    //         {rating}
    //     </div>;
    // };

    const competitorStockTemplate = (rowData) => {
        if (rowData.is_map || rowData.is_mrp)
            return ""

        let inactive_url_tooltip = rowData.is_url_deactivated ? rowData.is_url_deactivated : false;
        let not_available_tooltip = rowData.not_available_days && rowData.not_available_days >= 7 ? rowData.not_available_days : null;

        const lastPrice = getLastPrice(rowData.prices);

        return <div className="p-text-nowrap p-text-center">
            <span className="p-column-title">{t('product_detail.stock')}</span>
            {!inactive_url_tooltip &&  not_available_tooltip ? <Button icon="pi pi-exclamation-triangle" className="imageButton" tooltip={t('product_detail.not_available_since', {notAvailableSince: toDateString(rowData.not_available_since)})} style={{color: 'orange'}}/> : null}
            {inactive_url_tooltip ? <Button icon="pi pi-exclamation-triangle" className="imageButton" tooltip={t('product_detail.inactive_url')} style={{color: 'maroon'}}/> : null}
            {!inactive_url_tooltip &&  !not_available_tooltip && StockTemplate(lastPrice && lastPrice.stock)}
        </div>;
    }

    const violationDaysTemplate = (rowData) => {
        if (rowData.product_url)
        {
            let tooltip = toDateTimeString(rowData.violation_since);
            return <div className="p-text-nowrap p-text-center" data-toggle="tooltip" title={tooltip}>
                <span className="p-column-title">{t('price_violation.days')}</span>
                {rowData.violation_days}
            </div>;
        }
    };

    // const competitorStatusTemplate = (rowData) => {
    //     if (rowData.is_map || rowData.is_mrp)
    //         return ""
    //     const newRequest = {
    //         campaign_id: selectedProduct.campaign_id,
    //         product_id: selectedProduct.id,
    //         url: rowData.product_url,
    //         competitor_url: rowData.product_url,
    //         type: 'price_competitor_update'
    //     };
    //
    //
    //     return <div className="p-text-right">
    //         <span className="p-column-title">{t('table_headers.scraping_status')}</span>
    //         <ScrapeStatus type="price" scraping_request={rowData.scraping_request}
    //                          parent_request={selectedProduct.scraping_request}
    //                          campaign_id={selectedProduct.campaign_id}
    //                          product_id={selectedProduct.id}
    //                          competitor_url={rowData.product_url}
    //                          newRequest={newRequest}
    //                          newRequestCompleted={props.refreshProducts}/>
    //     </div>;
    // }

    const dynamicPricingActiveTemplate = (rowData) => {
        return rowData.is_mine || rowData.is_map || rowData.is_mrp ? "" : <div className="p-text-right">
            <span className="p-column-title">{t('product_detail.repricing')}</span>
            <InputSwitch checked={rowData.is_dynamic_pricing_active} onChange={() => changeIsDynamicPricingActive(rowData)} tooltip={rowData.is_dynamic_pricing_active === true ? t('actions.exclude') : t('actions.include') }  />
        </div>;
    }

    const competitorActionTemplate = (rowData) => {
        const newRequest = {
            campaign_id: selectedProduct.campaign_id,
            product_id: selectedProduct.id,
            url: rowData.product_url,
            competitor_url: rowData.product_url,
            type: 'price_competitor_update'
        };
        if (rowData.is_map || rowData.is_mrp) {
            return ""
        } else if (rowData.is_mine) {
            return <div className="p-text-left">
                <InputSwitch style={{marginRight:'0.3em'}} checked={rowData.is_active} onChange={() => changeIsActive(rowData)} tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } />
                <ScrapeStatus type="price" scraping_request={rowData.scraping_request}
                              parent_request={selectedProduct.scraping_request}
                              campaign_id={selectedProduct.campaign_id}
                              product_id={selectedProduct.id}
                              competitor_url={rowData.product_url}
                              newRequest={newRequest}
                              newRequestCompleted={props.refreshProducts}/>
                <Button icon="pi pi-pencil" data-tour="step12" className="p-button-outlined p-button-rounded p-button-warning p-mr-1" tooltipOptions={{position: 'bottom'}} tooltip={t('actions.edit')} onClick={() => {setShowEditModal(true); setSelectedCompetitor({...rowData})}}/>
            </div>;
        } else {
            let activation_button
            if (selectedCampaign.new_seller_automatically_activated) {
                let hostname = getHostName(rowData.product_url)
                activation_button = !selectedCampaign.inactive_sellers?.has(rowData.name) && !selectedCampaign.inactive_sellers?.has(hostname)?
                    <InputSwitch style={{marginRight:'0.3em'}} checked={rowData.is_active} onChange={() => changeIsActive(rowData)} tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } /> :
                    <Button icon="pi pi-info-circle" className="p-button-outlined p-button-rounded p-button-danger  p-ml-1 p-mr-1" tooltipOptions={{position: 'bottom'}} tooltip={t('product_detail.campaign_based_deactivated_seller')} />
            } else {
                let hostname = getHostName(rowData.product_url)
                activation_button = selectedCampaign.active_sellers?.has(rowData.name) || selectedCampaign.active_sellers?.has(hostname) ?
                    <InputSwitch style={{marginRight:'0.3em'}} checked={rowData.is_active} onChange={() => changeIsActive(rowData)} tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } /> :
                    <Button icon="pi pi-info-circle" className="p-button-outlined p-button-rounded p-button-danger  p-ml-1 p-mr-1" tooltipOptions={{position: 'bottom'}} tooltip={t('product_detail.campaign_based_deactivated_seller')} />
            }

            return <div className="p-text-left">
                <span className="p-column-title">{t('table_headers.action')}</span>
                {activation_button}
                <ScrapeStatus type="price" scraping_request={rowData.scraping_request}
                              parent_request={selectedProduct.scraping_request}
                              campaign_id={selectedProduct.campaign_id}
                              product_id={selectedProduct.id}
                              competitor_url={rowData.product_url}
                              newRequest={newRequest}
                              newRequestCompleted={props.refreshProducts}/>
                <Button icon="pi pi-pencil" data-tour="step12" className="p-button-outlined p-button-rounded p-button-warning p-mr-1" tooltipOptions={{position: 'bottom'}} tooltip={t('actions.edit')} onClick={() => {setShowEditModal(true); setSelectedCompetitor({...rowData})}}/>
                {isCampaignManufacturer(selectedCampaign) &&
                <Button icon="pi pi-flag" className={(rowData.violation_actions && rowData.violation_actions.length>0 ? "" : "p-button-outlined ") + "p-button-rounded p-button-help p-mr-1"} tooltipOptions={{position: 'bottom'}} tooltip={t('price_violation.actions_taken')} onClick={() => {setShowViolationActionModal(true); setSelectedCompetitor({...rowData})}}/>
                }
                <Button icon="pi pi-trash" className="p-button-outlined p-button-rounded p-button-danger" tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => {
                    setShowDeleteConfirmation(true);
                    setSelectedCompetitor({...rowData});
                }}/>
            </div>;
        }
    }

    const groupHeaderTemplate = (rowData) =>{

        return <div style={{backgroundColor:rowData.groupColor}}>{rowData.groupName}</div>;
    };
    const groupFooterTemplate = (rowData) =>{
        return null;
    };
    const getCompetitors = () =>{

        let competitors = selectedProduct.competitors.filter(c=> c.is_parent_url !== true || isAdmin());

        if (isCampaignManufacturer(selectedCampaign)){
            const mapPrice = selectedProduct.competitors.find(c=>c.is_map) && getLastPrice(selectedProduct.competitors.find(c=>c.is_map).prices) ? getLastPrice(selectedProduct.competitors.find(c=>c.is_map).prices).value : 0;
            const mrpPrice = selectedProduct.competitors.find(c=>c.is_mrp) && getLastPrice(selectedProduct.competitors.find(c=>c.is_mrp).prices) ? getLastPrice(selectedProduct.competitors.find(c=>c.is_mrp).prices).value : 0;

            // competitors.forEach(c =>{
            //     const lastPrice = getLastPrice(c.prices);
            //     if (!selectedCampaign.settings?.stock_including_for_map && lastPrice &&  !lastPrice.stock)
            //     {
            //         c.groupName = "NotAvailable";
            //         c.groupColor = "";
            //     }
            //     else if(lastPrice && lastPrice.value < mapPrice)
            //     {
            //         c.groupName = "ViolatesMap";
            //         c.groupColor = "drop_shipping";
            //     }
            //     else if(lastPrice && mrpPrice > 0 && lastPrice.value > mrpPrice)
            //     {
            //         c.groupName = "ViolatesMrp";
            //         c.groupColor = "drop_shipping";
            //     }
            //     else if(!lastPrice){
            //         c.groupName = "NotAvailable";
            //         c.groupColor = "";
            //     }
            //     else
            //     {
            //         c.groupName = "OK";
            //         c.groupColor = "former";
            //     }
            // });
        }
        else{
            const myPrice = selectedProduct.competitors.find(c=>c.is_mine) && getLastPrice(selectedProduct.competitors.find(c=>c.is_mine).prices) ? getLastPrice(selectedProduct.competitors.find(c=>c.is_mine).prices).value : 0;

            // competitors.forEach(c =>{
            //     const lastPrice = getLastPrice(c.prices);
            //     if (lastPrice && !lastPrice.stock)
            //     {
            //         c.groupName = "NotAvailable";
            //         c.groupColor = "";
            //     }
            //     else if(lastPrice && lastPrice.value < myPrice)
            //     {
            //         c.groupName = "Cheap";
            //         c.groupColor = "var(--pink-300)";
            //     }
            //     else if(lastPrice && lastPrice.value === myPrice)
            //     {
            //         c.groupName = "Same";
            //         c.groupColor = "";
            //     }
            //     else if(lastPrice && lastPrice.value > myPrice)
            //     {
            //         c.groupName = "Expensive";
            //         c.groupColor = "var(--green-300)";
            //     }
            //     else
            //     {
            //         c.groupName = "OK";
            //         c.groupColor = "var(--pink-300)";
            //     }
            // });
        }


        if (showCampaignInactiveCompetitors)
            return competitors;
        else
            return competitors.filter(c=>{
                if (selectedCampaign.new_seller_automatically_activated) {
                    let hostname = getHostName(c.product_url)
                    return !selectedCampaign.inactive_sellers?.has(c.name) && !selectedCampaign.inactive_sellers?.has(hostname);
                } else {
                    let hostname = getHostName(c.product_url)
                    return selectedCampaign.active_sellers?.has(c.name) || selectedCampaign.active_sellers?.has(hostname);
                }
            })
    };

    let competitors = getCompetitors();

    const variantsTemplate = (rowData) => {
        let variantList = null;
        if (rowData.properties !== undefined && rowData.properties !== null) {
            variantList = rowData.properties.map(property => {
                return <React.Fragment><div><b>{property.name + " : "}</b>{property.value}</div></React.Fragment>;
            });
        }

        return <div className="">
            <span className="p-column-title">{t('product_detail.variants')}</span>
            {variantList}
        </div>;
    };
    const variantPriceTemplate = (rowData) =><div className="p-text-right">
        <span className="p-column-title">{t('product_detail.price')}</span>
        {formatCurrency(rowData.price, rowData.currency)}
    </div>;
    const variantStockTemplate = (rowData) => <div className="p-text-right">
        <span className="p-column-title">{t('product_detail.stock')}</span>
        {rowData.stock ? <i style={{color:"lightgreen"}} className="pi pi-check-circle" /> : <i style={{color:"red"}} className="pi pi-times-circle" />}
    </div>;

    const selectedVariantRow = () =>{
        return selectedCompetitor.variants.find(variant => JSON.stringify(variant.properties) === JSON.stringify(selectedCompetitor.selected_variant));
    };

    const onChange = (e) =>{
        const {name, value} = e.target;
        let error = urlValidator(value, t);
        setProductUrl(value);
        setFormErrors({[name]: error});
    };

    const onEnter = (e) => e.key === "Enter" && addCompetitor();

    const showDiscountType = () => {
        return selectedCampaign?.settings?.special_fields?.some(sf=> sf.name === "competitors.discount_type" && sf.selected) ;
    } ;

    const showDiscountRate = () => {
        return selectedCampaign?.settings?.special_fields?.some(sf=> sf.name === "competitors.prices.discount_rate" && sf.selected) ;
    } ;

    const showShippingPrices = () => {
        return !isShippingPricesHandledSeparately(selectedCampaign) && selectedProduct.competitors.some(c=>
            c.shipping_prices && c.shipping_prices.length > 0
        );
    } ;
    // const showReviews = () => {
    //     return selectedCampaign?.settings?.special_fields?.some(sf=> sf.name === "competitors.prices.rating" && sf.selected) && selectedProduct.competitors.some(c=>
    //         c.prices && c.prices.length > 0 && c.prices[c.prices.length - 1].rating
    //     );
    // } ;

    const decisionColor = (rowData) => {
        if (rowData.groupColor)
        {
            let o = {};
            o[rowData.groupColor] = true;
            return o;
        }
        else if (rowData.is_drop_shipping)
            return { 'drop_shipping' : true };
        else if (!rowData.is_active)
            return { 'deactivated' : true };
        return "";
    };

    const onCompetitorChange = (e) => {
        const { key, value } = getKeyValue(e);
        setSelectedCompetitor({...selectedCompetitor, [key] : value});
    };

    const onNewPriceChange = (e) => {
        const { key, value } = getKeyValue(e);
        setNewPrice({...newPrice, [key] : value});
    }

    const modalYesNo = (
        selectedCompetitor &&
        competitors.filter(p=> (selectedCompetitor.product_url === p.product_url || selectedCompetitor.product_url === p.parent_url ||
            (selectedCompetitor.parent_url && (selectedCompetitor.parent_url === p.product_url || selectedCompetitor.parent_url === p.parent_url))
        )).filter(p=>p.is_mine).length > 0 ?
            <Button label={t('yes_no.ok')} icon="pi pi-check" className="p-button-info" onClick={() => {setShowDeleteConfirmation(false); setSelectedCompetitor(null);}} />
        :
        <div>
            {selectedCompetitor && competitors.filter(p=> (selectedCompetitor.product_url === p.product_url || selectedCompetitor.product_url === p.parent_url ||
            (selectedCompetitor.parent_url && (selectedCompetitor.parent_url === p.product_url || selectedCompetitor.parent_url === p.parent_url))
            )).length > 1 &&
            <Message className="p-text-left" severity="warn" text={t('dialogs.competitor.delete_msg_all')}/>}

            <Button label={t('yes_no.no')} icon="pi pi-times" className="p-button-warning" onClick={() => {setShowDeleteConfirmation(false); setSelectedCompetitor(null);}} />
            <Button label={t('yes_no.yes')} icon="pi pi-check" className="p-button-danger" onClick={deleteCompetitor} />
        </div>
    );

    return(
        <React.Fragment>
            <Sidebar showCloseIcon={false} visible={dialogVisible} style={{width:'25em', overflow: "scroll"}} position="right" onHide={()=>{setDialogVisible(false);}}>
                <h3>{t('product_detail.add_competitor')}</h3>
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputText id="product_url" name='product_url' value={product_url} onChange={onChange} onKeyDown={onEnter}
                                       className={formErrors.product_url.length > 0 ? "p-error" : null} autoFocus={true}/>
                            <label htmlFor="product_url">{t('product_detail.competitor_url')}</label>
                        </span>
                        {formErrors.product_url.length > 0 && (<Message severity="error" text={formErrors.product_url}/>)}
                    </div>
                    <div className="p-col-6">
                        <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={()=>{setDialogVisible(false)}}/>
                    </div>
                    <div className="p-col-6">
                        <Button id="add" label={t('actions.add')} icon="pi pi-plus" className="p-button-outlined p-button-success" onClick={addCompetitor}/>
                    </div>
                </div>
            </Sidebar>

            {selectedCompetitor && showEditModal &&
                <Dialog visible={showEditModal} header={t('product_detail.edit_competitor') + ' - ' + selectedCompetitor.name} style={{maxWidth: '60vw', minWidth: '30vw'}} modal={true} onHide={() => {setShowEditModal(false); setSelectedCompetitor(null);}}>
                    <div className="p-grid p-fluid alignCenter">
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputNumber id="multiplier" name="multiplier" value={selectedCompetitor.multiplier} style={{width:"200px"}}
                                             mode="decimal" minFractionDigits={1}
                                             onValueChange={onCompetitorChange}/>
                                <label htmlFor="multiplier">{t('product_detail.multiplier')}</label>
                            </span>
                        </div>
                        {showDiscountType() &&
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="discount_type" name="discount_type" value={selectedCompetitor.discount_type} style={{width:"200px"}}
                                             onChange={onCompetitorChange}/>
                                <label htmlFor="discount_type">{t('product.discount_type')}</label>
                            </span>
                        </div>}
                        {showShippingPrices() &&
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputNumber id="manual_shipping_price" name="manual_shipping_price" value={selectedCompetitor.manual_shipping_price} style={{width:"200px"}}
                                             mode="decimal" minFractionDigits={1}
                                             onValueChange={onCompetitorChange}/>
                                <label htmlFor="manual_shipping_price">{t('product_detail.shipping_price')}</label>
                            </span>
                        </div>}
                        {showShippingPrices() &&
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputNumber id="manual_shipping_price_threshold" name="manual_shipping_price_threshold" value={selectedCompetitor.manual_shipping_price_threshold} style={{width:"200px"}}
                                             mode="decimal" minFractionDigits={1}
                                             onValueChange={onCompetitorChange}/>
                                <label htmlFor="manual_shipping_price_threshold">{t('product_detail.shipping_price_threshold')}</label>
                            </span>
                        </div>}
                        <div className="p-col-6">
                            <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={()=>{setShowEditModal(false);}}/>
                        </div>
                        <div className="p-col-6">
                            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={updateCompetitor}/>
                        </div>
                    </div>
                </Dialog>
            }

            {newPrice && showAddPriceModal &&
                <Dialog visible={true} header={t('product_detail.edit_competitor') + ' - ' + selectedCompetitor.name} style={{maxWidth: '60vw', minWidth: '30vw'}} modal={true} onHide={() => {setShowAddPriceModal(false); setNewPrice(null);}}>
                    <div className="p-grid p-fluid alignCenter">
                        <div className="p-col-12">
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputNumber id="added_price" name="added_price" value={newPrice.added_price} style={{width:"200px"}}
                                             mode="decimal" minFractionDigits={1}
                                             onValueChange={onNewPriceChange}/>
                                <label htmlFor="price">{t('product_detail.price')}</label>
                            </span>
                        </div>

                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputNumber id="added_sale_price" name="added_sale_price" value={newPrice.added_sale_price} style={{width:"200px"}}
                                             mode="decimal" minFractionDigits={1}
                                             onValueChange={onNewPriceChange}/>
                                <label htmlFor="sale_price">{t('product.list_price')}</label>
                            </span>
                        </div>

                        <div className="p-col-12">
                            <span className="p-float-label">
                                <span className="p-float-label">
                                    <Dropdown id="added_currency" name="added_currency" value={newPrice.added_currency} style={{width:"200px"}} options={currencies} filter showClear onChange={onNewPriceChange}/>
                                    <label htmlFor="currency">{t('campaign.currency')}</label>
                                </span>
                            </span>
                        </div>

                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputNumber id="added_stock" name="added_stock" value={newPrice.added_stock ? newPrice.added_stock : 0} style={{width:"200px"}}
                                             mode="decimal" minFractionDigits={0}
                                             onValueChange={onNewPriceChange}/>
                                <label htmlFor="stock">{t('product.stock')}</label>
                            </span>
                        </div>

                        <div className="p-col-12">
                            <span className="p-float-label">
                                <span className="p-float-label">
                                    <Calendar id="added_date" name="added_date" readOnlyInput={true} value={newPrice.added_date} onChange={onNewPriceChange} showIcon={true} dateFormat="yy-mm-dd" style={{marginRight:'0.5em', width:"200px"}} />
                                    <label htmlFor="added_date">{t('product_detail.date')}</label>
                                </span>
                            </span>
                        </div>

                        <div className="p-col-6">
                            <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => {setShowAddPriceModal(false); setNewPrice(null);}}/>
                        </div>
                        <div className="p-col-6">
                            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={addCompetitorPrice}/>
                        </div>
                    </div>
                </Dialog>
            }

            {selectedCompetitor && showViolationActionModal &&
            <Dialog visible={showViolationActionModal} header={t('price_violation.actions_taken') + ' - ' + selectedCompetitor.name} style={{maxWidth: '60vw', minWidth: '30vw'}} modal={true} onHide={() => {setShowViolationActionModal(false); setSelectedCompetitor(null);}}>
                <CompetitorViolationAction selectedProduct={selectedProduct} selectedCompetitor={selectedCompetitor} />
            </Dialog>
            }

            <Dialog visible={showVariants} header={t('product_detail.select_variants')} style={{maxWidth: '60vw', minWidth: '20vw'}} modal={true} onHide={()=>{setShowVariants(false);}}>
                {selectedCompetitor && showVariants ?

                    <DataTable
                        value={selectedCompetitor.variants}
                        responsive={true}
                        selectionMode="single"
                        selection={selectedVariantRow()}
                        onSelectionChange={matchVariant}
                        resizableColumns={true} columnResizeMode="fit">
                        <Column body={IndexTemplate} headerStyle={{width:'3em'}} />
                        <Column header={t('product_detail.variants')} body={variantsTemplate} headerStyle={{width:'60%'}}/>
                        <Column header={t('product_detail.price')} field="price" body={variantPriceTemplate} headerStyle={{textAlign:"right", width:'20%'}}/>
                        <Column header={t('product_detail.stock')} field="stock" body={variantStockTemplate} headerStyle={{textAlign:"right", width:'20%'}}/>
                    </DataTable>:""
                }
            </Dialog>


            <OverlayPanel ref={op} dismissable={true}>
                {detailData &&
                <span>
                    {/*{detailData.product_name}*/}
                    {/*{(detailData.selected_variant && detailData.selected_variant.length !== 0) &&*/}
                    {/*detailData.selected_variant.map(property => {*/}
                    {/*    return <div>*/}
                    {/*        <b>{property.name + " : "}</b>{property.value}*/}
                    {/*    </div>;*/}
                    {/*})*/}
                    {/*}*/}
                    <img referrerPolicy='no-referrer' className="img-fluid-thumb" src={detailData.product_image} alt=""/>
                    {detailData.prices !== null && detailData.prices.length > 0 && <img referrerPolicy='no-referrer' className="img-fluid-thumb" src={detailData.prices[detailData.prices.length - 1]?.promotion_image_url} alt=""/>}
                </span> }
            </OverlayPanel>

            <div className="p-grid p-justify-end">
                <Button tooltip={t('product_detail.show_campaign_based_deactivated_sellers')} tooltipOptions={{position: 'bottom'}} icon="pi pi-eye" className={showCampaignInactiveCompetitors ? "p-button-warning p-mr-1" : "p-button-outlined p-button-warning p-mr-1"} onClick={() => setShowCampaignInactiveCompetitors(!showCampaignInactiveCompetitors)}/>
                <Button tooltip={t('actions.bulk_operations')} tooltipOptions={{position: 'bottom'}} icon="pi pi-sort-alt" className="p-button-outlined p-button-warning p-mr-1" onClick={() => setShowBulkOperations(true)}/>
                <Button id="add" label={t('product_detail.add_competitor')} icon="pi pi-plus" className="p-button-outlined p-button-success" onClick={addDialog}/>
            </div>

            <DataTable value={competitors}
                       //rowGroupMode="subheader"
                       //groupField="groupName"
                       //rowGroupHeaderTemplate={groupHeaderTemplate}
                       //rowGroupFooterTemplate={groupFooterTemplate}
                       // expandableRowGroups={true}
                       responsive={true}
                       expandedRows={expandedCompetitor}
                       onRowToggle={(e) => {
                           setExpandedCompetitor(e.data);
                       }}
                       onSelectionChange={(e) => {
                           if(!isPreventSelect(e.originalEvent.target))
                               setExpandedCompetitor(e.value)
                       }}
                       selection={expandedCompetitor}
                       selectionMode="multiple"
                       metaKeySelection={false}
                       rowClassName={decisionColor}
                       rowExpansionTemplate={competitorRowExpansionTemplate}
                       resizableColumns={true} columnResizeMode="fit">
                <Column expander={true} style={{width:"3.3em"}}/>
                <Column body={IndexTemplate} headerStyle={{width:'3em'}} />
                <Column header={t('table_headers.name')} field="competitorUrl" body={competitorProductUrlTemplate} headerStyle={{width:'40%'}}/>
                <Column header={t('table_headers.last_check')} headerStyle={{textAlign:"left", width:'20%'}}  field="lastModified" body={competitorLastModifiedTemplate}/>
                {showDiscountType() && <Column header={t('product.discount_type')} field="discount_type" headerStyle={{width: '20%'}}/>}
                <Column header={t('product_detail.price')} headerStyle={{textAlign:"center", width:'14%'}} field="price" body={competitorPriceTemplate}/>
                {showDiscountRate() && <Column header={t('product.discount_rate')} headerStyle={{textAlign:"center", width:'10%'}} field="discount_rate" body={competitorDiscountRateTemplate}/>}
                {showShippingPrices() && <Column header={t('product_detail.shipping_price')} headerStyle={{textAlign:"right", width: '14%'}} field="shipping_price" body={competitorShippingPriceTemplate}/>}
                <Column header={t('product_detail.stock')} headerStyle={{textAlign:"center", width:'9%'}} field="stock" body={competitorStockTemplate} />
                {isCampaignManufacturer(selectedCampaign) && <Column header={t('price_violation.days')} headerStyle={{textAlign:"center", width:'9%'}} body={violationDaysTemplate}/>}

                {/*{showReviews() && <Column header={t('product_detail.reviews')} headerStyle={{textAlign:"right", width: '14%'}}  field="reviews" body={competitorReviewsTemplate}/>}*/}
                {/*<Column header={t('table_headers.scraping_status')} className="preventSelect" headerStyle={{textAlign:"right", width:'10.5em'}} body={competitorStatusTemplate} />*/}

                {selectedProduct.pricing_strategy &&
                selectedProduct.pricing_strategy.ignore_some_competitors &&
                <Column header={t('product_detail.repricing')} body={dynamicPricingActiveTemplate} className="preventSelect" headerStyle={{textAlign:"right", width:'5em'}}/>}
                <Column header={t('table_headers.action')} body={competitorActionTemplate} className="preventSelect" headerStyle={{textAlign:"left", width:'17em'}}/>
                {/*{!isDesktop() && <Column expander={true} style={{width:'3em'}}/>}*/}
            </DataTable>

            {showBulkOperations &&
            <CompetitorBulkOperations selectedProduct={selectedProduct}
                                      changeCompetitorIsDynamicPricingActive={props.changeCompetitorIsDynamicPricingActive}
                                      changeCompetitorIsActive={props.changeCompetitorIsActive}
                                      deleteCompetitor={props.deleteCompetitor}
                                      hideDialog={() => {setShowBulkOperations(false);}} />
            }

            {showDeleteConfirmation &&
            <Dialog header={t('dialogs.competitor.delete')} footer={modalYesNo} visible={true} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setShowDeleteConfirmation(false); setSelectedCompetitor(null);}}>
                {
                    competitors.filter(p=> (selectedCompetitor.product_url === p.product_url || selectedCompetitor.product_url === p.parent_url ||
                        (selectedCompetitor.parent_url && (selectedCompetitor.parent_url === p.product_url || selectedCompetitor.parent_url === p.parent_url))
                    )).filter(p=>p.is_mine).length > 0 ? <Message severity="info" text={t('dialogs.competitor.cannot_delete_msg')}/> :
                        (competitors.filter(p=> (selectedCompetitor.product_url === p.product_url || selectedCompetitor.product_url === p.parent_url ||
                        (selectedCompetitor.parent_url && (selectedCompetitor.parent_url === p.product_url || selectedCompetitor.parent_url === p.parent_url))
                    )).length > 1 ?
                    <React.Fragment>
                        <DataTable value={competitors.filter(p=> (selectedCompetitor.product_url === p.product_url || selectedCompetitor.product_url === p.parent_url ||
                                (selectedCompetitor.parent_url && (selectedCompetitor.parent_url === p.product_url || selectedCompetitor.parent_url === p.parent_url)))
                        )}
                                   responsive={true}
                                   resizableColumns={true} columnResizeMode="fit">
                            <Column body={IndexTemplate} headerStyle={{width:'3em'}} />
                            <Column header={t('table_headers.name')} field="competitorUrl" body={competitorProductUrlTemplate} headerStyle={{width:'45%'}}/>
                            <Column header={t('table_headers.last_check')} headerStyle={{textAlign:"left", width:'25%'}}  field="lastModified" body={competitorLastModifiedTemplate}/>
                            <Column header={t('product_detail.price')} headerStyle={{textAlign:"center", width:'18%'}} field="price" body={competitorPriceTemplate}/>
                            <Column header={t('product_detail.stock')} headerStyle={{textAlign:"center", width:'12%'}} field="stock" body={competitorStockTemplate} />
                        </DataTable>
                    </React.Fragment>
                    :
                    <Message severity="warn" text={t('dialogs.competitor.delete_msg')}/>)
                }
            </Dialog>}
        </React.Fragment>
    );

};
export default Competitor;
