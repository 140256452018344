import React, {useState, useEffect} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

import {AuthService} from "../../service/AuthService";




import {formValid, getKeyValue, isAdmin, user, userId} from "../../utils/Utils";
import {Message} from "primereact/message";
import {InputMask} from "primereact/inputmask";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCcAmex, faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";
import {RadioButton} from "primereact/radiobutton";
import {Dialog} from "primereact/dialog";
import {AccountService} from "../../service/AccountService";

import {AppContext} from "../../HomePage";
import {Tooltip} from "../../utils/InlineComponents";


const PaymentMethod = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [current_payment_method, set_current_payment_method] = useState({});
    const [payment_method, set_payment_method] = useState({
                method_type: "",
                first_name: "",
                last_name: "",
                card_number: null,
                cvv: null,
                expiry_month: null,
                expiry_year: null,
            });
    const [formErrors, setFormErrors] = useState({
                method_type: "",
                first_name: "",
                last_name: "",
                card_number: "",
                cvv: "",
                expiry_month: "",
                expiry_year: "",
            });

    const [editPaymentMethod, setEditPaymentMethod] = useState(false);
    const [showManuelCreditCart, setShowManuelCreditCart] = useState(false);
    const [showMoneyOrder, setShowMoneyOrder] = useState(false);


    const accountService = new AccountService();

    useEffect(() =>{
        if (props.payment_method)
        {
            let payment_method = Object.assign({}, props.payment_method);
            let showManuelCreditCart = false, showMoneyOrder = false;

            if (payment_method.method_type === "manuel_credit_card" || isAdmin())
                showManuelCreditCart = true;
            if (payment_method.method_type === "money_order" || isAdmin())
                showMoneyOrder = true;

            set_current_payment_method(payment_method);
            set_payment_method({...payment_method, card_number : ""});
            setShowManuelCreditCart(showManuelCreditCart);
            setShowMoneyOrder(showMoneyOrder);
        }
        else {
            set_current_payment_method({...payment_method, user_id: userId()});
            set_payment_method({...payment_method, user_id: userId(), method_type: "credit_card"});

            if (isAdmin()) {
                setShowManuelCreditCart(true);
                setShowMoneyOrder(true);
            }
        }
    }, []);

    const save = () => {
        checkErrors(payment_method).then(formErrors=> {
            if (formValid(formErrors)) {

                if (payment_method.method_type === "money_order")
                {
                    payment_method.card_number = null;
                    payment_method.cvv = null;
                    payment_method.expiry_month = null;
                    payment_method.expiry_year = null;
                }

                accountService.savePaymentMethod(payment_method).then(saved_payment_method=>{
                    set_current_payment_method(saved_payment_method);
                    set_payment_method(saved_payment_method);
                    setEditPaymentMethod(false);
                    props.onDataChange(saved_payment_method);
                    toast.current.show({severity: 'success', summary: t('actions.save_payment_method'), detail: t('message_detail.successful')});
                }).catch(error => {
                    setError(error);
                    setError(null);
                });
            }
            else
                setFormErrors(formErrors);
        });
    };

    const convert_to_money_order = () => {
        let temp_payment_method = {"id": payment_method.id, "method_type": "money_order"};

        accountService.savePaymentMethod(temp_payment_method).then(saved_payment_method=>{
            set_current_payment_method(saved_payment_method);
            set_payment_method(saved_payment_method);
            setEditPaymentMethod(false);
            props.onDataChange(saved_payment_method);
            toast.current.show({severity: 'success', summary: t('actions.save_payment_method'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
        });
    };

    const convert_to_credit_card = () => {

        let temp_payment_method = {"id": payment_method.id, "method_type": "credit_card"};

        accountService.savePaymentMethod(temp_payment_method).then(saved_payment_method=>{
            set_current_payment_method(saved_payment_method);
            set_payment_method(saved_payment_method);
            setEditPaymentMethod(false);
            props.onDataChange(saved_payment_method);
            toast.current.show({severity: 'success', summary: t('actions.save_payment_method'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
        });
    };

    const onChange = (e) => {
        const { key, value } = getKeyValue(e);
        set_payment_method({...payment_method, [key]: value})
        setFormErrors({...formErrors, [key]: checkError(key, value)});
    };


    const checkError = (key, value) => {
        let errorText = "";

        if (key === "first_name") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "last_name") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "card_number") {
            errorText = payment_method.method_type !== "money_order" &&  (value === "" || (value.length >16 && value.length <15)) ? t('validations.invalid_value') : "";
        }
        else if (key === "cvv") {
            errorText = payment_method.method_type !== "money_order" && (value === "" || (value.length >4 && value.length <3)) ? t('validations.invalid_value') : "";
        }
        else if (key === "expiry_month") {
            errorText = payment_method.method_type !== "money_order" && (value === "" || parseInt(value) < 1 || parseInt(value) > 12) ? t('validations.invalid_value') : "";
        }
        else if (key === "expiry_year") {
            errorText = payment_method.method_type !== "money_order" && (value === "" || parseInt(value) < new Date().getFullYear() - 2001 || parseInt(value) > new Date().getFullYear() - 1991)
                ? t('validations.invalid_value') : "";
        }
        return errorText;
    };

    const checkErrors = async (payment_method) => {
        let errors = { ...formErrors };
        Object.entries(payment_method).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });
        return errors;
    };



    const footer = (
        <React.Fragment>
            {isAdmin() &&
                <Button label={"Convert to " + t('user.credit_card')} icon="pi pi-credit-card" className="p-button-outlined p-button-danger" onClick={convert_to_credit_card}/>
            }
            {isAdmin() &&
                <Button label={"Convert to " +t('user.money_order')} icon="pi pi-money-bill" className="p-button-outlined p-button-danger" onClick={convert_to_money_order}/>
            }
            <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => {setEditPaymentMethod(false);}}/>
            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save}/>
        </React.Fragment>);


    const text = current_payment_method.method_type === "credit_card" ? t('subscription.payment.credit_card_message')
        : current_payment_method.method_type === "manuel_credit_card" ? t('subscription.payment.manuel_credit_card_message')
            : current_payment_method.method_type === "money_order" ? t('subscription.payment.money_order') : null

    return <React.Fragment>



        <div className="p-grid card" style={{borderBottom: "1px solid black"}}>
            <div className="p-col">
                <h4><FontAwesomeIcon icon={faCreditCard} /> {t('subscription.payment_method')}</h4>
            </div>
            <div className="p-col-fixed" style={{width:'8em'}}>
                <Button label={t('actions.edit')} icon="pi pi-pencil" className="p-button-outlined" onClick={() => {setEditPaymentMethod(true);}}/>
            </div>
        </div>

        {current_payment_method && current_payment_method.method_type &&
        <div className="p-grid">
            <div className="p-col-12">{current_payment_method.card_number} { text && <Tooltip tooltip={text}/>}</div>
        </div>}


        {/*{current_payment_method && (current_payment_method.method_type === "manuel_credit_card" || (isAdmin() &&current_payment_method.method_type === "money_order") ) &&*/}
        {/*    <div className="p-col-12">*/}
        {/*        <Button label={current_payment_method.method_type === "manuel_credit_card" ? t('actions.pay') : t('actions.renew_subscription')} icon="pi pi-money-bill" className="p-button-outlined p-button-success" onClick={renew}/>*/}
        {/*    </div>*/}
        {/*}*/}


        {editPaymentMethod &&

        <Dialog visible={true} header={t('subscription.payment_method')} footer={footer} modal={true} onHide={() => {setEditPaymentMethod(false);}} >

            <div className="p-grid alignCenter p-fluid">
                {(showManuelCreditCart || showMoneyOrder) &&
                    <div className="p-col-12">
                        <RadioButton inputId="method_type_credit_card" value="credit_card" name="method_type" onChange={onChange} checked={payment_method.method_type === "credit_card"}/>
                        <label htmlFor="method_type_credit_card" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.credit_card')}</label>
                        {showManuelCreditCart && <RadioButton inputId="method_type_manuel_credit_card" value="manuel_credit_card" name="method_type" onChange={onChange} checked={payment_method.method_type === "manuel_credit_card"}/>}
                        {showManuelCreditCart && <label htmlFor="method_type_manuel_credit_card" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.manuel_credit_card')}</label>}
                        {showMoneyOrder && <RadioButton inputId="method_type_money_order" value="money_order" name="method_type" onChange={onChange} checked={payment_method.method_type === "money_order"}/>}
                        {showMoneyOrder && <label htmlFor="method_type_money_order" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.money_order')}</label>}
                    </div>}
                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText id="first_name" name="first_name" value={payment_method.first_name} onChange={onChange}
                                   className={formErrors.first_name.length > 0 ? "p-error" : null}/>
                        <label htmlFor="first_name">{t('user.first_name')}</label>
                    </span>
                    {formErrors.first_name.length > 0 && (<Message severity="error" text={formErrors.first_name}/>)}
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText id="last_name" name="last_name" value={payment_method.last_name} onChange={onChange}
                                   className={formErrors.last_name.length > 0 ? "p-error" : null}/>
                        <label htmlFor="last_name">{t('user.last_name')}</label>
                    </span>
                    {formErrors.last_name.length > 0 && (<Message severity="error" text={formErrors.last_name}/>)}
                </div>
                {payment_method.method_type !== "money_order" && <div className="p-grid p-col-12 p-nogutter">
                    <div className="p-col">
                        <span className="p-float-label">
                            <InputMask mask="9999-9999-9999-999?9" id="card_number" name="card_number" value={payment_method.card_number} onChange={onChange}
                                       className={formErrors.card_number.length > 0 ? "p-error" : null}/>
                            <label htmlFor="card_number">{t('user.card_number')}</label>
                        </span>
                    </div>
                    <div className="p-col-fixed" style={{width:"3.1em", marginLeft:"0.2em"}}>
                        <FontAwesomeIcon style={{color:"gray", fontSize:"2.7em", marginTop:"0.1em"}} icon={payment_method.card_number ?
                            (payment_method.card_number.startsWith("3") ? faCcAmex :
                                payment_method.card_number.startsWith("4") ? faCcVisa :
                                    payment_method.card_number.startsWith("5") ? faCcMastercard : faCreditCard) : faCreditCard} />
                    </div>
                    {formErrors.card_number.length > 0 && <div className="p-col-12">
                        {formErrors.card_number.length > 0 && (<Message severity="error" text={formErrors.card_number}/>)}
                    </div>}
                </div>}
                {payment_method.method_type !== "money_order" && <div className="p-col-12">
                    <span className="p-float-label">
                        <InputMask mask="9?9" id="expiry_month" name="expiry_month" value={payment_method.expiry_month ? payment_method.expiry_month.toString() : ""} onChange={onChange}
                                   className={formErrors.expiry_month.length > 0 ? "p-error" : null}/>
                        <label htmlFor="expiry_month">{t('user.expiry_month')}</label>
                    </span>
                    {formErrors.expiry_month.length > 0 && (<Message severity="error" text={formErrors.expiry_month}/>)}
                </div>}
                {payment_method.method_type !== "money_order" && <div className="p-col-12">
                    <span className="p-float-label">
                        <InputMask mask="99" id="expiry_year" name="expiry_year" value={payment_method.expiry_year ? payment_method.expiry_year.toString() : ""} onChange={onChange}
                                   className={formErrors.expiry_year.length > 0 ? "p-error" : null}/>
                        <label htmlFor="expiry_year">{t('user.expiry_year')}</label>
                    </span>
                    {formErrors.expiry_year.length > 0 && (<Message severity="error" text={formErrors.expiry_year}/>)}
                </div>}
                {payment_method.method_type !== "money_order" && <div className="p-col-12">
                    <span className="p-float-label">
                        <InputMask mask="999?9" id="cvv" name="cvv" value={payment_method.cvv} onChange={onChange}
                                   className={formErrors.cvv.length > 0 ? "p-error" : null}/>
                        <label htmlFor="cvv">{t('user.cvv')}</label>
                    </span>
                    {formErrors.cvv.length > 0 && (<Message severity="error" text={formErrors.cvv}/>)}
                </div>}


            </div>
        </Dialog>}
    </React.Fragment>;
};
export default PaymentMethod;
