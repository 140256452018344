import React, {useState} from 'react';
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import {Dialog} from 'primereact/dialog';
import {Message} from 'primereact/message';

import {
    getKeyValue, getLinkFromUrl, getProductDetailLink, isAdmin,
    isPreventSelect,
    toTitleCase,
    userId
} from "../../utils/Utils";
import {getWeeksAgo, toDateString, toDateTimeString} from "../../utils/TimeUtil";
import {IndexTemplate} from "../../utils/InlineComponents";

import {ErrorService} from "../../service/ErrorService";
import {Panel} from "primereact/panel";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {AppContext} from "../../HomePage";
import {job_states} from "../../utils/Constants";

export const error_states = (t) => {

    return  [
        {label: t('actions.all'), value: null},
        {label: t('scraping_request.statuses.todo'), value: job_states.todo},
        {label: t('scraping_request.statuses.in_progress'), value: job_states.in_progress},
        {label: t('scraping_request.statuses.done'), value: job_states.done}];
};


const Error = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [errors, setErrors] = useState([]);
    const [selectedError, setSelectedError] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const [filters, setFilters] = useState({
        dates: [getWeeksAgo(1), new Date()],
        state: "todo"
    });
    const [commentText, setCommentText] = useState("");

    const errorService = new ErrorService();

    const search = () => {
        if (filters.dates[0] && filters.dates[1]) {
            errorService.listErrors(filters.state, toDateString(filters.dates[0]), toDateString(filters.dates[1])).then(data => {
                setErrors(data);
            }).catch(error => {
                setError(error);
                setError(null);
            });
        }
    }

    const updateError = (id, state) => {
        errorService.updateError(id, state).then(data => {
            errors.find(e => e.id === id).state = state;
            setCommentText("");
        }).catch(error => {
            setError(error);
            setError(null);
        });
    };

    const addComment = (id) => {
        if (commentText !== "") {
            errorService.addComment(id, commentText).then(data => {
                errors.find(e => e.id === id).comments = data.comments;
                setCommentText("");
            }).catch(error => {
                setError(error);
                setError(null);
            });
        }
    }

    const deleteComment = (id, comment_id) => {
        errorService.deleteComment(id, comment_id).then(() => {
            errors.find(e=>e.id === id).comments.splice(errors.find(e=>e.id === id).comments.findIndex(c=> c.id===comment_id), 1);
            setCommentText(" ");
            setCommentText("");
            toast.current.show({severity: 'success', summary: t('error.delete_comment'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    // const save = () => {
    //     checkErrors(selectedError).then(formErrors=> {
    //         if (formValid(formErrors)) {
    //
    //         }
    //         else
    //             setFormErrors(formErrors);
    //     });
    //
    // };




    // const deleteError = () => {
    //     errorService.deleteError(selectedError.id).then(() => {
    //         // let filteredCampaigns = campaigns.filter(c => c.id !== campaign.id);
    //         //
    //         // setCampaigns(filteredCampaigns);
    //         // setShowDeleteModal(false);
    //
    //         toast.current.show({severity: 'success', summary: t('campaign.delete_campaign'), detail: t('message_detail.successful')});
    //     }).catch(error =>{
    //         setError(error);
    //         setError(null);
    //     });
    // }

    const onChange = (e) =>{

        const { key, value } = getKeyValue(e);

        setFilters({...filters, [key] : value});
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0 ? "p-error" : null
    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "name") {
            errorText =  value.length > 0 ? "" : t('validations.invalid_name');
        }

        return errorText;
    }

    const checkErrors = async (selectedError) => {

        let errors = { ...formErrors };

        Object.entries(selectedError).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        return errors;
    };

    const relatedItemTemplate = (rowData) => {
        return <div>
            {rowData.campaign && <span>{t('campaign.campaign') + " : " + rowData.campaign.name}</span>}
            {rowData.product_id && getProductDetailLink(rowData.product_id, t('product.product'))}
            {rowData.competitor_url && getLinkFromUrl(rowData.competitor_url)}
        </div>;
    };

    const states = error_states(t).filter(e => e.value);

    const stateTemplate = (rowData) => {
        return (rowData && rowData.state) ? <Dropdown id={rowData.id} value={rowData.state} options={states}
         onChange={(e) => {updateError(rowData.id, e.value)}}
        /> : "";
    };



    const selectError = (e) =>{
        if(!isPreventSelect(e.originalEvent.target))
        {
            setSelectedError(e.value);

            //if (e.value.name)
            //    this.detail(e.value);
            //else
            //    this.toast.show({severity: 'info', summary:  t('actions.wait'), detail:  t('product.gathering_product')});
        }
    }

    // const header = (
    //     <span className="ql-formats">
    //     <button className="ql-bold" aria-label="Bold"/>
    //     <button className="ql-italic" aria-label="Italic"/>
    //     <button className="ql-underline" aria-label="Underline"/>
    // </span>
    // );

    const filteredErrors = () => errors.map(error=> ({ ...error, full_name: toTitleCase(error.user.first_name) + ' ' + toTitleCase(error.user.last_name) }));

    return (
        <React.Fragment>

            <div className="p-grid card">

                <div className="p-col-12">
                    <Panel header={t('product.filter')} toggleable={true}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6 p-lg-3">
                                <span className="">
                                    <label htmlFor="dates">{t('actions.dates')}</label>
                                    <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={filters.dates} onChange={onChange} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em'}}/>
                                </span>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-3">
                                <span className="">
                                    <label htmlFor="state">{t('scraping_request.state')}</label>
                                    <Dropdown id="state" name="state" value={filters.state} options={error_states(t)}
                                              onChange={onChange}
                                              style={{width:'100%'}}/>
                                </span>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-3">
                                <Button id="search" label={t('actions.search')} icon="pi pi-search" onClick={search} style={{marginTop:'1.3em'}}/>
                            </div>

                        </div>
                    </Panel>
                </div>

                <div className="p-col-12">
                    <DataTable value={filteredErrors()}
                               responsive={true}
                               selectionMode="single"
                               onSelectionChange={selectError}
                               selection={selectedError}
                               sortField="no" sortOrder={-1}>
                        <Column body={IndexTemplate} style={{width:'3em'}} />
                        <Column field="no" header={t('error.no')} filter={true}/>
                        {isAdmin() && <Column field="user.email" header={t('user.email')} filter={true} filterMatchMode="contains"/>}
                        {isAdmin() && <Column field="full_name" header={t('user.name')} filter={true} filterMatchMode="contains"/>}
                        {isAdmin() && <Column field="scraping_request.type" header={t('menu_item.scraping_request') + " " + t('scraping_request.type')} filter={true}/>}
                        {isAdmin() && <Column field="scraping_request.state" header={t('menu_item.scraping_request') + " " + t('scraping_request.state')} filter={true}/>}
                        <Column body={relatedItemTemplate} header={t('error.related_item')} filter={true}/>
                        <Column field="type" header={t('common.type')} filter={true}/>
                        <Column field="message" header={t('common.message')} filter={true}/>
                        {!isAdmin() && <Column field="state" header={t('common.state')} filter={true}/>}
                        {isAdmin() && <Column field="state" body={stateTemplate} header={t('common.state')} className="preventSelect" filter={true}/>}
                        <Column field="created_at" header={t('common.created_at')} filter={true}/>
                        <Column field="updated_at" header={t('common.last_op_date')} filter={true}/>
                        {/*<Column body={actionTemplate} className="preventSelect" header={t('table_headers.action')} style={{textAlign:'center', width:'10.5em'}}/>*/}
                    </DataTable>
                </div>

                {selectedError &&
                <Dialog header={t('error.comments')} visible={true} contentStyle={{mingHeight:"250px", maxHeight: '80vh', overflow:"auto"}} style={{maxWidth: '50vw', minWidth: '200px'}} modal={true} onHide={() => setSelectedError(null)}>
                    <div className="p-grid">
                        <div className="speech-wrapper" style={{backgroundImage: "url(/assets/layout/images/message_back.jpg)"}} >
                            {selectedError.comments && selectedError.comments.length > 0 ? selectedError.comments.map(c=>{
                                const alt = c.user.id === userId() ? " alt" : ""
                                return <div className="p-grid p-col-12">
                                    {alt !== "" && <div className="p-col-fixed" style={{width:'5em'}}/>}
                                    <div key={c.id} className={"p-col bubble" + alt}>
                                        <div className={"txt" + alt}>
                                            <p className={"name" + alt}>{toTitleCase(c.user.first_name)}
                                                {isAdmin() && <Button tooltip={t('actions.remove')} icon="pi pi-trash" className="p-button-outlined p-button-danger imageButton" onClick={() => deleteComment(selectedError.id, c.id)} />}
                                            </p>
                                            <div className={"message" + alt}> {c.message}</div>
                                            <div className={"timestamp" + alt}>{toDateTimeString(c.created_at)}</div>
                                        </div>
                                        <div className={"bubble-arrow" + alt}/>
                                    </div>
                                    {alt === "" && <div className="p-col-fixed" style={{width:'5em'}}/>}
                                </div>;
                            }): <Message severity="info" text={t('error.no_comment')} />}
                        </div>
                        <div className="p-col">
                            {/*<Editor style={{height:'100px'}} value={commentText} onTextChange={(e) => setCommentText(e.htmlValue)} headerTemplate={header} />*/}
                            <InputTextarea rows={3} style={{width:'100%'}} value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                        </div>
                        <div className="p-col-fixed p-align-end p-justify-end" style={{width:'10em'}}>
                            <Button label={t('actions.comment')} icon="pi pi-comment" className="p-button-outlined p-button-success" onClick={() => addComment(selectedError.id)} />
                        </div>
                    </div>
                </Dialog>}
            </div>
        </React.Fragment>
    );



};
export default Error