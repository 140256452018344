import React, {useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {UserService} from "../../service/UserService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputSwitch} from "primereact/inputswitch";
import {formValid, getKeyValue, toTitleCase} from "../../utils/Utils";
import {toDateTimeString} from "../../utils/TimeUtil";
import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {Message} from "primereact/message";
import {Dialog} from "primereact/dialog";
import {Password} from "primereact/password";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import UpdateUser from "./UpdateUser";
import {AccountService} from "../../service/AccountService";
import {Dropdown} from "primereact/dropdown";
import {AppContext} from "../../HomePage";
import {SelectButton} from "primereact/selectbutton";
import {PricingPlanService} from "../../service/PricingPlanService";
import {InputNumber} from "primereact/inputnumber";
import UserSpecialPlan from "./UserSpecialPlan";


const User = (props) => {

    const {t, toast, setError, navigate} = React.useContext(AppContext);
    let dtUsers = useRef(null);

    const [showEditModal, setShowEditModal] = useState(false);
    const [passwordDialogVisible, setPasswordDialogVisible] = useState(false);
    const [chargeDialogVisible, setChargeDialogVisible] = useState(false);
    const [subscriptionDialogVisible, setSubscriptionDialogVisible] = useState(false);
    const [subscriptionHistoryDialogVisible, setSubscriptionHistoryDialogVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedUserType, setSelectedUserType] = useState("all");
    const [specialPlanDialogVisible, setSpecialPlanDialogVisible] = useState(false);
    const [specialPlan, setSpecialPlan] = useState(null);

    const [users, setUsers] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(0);
    const [standardPricingPlans, setStandardPricingPlans] = useState([]);
    const [selectedStandardPricingPlan, setSelectedStandardPricingPlan] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [editedSubscription, setEditedSubscription] = useState({
        plan_price:"",
        start_at:"",
        finish_at:"",
        total_product_count:"",
        daily_price_check_count:"",
        manuel_price_check_count:"",
        instant_price_check_enabled:"",
        sale_history_enabled:"",
        competitor_suggestion_enabled:"",
        dynamic_pricing_enabled:"",
        is_paid:"",
        is_canceled:"",
        product_variant_tracking_enabled:"",
        daily_notification_enabled:"",
        price_history_enabled:"",
        stock_availability_monitoring_enabled:"",
        product_cost_monitoring_enabled:"",
        instant_notification_enabled:"",
        api_enabled:"",

    });

    const [filter, setFilter] = useState("");
    const [change_password, setChange_password] = useState({
        user_id:"",
        new_password:""
    });

    const [charge, setCharge] = useState({
        user_id:"",
        amount: 0
    });

    const [pricingPlans, setPricingPlans] = useState([]);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [subscriptionHistory, setSubscriptionHistory] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [formErrors, setFormErrors] = useState({
        new_password:"",
        plan_price:"",
        start_at:"",
        finish_at:"",
        total_product_count:"",
        daily_price_check_count:"",
        manuel_price_check_count:"",
        instant_price_check_enabled:"",
        sale_history_enabled:"",
        competitor_suggestion_enabled:"",
        dynamic_pricing_enabled:"",
        is_paid:"",
        is_canceled:""
    });

    const userService = new UserService();
    const accountService = new AccountService();
    const pricingPlanService = new PricingPlanService();

    useEffect(()=>{
        getUsers(false)
    }, [forceRefresh]);

    const getUsers = (all_user) => {
        setLoading(true);

        userService.getUsers(true, true, all_user).then(users=>{
            setUsers(users);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });

        pricingPlanService.getStandardPricingPlans().then(standardPricingPlans=>{
            setStandardPricingPlans(standardPricingPlans.map(item => ({label: item.name, value: item.id})));
        }).catch(error =>{
            setError(error);
            setError(null);
        });

        return () => {
            toast.current.clear();
        }
    }

    const createdAtTemplate = (rowData) => <span>{toDateTimeString(rowData.created_at)}</span>;

    const last_login_atTemplate = (rowData) => <span>{toDateTimeString(rowData.last_login_at)}</span>;

    const is_manufacturerTemplate = (rowData) => {
        return <div>{rowData.is_manufacturer === null ? t('user.market_research') : rowData.is_manufacturer === true ? t('user.manufacturer') : t('user.seller')}</div>;
    };

    const tagsTemplate = (rowData) => {
      return rowData.tags.map(t=>{
          return <div key={t}>{t}</div>;
      })
    };

    // const is_lockedTemplate = (rowData) => {
    //     return <div>
    //         <InputSwitch checked={rowData.is_locked} onChange={() => changeIsLocked(rowData)} tooltip={rowData.is_locked ? t('actions.unlock') : t('actions.lock') } />
    //     </div>;
    // };

    // const is_activeTemplate = (rowData) => {
    //     return <div>
    //         <InputSwitch checked={rowData.is_active} onChange={() => changeIsActive(rowData)} tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } />
    //     </div>;
    // };

    const changeIsLocked = (rowData) => {
        userService.lock_unlock(rowData.id, rowData.is_locked).then(updated_user => {
            const index = users.findIndex(user => user.id === updated_user.id);
            users[index].is_locked = !rowData.is_locked;
            setUsers([...users]);

            toast.current.show({severity: 'success', summary: updated_user.is_locked ? t('actions.locked') : t('actions.unlocked'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    };

    const changeIsActive = (rowData) => {
        userService.active_deactivate(rowData.id, rowData.is_active).then(updated_user => {
            const index = users.findIndex(user => user.id === updated_user.id);
            users[index].is_active = !rowData.is_active;
            setUsers([...users]);

            toast.current.show({severity: 'success', summary: updated_user.is_active ? t('actions.activate') : t('actions.deactivate'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    };

    const changeIsApproved = (rowData) => {
        userService.approve(rowData.id).then(updated_user => {
            const index = users.findIndex(user => user.id === updated_user.id);
            users[index] = updated_user;
            setUsers([...users]);

            toast.current.show({severity: 'success', summary: t('actions.approved'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    };

    const onChange = (e) => {
        const { key, value } = getKeyValue(e);

        if(key === "new_password")
        {
            setFormErrors({...formErrors, [key]: checkPasswordError(key, value)});
            setChange_password({...change_password, [key] : value});
        }
        else if(key === "amount")
        {
            setCharge({...charge, [key] : value});
        }
        else {
            setFormErrors({...formErrors, [key]: checkSubscriptionError(key, value)});
            setEditedSubscription({...editedSubscription, [key] : value});
        }

    };

    const showPasswordDialog = (rowData) => {
        setChange_password({user_id: rowData.id, new_password:""});
        setPasswordDialogVisible(true);
    };

    const showChargingDialog = (rowData) => {
        setCharge({user_id: rowData.id, amount: 0});
        setChargeDialogVisible(true);
    };

    const changePassword = () => {

        checkAllPasswordErrors(change_password).then(formErrors=> {
            if (formValid(formErrors)) {
                userService.changeUserPassword(change_password.user_id, change_password.new_password).then(()=>{
                    toast.current.show({severity: 'success', summary: t('actions.change_password'), detail: t('message_detail.successful')});
                    setPasswordDialogVisible(false);
                }).catch(error =>{
                    setError(error);
                    setError(null);
                });
            }
            else
                setFormErrors(formErrors);
        });
    };

    const chargeUser = () => {
        accountService.chargeUser(charge.user_id, charge.amount).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.charge_user'), detail: t('message_detail.successful')});
            setChargeDialogVisible(false);
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const checkPasswordError = (name, value) => {
        let errorText = "";

        if (name === "new_password") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        return errorText;
    };

    const checkAllPasswordErrors = async (change_password) => {
        let tempErrors = {...formErrors};
        Object.entries(change_password).forEach(([key, value]) => {
            tempErrors[key] = checkPasswordError(key, value);
        });

        return tempErrors;
    };

    const showSubscriptionDialog = (rowData) => {
        if (rowData.subscription){
            setEditedSubscription(rowData.subscription);
            pricingPlanService.getPricingPlans().then(pricingPlans => {
                setPricingPlans(pricingPlans)
                let currentPlan = pricingPlans.filter(plan => plan.id === rowData.subscription.pricing_plan_id)[0]
                setCurrentPlan(currentPlan)
                if (currentPlan) {
                    currentPlan.additional_features.forEach(item => {
                        let additional_feature = rowData.subscription.additional_features.filter(innerItem => innerItem.name === item.name)[0]
                        if (additional_feature) {
                            item.price = additional_feature.price
                            item.is_selected = true
                        }
                    })
                }
                setSubscriptionDialogVisible(true);
            });
        }
        else
            toast.current.show({severity: 'warn', summary: t('subscription.user_has_no_subscription'), detail: t('message_detail.failed')});
    };

    const convertSpecialSubscription = () => {
        userService.convertSpecialSubscription(editedSubscription, selectedStandardPricingPlan).then(updatedSubscription=>{
            let index = users.findIndex(user => user.id === updatedSubscription.user_id);
            users[index].subscription = updatedSubscription;
            setUsers(users);
            toast.current.show({severity: 'success', summary: t('actions.update_subscription'), detail: t('message_detail.successful')});
            setSubscriptionDialogVisible(false);
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const updateSubscription = () => {
        checkAllSubscriptionErrors(editedSubscription).then(formErrors=> {
            if (formValid(formErrors)) {
                if (currentPlan) {
                    editedSubscription.additional_features = currentPlan.additional_features.filter(additional_feature => additional_feature.is_selected)
                }
                userService.updateSubscription(editedSubscription).then(updatedSubscription=>{

                    let index = users.findIndex(user => user.id === updatedSubscription.user_id);
                    users[index].subscription = updatedSubscription;
                    setUsers(users);

                    toast.current.show({severity: 'success', summary: t('actions.update_subscription'), detail: t('message_detail.successful')});
                    setSubscriptionDialogVisible(false);
                }).catch(error =>{
                    setError(error);
                    setError(null);
                });

            }
            else
                setFormErrors(formErrors);
        });
    }

    const checkSubscriptionError = (name, value) => {
        let errorText = "";

        if (name === "plan_price") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (name === "start_at") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (name === "finish_at") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (name === "total_product_count") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (name === "daily_price_check_count") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (name === "manuel_price_check_count") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }

        return errorText;
    };

    const checkAllSubscriptionErrors = async (editedSubscription) => {
        let tempErrors = {...formErrors};
        Object.entries(editedSubscription).forEach(([key, value]) => {
            tempErrors[key] = checkSubscriptionError(key, value);
        });

        return tempErrors;
    };

    const showSubscriptionHistory = (e, rowData) => {

        userService.getSubscriptionHistory(rowData.id).then(subscriptionHistory=>{

            setSubscriptionHistory(subscriptionHistory);
            setSubscriptionHistoryDialogVisible(true);

        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const showSpecialPlan = (e, rowData) => {
        userService.getSpecialPlan(rowData.id).then(specialPlan=>{
            setSpecialPlan(specialPlan);
            setSpecialPlanDialogVisible(true);
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const impersonate = (rowData) => {
        accountService.impersonate(rowData.id).then(data => {
            navigate('campaign');
            window.location.reload(false);
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const addSpecialPlan = () => {
        pricingPlanService.addPricingPlan(specialPlan).then(addedPlan => {
            setSpecialPlan(null);
            setSpecialPlanDialogVisible(false);
            toast.current.show({severity: 'success', summary: t('actions.add_special_plan'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const deleteSpecialPlan = () => {
        pricingPlanService.deletePricingPlan(specialPlan.id).then(data => {
            setSpecialPlan(null);
            setSpecialPlanDialogVisible(false);
            toast.current.show({severity: 'success', summary: t('actions.delete_special_plan'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const confirmDelete = (rowData) => {

        toast.current.show({ severity: 'warn', sticky: true,  closable: false, content: (
                <div className="p-flex p-flex-column" style={{flex: '1'}}>
                    <div className="p-text-center">
                        <i className="pi pi-exclamation-triangle" style={{fontSize: '3rem'}}/>

                        <p>{t('dialogs.confirm_user_delete')}</p>

                        <h5>{t('dialogs.confirm_user_delete_msg0')}<b>{(rowData ? rowData.email + "(" + rowData.first_name + " " + rowData.last_name + ")" : "")}</b></h5>

                        <p>{t('dialogs.confirm_user_delete_msg1')}</p>

                        <p>{t('dialogs.confirm_user_delete_msg2')}</p>
                        <br/>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <Button label={t('yes_no.yes')} icon="pi pi-check" className="p-button-success" onClick={() => {
                                deleteUser(rowData);
                                toast.current.clear();
                            }} />
                        </div>
                        <div className="p-col-6">
                            <Button label={t('yes_no.no')} icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                toast.current.clear();
                            }} />
                        </div>
                    </div>
                </div>
            ) });
    };



    const deleteUser = (rowData) => {
        userService.deleteUser(rowData.id).then(message=>{
            let filteredUsers = users.filter(user => user.id !== rowData.id);
            setUsers(filteredUsers);
            toast.current.show({severity: 'success', summary: message, detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const detailTemplate = (rowData) => {
        return <div className="p-grid p-col-6 card" style={{margin:'1em'}}>
            <div className="p-col-6">{"Id"}</div>
            <div className="p-col-6">{rowData.id}</div>
            <div className="p-col-6">{t('user.date_joined')}</div>
            <div className="p-col-6">{toDateTimeString(rowData.date_joined)}</div>
            <div className="p-col-6">{t('subscription.plan')}</div>
            <div className="p-col-6">{toTitleCase(rowData.subscription ? rowData.subscription.type : "")}</div>
            <div className="p-col-6">{t('subscription.is_paid')}</div>
            <div className="p-col-6">{rowData.subscription && rowData.subscription.is_paid ? t('actions.paid') : t('actions.notpaid')}</div>
            <div className="p-col-6">{t('user.is_locked')}</div>
            <div className="p-col-6"><InputSwitch checked={rowData.is_locked} onChange={() => changeIsLocked(rowData)} tooltip={rowData.is_locked ? t('actions.unlock') : t('actions.lock') } /></div>
            <div className="p-col-6">{t('user.is_active')}</div>
            <div className="p-col-6"><InputSwitch checked={rowData.is_active} onChange={() => changeIsActive(rowData)} tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } /></div>

            <div className="p-col-6">{t('user.is_approved')}</div>
            {!rowData.is_approved ? <div className="p-col-6"><InputSwitch checked={rowData.is_approved} onChange={() => changeIsApproved(rowData)} tooltip={rowData.is_approved ? t('actions.refuse') : t('actions.approve') } /></div>
                : t('actions.approved')
            }
            <div className="p-col-6"></div>
            <div className="p-col-6">
                <Button icon="pi pi-key" className="p-button-outlined p-button-danger" tooltip={t('actions.change_password')} style={{marginRight: '.5em'}} onClick={() => showPasswordDialog(rowData)}/>
                <Button icon="pi pi-briefcase" className="p-button-outlined p-button-success" tooltip={t('actions.subscription_history')} style={{marginRight: '.5em'}} onClick={(e) => showSubscriptionHistory(e,rowData)}/>
                <Button icon="pi pi-shopping-cart" className="p-button-outlined p-button-warning" tooltip={t('actions.special_plan')} style={{marginRight: '.5em'}} onClick={(e) => showSpecialPlan(e,rowData)}/>
                <Button icon="pi pi-money-bill" className="p-button-outlined p-button-danger" tooltip={t('actions.charge_user')} style={{marginRight: '.5em'}} onClick={() => showChargingDialog(rowData)}/>
            </div>
        </div>;
    };

    const actionTemplate = (rowData) => {
        return <div>
            <Button icon="pi pi-users" className="p-button-outlined p-button-rounded p-button-warning" tooltip={t('actions.impersonate')} tooltipOptions={{position: 'bottom'}} style={{marginRight: '.5em'}} onClick={() => impersonate(rowData)}/>
            <Button icon="pi pi-shopping-cart" className="p-button-outlined p-button-rounded p-button-info" tooltip={t('actions.update_subscription')} tooltipOptions={{position: 'bottom'}} style={{marginRight: '.5em'}} onClick={() => showSubscriptionDialog(rowData)}/>
            <Button icon="pi pi-pencil" className="p-button-outlined p-button-rounded p-button-success" tooltip={t('actions.edit')} tooltipOptions={{position: 'bottom'}} style={{marginRight: '.5em'}} onClick={() => {
                setRowData(rowData);
                setShowEditModal(true);
            }}/>
            <Button icon="pi pi-trash" className="p-button-outlined p-button-rounded p-button-danger" tooltip={t('actions.delete')} tooltipOptions={{position: 'bottom'}} onClick={() => {confirmDelete(rowData);}}/>
        </div>;
    };

    const rowColor = (rowData)  => {

        if (rowData.is_admin)
            return { 'admin' : true };
        else if (rowData.tags.includes('Alabilir'))
            return { 'potential' : true };
        else if (rowData.tags.includes('Eski Müşteri'))
            return { 'former' : true };
        else
            return {[rowData.subscription && rowData.subscription.type && !rowData.subscription.is_canceled ? rowData.subscription.type :""] : true};
    };




    let changePasswordFooter = <div className="ui-dialog-buttonpane p-clearfix">
        <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => setPasswordDialogVisible(false)}/>
        <Button id="add" label={t('actions.change_password')} icon="pi pi-key" className="p-button-outlined p-button-success" onClick={changePassword}/>
    </div>;

    let chargeUserFooter = <div className="ui-dialog-buttonpane p-clearfix">
        <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => setChargeDialogVisible(false)}/>
        <Button id="charge" label={t('actions.charge_user')} icon="pi pi-money-bill" className="p-button-outlined p-button-success" onClick={chargeUser}/>
    </div>;

    let specialPlanFooter = <div className="ui-dialog-buttonpane p-clearfix">
        <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => setSpecialPlanDialogVisible(false)}/>
        <Button id="delete_special_plan" label={t('actions.delete')} icon="pi pi-trash" className="p-button-outlined p-button-danger" onClick={deleteSpecialPlan}/>
        <Button id="save_special_plan" label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={addSpecialPlan}/>
    </div>;

    const subscriptionFooter = (
        <React.Fragment>
            <div>
                <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => setSubscriptionDialogVisible(false)}/>
                <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={() => updateSubscription()}/>
            </div>
            <br></br>

            {editedSubscription.type === 'special' &&
            <div>
                <Dropdown name="standardPricingPlans" style={{marginRight:"0.5em"}} value={selectedStandardPricingPlan} options={standardPricingPlans} onChange={(e)=>{setSelectedStandardPricingPlan(getKeyValue(e).value);}}/>
                <Button label={t('actions.convert')} icon="pi pi-angle-double-right" className="p-button-outlined p-button-danger" onClick={() => convertSpecialSubscription()}/>
            </div>}
        </React.Fragment>
    );

    const userUpdateCompleted = (updatedUser) => {
        users[users.findIndex(user => user.id === updatedUser.id)] = {...updatedUser};
        setUsers([...users]);
        setRowData(null);
        setShowEditModal(false);
        toast.current.show({severity: 'success', summary: t('account.save_account'), detail: t('message_detail.successful')});
    };

    const filteredUsers = () => {
        let filteredUsers = users.map(user=> ({ ...user, full_name: toTitleCase(user.first_name) + ' ' + toTitleCase(user.last_name) })).filter(
            u=> {
                return Object.keys(u).some((key) => {
                   return typeof u[key] !== 'object' && u[key] && u[key].toString().toLowerCase().includes(filter.toLowerCase());
                })
            }
        );
        let today = new Date();

        if (selectedUserType === "all")
            return filteredUsers;
        else if (selectedUserType === "admin")
            return filteredUsers.filter(user => user.is_admin);
        else if (selectedUserType === "basic")
            return filteredUsers.filter(user => user.subscription && user.subscription.type && !user.subscription.is_canceled && user.subscription.type === "basic");
        else if (selectedUserType === "professional")
            return filteredUsers.filter(user => user.subscription && user.subscription.type && !user.subscription.is_canceled && user.subscription.type === "professional");
        else if (selectedUserType === "premium")
            return filteredUsers.filter(user => user.subscription && user.subscription.type && !user.subscription.is_canceled && user.subscription.type === "premium");
        else if (selectedUserType === "special")
            return filteredUsers.filter(user => user.subscription && user.subscription.type && !user.subscription.is_canceled && user.subscription.type === "special");
        else if (selectedUserType === "potential")
            return filteredUsers.filter(user => user.tags.includes('Alabilir'));
        else if (selectedUserType === "former")
            return filteredUsers.filter(user => user.tags.includes('Eski Müşteri'));
        else if (selectedUserType === "account-setup")
            return filteredUsers.filter(user => user.tags.includes('Hesap Kurulacak'));
        else if (selectedUserType === "trial")
            return filteredUsers.filter(user => !user.is_admin && user.subscription && user.subscription.type === "trial" && Date.parse(user.subscription.finish_at) > today);
        else if (selectedUserType === "ex-trial")
            return filteredUsers.filter(user => user.subscription && user.subscription.type === "trial" && Date.parse(user.subscription.finish_at) < today);
        else
            return filteredUsers;
    }

    const userTypes = [
        {className:"p-text-bold all", name: "All", value: "all"},
        {className:"p-text-bold admin", name: "Admin", value: "admin"},
        {className:"p-text-bold basic", name: "Basic", value: "basic"},
        {className:"p-text-bold professional", name: "Professional", value: "professional"},
        {className:"p-text-bold premium", name: "Premium", value: "premium"},
        {className:"p-text-bold special", name: "Special", value: "special"},
        {className:"p-text-bold potential", name: "Potential", value: "potential"},
        {className:"p-text-bold trial", name: "Trial", value: "trial"},
        {className:"p-text-bold former", name: "Former", value: "former"},
        {className:"p-text-bold exTrial", name: "Ex-Trial", value: "ex-trial"},
        {className:"p-text-bold accountSetup", name: "Account Setup", value: "account-setup"}
    ];

    const additionalFeatureSelectTemplate = (rowData) => {
        return rowData.is_selected ? t('trueFalse.true') : t('trueFalse.false');
    };

    const additionalFeatureNameTemplate = (rowData) => {
        return t('subscription.' + rowData.name);
    };

    const additionalFeatureSelectEditor = (props) => {
        return <Checkbox inputId="is_selected" name="is_selected" value={props.rowData.is_selected} checked={props.rowData.is_selected} onChange={(e)=>{
            let additional_feature = currentPlan.additional_features.filter(additional_feature => additional_feature.name === props.rowData.name)[0]
            additional_feature.is_selected = getKeyValue(e).value;
            props.rowData.is_selected = getKeyValue(e).value;
        }}/>;
    };

    const additionalFeaturePriceEditor = (props) => {
        return <InputNumber value={props.rowData.price} style={{width:"80px"}} mode="decimal" minFractionDigits={2} onValueChange={(e)=>{
            let additional_feature = currentPlan.additional_features.filter(additional_feature => additional_feature.name === props.rowData.name)[0]
            additional_feature.price = getKeyValue(e).value;
        }}/>;
    };

    return (

        <div className="p-grid card">
            <Dialog visible={subscriptionHistoryDialogVisible} header={t('actions.subscription_history')} style={{maxWidth: '70vw', minWidth: '40vw'}} modal={true} onHide={() => setSubscriptionHistoryDialogVisible(false)}>
                <DataTable value={subscriptionHistory} responsive={true}>
                    <Column field="type" header={t('subscription.plan')}  />
                    <Column field="start_at" header={t('subscription.start_at')} />
                    <Column field="finish_at" header={t('subscription.finish_at')} />
                    <Column field="price" header={t('subscription.price')}/>
                    <Column field="billed_amount" header={t('subscription.billed_amount')}/>
                    <Column field="is_paid" header={t('subscription.is_paid')}/>
                    <Column field="is_canceled" header={t('subscription.is_canceled')}/>
                    <Column field="created_at" body={createdAtTemplate} header={t('subscription.created_at')}/>
                </DataTable>
            </Dialog>

            <Dialog visible={specialPlanDialogVisible} header={t('actions.update_subscription')} footer={specialPlanFooter} modal={true} onHide={() => setSpecialPlanDialogVisible(false)} >
                <UserSpecialPlan specialPlan={specialPlan} addSpecialPlan={addSpecialPlan}/>
            </Dialog>

            <Dialog visible={subscriptionDialogVisible} header={t('actions.update_subscription')} footer={subscriptionFooter} modal={true} onHide={() => setSubscriptionDialogVisible(false)} >
                <div className="card card-w-title alignCenter p-fluid">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <h4>{t('subscription.plan') + " : " + editedSubscription.name}</h4>
                        </div>
                        <div className="p-col-6">
                            <label htmlFor="plan_price">{t('subscription.total_price')}</label>
                            <InputText id="plan_price" name="plan_price" value={editedSubscription.plan_price ?? ""} onChange={onChange}
                                       className={formErrors.plan_price.length > 0 ? "p-error" : null}/>
                            {formErrors.plan_price.length > 0 && (<Message severity="error" text={formErrors.plan_price}/>)}
                        </div>
                        <div className="p-col-6">
                        </div>
                        <div className="p-col-6">
                            <label htmlFor="start_at">{t('subscription.start_at')}</label>
                            <InputText id="start_at" name="start_at" value={editedSubscription.start_at ?? ""} onChange={onChange}
                                       className={formErrors.start_at.length > 0 ? "p-error" : null}/>
                            {formErrors.start_at.length > 0 && (<Message severity="error" text={formErrors.start_at}/>)}
                        </div>
                        <div className="p-col-6">
                            <label htmlFor="finish_at">{t('subscription.finish_at')}</label>
                            <InputText id="finish_at" name="finish_at" value={editedSubscription.finish_at ?? ""} onChange={onChange}
                                       className={formErrors.finish_at.length > 0 ? "p-error" : null}/>
                            {formErrors.finish_at.length > 0 && (<Message severity="error" text={formErrors.finish_at}/>)}
                        </div>
                        <div className="p-col-6">
                            <label htmlFor="total_product_count">{t('subscription.total_product_count')}</label>
                            <InputText id="total_product_count" name="total_product_count" keyfilter="pint" value={editedSubscription.total_product_count ?? ""} onChange={onChange}
                                       className={formErrors.total_product_count.length > 0 ? "p-error" : null}/>
                            {formErrors.total_product_count.length > 0 && (<Message severity="error" text={formErrors.total_product_count}/>)}
                        </div>
                        <div className="p-col-6">
                            <label htmlFor="daily_price_check_count">{t('subscription.daily_price_check_count')}</label>
                            <InputText id="daily_price_check_count" name="daily_price_check_count" keyfilter="pint" value={editedSubscription.daily_price_check_count ?? ""} onChange={onChange}
                                       className={formErrors.daily_price_check_count.length > 0 ? "p-error" : null}/>
                            {formErrors.daily_price_check_count.length > 0 && (<Message severity="error" text={formErrors.daily_price_check_count}/>)}
                        </div>
                        <div className="p-col-6">
                            <label htmlFor="manuel_price_check_count">{t('subscription.manuel_price_check_count')}</label>
                            <InputText id="manuel_price_check_count" name="manuel_price_check_count" keyfilter="pint" value={editedSubscription.manuel_price_check_count ?? ""} onChange={onChange}
                                       className={formErrors.manuel_price_check_count.length > 0 ? "p-error" : null}/>
                            {formErrors.manuel_price_check_count.length > 0 && (<Message severity="error" text={formErrors.manuel_price_check_count}/>)}
                        </div>
                        <div className="p-col-6">
                            <Checkbox id="instant_price_check_enabled" name="instant_price_check_enabled" value={editedSubscription.instant_price_check_enabled} checked={editedSubscription.instant_price_check_enabled === true} onChange={onChange}/>
                            <label htmlFor="instant_price_check_enabled">{t('subscription.instant_price_check_enabled')}</label>
                        </div>
                        <div className="p-col-6">
                            <Checkbox id="sale_history_enabled" name="sale_history_enabled" value={editedSubscription.sale_history_enabled} checked={editedSubscription.sale_history_enabled === true} onChange={onChange}/>
                            <label htmlFor="sale_history_enabled">{t('subscription.sale_history_enabled')}</label>
                        </div>
                        <div className="p-col-6">
                            <Checkbox id="competitor_suggestion_enabled" name="competitor_suggestion_enabled" value={editedSubscription.competitor_suggestion_enabled} checked={editedSubscription.competitor_suggestion_enabled === true} onChange={onChange}/>
                            <label htmlFor="competitor_suggestion_enabled">{t('subscription.competitor_suggestion_enabled')}</label>
                        </div>
                        <div className="p-col-6">
                            <Checkbox id="dynamic_pricing_enabled" name="dynamic_pricing_enabled" value={editedSubscription.dynamic_pricing_enabled} checked={editedSubscription.dynamic_pricing_enabled === true} onChange={onChange}/>
                            <label htmlFor="dynamic_pricing_enabled">{t('subscription.dynamic_pricing_enabled')}</label>
                        </div>
                        <div className="p-col-6">
                            <Checkbox id="is_paid" name="is_paid" value={editedSubscription.is_paid} checked={editedSubscription.is_paid === true} onChange={onChange}/>
                            <label htmlFor="is_paid">{t('subscription.is_paid')}</label>
                        </div>
                        <div className="p-col-6">
                            <Checkbox id="is_canceled" name="is_canceled" value={editedSubscription.is_canceled} checked={editedSubscription.is_canceled === true} onChange={onChange}/>
                            <label htmlFor="is_canceled">{t('subscription.is_canceled')}</label>
                        </div>
                        <div className="p-col-12">
                            {currentPlan &&
                            <DataTable value={currentPlan.additional_features}>
                                <Column field="is_selected" header={t('table_headers.is_selected')} body={additionalFeatureSelectTemplate} editor={additionalFeatureSelectEditor}/>
                                <Column field="name" header={t('table_headers.name')} body={additionalFeatureNameTemplate}/>
                                <Column field="price" header={t('subscription.price')} editor={additionalFeaturePriceEditor}/>
                            </DataTable>
                            }
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={passwordDialogVisible} header={t('actions.change_password')} footer={changePasswordFooter} modal={true} onHide={() => setPasswordDialogVisible(false)}>
                <div className="p-grid alignCenter p-fluid">
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <Password id="new_password" name="new_password" value={change_password.new_password} onChange={onChange}
                                      className={formErrors.new_password.length > 0 ? "p-error" : null}/>
                            <label htmlFor="new_password">{t('user.new_password')}</label>
                        </span>
                        {formErrors.new_password.length > 0 && (<Message severity="error" text={formErrors.new_password}/>)}
                    </div>
                </div>
            </Dialog>

            <Dialog visible={chargeDialogVisible} header={t('actions.charge_user')} footer={chargeUserFooter} modal={true} onHide={() => setChargeDialogVisible(false)}>
                <div className="p-grid alignCenter p-fluid">
                    <div className="p-col-12">
                        <Message severity="info" text={t('actions.payment_message')} />
                    </div>
                    <div className="p-col-12">
                        <span className="p-float-label">
                              <InputNumber id="amount" name="amount" value={charge.amount} style={{width:"200px"}}
                                           mode="decimal" minFractionDigits={2}
                                           onValueChange={onChange}/>
                              <label htmlFor="amount">{t('user.charge_amount')}</label>
                        </span>
                    </div>
                </div>
            </Dialog>

            <div className="p-grid p-col-12">
                <InputText name="filter" value={filter} onChange={(e) => {setFilter(getKeyValue(e).value)}} className="p-mr-1" placeholder={t('product.globalFilter')} />
                <Button icon="pi pi-refresh" data-tour="step5" tooltip={t('actions.refresh')}
                        tooltipOptions={{position: 'bottom'}} className="p-button-outlined p-mr-1"
                        onClick={() => {setForceRefresh(forceRefresh + 1);}}/>
                <Button icon="pi pi-circle" data-tour="step5" tooltip={t('user.all_users')}
                        tooltipOptions={{position: 'bottom'}} className="p-button-outlined p-mr-1"
                        onClick={() => {getUsers(true);}}/>
                <SelectButton value={selectedUserType}
                              optionLabel="name" options={userTypes}
                              onChange={(e) => {setSelectedUserType( getKeyValue(e).value)}}/>
            </div>

            {loading ? <div className="p-col-12" style={{textAlign:'center'}}>
                <Loading/>
            </div> :
            <div className="p-col-12">
                <DataTable ref={dtUsers} value={filteredUsers()}
                           responsive={true}
                           paginator={true}
                           rows={50}
                           sortField="last_login_at" sortOrder={-1}
                           rowClassName={rowColor}
                           style={{fontSize:'0.9em'}}
                           rowExpansionTemplate={detailTemplate}
                           expandedRows={expandedRows}
                           onRowToggle={(e) => setExpandedRows(e.data)}>
                    <Column expander={true} style={{width:"3em"}} />
                    <Column body={IndexTemplate} style={{width:'3em'}} />
                    <Column field="full_name" header={t('user.name')} />
                    <Column field="email" header={t('user.email')} />
                    <Column field="last_login_at" header={t('user.last_login')} body={last_login_atTemplate} sortable={true}/>
                    <Column field="tags" header={t('user.tags')} body={tagsTemplate}/>
                    <Column field="note" header={t('user.note')}/>
                    <Column field="usage_type" header={t('user.user_type')} className="p-align-center" style={{width:"10em"}}/>
                    <Column body={actionTemplate} className="p-align-center" style={{width:"17em"}}/>
                </DataTable>
            </div>}

            {showEditModal && <UpdateUser selectedUser={rowData} userUpdateCompleted={userUpdateCompleted} hideDialog={() => {setShowEditModal(false)}}  />}

        </div>
    );
};

export default User;
