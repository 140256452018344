import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";

import {getKeyValue} from "../../utils/Utils";

import ScrapeStatus from "./ScrapeStatus";
import {Panel} from "primereact/panel";
import {RadioButton} from "primereact/radiobutton";
import {InputNumber} from 'primereact/inputnumber';
import {CampaignService} from "../../service/CampaignService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {ScraperService} from "../../service/ScraperService";
import {AppContext} from "../../HomePage";
import {Loading} from "../../utils/InlineComponents";


const ProductAdminAction = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const [loading, setLoading] = useState(false);


    let selectedProduct = props.selectedProduct;
    const campaignService = new CampaignService();
    const scraperService = new ScraperService();
    const [campaignCompetitors, setCampaignCompetitors] = useState(null)

    const [filters, setFilters] = useState({
        match_method: "",
        min_match_score: 70,
    });

    useEffect(() => {
        setLoading(true);
        selectedProduct = props.selectedProduct;
        campaignService.getCampaignCompetitors(selectedProduct.campaign_id).then((competitors) => {
            let campaignCompetitors = competitors.filter(comp => comp.is_site_searching_ready);
            setCampaignCompetitors(campaignCompetitors);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    }, [props.selectedProduct]);

    const newSearchRequest = {
        campaign_id: selectedProduct.campaign_id,
        product_id: selectedProduct.id,
        type: 'competitor_suggestion_product',
        spider_name: "competitor_suggestion_product"
    }

    const onChange = (e) => {
        const {key, value} = getKeyValue(e);
        setFilters({...filters, [key]: value});
    };

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <Button icon="pi pi-search-plus" className="p-button-outlined p-button-info" tooltip={t('actions.search_competitors_on_website')}
                    style={{marginRight: '.5em'}} disabled={!rowData.is_site_searching_ready} onClick={() => searchCompetitorsOnWebsite(rowData)}/>
        </React.Fragment>;
    };

    const searchCompetitorsOnWebsite = (rowData) => {
        scraperService.saveScrapingRequest({
            type: "site_search_product",
            campaign_id: selectedProduct.campaign_id,
            product_id: selectedProduct.id,
            url: rowData.url
        }).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.search_competitors_on_website'), detail: t('message_detail.successful')});
        });
    };

    return <div className="p-grid p-col-12">
        <div className="p-col-2"><b>{t('table_headers.find_matches_status')}</b></div>
        <div className="p-col-10">
            <ScrapeStatus type="suggest" scraping_request={selectedProduct.competitor_suggestion_scraping_request}
                          campaign_id={selectedProduct.campaign_id}
                          product_id={selectedProduct.id}
                          competitor_url={null}
                          newRequest={newSearchRequest}
                          newRequestCompleted={props.refreshProducts}/>
        </div>

        <div className="p-col-2"><b>{t('table_headers.competitor_suggestion_on_site')}</b></div>
        <div className="p-col-10">
            <Button icon="pi pi-refresh" className="p-button-outlined p-button-success"
                    style={{marginRight: '.5em'}}
                    label={t('actions.search_competitors_on_website')} onClick={props.searchCompetitorsOnWebsite}/>
        </div>

        <div className="p-col-2">
            <b>{t('actions.get_shipping_price')}</b>
        </div>
        <div className="p-col-10">
            <Button icon="pi pi-shopping-cart" className="p-button-outlined p-button-info" label={t('actions.get_shipping_price')} onClick={props.getShippingPrices}/>
        </div>

        <div className="p-col-2"><b>{t('table_headers.product_reviews')}</b></div>
        <div className="p-col-10">
            <Button icon="pi pi-comments" className="p-button-outlined p-button-warning"
                    style={{marginRight: '.5em'}}
                    label={t('actions.get_product_reviews')} onClick={props.getProductReviews}/>
        </div>

        <div className="p-col-2"><b>{t('table_headers.product_reviews')}</b></div>
        <div className="p-col-10">
            <Button icon="pi pi-comments" className="p-button-outlined p-button-danger"
                    style={{marginRight: '.5em'}}
                    label={t('actions.get_product_failed_reviews')} onClick={props.getProductFailedReviews}/>
        </div>

        <div className="p-col-12">
            <Panel header={t('suggestion.match_method')}>

                <RadioButton inputId="match_method_sku" value="sku" name="match_method" onChange={onChange} checked={filters.match_method === "sku"}/>
                <label htmlFor="match_method_sku" className="p-ml-1 p-mr-1">{t('product.sku')}</label>

                <RadioButton inputId="match_method_mpn" value="mpn" name="match_method" onChange={onChange} checked={filters.match_method === "mpn"}/>
                <label htmlFor="match_method_mpn" className="p-ml-1 p-mr-1">{t('product.mpn')}</label>

                <RadioButton inputId="match_method_ean" value="ean" name="match_method" onChange={onChange} checked={filters.match_method === "ean"}/>
                <label htmlFor="match_method_ean" className="p-ml-1 p-mr-1">{t('product.ean')}</label>

                <RadioButton inputId="match_method_upc" value="upc" name="match_method" onChange={onChange} checked={filters.match_method === "upc"}/>
                <label htmlFor="match_method_upc" className="p-ml-1 p-mr-1">{t('product.upc')}</label>

                <RadioButton inputId="match_method_barcode" value="barcode" name="match_method" onChange={onChange} checked={filters.match_method === "barcode"}/>
                <label htmlFor="match_method_barcode" className="p-ml-1 p-mr-1">{t('product.barcode')}</label>

                <RadioButton inputId="match_method_name_exact" value="name_exact" name="match_method" onChange={onChange} checked={filters.match_method === "name_exact"}/>
                <label htmlFor="match_method_name_exact" className="p-ml-1 p-mr-1">{t('suggestion.name_exact')}</label>

                <RadioButton inputId="match_method_name_in" value="name_in" name="match_method" onChange={onChange} checked={filters.match_method === "name_in"}/>
                <label htmlFor="match_method_name_in" className="p-ml-1 p-mr-1">{t('suggestion.name_in')}</label>

                <RadioButton inputId="match_method_match_score" value="match_score" name="match_method" onChange={onChange} checked={filters.match_method === "match_score"}/>
                <label htmlFor="match_method_match_score" className="p-ml-1 p-mr-1">{t('suggestion.match_score')}</label>
                {filters.match_method === "match_score" && <InputNumber name="min_match_score" value={filters.min_match_score} style={{
                    width: "7%",
                    marginLeft: '.5em'
                }} onValueChange={onChange} showButtons min={0} max={100} size={2}/>}

                <Button icon="pi pi-refresh" className="p-button-outlined p-button-success"
                        label={t('actions.auto_match_suggestions')} style={{width: "25%", marginLeft: '.5em'}}
                        onClick={() => props.autoMatchProductSuggestions(filters.match_method, filters.min_match_score)}/>

            </Panel>
        </div>
        {loading ?
            <div className="p-col-12" style={{textAlign: 'center'}}>
                <Loading/>
            </div> :
            campaignCompetitors && <div className="p-col-12">
                <DataTable value={campaignCompetitors} dataKey="url" responsive={true}>
                    <Column field="url" header={t('campaign.competitors.url')} sortable/>
                    <Column body={actionTemplate} header={t('table_headers.action')} style={{textAlign: 'center', width: '10em'}}/>
                </DataTable>
            </div>
        }
    </div>;

};
export default ProductAdminAction;
