import React, {useEffect, useState} from 'react';

import {InputText} from "primereact/inputtext";
import {getKeyValue, isNumeric} from "../../utils/Utils";



const Number = (props) => {

    const [value, setValue] = useState(props.value);

    useEffect ( ()=>{
        setValue(props.value);
    },[props.value]);

    useEffect ( ()=>{
        props.onChange({target:{name: props.name, value: parseFloat(value === "" ? 0 : value)}});
    },[value]);

    const onKeyDown = (e) =>
    {
        if (e.key >= "0" && e.key <= "9") {
            if (e.target.selectionStart>e.target.value.indexOf('.') &&
                e.target.value.indexOf('.') !== -1 && (e.target.value.split('.')[1]).length === 2) {
                e.preventDefault();
            }
        }
        else if (e.key === "Control" || e.key === "Shift" || e.key === "Alt")//control
        {
            return;
        }
        // else if (e.which === 8 || e.which === 37 || e.which === 38 || e.which === 39 || e.which === 40 || e.which === 46)//
        // {
        //     return;
        // }
        else if (e.key === ".")
        {
            if (e.target.value.indexOf('.') !== -1) {
                e.preventDefault();
            }
        }
        else {
            e.preventDefault();
        }
    }

    return (
        <React.Fragment>
            <InputText id="value" name="value" value={isNumeric(value) ? value : 0} onKeyPress={onKeyDown}
                       onChange={(e) => {setValue(getKeyValue(e).value)}}
                       className={props.className} maxLength={13} />
        </React.Fragment>
    );
};
export default Number