import React, {useState} from 'react';
import Overview from "./Overview";
import {AppContext} from "../../HomePage";
import {TabMenu} from "primereact/tabmenu";
import OverviewUrl from "./OverviewUrl";
import {CampaignContext} from "../../App";
import OverviewDistinctUrl from "./OverviewDistinctUrl";

const Dashboard = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [activeIndex, setActiveIndex] = useState(0);
    let items = [
        {label: t('dashboard.overview'), icon: 'pi pi-eye'},
        {label: t('dashboard.overview_url'), icon: 'pi pi-external-link'},
        {label: t('dashboard.overview_sku'), icon: 'pi pi-ticket'},
    ];

    return selectedCampaign ?
            <div className="p-grid card">
                <div className="p-col-12">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e)=>
                    {
                        setActiveIndex(items.indexOf(e.value));
                    }}/>
                </div>
                <div className="p-col-12">
                    {activeIndex === 0 && <Overview />}
                    {activeIndex === 1 && <OverviewUrl />}
                    {activeIndex === 2 && <OverviewDistinctUrl />}
                </div>
            </div> : <></>;
};
export default Dashboard;
