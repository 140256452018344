import React, {useEffect, useState} from "react";

import {Line} from "react-chartjs-2";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";
import {DashboardService} from "../../service/DashboardService";
import {Calendar} from "primereact/calendar";
import {getKeyValue} from "../../utils/Utils";
import {Loading} from "../../utils/InlineComponents";
import {MultiSelect} from "primereact/multiselect";
import {Button} from "primereact/button";
import {getFirstDayOfTheYear, getMonthsAgoFirstDay} from "../../utils/TimeUtil";


const BrandComparison = () => {

    const {t, setError, toast} = React.useContext(AppContext);
    const {campaigns} = React.useContext(CampaignContext);

    const [dates, setDates] = useState([getFirstDayOfTheYear(), getMonthsAgoFirstDay(0)]);
    const [selectedCampaigns, setSelectedCampaigns] = useState(null)

    const [reportData, setReportData] = useState(null);

    const [dataOverall, setDataOverall] = useState(null);
    const [dataBrand, setDataBrand] = useState(null);
    const [dataCategory, setDataCategory] = useState(null);
    const [options, setOptions] = useState(null);

    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [loading, setLoading] = useState(false);
    const dashboardService = new DashboardService();

    useEffect(() => {

        let dataOverall = [];
        let dataBrand = [];
        let dataCategory = [];

        if (selectedCampaigns)
            selectedCampaigns.forEach(c_id=> {

                let campaignData = reportData.find(r => r.campaign_id === c_id)["data"];

                if (campaignData) {

                    let months = campaignData["months"].sort(function (a, b) {
                        return (a - b)
                    });

                    dataOverall.push({
                        "campaign_id": c_id,
                        "data": {
                            labels: months,
                            datasets: [{
                                type: 'line',
                                label: "overall",
                                borderColor: 'red',
                                borderWidth: 2,
                                fill: false,
                                data: campaignData["overall"].sort(function (a, b) {
                                    return (a["x"] - b["x"])
                                }),
                                lineTension: 0
                            }]
                        }
                    });

                    let dataBrandC = {
                        labels: months,
                        datasets: []
                    };

                    campaignData["brand_names"].forEach((b, i) => {
                        dataBrandC.datasets.push({
                            type: 'line',
                            label: b,
                            borderColor: brands.length > 0 && brands.find(b1 => b1.label === b) && brands.find(b1 => b1.label === b)["color"],
                            borderWidth: 2,
                            fill: false,
                            data: campaignData["brand"][b].sort(function (a, b) {
                                return (a["x"] - b["x"])
                            }),
                            lineTension: 0,
                            // hidden: !selectedBrands.includes(b)
                        });
                    });

                    dataBrand.push({
                        "campaign_id": c_id,
                        "data": dataBrandC,
                        "brands": campaignData["brand_names"]
                    });


                    let dataCategoryC = {
                        labels: months,
                        datasets: []
                    };

                    campaignData["category_names"].forEach((c, i) => {
                        dataCategoryC.datasets.push({
                            type: 'line',
                            label: c,
                            borderColor: categories.length > 0 && categories.find(c1 => c1.label === c) && categories.find(c1 => c1.label === c)["color"],
                            borderWidth: 2,
                            fill: false,
                            data: campaignData["category"][c].sort(function (a, b) {
                                return (a["x"] - b["x"])
                            }),
                            lineTension: 0,
                            // hidden: !selectedCategories.includes(c)
                        });
                    });

                    dataCategory.push({
                        "campaign_id": c_id,
                        "data": dataCategoryC,
                        "categories": campaignData["category_names"]
                    });
                }
        });

        setDataOverall(dataOverall);
        setDataBrand(dataBrand);
        setDataCategory(dataCategory);

        setOptions({
            responsive: true,
            spanGaps: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    ticks: {
                        callback: function(value) {
                            return this.getLabelForValue(value).toString().substring(0,4) + "-" + this.getLabelForValue(value).toString().substring(4);
                        },
                    },
                    title: {
                        display: true,
                        text: "Months"
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: "Percentage Change (%)"
                    }
                }
            },

            plugins: {
                labels: false,
                legend:{
                    display:true,
                    maxWidth:80,
                    position: "right",
                    align:"left",
                    labels:{
                        textAlign:"left",
                        usePointStyle:true,
                        pointStyleWidth:1,
                        font:{
                            size:12
                        },
                        sort: function(a, b){
                            return a.text.localeCompare(b.text, undefined, {sensitivity: 'base'})
                        },

                    }

                },
                legendCallback:{

                },
                tooltip: {
                    mode: 'point',
                    intersect: false,
                    titleFontSize: 20,
                    bodyFontSize: 15,
                    bodySpacing: 5,
                    displayColors: true,
                    callbacks:{
                        label: function(item){
                            return item.dataset.label + " : % " + item.raw.y + " (" + item.raw.price + ")"
                        }
                    },
                },
            }
        });
        setLoading(false);

    }, [selectedBrands, selectedCategories, reportData]);

    const search = () => {
        if (selectedCampaigns && dates[0] && dates[1]) {
            setLoading(true);
            dashboardService.getMonthlyAveragePriceComparison(selectedCampaigns, dates, [], []).then(reportData=>{

                let colors = ['#845EC2', '#D65DB1', '#FF6F91', '#FF9671', '#FFC75F', '#F9F871', '#2C73D2', '#0081CF', '#0089BA', '#008E9B', '#008F7A', '#B39CD0', '#FBEAFF', '#00C9A7', '#C4FCEF', '#4D8076', '#4B4453', '#B0A8B9', '#C34A36', '#FF8066', '#4E8397', '#D5CABD', '#F3C5FF', '#FEFEDF', '#DBE7ED', '#39829B'];

                setReportData(reportData);

                let brands = [];
                let categories = [];

                selectedCampaigns.forEach(c_id=>{
                    let campaignData = reportData.find(r=> r.campaign_id ===c_id)["data"];
                    brands = [...new Set([...brands ,...campaignData["brand_names"]])];
                    categories = [...new Set([...categories ,...campaignData["category_names"]])];
                });
                setBrands([...brands.sort((a,b)=>{
                    return a.localeCompare(b, undefined, {sensitivity: 'base'})
                }).map((b,i)=>{return {"label":b, "value":b, "color": colors[i%26]}})]);
                setCategories([...categories.sort((a,b)=>{
                    return a.localeCompare(b, undefined, {sensitivity: 'base'})
                }).map((c,i)=>{return {"label":c, "value":c, "color": colors[i%26]}})]);
                setSelectedBrands([...brands.sort((a,b)=>{
                    return a.localeCompare(b, undefined, {sensitivity: 'base'})
                })]);
                setSelectedCategories([...categories.sort((a,b)=>{
                    return a.localeCompare(b, undefined, {sensitivity: 'base'})
                })]);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
        else
            toast.current.show({severity: 'warn', summary: t('dashboard.brand_comparison_please_select'), detail: t('message_detail.required')});


    }

    const filterByBrandCategory = () =>{

        if (selectedCampaigns && dates[0] && dates[1] && (selectedBrands.length !== 0 || selectedCategories.length !== 0)) {
            setLoading(true);
            dashboardService.getMonthlyAveragePriceComparison(selectedCampaigns, dates, selectedBrands, selectedCategories).then(reportData=>{
                setReportData(reportData);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
        else
            toast.current.show({severity: 'warn', summary: t('dashboard.brand_comparison_please_select'), detail: t('message_detail.required')});
    }

    const classForColumn = selectedCampaigns && selectedCampaigns.length < 6 ? (selectedCampaigns.length < 4 ? "p-col-3" : "p-col-2") : "p-col";

    const template = (item) =>{
        return <div><span style={{"height":"15px", "width":"15px", "borderRadius":"50%", "display": "inline-block", "border":"2px solid" + item.color}}></span> {item.label}</div>
    };
    return <React.Fragment>


        <div className="p-grid card p-fluid">
            <div className="p-col-12">
                <h3>{t('dashboard.brand_comparison')}</h3>
                <hr></hr>
            </div>

            <div className="p-col-fixed" style={{width:"15em"}}>
                <span className="p-float-label">
                    <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" view="month" dateFormat="yy/mm"
                              yearNavigator yearRange="2021:2022"
                              numberOfMonths={2} value={dates} onChange={(e) => setDates(getKeyValue(e).value)} showIcon={true} showButtonBar={true}/>
                    <label htmlFor="dates">{t('date.months')}</label>
                </span>
            </div>
            <div className="p-col-fixed" style={{width:"15em"}}>

                <span className="p-float-label">
                    <MultiSelect name="campaigns" value={selectedCampaigns} className="p-mr-1 p-ml-1"
                                 options={campaigns} optionLabel="name" optionValue="id"
                                 filter={true} maxSelectedLabels={1}
                                 onChange={(e) => {
                                     setSelectedCampaigns(e.value)
                                 }}/>
                    <label htmlFor="campaigns">{t('campaign.campaigns')}</label>
                </span>
            </div>
            <div className="p-col p-mt-2">
                <Button tooltip={t('actions.search')} tooltipOptions={{position: 'bottom'}} icon="pi pi-search"
                        className="p-button-outlined" onClick={search} />
            </div>
            {loading ?
                <div className="p-col-12" style={{textAlign:'center'}}>
                    <Loading/>
                </div> :
                reportData && selectedCampaigns && <React.Fragment>
                    <div className="p-col-12 p-grid">
                        <div className="p-col-fixed" style={{width:"15em"}}>
                            <span className="p-float-label">
                                <MultiSelect name="selectedBrands" value={selectedBrands} className="p-mr-1 p-ml-1"
                                             options={brands}
                                             filter={true} maxSelectedLabels={2}
                                             onChange={(e) => {setSelectedBrands(getKeyValue(e).value)}}
                                             itemTemplate={template}
                                />
                                <label htmlFor="selectedBrands">{t('product.brand')}</label>
                            </span>
                        </div>
                        <div className="p-col-fixed" style={{width:"15em"}}>
                            <span className="p-float-label">
                                <MultiSelect name="selectedCategories" value={selectedCategories}
                                             options={categories}
                                             filter={true} maxSelectedLabels={2}
                                             onChange={(e) => {setSelectedCategories(getKeyValue(e).value)}}
                                             itemTemplate={template}
                                />
                                <label htmlFor="selectedCategories">{t('product.category')}</label>
                            </span>
                        </div>
                        <div className="p-col p-mt-2">
                            <Button tooltip={t('actions.filter')} tooltipOptions={{position: 'bottom'}} icon="pi pi-filter"
                                    className="p-button-outlined" onClick={filterByBrandCategory} />
                        </div>
                    </div>
                    <div className="p-col-12 p-grid">

                        <div key="header" className={classForColumn + " p-grid"}>
                            <div className="p-grid p-col-12 p-nogutter">
                                <div className="p-col-12 p-mb-2" style={{height: "40px"}}>
                                    <center><b> </b></center>
                                </div>
                                <div className="p-grid p-col-12 p-align-center p-justify-center p-mb-2" style={{height: "200px", backgroundColor: "#FEFCFF", border: "1px solid #F8F6F0" }}>
                                    <center>
                                        <b>Overall Price Increase View</b><br/>
                                        Monthly View
                                    </center>
                                </div>
                                <div className="p-grid p-col-12 p-align-center p-justify-center p-mb-2" style={{height: "350px", backgroundColor: "#FEFCFF", border: "1px solid #F8F6F0" }}>
                                    <center>
                                        <b>Brand Price Increase View</b><br/>
                                        Monthly View
                                    </center>
                                </div>
                                <div className="p-grid p-col-12 p-align-center p-justify-center" style={{height: "350px", backgroundColor: "#FEFCFF", border: "1px solid #F8F6F0" }}>
                                    <center>
                                        <b>Category Price Increase View</b><br/>
                                        Monthly View
                                    </center>
                                </div>
                            </div>
                        </div>

                        {selectedCampaigns.map(c=> {
                            return dataOverall.find(d=>d.campaign_id===c)&&<div key={c} className={classForColumn + " p-grid"}>
                                    <div className="p-grid p-col-12 p-nogutter">
                                        <div className="p-col-12 p-mb-2" style={{height: "40px"}}>
                                            <center><b>{campaigns.find(c1=>c1.id===c)["name"]}</b></center>
                                        </div>
                                        <div className="p-col-12 p-mb-2" style={{height: "200px", backgroundColor: "#FEFCFF", border: "1px solid #F8F6F0" }}>
                                            <Line data={dataOverall.find(d=>d.campaign_id===c)["data"]} options={options}/>
                                        </div>
                                        <div className="p-col-12 p-mb-2" style={{height: "350px", backgroundColor: "#FEFCFF", border: "1px solid #F8F6F0" }}>
                                            <Line data={dataBrand.find(d=>d.campaign_id===c)["data"]} options={options} />
                                        </div>
                                        <div className="p-col-12" style={{height: "350px", backgroundColor: "#FEFCFF", border: "1px solid #F8F6F0" }}>
                                            <Line data={dataCategory.find(d=>d.campaign_id===c)["data"]} options={options}/>
                                        </div>
                                    </div>
                            </div>;
                        })}
                    </div>
                </React.Fragment>
            }
        </div>

    </React.Fragment>;
};
export default BrandComparison;
