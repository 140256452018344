import React from 'react';

import {Message} from 'primereact/message';

const Page404 = (props) =>{

    return <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h3>The Page can not be found</h3>
                    <div className="p-col-12">
                        <Message severity="error" text={"No match found for " + props.location.pathname} />
                    </div>
                </div>
            </div>
        </div>;
};
export default Page404;
