import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';


import {AuthService} from "./service/AuthService";
import {LanguageService} from "./service/LanguageService";
import {getKeyValue, isAdmin} from "./utils/Utils";
import UserGuiding from "./utils/UserGuiding";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";
import {AppContext} from "./HomePage";
import Contact from "./components/Account/Contact";
import {Button} from "primereact/button";
import {CampaignContext} from "./App";
import DownloadReport from "./components/Export/DownloadReport";

const AppTopbar  = (props) => {

    const {t, toast, host} = React.useContext(AppContext);
    const {campaigns, selectedCampaign, selectCampaign} = React.useContext(CampaignContext);

    const [showTourGlobe, setShowTourGlobe] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [tourPage, setTourPage] = useState("");

    useEffect ( ()=>{
        if (showTourGlobe === false && ( window.location.hash === "#/campaign" || window.location.hash === "#/product"))
        {
            setShowTourGlobe(true);
            setTourPage(window.location.hash.replace("#/", ""));
        }
        else if (showTourGlobe === true && window.location.hash !== "#/campaign" && window.location.hash !== "#/product") {
            setShowTourGlobe(false);
            setTourPage(window.location.hash.replace("#/", ""));
        }

        if (host === "ecomscanner")
            setEn();
    },[]);


    const logout = () => {
        AuthService.logout();
    }

    const setEn = () =>{
        LanguageService.setLanguage("en");
    };

    return (
        <div className={"p-grid layout-topbar clearfix p-nogutter" + (host === "ecomscanner" ? " ecomscanner" : "")}>
            {showTourGlobe && <UserGuiding tourPage={tourPage} openTour={isTourOpen} closeTour={()=> {setIsTourOpen(false);}} />}
            <div className="p-col-fixed" style={{width:'15em'}}>
                {host === "ecomscanner" ? <img className="logo" alt="logo" src="/assets/layout/images/logo_ecomscanner.png" />:
                    <img className="logo" alt="logo" src="/assets/layout/images/logo_white.png" /> }

                <Button icon="pi pi-bars" className="p-link layout-menu-button p-button-text" onClick={props.onToggleMenu} />
            </div>
            <div className="p-col-fixed campaign-bar">
            {campaigns && campaigns.length > 1 &&
                <Dropdown id="state" name="state" className="campaignSelect" style={{verticalAlign:"inherit", marginLeft:"0.5em"}}
                          options={campaigns.sort((a, b) => (a.name ? a.name.toLowerCase() : "").localeCompare((b.name ? b.name.toLowerCase() : ""), undefined, {sensitivity: 'base'}))}
                          optionLabel="name" optionValue="id"
                          value={selectedCampaign ? selectedCampaign.id : null}
                          onChange={(e) => {selectCampaign(getKeyValue(e).value);}}
                    //value={filters.state} options={error_states(t)} onChange={onChange} style={{width:'100%'}}
                />}
            </div>
            <div className="p-col"></div>
            <div className="p-col-fixed" style={{width:'30em'}}>
                <div className="layout-topbar-icons">

                    <DownloadReport />

                    {showTourGlobe &&
                    <Button icon="pi pi-globe" className="p-button-text" onClick={() => {setIsTourOpen(true);}} tooltip={t('actions.start_tour')} tooltipOptions={{position: 'bottom'}}  />}

                    <Contact />

                    <Button icon="pi pi-user" className="p-button-text" onClick={() => {window.location = "#/account"}} tooltip={t('menu_item.account')} tooltipOptions={{position: 'bottom'}}  />

                    <Button icon="pi pi-power-off" className="p-button-text" onClick={logout} tooltip={t('actions.logout')} tooltipOptions={{position: 'bottom'}}  />

                    {isAdmin() &&
                        <Checkbox style={{marginBottom:"0.5em"}} value={isAdmin()} checked={isAdmin()} onChange={(e)=>{AuthService.changeIsAdmin()}}/>
                    }

                </div>
            </div>
        </div>
    );
}
AppTopbar.prototype = {
    onToggleMenu: PropTypes.func.isRequired
}
export default AppTopbar;
