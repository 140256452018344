import React, { useState} from 'react';


import {AppContext} from "../../HomePage";
import {Loading} from "../../utils/InlineComponents";
import {Button} from "primereact/button";
import {CleanupService} from "../../service/CleanupService";
import {Dropdown} from "primereact/dropdown";
import {ProductService} from "../../service/ProductService";
import {InputNumber} from "primereact/inputnumber";


const CleanUp = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const campaign = props.campaign;
    const [loading, setLoading] = useState(false);
    const [selectedField, setSelectedField] = useState("sku");
    const [days, setDays] = useState(100);

    const cleanupService = new CleanupService();
    const productService = new ProductService();

    const cleanDuplicateProducts = (rowData) => {
        setLoading(true);
        cleanupService.deleteDuplicateProducts(campaign.id, selectedField).then(()=>{
            toast.current.show({severity: 'success', summary: t('campaign.products.clear_duplicates'), detail: t('message_detail.successful')});
            setLoading(false);
        }).catch((error)=>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const cleanDuplicateSellers = (rowData) => {
        setLoading(true);
        cleanupService.deleteDuplicateSellers(campaign.id).then(()=>{
            toast.current.show({severity: 'success', summary: t('clean_up.remove_duplicate_sellers'), detail: t('message_detail.successful')});
            setLoading(false);
        }).catch((error)=>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const cleanDuplicateSellersByName = (rowData) => {
        setLoading(true);
        cleanupService.deleteDuplicateSellersByName(campaign.id).then(()=>{
            toast.current.show({severity: 'success', summary: t('clean_up.remove_duplicate_sellers_by_name'), detail: t('message_detail.successful')});
            setLoading(false);
        }).catch((error)=>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const cleanDuplicateSuggestions = (rowData) => {
        setLoading(true);
        cleanupService.deleteDuplicateSuggestions(campaign.id).then(()=>{
            toast.current.show({severity: 'success', summary: t('clean_up.remove_duplicate_suggestions'), detail: t('message_detail.successful')});
            setLoading(false);
        }).catch((error)=>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const deactivateProductsWithNoLongerAvailableOwner = (rowData) => {
        setLoading(true);
        cleanupService.deactivateProductsWithNoLongerAvailableOwner(campaign.id, days).then(()=>{
            toast.current.show({severity: 'success', summary: t('clean_up.deactivate_products_with_no_longer_available_owner'), detail: t('message_detail.successful')});
            setLoading(false);
        }).catch((error)=>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }



    const setSuggestionsUnmatched = (rowData) => {
        setLoading(true);
        cleanupService.setSuggestionsUnmatched(campaign.id).then(()=>{
            toast.current.show({severity: 'success', summary: t('clean_up.set_suggestions_unmatched'), detail: t('message_detail.successful')});
            setLoading(false);
        }).catch((error)=>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const clearViolationStrategies = (rowData) => {
        setLoading(true);
        cleanupService.clearViolationStrategies(campaign.id).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.clear_violation_strategies'), detail: t('message_detail.successful')});
            setLoading(false);
        }).catch((error)=>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const removeIsMapIsMrpFromCompetitors = (rowData) => {
        setLoading(true);
        cleanupService.removeIsMapIsMrpFromCompetitors(campaign.id).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.remove_ismap_ismrp_from_competitors'), detail: t('message_detail.successful')});
            setLoading(false);
        }).catch((error)=>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const refreshImageUrls = () => {
        productService.refreshImageUrls(campaign.id).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.refresh_image_urls'), detail: t('message_detail.successful')});
        });
    };

    const refreshProductNames = () => {
        productService.refreshProductNames(campaign.id).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.refresh_product_names'), detail: t('message_detail.successful')});
        });
    };

    const fields = [
        {name: 'Sku', code: 'sku'},
        {name: 'Mpn', code: 'mpn'},
        {name: 'Ean', code: 'ean'},
        {name: 'Upc', code: 'upc'},
        {name: 'Barcode', code: 'barcode'},
        {name: 'ASIN', code: 'asin'},
        {name: 'Ref ID', code: 'ref_id'}
    ];

    return <React.Fragment>
        {loading ?
            <div className="p-col-12" style={{textAlign: 'center'}}>
                <Loading/>
            </div> :
            <div className="p-grid">
                <div className="p-col-12">
                    <Dropdown value={selectedField} options={fields} onChange={(e) => setSelectedField(e.value)} className="p-mr-1" optionLabel="name" optionValue="code" placeholder="Select a Field" />
                    <Button label={t('clean_up.remove_duplicate_products')} icon="pi pi-list" className="p-button-outlined p-button-danger p-ml-1" onClick={cleanDuplicateProducts}/>
                </div>
                <div className="p-col-12">
                    <Button label={t('clean_up.remove_duplicate_sellers')} icon="pi pi-sitemap" className="p-button-outlined p-button-danger" onClick={cleanDuplicateSellers}/>
                </div>
                <div className="p-col-12">
                    <Button label={t('clean_up.remove_duplicate_sellers_by_name')} icon="pi pi-sitemap" className="p-button-outlined p-button-danger" onClick={cleanDuplicateSellersByName}/>
                </div>
                <div className="p-col-12">
                    <Button label={t('clean_up.remove_duplicate_suggestions')} icon="pi pi-sitemap" className="p-button-outlined p-button-danger" onClick={cleanDuplicateSuggestions}/>
                </div>

                <div className="p-col-12">
                    <InputNumber id="days" name="days" value={days} inputStyle={{width: '75px'}} placeholder={t('date.days')} className="p-mr-1"
                                 onValueChange={(e) => setDays(e.value)} showButtons min={0}/>
                    <Button label={t('clean_up.deactivate_products_with_no_longer_available_owner')} icon="pi pi-sitemap" className="p-button-outlined p-button-danger" onClick={deactivateProductsWithNoLongerAvailableOwner}/>
                </div>



                <div className="p-col-12">
                    <Button label={t('clean_up.set_suggestions_unmatched')} icon="pi pi-sitemap" className="p-button-outlined p-button-danger" onClick={setSuggestionsUnmatched}/>
                </div>
                <div className="p-col-12">
                    <Button label={t('actions.clear_violation_strategies')} icon="pi pi-sitemap" className="p-button-outlined p-button-danger" onClick={clearViolationStrategies}/>
                </div>
                <div className="p-col-12">
                    <Button label={t('actions.remove_ismap_ismrp_from_competitors')} icon="pi pi-sitemap" className="p-button-outlined p-button-danger" onClick={removeIsMapIsMrpFromCompetitors}/>
                </div>
                <div className="p-col-12">
                    <Button label={t('actions.refresh_image_urls')} style={{marginRight:'0.5em'}} icon="pi pi-images" className="p-button-outlined p-button-success" onClick={refreshImageUrls} />
                </div>
                <div className="p-col-12">
                    <Button label={t('actions.refresh_product_names')} style={{marginRight:'0.5em'}} icon="pi pi-refresh" className="p-button-outlined p-button-success" onClick={refreshProductNames} />
                </div>

            </div>}
    </React.Fragment>;

};
export default (CleanUp)
