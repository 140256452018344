import React, {useEffect, useState} from "react";

import {isCampaignDropShipping, isCampaignManufacturer, isCampaignMarketSearch} from "../../utils/Utils";
import {toDateString} from "../../utils/TimeUtil";

import {DashboardService} from "../../service/DashboardService";


import OverviewPrice from "./OverviewPrice";
import OverviewProduct from "./OverviewProduct";
import OverviewCompetitor from "./OverviewCompetitor";
import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";

const Overview = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [overview, setOverview] = useState([]);
    const [date, setDate] = useState(toDateString(new Date()));

    const [loading, setLoading] = useState(false);

    const dashboardService = new DashboardService();

    useEffect ( ()=>{
        search();
    },[selectedCampaign, date]);

    const search = () => {
        setLoading(true);

        dashboardService.getOverview(selectedCampaign.id, date).then(overview=>{
            setOverview(overview);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    }

    return <React.Fragment>

        {loading ?
        <div className="p-col-12" style={{textAlign:'center'}}>
            <Loading/>
        </div> :
        <div className="p-grid p-fluid dashboard card">
            <OverviewProduct overview={overview} isManufacturer={isCampaignManufacturer(selectedCampaign)} isDropShipping={isCampaignDropShipping(selectedCampaign)}/>
            {!isCampaignMarketSearch(selectedCampaign) && <OverviewPrice overview={overview} isManufacturer={isCampaignManufacturer(selectedCampaign)}/>}
            <OverviewCompetitor overview={overview} isManufacturer={isCampaignManufacturer(selectedCampaign)}/>
        </div>}
    </React.Fragment>;
};
export default Overview;
