import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import React, {useEffect, useRef, useState} from "react";

import {formatCurrency, getHostName, getKeyValue, toTitleCase} from "../../utils/Utils";


import {Messages} from "primereact/messages";
import {ProductService} from "../../service/ProductService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";
import {confirmDialog} from "primereact/confirmdialog";
import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {Message} from "primereact/message";
import {InputText} from "primereact/inputtext";
import {Chips} from "primereact/chips";
import {TabMenu} from "primereact/tabmenu";

const ProductBulkOperations = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {campaigns, selectedCampaign} = React.useContext(CampaignContext);
    const messages = useRef(null);

    const [campaign, setCampaign] = useState(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(false);


    const [bulkValues, setBulkValues] = useState({brand:"", category:"", properties:[], tags:[]});

    const productService = new ProductService();

    useEffect ( ()=>{
        setSelectedItems([...props.filteredProducts]);

        let properties = [];
        selectedCampaign?.settings?.special_fields?.forEach(sf=> {
            if (sf.selected && sf.name.startsWith("properties.") && !properties.some(p=> p.name === sf.name.replace("properties.", "")))
                properties.push({name:sf.name.replace("properties.", ""), value:""});
        });

        setBulkValues({...bulkValues, properties: properties});

    },[]);

    const deactivateProducts = () => {
        let data = {"campaign_id": selectedCampaign.id, "product_ids": selectedItems.map(s=> s.id)};
        setLoading(true);
        productService.updateAllProductsIsActive(data, true).then(() => {
            setLoading(false);
            setSelectedItems([]);
            props.refreshProducts();
            toast.current.show({severity: 'success', summary: t('actions.deactivate'), detail: t('message_detail.successful') });
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const activateProducts = () => {
        let data = {"campaign_id": selectedCampaign.id, "product_ids": selectedItems.map(s=> s.id)};
        setLoading(true);
        productService.updateAllProductsIsActive(data, false).then(() => {
            setLoading(false);
            setSelectedItems([]);
            props.refreshProducts();
            toast.current.show({severity: 'success', summary: t('actions.activate'), detail: t('message_detail.successful') });
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const removeProducts = () => {
        let data = {"campaign_id": selectedCampaign.id, "product_ids": selectedItems.map(s=> s.id)};
        setLoading(true);
        productService.removeAllProducts(data).then(() => {
            setLoading(false);
            setSelectedItems([]);
            props.refreshProducts();
            toast.current.show({severity: 'success', summary: t('actions.remove'), detail: t('message_detail.successful') });
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });

    }

    const moveSelectedProducts = () => {
        if (!campaign || selectedItems.length === 0) {
            toast.current.show({severity: 'warn', summary: t('product.nothing_to_move')});
            return;
        }
        let data = {"campaign_id_from": selectedCampaign.id,
            "campaign_id_to": campaign.id,
            "product_ids": selectedItems.map(s=> s.id)};

        setLoading(true);
        productService.moveProducts(data).then(() => {
            setSelectedItems([]);
            props.refreshProducts();
            setLoading(false);
            toast.current.show({severity: 'success', summary: t('actions.move_products', {number:""}), detail: t('message_detail.successful') });
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    };

    const updateSelectedProducts = () => {
        let data = {"campaign_id": selectedCampaign.id,
            "product_ids": selectedItems.map(s=> s.id),
            brand: bulkValues.brand,
            category: bulkValues.category,
            sub_category: bulkValues.sub_category,
            type: bulkValues.type,
            tags: bulkValues.tags,
            properties: bulkValues.properties};
        setLoading(true);
        productService.bulkUpdateProducts(data).then(() => {
            setLoading(false);
            setSelectedItems([]);
            props.refreshProducts();
            toast.current.show({severity: 'success', summary: t('actions.update'), detail: t('message_detail.successful') });
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const productNameTemplate = (rowData) => {
        let variantList = null;
        if (rowData.properties !== null) {
            variantList = rowData.properties.map(property => {
                return <div><b>{property.name} : </b>{property.value}</div>;
            });
        }

        return  <React.Fragment> <div>{rowData.name && rowData.name !== "" ? rowData.name : getHostName(rowData.product_url)}</div>
            {rowData.barcode && <div><b>{t('product.barcode')} : </b>{rowData.barcode}</div>}
            {rowData.sku && <div><b>{t('product.sku')} : </b>{rowData.sku}</div>}
            {variantList!== null ? variantList :""}
        </React.Fragment>;
    };

    const productPriceTemplate = (rowData) => {

        let color = "black";
        if (rowData.price && rowData.competitorsMinPrice && rowData.price < rowData.competitorsMinPrice )
            color= "green";
        else if (rowData.price && rowData.competitorsMinPrice && rowData.competitorsMaxPrice && rowData.price > rowData.competitorsMinPrice && rowData.price < rowData.competitorsMaxPrice )
            color= "firebrick";
            // else if (rowData.price && rowData.competitorsMinPrice && rowData.competitorsAvgPrice && rowData.price > rowData.competitorsAvgPrice && rowData.price < rowData.competitorsMaxPrice)
        //     color= "red";
        else if (rowData.price && rowData.competitorsMaxPrice && rowData.price > rowData.competitorsMaxPrice )
            color= "firebrick";

        return <div className="p-grid p-justify-end" style={{whiteSpace: 'nowrap', color: color}}>{formatCurrency(rowData.price, rowData.currency)}</div>
    };

    const statusTemplate = (rowData) => {
        return <div className="p-grid p-justify-end" style={{whiteSpace: 'nowrap'}}>{rowData.is_active ? t('actions.active') : t('actions.passive')}</div>;
    };

    const sortName = (event) =>
    {
        return props.filteredProducts.sort(function(a, b){return (event.order * ((a["name"] && b["name"]) ? a["name"].localeCompare(b["name"] , undefined, {sensitivity: 'base'}): 1));});
    };

    const onChange = (e) =>{
        const { key, value } = getKeyValue(e);
        if (key.startsWith('properties.'))
        {
            let p = bulkValues.properties ? bulkValues.properties : [];
            p.forEach(pp => {
                if (pp.name === key.split('.')[1])
                    pp.value = value;
            })
            setBulkValues({...bulkValues, ['properties'] : p});
        }
        else {
            setBulkValues({...bulkValues, [key] : value});
        }
    };

    let topMenuItems = [{label: t('actions.move_products', {number:""})},
        {label: t('actions.update_all', {number:""})},
        {label: t('actions.bulk_operations')}];

    return <Dialog header={t('product.move_message_1')} visible={true} contentStyle={{maxHeight: '60vh', overflow:"auto"}} style={{maxWidth: '60vw', minWidth: '60vw'}} modal={true} onHide={() => props.hideDialog()}>

        <div className="p-grid">

        <Messages ref={messages}/>
        {loading ?
            <div className="p-col-12" style={{textAlign: 'center'}}>
                <Loading/>
            </div> :

        <TabMenu model={topMenuItems}
                 activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        </TabMenu>}

        {activeIndex === 0 ?
            <div className="p-col-12">
                <Dropdown placeholder={t('product.campaign_to_move')} className="p-mr-1" id="campaign" name="campaign" value={campaign} options={campaigns ? campaigns.filter(c => c.id !== selectedCampaign.id) : []} onChange={(e) => {setCampaign(e.value);}} optionLabel="name"/>
                <Button label={t('actions.move_products', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})} icon="pi pi-upload" className="p-button-outlined p-button-success p-mr-1" onClick={() => {
                        confirmDialog({
                        message: t('dialogs.product.move_msg'),
                        header: t('dialogs.product.move'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        accept: moveSelectedProducts,
                        acceptLabel: t('yes_no.yes'),
                        rejectLabel: t('yes_no.no')
                    });
                }}/>
                {campaign ? <Message severity="info" text={t('product.move_message_2', {count: selectedItems.length.toString(), current_campaign: selectedCampaign.name, new_campaign: campaign.name})}/> : ""}
            </div>
            : activeIndex === 1 ?
            <div className="p-col-12 p-grid">
                <span className="p-float-label p-mr-1">
                    <InputText id="category" name="category" value={bulkValues.category} onChange={onChange}/>
                    <label htmlFor="category">{t('product.category')}</label>
                </span>

                {selectedCampaign.settings?.special_fields?.find(sf => sf.name==="sub_category" && sf.selected) &&
                <span className="p-float-label p-mr-1">
                    <InputText id="sub_category" name="sub_category" value={bulkValues.sub_category} onChange={onChange}/>
                    <label htmlFor="sub_category">{t('product.sub_category')}</label>
                </span>}
                {selectedCampaign.settings?.special_fields?.find(sf => sf.name==="type" && sf.selected) &&
                <span className="p-float-label p-mr-1">
                    <InputText id="type" name="type" value={bulkValues.type} onChange={onChange}/>
                    <label htmlFor="type">{t('product.type')}</label>
                </span>}
                <span className="p-float-label p-mr-1">
                    <InputText id="brand" name="brand" value={bulkValues.brand} onChange={onChange}/>
                    <label htmlFor="brand">{t('product.brand')}</label>
                </span>
                <span className="p-float-label p-mr-1">
                    <Chips id="tags" name="tags" value={bulkValues.tags} onChange={onChange} onBlur={(e) =>{
                        if (e.target.value){
                            let tags = [...bulkValues.tags];
                            tags.push(e.target.value);
                            e.target.value = "";
                            setBulkValues({...bulkValues, "tags" : tags});
                        }
                    }}/>
                    <label htmlFor="brand">{t('product.tags')}</label>
                </span>
            {bulkValues.properties && bulkValues.properties.map(prop=> {
                return <span className="p-float-label p-mr-1">
                <InputText id={"properties." + prop.name} name={"properties." + prop.name} value={prop.value ?? ""} onChange={onChange}/>
                <label htmlFor={"properties." + prop.name}>{toTitleCase(prop.name.replace("_", " "))}</label>
                </span>}
                )}
                <Button id="edit" label={t('actions.update_all', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})}
                icon="pi pi-pencil" className="p-button-outlined p-button-success p-mt-2" onClick={updateSelectedProducts}/>
            </div>
            :
            <div className="p-col-12">
                <Button label={t('actions.deactivate_all', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})}
                        icon="pi pi-times" className="p-button-outlined p-button-warning p-mr-1" onClick={() => {
                        confirmDialog({
                        message: t('dialogs.product.deactivate_msg'),
                        header: t('dialogs.product.deactivate'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        accept: deactivateProducts,
                        acceptLabel: t('yes_no.yes'),
                        rejectLabel: t('yes_no.no')
                    });
                }}/>
                <Button label={t('actions.activate_all', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})}
                        icon="pi pi-check" className="p-button-outlined p-button-info p-mr-1" onClick={() => {
                        confirmDialog({
                        message: t('dialogs.product.activate_msg'),
                        header: t('dialogs.product.activate'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        accept: activateProducts,
                        acceptLabel: t('yes_no.yes'),
                        rejectLabel: t('yes_no.no')
                    });
                }}/>
                <Button label={t('actions.remove_all', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})}
                        icon="pi pi-trash" className="p-button-outlined p-button-danger p-mr-1" onClick={() => {
                        confirmDialog({
                        message: t('dialogs.product.delete_msg'),
                        header: t('dialogs.product.delete'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        accept: removeProducts,
                        acceptLabel: t('yes_no.yes'),
                        rejectLabel: t('yes_no.no')
                    });
                }}/>
            </div>
        }



        <DataTable value={props.filteredProducts}
                   responsive={true}
                   resizableColumns={true} columnResizeMode="fit"
                   footer={t('product.footer', {count: props.filteredProducts.length.toString(), filteredCount: selectedItems.length.toString()})}
                   selectionMode="multiple" metaKeySelection={false}
                   selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                   sortField={props.sortField} sortOrder={props.sortOrder}>
            <Column selectionMode="multiple" style={{width: '3em'}}/>
            <Column body={IndexTemplate} style={{width: '3em'}}/>
            <Column field="name" header={t('product.product_name')} body={productNameTemplate} sortable={true} sortFunction={sortName} style={{width: '40%'}}/>
            <Column field="category" header={t('product.category')} sortable={true} style={{width: '20%'}}/>
            <Column field="brand" header={t('product.brand')} sortable={true} style={{width: '20%'}}/>
            <Column field="price" header={t('product.price')} body={productPriceTemplate} style={{width: '10%'}}/>
            <Column field="is_active" header={t('product.status')} body={statusTemplate} sortable={true} style={{width: '10%'}}/>
        </DataTable>
        </div>
    </Dialog>;
};
export default ProductBulkOperations
