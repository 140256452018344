import React, {useEffect, useState} from "react";

import {
    exportFile,
    getKeyValue,
    userId
} from "../../utils/Utils";
import {toDateStringIgnoringTimezone} from "../../utils/TimeUtil";

import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Calendar} from "primereact/calendar";


import {ExportService} from "../../service/ExportService";
import {Button} from "primereact/button";
import {IndexTemplate, Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {InputText} from "primereact/inputtext";
import {QueueService} from "../../service/QueueService";
import {CampaignContext} from "../../App";
import {SelectButton} from "primereact/selectbutton";
import {Bar} from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import SellerDetail from "./SellerDetail";


const SellersSummary = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);
    const [sellersSummary, setSellersSummary] = useState([]);
    const [date, setDate] = useState(toDateStringIgnoringTimezone(new Date()));

    const [loading, setLoading] = useState(false);

    const [expandedSeller, setExpandedSeller] = useState(null);


    const [competitor_name, setCompetitorName] = useState("");
    const [show_violators, setShowViolators] = useState(true);

    const dashboardService = new DashboardService();

    const violatorsOptions = [
        {label: t('price_violation.violators'), value: true},
        {label: t('price_violation.all'), value: false}
    ];

    useEffect ( ()=>{
        search();
    },[selectedCampaign, date]);

    const search = () => {
        if (selectedCampaign) {
            setLoading(true);
            dashboardService.getSellersSummary(selectedCampaign.id, date).then(sellersSummary=>{
                setSellersSummary(sellersSummary);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    }


    const previousDate = () => {
        let previous_day = (new Date(date)).getDate() - 1
        let previous_date = new Date(new Date(date).setDate(previous_day));
        setDate(toDateStringIgnoringTimezone(previous_date));
    }

    const nextDate = () => {
        let next_day = (new Date(date)).getDate() + 1
        let next_date = new Date(new Date(date).setDate(next_day));
        setDate(toDateStringIgnoringTimezone(next_date));
    }

    const selectDate = (e) => {
        setDate(toDateStringIgnoringTimezone(e.value));
    }

    const graphTemplate = (rowData) => {
        let priceData = {
            labels: ['price1', 'price1', 'price1'],
            datasets: []
        };

        let data1 =[];
        let data2 =[];
        let data3 =[];
        data1.push(rowData.below_map);
        data2.push(rowData.equal_map);
        data3.push(rowData.above_map);

        priceData.datasets.push({
            type: 'bar',
            label: t('product_detail.below_map'),
            backgroundColor: 'lightpink',
            data: data1,
        });
        priceData.datasets.push({
            type: 'bar',
            label: t('product_detail.equal_map'),
            backgroundColor: 'lightblue',
            data: data2,
        });
        priceData.datasets.push({
            type: 'bar',
            label: t('product_detail.above_map'),
            backgroundColor: 'lightgreen',
            data: data3,
        });
        let options = {
            indexAxis: 'y',
            responsive: true,
            spanGaps: true,
            maintainAspectRatio: false,
            barThickness: 35,
            scales: {
                x: {
                    stacked: true,
                    display: false
                },
                y: {
                    stacked: true,
                    display: false
                }
            },

            plugins: {
                labels: false,
                tooltip: false,
                legend:{
                    display: false
                },
                stacked100: { enable: true, replaceTooltipLabel: false },
                datalabels: {
                    align: 'bottom',
                    anchor: 'center',
                    formatter: function(_value, context) {
                        const data = context.chart.data;
                        const { datasetIndex, dataIndex } = context;

                        if (data.originalData[datasetIndex][dataIndex] < 1)
                            return "";
                        let total = 0;
                        data.originalData.forEach(x=> {
                            total = total + x[0];
                        })
                        return data.originalData[datasetIndex][dataIndex] + "/" + total;
                    }
                },
            }
        };

        return <Bar id="comparison" data={priceData} plugins={[ChartjsPluginStacked100, ChartDataLabels]} options={options} height={20} />
    };

    const filtered_sellers_summary = sellersSummary.filter(seller=>
        (!show_violators || (show_violators && seller.below_map > 0)) &&
        (competitor_name==="" || (seller.name && seller.name.toLowerCase().indexOf(competitor_name.toLowerCase()) !== -1))
    );

    const header = <div className="p-grid">
        <div className="p-col-12">
            <label htmlFor="competitor_name">{t('product_detail.competitor')}</label>
            <InputText id="competitor_name" value={competitor_name} onChange={(e) => {setCompetitorName( getKeyValue(e).value)}}/>
            {/*<SelectButton value={violationType} optionLabel="label" options={violationTypeOptions}*/}
            {/*              onChange={(e) => {setViolationType( getKeyValue(e).value)}}*/}
            {/*              style={{ marginRight:'.5em', float: 'right'}}/>*/}
            <SelectButton value={show_violators} optionLabel="label" options={violatorsOptions}
                          onChange={(e) => {setShowViolators( getKeyValue(e).value)}}
                          style={{ marginRight:'.5em', float: 'right'}}/>
        </div>
    </div>;

    const sellerRowExpansionTemplate = (rowData) => {
        return <SellerDetail competitor_name={rowData.name} />;
    };

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.sellers_summary')}</h3>
                <hr></hr>
            </div>

            <div className="p-col-9">
                <Button tooltip={t('actions.previous')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left"
                        className="p-button-outlined" onClick={previousDate} style={{marginRight:'0.5em'}} />
                <Calendar id="date" name="date" readOnlyInput={true} value={new Date(date)} onChange={selectDate} showIcon={true} dateFormat="yy-mm-dd" style={{marginRight:'0.5em'}} />
                <Button tooltip={t('actions.next')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right"
                        className="p-button-outlined" onClick={nextDate} style={{marginRight:'0.5em'}}/>
                {/*<Button icon="pi pi-file-excel" style={{marginRight:'0.5em'}} className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />*/}
                {/*<Button icon="pi pi-envelope" style={{marginRight:'0.5em'}} className="p-button-outlined p-button-success" label={t('actions.send_email')} onClick={sendEmail} />*/}
            </div>


            <div className="p-col-12">
                {loading ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div> :

                    <DataTable value={filtered_sellers_summary} responsive={true} header={header} sortField="below_map" sortOrder={-1}
                               expandedRows={expandedSeller}
                               onRowToggle={(e) => {
                                   setExpandedSeller(e.data);
                               }}
                               rowExpansionTemplate={sellerRowExpansionTemplate}
                    >
                        <Column expander={true} style={{width:"3.3em"}}/>
                        <Column body={IndexTemplate} headerStyle={{width: '3em'}}/>
                        <Column field="name" sortable header={t('product_detail.competitor')}/>
                        <Column field="below_map" sortable header={t('price_violation.violators')}/>
                        <Column body={graphTemplate} header={t('price_violation.graph')}/>
                    </DataTable>
                }
            </div>

        </div>

    </React.Fragment>;
};
export default SellersSummary;
