import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import React, {useState} from "react";
import {Button} from "primereact/button";
import {formatCurrency, formValid, getKeyValue, urlValidator} from "../../utils/Utils";

import {Sidebar} from "primereact/sidebar";
import {AppContext} from "../../HomePage";
import CopyToClipboard from "../../utils/CopyToClipboard";

const ProductMerge = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const classForColumn = props.productsToMerge.length > 4 ? "p-col" : "p-col-2";

    return <div style={{marginLeft:"10em"}}>
        <center><h5>{t('dialogs.product.merge_msg')}</h5></center>
        <div className="p-grid">
            <div className="p-col-11 p-grid">
                <div className="p-col-2 plan-legend">
                    <div className="p-col-12 plan-row" style={{height:"6em"}}>{t('product.image')}</div>
                    <div className="p-col-12 plan-row" style={{height:"2em"}}>{t('product.website')}</div>
                    <div className="p-col-12 plan-row" style={{height:"2em"}}>{t('product.product_name')}</div>
                    <div className="p-col-12 plan-row" style={{height:"2em"}}>{t('product.category')}</div>
                    <div className="p-col-12 plan-row" style={{height:"2em"}}>{t('product.brand')}</div>
                    <div className="p-col-12 plan-row" style={{height:"2em"}}>{t('product.price')}</div>
                </div>
                {props.productsToMerge.map(p => {
                    return <div className={classForColumn + " plan" + (p.keepAsMine ? " premium" : "")}>
                        <div className="p-col-12 plan-row" style={{height:"6em"}}><img referrerPolicy='no-referrer' className="img-fluid-thumb" style={{maxHeight:"5em"}} src={p.image} alt=""/></div>
                        <div className="p-col-12 plan-row" style={{height:"2em"}}>{p.website}</div>
                        <div className="p-col-12 plan-row" style={{height:"2em"}}>{p.name}</div>
                        <div className="p-col-12 plan-row" style={{height:"2em"}}>{p.category}</div>
                        <div className="p-col-12 plan-row" style={{height:"2em"}}>{p.brand}</div>
                        <div className="p-col-12 plan-row" style={{height:"2em"}}>{formatCurrency(p.price, p.currency)}</div>
                        <div className="p-col-12 plan-row" style={{height:"7em"}}>
                            <Button id="set_as_mine" label={t('actions.set_as_mine')} icon="pi pi-star" className="p-button-outlined p-button p-m-1" onClick={()=>{props.setAsInitial(p)}}/>
                            <Button id="remove" label={t('actions.remove')} icon="pi pi-minus" className="p-button-outlined p-button-warning p-m-1" onClick={()=>{props.removeFromMerge(p)}}/>

                        </div>
                    </div>;
                    })
                }
            </div>
            <div className="p-col-1">
                <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning p-m-1" onClick={props.cancel}/>
                {props.productsToMerge.length > 1 &&
                    <Button id="merge" label={t('actions.merge')} icon="pi pi-clone" className="p-button-outlined p-button-success p-m-1" onClick={props.merge}/>}
            </div>

        </div>
    </div>;
};
export default ProductMerge;