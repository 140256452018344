import React, {useEffect} from "react";
import {DataView} from "primereact/dataview";
import {toDateString} from "../../utils/TimeUtil";

import {OverallReviewRating} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {ScrollPanel} from "primereact/scrollpanel";
import {Rating} from "primereact/rating";

const ProductReviews = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    let selectedProduct = {...props.selectedProduct};
    let myProduct = {...props.myProduct};

    useEffect ( ()=>{
        selectedProduct = {...props.selectedProduct};
        myProduct = {...props.myProduct};
    },[props.selectedProduct, props.myProduct]);

    const reviewTemplate = (rowData) => {

        return rowData ? <div className="p-col-12 p-grid">
                <div className="p-col-6">
                    <label>{rowData.published_at} <b>{rowData.author}</b></label>
                </div>
                <div className="p-col-6" style={{textAlign:"right"}}>
                    {OverallReviewRating(rowData)}
                </div>
                <div className="p-col-12">
                    {rowData.comment}
                </div>
            </div> : null;
    };

    const ReviewRating = (competitor, t) => {
        return selectedProduct.reviews && selectedProduct.reviews.length ?
            <div className="p-grid">
                {competitor.prices.length !== 0 && competitor.prices[competitor.prices.length - 1].rating ? <Rating value={parseInt(competitor.prices[competitor.prices.length - 1].rating)} readonly cancel={false} style={{color:'#007ad9', width:"115px"}} /> : <></>}
                {competitor.prices.length !== 0 && competitor.prices[competitor.prices.length - 1].rating_count ? <div>{" / " + competitor.prices[competitor.prices.length - 1].rating_count + " " + t('product_detail.ratings')}</div> : <></>}
                <div>{" / " + selectedProduct.reviews.length + " " + t('product_detail.reviews')}</div>
            </div> : "";
    };

    const header = ReviewRating(myProduct, t);

    return <ScrollPanel style={{height: '400px', fontSize:"0.8em"}}>
        <DataView value={selectedProduct.reviews} paginator={true} rows={50} sortField="published_at" sortOrder={-1} page
                     layout="list" header={header} itemTemplate={reviewTemplate}/>
    </ScrollPanel>;
};
export default ProductReviews;