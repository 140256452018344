import React, {useEffect, useState} from "react";
import {toDateStringIgnoringTimezone} from "../../utils/TimeUtil";


import {IndexTemplate, Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {ScraperService} from "../../service/ScraperService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import {getKeyValue, getLinkFromUrl, getProductDetailLink} from "../../utils/Utils";
import {MultiSelect} from "primereact/multiselect";
import {SelectButton} from "primereact/selectbutton";
import {Dialog} from "primereact/dialog";

const OverviewRequest = (props) =>{

    const {t, setError} = React.useContext(AppContext);

    const [date, setDate] = useState("");

    const [loading, setLoading] = useState(false);
    const [failedRequestsDigest, setFailedRequestsDigest] = useState([]);
    const [filteredFailedRequestsDigest, setFilteredFailedRequestsDigest] = useState([]);
    const [filteredFailedRequestsBasedHostname, setFilteredFailedRequestsBasedHostname] = useState([]);
    const [jobsRunTime, setJobsRunTime] = useState([]);
    const [filteredJobsRunTime, setFilteredJobsRunTime] = useState([]);
    const [totalRealFailure, setTotalRealFailure] = useState(0);
    const [totalConnectionFailure, setTotalConnectionFailure] = useState(0);
    const [totalHttp5xx, setTotalHttp5xx] = useState(0);
    const [totalHttp4xx, setTotalHttp4xx] = useState(0);

    const [filteredUserEmails, setFilteredUserEmails] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    const scraperService = new ScraperService();

    const [rowData, setRowData] = useState(null);
    const [showDetailFailuresModal, setShowDetailFailuresModal] = useState(false);
    const [selectedListType, setSelectedListType] = useState('failed_requests_based_hostname');
    const listTypes = [
        {label: t('scraping_request.failed_requests_based_hostname'), value: 'failed_requests_based_hostname'},
        {label: t('scraping_request.failed_requests_overview'), value: 'failed_requests_overview'},
        {label: t('scraping_request.jobs_runtime'), value: 'jobs_runtime'}
    ];

    useEffect ( ()=>{
        if (date) {
            if (selectedListType === "failed_requests_overview") {
                listFailedRequestsDigest(date);
            }
            else if (selectedListType === "failed_requests_based_hostname") {
                listFailedRequestsBasedHostname(date);
            }
            else if(selectedListType === "jobs_runtime") {
                listJobsRunTime(date);
            }

        }
    },[date, selectedListType]);

    const listFailedRequestsDigest = (selectedDate) => {
        setLoading(true);
        scraperService.listFailedRequestsDigest(toDateStringIgnoringTimezone(selectedDate), toDateStringIgnoringTimezone(selectedDate)).then(data => {
            setFailedRequestsDigest(data);
            setFilteredFailedRequestsDigest(data);
            let userOptions = data.map(item => {return {"label":item.user_email, "value": item.user_email}});
            userOptions = [...new Map(userOptions.map(item => [item['value'], item])).values()];
            userOptions = userOptions.sort((a, b) => (a ? a.label : "").localeCompare((b ? b.label : ""), undefined, {sensitivity: 'base'}));
            setUserOptions(userOptions)
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const filterItems = (e) => {
        const filteredUserEmails = getKeyValue(e).value
        setFilteredUserEmails(filteredUserEmails)
        let filteredFailedRequests = failedRequestsDigest.filter(item => filteredUserEmails.includes(item.user_email))
        let filteredJobsRunTime = jobsRunTime.filter(item => filteredUserEmails.includes(item.user_email))
        setFilteredFailedRequestsDigest(filteredFailedRequests)
        setFilteredJobsRunTime(filteredJobsRunTime)
    }

    const listFailedRequestsBasedHostname = (selectedDate) => {
        setLoading(true);
        scraperService.listFailedRequestsBasedHostname(toDateStringIgnoringTimezone(selectedDate), toDateStringIgnoringTimezone(selectedDate)).then(data => {
            setFilteredFailedRequestsBasedHostname(data);
            setTotalRealFailure(data.map(item => item.total_real_failure_count).reduce((a, b) => a + b, 0))
            setTotalConnectionFailure(data.map(item => item.total_connection_failure).reduce((a, b) => a + b, 0))
            setTotalHttp5xx(data.map(item => item.total_http_5xx).reduce((a, b) => a + b, 0))
            setTotalHttp4xx(data.map(item => item.total_http_4xx).reduce((a, b) => a + b, 0))
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const listJobsRunTime = (selectedDate) => {
        setLoading(true);
        scraperService.listJobsRunTime(toDateStringIgnoringTimezone(selectedDate), toDateStringIgnoringTimezone(selectedDate)).then(data => {
            setJobsRunTime(data);
            setFilteredJobsRunTime(data);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const digestTemplate = (data) => {
        return (
            <React.Fragment>
                <button className="p-link" onClick={() => {window.open('#/scraping_job?scraping_request_id=' + data.id, '_blank');}}>
                    <span style={{textAlign: 'center', marginRight:"5px", width: "50px"}} className={"p-tag p-tag-info"}>{data.total_failure_count}</span>
                    <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-warning"}>{data.user_email}</span>
                    <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-success"}>{data.campaign_name}</span>
                    <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-info"}>{data.type}</span>
                    <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-danger"}>{data.total_real_failure_count}</span>
                    <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-warning"}>{data.total_http_5xx}</span>
                    <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-info"}>{data.total_http_4xx}</span>
                    <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-success"}>{data.total_connection_failure}</span>
                </button>
            </React.Fragment>
        );
    }

    const jobsRunTimeTemplate = (data) => {
        return (
            <React.Fragment>
                <span style={{textAlign: 'center', marginRight:"5px", width: "100px"}} className={"p-tag p-tag-danger"}>{data.duration}</span>
                <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-warning"}>{data.user_email}</span>
                <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-success"}>{data.campaign_name}</span>
                <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-info"}>{data.divided_job_units}</span>
            </React.Fragment>
        );
    }

    const previousDate = () => {
        if (date)
            setDate(toDateStringIgnoringTimezone((new Date(date)).setDate((new Date(date)).getDate()-1)));
    }
    const nextDate = () => {
        if (date)
            setDate(toDateStringIgnoringTimezone((new Date(date)).setDate((new Date(date)).getDate()+1)));
    }
    const selectDate = (e) => {
        setDate(toDateStringIgnoringTimezone(e.value));
    }

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <div className="p-grid p-align-center">
                <Button icon="pi pi-info" className="p-button-info p-mr-1" tooltip={t('actions.detail')} tooltipOptions={{position: 'bottom'}} onClick={() => {setRowData(rowData); setShowDetailFailuresModal(true);}}/>
            </div>
        </React.Fragment>;
    }

    const failedUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.url);
    };

    const failedProductTemplate = (rowData) => {
        return getProductDetailLink(rowData.product_id, t('scraping_request.product_id'));
    };

    const failedRedirectUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.redirect_url);
    };

    const failedCompetitorUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.competitor_url);
    };

    return <React.Fragment>


        <Dialog header={t('dialogs.expense_update')} visible={showDetailFailuresModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setShowDetailFailuresModal(false); setRowData(null);}}>
            {rowData &&
                <DataTable value={rowData.failure_details} responsive={true} header={t('scraping_request.failure_details')}>
                    <Column body={IndexTemplate} style={{width:'3em'}} />
                    <Column field="url" body={failedUrlTemplate} header={t('scraping_request.url')}/>
                    <Column field="redirect_url" body={failedRedirectUrlTemplate} header={t('scraping_request.redirect_url')}/>
                    <Column field="product_id" body={failedProductTemplate} header={t('scraping_request.product_id')}/>
                    <Column field="competitor_url" body={failedCompetitorUrlTemplate} header={t('scraping_request.competitor_url')}/>
                    <Column field="exception" header={t('scraping_request.exception')}/>
                </DataTable>
            }

        </Dialog>

        <div className="p-grid">
            <div className="p-col-6">
                <Button tooltip={t('actions.previous')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left"
                        className="p-button-outlined" onClick={previousDate} style={{marginRight:'0.5em'}} />
                <Calendar id="date" name="date" readOnlyInput={true} value={new Date(date)} onChange={selectDate}
                          showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em'}} />
                <Button tooltip={t('actions.next')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right"
                        className="p-button-outlined" onClick={nextDate} style={{marginRight:'0.5em'}}/>
                {selectedListType === "failed_requests_overview" &&
                    <MultiSelect id="userOption" name="userOption" value={filteredUserEmails} options={userOptions}
                                 filter={true}
                                 onChange={filterItems}
                                 placeholder={t('user.email')}
                                 style={{marginRight: '0.5em', width: "30%"}}/>
                }
            </div>

            <div className="p-col-6">
                <SelectButton value={selectedListType} optionLabel="label" options={listTypes}
                              onChange={(e) => {setSelectedListType(e.value)}}
                              style={{ marginRight:'.5em', float: 'right'}}/>
            </div>

        </div>

        {loading
            ?
            <div className="p-grid p-col-12" style={{textAlign:'center'}}>
                <Loading/>
            </div>
            :
            <React.Fragment>
                {selectedListType === "failed_requests_based_hostname" &&
                    <div className="p-grid p-fluid dashboard card">
                        <div className="p-col-3">
                            <div className="card summary">
                                <button className="p-link" onClick={() => {
                                }}>
                                    <span className="title">{t('scraping_request.total_real_failure')}</span>
                                </button>
                                <span className="detail">{t('scraping_request.total_real_failure_detail')}</span>
                                <span className="count red">{totalRealFailure}</span>
                            </div>
                        </div>
                        <div className="p-col-3">
                            <div className="card summary">
                                <button className="p-link" onClick={() => {
                                }}>
                                    <span className="title">{t('scraping_request.total_http_5xx')}</span>
                                </button>
                                <span className="detail">{t('scraping_request.total_http_5xx_detail')}</span>
                                <span className="count yellow">{totalHttp5xx}</span>
                            </div>
                        </div>
                        <div className="p-col-3">
                            <div className="card summary">
                                <button className="p-link" onClick={() => {
                                }}>
                                    <span className="title">{t('scraping_request.total_http_4xx')}</span>
                                </button>
                                <span className="detail">{t('scraping_request.total_http_4xx_detail')}</span>
                                <span className="count blue">{totalHttp4xx}</span>
                            </div>
                        </div>
                        <div className="p-col-3">
                            <div className="card summary">
                                <button className="p-link" onClick={() => {
                                }}>
                                    <span className="title">{t('scraping_request.total_connection_failure')}</span>
                                </button>
                                <span className="detail">{t('scraping_request.total_connection_failure_detail')}</span>
                                <span className="count green">{totalConnectionFailure}</span>
                            </div>
                        </div>
                    </div>
                }

                <div className="p-grid p-fluid">
                    {selectedListType === "failed_requests_overview" &&
                        <div className="p-grid p-col-12" style={{textAlign:'center'}}>
                            <DataTable value={filteredFailedRequestsDigest} dataKey="id">
                                <Column header={t('scraping_request.failed_requests_overview')} body={digestTemplate}/>
                            </DataTable>
                        </div>
                    }

                    {selectedListType === "failed_requests_based_hostname" &&
                        <DataTable value={filteredFailedRequestsBasedHostname} emptyMessage={t('actions.empty_message')} paginator={true} rows={100}>
                            <Column body={IndexTemplate} style={{width:'3em'}} />
                            <Column field="hostname" style={{width: '230px'}} header={t('scraping_request.hostname')} sortable/>
                            <Column field="exception" header={t('scraping_request.exception')} sortable/>
                            <Column field="total_failure_count" style={{width: '100px'}} header={t('scraping_request.error_count')} sortable/>
                            <Column field="total_real_failure_count" style={{width: '100px'}} header={t('scraping_request.total_real_failure')} sortable/>
                            <Column field="total_http_5xx" style={{width: '100px'}} header={t('scraping_request.total_http_5xx')} sortable/>
                            <Column field="total_http_4xx" style={{width: '100px'}} header={t('scraping_request.total_http_4xx')} sortable/>
                            <Column field="total_connection_failure" style={{width: '100px'}} header={t('scraping_request.total_connection_failure')} sortable/>
                            <Column header={t('table_headers.action')} body={actionTemplate} style={{textAlign:'center', width:'4em'}}/>
                        </DataTable>
                    }

                    {selectedListType === "jobs_runtime" &&
                        <div className="p-grid p-col-12" style={{textAlign: 'center'}}>
                            <DataTable value={filteredJobsRunTime} dataKey="id">
                                <Column header={t('scraping_request.jobs_runtime')} body={jobsRunTimeTemplate}
                                       />
                            </DataTable>
                        </div>
                    }

                </div>

            </React.Fragment>
        }

    </React.Fragment>;
};
export default OverviewRequest;
