import React, {useState} from 'react';
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {CampaignService} from "../../service/CampaignService";


import {getLinkFromUrl, getProductDetailLink} from "../../utils/Utils";
import {Dialog} from "primereact/dialog";
import JobSchedulingSetting from "./JobSchedulingSetting";
import {AppContext} from "../../HomePage";



const JobSchedulingTable = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);


    const [filters, setFilters] = useState({});
    const [campaign, setCampaign] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [showDetailModal, setShowDetailModal] = useState(false);

    const campaignService = new CampaignService();

    const save = () => {
        campaignService.saveCampaignSettings(campaign.id, campaign.use_sale_value, campaign.settings).then(data =>{
            toast.current.show({
                severity: 'success',
                summary: t('reprice.save_pricing_strategy'),
                detail: t('message_detail.successful')
            });
            props.list();
            setShowEditModal(false);
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const actionTemplate = (rowData) => {
        return <div>
            <Button icon="pi pi-info" className="p-button-outlined p-button-info" tooltip={t('actions.detail')} style={{marginRight: '.5em'}} onClick={() => {
                setRowData(rowData);
                setShowDetailModal(true);
            }}/>
            <Button icon="pi pi-pencil" className="p-button-outlined p-button-success" tooltip={t('actions.edit')} style={{marginRight: '.5em'}} onClick={() => {
                setRowData(rowData);
                setCampaign(rowData.campaign);
                setShowEditModal(true);
            }}/>

        </div>;
    };

    const editModalButtons = (
        <div>
            <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => setShowEditModal(false)} />
            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save} />
        </div>
    );

    const failureTemplate = () => {
        return rowData.scraping_request.failure_details && rowData.scraping_request.failure_details.length > 0 ? <React.Fragment>

            <DataTable value={rowData.scraping_request.failure_details} responsive={true} header={t('scraping_request.failure_details')}>
                <Column field="url" body={failedUrlTemplate} header={t('scraping_request.url')}/>
                <Column field="redirect_url" body={failedRedirectUrlTemplate} header={t('scraping_request.redirect_url')}/>
                <Column field="product_id" body={failedProductTemplate} header={t('scraping_request.product_id')}/>
                <Column field="competitor_url" body={failedCompetitorUrlTemplate} header={t('scraping_request.competitor_url')}/>
                <Column field="exception" header={t('scraping_request.exception')}/>
            </DataTable>

        </React.Fragment> : null;
    };
    const failedUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.url);
    };
    const failedProductTemplate = (rowData) => {
        return getProductDetailLink(rowData.product_id, t('scraping_request.product_id'));
    };
    const failedRedirectUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.redirect_url);
    };
    const failedCompetitorUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.competitor_url);
    };

    return (
        <div className="p-col-12">
                <DataTable value={props.jobs}
                           responsive={true} sortMode="multiple"
                           filters={filters} onFilter={(e) => setFilters(e.filters)}
                           multiSortMeta={[{field: "scraping_job_setting.hour", order: 1}, {field: "scraping_request.started_at", order: 1}]}>
                    <Column field="scraping_job_setting.hour" header={t('job.hour')} sortable filter/>
                    <Column field="user_full_name" header={t('user.name')} sortable filter/>
                    <Column field="campaign.name" header={t('campaign.name')} filter/>
                    <Column field="scraping_request.started_at" body={props.startedAtTemplate} header={t('job.start_at')} sortable/>
                    <Column body={props.durationTemplate} header={t('job.duration')}/>
                    <Column body={actionTemplate} style={{textAlign: 'center', width: '11em'}}/>
                </DataTable>

                {showEditModal &&
                <Dialog header={t('job.update_jobs')}  footer={editModalButtons} visible={true} style={{maxWidth: '50vw', minWidth: '50vw'}} modal={true} onHide={() => setShowEditModal(false)}>
                    <JobSchedulingSetting campaign={campaign}/>
                </Dialog>}

                {showDetailModal &&
                <Dialog header={t('scraping_request.failure_details')}  visible={true} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => setShowDetailModal(false)}>
                    {failureTemplate()}
                </Dialog>}


            </div>
    );

};
export default (JobSchedulingTable)
