import React, {useEffect, useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import WebSearchSetting from "./WebSearchSetting";
import {CampaignService} from "../../service/CampaignService";
import {Panel} from "primereact/panel";
import JobSchedulingTimeSetting from "./JobSchedulingTimeSetting";
import {AppContext} from "../../HomePage";
import {Loading, IndexTemplate} from "../../utils/InlineComponents";

const JobSchedulingSiteSearchSetting = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const [loading, setLoading] = useState(false);


    const [campaign, setCampaign] = useState(props.campaign);
    const [searchCompetitors, setSearchCompetitors] = useState(campaign.settings && campaign.settings.site_search_job && campaign.settings.site_search_job.competitor_urls ? [...campaign.settings.site_search_job.competitor_urls.map(c => ({url: c}))] : []);
    const [campaignCompetitors, setCampaignCompetitors] = useState(null)
    const campaignService = new CampaignService();
    let site_search_job = campaign.settings && campaign.settings.site_search_job ? campaign.settings.site_search_job : {}

    useEffect ( ()=>{
        campaign.settings.site_search_job = site_search_job
        if (campaign.id) {
            setLoading(true);
            campaignService.getCampaignCompetitors(campaign.id).then((competitors) => {
                let campaignCompetitors = competitors.filter(comp => comp.is_site_searching_ready);
                setCampaignCompetitors(campaignCompetitors);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    },[]);

    const urlTemplate = (rowData) => <a target="_blank" rel="noopener noreferrer" href={rowData.url}>{rowData.url}</a>;

    return (
        <div className="p-grid p-fluid">
            <Panel header={t('suggestion.time_settings')} style={{height:'100%'}}>
                <JobSchedulingTimeSetting job={site_search_job}/>
            </Panel>

            <p></p>

            <Panel header={t('campaign.generic_setting')} style={{height:'100%'}}>
                <WebSearchSetting webSearchSetting={site_search_job}/>
            </Panel>

            <p></p>
            {loading ?
                <div className="p-col-12" style={{textAlign: 'center'}}>
                    <Loading/>
                </div> :
                <Panel header={t('suggestion.competitor_settings')} style={{height:'100%'}}>
                    <div className="p-col-12 p-xl-12">
                        <DataTable value={campaignCompetitors} dataKey="url" responsive={true}
                                   sortField="url" sortOrder={1}
                                   selection={searchCompetitors}
                                   onSelectionChange={e => {site_search_job.competitor_urls = e.value.map(s => s.url); setSearchCompetitors(e.value)}}>
                            <Column selectionMode="multiple" style={{width:'3em'}}/>
                            <Column header={t('table_headers.index')} style={{width:'5em'}} body={IndexTemplate} />
                            <Column field="url" body={urlTemplate} header={t('campaign.competitors.url')} sortable/>
                        </DataTable>
                    </div>
                </Panel>
            }
        </div>
    );

};
export default (JobSchedulingSiteSearchSetting)
