import React, {useEffect, useState} from "react";

import {Panel} from "primereact/panel";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown, faAngleDoubleUp,faArrowsAlt,faDollarSign,faHandHoldingUsd} from "@fortawesome/free-solid-svg-icons";

import {formatCurrency, formatDecimal} from "../../utils/Utils";
import {Tooltip} from "../../utils/InlineComponents";


import {AppContext} from "../../HomePage";

const ProductKpi = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const red = "#c65454";
    const black = "black";
    const white = "white";
    const green = "#90bf91";
    const lightgray = "lightgray";

    //#region KPI

    const byPriceText = (myPrice, minPrice, avgPrice, maxPrice) => {
        let returnValue = "";
        if (myPrice === undefined)
            returnValue = "-";
        else if (minPrice !== undefined && myPrice <= minPrice)
            returnValue = t('product_detail.lowest');
        else if (avgPrice !== undefined && myPrice <= avgPrice)
            returnValue = t('product_detail.cheaper');
        else if (avgPrice !== undefined && maxPrice !== undefined  && myPrice > avgPrice && myPrice < maxPrice)
            returnValue = t('product_detail.expensive');
        else if (maxPrice !== undefined && myPrice >= maxPrice)
            returnValue = t('product_detail.highest');
        else
            returnValue = "-";
        return returnValue;
    };
    const byLowestPriceText = (myPrice, minPrice) => {
        let returnValue = "";
        if (myPrice === undefined || minPrice === undefined)
            returnValue = "-";
        else if (minPrice/myPrice > 1.1 )
            returnValue = t('product_detail.much_lower');
        else if (minPrice/myPrice > 1.0)
            returnValue = t('product_detail.little_lower');
        else if (minPrice/myPrice === 1.0)
            returnValue = t('product_detail.equal');
        else if (minPrice/myPrice > 0.9)
            returnValue = t('product_detail.little_higher');
        else
            returnValue = t('product_detail.much_higher');
        return returnValue;
    };
    const myPriceColor = () => white;
    const byPriceColor = (myPrice, minPrice, avgPrice, maxPrice) => {

        let returnValue;
        if (myPrice === undefined || minPrice === undefined || avgPrice === undefined || maxPrice === undefined )
            returnValue = "white";
        else if (myPrice <= minPrice)
            returnValue = "darkgreen";
        else if (myPrice >= maxPrice)
            returnValue = "red";
        else if (myPrice <= avgPrice)
            returnValue = "green";
        else
            returnValue = "orange";

        return returnValue;
    };
    const byLowestPriceColor = (myPrice, minPrice) => {
        let returnValue;
        if (myPrice === undefined || minPrice === undefined)
            returnValue = "white";
        else if (minPrice/myPrice > 1.1 )
            returnValue = "darkgreen";
        else if (minPrice/myPrice > 1.0)
            returnValue = "green";
        else if (minPrice/myPrice === 1.0)
            returnValue = "white";
        else if (minPrice/myPrice > 0.9)
            returnValue = "orange";
        else
            returnValue = "red";

        return returnValue;
    };
    const minPriceColor = (myPrice, minPrice) => {
        let returnValue;
        if (myPrice === undefined || minPrice === undefined)
            returnValue = lightgray;
        else if (minPrice > myPrice)
            returnValue = green;
        else if (minPrice === myPrice)
            returnValue = black;
        else
            returnValue = red;

        return returnValue;
    };
    const avgPriceColor = (myPrice, avgPrice) => {
        let returnValue;
        if (myPrice === undefined || avgPrice === undefined)
            returnValue = lightgray;
        else if (avgPrice > myPrice)
            returnValue = green;
        else if (avgPrice === myPrice)
            returnValue = black;
        else
            returnValue = red;

        return returnValue;
    };
    const maxPriceColor = (myPrice, maxPrice) => {
        let returnValue;
        if (myPrice === undefined || maxPrice === undefined)
            returnValue = lightgray;
        else if (maxPrice > myPrice)
            returnValue = green;
        else if (maxPrice === myPrice)
            returnValue = black;
        else
            returnValue = red;

        return returnValue;
    };

    //#endregion KPI

    const calculateKPI = () => {
        let product = props.selectedProduct;

        let pricesAll = (props.competitors.map(competitor => (competitor.prices.length !== 0 && competitor.prices[competitor.prices.length-1]['stock'] && competitor.prices[competitor.prices.length-1]['value'] !== undefined) ? competitor.prices[competitor.prices.length-1]['value'] : undefined));
        let prices = pricesAll.filter(price => price);

        product["minPrice"] = prices.length === 0 ? undefined : Math.min(...prices);
        product["avgPrice"] = prices.length === 0 ? undefined : parseFloat(prices.reduce((previous, current) => (current += previous))) / prices.length;
        product["maxPrice"] = prices.length === 0 ? undefined : Math.max(...prices);
        if (props.competitors.length > 0 && props.competitors[0].prices.length > 0)
            product["currency"] = props.competitors[0].prices[props.competitors[0].prices.length-1].currency;
        if (props.myProduct !== null && props.myProduct.prices.length !== 0 )
        {
            product["myPrice"] = props.myProduct.prices[props.myProduct.prices.length-1]['value'];
            product["currency"] = props.myProduct.prices[props.myProduct.prices.length-1]['value'] === undefined ? undefined : props.myProduct.prices[props.myProduct.prices.length-1].currency;

            product["minDifference"] = product["myPrice"] === undefined || product["minPrice"] === undefined ? undefined : Math.abs(parseFloat(product["myPrice"]) - parseFloat(product["minPrice"]));
            product["avgDifference"] = product["myPrice"] === undefined || product["avgPrice"] === undefined ? undefined : Math.abs(parseFloat(product["myPrice"]) - parseFloat(product["avgPrice"]));
            product["maxDifference"] = product["myPrice"] === undefined || product["maxPrice"] === undefined ? undefined : Math.abs(parseFloat(product["myPrice"]) - parseFloat(product["maxPrice"]));

            product["minPercent"] = product["myPrice"] === undefined || product["minPrice"] === undefined ? "" : Math.abs(((product["myPrice"]) - parseFloat(product["minPrice"]))/parseFloat(product["minPrice"])*100);
            product["avgPercent"] = product["myPrice"] === undefined || product["avgPrice"] === undefined ? "" : Math.abs(((product["myPrice"]) - parseFloat(product["avgPrice"]))/parseFloat(product["avgPrice"])*100);
            product["maxPercent"] = product["myPrice"] === undefined || product["maxPrice"] === undefined ? "" : Math.abs(((product["myPrice"]) - parseFloat(product["maxPrice"]))/parseFloat(product["maxPrice"])*100);

            product["byLowestPriceText"] = byLowestPriceText(product["myPrice"], product["minPrice"]);
            product["byPriceText"] = byPriceText(product["myPrice"], product["minPrice"], product["avgPrice"], product["maxPrice"]);
            product["myPriceColor"] = myPriceColor();
            product["minPriceColor"] = minPriceColor(product["myPrice"], product["minPrice"]);
            product["avgPriceColor"] = avgPriceColor(product["myPrice"], product["avgPrice"]);
            product["maxPriceColor"] = maxPriceColor(product["myPrice"], product["maxPrice"]);
            product["byLowestPriceColor"] = byLowestPriceColor(product["myPrice"], product["minPrice"]);
            product["byPriceColor"] = byPriceColor(product["myPrice"], product["minPrice"], product["avgPrice"], product["maxPrice"]);
        }

        return {...product};
    };

    const [KPIs, setKPIs] = useState(calculateKPI());

    useEffect(() => {
        setKPIs(calculateKPI());
    }, [props.selectedProduct]);

    return <Panel header={t('product_detail.kpi')} toggleable={true} style={{height:"100%"}}>
            <div className="p-grid p-text-center">

                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className={"p-col-12 p-shadow-3 p-m-1 " + KPIs.myPriceColor} style={{borderRadius:"10px", height:"4.5em"}} >
                        <label>{t('product_detail.kpi_current_price')}<Tooltip tooltip={t('product_detail.kpi_current_price_help')} /></label>
                        <div>{formatCurrency(KPIs.myPrice, KPIs.currency)}</div>
                        <div className="kpi-card-logo">
                            <FontAwesomeIcon icon={faHandHoldingUsd} />
                        </div>
                    </div>
                    <div className={"p-col-12 p-shadow-3 p-m-1 " + KPIs.byLowestPriceColor} style={{borderRadius:"10px", height:"4.5em"}}>
                        <label>{t('product_detail.kpi_by_lowest_price')}<Tooltip tooltip={t('product_detail.kpi_by_lowest_price_help')}/></label>
                        <div>{KPIs.byLowestPriceText}</div>
                        <div className="kpi-card-logo" >
                            <FontAwesomeIcon icon={faDollarSign} />
                        </div>
                    </div>
                    <div className={"p-col-12 p-shadow-3 p-m-1 " + KPIs.byPriceColor} style={{borderRadius:"10px", height:"4.5em"}}>
                        <label>{t('product_detail.kpi_by_price')}<Tooltip tooltip={t('product_detail.kpi_by_price_help')}/></label>
                        <div>{KPIs.byPriceText}</div>
                        <div className="kpi-card-logo">
                            <FontAwesomeIcon icon={faDollarSign} />
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="p-col-12 p-shadow-3 p-m-1" style={{borderRadius:"10px", height:"4.5em"}}>
                        <label>{t('product_detail.kpi_min_of_comp')}<Tooltip tooltip={t('product_detail.kpi_min_of_comp_help')}/></label>
                        <div style={{color: KPIs.minPriceColor}}>{formatCurrency(KPIs.minPrice, KPIs.currency) }</div>
                        <div className="kpi-card-logo">
                            <FontAwesomeIcon icon={faAngleDoubleDown} style={{color: KPIs.minPriceColor}}/>
                        </div>
                    </div>
                    <div className="p-col-12 p-shadow-3 p-m-1" style={{borderRadius:"10px", height:"4.5em"}}>
                        <label>{t('product_detail.kpi_avg_of_comp')}<Tooltip tooltip={t('product_detail.kpi_avg_of_comp_help')}/></label>
                        <div style={{color: KPIs.avgPriceColor}}>{formatCurrency(KPIs.avgPrice, KPIs.currency)}</div>
                        <div className="kpi-card-logo">
                            <FontAwesomeIcon icon={faArrowsAlt} style={{color: KPIs.avgPriceColor}}/>
                        </div>
                    </div>
                    <div className="p-col-12 p-shadow-3 p-m-1" style={{borderRadius:"10px", height:"4.5em"}}>
                        <label>{t('product_detail.kpi_max_of_comp')}<Tooltip tooltip={t('product_detail.kpi_max_of_comp_help')}/></label>
                        <div style={{color: KPIs.maxPriceColor}}>{formatCurrency(KPIs.maxPrice, KPIs.currency)}</div>
                        <div className="kpi-card-logo">
                            <FontAwesomeIcon icon={faAngleDoubleUp} style={{color: KPIs.maxPriceColor}}/>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="p-col-12 p-shadow-3 p-m-1" style={{borderRadius:"10px", height:"4.5em"}}>
                        <label>{t('product_detail.kpi_min_diff')}<Tooltip tooltip={t('product_detail.kpi_min_diff_help')}/></label>
                        <div style={{color: KPIs.minPriceColor}}>
                            {formatCurrency(KPIs.minDifference, KPIs.currency)}&nbsp;<sup>{formatDecimal(KPIs.minPercent)} %</sup>
                        </div>
                        <div className="kpi-card-logo">
                            <FontAwesomeIcon icon={faAngleDoubleDown} style={{color: KPIs.minPriceColor}}/>
                        </div>
                    </div>
                    <div className="p-col-12 p-shadow-3 p-m-1" style={{borderRadius:"10px", height:"4.5em"}}>
                        <label>{t('product_detail.kpi_avg_diff')}<Tooltip tooltip={t('product_detail.kpi_avg_diff_help')}/></label>
                        <div style={{color: KPIs.avgPriceColor}}>
                            {formatCurrency(KPIs.avgDifference, KPIs.currency)}&nbsp;<sup>{formatDecimal(KPIs.avgPercent)} %</sup>
                        </div>
                        <div className="kpi-card-logo">
                            <FontAwesomeIcon icon={faArrowsAlt} style={{color: KPIs.avgPriceColor}}/>
                        </div>
                    </div>
                    <div className="p-col-12 p-shadow-3 p-m-1" style={{borderRadius:"10px", height:"4.5em"}}>
                        <label>{t('product_detail.kpi_max_diff')}<Tooltip tooltip={t('product_detail.kpi_max_diff_help')}/></label>
                        <div style={{color: KPIs.maxPriceColor}}>
                            {formatCurrency(KPIs.maxDifference, KPIs.currency)}&nbsp;<sup>{formatDecimal(KPIs.maxPercent)} %</sup>
                        </div>
                        <div className="kpi-card-logo">
                            <FontAwesomeIcon icon={faAngleDoubleUp} style={{color: KPIs.maxPriceColor}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Panel>;
};
export default ProductKpi;