import {AuthService} from "./AuthService";
import {isAdmin} from "../utils/Utils";

export class CacheService {

    url_cache = `caches/`;

    getCaches(){
        return AuthService.apiGet(this.url_cache,{
        }).then(res => res.data.data);
    }

    deleteCache(cache){
        return AuthService.apiDelete(this.url_cache  + cache.cache_usage_id + '/').then(res => res.data.data);
    }

    refreshCache(cache){
        return AuthService.apiPut(this.url_cache  + cache.cache_usage_id + "/refresh_cache/", cache).then(res => res.data.data);
    }

    clearAllCache(){
        return AuthService.apiPut(this.url_cache  + "clear_all_cache/").then(res => res.data.data);
    }

    addCache(campaign_id){
        return AuthService.apiPost(this.url_cache, {campaign_id: campaign_id}).then(res => res.data.data);
    }

}
