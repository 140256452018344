import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import React, {useState} from "react";
import {Button} from "primereact/button";
import {formValid, getKeyValue, urlValidator} from "../../utils/Utils";

import {Sidebar} from "primereact/sidebar";
import {AppContext} from "../../HomePage";

const ProductAdd = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const [product, setProduct] = useState({product_url: ""});
    const [formErrors, setFormErrors] = useState({product_url: ""});

    const add = () => {
        checkErrors(product).then(formErrors=> {
            if (formValid(formErrors))
                props.add(product);
            else
                setFormErrors(formErrors);
        });
    }

    const onChange = (e) =>{
        const { key, value } = getKeyValue(e);
        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setProduct({...product, [key] : value});
    };

    const onEnter = (e) => e.key === "Enter" && add();

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0
    };

    const checkError = (key, value) => {
        let errorText = "";
        if (key === "product_url") {
            errorText =  urlValidator(value, t);
        }
        return errorText;
    };

    const checkErrors = async (product) => {
        let errors = { ...formErrors };
        Object.entries(product).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });
        setFormErrors(errors);
        return errors;
    };

    const addFooter = <div className="ui-dialog-buttonpane p-clearfix">
        <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={props.cancel}/>
        <Button id="add" label={t('actions.add')} icon="pi pi-plus" className="p-button-outlined p-button-success" onClick={add}/>
    </div>;

    return <React.Fragment>
        <h3>{t('product.add_product')}</h3>
        <div className="p-grid alignCenter p-fluid">
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="product_url" name="product_url" value={product.product_url} onChange={onChange} onKeyDown={onEnter}
                               className={errorClass("product_url")} autoFocus={true}/>
                    <label htmlFor="product_url">{t('product.product_url')}</label>
                </span>
                {errorClass("product_url") && (<Message severity="error" text={formErrors.product_url} />)}
            </div>

            <div className="p-col-6">
                <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={props.cancel}/>
            </div>
            <div className="p-col-6">
                <Button id="add" label={t('actions.add')} icon="pi pi-plus" className="p-button-outlined p-button-success" onClick={add}/>
            </div>

        </div>
    </React.Fragment>;
};
export default ProductAdd;