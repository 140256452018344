import React, {useEffect, useState} from 'react';


import {exportFile, getKeyValue} from "../../utils/Utils";
import {getWeeksAgo, toDateString} from "../../utils/TimeUtil";
import {ExportService} from "../../service/ExportService";
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";

import {Calendar} from "primereact/calendar";
import {Loading} from "../../utils/InlineComponents";
import {ScraperService} from "../../service/ScraperService";
import {MultiSelect} from "primereact/multiselect";
import {CampaignContext} from "../../App";

const ExcelReports = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [loadingExportCompetitorList, setLoadingExportCompetitorList] = useState(false);
    const [loadingExportCompetitorPriceHistory, setLoadingExportCompetitorPriceHistory] = useState(false);
    const [loadingExportMarketplaceSellers, setLoadingExportMarketplaceSellers] = useState(false);
    const [loadingExportDetailedProductList, setLoadingExportDetailedProductList] = useState(false);
    const [loadingExportAllSellerUrlList, setLoadingExportAllSellerUrlList] = useState(false);


    const [dates, setDates] = useState([getWeeksAgo(1), new Date()]);
    const [excelProductListDates, setExcelProductListDates] = useState([getWeeksAgo(1), new Date()]);
    const [excelProductListDate, setExcelProductListDate] = useState(new Date());
    const [detailReportDate, setDetailReportDate] = useState(new Date());

    const exportService = new ExportService();
    const [marketplaces, setMarketplaces] = useState([]);
    const [selectedMarketplaces, setSelectedMarketplaces] = useState(null);

    const scraperService = new ScraperService()

    useEffect(() =>{
        listMarketplaces()
    }, []);

    const listMarketplaces = () => {
        scraperService.getMarketplaces().then(data => {
            setMarketplaces(data.map(item => {
                return {name: item.url, code: item.url}
            }));
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const exportCompetitorList = () => {
        setLoadingExportCompetitorList(true);
        exportService.exportCompetitorPriceList(selectedCampaign.id, detailReportDate).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.excel_competitor_list') + "_" + toDateString(new Date()) + ".xlsx");
            setLoadingExportCompetitorList(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExportCompetitorList(false);
        });
    };

    const exportCompetitorPriceHistory = () => {
        setLoadingExportCompetitorPriceHistory(true);
        exportService.exportCompetitorPriceHistory(selectedCampaign.id, toDateString(dates[0]), toDateString(dates[1])).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.competitor_price_history') + "_" + toDateString(dates[0]) + "_" + toDateString(dates[1]) + ".xlsx");
            setLoadingExportCompetitorPriceHistory(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExportCompetitorPriceHistory(false);
        });
    };

    const exportMarketplaceSellers = () => {
        setLoadingExportMarketplaceSellers(true);
        exportService.exportMarketplaceSellers(selectedCampaign.id, selectedMarketplaces).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.excel_marketplace_sellers') + "_" + toDateString(new Date()) + ".xlsx");
            setLoadingExportMarketplaceSellers(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExportMarketplaceSellers(false);
        });
    };

    const exportDetailedProductList = () => {
        setLoadingExportDetailedProductList(true);
        exportService.exportDetailedProductList(selectedCampaign.id, toDateString(excelProductListDate[0]),  toDateString(excelProductListDate[1])).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.excel_product_list') + "_" + toDateString(new Date()) + ".xlsx");
            setLoadingExportDetailedProductList(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExportDetailedProductList(false);
        });
    };

    const exportAllSellerUrlList = () => {
        setLoadingExportAllSellerUrlList(true);
        exportService.exportAllSellerUrlList(selectedCampaign.id).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.all_seller_urls') + "_" + toDateString(new Date()) + ".csv");
            setLoadingExportAllSellerUrlList(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExportAllSellerUrlList(false);
        });
    };

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.excel_reports')}</h3>
                <hr></hr>
            </div>

            <div className="p-col-12">
                <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={dates} onChange={(e) => setDates(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em', width:'250px'}}/>
                <Button label={t('dashboard.competitor_price_history')} icon="pi pi-file-excel" className="p-button-raised p-button-success p-button-text" onClick={exportCompetitorPriceHistory} loading={loadingExportCompetitorPriceHistory} />
            </div>

            <div className="p-col-12">
                <MultiSelect style={{marginRight:'0.5em', width:'250px'}} optionLabel="name" filter value={selectedMarketplaces} options={marketplaces} onChange={(e) => setSelectedMarketplaces(e.value)} />
                <Button label={t('dashboard.excel_marketplace_sellers')} icon="pi pi-file-excel" className="p-button-raised p-button-success p-button-text" onClick={exportMarketplaceSellers} loading={loadingExportMarketplaceSellers} />
            </div>

            <div className="p-col-12">
                <Calendar id="detailReportDate" name="detailReportDate" readOnlyInput={true} value={detailReportDate} onChange={(e) => setDetailReportDate(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em', width:'250px'}}/>
                <Button label={t('dashboard.excel_competitor_list')} icon="pi pi-file-excel" className="p-button-raised p-button-success p-button-text" onClick={exportCompetitorList} loading={loadingExportCompetitorList} />
            </div>

            <br/>

            <div className="p-col-12">
                <Calendar id="excelProductListDate" name="excelProductListDate" selectionMode="range" readOnlyInput={true} value={excelProductListDate} onChange={(e) => setExcelProductListDate(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em', width:'250px'}}/>
                {/*<Calendar id="excel_product_list_dates" name="excel_product_list_dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={excelProductListDates} onChange={(e) => setExcelProductListDates(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em', width:'250px'}}/>*/}
                <Button label={t('dashboard.excel_product_list')} icon="pi pi-file-excel" className="p-button-raised p-button-success p-button-text" onClick={exportDetailedProductList} loading={loadingExportDetailedProductList} />
            </div>

            <br/>

            <div className="p-col-12">
                <Button label={t('dashboard.all_seller_urls')} icon="pi pi-file-excel" className="p-button-raised p-button-warning p-button-text" onClick={exportAllSellerUrlList} loading={loadingExportAllSellerUrlList} />
            </div>

        </div>
    </React.Fragment>;
};
export default ExcelReports;
