import React, {useEffect, useState} from "react";

import {exportFile, getKeyValue, getProductDetailLink, toTitleCase} from "../../utils/Utils";
import {getMonthsAgo, toDateString, toDateStringIgnoringTimezone} from "../../utils/TimeUtil";

import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {DataView} from "primereact/dataview";
import {Calendar} from "primereact/calendar";


import {InputText} from "primereact/inputtext";
import {AutoComplete} from "primereact/autocomplete";
import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";
import {Button} from "primereact/button";
import {ExportService} from "../../service/ExportService";

const SalesPerformance = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [sales, setSales] = useState([]);
    const [dates, setDates] = useState([getMonthsAgo(1), new Date()]);

    const [product_name, setProductName] = useState("");
    const [competitor_name, setCompetitorName] = useState("");
    const [productSuggestions, setProductSuggestions] = useState(null);


    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);

    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect ( ()=>{
        search();
    },[selectedCampaign, dates[0], dates[1]]);

    const suggestProducts = (event) => {
        if (sales != null) {
            let results = sales.filter((sale) => {
                return sale.name.toLowerCase().startsWith(event.query.toLowerCase());
            }).map(item => item.name);

            setProductSuggestions(results);
        }
    };

    const search = () => {
        if (dates[0] && dates[1] && selectedCampaign) {
            setLoading(true);
            dashboardService.getSales(selectedCampaign.id, toDateString(dates[0]), toDateString(dates[1])).then(sales => {
                setSales(sales);
                setLoading(false);
            }).catch(error => {
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const onExport = () => {
        setLoadingExcelExport(true);
        exportService.exportSales(selectedCampaign.id, toDateString(dates[0]), toDateString(dates[1])).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.sales') + "_" + toDateString(dates[0]) + "_" + toDateString(dates[1]) + ".xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const nameTemplate = (rowData) => {
        return<a target="_blank" rel="noopener noreferrer" href={rowData.product_url}>{rowData.name}</a>;
    };

    const saleCountTemplate = (rowData) => {
        return <div style={{textAlign:'center'}}>{rowData.total_sale_count}</div>;
    };

    const productTemplate = (rowData) => {
        const competitors = rowData && rowData.competitors ? rowData.competitors.filter(competitor=> competitor.total_sale_count && competitor.total_sale_count !== 0 && (competitor_name === "" || competitor.name.toLowerCase().indexOf(competitor_name.toLowerCase()) !== -1)) : [];
        const total_sale = competitors.length !== 0 ? competitors.reduce((sum, competitor) => sum+competitor.total_sale_count, 0) : 0;

        let variantList = null;
        if (rowData.properties) {
            variantList = rowData.properties.map(property => {
                if (property.value !== "")
                    return <div key={property.name}><b>{toTitleCase(property.name.replace("_", " "))} : </b>{property.value}</div>;
            });
        }

        return total_sale ? <div className="p-grid p-fluid" style={{border:"1px solid #c8c8c8", margin:"0.5em"}}>
                <div className="p-col-4 p-grid">
                    <div className="p-col-6">
                        <a target="_blank" rel="noopener noreferrer" href={rowData.owner && rowData.owner.product_url}>
                            <img referrerPolicy='no-referrer' className="img-fluid" src={rowData.image} alt=""/>
                        </a>
                    </div>
                    <div className="p-col-6">
                        <div className="p-col-12">
                            {getProductDetailLink(rowData.id, rowData.name)}
                            {variantList}
                        </div>
                        <div className="p-col-12">
                            <span><b>{t('product_detail.my_sold_count') + " : " }</b>{rowData.owner ? rowData.owner.total_sale_count : ""}</span>
                        </div>
                    </div>
                </div>
                <div className="p-col-8">
                    <DataTable value={competitors} responsive={true} sortable={true} sortField="total_sale_count" sortOrder={-1}>
                        <Column body={nameTemplate} header={t('product_detail.competitor')} footer={t('table_headers.total')}/>
                        <Column field="total_sale_count" body={saleCountTemplate} header={t('product_detail.sold_count')} headerStyle={{textAlign:"center"}} footer={total_sale.toString()} footerStyle={{textAlign:"center"}}/>
                    </DataTable>
                </div>
            </div> : null;
    };

    const filtered_sales = sales.filter(sales=>
        sales.competitors.some(competitor=> competitor.total_sale_count && competitor.total_sale_count !== 0 && (competitor_name === "" || competitor.name.toLowerCase().indexOf(competitor_name.toLowerCase()) !== -1)) &&
        (product_name==="" || sales.name.toLowerCase().indexOf(product_name.toLowerCase()) !== -1));

    const header = <div className="p-grid p-fluid">
        <div className="p-col-4">
            <label htmlFor="product_name">{t('product.product_name')}</label>
            <AutoComplete id="product_name" dropdown={true} value={product_name} onChange={(e) => {setProductName(getKeyValue(e).value)}}
                                  suggestions={productSuggestions} completeMethod={suggestProducts}/>

        </div>
        <div className="p-col-4">
            <label htmlFor="competitor_name">{t('product_detail.competitor')}</label>
            <InputText id="competitor_name" value={competitor_name} onChange={(e) => {setCompetitorName( getKeyValue(e).value)}}/>
        </div>
    </div>;

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.sales')}</h3>
                <hr></hr>
            </div>

            <div className="p-col-12">
                <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={dates} onChange={(e) => setDates(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} className="p-mr-1"/>
                <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
            </div>

            <div className="p-col-12">
                {loading ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div> :
                    <DataView value={filtered_sales} paginator={true} rows={50}
                              emptyMessage={t('actions.empty_message')} layout="grid" header={header} itemTemplate={productTemplate}/>}
            </div>
        </div>

    </React.Fragment>;
};
export default SalesPerformance;
