
import React, {useState} from "react";
import {AccountService} from "../../service/AccountService";
import {emailValidator, formValid, getKeyValue, user} from "../../utils/Utils";


import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {AuthService} from "../../service/AuthService";
import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import {Password} from "primereact/password";
import {SelectButton} from "primereact/selectbutton";
import {AppContext} from "../../HomePage";


const Profile = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [currentUser, setCurrentUser] = useState(user());
    const [profile, setProfile] = useState(user().profile ?? []);
    const [newProfile, setNewProfile] = useState({name:"", email:"", password:"", read_only:true});
    const [formErrors, setFormErrors] = useState({name:"", email:"", password:"", read_only:true});
    const [disabled, setDisabled] = useState(false);


    const accountService = new AccountService();

    const add = () => {

        if (profile.some(s => s.email === newProfile.email) || newProfile.email === currentUser.email)
        {
            toast.current.show({severity: 'warn', summary: t('user.profile.exist'), detail: ""});
            return;
        }

        checkErrors(newProfile).then(formErrors=> {
            if (formValid(formErrors)) {
                profile.push(newProfile);
                setProfile([...profile]);
                save();
            }
            else
                setFormErrors(formErrors);
        });
    };

    const deleteProfile = (rowData) => {
        profile.splice(profile.findIndex(n => n.email === rowData.email),1);
        setProfile([...profile]);
        save();
    };

    const onChange = (e) =>{
        const { key, value } = getKeyValue(e);
        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setNewProfile({...newProfile, [key] : value});
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0 ? "p-error" : null
    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "name") {
            errorText =  value.length > 0 ? "" : t('validations.invalid_value');
        }
        else if (key === "email") {
            errorText =  value.length > 0 ? emailValidator(value, t) : t('validations.invalid_email');
        }
        else if (key === "password") {
            errorText =  value.length > 0 ? "" : t('validations.invalid_value');
        }

        return errorText;
    }

    const checkErrors = async (profile) => {

        let errors = { ...formErrors };

        Object.entries(profile).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        return errors;
    };


    const actionTemplate = (rowData) => {
        return <Button icon="pi pi-trash" className="p-button-outlined p-button-rounded p-button-danger" tooltip={t('actions.remove')} onClick={() => deleteProfile(rowData)} />;
    };

    const authorizationTemplate = (rowData) => {
        if (rowData.read_only) {
            return t('user.profile.read_only')
        } else {
            return t('user.profile.read_write')
        }
    };

    const save =() => {

        if (disabled) {
            return;
        }
        setDisabled(true);

        currentUser.profile = profile;

        accountService.updateMyAccount(currentUser).then(user_updated=>{
            AuthService.setUser(user_updated);
            setCurrentUser({...user_updated});
            setProfile([...user_updated.profile])
            toast.current.show({severity: 'success', summary: t('account.save_profile'), detail: t('message_detail.successful')});
            setDisabled(false);
            setNewProfile({name:"", email:"", password:"", read_only: true});
        }).catch(error =>{
            setError(error);
            setError(null);
            setDisabled(false);
        });

    };

    const authorizations =  [
        {label: t('user.profile.read_only'), value: true},
        {label: t('user.profile.read_write'), value: false},
    ];

    return (
        <div className="p-grid alignCenter" >

            <div className="p-col-12 p-fluid">
                <Message severity="info" text={t('user.profile.message')}/>
            </div>
            <div className="p-col-12 p-fluid">
                <span className="p-float-label">
                    <InputText id="name" name="name" value={newProfile.name} onChange={onChange}
                               className={errorClass("email")}/>
                    <label htmlFor="state">{t('user.name')}</label>
                </span>
                {errorClass("name") && (<Message severity="error" text={formErrors.name}/>)}
            </div>
            <div className="p-col-12 p-fluid">
                <span className="p-float-label">
                    <InputText name="email" value={newProfile.email} onChange={onChange}
                               className={errorClass("email")}/>
                    <label htmlFor="state">{t('user.profile.email')}</label>
                </span>
                {errorClass("email") && (<Message severity="error" text={formErrors.email}/>)}
            </div>
            <div className="p-col-12 p-fluid">
                <span className="p-float-label">
                    <Password name="password" value={newProfile.password} onChange={onChange} className={errorClass("email")}/>
                    <label htmlFor="state">{t('user.password')}</label>
                </span>
                {errorClass("password") && (<Message severity="error" text={formErrors.password}/>)}
            </div>
            <div className="p-col-12 p-fluid">
                <span className="p-float-label">
                    <SelectButton name="read_only" value={newProfile.read_only} options={authorizations} onChange={onChange} />
                    {/*<label htmlFor="read_only">{t('user.profile.authorization')}</label>*/}
                </span>
            </div>
            <div className="p-col-12" style={{textAlign:"right"}}>
                <Button label={t('actions.add')} icon="pi pi-plus" className="p-button-outlined p-button-success" onClick={add}/>
            </div>
            <DataTable value={profile} sortField="name" sortOrder={1}
                       emptyMessage={t('actions.empty_message')}>
                <Column field="name" header={t('user.name')}/>
                <Column field="email" header={t('user.email')} />
                <Column body={authorizationTemplate} header={t('user.profile.authorization')} />
                <Column header={t('table_headers.action')} body={actionTemplate} style={{width: '4.5em'}}/>
            </DataTable>
        </div>
    );
};
export default Profile
