import {AuthService} from "./AuthService";

export class PricingPlanService {

    url = `pricing_plans/`;

    getPricingPlans(){
        return AuthService.apiGet(this.url).then(res => res.data.data);
    }

    getStandardPricingPlans(){
        return AuthService.apiGet(this.url + 'standard_plans/').then(res => res.data.data);
    }

    addPricingPlan(data){
        return AuthService.apiPost(this.url, data).then(res => res.data.data);
    }

    deletePricingPlan(id){
        return AuthService.apiDelete(this.url + id + '/').then(res => res.data.data);
    }
}
