import React, {useEffect, useState} from 'react';



import WebSearchSetting from "./WebSearchSetting";
import {Panel} from "primereact/panel";
import JobSchedulingTimeSetting from "./JobSchedulingTimeSetting";
import {AppContext} from "../../HomePage";



const JobSchedulingNewCompetitorWebSearchSetting = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const [isGoogleShoppingSearch, setIsGoogleShoppingSearch] = useState(props.isGoogleShoppingSearch);

    const campaign = props.campaign

    let new_competitor_web_search_job = campaign.settings && campaign.settings.new_competitor_web_search_job ? campaign.settings.new_competitor_web_search_job : {}

    useEffect ( ()=>{
        campaign.settings.new_competitor_web_search_job = new_competitor_web_search_job
    },[]);


    return (
        <div className="p-grid p-fluid">
            <Panel header={t('suggestion.time_settings')} style={{height:'100%'}}>
                <JobSchedulingTimeSetting job={new_competitor_web_search_job}/>
            </Panel>

            <p></p>

            <Panel header={t('campaign.generic_setting')} style={{height:'100%'}}>
                <WebSearchSetting webSearchSetting={new_competitor_web_search_job} isGoogleShoppingSearch={isGoogleShoppingSearch}/>
            </Panel>

            <p></p>

        </div>
    );

};
export default (JobSchedulingNewCompetitorWebSearchSetting)
