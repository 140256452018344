import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import {emailValidator, formValid, getKeyValue, userId} from "../../utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileInvoice} from "@fortawesome/free-solid-svg-icons";
import {Dialog} from "primereact/dialog";
import {AccountService} from "../../service/AccountService";
import {AppContext} from "../../HomePage";


const BillingAddress = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [current_billing_address, set_current_billing_address] = useState({});
    const [billing_address, set_billing_address] = useState({
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        tax: ""
    });
    const [formErrors, setFormErrors] = useState({
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        tax: ""
    });
    const [editBillingAddress, setEditBillingAddress] = useState(false);

    const accountService = new AccountService();

    useEffect(() =>{
        if (props.billing_address) {
            set_current_billing_address(Object.assign({}, props.billing_address));
            set_billing_address(Object.assign({}, props.billing_address));
        }
        else {
            set_current_billing_address({...billing_address, user_id: userId()});
            set_billing_address({...billing_address, user_id: userId()});
        }
    }, []);

    const save = () => {
        checkErrors(billing_address).then(formErrors=> {
            if (formValid(formErrors)) {
                accountService.saveBillingAddress(billing_address).then(saved_billing_address=>{
                    set_current_billing_address(saved_billing_address);
                    set_billing_address( saved_billing_address);
                    setEditBillingAddress(false);
                    props.onDataChange(saved_billing_address);
                    toast.current.show({severity: 'success', summary: t('actions.save_billing_address'), detail: t('message_detail.successful')});
                }).catch(error => {
                    setError(error);
                    setError(null);
                });
            }
            else
                setFormErrors(formErrors);
        });
    };

    const onChange = (e) => {
        const { key, value } = getKeyValue(e);
        set_billing_address({...billing_address, [key]: value})
        setFormErrors({...formErrors, [key]: checkError(key, value)});
    };


    const checkError = (key, value) =>{
        let errorText = "";

        if (key === "first_name") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "last_name") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "email") {
            errorText =  value.length > 0 ? emailValidator(value, t) : t('validations.invalid_email');
        }
        else if (key === "address_line_1") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "city") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "zip") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "state") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "country") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }

        return errorText;
    };

    const checkErrors = async (billing_address) => {
        let errors = { ...formErrors };
        Object.entries(billing_address).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });
        return errors;
    };



    const footer = (
        <React.Fragment>
            <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => {setEditBillingAddress(false);}}/>
            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save}/>
        </React.Fragment>);

    return (
        <React.Fragment>
            <div className="p-grid card" style={{borderBottom: "1px solid black"}}>
                <div className="p-col">
                    <h4><FontAwesomeIcon icon={faFileInvoice} /> {t('subscription.billing_information')}</h4>
                </div>
                <div className="p-col-fixed" style={{width:'8em'}}>
                    <Button label={t('actions.edit')} icon="pi pi-pencil" className="p-button-outlined" onClick={() => {setEditBillingAddress(true);}}/>
                </div>
            </div>
            {current_billing_address &&
            <div className="p-grid">
                <div className="p-col-12">{current_billing_address.first_name + " " + current_billing_address.last_name + "  " + current_billing_address.company}</div>
            </div>}

            {editBillingAddress &&

            <Dialog visible={editBillingAddress} header={t('subscription.billing_information')} footer={footer} modal={true} onHide={() => {setEditBillingAddress(false);}}>

                <div className="p-grid alignCenter p-fluid">
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="first_name" name="first_name" value={billing_address.first_name} onChange={onChange}
                                       className={formErrors.first_name.length > 0 ? "p-error" : null}/>
                            <label htmlFor="first_name">{t('user.first_name')}</label>
                        </span>
                        {formErrors.first_name.length > 0 && (<Message severity="error" text={formErrors.first_name}/>)}
                    </div>
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="last_name" name="last_name" value={billing_address.last_name} onChange={onChange}
                                       className={formErrors.last_name.length > 0 ? "p-error" : null}/>
                            <label htmlFor="last_name">{t('user.last_name')}</label>
                        </span>
                        {formErrors.last_name.length > 0 && (<Message severity="error" text={formErrors.last_name}/>)}
                    </div>
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputText id="email" name="email" keyfilter="email" value={billing_address.email} onChange={onChange}
                                       className={formErrors.email.length > 0 ? "p-error" : null}/>
                            <label htmlFor="email">{t('user.email')}</label>
                        </span>
                        {formErrors.email.length > 0 && (<Message severity="error" text={formErrors.email}/>)}
                    </div>
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputText id="company" name="company" value={billing_address.company} onChange={onChange}
                                       className={formErrors.company.length > 0 ? "p-error" : null}/>
                            <label htmlFor="company">{t('user.company')}</label>
                        </span>
                        {formErrors.company.length > 0 && (<Message severity="error" text={formErrors.company}/>)}
                    </div>
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputText id="address_line_1" name="address_line_1" value={billing_address.address_line_1} onChange={onChange}
                                       className={formErrors.address_line_1.length > 0 ? "p-error" : null}/>
                            <label htmlFor="address_line_1">{t('user.address_line_1')}</label>
                        </span>
                        {formErrors.address_line_1.length > 0 && (<Message severity="error" text={formErrors.address_line_1}/>)}
                    </div>
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputText id="address_line_2" name="address_line_2" value={billing_address.address_line_2} onChange={onChange}
                                       className={formErrors.address_line_2.length > 0 ? "p-error" : null}/>
                            <label htmlFor="address_line_2">{t('user.address_line_2')}</label>
                        </span>
                        {formErrors.address_line_2.length > 0 && (<Message severity="error" text={formErrors.address_line_2}/>)}
                    </div>
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="city" name="city" value={billing_address.city} onChange={onChange}
                                       className={formErrors.city.length > 0 ? "p-error" : null}/>
                            <label htmlFor="city">{t('user.city')}</label>
                        </span>
                        {formErrors.city.length > 0 && (<Message severity="error" text={formErrors.city}/>)}
                    </div>
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="zip" name="zip" value={billing_address.zip} onChange={onChange}
                                       className={formErrors.zip.length > 0 ? "p-error" : null}/>
                            <label htmlFor="zip">{t('user.zip')}</label>
                        </span>
                        {formErrors.zip.length > 0 && (<Message severity="error" text={formErrors.zip}/>)}
                    </div>
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="state" name="state" value={billing_address.state} onChange={onChange}
                                       className={formErrors.state.length > 0 ? "p-error" : null}/>
                            <label htmlFor="state">{t('user.state')}</label>
                        </span>
                        {formErrors.state.length > 0 && (<Message severity="error" text={formErrors.state}/>)}
                    </div>
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="country" name="country" value={billing_address.country} onChange={onChange}
                                       className={formErrors.country.length > 0 ? "p-error" : null}/>
                            <label htmlFor="country">{t('user.country')}</label>
                        </span>
                        {formErrors.country.length > 0 && (<Message severity="error" text={formErrors.country}/>)}
                    </div>
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputText id="tax" name="tax" value={billing_address.tax} onChange={onChange}
                                       className={formErrors.tax.length > 0 ? "p-error" : null}/>
                            <label htmlFor="tax">{t('user.tax')}</label>
                        </span>
                        {formErrors.tax.length > 0 && (<Message severity="error" text={formErrors.tax}/>)}
                    </div>
                </div>
            </Dialog>}
        </React.Fragment>
    );
};
export default BillingAddress;
