import axios from 'axios';
import {AuthService} from "./AuthService";

export class UnAuthService {

    static getAxiosWithoutAuth = () => {
        return axios.create({
            baseURL: AuthService.baseURL,
            timeout: 40000,
            validateStatus: function (status) {
                return status >= 200 && status <= 226 ;
            }
        });
    };

    static login(email, password) {
        return UnAuthService.getAxiosWithoutAuth().post("users/login/",{"email": email, "password": password}).then(res => {
            AuthService.setUserAndToken(res);
            return res;
        }).catch((error) => {
            throw error
        });
    }

    static register(user){
        return UnAuthService.getAxiosWithoutAuth().post("users/", user).then(res => {
            return res.data;
        }).catch((error) => {
            throw error
        });
    }

    static resetPassword(password, token) {
        return UnAuthService.getAxiosWithoutAuth().post( "users/reset_password/", {"token": token, "password": password}).then(res => {
            return res;
        }).catch((error) => {
            throw error
        });
    }

    static sendRecoveryLink(email) {
        return UnAuthService.getAxiosWithoutAuth().post( "users/send_recovery_link/", {"email": email}).then(res => {
            return res;
        }).catch((error) => {
            throw error
        });
    }

}
