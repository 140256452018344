import React, {useEffect, useState} from "react";

import {
    exportFile,
    formatCurrency,
    getKeyValue,
    getProductDetailLink,
    isCampaignMarketSearch
} from "../../utils/Utils";
import {toDateStringIgnoringTimezone} from "../../utils/TimeUtil";

import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ExportService} from "../../service/ExportService";
import {Dropdown} from "primereact/dropdown";
import {CampaignContext} from "../../App";

const NCheapestSellerPriceComparison = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);

    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);

    const [brand, setBrand] = useState("");
    const [category, setCategory] = useState(null);
    const [product_name, setProductName] = useState("");

    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect ( ()=>{
        search();
    },[selectedCampaign]);

    useEffect(() =>{
        setFilteredItems( items.filter(item=> (
            (product_name==="" || (item.name && item.name.toLowerCase().indexOf(product_name.toLowerCase()) !== -1)) &&
            (brand==="" || brand == null || (item.brand && item.brand.toLowerCase().indexOf(brand.toLowerCase()) !== -1)) &&
            (category==="" || category == null || (item.category && item.category.toLowerCase().indexOf(category.toLowerCase()) !== -1))
        )));
    }, [items, product_name, brand, category]);

    const search = () => {
        if (selectedCampaign) {
            setLoading(true);
            dashboardService.getNCheapestSellerPriceComparison(selectedCampaign.id, 5).then(data=>{
                setItems(data);
                let distBrands = [...new Set(data.map(allProducts => allProducts.brand ? allProducts.brand : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                distBrands = distBrands.filter(brand => brand !== undefined && brand !== '')
                setBrands(distBrands.map(brand => ({label: brand, value: brand})))
                let distCategories = [...new Set(data.map(allProducts => allProducts.category ? allProducts.category : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                distCategories = distCategories.filter(category => category !== undefined && category !== '')
                setCategories(distCategories.map(category => ({label: category, value: category})))
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const onExport = () => {
        setLoadingExcelExport(true);
        exportService.exportNCheapestSellerPriceComparison(selectedCampaign.id).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.n_cheapest_seller_price_comparison') + "_" + toDateStringIgnoringTimezone(new Date()) + ".xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const nameTemplate = (rowData) => {
        return getProductDetailLink(rowData.id, rowData.name);
    };

    const sellerNameTemplate = (rowData, props) => {
        return rowData[props.field + '_name'] ? <div className="p-grid" style={{color: rowData[props.field + '_is_mine'] ? rowData.competitorsMinPrice >= rowData.price ? 'green' : rowData.competitorsMaxPrice <= rowData.price ? 'red' : 'orange' : 'black'}}>
            {rowData[props.field + '_name']}
        </div> : '';
    };

    const sellerPriceTemplate = (rowData, props) => {
        return rowData[props.field + '_price'] ? <div className="p-grid">
            <a target="_blank" rel="noopener noreferrer" href={rowData[props.field + '_url']} style={{color: rowData[props.field + '_is_mine'] ? rowData.competitorsMinPrice >= rowData.price ? 'green' : rowData.competitorsMaxPrice <= rowData.price ? 'red' : 'orange' : 'black'}}>
                {formatCurrency(rowData[props.field + '_price'], rowData.currency)}
            </a>
        </div> : '';
    };

    const sellerShippingPriceTemplate = (rowData, props) => {
        return rowData[props.field + '_shipping_price'] ? <div className="p-grid" style={{color: rowData[props.field + '_is_mine']  ? 'orange' : 'black'}}>
            {formatCurrency(rowData[props.field + '_shipping_price'], rowData.currency)}
        </div> : '';
    };

    const priceDifferenceTemplate = (rowData) => {
        return rowData.price_difference ? <div className="p-grid p-justify-center">
            {rowData.price_difference}
        </div> : '';
    };

    const priceDifferenceRateTemplate = (rowData) => {
        return rowData.price_difference_rate ? <div className="p-grid p-justify-center">
            {rowData.price_difference_rate} %
        </div> : '';
    };

    const dynamicColumns = () => {
        let columns = []
        columns.push(<Column key="product_code" field="product_code" header={t('product.product_code')} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="brand" field="brand" header={t('product.brand')} headerStyle={{width: '150px'}}/>);
        columns.push(<Column key="category" field="category" header={t('product.category')} headerStyle={{width: '150px'}}/>);
        columns.push(<Column key="name" field="name" body={nameTemplate} header={t('product.product_name')} headerStyle={{width: '300px'}} sortable/>);
        columns.push(<Column key="competitorsCount" field="competitorsCount" header={t('product_detail.seller_number')} headerStyle={{width: '80px', textAlign:"center"}} style={{textAlign:'center'}} sortable/>);
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="stock" field="stock" header={t('product_detail.stock')} headerStyle={{width: '80px', textAlign:"center"}} style={{textAlign:'center'}} sortable/>);}
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="position" field="position" header={t('product_detail.position')} headerStyle={{width: '80px', textAlign:"center"}} style={{textAlign:'center'}} sortable/>);}
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="price_difference" field="price_difference" body={priceDifferenceTemplate} header={t('product.difference')} headerStyle={{width: '150px', textAlign:"center"}}/>);}
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="price_difference_rate" field="price_difference_rate" body={priceDifferenceRateTemplate} header={t('product.difference_rate')} headerStyle={{width: '150px', textAlign:"center"}}/>);}
        columns.push(<Column key="0_seller_name" field="0_seller" header={t('product.seller_name_1')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="0_seller_price" field="0_seller" body={sellerPriceTemplate} header={t('product.seller_price_1')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {selectedCampaign?.settings?.shipping_prices_handled_separately && columns.push(<Column key="0_seller_shipping_price" field="0_seller" body={sellerShippingPriceTemplate} header={t('product.seller_shipping_price_1')} headerStyle={{width: '150px', textAlign:"left"}}/>);}
        columns.push(<Column key="1_seller_name" field="1_seller" header={t('product.seller_name_2')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="1_seller_price" field="1_seller" body={sellerPriceTemplate} header={t('product.seller_price_2')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {selectedCampaign?.settings?.shipping_prices_handled_separately && columns.push(<Column key="1_seller_shipping_price" field="1_seller" body={sellerShippingPriceTemplate} header={t('product.seller_shipping_price_2')} headerStyle={{width: '150px', textAlign:"left"}}/>);}
        columns.push(<Column key="2_seller_name" field="2_seller" header={t('product.seller_name_3')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="2_seller_price" field="2_seller" body={sellerPriceTemplate} header={t('product.seller_price_3')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {selectedCampaign?.settings?.shipping_prices_handled_separately && columns.push(<Column key="2_seller_shipping_price" field="2_seller" body={sellerShippingPriceTemplate} header={t('product.seller_shipping_price_3')} headerStyle={{width: '150px', textAlign:"left"}}/>);}
        columns.push(<Column key="3_seller_name" field="3_seller" header={t('product.seller_name_4')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="3_seller_price" field="3_seller" body={sellerPriceTemplate} header={t('product.seller_price_4')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {selectedCampaign?.settings?.shipping_prices_handled_separately && columns.push(<Column key="3_seller_shipping_price" field="3_seller" body={sellerShippingPriceTemplate} header={t('product.seller_shipping_price_4')} headerStyle={{width: '150px', textAlign:"left"}}/>);}
        columns.push(<Column key="4_seller_name" field="4_seller" header={t('product.seller_name_5')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="4_seller_price" field="4_seller" body={sellerPriceTemplate} header={t('product.seller_price_5')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {selectedCampaign?.settings?.shipping_prices_handled_separately && columns.push(<Column key="4_seller_shipping_price" field="4_seller" body={sellerShippingPriceTemplate} header={t('product.seller_shipping_price_5')} headerStyle={{width: '150px', textAlign:"left"}}/>);}

        return columns.map((col, i) => {return col});
    };

    const headerTemplate = <div className="p-grid p-fluid">
        <div className="p-col-4">
                    <span className="p-float-label">
                        <InputText id="product_name" value={product_name} onChange={(e) => {setProductName(getKeyValue(e).value)}}/>
                        <label htmlFor="product_name">{t('product.product_name')}</label>
                    </span>
        </div>
        <div className="p-col-4">
                    <span className="p-float-label">
                        <Dropdown name="brand" value={brand} options={brands} onChange={(e) => {setBrand(getKeyValue(e).value)}} filter showClear />
                        <label htmlFor="brand">{t('product.brand')}</label>
                    </span>
        </div>
        <div className="p-col-4">
                    <span className="p-float-label">
                        <Dropdown name="category" value={category} options={categories} onChange={(e) => {setCategory(getKeyValue(e).value)}} filter showClear />
                        <label htmlFor="category">{t('product.category')}</label>
                    </span>
        </div>
    </div>;

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.n_cheapest_seller_price_comparison')}</h3>
                <hr></hr>
            </div>
            {loading ?
                <div className="p-col-12" style={{textAlign:'center'}}>
                    <Loading/>
                </div> :
                <div className="p-col-12 p-grid p-fluid">
                    <div className="p-col-3 p-mt-2">
                        <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
                    </div>
                    <div className="p-col-12">
                        <DataTable value={filteredItems} scrollable scrollHeight={(window.innerHeight - 430)+"px"}
                                   emptyMessage={t('actions.empty_message')}
                                   paginator={true}
                                   rows={100}
                                   first={first}
                                   rowsPerPageOptions={[10, 20, 50, 100]}
                                   onPage={(e) => {
                                       setFirst(e.first);
                                       setRows(e.rows);
                                   }}
                                   header={headerTemplate}>
                            {dynamicColumns()}
                        </DataTable>
                    </div>
                </div>
                }
        </div>

    </React.Fragment>;
};
export default NCheapestSellerPriceComparison;
