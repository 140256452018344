import {AuthService} from "./AuthService";
import {userId} from "../utils/Utils";

export class ProductExcelImportService {
    url_import_excel = `product_import_excel/`;
    url_upload = `product_upload/`

    importProductsFromUrls(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "import_products_from_urls/", formData, config).then(res => res.data.data);
    }

    importProductsFromCustomFile(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "import_products_from_custom_file/", formData, config).then(res => res.data.data);
    }

    importDropShippingProducts(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "import_drop_shipping_products/", formData, config).then(res => res.data.data);
    }

    importManufacturerProducts(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "import_manufacturer_products/", formData, config).then(res => res.data.data);
    }

    importSellerProducts(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "import_seller_products/", formData, config).then(res => res.data.data);
    }

    importProductsWithSellers(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "import_products_with_sellers/", formData, config).then(res => res.data.data);
    }

    activateProducts(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "activate_products/", formData, config).then(res => res.data.data);
    }

    deactivateProducts(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "deactivate_products/", formData, config).then(res => res.data.data);
    }

    deleteProducts(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "delete_products/", formData, config).then(res => res.data.data);
    }

    updateProductMaps(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_import_excel + "update_product_map/", formData, config).then(res => res.data.data);
    }

    importViolationStrategySchedule(campaign_id, file){
        let formData = new FormData();
        formData.append('user_id', userId());
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_upload + "import_violation_strategy_schedule/", formData, config).then(res => res.data.data);
    }

    importUpdateProducts(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPut(this.url_upload + "update_products/", formData, config).then(res => res.data.data);
    }

    updateProductSellerURLs(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPut(this.url_import_excel + "update_product_sellers_urls/", formData, config).then(res => res.data.data);
    }

    updateProductSellerURLsColumn(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPut(this.url_import_excel + "update_product_sellers_urls_vertical/", formData, config).then(res => res.data.data);
    }

    importDynamicPricing(campaign_id, file){
        let formData = new FormData();
        formData.append('campaign_id', campaign_id);
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPut(this.url_import_excel + "import_dynamic_pricing/", formData, config).then(res => res.data.data);
    }
}
