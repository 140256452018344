import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Outlet} from 'react-router-dom';
import {CSSTransition} from "react-transition-group";

import PrimeReact from 'primereact/api';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './layout/layout.scss';
import './App.css';

import {AppContext} from "./HomePage";

import {Messages} from 'primereact/messages';
import {Message} from "primereact/message";

import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppInlineProfile from './AppInlineProfile';
import AppTopbar from "./AppTopbar";

import {AccountService} from "./service/AccountService";
import {AuthService} from "./service/AuthService";
import {CampaignService} from "./service/CampaignService";
import {LanguageService} from "./service/LanguageService";

import {
    exportFile,
    isAdmin,
    next_subscription,
    populateInactiveSellers,
    subscription,
    toTitleCase,
    user,
    userId
} from "./utils/Utils";
import {toDateString, toDateTimeString} from "./utils/TimeUtil";
import {ScrollTop} from "primereact/scrolltop";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import {ExportAsyncService} from "./service/ExportAsyncService";
import {Column} from "primereact/column";
import {IndexTemplate} from "./utils/InlineComponents";
import {DataTable} from "primereact/datatable";

export const CampaignContext = React.createContext();

const App = () =>  {

    const {t, host, toast, setError} = React.useContext(AppContext);

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState(host === "ecomscanner" ? 'light' : 'dark');
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const sidebar = useRef();
    let menuClick = false;

    let messages = useRef(null);
    let layoutMenuScroller = useRef(null);
    const languages = LanguageService.getLanguages();

    const [language, setLanguage] = useState(null);

    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [totalProducts, setTotalProducts] = useState(0);


    const [showMessages, setShowMessages] = useState(false);
    const [contactMessages, setContactMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [showDownloadReports, setShowDownloadReports] = useState(false);
    const [downloadReports, setDownloadReports] = useState([]);

    const [intervalId, setIntervalId] = useState(null);
    const [downloadReportsIntervalId, setDownloadReportsIntervalId] = useState(null);

    const accountService = new AccountService();
    const exportAsyncService = new ExportAsyncService();
    const campaignService = new CampaignService();

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden');
        }
        else {
            removeClass(document.body, 'body-overflow-hidden');
        }
    }, [mobileMenuActive]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                setOverlayMenuActive(prevState => !prevState);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive(prevState => !prevState);
            }
        }
        else {
            setMobileMenuActive(prevState => !prevState);
        }
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }

        return true;
    }

    const logo = layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg' : 'assets/layout/images/logo.svg';

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });

    const sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': layoutColorMode === 'dark',
        'layout-sidebar-light': layoutColorMode === 'light'
    });

    const dateTemplate = (rowData) => <span>{toDateTimeString(rowData.created_at)}</span>;

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            {rowData.state === 'done' ?
                <Button icon="pi pi-file-excel" className="p-button-outlined p-button-rounded p-button-help p-mr-1"
                        tooltip={t('report.download')} tooltipOptions={{position: 'bottom'}}
                        onClick={() => {downloadGeneratedReport(rowData.report_key)}} /> : <></>}
        </React.Fragment> ;
    };

    useEffect ( ()=>{
        if(AuthService.loggedIn())
        {
            if (subscription())
            {
                if (subscription().finish_at)
                {
                    const date = new Date();
                    const finish_at = user().next_payment_date ? Date.parse(user().next_payment_date) : Date.parse(subscription().finish_at);

                    const diffTime = finish_at - date;
                    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    const isTrial = subscription().type.toLowerCase() === 'trial';

                    if (subscription().is_canceled || (!subscription().is_paid && diffDays < 0))
                    {
                        let message=isTrial ?
                            <React.Fragment> {t('subscription.payment.end_of_trial')} <a href="/#/account?subscription=1">{t('subscription.payment.choose')}</a> {t('subscription.payment.a_plan')} </React.Fragment>
                            :<React.Fragment> {t('subscription.payment.no_subscription')} <a href="/#/account?subscription=1">{t('subscription.payment.choose')}</a> {t('subscription.payment.a_plan')}</React.Fragment>;

                        messages.current.show({closable: false, sticky: true, severity: 'error', summary: '', detail: message });
                        window.location = "#/account?subscription=1";
                    }
                    else if (diffDays <=5 )
                    {
                        if (next_subscription() && next_subscription().finish_at && !next_subscription().is_canceled && (next_subscription().is_paid || (!next_subscription().is_paid && diffDays < 0)))
                        {
                            const next_date = new Date();
                            const next_finish_at = Date.parse(next_subscription().finish_at);

                            const next_diffTime = Math.abs(next_finish_at - next_date);
                            const next_diffDays = Math.ceil(next_diffTime / (1000 * 60 * 60 * 24));

                            if (next_diffDays>5)
                                diffDays=10;
                        }

                        if (diffDays <=5 )
                        {
                            let closable = !(diffDays<=1);

                            let method_type = "credit_card";
                            accountService.getPaymentMethod().then(payment_method => {

                                if (payment_method)
                                    method_type = payment_method.method_type;

                                let message = null;
                                if(isTrial)
                                    if (diffDays === 0)
                                        message = <React.Fragment> {t('subscription.payment.end_of_trial_today', {days: diffDays.toString()})} <a href="/#/account?subscription=1">{t('subscription.payment.choose')}</a> {t('subscription.payment.a_plan')} </React.Fragment>;
                                    else
                                        message = <React.Fragment> {t('subscription.payment.end_of_trial_days', {days: diffDays.toString()})} <a href="/#/account?subscription=1">{t('subscription.payment.choose')}</a> {t('subscription.payment.a_plan')} </React.Fragment>;
                                else if (method_type === "money_order")
                                {
                                    if (diffDays === 0)
                                        message = <React.Fragment> {t('subscription.payment.order_payment_remainder_today')} </React.Fragment>;
                                    else
                                        message = <React.Fragment> {t('subscription.payment.order_payment_remainder', {days: diffDays.toString(), finish_at: toDateString(finish_at)})} </React.Fragment>;
                                }
                                else if (method_type === "manuel_credit_card")
                                {
                                    if (diffDays === 0)
                                        message = <React.Fragment> {t('subscription.payment.manuel_payment_remainder_today')} </React.Fragment>;
                                    else
                                        message = <React.Fragment> {t('subscription.payment.manuel_payment_remainder', {days: diffDays.toString(), finish_at: toDateString(finish_at)})} </React.Fragment>
                                }
                                if (message != null) {
                                    messages.current.show({closable: closable, sticky: true, severity: 'warn', summary: '', detail: message });
                                }
                            }).catch(error => {
                                setError(error);
                                setError(null);
                            });
                        }
                    }
                    else
                        messages.current.clear();
                }
                else {
                    const message = <React.Fragment> {t('subscription.payment.no_subscription')} <a href="/#/account?subscription=1">{t('subscription.payment.choose')}</a> {t('subscription.payment.a_plan')}</React.Fragment>;
                    messages.current.show({closable: false, sticky: true, severity: 'error', summary: '', detail: message});
                    window.location = "#/account?subscription=1";
                }
            }
            else
            {
                const message = <React.Fragment> {t('subscription.payment.no_subscription')} <a href="/#/account?subscription=1">{t('subscription.payment.choose')}</a> {t('subscription.payment.a_plan')}</React.Fragment>;
                messages.current.show({closable: false, sticky: true, severity: 'error', summary: '', detail: message});
                window.location = "#/account?subscription=1";
            }
            loadCampaigns();
            listMessages();
            listGeneratedReports()
        }

        return () => {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    },[]);

    const listMessages = () => {
        accountService.listMessages(userId()).then(data => {
            setContactMessages(data);
        });
    }

    const listGeneratedReports = () => {
        exportAsyncService.listGeneratedReports().then(data => {
            setDownloadReports(data);
        });
    }

    const downloadGeneratedReport = (reportKey) =>{
        exportAsyncService.downloadGeneratedReport(reportKey).then((response) =>{
            exportFile(response, reportKey);
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    useEffect(()=>{
        if (contactMessages.length !== 0 && intervalId !== null) {
            let intervalId = setInterval(listMessages, 60000);
            setIntervalId(intervalId);
        }
    }, [JSON.stringify(contactMessages)]);

    const toggleMessages = (show) => {
        setShowMessages(show);
        if (contactMessages && contactMessages.filter(c=> c.sender_id !== userId() && c.is_read === false).length > 0)
            accountService.setAllMessagesRead().then(() => {
                contactMessages.forEach(m=> {m.is_read = true;});
                setContactMessages([...contactMessages]);
            });
    };

    const toggleDownloadReports = (show) => {
        setShowDownloadReports(show);
        if (show) {
            setDownloadReportsInterval()
            listGeneratedReports()
        } else {
            clearDownloadReportsInterval()
        }
    };

    const setDownloadReportsInterval = () =>{
        if (downloadReportsIntervalId == null) {
            let downloadReportsIntervalId = setInterval(listGeneratedReports, 3000);
            setDownloadReportsIntervalId(downloadReportsIntervalId);
        }
    }

    const clearDownloadReportsInterval = () =>{
        if (downloadReportsIntervalId != null) {
            clearInterval(downloadReportsIntervalId);
            setDownloadReportsIntervalId(null);
        }
    }

    const sendMessage = () =>{
        accountService.sendMessage(userId(), newMessage).then(result=>{
            contactMessages.push(result);
            toast.current.show({severity: 'success', summary:t('contact.message_sent')});
            setNewMessage("");
        });
    };


    useEffect ( ()=>{
        if(AuthService.loggedIn()) {
            if (subscription()) {
                if (subscription().finish_at) {

                    const date = new Date();
                    const finish_at = user().next_payment_date ? Date.parse(user().next_payment_date) : Date.parse(subscription().finish_at);

                    const diffTime = finish_at - date;
                    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (subscription().is_canceled || (!subscription().is_paid && diffDays < 0))
                    {
                        window.location = "#/account?subscription=1";
                    }
                }
                else {
                    window.location = "#/account?subscription=1";
                }
            } else {
                window.location = "#/account?subscription=1";
            }
        }
    });

    useEffect(()=>{
        if (campaigns.length === 0 || !selectedCampaign || !campaigns.some(d => d.id === selectedCampaign.id)) {
            setSelectedCampaign(campaigns.length !== 0 ? campaigns[0] : null);
        }
    }, [campaigns]);

    const loadCampaigns = (detailed = false) => {
        campaignService.getCampaigns(detailed).then(campaignData => {
            let totalProductCount = 0

            populateInactiveSellers(campaignData)

            if (detailed) {
                countActiveAndInactiveProducts(campaignData, totalProductCount)
            }
            setCampaigns([...campaignData]);
        }).catch(error => {
            setError(error);
            setError(null);
        });
    };

    const countActiveAndInactiveProducts = (campaignData, totalProductCount) => {
        campaignService.countActiveAndInactiveProducts().then(data => {
            campaignData.forEach(item => {
                if (data[item.id]) {
                    item.product_count = data[item.id].product_count
                    item.inactive_product_count = data[item.id].inactive_product_count
                    totalProductCount += item.product_count
                } else {
                    item.product_count = 0
                    item.inactive_product_count = 0
                }
            })
            setTotalProducts(totalProductCount)
            setCampaigns([...campaignData]);
            countUnmatchedCompetitorSuggestions(campaignData, totalProductCount)
            addScrapingRequestOfCampaigns(campaignData, totalProductCount)
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const countUnmatchedCompetitorSuggestions = (campaignData, totalProductCount) => {
        campaignService.countUnmatchedCompetitorSuggestions().then(data => {
            campaignData.forEach(item => {
                if (data[item.id]) {
                    item.unmatched_competitor_suggestions_count = data[item.id].unmatched_competitor_suggestions_count
                } else {
                    item.unmatched_competitor_suggestions_count = 0
                }
            })
            setCampaigns([...campaignData]);
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const addScrapingRequestOfCampaigns = (campaignData, totalProductCount) => {
        campaignService.getProductImportsOfCampaigns(campaignData.map(item => item.id).join()).then(data => {
            campaignData.forEach(item => {
                if (data[item.id]) {
                    item.product_imports = data[item.id].product_imports
                }
                setCampaigns(campaignData);
            })
        }).catch(error => {
            setError(error);
            setError(null);
        });

        campaignService.getPricesCampaignUpdateScrapingRequestsOfCampaigns(campaignData.map(item => item.id).join()).then(data => {
            campaignData.forEach(item => {
                if (data[item.id]) {
                    item.prices_campaign_update_scraping_request = data[item.id].prices_campaign_update_scraping_request
                }
                setCampaigns(campaignData);
            })
        }).catch(error => {
            setError(error);
            setError(null);
        });

        campaignService.getHeadlessPricesCampaignUpdateScrapingRequestsOfCampaigns(campaignData.map(item => item.id).join()).then(data => {
            campaignData.forEach(item => {
                if (data[item.id]) {
                    item.headless_prices_campaign_update_scraping_request = data[item.id].headless_prices_campaign_update_scraping_request
                }
                setCampaigns(campaignData);
            })
        }).catch(error => {
            setError(error);
            setError(null);
        });

        campaignService.getProductsCreateOrUpdateScrapingRequestsOfCampaigns(campaignData.map(item => item.id).join()).then(data => {
            campaignData.forEach(item => {
                if (data[item.id]) {
                    item.scraping_requests = data[item.id].scraping_requests
                }
                setCampaigns(campaignData);
            })

        }).catch(error => {
            setError(error);
            setError(null);
        });

    }

    const updateCampaigns = (campaigns) => {
        setCampaigns([...campaigns]);
    }

    const selectCampaign = async (id) => {
        setSelectedCampaign(campaigns.find(c=> c.id === id));
    };

    return (
        <CampaignContext.Provider value={{campaigns, selectedCampaign, selectCampaign, loadCampaigns, updateCampaigns, contactMessages, toggleMessages, downloadReports, toggleDownloadReports}}>
            <div className={wrapperClass} onClick={onWrapperClick}>

                <AppTopbar onToggleMenu={onToggleMenu} />

                <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                    <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>

                        <AppInlineProfile />
                        <AppMenu onMenuItemClick={onMenuItemClick} />
                    </div>
                </CSSTransition>


                <Sidebar visible={showMessages} style={{width:'50em'}} position="right" onHide={() => {setShowMessages(false)}}>
                    <div className="p-grid p-nogutter" style={{height: "100vh", width:'37em'}}>
                        <div className="speech-wrapper" style={{top:"0px", position: "absolute", bottom: "230px", backgroundImage: "url(/assets/layout/images/message_back.jpg)"}} >
                            {contactMessages.map(c=>{
                                const alt = c.sender_id === userId() ? " alt" : ""
                                return <div key={c.id} className="p-grid p-col-12">
                                    {alt !== "" && <div className="p-col-fixed" style={{width:'5em'}}/>}
                                    <div key={c.id} className={"p-col bubble" + alt}>
                                        <div className={"txt" + alt}>
                                            <p className={"name" + alt}>{toTitleCase(c.sender_full_name)}</p>
                                            <div className={"message" + alt}> {c.message}</div>
                                            <div className={"timestamp" + alt}>{toDateTimeString(c.created_at)}</div>
                                        </div>
                                        <div className={"bubble-arrow" + alt}/>
                                    </div>
                                    {alt === "" && <div className="p-col-fixed" style={{width:'5em'}}/>}
                                </div>;
                            })}
                        </div>

                        <div style={{position: "fixed", bottom: "10px"}}>
                            <div className="p-col-12">
                                <InputTextarea rows={5} cols={70} value={newMessage} onChange={(e) => setNewMessage(e.target.value)} placeholder={t('contact.question_request')}/>
                            </div>
                            <div className="p-col-12" style={{textAlign:'right'}}>
                                <Button label={t('contact.send')} icon="pi pi-send" className="p-button-success" onClick={sendMessage}/>
                            </div>
                        </div>
                    </div>
                </Sidebar>

                <Sidebar visible={showDownloadReports} style={{width:'60em', height:'30em', overflow: "scroll"}} position="right" onHide={() => {toggleDownloadReports(false)}}>
                    <div className="p-grid" style={{width:'50em', marginLeft: '3em'}}>
                        <DataTable value={downloadReports} responsive={true} header={t('report.download')} sortField="created_at" sortOrder={-1}>
                            <Column body={IndexTemplate} style={{width:'3em'}} />
                            <Column field="report_key" header={t('report.name')} />
                            <Column field="state" header={t('common.state')} />
                            <Column field="created_at" style={{width: '10em'}} body={dateTemplate} header={t('report.created_at')} />
                            <Column body={actionTemplate} header={t('report.action')} style={{textAlign: 'center', width:"13em"}}/>
                        </DataTable>
                    </div>
                </Sidebar>


                {/*<AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}*/}
                {/*           layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />*/}


                <div className="layout-main">
                    <Messages ref={messages}  />

                    {AuthService.loggedIn() && totalProducts && parseInt(totalProducts) > parseInt(parseInt(subscription().total_product_count)*0.95)
                    ? <div className="" style={{marginBottom:'1em'}}>
                        <Message severity="warn" text={t('product.run_out_of_products', {total_products: totalProducts, subs_product_count: subscription().total_product_count })} />
                    </div>:null}

                    <Outlet/>

                    <ScrollTop className="scrollToTop"/>
                </div>

                <br/>

                <AppFooter/>

            </div>
        </CampaignContext.Provider>
    );

};
export default App;
