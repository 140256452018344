import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";

import {ProductService} from '../../service/ProductService';
import {ScraperService} from '../../service/ScraperService';


import ProductBrief from "./ProductBrief";
import ProductKpi from "./ProductKpi";

import CompetitorSuggestion from "./CompetitorSuggestion";
import CompetitorDetection from "./CompetitorDetection";
import Competitor from "./Competitor";

import ProductReprice from "./ProductReprice";

import queryString from "query-string";

import {
    formValid,
    getHostName,
    isAdmin,
    isCampaignDropShipping,
    isCampaignManufacturer,
    isCampaignMarketSearch,
    isCampaignSeller,
    isPriceViolationTabShown,
    populateInactiveSellers,
    userId,
} from "../../utils/Utils";
import ProductPriceViolation from "./ProductPriceViolation";
import {GlobalHotKeys} from "react-hotkeys";
import ProductAdminAction from "./ProductAdminAction";
import ProductReviews from "./ProductReviews";
import {TabMenu} from "primereact/tabmenu";
import ProductPriceGraph from "./ProductPriceGraph";
import ProductSaleGraph from "./ProductSaleGraph";
import {AppContext} from "../../HomePage";
import {getWeeksAgo} from "../../utils/TimeUtil";
import {Loading, ProductNotFound} from "../../utils/InlineComponents";
import {CampaignContext} from "../../App";
import {ScrollPanel} from "primereact/scrollpanel";
import {job_states} from "../../utils/Constants";
import ProductEdit from "./ProductEdit";
import {Sidebar} from "primereact/sidebar";
import {Badge} from "primereact/badge";
import {InputSwitch} from "primereact/inputswitch";

const ProductDetail = (props) => {

    const {t, toast, setError, location} = React.useContext(AppContext);
    const {campaigns} = React.useContext(CampaignContext);

    const [loading, setLoading] = useState(false);
    const [product_id, setProductID] = useState("");
    const [selectedProduct, setSelectedProduct] = useState(null);

    const loadSelectedCampaign = (selectedProduct) => {
        let campaign = {...campaigns.find(c=> c.id===selectedProduct?.campaign_id)}
        populateInactiveSellers([campaign])
        return campaign
    }

    let selectedCampaign = selectedProduct ? loadSelectedCampaign(selectedProduct) : null;

    const myProduct = () => {
        let myProduct = selectedProduct.competitors.some(competitor => {return competitor.is_mine}) ? selectedProduct.competitors.find(competitor => competitor.is_mine) : null;
        if (isCampaignMarketSearch(selectedCampaign) && selectedProduct.competitors.length > 0)
        {
            myProduct = selectedProduct.competitors[0];
        }
        return myProduct;
    }
    const competitors = () => {

        let competitors = selectedProduct.competitors.some(competitor => {return !competitor.is_mine}) ? selectedProduct.competitors.filter(competitor => !competitor.is_mine) : [];

        if (isCampaignMarketSearch(selectedCampaign) && selectedProduct.competitors.length > 0)
            competitors = selectedProduct.competitors.slice(1);

        return competitors;
    }
    const [graphTabIndex, setGraphTabIndex] = useState(0);
    const [competitorTabIndex, setCompetitorTabIndex] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [showCompetitionView, setShowCompetitionView] = useState(localStorage.getItem("showCompetitorView") && localStorage.getItem("showCompetitorView") === "true");

    const [notFound, setNotFound] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [topMenuItems, setTopMenuItems] = useState([]);
    const [subMenuItems, setSubMenuItems] = useState([]);

    const productService = new ProductService();
    const scraperService = new ScraperService();



    useEffect(() => {

        if (location)
        {
            let params = queryString.parse(location.search);
            if (params && params.id && params.id !== "") {
                setLoading(true);
                getProduct(params.id).then(() => {
                    setLoading(false);
                });
            }
        }

        let intervalId = setInterval(refreshProducts, 15000);
        setIntervalId(intervalId);


        return () => {
            clearInterval(intervalId);
            setIntervalId(null);
        }

        createMenuItems();

    }, []);

    useEffect(() => {
        if (props.product_id && props.product_id !== "") {
            setLoading(true);
            getProduct(props.product_id).then(() => {
                setLoading(false);
            });
        }
    },[props.product_id]);

    useEffect(() => {
        localStorage.setItem("showCompetitorView", showCompetitionView)
        if (showCompetitionView)
            setGraphTabIndex(0);
        else
            setGraphTabIndex(0);
        //selectedCampaign = {...campaigns.find(c=> c.id===selectedProduct?.campaign_id)};
        createMenuItems();
    },[showCompetitionView]);

    useEffect(() => {
        //selectedCampaign = {...campaigns.find(c=> c.id===selectedProduct?.campaign_id)};
        createMenuItems();
    },[selectedProduct]);

    const createMenuItems = () =>{

        let noMatchCount = selectedProduct && selectedProduct.competitor_suggestions ? selectedProduct.competitor_suggestions.filter(suggestion => suggestion.is_match_decision_made === false).length : 0;
        let newCompetitorSuggestionsCount = selectedProduct && selectedProduct.new_competitor_suggestions ? selectedProduct.new_competitor_suggestions.length : 0;

        let badge = <span className="p-overlay-badge p-pr-3">{t('product_detail.suggested_competitors')} {noMatchCount > 0 ?
            <Badge value={noMatchCount} severity="danger"/> : ""}</span>;
        let badgeNewCompetitorSuggestions = <span className="p-overlay-badge  p-pr-3">{t('product_detail.new_suggested_competitors')} {newCompetitorSuggestionsCount > 0 ?
            <Badge value={newCompetitorSuggestionsCount} severity="danger"/> : ""}</span>;


        let topMenuItems = [];
        let subMenuItems = [];
        if (!showCompetitionView){
            topMenuItems.push({key: t('product_detail.price_history'), label: t('product_detail.price_history'), icon: 'pi pi-tags'});
            if (!isCampaignMarketSearch(selectedCampaign))
                topMenuItems.push({key: t('product_detail.sale_history'), label: t('product_detail.sale_history'), icon: 'pi pi-bars'});
            if (isCampaignManufacturer(selectedCampaign) || isPriceViolationTabShown(selectedCampaign))
                topMenuItems.push({key: t('product_detail.price_violation'), label: t('product_detail.price_violation'), icon: 'pi pi-eye'});
            if (isCampaignSeller(selectedCampaign) || isCampaignDropShipping(selectedCampaign))
                topMenuItems.push({key: t('product_detail.repricing'), label: t('product_detail.repricing'), icon: 'pi pi-compass'});
            if (selectedProduct && showDescription(selectedProduct))
                topMenuItems.push({key: t('product_detail.description'), label: t('product_detail.description'), icon: 'pi pi-file-pdf'});
            if (selectedProduct && showReview(selectedProduct))
                topMenuItems.push({key: t('product_detail.reviews'), label: t('product_detail.reviews'), icon: 'pi pi-comments'});


            subMenuItems.push({key: t('product_detail.competitors'), label: t('product_detail.competitors'), icon: 'pi pi-sitemap'});
            if (!isCampaignMarketSearch(selectedCampaign) || selectedProduct?.competitor_suggestions?.length > 0)
                subMenuItems.push({key: t('product_detail.suggested_competitors'), label: badge, icon: 'pi pi-spin pi-spinner'});
            if (!isCampaignMarketSearch(selectedCampaign) || selectedProduct?.new_competitor_suggestions?.length > 0)
                subMenuItems.push({key: t('product_detail.new_suggested_competitors'), label: badgeNewCompetitorSuggestions, icon: 'pi pi-external-link'});

            if (isAdmin())
                subMenuItems.push({key: t('product_detail.product_admin_action'), label: t('product_detail.product_admin_action'), icon: 'pi pi-unlock'});
        }
        else {
            topMenuItems.push({key: t('product_detail.competitors'), label: t('product_detail.competitors'), icon: 'pi pi-sitemap'});
            if (!isCampaignMarketSearch(selectedCampaign) || selectedProduct?.competitor_suggestions?.length > 0)
                topMenuItems.push({key: t('product_detail.suggested_competitors'), label: badge, icon: 'pi pi-spin pi-spinner'});
            if (!isCampaignMarketSearch(selectedCampaign) || selectedProduct?.new_competitor_suggestions?.length > 0)
                topMenuItems.push({key: t('product_detail.new_suggested_competitors'), label: badgeNewCompetitorSuggestions, icon: 'pi pi-external-link'});
        }

        setTopMenuItems(topMenuItems);
        setSubMenuItems(subMenuItems);

        if (graphTabIndex + 1 > topMenuItems.length)
            setGraphTabIndex(0);
    }

    const getProduct = async (id) => {

        return productService.getProduct(id).then(product => {

            if (userId() !== product.user_id && !(isAdmin() || userId() === "3ba409bd-3b0a-4b53-9852-468b0bce9952"))
            {
                setNotFound(true);
                throw 'Not Found';
            }
            else
                setNotFound(false);
            setSelectedProduct({...product});
            setProductID(id);
            return product;
        }).then(product => {
            productService.getCompetitors(id, getWeeksAgo(2), new Date()).then(competitors=>{
                product.competitors = competitors;
                setSelectedProduct({...product});
            }).catch(error =>{
                setError(error);
                setError(null);
            })
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const refreshProducts = () => {
        let product = selectedProduct;

        if ((product && product.competitor_suggestion_scraping_request && (product.competitor_suggestion_scraping_request.state === job_states.todo || product.competitor_suggestion_scraping_request.state === job_states.in_progress)) ||
            (product && product.scraping_request && (product.scraping_request.state === job_states.todo || product.scraping_request.state === job_states.in_progress)) ||
            (product && product.competitors.length !== 0 && product.competitors.some(competitor => {return competitor.scraping_request && (competitor.scraping_request.state === job_states.todo || competitor.scraping_request.state === job_states.in_progress)})))
        {
            getProduct(product.id).then(()=>{});
        }
    }

    const refreshProductsMust = () => {
        getProduct(selectedProduct.id).then(()=>{});
    };

    function back()  {
        if (props.product_id !== undefined && props.product_id !== null && props.product_id !== ""){
            if (intervalId) {
                clearInterval(intervalId);
                setIntervalId(null);
            }
            props.onDetailComplete();
        }
    };

    function previous() {
        if (props.product_id !== undefined && props.product_id !== null && props.product_id !== "") {
            if (intervalId) {
                clearInterval(intervalId);
                setIntervalId(null);
            }
            props.goToPrevious();
        }
    };
    function next() {
        if (props.product_id !== undefined && props.product_id !== null && props.product_id !== "") {
            if (intervalId) {
                clearInterval(intervalId);
                setIntervalId(null);
            }
            props.goToNext();
        }
    };

    //#region Brief

    const requestFindMatchesProduct = () => {
        if (myProduct !== null)
        {
            productService.searchCompetitors(product_id).then(scraping_requests_created => {

                let product = selectedProduct;
                product.competitor_suggestion_scraping_request = scraping_requests_created;

                setSelectedProduct(product);

                toast.current.show({severity: 'success', summary: t('actions.request_find_matches'), detail: t('message_detail.successful')});
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        }
    };

    const autoMatchProductSuggestions = (match_method, min_match_score) => {

        if (match_method !== "sku" && match_method !== "mpn" && match_method !== "ean" &&
            match_method !== "upc" && match_method !== "barcode" &&
            match_method !== "name_exact" && match_method !== "name_in" && match_method !== "match_score" )
        {
            toast.current.show({severity: 'error', summary: t('suggestion.select_match_method'), detail: ""});
            return;
        }

        productService.autoMatchProductSuggestions(product_id, match_method, min_match_score).then(()=>{
            getProduct(product_id);
            toast.current.show({severity: 'success', summary: t('actions.auto_match_suggestions'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const searchCompetitorsOnWebsite = () => {

        scraperService.saveScrapingRequest({type: "site_search_product", campaign_id: selectedProduct.campaign_id, product_id: product_id}).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.search_competitors_on_website'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const getShippingPrices = () => {

        scraperService.getShippingPrices({
            spider_name: "prices_product_update",
            type: "shipping_price",
            campaign_id: selectedProduct.campaign_id,
            product_id: selectedProduct.id,
        }).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.get_shipping_price'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const getProductReviews = () => {
        scraperService.saveScrapingRequest({
            spider_name: "product_update_review",
            type: "products_update_partial",
            fields: ["reviews"],
            campaign_id: selectedProduct.campaign_id,
            product_id: selectedProduct.id,
        }).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.get_product_reviews'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const getProductFailedReviews = () => {
        scraperService.saveScrapingRequest({
            spider_name: "product_update_failed_review",
            type: "products_update_partial",
            fields: ["reviews"],
            campaign_id: selectedProduct.campaign_id,
            product_id: selectedProduct.id,
        }).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.get_product_failed_reviews'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    //#endregion Brief

    //##region RePricing

    const savePricingStrategy = async (pricing_strategy) => {

        return productService.savePricingStrategy(selectedProduct.id, pricing_strategy).then(result => {

            let product = selectedProduct;
            product.suggested_price = result.suggested_price;
            product.target_price = result.target_price;
            product.pricing_strategy = pricing_strategy;
            setSelectedProduct({...product});

            toast.current.show({
                severity: 'success',
                summary: t('reprice.save_pricing_strategy'),
                detail: t('message_detail.successful')
            });
        }).catch(error => {
            setError(error);
            setError(null);
        });
    };

    const savePriceViolation = (violation_strategy) => {
        productService.savePriceViolation(selectedProduct.id, violation_strategy).then(r => {

            let product = selectedProduct;
            product.violation_strategy = r.violation_strategy;

            setSelectedProduct(product);

            toast.current.show({
                severity: 'success',
                summary: t('product_detail.save_violation_strategy'),
                detail: t('message_detail.successful')
            });

        }).catch(error => {
            setError(error);
            setError(null);
        });

    };

    //#endregion RePricing

    //#region Competitor

    const addCompetitorFromSuggestions = (urls, formErrors) => {

        if (formValid(formErrors)) {

            let competitors = [];
            let product = selectedProduct;

            let is_exists = false;
            urls.forEach(url => {

                is_exists = product.competitors.some(competitor => competitor.product_url.toLowerCase() === url.product_url.toLowerCase());

                if (is_exists) {
                    toast.current.show({
                        severity: 'warn',
                        summary: t('product_detail.product_exist_summary'),
                        detail: t('product_detail.product_exist_detail')
                    });
                    return;
                }

                competitors.push({
                    id: "",
                    product_url: url.product_url,
                    name: getHostName(url.product_url),
                    is_mine: false,
                    prices: [],
                    scraping_request: null
                });
            });

            if (!is_exists){

                productService.addCompetitorFromSuggestions(product.id, competitors).then(result => {

                    let suggestions = product.competitor_suggestions;

                    competitors.forEach(competitor => {
                        if (suggestions)
                        {
                            let index = suggestions.findIndex(suggestion => suggestion.product_url.toLowerCase() === competitor.product_url.toLowerCase());
                            if (index !== -1) {
                                suggestions[index].is_match_decision_made = true;
                                suggestions[index].is_matched = true;
                            }
                        }

                    });

                    competitors.forEach(competitor => product.competitors.push(competitor));
                    product.competitor_suggestions = suggestions;

                    setSelectedProduct({...product});

                    toast.current.show({
                        severity: 'success',
                        summary: t('product_detail.add_competitor'),
                        detail: t('message_detail.successful')
                    });

                }).catch(error => {
                    setError(error);
                    setError(null);
                });
            }
        }

    };


    const addCompetitor = (urls, formErrors) => {

        if (formValid(formErrors)) {

            let competitors = [];
            let product = selectedProduct;

            let is_exists = false;
            urls.forEach(url => {

                is_exists = product.competitors.some(competitor => competitor.product_url.toLowerCase() === url.product_url.toLowerCase());

                if (is_exists) {
                    toast.current.show({
                        severity: 'warn',
                        summary: t('product_detail.product_exist_summary'),
                        detail: t('product_detail.product_exist_detail')
                    });
                    return;
                }

                competitors.push({
                    id: "",
                    product_url: url.product_url,
                    name: getHostName(url.product_url),
                    is_mine: false,
                    prices: [],
                    scraping_request: null,
                    is_active: true
                });
            });

            if (!is_exists){

                productService.addCompetitor(product.id, competitors).then(result => {

                    let suggestions = product.competitor_suggestions;

                    let scrapingRequests = [];

                    if (result && !Array.isArray(result))
                        scrapingRequests[0] = result;
                    else if (result)
                        scrapingRequests = result;
                    //Returns the related scraping request as result


                    competitors.forEach(competitor => {
                        let index = scrapingRequests.findIndex(scrapingRequest => scrapingRequest.competitor_url && competitor.product_url.toLowerCase() === scrapingRequest.competitor_url.toLowerCase());
                        if (index !== -1)
                            competitor.scraping_request = scrapingRequests[index];

                        if (suggestions)
                        {
                            let index = suggestions.findIndex(suggestion => suggestion.product_url.toLowerCase() === competitor.product_url.toLowerCase());
                            if (index !== -1) {
                                suggestions[index].is_match_decision_made = true;
                                suggestions[index].is_matched = true;
                            }
                        }

                    });


                    /*scrapingRequests.forEach(scrapingRequest=>{
                        if (scrapingRequest.competitor_url)
                        {
                            let index = competitors.findIndex(competitor => competitor.product_url.toLowerCase() === scrapingRequest.competitor_url.toLowerCase());
                            if (index !== -1)
                                competitors[index].scraping_request = scrapingRequest;

                            if (suggestions)
                            {
                                let index = suggestions.findIndex(suggestion => suggestion.product_url.toLowerCase() === scrapingRequest.competitor_url.toLowerCase());
                                if (index !== -1) {
                                    suggestions[index].is_match_decision_made = true;
                                    suggestions[index].is_matched = true;
                                }
                            }
                        }
                    });*/
                    competitors.forEach(competitor => product.competitors.push(competitor));
                    product.competitor_suggestions = suggestions;

                    setSelectedProduct({...product});

                    toast.current.show({
                        severity: 'success',
                        summary: t('product_detail.add_competitor'),
                        detail: t('message_detail.successful')
                    });

                }).catch(error => {
                    setError(error);
                    setError(null);
                });
            }
        }

    };



    const changeCompetitorIsActive = async (product_urls, is_active) => {

        return productService.updateCompetitorIsActive(selectedProduct.id, product_urls, is_active).then(() => {
            let competitors = selectedProduct.competitors;

            product_urls.forEach(product_url=>{
                let index = competitors.findIndex(competitor => competitor.product_url === product_url);
                competitors[index].is_active = !competitors[index].is_active;
            });

            selectedProduct.competitors = competitors;
            setSelectedProduct({...selectedProduct});

            toast.current.show({severity: 'success', summary: is_active ? t('product.comp_deactivated') : t('product.comp_activated'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    }

    const changeCompetitorIsDynamicPricingActive = async (product_urls, is_active) => {

        return productService.updateCompetitorIsDynamicPricingActive(selectedProduct.id, product_urls, is_active).then(() => {
            let competitors = selectedProduct.competitors;

            product_urls.forEach(product_url=>{
                let index = competitors.findIndex(competitor => competitor.product_url === product_url);
                competitors[index].is_dynamic_pricing_active = !competitors[index].is_dynamic_pricing_active;
            });

            selectedProduct.competitors = competitors;
            setSelectedProduct({...selectedProduct});

            toast.current.show({severity: 'success', summary: is_active ? t('actions.exclude') : t('actions.include'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    }

    const deleteCompetitor = async (product_urls) => {

        return productService.deleteCompetitor(selectedProduct.id, product_urls).then(() => {
            selectedProduct.competitors = selectedProduct.competitors.filter(competitor => !product_urls.includes(competitor.product_url));
            setSelectedProduct({...selectedProduct});

            // if (selectedProduct.competitor_suggestions)
            // {
            //     let suggestions = selectedProduct.competitor_suggestions;
            //
            //     let index = suggestions.findIndex(suggestion => suggestion.product_url.toLowerCase() === rowData.product_url.toLowerCase());
            //     if (index !== -1) {
            //         suggestions[index].is_match_decision_made = true;
            //         suggestions[index].is_matched = false;
            //
            //         selectedProduct.competitor_suggestions = suggestions;
            //         setSelectedProduct({...selectedProduct});
            //     }
            // }

            toast.current.show({severity: 'success', summary: t('product_detail.remove_competitor'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const ignoreCompetitorSuggestion = (competitor_suggestions) => {
        productService.ignoreCompetitor(selectedProduct.id, competitor_suggestions).then(() => {

            let suggestions = selectedProduct.competitor_suggestions;
            competitor_suggestions.forEach(new_suggestion=>{
                let index = suggestions.findIndex(suggestion => suggestion.product_url.toLowerCase() === new_suggestion.product_url.toLowerCase());
                suggestions[index].is_match_decision_made = true;
                suggestions[index].is_matched = false;
            });

            selectedProduct.competitor_suggestions = suggestions;
            setSelectedProduct({...selectedProduct});

            toast.current.show({severity: 'success', summary: t('actions.mark_not_competitor'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const updateCompetitor = (data) => {
        productService.updateCompetitorFields(selectedProduct.id, data).then(result => {
            let product = selectedProduct;

            let comp = product.competitors[product.competitors.findIndex(competitor => competitor.product_url === data.competitor_url)];
            comp.multiplier = data.multiplier
            comp.discount_type = data.discount_type
            comp.manual_shipping_price = data.manual_shipping_price
            comp.manual_shipping_price_threshold = data.manual_shipping_price_threshold
            comp.prices.push(result.prices[0])
            if (result.shipping_prices !== null) {
                if (!comp.shipping_prices)
                    comp.shipping_prices = []
                comp.shipping_prices.push(result.shipping_prices[0])
            }

            setSelectedProduct({...product});
            toast.current.show({severity: 'success', summary: t('product_detail.edit_competitor'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }


    const matchCompetitorVariant = (competitor_url, variants) => {

        productService.matchCompetitorVariant(selectedProduct.id, competitor_url, variants).then(() => {

            let product = selectedProduct;

            product.competitors[product.competitors.findIndex(competitor => competitor.product_url === competitor_url)].selected_variant = variants;

            setSelectedProduct(product);
            toast.current.show({severity: 'success', summary: t('product_detail.select_variant'), detail: t('message_detail.successful')});

            const newRequest = {
                campaign_id: selectedProduct.campaign_id,
                product_id: selectedProduct.id,
                url: competitor_url,
                competitor_url: competitor_url,
                type: 'price_competitor_update'
            };

            scraperService.saveScrapingRequest(newRequest).then(()=>{
                getProduct(selectedProduct.id);
            }).catch(error =>{
                setError(error);
                setError(null);
            });

        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    //#endregion Competitor


    const keyMap = {
        back: "backspace",
        previous: "ArrowLeft",
        next: "ArrowRight",
    };

    const handlers = {
        back: event => {back()},
        previous: event => {previous()},
        next: event => {next()}
    };

    const showDescription = (selectedProduct) => {
        if (selectedCampaign?.settings?.special_fields?.some(sf=> sf.name === "description" && sf.selected) && selectedProduct.description)
            return true;
        return false;
    }
    const showReview = (selectedProduct) => {
        if (selectedCampaign?.settings?.special_fields?.some(sf=> sf.name === "reviews" && sf.selected) && selectedProduct.reviews && selectedProduct.reviews.length !== 0)
            return true;
        return false;
    }

    const update = (product) =>{

        productService.updateProduct(product.id, product).then(updatedProduct => {
            selectedProduct.name = product.name;
            selectedProduct.brand = product.brand;
            selectedProduct.category = product.category;
            selectedProduct.barcode = product.barcode;
            selectedProduct.sku = product.sku;
            selectedProduct.mpn = product.mpn;
            selectedProduct.ean = product.ean;
            selectedProduct.upc = product.upc;
            selectedProduct.ref_id = product.ref_id;
            selectedProduct.vat = product.vat;
            selectedProduct.search_key = product.search_key;
            selectedProduct.properties = product.properties;
            selectedProduct.campaign_id = product.campaign_id;
            selectedProduct.is_active = product.is_active;

            setSelectedProduct({...selectedProduct});
            setShowEdit(false);

            toast.current.show({
                severity: 'success',
                summary: t('product.edit_product'),
                detail: t('message_detail.successful')
            });
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const changeIsActive = () => {
        productService.updateProductIsActive(selectedProduct.id, selectedProduct.is_active).then(() => {
            setSelectedProduct({...selectedProduct, is_active:!selectedProduct.is_active});
            toast.current.show({severity: 'success', summary: selectedProduct.is_active ? t('product.activated') : t('product.deactivated'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const getCompetitors = async (id, startdate, enddate) => {

        return productService.getCompetitors(id, startdate, enddate).then(competitors=>{
            selectedProduct.competitors = competitors;
            setSelectedProduct({...selectedProduct});
        }).catch(error =>{
            setError(error);
            setError(null);
        })

    }

    return loading ?
            <div className="card" style={{textAlign:'center'}}>
            <Loading/>
        </div>
        :  notFound ? <ProductNotFound/> :
            <React.Fragment>

                {selectedProduct !== null ?
                    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
                        <div className="card">

                            <Sidebar showCloseIcon={false} visible={showEdit} style={{width:'25em', overflow: "scroll"}} position="right" onHide={() => {setShowEdit(false);}}>
                                <ProductEdit selectedProduct={selectedProduct}
                                             categories={[]}
                                             brands={[]}
                                             cancel={() => {setShowEdit(false);}} update={update} />
                            </Sidebar>


                            <div className="p-grid float p-nogutter p-align-center">

                                <InputSwitch checked={selectedProduct.is_active} onChange={changeIsActive} className="p-mr-1"
                                             tooltipOptions={{position: 'bottom'}} tooltip={selectedProduct.is_active ? t('actions.deactivate') : t('actions.activate') } />
                                <Button tooltip={t('product.product') + " " + t('actions.edit')} tooltipOptions={{position: 'bottom'}} icon="pi pi-pencil"
                                        className="p-button-success p-mr-1" onClick={() => {setShowEdit(true);}}/>
                                <Button label={showCompetitionView ? t('actions.analysis_view') : t('actions.competition_view')} icon="pi pi-eye"
                                        className="p-button-success p-mr-1" onClick={() => {setShowCompetitionView(!showCompetitionView);}} />
                                {props.product_id && <>
                                <Button label={t('actions.back')} tooltip={t('product_detail.back_to_list')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-double-left"
                                        className="p-button-help p-mr-1" onClick={back}/>
                                <Button tooltip={t('actions.previous') + " " + t('product.product')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left"
                                        className="p-button-help p-mr-1" onClick={previous}/>
                                <Button tooltip={t('actions.next') + " " + t('product.product')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right"
                                        className="p-button-help" onClick={next}/>
                                </>}
                            </div>

                            <React.Fragment>
                                <div className="p-grid">

                                    <div className="p-col-12 p-sm-12 p-md-5 p-lg-4 p-xl-3">
                                        <ProductBrief selectedProduct={selectedProduct} refreshProducts={refreshProductsMust}
                                                      showCompetitionView={showCompetitionView}/>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-7 p-lg-8 p-xl-9">

                                        <TabMenu model={topMenuItems} activeIndex={graphTabIndex} onTabChange={(e) => {
                                            setGraphTabIndex(topMenuItems.findIndex(i => i.key.toLowerCase() === e.value.key.toLowerCase()));
                                        }} style={{paddingTop: "0", paddingLeft: "0", paddingRight: "0"}}/>

                                        <div className="p-col-12">
                                            <br/>
                                            {(topMenuItems[graphTabIndex]).key === t('product_detail.price_history') ?
                                                <ProductPriceGraph selectedProduct={selectedProduct} getCompetitors={getCompetitors}/> :
                                                (topMenuItems[graphTabIndex]).key === t('product_detail.sale_history') ?
                                                    <ProductSaleGraph selectedProduct={selectedProduct}/> :
                                                    (topMenuItems[graphTabIndex]).key === t('product_detail.price_violation') ?
                                                        <ProductPriceViolation selectedProduct={selectedProduct} savePriceViolation={savePriceViolation}/> :
                                                        (topMenuItems[graphTabIndex]).key === t('product_detail.repricing') ?
                                                            <ProductReprice selectedProduct={selectedProduct} savePricingStrategy={savePricingStrategy}/> :
                                                            (topMenuItems[graphTabIndex]).key === t('product_detail.description') ?
                                                                <ScrollPanel style={{height: '300px', fontSize:"0.8em"}}>
                                                                    <div dangerouslySetInnerHTML={{__html: selectedProduct.description}}></div>
                                                                </ScrollPanel> :
                                                                (topMenuItems[graphTabIndex]).key === t('product_detail.reviews') ?
                                                                    <ProductReviews selectedProduct={selectedProduct} myProduct={myProduct()}/> :
                                                                    (topMenuItems[graphTabIndex]).key === t('product_detail.competitors') ?
                                                                        <Competitor selectedProduct={selectedProduct} myProduct={myProduct()} competitors={competitors()}
                                                                                    addCompetitor={addCompetitor} changeCompetitorIsActive={changeCompetitorIsActive}
                                                                                    changeCompetitorIsDynamicPricingActive={changeCompetitorIsDynamicPricingActive}
                                                                                    deleteCompetitor={deleteCompetitor} matchCompetitorVariant={matchCompetitorVariant}
                                                                                    updateCompetitor={updateCompetitor}
                                                                                    refreshProducts={refreshProductsMust}/> :
                                                                        (topMenuItems[graphTabIndex]).key === t('product_detail.suggested_competitors') ?
                                                                            <CompetitorSuggestion selectedProduct={selectedProduct}
                                                                                                  addCompetitor={addCompetitorFromSuggestions}
                                                                                                  ignoreCompetitorSuggestion={ignoreCompetitorSuggestion}/> :
                                                                            (topMenuItems[graphTabIndex]).key === t('product_detail.new_suggested_competitors') ?
                                                                                <CompetitorDetection selectedProduct={selectedProduct}/> : ""}
                                        </div>

                                        <br/>

                                        {!showCompetitionView &&
                                        (!isCampaignMarketSearch(selectedCampaign) || (isCampaignMarketSearch(selectedCampaign) && selectedProduct.competitors && selectedProduct.competitors.length > 1)) &&
                                        <ProductKpi selectedProduct={selectedProduct} myProduct={myProduct()} competitors={competitors()}/>}
                                    </div>
                                </div>
                                <br/>

                                {subMenuItems && subMenuItems.length>0 &&
                                <div>

                                    <TabMenu model={subMenuItems} activeIndex={competitorTabIndex} onTabChange={(e) => {
                                        setCompetitorTabIndex(subMenuItems.findIndex(i => i.key.toLowerCase() === e.value.key.toLowerCase()));
                                    }} style={{paddingTop: "0", paddingLeft: "0", paddingRight: "0"}}/>

                                    <div className="p-col-12" >
                                        <br/>
                                        {(subMenuItems[competitorTabIndex]).key === t('product_detail.competitors') ?
                                            <Competitor selectedProduct={selectedProduct} myProduct={myProduct()} competitors={competitors()}
                                                        addCompetitor={addCompetitor} changeCompetitorIsActive={changeCompetitorIsActive}
                                                        changeCompetitorIsDynamicPricingActive={changeCompetitorIsDynamicPricingActive}
                                                        deleteCompetitor={deleteCompetitor} matchCompetitorVariant={matchCompetitorVariant}
                                                        updateCompetitor={updateCompetitor}
                                                        refreshProducts={refreshProductsMust}/> :
                                            (subMenuItems[competitorTabIndex]).key === t('product_detail.suggested_competitors') ?
                                                <CompetitorSuggestion selectedProduct={selectedProduct}
                                                                      addCompetitor={addCompetitorFromSuggestions}
                                                                      ignoreCompetitorSuggestion={ignoreCompetitorSuggestion}/> :
                                                (subMenuItems[competitorTabIndex]).key === t('product_detail.new_suggested_competitors') ?
                                                    <CompetitorDetection selectedProduct={selectedProduct}/> :
                                                        (subMenuItems[competitorTabIndex]).key === t('product_detail.product_admin_action') ?
                                                            <ProductAdminAction selectedProduct={selectedProduct}
                                                                        searchCompetitorsOnWebsite={searchCompetitorsOnWebsite}
                                                                        refreshProducts={refreshProductsMust}
                                                                        getShippingPrices={getShippingPrices}
                                                                        getProductReviews={getProductReviews}
                                                                        getProductFailedReviews={getProductFailedReviews}
                                                                        autoMatchProductSuggestions={autoMatchProductSuggestions}/> : ""
                                        }
                                    </div>

                                </div>}
                            </React.Fragment>

                        </div>
                    </GlobalHotKeys>
                    : <></>}
            </React.Fragment>;

};
export default ProductDetail;
