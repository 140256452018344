
import React, {useEffect, useState} from "react";
import {getLinkFromUrl, getProductDetailLink, isAdmin, user} from "../../utils/Utils";
import {toDateTimeString} from "../../utils/TimeUtil"
import {ScraperService} from "../../service/ScraperService";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";

import {ErrorService} from "../../service/ErrorService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import {AppContext} from "../../HomePage";
import {TabPanel, TabView} from "primereact/tabview";
import CopyToClipboard from "../../utils/CopyToClipboard";
import {job_states} from "../../utils/Constants";
import {Loading} from "../../utils/InlineComponents";

const ScrapeStatus = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const [showDetail, setShowDetail] = useState(false);
    const [reportType, setReportType] = useState('incorrect_price');
    const [reportMessage, setReportMessage] = useState('');
    const [allJobsInfo, setAllJobsInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);
    const scraping_request = props.scraping_request;
    const parent_request = props.parent_request;

    const [selectedScrapingRequest, setSelectedScrapingRequest] = useState(null);
    const [showSelectedScrapingRequestDetail, setShowSelectedScrapingRequestDetail] = useState(false);

    const scraperService = new ScraperService();
    const errorService = new ErrorService();

    const getAllJobsInfo = () => {
        if (props.campaign_id && isAdmin()) {
            setAllJobsInfo([])
            setLoading(true);
            scraperService.getTodayScrapingRequests(props.campaign_id).then(jobsInfo => {
                getAllJobsCurrentStatus(jobsInfo)
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const getAllJobsCurrentStatus = (jobsInfo) => {
        for (let i = 0; i < jobsInfo.length; i++) {
            let jobInfo = jobsInfo[i]
            let id = jobInfo.spider_args.scraping_request_id
            let job_key = jobInfo.job_key
            scraperService.getScrapingRequestStatus(id, job_key).then(data => {
                jobInfo.spider_args.divided_job_no = data.spider_args?.divided_job_no
                jobInfo.spider_args.divided_job_units = data.spider_args?.divided_job_units
                jobInfo.close_reason = data.close_reason
                jobInfo.items_scraped = data.items_scraped
                jobInfo.state = data.state
                jobInfo.spider_name = data.spider_name
                jobInfo.started_time = data.started_time
                jobInfo.updated_time = data.updated_time
                jobInfo.errors_count = data.errors_count
                if (i === (jobsInfo.length - 1)) {
                    setLoading(false);
                    jobsInfo.sort( compareJobs );
                    setAllJobsInfo(jobsInfo)
                }
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const compareJobs = ( a, b ) => {
        if ( a.created_at > b.created_at ){
            return -1;
        }
        if ( a.created_at < b.created_at ){
            return 1;
        }

        if ( parseInt(a.spider_args?.divided_job_no) < parseInt(b.spider_args?.divided_job_no) ){
            return -1;
        }
        if (parseInt(a.spider_args?.divided_job_no) > parseInt(b.spider_args?.divided_job_no) ){
            return 1;
        }
        return 0;
    }

    const getScrapingStatus = (scraping_request, t) =>{

        let status = scraping_request && scraping_request.state ? scraping_request.state: "";
        if (status === job_states.failed && !isAdmin() &&
            scraping_request && (scraping_request.type === "prices_campaign_update" || scraping_request.type === "products_create"))
            status = job_states.done;



        let colorClass="";
        let style = {};
        let icon;
        let tooltip = "";

        if (props.type === "price") {
            icon = "pi pi-tag";
            colorClass = "p-button-success";
            tooltip = t('actions.request_price_check');
        }
        else if (props.type === "product") {
            icon = "pi pi-tags";
            colorClass = "p-button-success";
            tooltip = t('actions.request_product_update');
        }
        else if (props.type === "suggest") {
            icon = "pi pi-google";
            colorClass = "p-button-info";
            tooltip = t('actions.request_find_matches');
        }
        else if (props.type === "reviews") {
            icon = "pi pi-comments";
            colorClass = "p-button-help";
            tooltip = t('scraping_request.partial_product_update');
        }
        else if (props.type === "failed_reviews") {
            icon = "pi pi-comments";
            colorClass = "p-button-danger";
            tooltip = t('scraping_request.partial_product_update');
        }
        else {
            icon = "pi pi-check-circle";
            colorClass = "p-button-help";
            tooltip = "";
        }
        if (props.text)
            tooltip = props.text;

        if (status === "") {
            tooltip += "\n\n" + t('scraping_request.statuses.done')
        }
        else if (status === job_states.done) {
            tooltip += "\n\n" + (t('scraping_request.statuses.done') + " : " + toDateTimeString(scraping_request.completed_at));
        }
        else if (status === job_states.failed) {
            style={borderColor:"red",color:"red"}
            tooltip += "\n\n" + (t('scraping_request.statuses.failed') + " : " + toDateTimeString(scraping_request.completed_at));
        }
        else if (status === job_states.todo) {
            style={borderColor:"maroon",color:"maroon"}
            tooltip += "\n\n" + (t('scraping_request.statuses.todo') + " : " + toDateTimeString(scraping_request.created_at));
        }
        else if (status === job_states.in_progress) {
            style={borderColor:"lightsalmon",color:"lightsalmon"}
            tooltip += "\n\n" + (t('scraping_request.statuses.in_progress') + " : " + toDateTimeString(scraping_request.started_at));
            icon += " pi-spin"
        }
        else if (status === job_states.in_preparation) {
            style={borderColor:"deepskyblue",color:"deepskyblue"}
            tooltip += "\n\n" + t('scraping_request.statuses.in_preparation');
            icon += " pi-spin"
        }

        return <Button onClick={() => setShowDetail(true)} icon={icon} className={"p-button-outlined p-button-rounded p-mr-1 " + colorClass} style={style} tooltipOptions={{position: 'bottom'}} tooltip={tooltip} />;
    };


    let result = parent_request && (parent_request.state === job_states.todo || parent_request.state === job_states.in_progress) ? getScrapingStatus(parent_request, t) : getScrapingStatus(scraping_request, t);

    let is_running = false;
    if (props.type !== "product")
    {
        is_running = false;
        if (isAdmin())// && selectedProduct.scraping_request.state === job_states.failed)
            is_running = false;
        else if (parent_request && parent_request.state !== job_states.done)
            is_running = true;
        else if (scraping_request && scraping_request.state !== job_states.done)
            is_running = true;
    }
    else {
        if (isAdmin())// && selectedProduct.scraping_request.state === job_states.failed)
            is_running = false;
        else if (parent_request && (parent_request.state === job_states.todo || parent_request.state === job_states.in_progress))
            is_running = true;
        else if (scraping_request && (scraping_request.state === job_states.todo || scraping_request.state === job_states.in_progress))
            is_running = true;
    }

    const report = () => {

        errorService.addError(scraping_request ? scraping_request.id : null, props.campaign_id, props.product_id, props.competitor_url, reportMessage, reportType).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.report_failure'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };
    const tooltip = props.type === "price" ?
        t('actions.request_price_check') : (props.type === "suggest" ?
            t('actions.request_find_matches') : (props.type === "product" ?
                t('actions.request_product_update') : (props.type === "partial_product_update" ?
                    t('scraping_request.partial_product_update') : "")));

    const saveNewRequest = () => {

        if (props.newRequest){
            scraperService.saveScrapingRequest(props.newRequest).then(scraping_requests_created=>{
                props.newRequestCompleted(scraping_requests_created);
                toast.current.show({severity: 'success', summary: tooltip, detail: t('message_detail.successful')});
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        }
        else
        {
            props.newRequestCompleted();
        }
    };

    const reportTypes = [
        {label: t('scraping_request.report_types.incorrect_price'), value: 'incorrect_price'},
        {label: t('scraping_request.report_types.incorrect_availability'), value: 'incorrect_availability'},
        {label: t('scraping_request.report_types.incorrect_product_matching'), value: 'incorrect_product_matching'},
        {label: t('scraping_request.report_types.incorrect_data'), value: 'incorrect_data'},
        {label: t('scraping_request.report_types.other'), value: 'other'}
    ];

    const startedAtTemplate = () =>{
        return <React.Fragment>
            <div className="p-col-3"><h6><b>{t('scraping_request.started_at')}</b></h6></div>
            <div className="p-col-9">{toDateTimeString(scraping_request.started_at)}</div>
        </React.Fragment>;
    };
    const completedAtTemplate = () => {
        return <React.Fragment>
            <div className="p-col-3"><h6><b>{t('scraping_request.completed_at')}</b></h6></div>
            <div className="p-col-9">{toDateTimeString(scraping_request.completed_at)}</div>
        </React.Fragment>;
    };
    const requestIdTemplate = () => {
        return <React.Fragment>
            <div className="p-col-3"><h6><b>{t('scraping_request.id')}</b></h6></div>
            <div className="p-col-9">{scraping_request.id}<CopyToClipboard textToCopy={scraping_request.id} /></div>
        </React.Fragment>;
    };
    const productTemplate = (scraping_request) => {
        return scraping_request.product_id ? <React.Fragment>
            <div className="p-col-12">{getProductDetailLink(scraping_request.product_id, t('scraping_request.product_id'))}</div>
        </React.Fragment> : null;
    };
    const dividedJobTemplate = (rowData) => {
        let job_id = rowData.spider_args.divided_job_no ? (parseInt(rowData.spider_args.divided_job_no) + 1) + "/" + rowData.spider_args.divided_job_units : "1/1";
        return <a target="_blank" rel="noopener noreferrer" href={rowData.url}>{job_id}</a>;
    };
    const scrapingRequestIdTemplate = (rowData) => {
        return rowData.spider_args.scraping_request_id;
    };

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <Button icon="pi pi-info" className="p-button-outlined p-button-info" tooltip={t('actions.detail')} style={{marginRight: '.5em'}} onClick={() => {showStatusDetailPage(rowData);}}/>
        </React.Fragment> ;
    };

    const showStatusDetailPage = (rowData) => {
        scraperService.getScrapingRequest(rowData.spider_args.scraping_request_id).then(data=>{
            setShowSelectedScrapingRequestDetail(true);
            setSelectedScrapingRequest(data);
        }).catch(error => {
            setError(error);
            setError(null);
            setShowSelectedScrapingRequestDetail(false);
        });

    }

    const showFailures = () => {
        if (selectedScrapingRequest && selectedScrapingRequest.failure_details && selectedScrapingRequest.failure_details.length > 0) {
            return <Panel header={t('scraping_request.job_detail')} toggleable={true}>
                <Button label={t('actions.back')} icon="pi pi-arrow-left" className="p-button-outlined p-button-success" onClick={(e) => {setShowSelectedScrapingRequestDetail(false)}}/>
                {isAdmin() && <div className="p-grid p-col-12">{productTemplate(selectedScrapingRequest)}</div>}
                {isAdmin() && <div className="p-grid p-col-12">{urlTemplate(selectedScrapingRequest)}</div>}
                {isAdmin() && <div className="p-grid p-col-12">{competitorUrlTemplate(selectedScrapingRequest)}</div>}
                {isAdmin() && <div className="p-grid p-col-12">{urlsTemplate(selectedScrapingRequest)}</div>}
                {isAdmin() && <div className="p-grid p-col-12">{failureTemplate(selectedScrapingRequest)}</div>}
                {isAdmin() && <div className="p-grid p-col-12">{ignoredTemplate(selectedScrapingRequest)}</div>}
            </Panel>
        }

        return <Button label={t('actions.back')} icon="pi pi-arrow-left" className="p-button-outlined p-button-success" onClick={(e) => {setShowSelectedScrapingRequestDetail(false)}}/>
    }

    const jobInfoTemplate = (jobs) => {
        return jobs.length > 0 ? <React.Fragment>
            <DataTable value={jobs} header={t('scraping_request.job_state')} responsive={true} paginator={true} rows={10}>
                <Column field="spider_args.divided_job_no" body={dividedJobTemplate} header={t('scraping_request.divided_job_no')}/>
                <Column field="spider_args.scraping_request_id" body={scrapingRequestIdTemplate} header={t('scraping_request.id')}/>
                <Column field="spider" header={t('scraping_request.spider_name')}/>
                <Column field="type" header={t('scraping_request.type')}/>
                <Column field="close_reason" header={t('scraping_request.close_reason')}/>
                <Column field="state" header={t('scraping_request.state')}/>
                <Column field="started_time" header={t('scraping_request.started_at')}/>
                <Column field="updated_time" header={t('scraping_request.updated_time')}/>
                <Column field="items_scraped" header={t('scraping_request.items_scraped')}/>
                <Column field="errors_count" header={t('scraping_request.errors_count')}/>
                <Column body={actionTemplate} style={{textAlign: 'center'}}  style={{width:"5em"}}/>
            </DataTable>
        </React.Fragment> : null;
    };
    const urlTemplate = (scraping_request) => {
        return scraping_request.url ?
            <React.Fragment>
                <div className="p-col-3">{t('scraping_request.url')}</div>
                <div className="p-col-9">
                    {getLinkFromUrl(scraping_request.url)}
                </div>
            </React.Fragment> : null;
    };
    const competitorUrlTemplate = (scraping_request) => {
        return scraping_request.competitor_url ?
            <React.Fragment>
                <div className="p-col-3">{t('scraping_request.competitor_url')}</div>
                <div className="p-col-9">
                    {getLinkFromUrl(scraping_request.competitor_url)}
                </div>
            </React.Fragment> : null;
    };

    const urlRowTemplate = (rowData) => {
        return getLinkFromUrl(rowData.url);
    };
    const urlsTemplate = (scraping_request) => {
        return scraping_request.urls && scraping_request.urls.length > 0 ?
            <React.Fragment>
                <DataTable value={scraping_request.urls} responsive={true} header={t('scraping_request.urls')}>
                    <Column field="url" body={urlRowTemplate} header={t('scraping_request.url')}/>
                </DataTable>
            </React.Fragment> : null;
    };
    const failedUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.url);
    };
    const failedProductTemplate = (rowData) => {
        return getProductDetailLink(rowData.product_id, t('scraping_request.product_id'));
    };
    const failedRedirectUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.redirect_url);
    };
    const failedCompetitorUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.competitor_url);
    };

    const failureTemplate = (scraping_request) => {
        return scraping_request.failure_details && scraping_request.failure_details.length > 0 ? <React.Fragment>
            <DataTable value={scraping_request.failure_details} header={t('scraping_request.failure_details')}
                       responsive={true} paginator={true} rows={10}>
                <Column field="url" body={failedUrlTemplate} header={t('scraping_request.url')} sortable/>
                <Column field="redirect_url" body={failedRedirectUrlTemplate} header={t('scraping_request.redirect_url')} sortable/>
                <Column field="product_id" body={failedProductTemplate} header={t('scraping_request.product_id')} sortable />
                <Column field="competitor_url" body={failedCompetitorUrlTemplate} header={t('scraping_request.competitor_url')} sortable/>
                <Column field="exception" header={t('scraping_request.exception')} sortable/>
            </DataTable>
        </React.Fragment> : null;
    };
    const ignoredTemplate = (scraping_request) => {
        return scraping_request.ignored_errors && scraping_request.ignored_errors.length > 0 ? <React.Fragment>

            <DataTable value={scraping_request.ignored_errors} header={t('scraping_request.ignored_errors')}
                       responsive={true} paginator={true} rows={10}>
                <Column field="url" body={failedUrlTemplate} header={t('scraping_request.url')} sortable/>
                <Column field="redirect_url" body={failedRedirectUrlTemplate} header={t('scraping_request.redirect_url')} sortable/>
                <Column field="product_id" body={failedProductTemplate} header={t('scraping_request.product_id')} sortable/>
                <Column field="competitor_url" body={failedCompetitorUrlTemplate} header={t('scraping_request.competitor_url')} sortable/>
                <Column field="exception" header={t('scraping_request.exception')} sortable/>
            </DataTable>

        </React.Fragment> : null;
    };

    return <React.Fragment>
        {result}
        <Dialog header={t('scraping_request.job_detail')} visible={showDetail} style={{textAlign:"left", width: isAdmin() ? '80vw': '50vw', minWidth: '20vw'}} modal={true} onHide={() => setShowDetail(false)} maskClassName="preventSelect">
            <br/>
            <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)} style={{paddingTop:"0", paddingLeft:"0", paddingRight:"0"}}>
                <TabPanel header={t('scraping_request.job_detail')} leftIcon="pi pi-info-circle" >
                    {scraping_request && <div className="p-grid p-col-12">{startedAtTemplate()}</div>}
                    {scraping_request && <div className="p-grid p-col-12">{completedAtTemplate()}</div>}
                    {isAdmin() && scraping_request && <div className="p-grid p-col-12">{requestIdTemplate()}</div>}
                    <div className="p-col-12" style={{textAlign:"right"}}>
                        <span>{props.type && <Button disabled={is_running ? "disabled" : ""} icon="pi pi-refresh" className="p-button-outlined p-button p-button-success" label={tooltip} onClick={() => saveNewRequest()}/>}</span>
                    </div>
                    <br/>

                    {isAdmin() && scraping_request && scraping_request.failure_details && scraping_request.failure_details.length > 0 &&
                        <Panel header={t('scraping_request.job_detail')} toggleable={true}>
                            {isAdmin() && <div className="p-grid p-col-12">{productTemplate(scraping_request)}</div>}
                            {isAdmin() && <div className="p-grid p-col-12">{urlTemplate(scraping_request)}</div>}
                            {isAdmin() && <div className="p-grid p-col-12">{competitorUrlTemplate(scraping_request)}</div>}
                            {isAdmin() && <div className="p-grid p-col-12">{urlsTemplate(scraping_request)}</div>}
                            {isAdmin() && <div className="p-grid p-col-12">{failureTemplate(scraping_request)}</div>}
                            {isAdmin() && <div className="p-grid p-col-12">{ignoredTemplate(scraping_request)}</div>}
                        </Panel>
                    }
                </TabPanel>
                <TabPanel header={t('actions.report_failure')} leftIcon="pi pi-question-circle" >
                    <div className="p-grid">
                        <div className="p-col-3">
                            <b>{t('scraping_request.report_type')}</b>
                        </div>
                        <div className="p-col-9">
                            <Dropdown id="report_type" name="report_type" value={reportType} options={reportTypes} onChange={(e) => setReportType(e.value)} />
                        </div>
                        <div className="p-col-3">
                            <b>{t('scraping_request.report_message')}</b>
                        </div>
                        <div className="p-col-9">
                            <InputTextarea id="report_message" name="report_message" rows={5} cols={50} value={reportMessage} autoResize={true} onChange={(e) =>  setReportMessage(e.target.value) } />
                        </div>
                        <div className="p-col-3"></div>
                        <div className="p-col-9">
                            <Button label={t('actions.report_failure')} icon="pi pi-envelope" className="p-button-outlined p-button-success" onClick={() => report()} style={{marginRight:"0.5em"}} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header={t('scraping_request.all_jobs_detail')} leftIcon="pi pi-info-circle" >
                    {isAdmin() &&
                        <div className="p-grid p-col-12">
                            <Button label={t('scraping_request.show_all_today_jobs_status')} icon="pi pi-info-circle" className="p-button-outlined p-button-warning" onClick={() => getAllJobsInfo()} style={{marginRight:"0.5em"}} />
                        </div>
                    }

                    {loading ?
                        <div className="p-col-12" style={{textAlign: 'center'}}>
                            <Loading/>
                        </div> :
                        <div className="p-grid p-col-12">
                            {showSelectedScrapingRequestDetail ? showFailures() : jobInfoTemplate(allJobsInfo)}
                        </div>
                    }

                </TabPanel>
            </TabView>
        </Dialog>

    </React.Fragment>;
};
export default ScrapeStatus;
