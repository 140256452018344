import React, {useEffect, useState} from "react";
import {
    exportFile,
    formatCurrency,
    getKeyValue,
    getProductDetailLink
} from "../../utils/Utils";
import {toDateString} from "../../utils/TimeUtil";
import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {ExportService} from "../../service/ExportService";
import {Dropdown} from "primereact/dropdown";
import {CampaignContext} from "../../App";

const WeeklyPriceComparison = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [items, setItems] = useState([]);
    const [years, setYears] = useState([]);
    const [weekNumbers, setWeekNumbers] = useState([]);

    const [startYear, setStartYear] = useState(null);
    const [startWeekNumber, setStartWeekNumber] = useState(null);
    const [endYear, setEndYear] = useState(null);
    const [endWeekNumber, setEndWeekNumber] = useState(null);


    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);

    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect ( ()=>{
        let currentDate = new Date();
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
        let currentWeekNumber = Math.ceil(days / 7);
        let startWeekNumber = currentWeekNumber - 6 > 0 ? currentWeekNumber - 6 : 1;
        setStartYear(currentDate.getFullYear())
        setEndYear(currentDate.getFullYear())
        setStartWeekNumber(startWeekNumber)
        setEndWeekNumber(currentWeekNumber)

        let weekNumbers = []
        for (let i = 1; i < 53; i++) {
            weekNumbers.push({value: i, label: "Week - " + i})
        }
        setWeekNumbers(weekNumbers)

        let years = []
        years.push(currentDate.getFullYear() - 1)
        years.push(currentDate.getFullYear())
        setYears(years)

    },[selectedCampaign]);

    const search = () => {
        if (selectedCampaign && startWeekNumber && endWeekNumber) {
            setLoading(true);
            dashboardService.getWeeklyPriceComparison(selectedCampaign.id, startYear, startWeekNumber, startYear, endWeekNumber).then(data=>{
                setItems(data);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const onExport = () => {
        setLoadingExcelExport(true);
        exportService.exportWeeklyPriceComparisonSummary(selectedCampaign.id, startYear, startWeekNumber, startYear, endWeekNumber).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.weekly_price_comparison') + "_" + toDateString(new Date()) + ".xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const nameTemplate = (rowData) => {
        return getProductDetailLink(rowData.product_id, rowData.product_name);
    };

    const ownPriceTemplate = (rowData) => {
        return rowData.map_owner_value ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.map_owner_value, rowData.map_owner_currency)}
        </div> :
            rowData.owner_value ? <div className="p-grid p-justify-end">
                {formatCurrency(rowData.owner_value, rowData.owner_currency)}
            </div> : '';
    };

    const minPriceTemplate = (rowData) => {
        return rowData.min_value ? <div className="p-grid p-justify-end">
                {formatCurrency(rowData.min_value, rowData.currency)}
            </div> : '';
    };

    const maxPriceTemplate = (rowData) => {
        return rowData.max_value ? <div className="p-grid p-justify-end">
                {formatCurrency(rowData.max_value, rowData.currency)}
            </div> : '';
    };

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.weekly_price_comparison')}</h3>
                <hr></hr>
            </div>

            {loading ?
                <div className="p-col-12" style={{textAlign:'center'}}>
                    <Loading/>
                </div> :
                <div className="p-col-12 p-grid p-fluid">
                    <div className="p-col-1">
                        <span className="p-float-label">
                            <Dropdown id="start_year" name="start_year" value={startYear} options={years} onChange={(e)=>{setStartYear(getKeyValue(e).value);}} />
                        </span>
                    </div>
                    <div className="p-col-2">
                        <span className="p-float-label">
                            <Dropdown id="start_week_number" name="start_week_number" value={startWeekNumber} options={weekNumbers} onChange={(e)=>{setStartWeekNumber(getKeyValue(e).value);}} />
                        </span>
                    </div>

                    <div className="p-col-2">
                        <span className="p-float-label">
                            <Dropdown id="finish_week_number" name="finish_week_number" value={endWeekNumber} options={weekNumbers} onChange={(e)=>{setEndWeekNumber(getKeyValue(e).value);}} />
                        </span>
                    </div>

                    <div className="p-col-2 p-mt-2">
                        <Button icon="pi pi-search" className="p-button-outlined p-button-info" label={t('actions.search')} onClick={search} loading={loadingExcelExport} />
                    </div>

                    <div className="p-col-3 p-mt-2">
                        <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
                    </div>
                    <div className="p-col-12">
                        <DataTable value={items} scrollable scrollHeight={(window.innerHeight - 430)+"px"}
                                   emptyMessage={t('actions.empty_message')}
                                   paginator={true} rows={100}>
                            <Column field="year_week" header={t('weekly_price_comparison.year_week')} headerStyle={{width: '100px'}} sortable filter/>
                            <Column field="range" header={t('weekly_price_comparison.range')} headerStyle={{width: '150px'}} sortable filter/>
                            <Column field="product_code" header={t('weekly_price_comparison.product_code')} headerStyle={{width: '150px'}} sortable filter/>
                            <Column field="product_name" body={nameTemplate} header={t('weekly_price_comparison.product_name')} headerStyle={{width: '300px'}} sortable filter/>
                            <Column field="category" header={t('weekly_price_comparison.category')} headerStyle={{width: '200px'}} sortable filter/>
                            <Column field="sub_category" header={t('weekly_price_comparison.sub_category')} headerStyle={{width: '200px'}} sortable filter/>
                            <Column field="seller_name" header={t('weekly_price_comparison.seller_name')} headerStyle={{width: '150px'}} sortable filter/>
                            <Column field="owner_value" body={ownPriceTemplate} header={t('weekly_price_comparison.owner_value')} headerStyle={{width: '100px', textAlign:"right"}}/>
                            <Column field="min_value" body={minPriceTemplate} header={t('weekly_price_comparison.min_value')} headerStyle={{width: '100px', textAlign:"right"}}/>
                            <Column field="max_value" body={maxPriceTemplate} header={t('weekly_price_comparison.max_value')} headerStyle={{width: '100px', textAlign:"right"}}/>
                            <Column field="min_difference_rate" header={t('weekly_price_comparison.min_difference_rate')} headerStyle={{width: '100px'}}/>
                            <Column field="max_difference_rate" header={t('weekly_price_comparison.max_difference_rate')} headerStyle={{width: '100px'}}/>
                        </DataTable>
                    </div>
                </div>
                }
        </div>

    </React.Fragment>;
};
export default WeeklyPriceComparison;
