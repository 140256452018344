import React from "react";

import {Button} from "primereact/button";
import {Logo} from "./InlineComponents";

export const tourTextSize = '1.2em';
/*style: {
                backgroundColor: '#fafafa',
            },
            stepInteraction: false,
            navDotAriaLabel: 'Go to step 2',
            position: 'top',
            // position: [160, 250],

            action: node => {
                // by using this, focus trap is temporary disabled
                //node.focus();
                //console.log('yup, the target element is also focused!');
            }


              content: ({ goTo }) => (
                        <div>
                            Lorem ipsum <button onClick={() => goTo(4)}>Go to Step 5</button>
                            <br />
                            { '🎉 Look at your step!'}
                        </div>
                    ),

            */

export const campaignTourSteps = (t, i18n, onCloseTour, appName) => {

    if (i18n.language.toLowerCase() === "tr")
        return [
            {
                selector: '[data-tour="step1"]',
                content: <div style={{fontSize: tourTextSize}}><Logo/> {appName}'e hoşgeldiniz.<p/>Sizi {appName} kullanımını anlatacağımız bir tura
                    davet etmek istiyoruz.</div>,
            },
            {
                selector: '[data-tour="step2"]',
                content: <div style={{fontSize: tourTextSize}}>Uygulamayı kullanmaya başlamak için en az bir kampanya tanımınız olmalıdır.
                    <p/>Kampanyalar uygulama içinde ürünleri gruplamak için kullanılır.</div>,

            },
            {
                selector: '[data-tour="step3"]',
                content: <div style={{fontSize: tourTextSize}}>Kampanya adı uygulama içinde göreceğiniz kampanya bilgisidir.</div>
            },
            {
                selector: '[data-tour="step4"]',
                content: <div style={{fontSize: tourTextSize}}>Kampanya URL'leri.<p/>
                    Kampanya tanımlanmasından sonra, Ürün Kaynaklarım bölümünden tanımlama yaparak sizin için otomatik olarak kampanya altına eklenecek ürünleri tanımlayabilirsiniz.
                    <p/>Ayrıca sitenizdeki ürünlerin fiyat ve varsa stok bilgileri üyelik planınıza göre hergün güncellenecektir.
                </div>
            },

            {
                selector: '[data-tour="step5"]',
                content: <div style={{fontSize: tourTextSize}}>Rakip Siteler<p/>
                    Rakip önerisi almak istediğiniz web site URL'lerini lütfen bizimle paylaşınız.
                    <p/>Gerekli tanımlamalar yapıldıkdan sonra burada Rakip tanımlarınızı görebilirsiniz.
                </div>
            },
            {
                selector: '[data-tour="step6"]',
                content: <div style={{fontSize: tourTextSize}}>Güncellemeler<p/>
                    Sitenizden ve rakiplerinizden otomatik bilgi almak için çalışan işlerin durumunu buradan takip edebilirsiniz.
                    <p/>Hata olduğunu düşündüğünüz durumlar için "Hata Bildirimi" yapabilirsiniz.
                    <p/>Üyelik tipinize göre işleri manuel çalıştırabilirsiniz.
                </div>
            },
            {
                selector: '[data-tour="step7"]',
                content: <div style={{fontSize: tourTextSize}}>Kendi kampanyanızı eklemek için butonu kullanabilirsiniz.</div>
            },
            {
                selector: '[data-tour="step8"]',
                content: <div style={{fontSize: tourTextSize}}>Kayıtlı kampanyanızı buradan silebilirsiniz.<p/>
                    <i className="pi pi-exclamation-circle" style={{color: "red"}}/>Kampanya silinince içindeki tüm ürünlerde silinecektir.</div>
            },
            {
                selector: '[data-tour="step9"]',
                content: <div style={{fontSize: tourTextSize}}>Ürün listesine menüden veya kampanya üzerine tıklayarak ulaşabilirsiniz.<p/>
                    <Button icon="pi pi-fw pi-list" onClick={() => {
                        onCloseTour();
                        window.location = '#/product';
                    }} label={t('menu_item.products')}/>
                </div>,
            }
        ];
    else
        return [
            {
                selector: '[data-tour="step1"]',
                content: <div style={{fontSize: tourTextSize}}><Logo/> Welcome to {appName}. <p/>
                    We would like to invite you to a tour where we will explain the use of {appName}.</div>,
            },
            {
                selector: '[data-tour="step2"]',
                content: <div style={{fontSize: tourTextSize}}>You must have at least one campaign definition to start
                    using the application.
                    <p/> Campaigns are used to group products within the application.
                </div>,

            },
            {
                selector: '[data-tour="step3"]',
                content: <div style={{fontSize: tourTextSize}}>Campaign name is the campaign information you will see in
                    the application.</div>
            },
            {

                // "Kampanya tanımlanmasından sonra, Ürün Kaynaklarım bölümünden tanımlama yaparak sizin için otomatik olarak kampanya altına eklenecek ürünleri tanımlatabilirsiniz."

                selector: '[data-tour="step4"]',
                content: <div style={{fontSize: tourTextSize}}>Campaign URL's.
                    <p/>
                    After the campaign is defined, you can define the products that will be automatically added to the campaign for you by defining them from Product Import section.
                    <p/>
                    In addition, the price and stock information of the products if available on your site will be
                    updated daily according to your membership plan.
                </div>

            },

            {
                selector: '[data-tour="step5"]',
                content: <div style={{fontSize: tourTextSize}}>Competitor Sites<p/>
                    Please share the website URLs you want to get competitor suggestions with.
                    <p/>After making the necessary definitions, you can see your competitor definitions here.
                </div>
            },

            {
                selector: '[data-tour="step6"]',
                content: <div style={{fontSize: tourTextSize}}>Price Checks<p/>
                    Here, you can follow the status of the jobs that are running to get automatic information from your site and your competitors.
                    <p/>You can make "Error Reporting" for cases where you think there is an error.
                    <p/>You can run the jobs manually according to your membership type.
                </div>
            },


            {
                selector: '[data-tour="step7"]',
                content: <div style={{fontSize: tourTextSize}}>You can use the button to add your own campaign.</div>
            },
            {
                selector: '[data-tour="step8"]',
                content: <div style={{fontSize: tourTextSize}}>You can delete your saved campaign here.<p/><i
                    className="pi pi-exclamation-circle" style={{color: "red"}}/>When the campaign is deleted, all
                    products will be deleted in your campaign.</div>
            },
            {
                selector: '[data-tour="step9"]',
                content: <div style={{fontSize: tourTextSize}}>You can access the product list from the menu or by
                    clicking on the campaign.<p/>
                    <Button icon="pi pi-fw pi-list" onClick={() => {
                        onCloseTour();
                        window.location = '#/product';
                    }} label={t('menu_item.products')}/>
                </div>,
            }];
};

export const productTourSteps = (t, i18n, appName) => {

    if (i18n.language.toLowerCase() === "tr") {
        return [
            {
                selector: '[data-tour="step1"]',
                content: <div style={{fontSize: tourTextSize}}>
                    Ürün Listenizi Kategori, Marka, Ürün Durumu, Ürün Adı, Fiyat Durumu, Stok Durumu ve Fiyat Öneri durumuna göre filtreleme
                    yapabilirsiniz.
                </div>,
            },
            {
                selector: '[data-tour="step2"]',
                content: <div style={{fontSize: tourTextSize}}>
                    Ürün listenizi burada görebilirsiniz.<p/>
                    Herhangi bir ürünün detayına ürün üzerine tıklayarak ulaşabilirsiniz.
                </div>,
            },
            {
                selector: '[data-tour="step3"]',
                content: <div style={{fontSize: tourTextSize}}>
                    Dilerseniz Ürün listenizi dışarı aktarabilirsiniz.
                </div>,

            },
            {
                selector: '[data-tour="step4"]',
                content: <div style={{fontSize: tourTextSize}}>
                    Yeni Ürün Eklemek içn bu düğmeyi kullanarak, eklemek istediğiniz ürünün URL adresini yazdıktan sonra sistem ürün bilgilerini
                    otomatik olarak alıp ürün listenize ekleyecetir.
                </div>
            },
            {
                selector: '[data-tour="step5"]',
                content: <div style={{fontSize: tourTextSize}}>
                    Ürün listenizdeki ürünlerin son durumlarını gözlemlemek için YENİLE düğmesini kullanabilirsiniz.
                </div>
            },
            {
                selector: '[data-tour="step6"]',
                content: <div style={{fontSize: tourTextSize}}>
                    Ürün detayınızda dinamik fiyatlama kriterleri belirlediyseniz sizin için {appName} algoritması en doğru fiyat bilgisini
                    önerecektir.
                </div>
            },
            {
                selector: '[data-tour="step7"]',
                content: <div style={{fontSize: tourTextSize}}>
                    İlgili üründeki rakiplerinizin arasında en ucuz fiyatı gösterir.
                </div>
            },
            {
                selector: '[data-tour="step8"]',
                content: <div style={{fontSize: tourTextSize}}>
                    İlgili üründeki rakiplerinizin arasında en pahalı fiyatı gösterir.
                </div>,
            },
            {
                selector: '[data-tour="step9"]',
                content: <div style={{fontSize: tourTextSize}}>
                    İlgili üründeki rakiplerinizin sayısını gösterir.
                </div>,
            },
            {
                selector: '[data-tour="step10"]',
                content: <div style={{fontSize: tourTextSize}}>
                    İlgili ürüne {appName}'in sizin için rakip olrak önerdiği web sitelerinin sayısını gösterir.
                </div>,
            }
            ,
            {
                selector: '[data-tour="step11"]',
                content: <div style={{fontSize: tourTextSize}}>
                    Listede görmek istemediğiniz ürünü buradan pasife çekip ya da yeniden aktif hale getirebilirsiniz.<p/>
                    Ayrıca pasife çekilen ürünler için fiyat stok güncellemesi yapılmaz.
                </div>,
            }
            ,
            {
                selector: '[data-tour="step12"]',
                content: <div style={{fontSize: tourTextSize}}>
                    Ürün bilgilerinizi bu düğme ile güncelleyebilirsiniz.
                </div>,
            }
            ,
            {
                selector: '[data-tour="step13"]',
                content: <div style={{fontSize: tourTextSize}}>
                    Ürünü silmek için bu düğmeyi kullanabilirsiniz.<p/>
                    Silinen ürüne ait tüm geçmiş bilgiler kalıcı olarak silinecektir.
                </div>,
            }

        ];

    } else {
        return [{
            selector: '[data-tour="step1"]',
            content: <div style={{fontSize: tourTextSize}}>
                You can filter your Product List by Category, Brand, Product Status, Product Name, Price Status, Stock Status and Price Suggestion
                status.
                </div>,
            },
            {
                selector: '[data-tour="step2"]',
                content: <div style={{fontSize: tourTextSize}}>
                    You can see product list here.<p/>
                    You can access the product detail of any listed product by clicking on that product.
                </div>,
            },
            {
                selector: '[data-tour="step3"]',
                content: <div style={{fontSize: tourTextSize}}>
                    You can export your product list.
                </div>,

            },
            {
                selector: '[data-tour="step4"]',
                content: <div style={{fontSize: tourTextSize}}>
                    You can add new Product by using this button, after writing the URL of the product that you want to add, the system will
                    automatically get the product information and add it to your product list.
                </div>
            },
            {
                selector: '[data-tour="step5"]',
                content: <div style={{fontSize: tourTextSize}}>
                    You can use the REFRESH button to review the latest status of the products in your product list.
                </div>
            },
            {
                selector: '[data-tour="step6"]',
                content: <div style={{fontSize: tourTextSize}}>
                    If you have set dynamic pricing criteria in the product detail, the {appName} algorithm will recommend the most accurate price
                    information for you.
                </div>
            },
            {
                selector: '[data-tour="step7"]',
                content: <div style={{fontSize: tourTextSize}}>
                    It shows the cheapest price among your competitors.
                </div>
            },
            {
                selector: '[data-tour="step8"]',
                content: <div style={{fontSize: tourTextSize}}>
                    It shows the expensive price among your competitors.
                </div>,
            },
            {
                selector: '[data-tour="step9"]',
                content: <div style={{fontSize: tourTextSize}}>
                    It Shows the number of your competitors with the product.
                </div>,
            },
            {
                selector: '[data-tour="step10"]',
                content: <div style={{fontSize: tourTextSize}}>
                    It shows the number of websites that {appName} recommends for you as a competitor.
                </div>,
            },
            {
                selector: '[data-tour="step11"]',
                content: <div style={{fontSize: tourTextSize}}>
                    You can pass the product that you do not want to see in the list, passive here or reactivate it. <p/>
                    Price monitoring is not updated for passive products.
                </div>,
            },
            {
                selector: '[data-tour="step12"]',
                content: <div style={{fontSize: tourTextSize}}>
                    You can update your product information with this button.
                </div>,
            },
            {
                selector: '[data-tour="step13"]',
                content: <div style={{fontSize: tourTextSize}}>
                    You can use this button to delete the product completely. <p/>
                    All historical information of the deleted product will be permanently deleted.
                </div>,
            }];
    }
};