import React, {useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from 'primereact/dialog';

import {ScraperService} from "../../service/ScraperService";
import queryString from "query-string";

import {
    getHostName,
    getKeyValue,
    getLinkFromUrl,
    getProductDetailLink,
    toTitleCase
} from "../../utils/Utils";
import {toDateStringIgnoringTimezone, toDateTimeString} from "../../utils/TimeUtil";
import {IndexTemplate} from "../../utils/InlineComponents";
import {job_states, yes_no} from "../../utils/Constants";



import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import {Panel} from "primereact/panel";
import {GlobalHotKeys} from "react-hotkeys";
import {AppContext} from "../../HomePage";
import {Loading} from "../../utils/InlineComponents";
import JsonToExcel from "../Export/JsonToExcel";


export const scraping_request_states = (t) => {

    return  [
        {label: t('actions.all'), value: null},
        {label: t('scraping_request.statuses.in_preparation'), value: job_states.in_preparation},
        {label: t('scraping_request.statuses.todo'), value: job_states.todo},
        {label: t('scraping_request.statuses.in_progress'), value: job_states.in_progress},
        {label: t('scraping_request.statuses.done'), value: job_states.done},
        {label: t('scraping_request.statuses.failed'), value: job_states.failed}];
};

const ScrapingJobs = (props) => {

    const {t, toast, setError, location} = React.useContext(AppContext);
    let dtJobs = useRef(null);

    const [scrapingRequests, setScrapingRequests] = useState([]);
    const [failedRequestsDigest, setFailedRequestsDigest] = useState([]);
    const [newWebsites, setNewWebsites] = useState([]);
    const [selectedRequests, setSelectedRequests] = useState(null);
    const [showDigest, setShowDigest] = useState(false);
    const [showRequests, setShowRequests] = useState(false);
    const [showNewWebsites, setShowNewWebsites] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isDeleteAll, setIsDeleteAll] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [detailLoading, setDetailLoading] = useState(false);
    const [failureDigest, setFailureDigest] = useState([]);

    const [filters, setFilters] = useState({
        searchState: job_states.in_preparation,
        dates: [new Date(), new Date()],
        state: null,
        is_rerun: null,
        type: null,
    });




    const scraperService = new ScraperService();

    useEffect ( ()=>{
        if (location !== undefined) {
            let params = queryString.parse(location.search);
            if (params && params.scraping_request_id ) {
                retrieve(params.scraping_request_id)
            }
        }
    },[]);

    const back = () => {
        setShowDetail(false);
        setShowRequests(true);
        setRowData(null);
    };

    const previous = () => {
        let index = scrapingRequests.findIndex(p=> p.id === rowData.id);
        let newIndex = (index === 0 ? scrapingRequests.length - 1 : index - 1);

        retrieve(scrapingRequests[newIndex].id);
    };

    const next = () => {
        let index = scrapingRequests.findIndex(p=> p.id === rowData.id);
        let newIndex = (index === scrapingRequests.length - 1 ? 0 : index + 1);

        retrieve(scrapingRequests[newIndex].id);
    };

    const retrieve = (scraping_request_id) => {
        setDetailLoading(true);
        scraperService.getScrapingRequest(scraping_request_id).then(data => {
            data = { ...data, user_full_name: toTitleCase(data.user_first_name) + ' ' + toTitleCase(data.user_last_name) };
            setDetailLoading(false);
            setRowData(data);
            setShowDetail(true);
        }).catch(error =>{
            setError(error);
            setError(null);
            setDetailLoading(false);
        });
        scraperService.retrieveFailedRequestsDigest(scraping_request_id).then(data => {
            setFailureDigest(data.failure_digest);
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const search = () => {
        if (filters.dates[0] && filters.dates[1]) {
            setLoading(true);
            setShowDigest(false)
            setShowRequests(true)
            setShowNewWebsites(false)
            scraperService.listScrapingJobs(filters.searchState, toDateStringIgnoringTimezone(filters.dates[0]), toDateStringIgnoringTimezone(filters.dates[1])).then(data => {
                data = data.map(sr => ({...sr, user_full_name: toTitleCase(sr.user_first_name) + ' ' + toTitleCase(sr.user_last_name)}));
                setScrapingRequests(data);
                setLoading(false);
            }).catch(error => {
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const listFailedRequestsDigest = () => {
        if (filters.dates[0] && filters.dates[1]) {
            setLoading(true);
            setShowDigest(true)
            setShowRequests(false)
            setShowNewWebsites(false)
            scraperService.listFailedRequestsDigest(toDateStringIgnoringTimezone(filters.dates[0]), toDateStringIgnoringTimezone(filters.dates[1])).then(data => {
                data = data.flatMap(sr => (sr.failure_digest.map(fd => ({
                    ...fd,
                    user_full_name: toTitleCase(sr.user_first_name) + ' ' + toTitleCase(sr.user_last_name),
                    user_email: sr.user_email,
                    id: sr.id,
                    campaign_id: sr.campaign_id,
                    campaign_name: sr.campaign_name,
                    total_failure_count: sr.total_failure_count
                }))));
                setFailedRequestsDigest(data);
                setLoading(false);
            }).catch(error => {
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const listNewWebsites = () => {
        if (filters.dates[0] && filters.dates[1]) {
            setLoading(true);
            setShowDigest(false)
            setShowRequests(false)
            setShowNewWebsites(true)
            scraperService.listNewWebsites(toDateStringIgnoringTimezone(filters.dates[0]), toDateStringIgnoringTimezone(filters.dates[1])).then(data => {
                data = data.flatMap(sr => (sr.hostnames.map(hostname => ({
                    hostname: hostname,
                    user_full_name: toTitleCase(sr.user_first_name) + ' ' + toTitleCase(sr.user_last_name),
                    user_email: sr.user_email,
                }))));
                setNewWebsites(data);
                setLoading(false);
            }).catch(error => {
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const actionTemplate = (rowData) => {
        return <div>
            <Button icon="pi pi-refresh" className="p-button-outlined p-button-success" tooltip={t('actions.rerun')} tooltipOptions={{position: 'bottom'}} style={{marginRight: '.5em'}} onClick={() => rerun(rowData)}/>
            <Button icon="pi pi-trash" className="p-button-outlined p-button-danger" tooltip={t('actions.delete')} tooltipOptions={{position: 'bottom'}} style={{marginRight: '.5em'}} onClick={() => {
                setShowModal(true);
                setRowData(rowData);
                setIsDeleteAll(false);
            }}/>
            <Button icon="pi pi-info" className="p-button-outlined p-button-info" tooltip={t('actions.detail')} tooltipOptions={{position: 'bottom'}} style={{marginRight: '.5em'}} onClick={() => {
                retrieve(rowData.id)
                setShowDetail(true);
                setRowData(rowData);
            }}/>
        </div>;
    };

    const deleteWithId = (id) => {
        scraperService.deleteScrapingRequest(id).then(() => {
            let filteredRequests = scrapingRequests.filter(scrapingRequest => scrapingRequest.id !== id);
            setScrapingRequests(filteredRequests);
            setShowModal(false);
            setShowDetail(false);
            setRowData(null);

            toast.current.show({severity: 'success', summary: t('actions.delete_request'), detail: t('message_detail.successful') });
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const onDelete = () => {
        if (isDeleteAll)
            deleteAll();
        else
            deleteWithId(rowData.id);
    };

    const deleteAll = () => {
        selectedRequests.forEach(request => {
            deleteWithId(request.id);
        });
        setSelectedRequests(null);
    };


    const rerunWithId = (id) => {
        scraperService.rerun(id).then(
            toast.current.show({severity: 'success', summary: t('actions.refresh_request'), detail: t('message_detail.successful') })
        ).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const rerun = (rowData) => {
        rerunWithId(rowData.id);
    };

    const rerunAll = () => {
        selectedRequests.forEach(request => {
            rerunWithId(request.id);
        });
    };

    const onChange = (e) =>{
        const { key, value } = getKeyValue(e);
        setFilters({...filters, [key]: value});
    };

    const onFilterChange = (e) => {
        const { key, value } = getKeyValue(e);
        setFilters({...filters, [key]: value});
        dtJobs.current.filter(value, key, 'in');
    };

    const getAllUrlArray = (rowData) => {
        let str = [];

        if (rowData.url)
            str.unshift(getHostName(rowData.url));
        if (rowData.competitor_url && str.indexOf(getHostName(rowData.competitor_url)) === -1)
            str.unshift(getHostName(rowData.competitor_url));

        rowData.urls.forEach(url =>{
            if (url && str.indexOf(getHostName(url)) === -1)
                str.unshift(getHostName(url)) });

        return str;
    };

    const allUrlsTemplate = (rowData) =>{
        return <span>{getAllUrlArray(rowData).toString()}</span>;
    };

    const IsReRunTemplate = (rowData) =>{
        return <span>{rowData.is_rerun ? t('yes_no.yes') : t('yes_no.no') }</span>;
    };

    const createdAtTemplate = (rowData) => <span>{toDateTimeString(rowData.created_at)}</span>;

    const idTemplate = () =>{
        return <React.Fragment>
            <div className="p-col-3"><span style={{fontWeight: "bold"}}>{t('scraping_request.id')}</span></div>
            <div className="p-col-9">{rowData.id}</div>
        </React.Fragment>;
    };

    const typeTemplate = () =>{
        return <React.Fragment>
            <div className="p-col-3"><span style={{fontWeight: "bold"}}>{t('scraping_request.type')}</span></div>
            <div className="p-col-9">{rowData.type}</div>
        </React.Fragment>;
    };

    const emailTemplate = () =>{
        return <React.Fragment>
            <div className="p-col-3"><span style={{fontWeight: "bold"}}>{t('user.email')}</span></div>
            <div className="p-col-9">{rowData.user_email}</div>
        </React.Fragment>;
    };

    const userFullNameTemplate = () =>{
        return <React.Fragment>
            <div className="p-col-3"><span style={{fontWeight: "bold"}}>{t('user.name')}</span></div>
            <div className="p-col-9">{rowData.user_full_name}</div>
        </React.Fragment>;
    };

    const startedAtTemplate = () =>{
        return <React.Fragment>
                <div className="p-col-3"><span style={{fontWeight: "bold"}}>{t('scraping_request.started_at')}</span></div>
                <div className="p-col-9">{toDateTimeString(rowData.started_at)}</div>
        </React.Fragment>;
    };

    const completedAtTemplate = () => {
        return <React.Fragment>
            <div className="p-col-3"><span style={{fontWeight: "bold"}}>{t('scraping_request.completed_at')}</span></div>
            <div className="p-col-9">{toDateTimeString(rowData.completed_at)}</div>
        </React.Fragment>;
    };

    const productTemplate = () => {
        return rowData.product_id ? <React.Fragment>
            <div className="p-col-12">{getProductDetailLink(rowData.product_id, t('scraping_request.product_id'))}</div>
        </React.Fragment> : null;
    };

    const urlTemplate = () => {
        return rowData.url ?
            <React.Fragment>
                <div className="p-col-3"><span style={{fontWeight: "bold"}}>{t('scraping_request.url')}</span></div>
                <div className="p-col-9">
                    {getLinkFromUrl(rowData.url)}
                </div>
        </React.Fragment> : null;
    };

    const competitorUrlTemplate = () => {
        return rowData.competitor_url ?
            <React.Fragment>
                <div className="p-col-3"><span style={{fontWeight: "bold"}}>{t('scraping_request.competitor_url')}</span></div>
                <div className="p-col-9">
                    {getLinkFromUrl(rowData.competitor_url)}
                </div>
            </React.Fragment> : null;
    };

    const urlRowTemplate = (rowData) => {
        return getLinkFromUrl(rowData.url);
    };

    const urlsTemplate = () => {
        return rowData.urls && rowData.urls.length > 0 ?
            <React.Fragment>
                <DataTable value={rowData.urls} responsive={true} header={t('scraping_request.urls')}>
                    <Column field="url" body={urlRowTemplate} header={t('scraping_request.url')}/>
                </DataTable>
            </React.Fragment> : null;
    };

    const failedUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.url);
    };

    const failedProductTemplate = (rowData) => {
        return getProductDetailLink(rowData.product_id, t('scraping_request.product_id'));
    };

    const failedRedirectUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.redirect_url);
    };

    const failedCompetitorUrlTemplate = (rowData) => {
        return getLinkFromUrl(rowData.competitor_url);
    };

    const failureDigestTemplate = () =>{
        return <React.Fragment>
            <div className="p-col-12">
                <DataTable value={failureDigest} dataKey="exception" paginator rows={5} rowsPerPageOptions={[5,10,20,50]}>
                    <Column field="hostname" header={t('scraping_request.hostname')} body={hostNameTemplate}/>
                    <Column field="exception" header={t('scraping_request.exception')}/>
                    <Column field="count" header={t('scraping_request.error_count')}/>
                </DataTable>
            </div>
        </React.Fragment>;
    };

    const failureTemplate = () => {
        return rowData.failure_details && rowData.failure_details.length > 0 ? <React.Fragment>

            <DataTable value={rowData.failure_details} responsive={true} header={t('scraping_request.failure_details')}>
                <Column body={IndexTemplate} style={{width:'3em'}} />
                <Column field="url" body={failedUrlTemplate} header={t('scraping_request.url')}/>
                <Column field="redirect_url" body={failedRedirectUrlTemplate} header={t('scraping_request.redirect_url')}/>
                <Column field="product_id" body={failedProductTemplate} header={t('scraping_request.product_id')}/>
                <Column field="competitor_url" body={failedCompetitorUrlTemplate} header={t('scraping_request.competitor_url')}/>
                <Column field="exception" header={t('scraping_request.exception')}/>
            </DataTable>

        </React.Fragment> : null;
    };

    const ignoredTemplate = () => {
        return rowData.ignored_errors && rowData.ignored_errors.length > 0 ? <React.Fragment>
            <DataTable value={rowData.ignored_errors} responsive={true} header={t('scraping_request.ignored_errors')}>
                <Column field="url" body={failedUrlTemplate} header={t('scraping_request.url')}/>
                <Column field="redirect_url" body={failedRedirectUrlTemplate} header={t('scraping_request.redirect_url')}/>
                <Column field="product_id" body={failedProductTemplate} header={t('scraping_request.product_id')}/>
                <Column field="competitor_url" body={failedCompetitorUrlTemplate} header={t('scraping_request.competitor_url')}/>
                <Column field="exception" header={t('scraping_request.exception')}/>
            </DataTable>
        </React.Fragment> : null;
    };

    const keyMap = {
        back: "backspace",
        previous: "ArrowLeft",
        next: "ArrowRight",
    };

    const handlers = {
        back: event => {back()},
        previous: event => {previous()},
        next: event => {next()}
    };

    const modalYesNo = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" onClick={onDelete} />
            <Button label={t('yes_no.no')} icon="pi pi-times" onClick={() => {setShowModal(false);}} />
        </div>
    );

    let types = [...new Set(scrapingRequests.map(s=> s.type))];

    const stateFilter = <MultiSelect name="state" value={filters.state} style={{width:'100%'}} options={scraping_request_states(t).filter(state=> state.value !== null)} onChange={onFilterChange}/>;
    const is_rerunFilter = <MultiSelect name="is_rerun" value={filters.is_rerun} style={{width:'100%'}} options={yes_no(t).filter(state=> state.value !== null)} onChange={onFilterChange}/>;
    const typeFilter = <MultiSelect name="type" value={filters.type} style={{width:'100%'}} options={types} onChange={onFilterChange}/>;

    const actionsHeader = <div>
        <Button icon="pi pi-refresh" className="p-button-outlined p-button-success" tooltip={t('actions.rerun_selected')} style={{marginRight: '.5em'}} onClick={() => rerunAll()}/>
        <Button icon="pi pi-trash" className="p-button-outlined p-button-danger" tooltip={t('actions.delete_selected')} style={{marginRight: '.5em'}} onClick={() => {
            setShowModal(true);
            setIsDeleteAll(true);
        }}/>
        <JsonToExcel data={selectedRequests ?? scrapingRequests} fileName="ScrapingJobs" ></JsonToExcel>
    </div>;

    const digestHeaderTemplate = (data) => {
        return (
            <React.Fragment>
                <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-warning"}>{data.user_email}</span>
                <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-success"}>{data.campaign_name}</span>
                <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-danger"}>{data.total_failure_count}</span>
            </React.Fragment>
        );
    }

    const newWebsitesHeaderTemplate = (data) => {
        return (
            <React.Fragment>
                <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-warning"}>{data.user_email}</span>
                <span style={{textAlign: 'center', marginRight:"5px"}} className={"p-tag p-tag-danger"}>{calculateNewWebsitesTotal(data.user_email)}</span>
            </React.Fragment>
        );
    }

    const digestFooterTemplate = (data) => {
        return ""
    }

    const hostNameTemplate = (data) =>{
        return getLinkFromUrl(data.hostname);
    };

    const calculateNewWebsitesTotal = (user_email) => {
        let total = 0;

        if (newWebsites) {
            for (let newWebsite of newWebsites) {
                if (newWebsite.user_email === user_email) {
                    total++;
                }
            }
        }

        return total;
    }

    return (
        <div className="p-grid card">



            <div className="p-col-12">
                <div className="p-col-12" hidden={showDetail}>
                    <div className="p-col-12">
                        <Panel header={t('product.filter')} toggleable={true}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6 p-lg-2">
                            <span className="p-float-label">
                                <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={filters.dates} onChange={onChange} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em'}}/>
                                <label htmlFor="dates">{t('actions.dates')}</label>
                            </span>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-2">
                            <span className="p-float-label">
                                <Dropdown id="searchState" name="searchState" value={filters.searchState} options={scraping_request_states(t)}
                                          onChange={onChange}
                                          style={{width:'100%'}}/>
                                <label htmlFor="state">{t('scraping_request.state')}</label>
                            </span>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-2">
                                <Button id="search" label={t('actions.search')} icon="pi pi-search" className="p-button-danger" onClick={search} style={{marginTop:'0.5em', marginRight:'0.5em'}}/>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-2">
                                <Button id="digest" label={t('actions.digest')} icon="pi pi-search" className="p-button-warning" onClick={listFailedRequestsDigest} style={{marginTop:'0.5em'}}/>
                            </div>

                            <div className="p-col-12 p-md-6 p-lg-2">
                                <Button id="digest" label={t('actions.new_websites')} icon="pi pi-search" onClick={listNewWebsites} style={{marginTop:'0.5em'}}/>
                            </div>

                        </div>
                    </Panel>
                    </div>

                    <div className="p-col-12">
                        {showRequests && (loading
                            ?
                            <div className="p-grid p-col-12" style={{textAlign:'center'}}>
                                <Loading/>
                            </div>
                            :
                            <DataTable ref={dtJobs} value={scrapingRequests}
                                       responsive={true}
                                       paginator={true}
                                       rows={20}
                                       dataKey="id"
                                       selection={selectedRequests}
                                       onSelectionChange={(e) => {setSelectedRequests(e.value);}}>
                                <Column selectionMode="multiple"  style={{width:'8em'}}/>
                                <Column field="user_email" header={t('user.email')} filter={true} filterMatchMode="contains"/>
                                <Column field="user_full_name" header={t('user.name')} filter={true} filterMatchMode="contains"/>
                                <Column field="url" body={allUrlsTemplate} header={t('scraping_request.urls')} sortable={true}/>
                                <Column field="type" header={t('scraping_request.type')} filter={true} filterElement={typeFilter} style={{width:"8em"}}/>
                                <Column field="is_rerun" body={IsReRunTemplate} header={t('scraping_request.is_rerun')} filter={true} filterElement={is_rerunFilter} style={{width:"8em"}}/>
                                <Column field="state" header={t('scraping_request.state')} filter={true} filterElement={stateFilter} style={{width:"8em"}}/>
                                <Column field="created_at" body={createdAtTemplate} header={t('scraping_request.created_at')} />
                                <Column body={actionTemplate} header={actionsHeader} style={{textAlign:'center', width: '11em'}}/>
                            </DataTable>)
                        }
                        {showDigest && (loading
                            ?
                            <div className="p-grid p-col-12" style={{textAlign:'center'}}>
                                <Loading/>
                            </div>
                            :
                            <DataTable value={failedRequestsDigest}
                                       rowGroupMode="subheader" groupField="id"
                                       rowGroupHeaderTemplate={digestHeaderTemplate}
                                       rowGroupFooterTemplate={digestFooterTemplate}
                                       dataKey="id">
                                <Column field="hostname" header={t('scraping_request.hostname')} body={hostNameTemplate}/>
                                <Column field="exception" header={t('scraping_request.exception')}/>
                                <Column field="count" header={t('scraping_request.error_count')}/>
                            </DataTable>)
                        }
                        {showNewWebsites && (loading
                            ?
                            <div className="p-grid p-col-12" style={{textAlign:'center'}}>
                                <Loading/>
                            </div>
                            :
                            <DataTable value={newWebsites}
                                       rowGroupMode="subheader" groupField="user_email"
                                       rowGroupHeaderTemplate={newWebsitesHeaderTemplate}
                                       rowGroupFooterTemplate={digestFooterTemplate}
                                       rows={100}
                                       dataKey="user_email">
                                <Column field="hostname" header={t('scraping_request.hostname')} body={hostNameTemplate}/>
                            </DataTable>)
                        }
                    </div>
                </div>

                {rowData &&
                <div className="card card-w-title" hidden={!showDetail}>

                    {detailLoading
                    ?
                    <div className="p-grid p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div>
                    :
                        <GlobalHotKeys keyMap={keyMap} handlers={rowData !== undefined && rowData !== null ? handlers : null} >
                            <div className="p-grid">

                                <div className="p-col-12" style={{textAlign: 'right'}}>
                                    <Button icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" label={t('actions.back')} style={{marginRight: '.5em'}} onClick={back}/>
                                    <Button tooltip={t('actions.previous') + " " + t('scraping_request.job_detail')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left"
                                            className="p-button-outlined p-button-warning" onClick={previous} style={{marginRight:'0.5em'}} />
                                    <Button tooltip={t('actions.next') + " " + t('scraping_request.job_detail')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right"
                                            className="p-button-outlined p-button-warning" onClick={next} style={{marginRight:'0.5em'}} />

                                    <Button icon="pi pi-refresh" className="p-button-outlined p-button-success" label={t('actions.rerun')} style={{marginRight: '.5em'}} onClick={() => rerun(rowData)}/>
                                    <Button icon="pi pi-trash" className="p-button-outlined p-button-danger" label={t('actions.delete')} style={{marginRight: '.5em'}} onClick={() => {
                                        setShowModal(true);
                                        setIsDeleteAll(false);
                                    }}/>
                                </div>

                                <div className="p-grid p-fluid p-col-12">
                                    <div className="p-col-12">
                                        <h5>{t('scraping_request.job_detail')}</h5>
                                    </div>

                                    <div className="p-grid p-col-6">
                                        <div className="p-grid p-col-12">{idTemplate()}</div>
                                        <div className="p-grid p-col-12">{typeTemplate()}</div>
                                        <div className="p-grid p-col-12">{emailTemplate()}</div>
                                        <div className="p-grid p-col-12">{userFullNameTemplate()}</div>
                                        <div className="p-grid p-col-12">{startedAtTemplate()}</div>
                                        <div className="p-grid p-col-12">{completedAtTemplate()}</div>
                                        <div className="p-grid p-col-12">{productTemplate()}</div>
                                        <div className="p-grid p-col-12">{urlTemplate()}</div>
                                        <div className="p-grid p-col-12">{competitorUrlTemplate()}</div>
                                        <div className="p-grid p-col-12">{urlsTemplate()}</div>
                                    </div>

                                    <div className="p-grid p-col-6">
                                        <div className="p-grid p-col-12">{failureDigestTemplate()}</div>
                                    </div>
                                    <div className="p-grid p-col-12">{failureTemplate()}</div>
                                    <div className="p-grid p-col-12">{ignoredTemplate()}</div>
                                </div>

                            </div>
                        </GlobalHotKeys>}

                </div>
                }

                <Dialog header={t('dialogs.confirm_scrape_delete')}  footer={modalYesNo} visible={showModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setShowModal(false);}}>
                    {t('dialogs.confirm_scrape_delete_msg1')}
                    <br/>
                    {t('dialogs.confirm_scrape_delete_msg2')}
                </Dialog>

            </div>
        </div>

    );
};
export default ScrapingJobs;
