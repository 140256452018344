import React, {useState} from "react";
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {confirmDialog} from "primereact/confirmdialog";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {toDateTimeString} from "../../utils/TimeUtil";
import {ProductService} from "../../service/ProductService";
import {getKeyValue, user} from "../../utils/Utils";
import {IndexTemplate} from "../../utils/InlineComponents";

const CompetitorViolationAction = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const [action, setAction] = useState(null);

    const types = [
        {value:"call", label:t('price_violation.action.call')},
        {value:"email", label:t('price_violation.action.email')},
        {value:"ignore", label:t('price_violation.action.ignore')},
        {value:'on_hold', label:t('price_violation.action.on_hold')},
        {value:'vero', label:t('price_violation.action.vero')},
        {value:'warranty', label:t('price_violation.action.warranty')},
        {value:'other', label:t('price_violation.action.other')}
    ];

    const productService = new ProductService();

    const deleteAction = () =>{};
    const saveAction = () =>{
        let added_action = {...action, created_at: Date.now(), sender: user().first_name + ' ' + user().last_name};
        productService.addCompetitorViolationAction(props.selectedProduct.id, props.selectedCompetitor.product_url, added_action).then(() => {

            if (!props.selectedCompetitor.violation_actions){
                props.selectedCompetitor.violation_actions = [];
            }

            props.selectedCompetitor.violation_actions.push(added_action);
            let comp = props.selectedProduct.competitors[props.selectedProduct.competitors.findIndex(c => c.product_url === props.selectedCompetitor.product_url)];
            comp.violation_actions = props.selectedCompetitor.violation_actions;

            setAction(null);
            toast.current.show({severity: 'success', summary: t('actions.add'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    };

    const onChange = (e) => {

        const { key, value } = getKeyValue(e);

        setAction({...action, [key] : value});
    };

    const createdAtTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{t('common.created_at')}</span>
            {toDateTimeString(rowData.created_at)}
        </div>;
    };

    const typeTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{t('price_violation.action.type')}</span>
            {types.find(t=> t.value === rowData.type).label}
        </div>;
    };

    const noteTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{t('price_violation.action.note')}</span>
            {rowData.note}
        </div>;
    };
    const actionTemplate = (rowData) =>{
        return <div className="p-text-right">
            <Button icon="pi pi-pencil" data-tour="step12" className="p-button-outlined p-button-rounded p-button-success p-ml-1 p-mr-1" tooltipOptions={{position: 'bottom'}} tooltip={t('actions.edit')} onClick={() => {setAction(rowData)}}/>
            <Button icon="pi pi-trash" className="p-button-outlined p-button-rounded p-button-danger" tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => {
                confirmDialog({
                    message: t('dialogs.competitor.delete_msg'),
                    header: t('dialogs.competitor.delete'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptClassName: 'p-button-danger',
                    accept: () => deleteAction(rowData),
                    acceptLabel: t('yes_no.yes'),
                    rejectLabel: t('yes_no.no')
                });
            }}/>
        </div>
    };

    return <div className="p-grid p-fluid alignCenter">
        <div className="p-col-12">
            <span className="p-float-label">
                <Dropdown id="type" name="type" value={action?.type} options={types} onChange={onChange} />
                <label htmlFor="type">{t('price_violation.action.type')}</label>
            </span>
        </div>
        <div className="p-col-12">
            <span className="p-float-label">
                <InputTextarea id="note" name="note" value={action?.note ?? ""} autoComplete="off" onChange={onChange} rows={5} cols={30}/>
                <label htmlFor="note">{t('price_violation.action.note')}</label>
            </span>
        </div>

        <div className="p-col-6">
            <Button label={t('actions.clear')} icon="pi pi-save" className="p-button-outlined p-button-warning" onClick={() => {setAction(null);}}/>
        </div>
        <div className="p-col-6">
            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={saveAction}/>
        </div>
        <div className="p-col-12">
            <DataTable value={props.selectedCompetitor.violation_actions} >
                <Column body={IndexTemplate} headerStyle={{width:'3em'}} />
                <Column field="type" body={typeTemplate} header={t('price_violation.action.type')} />
                <Column field="note" body={noteTemplate} header={t('price_violation.action.note')} />
                <Column field="created_at" body={createdAtTemplate} header={t('common.created_at')} />
                <Column field="sender" header={t('user.name')} />
                {/*<Column header={t('table_headers.action')} body={actionTemplate}/>*/}
            </DataTable>
        </div>

    </div>;
};
export default CompetitorViolationAction;