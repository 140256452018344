import React, {useEffect, useState} from "react";

import {AppContext} from "../../HomePage";
import {Panel} from "primereact/panel";
import {DashboardService} from "../../service/DashboardService";
import {Loading} from "../../utils/InlineComponents";

import {CampaignContext} from "../../App";

const OverviewDistinctUrl = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [loading, setLoading] = useState(false);

    const [trackedWebsitesOverview, setTrackedWebsitesOverview] = useState(null);
    const [websites, setWebsites] = useState([]);

    const dashboardService = new DashboardService();

    useEffect ( ()=>{
        search();
    },[selectedCampaign]);

    const search = () => {
        setLoading(true);

        dashboardService.getTrackedDistinctWebsitesOverview(selectedCampaign.id).then(overview=>{
            setTrackedWebsitesOverview(overview);
            setWebsites(overview.websites.map((website, index) => {
                return (
                    <li>
                        <button className="p-link">
                            <span className="count blue">{website.total} - %{website.rate}</span>
                            <span className="title">{website.name}</span>
                        </button>
                    </li>
                );
            }))
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    }

    return <React.Fragment>

        {loading ?
            <div className="p-col-12" style={{textAlign:'center'}}>
                <Loading/>
            </div> :
            trackedWebsitesOverview && <div className="p-grid p-fluid dashboard card">
                <div className="p-col-12 p-lg-6 contacts fixed_list">
                    <Panel header={t('overview.websites')} style={{height:'100%'}}>
                        <ul className="activity-list">{websites}</ul>
                    </Panel>
                </div>
            </div>}
    </React.Fragment>;
};
export default OverviewDistinctUrl;
