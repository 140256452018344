import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {UserService} from "../../service/UserService";
import {getMonthsAgo, toDateString, toDateTimeString} from "../../utils/TimeUtil";
import {Calendar} from "primereact/calendar";
import {getKeyValue} from "../../utils/Utils";
import JsonToExcel from "../Export/JsonToExcel";

const SignInLogs = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const [dates, setDates] = useState([getMonthsAgo(1), new Date()]);
    const [expandedRows, setExpandedRows] = useState([]);

    const [items, setItems] = useState([]);

    const [loading, setLoading] = useState(false);

    const userService = new UserService();

    useEffect ( ()=>{
        search();
    },[dates[0], dates[1]]);

    const search = () => {
        if (dates[0] && dates[1])
        {
            setLoading(true);
            userService.getTokens(toDateString(dates[0]), toDateString(dates[1])).then(data=>{
                setItems(data);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const dateTemplate = (rowData) => <span>{toDateTimeString(rowData.created)}</span>;

    const emailTemplate = (rowData) => {
        return rowData.user_email;
    };

    return <React.Fragment>
         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <div className="p-grid card">
                 <div className="p-col-12">
                    <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={dates} onChange={(e) => setDates(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} className="p-mr-1"/>
                    {/*<Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />*/}

                    <JsonToExcel data={items ? items.map(i => { return {"email": i.user_email, "login_time": i.created}}) : null} fileName="SignInLogs" ></JsonToExcel>
                 </div>
                 <div className="p-col-12">
                     <DataTable value={items} emptyMessage={t('actions.empty_message')}
                                expandableRowGroups
                                expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                                rowGroupHeaderTemplate={emailTemplate} rowGroupFooterTemplate={()=>{return ""}}
                                rowGroupMode="subheader" sortField="user_email" sortOrder={1} groupField="user_email"
                                paginator={true} rows={100} >
                         <Column field="user_email" header={t('user.email')} />
                         <Column field="created" body={dateTemplate} header={t('user.login_time')} />
                     </DataTable>
                 </div>
             </div>
             }
    </React.Fragment>;
};
export default SignInLogs;
