import 'react-app-polyfill/ie11';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import HomePage from './HomePage';
import { HashRouter } from 'react-router-dom'

//import * as serviceWorker from './serviceWorker';

// loading component for suspense fallback
const Loader = () => (
    <div className="splash-screen">
        <div className="splash-container">
            <div className="splash-double-bounce1"></div>
            <div className="splash-double-bounce2"></div>
        </div>
    </div>
);

ReactDOM.render(
    <HashRouter>
        <Suspense fallback={<Loader />}>
            <HomePage />
        </Suspense>
    </HashRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
