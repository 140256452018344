import {AuthService} from "./AuthService";
import moment from "moment";

export class ProductService {
    url = `products/`;
    fields_url = `fields/`;

    getProducts(category, brand, is_active, name, campaign_id, start_index) {

        return AuthService.apiGet(this.url, {
            params: {
                product_id: '',
                category: category,
                brand: brand,
                is_active: is_active,
                name: name,
                campaign_id: campaign_id,
                start_index: start_index}
        }).then(res => res.data.products);
    }

    getProduct(product_id) {
        return AuthService.apiGet(this.url + product_id + "/").then(res => res.data.product).catch(error=> {throw error});
    }

    getCompetitors(product_id, history_start_date, history_end_date){
        return AuthService.apiGet(this.url + product_id + "/competitors/", {
            params: {
                history_start_date: moment(history_start_date).startOf('day').toDate(),
                history_end_date: moment(history_end_date).endOf('day').toDate()}
        }).then(res => res.data.competitors).catch(error=> {throw error});
    }


    addProduct(product) {
        return AuthService.apiPost(this.url, {"product": product}).then(res => res.data.product_created);
    }

    updateProduct(id, product) {
        return AuthService.apiPut(this.url + id + "/", product).then(res => res.data.product_updated);
    }

    bulkUpdateProducts(data) {
        return AuthService.apiPut(this.url + "update_bulk/", data).then(res => res.data.message);
    }

    deleteProduct(product) {
        return AuthService.apiDelete(this.url + product.id).then(res => res.data.message);
    }

    updateProductIsActive(id, is_active){
        if (is_active)
            return AuthService.apiPut(this.url + id+'/deactivate/').then(res => res.data.message);
        else
            return AuthService.apiPut(this.url + id+'/activate/').then(res => res.data.message);
    }

    updateAllProductsIsActive(data, is_active){
        if (is_active)
            return AuthService.apiPut(this.url + 'deactivate_all/', data).then(res => res.data.message);
        else
            return AuthService.apiPut(this.url + 'activate_all/', data).then(res => res.data.message);
    }

    removeAllProducts(data){
        return AuthService.apiPut(this.url + 'remove_all/', data).then(res => res.data.message);
    }

    getCategories(campaign_id)
    {
        return AuthService.apiGet(this.fields_url, {
            params: {field_name: 'category', campaign_id: campaign_id
            }}).then(res => res.data.categorylist);
    }
    getBrands(campaign_id)
    {
        return AuthService.apiGet(this.fields_url, {
            params: {field_name: 'brand', campaign_id: campaign_id
            }}).then(res => res.data.brandlist);
    }

    addCompetitor(id, competitors){
        return AuthService.apiPost(this.url + id + "/competitors/add/", {"competitors": competitors}).then(res => res.data.data);
    }

    addCompetitorFromSuggestions(id, competitors){
        return AuthService.apiPost(this.url + id + "/competitors/add_all_from_suggestions/", {"competitors": competitors}).then(res => res.data.data);
    }

    deleteCompetitor(id, product_urls){
        return AuthService.apiPost(this.url + id + "/competitors/delete/", {"product_urls": product_urls}).then(res => res.data.message);
    }

    updateCompetitorIsActive(id, product_urls, is_active){
        if (is_active)
            return AuthService.apiPut(this.url + id + '/competitors/deactivate/', {product_urls: product_urls}).then(res => res.data.message);
        else
            return AuthService.apiPut(this.url + id + '/competitors/activate/',{product_urls: product_urls}).then(res => res.data.message);
    }

    updateCompetitorIsDynamicPricingActive(id, product_urls, is_dynamic_pricing_active){
        if (is_dynamic_pricing_active)
            return AuthService.apiPut(this.url + id + '/competitors/deactivate_dynamic_pricing/', {product_urls: product_urls}).then(res => res.data.message);
        else
            return AuthService.apiPut(this.url + id + '/competitors/activate_dynamic_pricing/',{product_urls: product_urls}).then(res => res.data.message);
    }

    searchCompetitors(id){
        return AuthService.apiPost(this.url + id + "/competitors/search/", "").then(res => res.data.scraping_request);
    }

    ignoreCompetitor(id, competitor_suggestions){
        return AuthService.apiPut(this.url + id + "/competitor_suggestions/not_match/", {"competitor_suggestions": competitor_suggestions}).then(res => res.data.message);
    }

    savePricingStrategy(id, pricing_strategy){

        pricing_strategy.min_value = parseFloat(pricing_strategy.min_value);
        pricing_strategy.reduction_value = parseFloat(pricing_strategy.reduction_value);

        return AuthService.apiPut(this.url + id + "/pricing_strategy/", {"pricing_strategy" : pricing_strategy}).then(res => res.data);
    }

    applyPriceSuggestion(id)
    {
        return AuthService.apiPut(this.url + id + "/pricing_suggestion/", "").then(res => res.data.message);
    }

    getAppliedPriceSuggestions(product_id, start_date, end_date)
    {
        return AuthService.apiGet(this.url + product_id + "/applied_pricing_suggestions/",{
            params: {
                start_date: moment(start_date).startOf('day').toDate(),
                end_date: moment(end_date).endOf('day').toDate()}
        }).then(res => res.data.data);
    }

    savePriceViolation(id, violation_strategy)
    {
        violation_strategy.min_value = parseFloat(violation_strategy.min_value);
        violation_strategy.max_value = parseFloat(violation_strategy.max_value);
        return AuthService.apiPut(this.url + id + "/violation_strategy/", {"violation_strategy" : violation_strategy}).then(res => res.data.data);
    }

    autoMatchProductSuggestions(id, match_method, min_match_score)
    {
        return AuthService.apiPut(this.url + id + "/competitors/match/", {"match_method":match_method, "min_match_score":min_match_score}).then(res => res.data.message);
    }

    autoMatchAllSuggestions(campaign_id, match_method, min_match_score)
    {
        return AuthService.apiPut(this.url + "competitors/match/", {"campaign_id": campaign_id, "match_method":match_method, "min_match_score":min_match_score}).then(res => res.data.message);
    }

    moveProducts(data)
    {
        return AuthService.apiPut(this.url + "change_campaign/", data).then(res => res.data.message);
    }

    matchCompetitorVariant(id, competitor_url, variant){
        return AuthService.apiPut(this.url + id + "/competitors/match_variant/", {"variant": variant,"competitor_url":competitor_url}).then(res => res.data.data);
    }

    updateCompetitorFields(id, data){
        return AuthService.apiPut(this.url + id + "/competitors/update_fields/", data).then(res => res.data.data);
    }

    addCompetitorPrice(id, data){
        return AuthService.apiPost(this.url + id + "/competitors/add_price/", data).then(res => res.data.data);
    }

    deleteCompetitorPrice(id, data){
        return AuthService.apiPut(this.url + id + "/competitors/delete_price/", data).then(res => res.data.data);
    }

    addCompetitorViolationAction(id, competitor_url, violation_action){
        return AuthService.apiPut(this.url + id + "/competitors/add_violation_action/", {"violation_action": violation_action, "competitor_url": competitor_url}).then(res => res.data.data);
    }

    refreshImageUrls(campaign_id) {
        return AuthService.apiPut(this.url + "refresh_image_urls/", {"campaign_id": campaign_id}).then(res => res.data.data);
    }

    refreshProductNames(campaign_id) {
        return AuthService.apiPut(this.url + "refresh_product_names/", {"campaign_id": campaign_id}).then(res => res.data.data);
    }

    mergeProducts(productsToMerge) {
        let main_product_id =  productsToMerge.filter(p=> p.keepAsMine).map(p=>p.id)[0];
        let add_as_competitor_ids =  productsToMerge.filter(p=> p.keepAsMine === false).map(p=>p.id);
        return AuthService.apiPut(this.url + "merge_products/", {"main_product_id": main_product_id, "add_as_competitor_ids": add_as_competitor_ids}).then(res => res.data.data);
    }
}
