import React, {useEffect, useState} from 'react';
import JobSchedulingGenericSetting from "./JobSchedulingGenericSetting";
import {SelectButton} from "primereact/selectbutton";
import JobSchedulingWebSearchSetting from "./JobSchedulingWebSearchSetting";
import JobSchedulingSiteSearchSetting from "./JobSchedulingSiteSearchSetting";
import JobSchedulingNewCompetitorWebSearchSetting from "./JobSchedulingNewCompetitorWebSearchSetting";
import {AppContext} from "../../HomePage";
import JobSchedulingDailyWebSearchSetting from "./JobSchedulingDailyWebSearchSetting";
import SpecialFieldSetting from "./SpecialFieldSetting";
import CleanUp from "./CleanUp";
import UserOperation from "./UserOperation";
import InstantWebSearchSetting from "./InstantWebSearchSetting";



const JobSchedulingSetting = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const campaign = props.campaign;
    const user = props.user;
    const [selectedTabView, setSelectedTabView] = useState('generic_setting');
    const [tabViews, setTabViews] = useState([]);

    useEffect ( ()=>{

        let tabViews =  [
            {label: t('campaign.generic_setting'), value: 'generic_setting'}
        ];

        if (!campaign.id) {
            tabViews.push({label: t('campaign.instant_web_search_setting'), value: 'instant_web_search_setting'})
        }

        tabViews.push({label: t('campaign.web_search_setting'), value: 'web_search_setting'})
        tabViews.push({label: t('campaign.google_shopping_search_setting'), value: 'google_shopping_search_setting'})
        tabViews.push({label: t('campaign.daily_web_search_setting'), value: 'daily_web_search_setting'})
        tabViews.push({label: t('campaign.daily_google_shopping_search_setting'), value: 'daily_google_shopping_search_setting'})
        tabViews.push({label: t('campaign.site_search_setting'), value: 'site_search_setting'})
        tabViews.push({label: t('campaign.new_competitor_web_search_setting'), value: 'new_competitor_web_search_setting'})
        tabViews.push({label: t('campaign.special_field_setting'), value: 'special_field_setting'})

        if (campaign.id) {
            tabViews.push({label: t('clean_up.clean_up'), value: 'clean_up'})
        } else {
            tabViews.push({label: t('actions.operations'), value: 'operations'})
        }

        setTabViews(tabViews)
    }, []);

    return (
        <div className="p-col-12">
            <SelectButton name="tab_view" value={selectedTabView} options={tabViews} onChange={(e) => { setSelectedTabView(e.target.value);}} />
            <p></p>
            {selectedTabView === 'generic_setting' && <JobSchedulingGenericSetting campaign={campaign}/>}
            {selectedTabView === 'instant_web_search_setting' && <InstantWebSearchSetting campaign={campaign}/>}
            {selectedTabView === 'web_search_setting' && <JobSchedulingWebSearchSetting campaign={campaign} isGoogleShoppingSearch={false}/>}
            {selectedTabView === 'google_shopping_search_setting' && <JobSchedulingWebSearchSetting campaign={campaign} isGoogleShoppingSearch={true}/>}
            {selectedTabView === 'daily_web_search_setting' && <JobSchedulingDailyWebSearchSetting campaign={campaign} isGoogleShoppingSearch={false}/>}
            {selectedTabView === 'daily_google_shopping_search_setting' && <JobSchedulingDailyWebSearchSetting campaign={campaign} isGoogleShoppingSearch={true}/>}
            {selectedTabView === 'site_search_setting' && <JobSchedulingSiteSearchSetting campaign={campaign}/>}
            {selectedTabView === 'new_competitor_web_search_setting' && <JobSchedulingNewCompetitorWebSearchSetting campaign={campaign} isGoogleShoppingSearch={false}/>}
            {selectedTabView === 'special_field_setting' && <SpecialFieldSetting campaign={campaign}/>}
            {campaign.id && selectedTabView === 'clean_up' && <CleanUp campaign={campaign}/>}
            {!campaign.id && selectedTabView === 'operations' && <UserOperation user={user}/>}

        </div>
    );

};
export default (JobSchedulingSetting)
