import React, {useEffect, useRef, useState} from "react";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";

import {
    formatCurrency,
    getHostName,
    getLastPrice,
    getProductDetailLink,
    isCampaignManufacturer,
    isCampaignMarketSearch,
    toTitleCase
} from "../../utils/Utils";
import ScrapeStatus from "./ScrapeStatus";
import {OverlayPanel} from "primereact/overlaypanel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {AppContext} from "../../HomePage";
import CopyToClipboard from "../../utils/CopyToClipboard";
import {positionTemplate, StockTemplate, Tooltip} from "../../utils/InlineComponents";
import {Dialog} from "primereact/dialog";
import {Rating} from "primereact/rating";
import {Tag} from "primereact/tag";
import {CampaignContext} from "../../App";
import {toDateString} from "../../utils/TimeUtil";

const ProductBrief = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const {campaigns} = React.useContext(CampaignContext);

    const op = useRef(null);
    const op2 = useRef(null);

    let selectedProduct = {...props.selectedProduct};
    let selectedCampaign = {...campaigns.find(c=> c.id===props.selectedProduct.campaign_id)};

    let [dropShippingCompetitor, setDropShippingCompetitor] = useState(null);
    let myProduct = {...props.selectedProduct.competitors.find(competitor => competitor.is_mine || competitor.is_map)};
    const [details, setDetails] = useState({variantList: null, sku: null, mpn: null, ean: null, upc: null, asin: null, barcode: null, ref_id: null, otherCodes: null, crossCodes: null, bestSellersRank: null, tags: null});
    const [showCrossCodes, setShowCrossCodes] = useState(false);
    const [codeList, setCodeList] = useState([]);
    const [rating, setRating] = useState(null);
    const [ratingCount, setRatingCount] = useState(null);
    const [suggestion_direction, set_suggestion_direction] = useState("");
    const [difference, setDifference] = useState("");

    useEffect(() => {
        SetProperties();
    }, [])

    useEffect ( ()=>{
        for (let comp of props.selectedProduct.competitors) {
            const lastPrice = getLastPrice(comp.prices);
            if (lastPrice && lastPrice.rating) {
                setRating(lastPrice.rating);
                setRatingCount(lastPrice.rating_count);
                break;
            }
        }
        selectedProduct = {...props.selectedProduct};
        selectedCampaign = {...campaigns.find(c=> c.id===props.selectedProduct.campaign_id)};

        myProduct = {...props.selectedProduct.competitors.find(competitor => competitor.is_mine)};
        let dropShippingCompetitors = selectedProduct.competitors.filter(competitor => competitor.is_drop_shipping);
        if (dropShippingCompetitors.length > 0) {
            dropShippingCompetitor = dropShippingCompetitors[0]
            setDropShippingCompetitor(dropShippingCompetitor)
        }
        SetProperties();
    },[props.selectedProduct]);

    const SetProperties = () => {
        let variantList = null, sku = null, mpn = null, ean = null, upc = null, asin = null, barcode = null, ref_id = null, otherCodes = null, bestSellersRank=null, tags=null;

        if (selectedProduct.properties !== undefined && selectedProduct.properties !== null) {
            variantList = selectedProduct.properties.map(property => {
                if (property.name.toLowerCase() !== "brand" && property.name.toLowerCase() !== "category" && property.name.toLowerCase() !== "description")
                    return <React.Fragment key={property.name}><div className="p-col-4"><b>{t("product.properties." + property.name.toLowerCase()).replace('product.properties.', '')}</b></div><div className="p-col-8">{property.value}</div></React.Fragment>;
            });
        }


        let listCodes = [];

        if (selectedProduct.competitor_suggestions && selectedProduct.competitor_suggestions.length>0) {
            listCodes = listCodes.concat([... new Set(selectedProduct.competitor_suggestions.filter(s => s.is_match_decision_made && s.is_matched && s.sku && s.sku.trim() !== "").map(s => {
                return {type:"sku",site: getHostName(s.product_url), code: s.sku};
            }))]);

            listCodes = listCodes.concat([... new Set(selectedProduct.competitor_suggestions.filter(s => s.is_match_decision_made && s.is_matched && s.barcode && s.barcode.trim() !== "").map(s => {
                return {type:"barcode",site: getHostName(s.product_url), code: s.barcode};
            }))]);
            listCodes = listCodes.concat([... new Set(selectedProduct.competitor_suggestions.filter(s => s.is_match_decision_made && s.is_matched && s.mpn && s.mpn.trim() !== "").map(s => {
                return {type:"mpn", site: getHostName(s.product_url), code: s.mpn};
            }))]);
            listCodes = listCodes.concat([... new Set(selectedProduct.competitor_suggestions.filter(s => s.is_match_decision_made && s.is_matched && s.ean && s.ean.trim() !== "").map(s => {
                return {type:"ean", site: getHostName(s.product_url), code: s.ean};
            }))]);
            listCodes = listCodes.concat([... new Set(selectedProduct.competitor_suggestions.filter(s => s.is_match_decision_made && s.is_matched && s.upc && s.upc.trim() !== "").map(s => {
                return {type:"upc", site: getHostName(s.product_url), code: s.upc};
            }))]);

            listCodes = listCodes.concat([... new Set(selectedProduct.competitor_suggestions.filter(s => s.is_match_decision_made && s.is_matched && s.asin && s.asin.trim() !== "").map(s => {
                return {type:"asin", site: getHostName(s.product_url), code: s.asin};
            }))]);
        }

        setCodeList([... new Set(listCodes)]);


        let crossCodes = "";
        if (listCodes.length !== 0)
        {
            crossCodes = <React.Fragment>
                <div className="p-col-4">
                    <b>{t('product.cross_codes')}</b>
                </div>
                <div className="p-col-8 p-grid p-nogutter p-align-start">
                    <Button icon="pi pi-sitemap" className="imageButton p-justify-right" style={{color:'maroon'}} onClick={() => {setShowCrossCodes(true)}} />
                </div>
            </React.Fragment>
        }

        if (selectedProduct.sku)
        {
            let crossSkus = "";
            if (selectedProduct.cross_skus.length>0)
                crossSkus = <OverlayPanel ref={op} style={{width:'25em'}} dismissable={true}>
                    <DataTable header={t('product.cross_skus')} value={selectedProduct.cross_skus} responsive={true}>
                        <Column field="site" header={t('table_headers.site')}/>
                        <Column field="sku" header={t('product.sku')}/>
                    </DataTable>
                </OverlayPanel>;
            sku = <React.Fragment>
                <div className="p-col-4"><b>{t('product.sku')}</b></div>
                <div className="p-col-8 p-grid p-nogutter p-align-start">
                    {selectedProduct.sku}
                    <CopyToClipboard textToCopy={selectedProduct.sku} />
                    {crossSkus ? <Button icon="pi pi-sitemap" className="imageButton p-justify-right" style={{color:'maroon'}} onMouseLeave={(e) => op.current.hide(e)} onMouseOver={(e) => op.current.show(e)}/> : "" }
                </div>
                {crossSkus}
            </React.Fragment>;
        }
        if (selectedProduct.other_codes && selectedProduct.other_codes.length>0)
        {
            let otherCodesOptions = selectedProduct.other_codes.map(item => ({url: item}))
            otherCodes = <React.Fragment>
                <div className="p-col-4"><b>{t('product.codes')}</b></div>
                <div className="p-col-8 p-grid p-nogutter p-align-start">
                    <Button icon="pi pi-sitemap" className="imageButton p-justify-right" style={{color:'maroon'}} onClick={(e) => op2.current.show(e)}/>
                </div>
                <OverlayPanel ref={op2} style={{width:'12em'}} dismissable={true}>
                    <DataTable value={otherCodesOptions} responsive={true}>
                        <Column field="url" header={t('product.codes')}/>
                    </DataTable>
                </OverlayPanel>
            </React.Fragment>;
        }
        if (selectedProduct.ref_id)
            ref_id = <React.Fragment><div className="p-col-4"><b>{t('product.ref_id')}</b></div><div className="p-col-8">{selectedProduct.ref_id}<CopyToClipboard textToCopy={selectedProduct.ref_id} /></div></React.Fragment>;
        if (selectedProduct.barcode)
            barcode = <React.Fragment><div className="p-col-4"><b>{t('product.barcode')}</b></div><div className="p-col-8">{selectedProduct.barcode}<CopyToClipboard textToCopy={selectedProduct.barcode} /></div></React.Fragment>;
        if (selectedProduct.mpn)
            mpn = <React.Fragment><div className="p-col-4"><b>{t('product.mpn')}</b></div><div className="p-col-8">{selectedProduct.mpn}<CopyToClipboard textToCopy={selectedProduct.mpn}/></div></React.Fragment>;
        if (selectedProduct.ean)
            ean = <React.Fragment><div className="p-col-4"><b>{t('product.ean')}</b></div><div className="p-col-8">{selectedProduct.ean}<CopyToClipboard textToCopy={selectedProduct.ean} /></div></React.Fragment>;
        if (selectedProduct.upc)
            upc = <React.Fragment><div className="p-col-4"><b>{t('product.upc')}</b></div><div className="p-col-8">{selectedProduct.upc}<CopyToClipboard textToCopy={selectedProduct.upc} /></div></React.Fragment>;
        if (selectedProduct.asin)
            asin = <React.Fragment><div className="p-col-4"><b>{t('product.asin')}</b></div><div className="p-col-8">{selectedProduct.asin}<CopyToClipboard textToCopy={selectedProduct.asin} /></div></React.Fragment>;
        if (dropShippingCompetitor && dropShippingCompetitor.prices.length > 0 && dropShippingCompetitor.prices[0].best_sellers_rank)
            bestSellersRank = <React.Fragment><div className="p-col-4"><b>{t('product.drop_shipping_best_sellers_rank')}</b></div><div className="p-col-8">{dropShippingCompetitor.prices[0].best_sellers_rank}</div></React.Fragment>;

        if (selectedProduct.tags)
            tags = <React.Fragment><div className="p-col-4"><b>{t('product.tags')}</b></div><div className="p-col-8">
                    {selectedProduct.tags.map(t => {
                        return <Tag value={t} className="p-mr-1" rounded/>
                    })}
                </div></React.Fragment>;
        setDetails({variantList, sku, mpn, ean, upc, asin, barcode, ref_id, otherCodes, crossCodes, bestSellersRank, tags});

        if (myProduct.prices && myProduct.prices.length > 0)
        {
            selectedProduct["myPrice"] = myProduct.prices[myProduct.prices.length-1]['value'];
            selectedProduct["currency"] = myProduct.prices[myProduct.prices.length-1]['value'] === undefined ? undefined : myProduct.prices[myProduct.prices.length-1].currency;
        }

        if (selectedProduct.target_price) {
            if(selectedProduct.myPrice)
            {
                if (parseFloat(selectedProduct.myPrice) > parseFloat(selectedProduct.target_price))
                    set_suggestion_direction("down");
                else if (parseFloat(selectedProduct.myPrice) < parseFloat(selectedProduct.target_price))
                    set_suggestion_direction("up");

                setDifference(formatCurrency(Math.abs(parseFloat(selectedProduct.myPrice) - parseFloat(selectedProduct.target_price)), selectedProduct.currency));
            }
        }
    };

    const newRequest = {
        campaign_id: selectedProduct.campaign_id,
        product_id: selectedProduct.id,
        url: myProduct.product_url,
        type: 'prices_product_update'
    };

    const myLastPrice = getLastPrice(myProduct.prices);



    return <Panel header={t('product_detail.product_brief')} toggleable={true} style={{height:"100%"}} >
        <Dialog header={t('product.cross_codes')} visible={showCrossCodes} ref={op} style={{maxWidth: '50vw', minWidth: '20vw', maxHeight:"50vh", overflow: "scroll"}} modal={true} onHide={() => {setShowCrossCodes(false)}}>
            <DataTable value={codeList} responsive={true}>
                <Column field="type" header={t('common.type')}/>
                <Column field="site" header={t('table_headers.site')}/>
                <Column field="code" header={t('product.codes')}/>
            </DataTable>
        </Dialog>

        <div className="p-grid p-col-12"  style={{overflowWrap: 'break-word'}}>
            <div className="p-col-12" style={{textAlign:"center"}}>
                <a target="_blank" rel="noopener noreferrer" href={myProduct.product_url} title={selectedProduct.name}>
                    <img referrerPolicy='no-referrer' src={selectedProduct.image} alt="" className="img-fluid"  />
                </a>
            </div>

            <div className="p-col-12">
                <div className="p-grid">
                    <span style={{marginLeft:"5px"}}>{getProductDetailLink(selectedProduct.id, selectedProduct.name)}</span>
                    <CopyToClipboard textToCopy={selectedProduct.name} />
                </div>
            </div>
            {selectedProduct.created_at && <React.Fragment>
                <div className="p-col-4"><b>{t('product.created_at')}</b></div>
                <div className="p-col-8">{toDateString(selectedProduct.created_at)}</div>
            </React.Fragment>}
            {details.ref_id}
            {details.sku}
            {details.mpn}
            {details.ean}
            {details.upc}
            {details.asin}
            {details.barcode}
            {details.otherCodes}
            {details.crossCodes}
            {details.bestSellersRank}
            {details.tags}
            {selectedProduct.campaign && <React.Fragment>
                <div className="p-col-4"><b>{t('campaign.name')}</b></div>
                <div className="p-col-8">{selectedProduct.campaign.name}</div>
            </React.Fragment>}
            {selectedProduct.category && <React.Fragment>
                <div className="p-col-4"><b>{t('product.category')}</b></div>
                <div className="p-col-8">{selectedProduct.category}</div>
            </React.Fragment>}
            {selectedProduct.sub_category && <React.Fragment>
                <div className="p-col-4"><b>{t('product.sub_category')}</b></div>
                <div className="p-col-8">{selectedProduct.sub_category}</div>
            </React.Fragment>}
            {selectedProduct.type && <React.Fragment>
                <div className="p-col-4"><b>{t('product.type')}</b></div>
                <div className="p-col-8">{selectedProduct.type}</div>
            </React.Fragment>}
            {selectedProduct.brand && <React.Fragment>
                <div className="p-col-4"><b>{t('product.brand')}</b></div>
                <div className="p-col-8">{selectedProduct.brand}</div>
            </React.Fragment>}
            {details.variantList}
            {myLastPrice && <React.Fragment>
                <div className="p-col-4"><b>{t('product.price')}</b></div>
                <div className="p-col-8">{formatCurrency(myLastPrice.value, myLastPrice.currency)  }</div>
            </React.Fragment>}
            {!isCampaignManufacturer(selectedCampaign) && !isCampaignMarketSearch(selectedCampaign) && <>
                <div className="p-col-4"><b>{t('product.stock')}</b></div>
                <div className="p-col-8">
                    {StockTemplate(myLastPrice && myLastPrice.stock)}
                </div>
            </>
            }
            {!isCampaignManufacturer(selectedCampaign) && !isCampaignMarketSearch(selectedCampaign) && <>
                <div className="p-col-4"><b>{t('product.position')}</b></div>
                <div className="p-col-8">{selectedProduct.position ? positionTemplate(selectedProduct.position) : '-'}</div>
            </>
            }

            {selectedProduct.current_shelve_position !== null && <>
                <div className="p-col-4"><b>{t('product.shelve_position')}</b></div>
                <div className="p-col-8">{selectedProduct.current_shelve_position ? positionTemplate(selectedProduct.current_shelve_position) : '-'}</div>
            </>
            }

            {rating && <React.Fragment>
                <div className="p-col-4"><b>{t('product_detail.review_rate')}</b></div>
                <div className="p-col-8"><Rating value={parseInt(rating)} tooltip={rating} tooltipOptions={{position: 'bottom'}} readonly cancel={false} /></div>

                <div className="p-col-4"><b>{t('product_detail.ratings')}</b></div>
                <div className="p-col-8" style={{textAlign: "left"}}><span style={{maxWidth: "7em", minWidth: "3em", textAlign: 'center'}} className={"p-tag p-tag-info"}>{ratingCount}</span></div>

            </React.Fragment>}

            {selectedProduct.suggested_price !== null && selectedProduct.suggested_price !== undefined ?
                <React.Fragment>


                    <div className="p-col-4 p-text-bold">{t('reprice.target_price')}</div>
                    <div className="p-col-8">
                        <label className={suggestion_direction === "down" ? "p-tag p-tag-danger" : (suggestion_direction === "up" ? "p-tag p-tag-success" : "")}>
                            {formatCurrency(selectedProduct.target_price, selectedProduct.currency)}
                            &nbsp;<i className={suggestion_direction === "down" ? "pi pi-arrow-down" : (suggestion_direction === "up" ? "pi pi-arrow-up" : "")}/>
                            &nbsp;&nbsp;&nbsp;<label>({difference})</label>
                        </label>
                    </div>

                    {selectedProduct.suggested_price !== selectedProduct.target_price &&
                    <React.Fragment>
                        <div className="p-col-4 p-text-bold">
                            {t('reprice.suggested_price')}
                            {<Tooltip tooltip={t('product.suggested_price_tooltip')}/>}
                        </div>
                        <div className="p-col-8">
                            <label className={suggestion_direction === "down" ? "p-tag p-tag-danger" : (suggestion_direction === "up" ? "p-tag p-tag-success" : "")}>
                                {formatCurrency(selectedProduct.suggested_price, selectedProduct.currency)}
                                &nbsp;{t('reprice.in_site_discount', {discount:((1-(selectedProduct.target_price / selectedProduct.suggested_price)) * 100).toFixed(2)})}
                            </label>
                        </div>
                    </React.Fragment>

                    }

                </React.Fragment> : ""}

            <div className="p-col-4"><b>{t('table_headers.scraping_status')}</b></div>
            <div className="p-col-8">
                <ScrapeStatus type="price" scraping_request={selectedProduct.scraping_request}
                              campaign_id={selectedProduct.campaign_id}
                              product_id={selectedProduct.id}
                              competitor_url={null}
                              newRequest={newRequest}
                              newRequestCompleted={props.refreshProducts}
                              />
            </div>
        </div>
    </Panel>;
}
export default ProductBrief;
