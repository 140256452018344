import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import React, {useRef, useState} from "react";

import {formatCurrency, formatCurrencyText, getHostName, getLastPrice} from "../../utils/Utils";

import {Messages} from "primereact/messages";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {AppContext} from "../../HomePage";
import {toDateString, toDateTimeString} from "../../utils/TimeUtil";
import {Loading, StockTemplate, IndexTemplate} from "../../utils/InlineComponents";
import {OverlayPanel} from "primereact/overlaypanel";
import {confirmDialog} from "primereact/confirmdialog";

const CompetitorBulkOperations = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const messages = useRef(null);
    const op = useRef(null);

    const [detailData, setDetailData] = useState(null);

    const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const deactivateCompetitorsDynamicPricing = () => {
        let product_urls = [...selectedItems.filter(s=> s.is_dynamic_pricing_active === true).map(s=> s.product_url)];

        setLoading(true);
        props.changeCompetitorIsDynamicPricingActive(product_urls, true).then(()=>{
            setLoading(false);
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const activateCompetitorsDynamicPricing = () => {
        let product_urls = [...selectedItems.filter(s=> s.is_dynamic_pricing_active === false).map(s=> s.product_url)];

        setLoading(true);
        props.changeCompetitorIsDynamicPricingActive(product_urls, false).then(()=>{
            setLoading(false);
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const deactivateCompetitors = () => {
        let product_urls = [...selectedItems.filter(s=> s.is_active === true).map(s=> s.product_url)];

        setLoading(true);
        props.changeCompetitorIsActive(product_urls, true).then(()=>{
            setLoading(false);
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const activateCompetitors = () => {
        let product_urls = [...selectedItems.filter(s=> s.is_active === false).map(s=> s.product_url)];

        setLoading(true);
        props.changeCompetitorIsActive(product_urls, false).then(()=>{
            setLoading(false);
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const removeCompetitors = () => {
        let product_urls = [...selectedItems.map(s=> s.product_url)];

        setLoading(true);
        props.deleteCompetitor(product_urls).then(()=>{
            setLoading(false);
        }).catch(error =>{
            setLoading(false);
            setError(error);
            setError(null);
        });
    }

    const competitorProductUrlTemplate = (rowData) => {

        let l = document.createElement("a");
        l.href = rowData.product_url;
        let is_mine = rowData.is_mine;

        return <div className="p-grid p-nogutter ">
            <div className="p-col-12"
                 onMouseEnter={(e) => {
                     setDetailData(rowData);
                     op.current.toggle(e);}}
                 onMouseLeave={(e) => op.current.toggle(e)}>

                    <span className="p-mb-2">
                        {rowData.name && rowData.name !== "" ? rowData.name : getHostName(rowData.product_url)}
                    </span>
            </div>
            {rowData.product_name || rowData.variants && rowData.variants.length !== 0 ?
                <div className="p-col-12" style={{fontSize:"0.8em"}}>
                    {rowData.product_name && rowData.product_name}
                    {rowData.variants && rowData.variants.length !== 0 &&
                    rowData.selected_variant ? (rowData.selected_variant && rowData.selected_variant.length !== 0) &&
                        rowData.selected_variant.map(property => {
                            return <div>
                                <b>{property.name + " : "}</b>{property.value}
                            </div>;}) : null
                    }
                </div>
                :
                null
            }
            {/*<b className="pi pi-external-link"></b>*/}
            {/*<div className="pi pi-chart-bar" title="Show History"/>
            <div className="pi pi-home" data-toggle="tooltip" title="Own Site" style={{display: (is_mine ? 'inline' : 'none') }} />*/}

        </div>;
    };

    const competitorLastModifiedTemplate = (rowData) => {
        const lastPrice = getLastPrice(rowData.prices);

        return <div className="p-grid p-justify-end">{lastPrice && toDateTimeString(lastPrice.created_at)}</div>
    };

    const competitorPriceTemplate = (rowData) =>{

        const lastPrice = getLastPrice(rowData.prices);

        let price = lastPrice ? (formatCurrency(lastPrice.value, lastPrice.currency)) : "";

        let tooltip = lastPrice && lastPrice.original_value ?
            (formatCurrencyText(lastPrice.original_value, lastPrice.original_currency)) :
            null;

        return <div className="p-grid p-align-center p-justify-end">{price}
            {rowData.multiplier && rowData.multiplier > 1 && <div style={{fontSize: '10px', fontWeight: 'bold', color: '#d06868'}}>{" ( x" + rowData.multiplier + " )"}</div>}
            {tooltip ? <Button icon="pi pi-refresh" className="imageButton" tooltip={tooltip} style={{color: 'maroon'}}/> : null}
        </div>;
    };

    const competitorStockTemplate = (rowData) => {
        let inactive_url_tooltip = rowData.is_url_deactivated ? rowData.is_url_deactivated : false;
        let not_available_tooltip = rowData.not_available_days && rowData.not_available_days >= 7 ? rowData.not_available_days : null;

        const lastPrice = getLastPrice(rowData.prices);

        return <div className="p-grid p-justify-end">
            {!inactive_url_tooltip &&  not_available_tooltip ? <Button icon="pi pi-exclamation-triangle" className="imageButton" tooltip={t('product_detail.not_available_since', {notAvailableSince: toDateString(rowData.not_available_since)})} style={{color: 'orange'}}/> : null}
            {inactive_url_tooltip ? <Button icon="pi pi-exclamation-triangle" className="imageButton" tooltip={t('product_detail.inactive_url')} style={{color: 'maroon'}}/> : null}
            {StockTemplate(lastPrice && lastPrice.stock)}
        </div>;
    };

    const dynamicPricingActiveTemplate = (rowData) => {
        return <div className="p-grid p-justify-end">
            {rowData.is_dynamic_pricing_active ? t("actions.active") : t("actions.passive")}
        </div>;
    };

    const competitorIsActiveTemplate = (rowData) => {
        return <div className="p-grid p-justify-end">
            {rowData.is_active ? t("actions.active") : t("actions.passive")}
        </div>;
    };

    const footer = (loading ?
        <div className="p-col-12" style={{textAlign: 'center'}}>
            <Loading/>
        </div> :
        <div className="p-grid">
            <div className="p-col-fixed">
                <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => props.hideDialog()}/>
            </div>
            <div className="p-col">

                <Button label={t('actions.exclude_all', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})}
                        icon="pi pi-times" className="p-button-outlined p-button-warning" onClick={() => {
                    confirmDialog({
                        message: t('dialogs.competitor.deactivate_dynamic_pricing_msg'),
                        header: t('dialogs.competitor.deactivate_dynamic_pricing'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        accept: deactivateCompetitorsDynamicPricing,
                        acceptLabel: t('yes_no.yes'),
                        rejectLabel: t('yes_no.no')
                    });
                }}/>
                <Button label={t('actions.include_all', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})}
                        icon="pi pi-check" className="p-button-outlined p-button-info" onClick={() => {
                    confirmDialog({
                        message: t('dialogs.competitor.activate_dynamic_pricing_msg'),
                        header: t('dialogs.competitor.activate_dynamic_pricing'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        accept: activateCompetitorsDynamicPricing,
                        acceptLabel: t('yes_no.yes'),
                        rejectLabel: t('yes_no.no')
                    });
                }}/>

                <Button label={t('actions.deactivate_all', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})}
                        icon="pi pi-times" className="p-button-outlined p-button-warning" onClick={() => {
                    confirmDialog({
                        message: t('dialogs.competitor.deactivate_msg'),
                        header: t('dialogs.competitor.deactivate'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        accept: deactivateCompetitors,
                        acceptLabel: t('yes_no.yes'),
                        rejectLabel: t('yes_no.no')
                    });
                }}/>
                <Button label={t('actions.activate_all', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})}
                        icon="pi pi-check" className="p-button-outlined p-button-info" onClick={() => {
                    confirmDialog({
                        message: t('dialogs.competitor.activate_msg'),
                        header: t('dialogs.competitor.activate'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        accept: activateCompetitors,
                        acceptLabel: t('yes_no.yes'),
                        rejectLabel: t('yes_no.no')
                    });
                }}/>
                <Button label={t('actions.remove_all', {number:' (' + (selectedItems.length ? selectedItems.length : 0) + ')'})}
                        icon="pi pi-trash" className="p-button-outlined p-button-danger" onClick={() => {
                    confirmDialog({
                        message: t('dialogs.competitor.delete_msg'),
                        header: t('dialogs.competitor.delete'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        accept: removeCompetitors,
                        acceptLabel: t('yes_no.yes'),
                        rejectLabel: t('yes_no.no')
                    });
                }}/>
            </div>
        </div>
    );

    return <Dialog header={t('actions.bulk_operations')} footer={footer} visible={true} contentStyle={{maxHeight: '60vh', overflow:"auto"}} style={{maxWidth: '60vw', minWidth: '60vw'}} modal={true} onHide={() => props.hideDialog()}>

        <Messages ref={messages}/>

        <OverlayPanel ref={op} dismissable={true}>
            {detailData &&
            <span>
                <img referrerPolicy='no-referrer' className="img-fluid-thumb" src={detailData.product_image} alt=""/>
            </span> }
        </OverlayPanel>

        <div className="p-grid">
            <div className="p-col-12">
                <DataTable value={props.selectedProduct.competitors.filter(c=> c.is_mine !== true)}
                           responsive={true}
                           selectionMode="multiple" metaKeySelection={false}
                           selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                           resizableColumns={true} columnResizeMode="fit">
                    <Column selectionMode="multiple" style={{width:'3em'}}/>
                    <Column body={IndexTemplate} style={{width:'3em'}} />
                    <Column header={t('table_headers.name')} field="competitorUrl" body={competitorProductUrlTemplate} style={{width:'40%'}}/>
                    <Column header={t('table_headers.last_check')} headerStyle={{textAlign:"right"}}  field="lastModified"  body={competitorLastModifiedTemplate} style={{width:'20%'}}/>
                    <Column header={t('product_detail.price')} headerStyle={{textAlign:"right"}} field="price" body={competitorPriceTemplate} style={{width:'14%'}}/>
                    <Column header={t('product_detail.stock')} headerStyle={{textAlign:"right"}} field="stock" body={competitorStockTemplate} style={{width:'13%'}}/>
                    {props.selectedProduct.pricing_strategy &&
                    props.selectedProduct.pricing_strategy.ignore_some_competitors &&
                    <Column header={t('product_detail.repricing')} headerStyle={{textAlign:"right"}} field="is_dynamic_pricing_active" body={dynamicPricingActiveTemplate} style={{width:'13%'}}/>}
                    <Column header={t('product.status')} headerStyle={{textAlign:"right"}} field="is_active" body={competitorIsActiveTemplate} style={{width:'13%'}}/>
                </DataTable>
            </div>
        </div>

    </Dialog>;
};
export default CompetitorBulkOperations
