import React, {useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Password} from 'primereact/password';
import {Messages} from 'primereact/messages';
import {Captcha} from 'primereact/captcha';
import {ProgressBar} from 'primereact/progressbar';

import {AuthService} from "../../service/AuthService";
import {UnAuthService} from "../../service/UnAuthService";

import {AuthInfo, Logo} from "../../utils/InlineComponents";
import queryString from "query-string";

import {AppContext} from "../../HomePage";
import {getKeyValue} from "../../utils/Utils";

const Login  = (props) =>  {

    const {t, toast, host, appName, navigate, location, setError} = React.useContext(AppContext);

    const messages = useRef(null);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [captchaValid, setCaptchaValid] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (location !== undefined)
        {
            let params = queryString.parse(location.search);
            if (params)
            {
                if(params.register && params.register === "1")
                    messages.current.show({sticky: true, severity: "success", summary: "", detail: t('login.await_activation_detail_1', {appName: appName})});
                else if (params.recover && params.recover === "1")
                    messages.current.show({sticky: true, severity: "success", summary: "", detail: t('login.recovery_mail_detail')});
                else if (params.recover && params.recover === "2")
                    messages.current.show({sticky: true, severity: "success", summary: "", detail: t('login.password_changed_detail')});
                else if (params.expire && params.expire === "1")
                    messages.current.show({sticky: true, severity: "warn", summary: "", detail: t('login.token_expire')});

            }
        }
        if(AuthService.loggedIn()) {
            navigate("/");
        }

        setShowCaptcha(localStorage.getItem("loginRetry") && localStorage.getItem("loginRetry") === "true");
    }, [])


    const signup = () => {
        navigate("/register");
    };

    const login = () => {

        if (disabled) {
            return;
        }
        setDisabled(true);

        if(showCaptcha && !captchaValid)
        {
            setDisabled(false);
            toast.current.show({severity: "error", summary: t('login.captcha_summary'), detail: t('login.captcha_detail')});
            return;
        }

        UnAuthService.login(email, password).then(data => {
            localStorage.removeItem("loginRetry");
            setShowCaptcha((localStorage.getItem("loginRetry") && localStorage.getItem("loginRetry") === "true"));
            navigate("/campaign");
        }).catch(error =>{
            setError(error);
            setError(null);
            setDisabled(false);
            setShowCaptcha((localStorage.getItem("loginRetry") && localStorage.getItem("loginRetry") === "true"));
            localStorage.setItem("loginRetry", "true");
        });
    }

    const recover = () => {
        navigate("/recovery");
    };

    const onEnter = (e) => e.key === "Enter" && login();

    const captchaResponse = () => {
        setCaptchaValid(true);
    };

    return <div className="p-grid p-nogutter" style={{height: "100vh"}}>
        <div className="p-grid p-col-12 p-lg-7 authInfoContainer" >
            <AuthInfo/>
        </div>

        <div className="p-grid p-col-12 p-lg-5 p-nogutter" style={{ backgroundColor:"white"}} >
            <div className="p-grid p-align-center p-fluid" style={{margin:"auto", width:"400px"}}  >
                <div className="p-col-12 ">
                    <Messages ref={messages} className="p-align-start" />
                    <Logo/>
                </div>
                <div className="p-col-12 ">
                    <h4>{t('menu_item.login')}</h4>
                </div>
                <div className="p-col-12 ">
                <span className="p-float-label">
                    <InputText id="email" name="email" value={email} onChange={(e) => {setEmail(getKeyValue(e).value);}} onKeyDown={onEnter} />
                    <label htmlFor="email">{t('user.email')}</label>
                </span>
                </div>

                <div className="p-col-12">
                <span className="p-float-label">
                    <Password id="password" name="password" value={password} onChange={(e) => {setPassword(getKeyValue(e).value)}} onKeyDown={onEnter} feedback={false} />
                    <label htmlFor="password">{t('user.password')}</label>
                </span>
                </div>
                <div className="p-col-12">
                    <button className="link-button" onClick={recover}>{t('user.forgot_password')}</button>
                </div>
                {showCaptcha &&<div className="p-col-12">
                    <Captcha siteKey="6LfwsuYUAAAAAHfOxoAxTJYehxxhfLO_SSJZDaaL" onResponse={captchaResponse} />
                </div>}
                <div className="p-col-12">
                    <Button label={t('actions.login')} icon="pi pi-sign-in" className="p-button-outlined p-button-success" onClick={login} disabled={disabled}/>
                    {disabled && <ProgressBar mode="indeterminate" style={{height: '3px'}} />}
                </div>
                {host !== "ecomscanner" &&
                <div className="p-col-12">
                    <span >{t('login.sign_up_message_1')}<h6 className="link-button" onClick={signup}>{t('actions.join_us')}</h6>{t('login.sign_up_message_2')}</span>
                </div> }
            </div>
        </div>
    </div>
};
export default Login;
