import {AuthService} from "./AuthService";
import {userId} from "../utils/Utils";
import moment from "moment";
import {toDateStringIgnoringTimezone} from "../utils/TimeUtil";

export class ExportService {
    url = `product_export/`;

    exportPriceStockChanges(campaign_id, selectedDate) {
        return AuthService.apiGet(this.url + "price_stock_changes/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, price_created_at: selectedDate}
        }).then(res => res);
    }

    exportSales(campaign_id, startDate, endDate){
        return AuthService.apiGet(this.url + "sales/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, start_date: startDate, end_date: endDate}
        }).then(res => res);

    }

    exportPriceComparison(campaign_id, otherCampaigns, showCheapestSellerInOtherCampaigns, showShippingPrices, showListPrices) {
        return AuthService.apiGet(this.url + "price_comparison/",{
            responseType: 'blob',
            params:
                {
                    campaign_id: campaign_id,
                    other_campaign_ids: otherCampaigns.join(","),
                    show_cheapest_seller_in_other_campaigns: showCheapestSellerInOtherCampaigns,
                    show_also_shipping_prices: showShippingPrices,
                    show_list_prices: showListPrices,
                }
        }).then(res => res);
    }

    exportPriceComparisonSummary(campaign_id, otherCampaigns, includeShippingPrices) {
        return AuthService.apiGet(this.url + "price_comparison_summary/",{
            responseType: 'blob',
            params: {
                campaign_id: campaign_id,
                other_campaign_ids: otherCampaigns.join(","),
                include_shipping_prices: includeShippingPrices
            }
        }).then(res => res);
    }

    exportDailyPriceComparisonSummary(campaign_id, start_date, end_date) {
        return AuthService.apiGet(this.url + "daily_price_comparison/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, start_date: start_date, end_date: end_date}
        }).then(res => res);
    }

    exportWeeklyPriceComparisonSummary(campaign_id, startYear, startWeekNumber, endYear, endWeekNumber) {
        startWeekNumber = startYear + '-W' + startWeekNumber;
        endWeekNumber = endYear + '-W' + endWeekNumber;
        return AuthService.apiGet(this.url + "weekly_price_comparison/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, start_week_range: startWeekNumber, end_week_range: endWeekNumber}
        }).then(res => res);
    }

    exportNCheapestSellerPriceComparison(campaign_id) {
        return AuthService.apiGet(this.url + "n_cheapest_sellers_price_comparison/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id}
        }).then(res => res);
    }

    exportPriceComparisonWithFilteredSellers(campaign_id, competitorNames) {
        return AuthService.apiGet(this.url + "price_comparison_with_filtered_sellers/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, competitor_names: competitorNames}
        }).then(res => res);
    }

    exportCompetitorPriceList(campaign_id, date) {
        return AuthService.apiGet(this.url + "competitor_price_list/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, date: toDateStringIgnoringTimezone(date)}
        }).then(res => res);
    }

    exportProductReviews(campaign_id) {
        return AuthService.apiGet(this.url + "product_reviews/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id}
        }).then(res => res);
    }

    exportCompetitorPriceHistory(campaign_id, startDate, endDate) {
        return AuthService.apiGet(this.url + "competitor_price_history/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, start_date: startDate, end_date: endDate}
        }).then(res => res);
    }

    exportMarketplaceSellers(campaign_id, urls) {
        urls = urls.map(item => item.code).join(", ")
        return AuthService.apiGet(this.url + "marketplace_sellers/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, urls: urls}
        }).then(res => res);
    }

    exportDetailedProductList(campaign_id, startDate, endDate) {
        return AuthService.apiGet(this.url + "detailed_product_list/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, start_date: startDate, end_date: endDate}
        }).then(res => res);
    }

    exportAllSellerUrlList(campaign_id) {
        return AuthService.apiGet(this.url + "all_seller_links/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id}
        }).then(res => res);
    }

    exportPriceViolations(campaign_id, selectedDate) {
        return AuthService.apiGet(this.url + "price_violations/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, price_created_at: selectedDate}
        }).then(res => res);
    }

    exportPriceViolationsBulk(user_id, campaign_id, startDate, endDate) {
        return AuthService.apiGet(this.url + "price_violations_bulk/",{
            responseType: 'blob',
            params: {user_id: user_id, campaign_id: campaign_id, start_price_created_at: startDate, finish_price_created_at: endDate}
        }).then(res => res);
    }

    exportUnauthorizedSellers(campaign_id, selectedDate) {
        return AuthService.apiGet(this.url + "unauthorized_sellers/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, price_created_at: selectedDate}
        }).then(res => res);
    }

    exportDropShippingLoss(campaign_id, selectedDate) {
        return AuthService.apiGet(this.url + "drop_shipping_loss/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, price_created_at: selectedDate}
        }).then(res => res);
    }

    exportProductList(campaign_id, fields, all_fields, filters) {
        return AuthService.apiGet(this.url + "product_list/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, fields: fields.join(), all_fields: JSON.stringify(all_fields), filters: filters}
        }).then(res => res);
    }

    exportDynamicPricing(campaign_id, startDate, endDate) {
        return AuthService.apiGet(this.url + "applied_pricing_suggestions/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, user_id: userId(), start_date: startDate, end_date: endDate}
        }).then(res => res);
    }

    exportProductPriceHistory(product_id, history_start_date, history_end_date){
        return AuthService.apiGet(this.url + "product_price_history/",{
            responseType: 'blob',
            params: {product_id: product_id,
                history_start_date: moment(history_start_date).startOf('day').toDate(),
                history_end_date: moment(history_end_date).endOf('day').toDate()}
        }).then(res => res);
    }

    exportProductPriceIndex(campaign_id, startDate, endDate){
        return AuthService.apiGet(this.url + "price_index_report/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id, user_id: userId(), start_date: startDate, end_date: endDate}
        }).then(res => res);
    }
}
