import moment from 'moment'
import React from "react";
import {user} from "./Utils"
import {DateTime} from "luxon";


export const getWeeksAgo = (number) => {
    let today = new Date();
    return new Date(today.setDate(today.getDate()-7*number));
}


export const getWeekNumber = (value) => {
    return DateTime.fromJSDate(new Date(value)).weekNumber;
}


export const getFirstDayOfTheYear = () => {
    let today = new Date();
    return new Date(today.getFullYear(), 0, 1);
}

export const getMonthsAgoFirstDay = (number) => {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth()-number, 1);
}

export const getMonthsAgoFirstDayOfDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth()-1, 1);
}

export const getMonthsAgoLastDayOfDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth()+1, 0);
}

export const getNextMonthFirstDayOfDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth()+1, 1);
}

export const getNextMonthLastDayOfDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth()+1, 0);
}

export const getMonthsAgo = (number) => {
    let today = new Date();
    return new Date(today.setMonth(today.getMonth()-number));
}

export const getYearsAgo = (number) => {
    let today = new Date();
    return new Date(today.setFullYear(today.getFullYear()-number));
}

export const toDateTimeString = (value) =>
{
    if(value && user() && user().timezone)
    {
        let m = moment(value);
        return m.tz(user().timezone).format("YYYY-MM-DD HH:mm:ss");
    }
    else
        return "";
};

export const toDateString = (value) =>
{

    if(value && user() && user().timezone)
    {
        if (value instanceof Date) {
            return DateTime.fromJSDate(value).setZone(user().timezone).toFormat('yyyy-MM-dd');
        }
        else if (value.toString().includes("T") ) {
            return DateTime.fromISO(value).setZone(user().timezone).toFormat('yyyy-MM-dd');
        }
        else {
            return DateTime.fromMillis(value).setZone(user().timezone).toFormat('yyyy-MM-dd');
        }
        // let m = moment(value);
        // return m.tz(user().timezone).format("YYYY-MM-DD");
    }
    else
        return "";
};

export const toDateStringIgnoringTimezone = (value) =>
{
    if(value && user() && user().timezone)
    {
        let m = moment(value);
        return m.format("YYYY-MM-DD");
    }
    else
        return "";
};

export const getDateString = (d, separator='-') => {
    let newDate = new Date(d);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;
}


export const getCurrentDate = (separator='-') =>
{
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}

export const getTimeZoneDisplay = (timezone) =>{
    return "(GMT" + moment.tz(timezone).format('Z') + ") " + timezone;
};
