import React, {useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {UserService} from "../../service/UserService";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import {getKeyValue, toTitleCase} from "../../utils/Utils";
import {AccountService} from "../../service/AccountService";
import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {InputText} from "primereact/inputtext";

const Impersonation = (props) => {

    const {t, toast, navigate, setError} = React.useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState("");
    const [forceRefresh, setForceRefresh] = useState(0);

    const userService = new UserService();
    const accountService = new AccountService();

    let dtUsers = useRef(null);

    useEffect(()=>{
        setLoading(true);

        userService.getUsers(false, forceRefresh !== 0, false).then(users=>{
            setUsers(users);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    }, [forceRefresh]);

    const impersonateUser = (e) => {
        impersonate(e.value)
    };

    const impersonate = (rowData) => {
        accountService.impersonate(rowData.id).then(data => {
            navigate('campaign');
            window.location.reload(false);
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const actionTemplate = (rowData) => {
        return <div>
            <Button icon="pi pi-users" className="p-button-outlined p-button-warning" tooltip={t('actions.impersonate')} style={{marginRight: '.5em'}} onClick={() => impersonate(rowData)}/>
        </div>;
    };

    const filteredUsers = () => users.map(user=> ({ ...user, full_name: toTitleCase(user.first_name) + ' ' + toTitleCase(user.last_name) })).filter(u=>{
        return filter === "" || (filter !== "" && (u.full_name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 || u.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
    });
    return (

        <div className="p-grid card">

            {loading ? <div className="p-col-12" style={{textAlign:'center'}}>
                <Loading/>
            </div> :
            <div className="p-col-12">
                <InputText name="filter" value={filter} onChange={(e) => {setFilter(getKeyValue(e).value)}} className="p-mr-1" placeholder={t('product.globalFilter')} />
                <Button icon="pi pi-refresh" data-tour="step5" tooltip={t('actions.refresh')}
                        tooltipOptions={{position: 'bottom'}} className="p-button-outlined p-mr-1"
                        onClick={() => {setForceRefresh(forceRefresh + 1);}}/>

                <DataTable ref={dtUsers} value={filteredUsers()}
                           responsive={true}
                           selectionMode="single"
                           onSelectionChange={impersonateUser}
                           sortField="last_login_at" sortOrder={-1}
                           style={{fontSize:'0.9em'}}>
                    <Column body={actionTemplate} className="p-align-center" style={{width:"5em"}}/>
                    <Column field="full_name" header={t('user.name')} />
                    <Column field="email" header={t('user.email')} />

                </DataTable>
            </div>}
        </div>
    );
};
export default Impersonation;
