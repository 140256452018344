import React, {useRef, useState} from 'react';
import App from "./App";
import Register from "./components/Account/Register";
import Login from "./components/Account/Login";
import Recovery from "./components/Account/Recovery";

import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Toast} from "primereact/toast";
import ErrorHandler from "./utils/ErrorHandler";
import {AuthRequiredRoute} from "./AuthRequiredRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import Account from "./components/Account/Account";
import ChangePassword from "./components/Account/ChangePassword";
import Campaign from "./components/Campaign/Campaign";
import Product from "./components/Product/Product";
import PriceComparison from "./components/Dashboard/PriceComparison";
import PriceComparisonSummary from "./components/Dashboard/PriceComparisonSummary";
import WeeklyPriceComparison from "./components/Dashboard/WeeklyPriceComparison";
import NCheapestSellerPriceComparison from "./components/Dashboard/NCheapestSellerPriceComparison";
import FilteredSellersPriceComparison from "./components/Dashboard/FilteredSellersPriceComparison";
import PriceStockChanges from "./components/Dashboard/PriceStockChanges";
import SalesPerformance from "./components/Dashboard/SalesPerformance";
import PriceViolations from "./components/Dashboard/PriceViolations";
import SellersSummary from "./components/Dashboard/SellersSummary";
import BrandComparison from "./components/Dashboard/BrandComparison";
import UnauthorizedSellers from "./components/Dashboard/UnauthorizedSellers";
import DropShippingLoss from "./components/Dashboard/DropShippingLoss";
import DynamicPricing from "./components/Dashboard/DynamicPricing";
import ExcelReports from "./components/Dashboard/ExcelReports";
import ProductDetail from "./components/Product/ProductDetail";
import SiteDefinitions from "./components/Admin/SiteDefinitions";
import Cache from "./components/Admin/Cache";
import HeadlessBrowser from "./components/Admin/HeadlessBrowser";
import ScrapingJobs from "./components/Admin/ScrapingJobs";
import JobScheduling from "./components/Admin/JobScheduling";
import Impersonation from "./components/Admin/Impersonation";
import AdminDashboard from "./components/Admin/AdminDashboard";
import User from "./components/Admin/User";
import PriceRestMessage from "./components/Admin/PriceRestMessage";
import Error from "./components/Common/Error";
import Page404 from "./Page404";
import SpecialFields from "./components/Admin/SpecialFields";
import DailyPriceComparison from "./components/Dashboard/DailyPriceComparison";
import PriceIndex from "./components/Dashboard/PriceIndex";

export const AppContext = React.createContext();

const HomePage = (props) => {

    const {t} = useTranslation();
    const navigate  = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(null);

    let toast = useRef(null);

    const url = window.location.hostname;

    let host = "pricerest";
    let appName = "PriceRest";
    let companyName = t('company_name');
    let supportEmail = "info@pricerest.com";

    if (url.toLowerCase().indexOf('ecomscanner') !== -1) {
        host = "ecomscanner";
        appName = "eCOMSCANNER";
        companyName = "";
        const favicon = document.getElementById("favicon"); // Accessing favicon element
        favicon.href = "https://intpact.com/uploads/lcwebsite/1/favicon_image/favicon.ico";
        document.title = t('login.auth_info_msg_1', {appName: appName});
        supportEmail = "support@intpact.com";
    }
    else
    {
        document.title = t('login.auth_info_msg_1', {appName: appName});
    }

    return (
        <AppContext.Provider value={{host, appName, supportEmail, companyName,
            setError,
            t, toast, navigate, location}}>
            <div>
                <Toast ref={toast} baseZIndex={100000} />
                <ErrorHandler error={error} />

                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/recovery" element={<Recovery/>}/>
                    <Route path="*" element={<App/>} >
                        <Route path="" element={<AuthRequiredRoute><Dashboard /></AuthRequiredRoute>} />
                        <Route path="account" element={<AuthRequiredRoute><Account /></AuthRequiredRoute>}/>
                        <Route path="changepassword" element={<AuthRequiredRoute><ChangePassword /></AuthRequiredRoute>}/>
                        <Route path="campaign" element={<AuthRequiredRoute><Campaign /></AuthRequiredRoute>} />
                        <Route path="product" element={<AuthRequiredRoute><Product /></AuthRequiredRoute>} />
                        <Route path="report/price_comparison" element={<AuthRequiredRoute><PriceComparison /></AuthRequiredRoute>} />
                        <Route path="report/price_comparison_summary" element={<AuthRequiredRoute><PriceComparisonSummary /></AuthRequiredRoute>} />
                        <Route path="report/daily_price_comparison" element={<AuthRequiredRoute><DailyPriceComparison /></AuthRequiredRoute>} />
                        <Route path="report/weekly_price_comparison" element={<AuthRequiredRoute><WeeklyPriceComparison /></AuthRequiredRoute>} />
                        <Route path="report/n_cheapest_seller_price_comparison" element={<AuthRequiredRoute><NCheapestSellerPriceComparison /></AuthRequiredRoute>} />
                        <Route path="report/filtered_seller_price_comparison" element={<AuthRequiredRoute><FilteredSellersPriceComparison /></AuthRequiredRoute>} />
                        <Route path="report/price_changes" element={<AuthRequiredRoute><PriceStockChanges /></AuthRequiredRoute>} />
                        <Route path="report/sales" element={<AuthRequiredRoute><SalesPerformance /></AuthRequiredRoute>} />
                        <Route path="report/violations" element={<AuthRequiredRoute><PriceViolations /></AuthRequiredRoute>} />
                        <Route path="report/sellers_summary" element={<AuthRequiredRoute><SellersSummary /></AuthRequiredRoute>} />
                        <Route path="report/brand_comparison" element={<AuthRequiredRoute><BrandComparison /></AuthRequiredRoute>} />
                        <Route path="report/price_index" element={<AuthRequiredRoute><PriceIndex /></AuthRequiredRoute>} />
                        <Route path="report/unauthorized_sellers" element={<AuthRequiredRoute><UnauthorizedSellers /></AuthRequiredRoute>} />
                        <Route path="report/drop_shipping_loss" element={<AuthRequiredRoute><DropShippingLoss /></AuthRequiredRoute>} />
                        <Route path="report/dynamic_pricing" element={<AuthRequiredRoute><DynamicPricing /></AuthRequiredRoute>} />
                        <Route path="report/excel_reports" element={<AuthRequiredRoute><ExcelReports /></AuthRequiredRoute>} />
                        <Route path="productdetail" element={<AuthRequiredRoute><ProductDetail /></AuthRequiredRoute>} />
                        <Route path="websites" element={<AuthRequiredRoute><SiteDefinitions /></AuthRequiredRoute>}/>
                        <Route path="cache" element={<AuthRequiredRoute><Cache /></AuthRequiredRoute>}/>
                        <Route path="headless_browsers" element={<AuthRequiredRoute><HeadlessBrowser /></AuthRequiredRoute>}/>
                        <Route path="special_fields" element={<AuthRequiredRoute><SpecialFields /></AuthRequiredRoute>}/>
                        <Route path="scraping_job" element={<AuthRequiredRoute><ScrapingJobs /></AuthRequiredRoute>}/>
                        <Route path="job_scheduling" element={<AuthRequiredRoute><JobScheduling /></AuthRequiredRoute>}/>
                        <Route path="impersonation" element={<AuthRequiredRoute><Impersonation /></AuthRequiredRoute>}/>
                        <Route path="admin_dashboard" element={<AuthRequiredRoute><AdminDashboard /></AuthRequiredRoute>}/>
                        <Route path="user" element={<AuthRequiredRoute><User /></AuthRequiredRoute>}/>
                        <Route path="message" element={<AuthRequiredRoute><PriceRestMessage /></AuthRequiredRoute>}/>
                        <Route path="error" element={<AuthRequiredRoute><Error /></AuthRequiredRoute>}/>

                        <Route path="*" element={<Page404 />}/>
                    </Route>
                </Routes>
            </div>
        </AppContext.Provider>

    );

};
export default HomePage;
