import React, {useEffect, useState} from 'react';
import {AppContext} from "../../HomePage";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {getKeyValue} from "../../utils/Utils";
import {InputNumber} from "primereact/inputnumber";

const UserSpecialPlan = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const [specialPlan, setSpecialPlan] = useState(props.specialPlan);
    const [monthlyPrice, setMonthlyPrice] = useState(specialPlan.monthly_price);

    useEffect ( ()=>{
    }, []);


    const onChange = (e) => {
        const { key, value } = getKeyValue(e);
        specialPlan[key] = value
        setMonthlyPrice(getKeyValue(e).value)
        // setSpecialPlan({...specialPlan, [key] : value});
    };

    const additionalFeatureNameTemplate = (rowData) => {
        return t('subscription.' + rowData.name);
    };

    const additionalFeaturePriceEditor = (props) => {
        return <InputNumber value={props.rowData.price} style={{width:"80px"}} mode="decimal" minFractionDigits={2} onValueChange={(e)=>{
            let additional_feature = specialPlan.additional_features.filter(additional_feature => additional_feature.name === props.rowData.name)[0]
            additional_feature.price = getKeyValue(e).value;
        }}/>;
    };

    return <React.Fragment>

        <div className="card card-w-title alignCenter p-fluid">
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <h4>{t('subscription.special_plan')}</h4>
                </div>
                <div className="p-col-6">
                    <label htmlFor="monthly_price">{t('subscription.monthly_price')}</label>
                    <InputText id="monthly_price" name="monthly_price" value={specialPlan.monthly_price ?? ""} onChange={onChange}/>
                    {/*<InputText id="monthly_price" name="monthly_price" value={specialPlan.monthly_price ?? ""} onChange={(e)=>{specialPlan.monthly_price = getKeyValue(e).value; setMonthlyPrice(getKeyValue(e).value);}}/>*/}
                </div>

                <div className="p-col-6">
                    <label htmlFor="total_product_count">{t('subscription.total_product_count')}</label>
                    <InputText id="total_product_count" name="total_product_count" keyfilter="pint" value={specialPlan.total_product_count ?? ""} onChange={onChange}/>
                </div>

                <div className="p-col-6">
                    <label htmlFor="daily_price_check_count">{t('subscription.daily_price_check_count')}</label>
                    <InputText id="daily_price_check_count" name="daily_price_check_count" keyfilter="pint" value={specialPlan.daily_price_check_count ?? ""} onChange={onChange}/>
                </div>

                <div className="p-col-6">
                    <label htmlFor="manuel_price_check_count">{t('subscription.manuel_price_check_count')}</label>
                    <InputText id="manuel_price_check_count" name="manuel_price_check_count" keyfilter="pint" value={specialPlan.manuel_price_check_count ?? ""} onChange={onChange}/>
                </div>

                <div className="p-col-6">
                    <Checkbox id="product_variant_tracking_enabled" name="product_variant_tracking_enabled" value={specialPlan.product_variant_tracking_enabled} checked={specialPlan.product_variant_tracking_enabled === true} onChange={onChange}/>
                    <label htmlFor="product_variant_tracking_enabled">{t('subscription.product_variant_tracking_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="daily_notification_enabled" name="daily_notification_enabled" value={specialPlan.daily_notification_enabled} checked={specialPlan.daily_notification_enabled === true} onChange={onChange}/>
                    <label htmlFor="daily_notification_enabled">{t('subscription.daily_notification_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="price_history_enabled" name="price_history_enabled" value={specialPlan.price_history_enabled} checked={specialPlan.price_history_enabled === true} onChange={onChange}/>
                    <label htmlFor="price_history_enabled">{t('subscription.price_history_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="stock_availability_monitoring_enabled" name="stock_availability_monitoring_enabled" value={specialPlan.stock_availability_monitoring_enabled} checked={specialPlan.stock_availability_monitoring_enabled === true} onChange={onChange}/>
                    <label htmlFor="stock_availability_monitoring_enabled">{t('subscription.stock_availability_monitoring_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="product_cost_monitoring_enabled" name="product_cost_monitoring_enabled" value={specialPlan.product_cost_monitoring_enabled} checked={specialPlan.product_cost_monitoring_enabled === true} onChange={onChange}/>
                    <label htmlFor="product_cost_monitoring_enabled">{t('subscription.product_cost_monitoring_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="instant_notification_enabled" name="instant_notification_enabled" value={specialPlan.instant_notification_enabled} checked={specialPlan.instant_notification_enabled === true} onChange={onChange}/>
                    <label htmlFor="instant_notification_enabled">{t('subscription.instant_notification_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="instant_price_check_enabled" name="instant_price_check_enabled" value={specialPlan.instant_price_check_enabled} checked={specialPlan.instant_price_check_enabled === true} onChange={onChange}/>
                    <label htmlFor="instant_price_check_enabled">{t('subscription.instant_price_check_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="sale_history_enabled" name="sale_history_enabled" value={specialPlan.sale_history_enabled} checked={specialPlan.sale_history_enabled === true} onChange={onChange}/>
                    <label htmlFor="sale_history_enabled">{t('subscription.sale_history_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="competitor_suggestion_enabled" name="competitor_suggestion_enabled" value={specialPlan.competitor_suggestion_enabled} checked={specialPlan.competitor_suggestion_enabled === true} onChange={onChange}/>
                    <label htmlFor="competitor_suggestion_enabled">{t('subscription.competitor_suggestion_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="dynamic_pricing_enabled" name="dynamic_pricing_enabled" value={specialPlan.dynamic_pricing_enabled} checked={specialPlan.dynamic_pricing_enabled === true} onChange={onChange}/>
                    <label htmlFor="dynamic_pricing_enabled">{t('subscription.dynamic_pricing_enabled')}</label>
                </div>

                <div className="p-col-6">
                    <Checkbox id="api_enabled" name="api_enabled" value={specialPlan.api_enabled} checked={specialPlan.api_enabled === true} onChange={onChange}/>
                    <label htmlFor="api_enabled">{t('subscription.api_enabled')}</label>
                </div>

                <div className="p-col-12">
                    {specialPlan &&
                    <DataTable value={specialPlan.additional_features}>
                        <Column field="name" header={t('table_headers.name')} body={additionalFeatureNameTemplate}/>
                        <Column field="price" header={t('subscription.price')} editor={additionalFeaturePriceEditor}/>
                    </DataTable>
                    }
                </div>
            </div>
        </div>

    </React.Fragment>;
};
export default UserSpecialPlan;
