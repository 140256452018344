import {AuthService} from "./AuthService";

export class TemplateService {
    url = `product_export/`;

    exportProductImportFromUrlsExcelTemplate() {
        return AuthService.apiGet(this.url + "product_import_from_urls_excel_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportProductImportWithSellersExcelTemplate() {
        return AuthService.apiGet(this.url + "product_import_with_sellers_excel_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportProductSellersURLsUpdateExcelTemplate() {
        return AuthService.apiGet(this.url + "product_sellers_urls_update_excel_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportProductSellersURLsUpdateColumnExcelTemplate() {
        return AuthService.apiGet(this.url + "product_sellers_urls_update_vertical_excel_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportDropShippingProductImportExcelTemplate() {
        return AuthService.apiGet(this.url + "drop_shipping_product_import_excel_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportSellerProductImportExcelTemplate() {
        return AuthService.apiGet(this.url + "seller_product_import_excel_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportProductActivationTemplate() {
        return AuthService.apiGet(this.url + "product_activation_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportProductDeletionTemplate() {
        return AuthService.apiGet(this.url + "product_deletion_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportProductMapUpdateTemplate() {
        return AuthService.apiGet(this.url + "product_map_updating_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportViolationStrategyScheduleTemplate() {
        return AuthService.apiGet(this.url + "violation_strategy_schedule_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportUpdateProductsTemplate() {
        return AuthService.apiGet(this.url + "update_products_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportManufacturerProductImportExcelTemplate() {
        return AuthService.apiGet(this.url + "manufacturer_product_import_excel_template/",{
            responseType: 'blob',
            params: {}
        }).then(res => res);
    }

    exportDynamicPricingTemplate(campaign_id) {
        return AuthService.apiGet(this.url + "dynamic_pricing_template/",{
            responseType: 'blob',
            params: {campaign_id: campaign_id}
        }).then(res => res);
    }
}
