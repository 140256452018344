import React from 'react';
import {Button} from "primereact/button";
import "moment-timezone";
import {AppContext} from "../../HomePage";
import {Badge} from "primereact/badge";
import {CampaignContext} from "../../App";

const DownloadReport = (props) => {

    const {t} = React.useContext(AppContext);
    const {toggleDownloadReports, downloadReports} = React.useContext(CampaignContext);

    // const unreadCount = downloadReports ? downloadReports.filter(c=> c.sender_id !== userId() && c.is_read === false).length : 0;
    const readyReportToDownloadCount = downloadReports ? downloadReports.filter(c=> c.is_downloaded === false && c.state !== 'failed').length : 0;
    // const readyReportCount = downloadReports ? downloadReports.length : 0;

    const badge = readyReportToDownloadCount > 0 ? <Badge value={readyReportToDownloadCount} severity="danger" />:""

    return (
        <React.Fragment>
            <Button className="p-button-text" onClick={(e) => {toggleDownloadReports(true);}} tooltip={t('report.download')} tooltipOptions={{position: 'bottom'}} >
                &nbsp;<i className="pi pi-cloud-download p-overlay-badge" >{badge}</i>
            </Button>
        </React.Fragment>
    );
};
export default DownloadReport;
