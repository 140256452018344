import React, {useEffect, useState} from "react";


import {Panel} from "primereact/panel";
import {AppContext} from "../../HomePage";
import {DashboardService} from "../../service/DashboardService";
import {CampaignContext} from "../../App";
import {isCampaignManufacturer} from "../../utils/Utils";


const OverviewCompetitor = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);


    const [loading, setLoading] = useState(false);
    const [loadingTop10SellersViolatingMRP, setLoadingTop10SellersViolatingMRP] = useState(false);
    const [competitors, setCompetitors] = useState([]);
    const [cheapestCompetitors, setCheapestCompetitors] = useState([]);
    const [top10CheaperSellers, setTop10CheaperSellers] = useState([]);
    const [top10SellersViolatingMRP, setTop10SellersViolatingMRP] = useState([]);
    const dashboardService = new DashboardService();

    useEffect ( ()=>{
        setLoading(true);
        dashboardService.getTop10CheaperSellers(selectedCampaign.id).then(data=>{
            setTop10CheaperSellers(data.map((website, index) => {
                return (
                    <li key={index}>
                        <button className="p-link" onClick={() => {window.location = '#/report/filtered_seller_price_comparison?competitorNames=' + website.name + ';';}}>
                            <span className="count red">{website.total}</span>
                            <span className="title">{website.name}</span>
                        </button>
                    </li>
                );
            }));

            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });

        if (isCampaignManufacturer(selectedCampaign)) {
            setLoadingTop10SellersViolatingMRP(true)
            dashboardService.getTopSellersViolatingMRP(selectedCampaign.id).then(data=>{
                setTop10SellersViolatingMRP(data.map((website, index) => {
                    return (
                        <li key={index}>
                            <button className="p-link" onClick={() => {window.location = '#/report/filtered_seller_price_comparison?competitorNames=' + website.name + ';';}}>
                                <span className="count red">{website.total}</span>
                                <span className="title">{website.name}</span>
                            </button>
                        </li>
                    );
                }));

                setLoadingTop10SellersViolatingMRP(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoadingTop10SellersViolatingMRP(false);
            });
        }
    },[selectedCampaign]);

    useEffect(()=>{

        setCheapestCompetitors(props.overview.cheapest_competitors && props.overview.cheapest_competitors.map((comp, index) => {
            return (
                <li key={index}>
                    <button className="p-link" onClick={() => {window.location = '#/product?cheapestCompetitor=' + comp.name;}}>
                        <span className="count red">{comp.total}</span>
                        <span className="title">{comp.name}</span>
                    </button>
                </li>
            );
        }));

        setCompetitors(props.overview.competitors && props.overview.competitors.map((comp, index) => {
                return (
                    <li key={index}>
                        <button className="p-link" onClick={() => {window.location = '#/report/filtered_seller_price_comparison?competitorNames=' + comp.name + ';';}}>
                            <span className="count blue">{comp.total}</span>
                            <span className="title">{comp.name}</span>
                        </button>
                    </li>
                );
            }));

    }, [props.overview])

    return <React.Fragment>
        <div className="p-col-12 p-lg-12 contacts fixed_list">
        </div>
        {!props.isManufacturer &&
            <div className="p-col-12 p-lg-4 contacts fixed_list">
                <Panel header={t('overview.cheapest_competitors')} style={{height: '100%'}}>
                    <ul className="activity-list">{cheapestCompetitors}</ul>
                </Panel>
            </div>
        }

        <div className="p-col-12 p-lg-4 contacts fixed_list">
            <Panel header={props.isManufacturer ? t('overview.resellers_violates') : t('overview.cheaper_competitors')} style={{height:'100%'}}>
                <ul className="activity-list">{top10CheaperSellers}</ul>
            </Panel>
        </div>

        {props.isManufacturer &&
            <div className="p-col-12 p-lg-4 contacts fixed_list">
                <Panel
                    header={t('overview.resellers_mrp_violates')}
                    style={{height: '100%'}}>
                    <ul className="activity-list">{top10SellersViolatingMRP}</ul>
                </Panel>
            </div>
        }

        <div className="p-col-12 p-lg-4 contacts fixed_list">
            <Panel header={props.isManufacturer ? t('overview.resellers') : t('overview.competitors')} style={{height:'100%'}}>
                <ul className="activity-list">{competitors}</ul>
            </Panel>
        </div>

    </React.Fragment>;
};
export default OverviewCompetitor;
