import React, {useEffect, useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";



import {getKeyValue, subscription, user} from "../../utils/Utils";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import {AppContext} from "../../HomePage";
import {InputText} from "primereact/inputtext";
import {UserService} from "../../service/UserService";
import {scrapy_cloud_projects, usage_types} from "../../utils/Constants";
import {MultiSelect} from "primereact/multiselect";



const JobSchedulingGenericSetting = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const jobPriorities = [{value:0, label:'Lowest'}, {value:1, label:'Low'}, {value:2, label:'Normal'}, {value:3, label:'High'}, {value:4, label:'Highest'}];
    const singularityTypes = [{value:'campaign_based', label:'Campaign Based'}, {value:'user_based', label:'User Based'}, {value:'no_singularity', label:'No Singularity'}];
    const matchingMethods = [{value:'contain_match', label:'Contain Match'}, {value:'exact_match', label:'Exact Match'}];
    const dropShippingCountries = [
        {value:'amazon.ca', label:'Canada'},
        {value:'amazon.de', label:'Germany'},
        {value:'amazon.it', label:'Italy'},
        {value:'amazon.nl', label:'Netherlands'},
        {value:'amazon.sg', label:'Singapore'},
        {value:'amazon.com.tr', label:'Turkey'},
        {value:'amazon.co.uk', label:'United Kingdom'},
        {value:'amazon.ae', label:'UAE'},
        {value:'amazon.com/', label:'USA'}
    ];

    const {campaign} = props;
    const [autoNewProductAddition, setAutoNewProductAddition] = useState(campaign.settings &&
    campaign.settings.auto_new_product_addition !== null &&
    campaign.settings.auto_new_product_addition !== undefined
        ? campaign.settings.auto_new_product_addition : true);
    const [variantImport, setVariantImport] = useState(campaign.settings &&
    campaign.settings.variant_import !== null &&
    campaign.settings.variant_import !== undefined
        ? campaign.settings.variant_import : user().settings?.variant_import);
    const [hepsiburadaApiUsage, setHepsiburadaApiUsage] = useState(campaign.settings &&
    campaign.settings.hepsiburada_api_usage !== null &&
    campaign.settings.hepsiburada_api_usage !== undefined
        ? campaign.settings.hepsiburada_api_usage : false);
    const [stockIncludingForMap, setStockIncludingForMap] = useState(campaign.settings &&
    campaign.settings.stock_including_for_map !== null &&
    campaign.settings.stock_including_for_map !== undefined
        ? campaign.settings.stock_including_for_map : true);
    const [onlyNewViolationsNotification, setOnlyNewViolationsNotification] = useState(campaign.settings &&
    campaign.settings.only_new_violations_notification !== null &&
    campaign.settings.only_new_violations_notification !== undefined
        ? campaign.settings.only_new_violations_notification : false);
    const [priceCheckForAlsoUnavailableUrls, setPriceCheckForAlsoUnavailableUrls] = useState(campaign.settings &&
    campaign.settings.price_check_for_also_unavailable_urls !== null &&
    campaign.settings.price_check_for_also_unavailable_urls !== undefined
        ? campaign.settings.price_check_for_also_unavailable_urls : false);
    const [matchProductsFromCatalog, setMatchProductsFromCatalog] = useState(campaign.settings &&
    campaign.settings.match_products_from_catalog !== null &&
    campaign.settings.match_products_from_catalog !== undefined
        ? campaign.settings.match_products_from_catalog : false);
    const [showPriceViolationTab, setShowPriceViolationTab] = useState(campaign.settings &&
    campaign.settings.show_price_violation_tab !== null &&
    campaign.settings.show_price_violation_tab !== undefined
        ? campaign.settings.show_price_violation_tab : false);
    const [amazonShippingPriceIncluded, setAmazonShippingPriceIncluded] = useState(campaign.settings &&
    campaign.settings.amazon_shipping_price_included !== null &&
    campaign.settings.amazon_shipping_price_included !== undefined
        ? campaign.settings.amazon_shipping_price_included : false);
    const [marketPlace, setMarketPlace] = useState(campaign.settings &&
    campaign.settings.market_place !== null &&
    campaign.settings.market_place !== undefined
        ? campaign.settings.market_place : false);
    const [violationStrategyScheduleEnabled, setViolationStrategyScheduleEnabled] = useState(campaign.settings &&
    campaign.settings.violation_strategy_schedule_enabled !== null &&
    campaign.settings.violation_strategy_schedule_enabled !== undefined
        ? campaign.settings.violation_strategy_schedule_enabled : false);
    const [sendProductData, setSendProductData] = useState(campaign.settings &&
    campaign.settings.send_product_data !== null &&
    campaign.settings.send_product_data !== undefined
        ? campaign.settings.send_product_data : false);
    const [xmlSourceAllCampaigns, setXmlSourceAllCampaigns] = useState(campaign.settings &&
    campaign.settings.xml_source_all_campaigns !== null &&
    campaign.settings.xml_source_all_campaigns !== undefined
        ? campaign.settings.xml_source_all_campaigns : false);
    const [scapePriceInCart, setScapePriceInCart] = useState(campaign.settings &&
    campaign.settings.scape_price_in_cart !== null &&
    campaign.settings.scape_price_in_cart !== undefined
        ? campaign.settings.scape_price_in_cart : false);
    const [shippingPricesHandledSeparately, setShippingPricesHandledSeparately] = useState(campaign.settings &&
    campaign.settings.shipping_prices_handled_separately !== null &&
    campaign.settings.shipping_prices_handled_separately !== undefined
        ? campaign.settings.shipping_prices_handled_separately : false);
    const [loadAllReviewsAgain, setLoadAllReviewsAgain] = useState(campaign.settings &&
    campaign.settings.load_all_reviews_again !== null &&
    campaign.settings.load_all_reviews_again !== undefined
        ? campaign.settings.load_all_reviews_again : false);
    const [newSellerAutomaticallyActivated, setNewSellerAutomaticallyActivated] = useState(campaign.settings &&
    campaign.settings.new_seller_automatically_activated !== null &&
    campaign.settings.new_seller_automatically_activated !== undefined
        ? campaign.settings.new_seller_automatically_activated : true);
    const [rerunFailedJob, setRerunFailedJob] = useState(campaign.settings &&
    campaign.settings.rerun_failed_job !== null &&
    campaign.settings.rerun_failed_job !== undefined
        ? campaign.settings.rerun_failed_job : false);
    const [ownerMap, setOwnerMap] = useState(campaign.settings &&
    campaign.settings.owner_map !== null &&
    campaign.settings.owner_map !== undefined
        ? campaign.settings.owner_map : false);
    const [jobUnits, setJobUnits] = useState(campaign.settings &&
    campaign.settings.job_units !== null &&
    campaign.settings.job_units !== undefined
        ? campaign.settings.job_units : 1);
    const [dividedJobUnits, setDividedJobUnits] = useState(campaign.settings &&
    campaign.settings.divided_job_units !== null &&
    campaign.settings.divided_job_units !== undefined
        ? campaign.settings.divided_job_units : 1);
    const [jobPriority, setJobPriority] = useState(campaign.settings &&
    campaign.settings.job_priority !== null &&
    campaign.settings.job_priority !== undefined
        ? campaign.settings.job_priority : 2);
    const [dailyPriceCheckCount, setDailyPriceCheckCount] = useState(campaign.settings &&
    campaign.settings.daily_price_check_count !== null &&
    campaign.settings.daily_price_check_count !== undefined
        ? campaign.settings.daily_price_check_count : subscription().daily_price_check_count);
    const [usageType, setUsageType] = useState(campaign.settings &&
    campaign.settings.usage_type !== null &&
    campaign.settings.usage_type !== undefined
        ? campaign.settings.usage_type : null);
    const [singularityType, setSingularityType] = useState(campaign.settings &&
    campaign.settings.singularity_type !== null &&
    campaign.settings.singularity_type !== undefined
        ? campaign.settings.singularity_type : 'campaign_based');
    const [matchingMethod, setMatchingMethod] = useState(campaign.settings &&
    campaign.settings.matching_method !== null &&
    campaign.settings.matching_method !== undefined
        ? campaign.settings.matching_method : 'contain_match');
    const [projectUsedForProductImport, setProjectUsedForProductImport] = useState(campaign.settings &&
    campaign.settings.project_used_for_product_import !== null &&
    campaign.settings.project_used_for_product_import !== undefined
        ? campaign.settings.project_used_for_product_import : scrapy_cloud_projects[0].value);
    const [projectsUsedForPriceUpdate, setProjectsUsedForPriceUpdate] = useState(campaign.settings &&
    campaign.settings.projects_used_for_price_update !== null &&
    campaign.settings.projects_used_for_price_update !== undefined
        ? campaign.settings.projects_used_for_price_update : [scrapy_cloud_projects[0].value]);
    const [dropShippingCountry, setDropShippingCountry] = useState(campaign.settings &&
    campaign.settings.drop_shipping_country !== null &&
    campaign.settings.drop_shipping_country !== undefined
        ? campaign.settings.drop_shipping_country : null);
    const [dropShippingCommissionRate, setDropShippingCommissionRate] = useState(campaign.settings &&
    campaign.settings.drop_shipping_commission_rate !== null &&
    campaign.settings.drop_shipping_commission_rate !== undefined
        ? campaign.settings.drop_shipping_commission_rate : 0);
    const [dropShippingMinRate, setDropShippingMinRate] = useState(campaign.settings &&
    campaign.settings.drop_shipping_min_rate !== null &&
    campaign.settings.drop_shipping_min_rate !== undefined
        ? campaign.settings.drop_shipping_min_rate : 0);
    const [dropShippingMaxRate, setDropShippingMaxRate] = useState(campaign.settings &&
    campaign.settings.drop_shipping_max_rate !== null &&
    campaign.settings.drop_shipping_max_rate !== undefined
        ? campaign.settings.drop_shipping_max_rate : 0);
    const [sellerCode, setSellerCode] = useState(campaign.settings &&
    campaign.settings.seller_code !== null &&
    campaign.settings.seller_code !== undefined
        ? campaign.settings.seller_code : user().settings?.seller_code);
    const [displayedPriceHistoryDays, setDisplayedPriceHistoryDays] = useState(campaign.settings &&
    campaign.settings.displayed_price_history_days !== null &&
    campaign.settings.displayed_price_history_days !== undefined
        ? campaign.settings.displayed_price_history_days : null);
    const [website, setWebsite] = useState(campaign.settings &&
    campaign.settings.website !== null &&
    campaign.settings.website !== undefined
        ? campaign.settings.website : null);
    const [country, setCountry] = useState(campaign.settings &&
    campaign.settings.country !== null &&
    campaign.settings.country !== undefined
        ? campaign.settings.country : null);
    const [brand, setBrand] = useState(campaign.settings &&
    campaign.settings.brand !== null &&
    campaign.settings.brand !== undefined
        ? campaign.settings.brand : null);
    const [websitesForImporting, setWebsitesForImporting] = useState(campaign.settings &&
    campaign.settings.websites_for_importing !== null &&
    campaign.settings.websites_for_importing !== undefined
        ? campaign.settings.websites_for_importing : null);
    const [urlForMapUpdating, setUrlForMapUpdating] = useState(campaign.settings &&
    campaign.settings.url_for_map_updating !== null &&
    campaign.settings.url_for_map_updating !== undefined
        ? campaign.settings.url_for_map_updating : null);
    const [currency, setCurrency] = useState(campaign.settings &&
    campaign.settings.currency !== null &&
    campaign.settings.currency !== undefined
        ? campaign.settings.currency : null);
    const [mrpCoefficient, setMrpCoefficient] = useState(campaign.settings &&
    campaign.settings.mrp_coefficient !== null &&
    campaign.settings.mrp_coefficient !== undefined
        ? campaign.settings.mrp_coefficient : null);
    const [rerunFailedJobNumber, setRerunFailedJobNumber] = useState(campaign.settings &&
    campaign.settings.rerun_failed_job_number !== null &&
    campaign.settings.rerun_failed_job_number !== undefined
        ? campaign.settings.rerun_failed_job_number : 1);
    const [rerunFailedJobDelay, setRerunFailedJobDelay] = useState(campaign.settings &&
    campaign.settings.rerun_failed_job_delay !== null &&
    campaign.settings.rerun_failed_job_delay !== undefined
        ? campaign.settings.rerun_failed_job_delay : 0);
    const [onlyDecreaseIfSaleValue, setOnlyDecreaseIfSaleValue] = useState(campaign?.settings?.only_decrease_if_sale_value);
    const [compareMinPriceWithSaleValue, setCompareMinPriceWithSaleValue] = useState(campaign?.settings?.compare_min_price_with_sale_value);

    const [scrapingJobs, setScrapingJobs] = useState([]);

    const userService = new UserService();
    const [currencies, setCurrencies] = useState([]);
    const [useSaleValue, setUseSaleValue] = useState(campaign.use_sale_value);

    useEffect ( ()=>{
        setScrapingJobs(campaign.settings.scraping_jobs.map(sj=>
            ({ ...sj, hh: parseInt(sj.hour.split(':')[0]) , mm: parseInt(sj.hour.split(':')[1])})
        ));

        if (currencies.length === 0) {
            userService.getCurrencies().then(data=>{
                setCurrencies(data);
            })
        }

        campaign.settings.auto_new_product_addition = autoNewProductAddition
        campaign.settings.variant_import = variantImport
        campaign.settings.hepsiburada_api_usage = hepsiburadaApiUsage
        campaign.settings.stock_including_for_map = stockIncludingForMap
        campaign.settings.only_new_violations_notification = onlyNewViolationsNotification
        campaign.settings.price_check_for_also_unavailable_urls = priceCheckForAlsoUnavailableUrls
        campaign.settings.match_products_from_catalog = matchProductsFromCatalog
        campaign.settings.show_price_violation_tab = showPriceViolationTab
        campaign.settings.amazon_shipping_price_included = amazonShippingPriceIncluded
        campaign.settings.market_place = marketPlace
        campaign.settings.violation_strategy_schedule_enabled = violationStrategyScheduleEnabled
        campaign.settings.send_product_data = sendProductData
        campaign.settings.xml_source_all_campaigns = xmlSourceAllCampaigns
        campaign.settings.scape_price_in_cart = scapePriceInCart
        campaign.settings.shipping_prices_handled_separately = shippingPricesHandledSeparately
        campaign.settings.load_all_reviews_again = loadAllReviewsAgain
        campaign.settings.new_seller_automatically_activated = newSellerAutomaticallyActivated
        campaign.settings.rerun_failed_job = rerunFailedJob
        campaign.settings.owner_map = ownerMap
        campaign.settings.job_units = jobUnits
        campaign.settings.divided_job_units = dividedJobUnits
        campaign.settings.job_priority = jobPriority
        campaign.settings.daily_price_check_count = dailyPriceCheckCount
        campaign.settings.usage_type = usageType
        campaign.settings.singularity_type = singularityType
        campaign.settings.matching_method = matchingMethod
        campaign.settings.project_used_for_product_import = projectUsedForProductImport
        campaign.settings.projects_used_for_price_update = projectsUsedForPriceUpdate
        campaign.settings.drop_shipping_country = dropShippingCountry
        campaign.settings.drop_shipping_commission_rate = dropShippingCommissionRate
        campaign.settings.drop_shipping_min_rate = dropShippingMinRate
        campaign.settings.drop_shipping_max_rate = dropShippingMaxRate
        campaign.settings.seller_code = sellerCode
        campaign.settings.displayed_price_history_days = displayedPriceHistoryDays
        campaign.settings.mrp_coefficient = mrpCoefficient
        campaign.settings.rerun_failed_job_number = rerunFailedJobNumber
        campaign.settings.rerun_failed_job_delay = rerunFailedJobDelay
        campaign.settings.only_decrease_if_sale_value = onlyDecreaseIfSaleValue
        campaign.settings.compare_min_price_with_sale_value = compareMinPriceWithSaleValue
        campaign.settings.website = website
        campaign.settings.country = country
        campaign.settings.brand = brand
        campaign.settings.websites_for_importing = websitesForImporting
        campaign.settings.url_for_map_updating = urlForMapUpdating
        campaign.settings.currency = currency

    },[]);

    const hourEditor = (props) => {

        return <InputNumber value={props.rowData.hh} style={{width:"80px"}} onValueChange={(e)=>{
                let sjs = [...props.value];
                sjs[props.rowIndex][props.field] = getKeyValue(e).value;
                campaign.settings.scraping_jobs = [...sjs.map(sj => ({hour: sj.hh.toString().padStart(2,'0') + ":" + sj.mm.toString().padStart(2,'0'), auto_price_update: sj.auto_price_update,
                notify: sj.notify, auto_suggested_price_update: sj.auto_suggested_price_update, headless_browser_enabled: sj.headless_browser_enabled }))];
                setScrapingJobs(sjs);
            }} min={0} max={23} showButtons />;
    };

    const minuteEditor = (props) => {

        return <InputNumber value={props.rowData.mm} style={{width:"80px"}} onValueChange={(e)=>{
                let sjs = [...props.value];
                sjs[props.rowIndex][props.field] = getKeyValue(e).value;
                campaign.settings.scraping_jobs = [...sjs.map(sj => ({hour: sj.hh.toString().padStart(2,'0') + ":" + sj.mm.toString().padStart(2,'0'), auto_price_update: sj.auto_price_update,
                notify: sj.notify, auto_suggested_price_update: sj.auto_suggested_price_update, headless_browser_enabled: sj.headless_browser_enabled }))];
                setScrapingJobs([...sjs]);
            }} min={0} max={59} step={10} showButtons />;
    };

    const autoPriceUpdateEditor = (props) => {
        return <Checkbox inputId="auto_price_update" name="auto_price_update" value={props.rowData.auto_price_update} checked={props.rowData.auto_price_update} onChange={(e)=>{
            let sjs = [...props.value];
            sjs[props.rowIndex][props.field] = getKeyValue(e).value;
            campaign.settings.scraping_jobs = [...sjs.map(sj => ({hour: sj.hh.toString().padStart(2,'0') + ":" + sj.mm.toString().padStart(2,'0'), auto_price_update: sj.auto_price_update,
                notify: sj.notify, auto_suggested_price_update: sj.auto_suggested_price_update, headless_browser_enabled: sj.headless_browser_enabled }))];
            setScrapingJobs([...sjs]);
        }}/>;
    };

    const notifyEditor = (props) => {
        return <Checkbox inputId="notify" name="notify" value={props.rowData.notify} checked={props.rowData.notify} onChange={(e)=>{
            let sjs = [...props.value];
            sjs[props.rowIndex][props.field] = getKeyValue(e).value;
            campaign.settings.scraping_jobs = [...sjs.map(sj => ({hour: sj.hh.toString().padStart(2,'0') + ":" + sj.mm.toString().padStart(2,'0'), auto_price_update: sj.auto_price_update,
                notify: sj.notify, auto_suggested_price_update: sj.auto_suggested_price_update, headless_browser_enabled: sj.headless_browser_enabled }))];
            setScrapingJobs(sjs);
        }}/>;
    };

    const autoSuggestedPriceUpdateEditor = (props) => {
        return <Checkbox inputId="auto_suggested_price_update" name="auto_suggested_price_update" value={props.rowData.auto_suggested_price_update} checked={props.rowData.auto_suggested_price_update} onChange={(e)=>{
            let sjs = [...props.value];
            sjs[props.rowIndex][props.field] = getKeyValue(e).value;
            campaign.settings.scraping_jobs = [...sjs.map(sj => ({hour: sj.hh.toString().padStart(2,'0') + ":" + sj.mm.toString().padStart(2,'0'), auto_price_update: sj.auto_price_update,
                notify: sj.notify, auto_suggested_price_update: sj.auto_suggested_price_update, headless_browser_enabled: sj.headless_browser_enabled }))];
            setScrapingJobs([...sjs]);
        }}/>;
    };

    const headlessBrowserEnabledEditor = (props) => {
        return <Checkbox inputId="headless_browser_enabled" name="headless_browser_enabled" value={props.rowData.headless_browser_enabled} checked={props.rowData.headless_browser_enabled} onChange={(e)=>{
            let sjs = [...props.value];
            sjs[props.rowIndex][props.field] = getKeyValue(e).value;
            campaign.settings.scraping_jobs = [...sjs.map(sj => ({hour: sj.hh.toString().padStart(2,'0') + ":" + sj.mm.toString().padStart(2,'0'), auto_price_update: sj.auto_price_update,
                notify: sj.notify, auto_suggested_price_update: sj.auto_suggested_price_update, headless_browser_enabled: sj.headless_browser_enabled }))];
            setScrapingJobs([...sjs]);
        }}/>;
    };

    const autoPriceUpdateTemplate = (rowData) => {
        return rowData.auto_price_update ? t('trueFalse.true') : t('trueFalse.false');
    };

    const notifyTemplate = (rowData) => {
        return rowData.notify ? t('trueFalse.true') : t('trueFalse.false');
    };

    const autoSuggestedPriceUpdateTemplate = (rowData) => {
        return rowData.auto_suggested_price_update ? t('trueFalse.true') : t('trueFalse.false');
    };

    const headlessBrowserEnabledTemplate = (rowData) => {
        return rowData.headless_browser_enabled ? t('trueFalse.true') : t('trueFalse.false');
    };

    return (
        <React.Fragment>
            <div className="p-grid p-fluid p-shadow-3">
                <div className="p-col-12 p-xl-12">
                    {scrapingJobs.length > 0 && <DataTable value={scrapingJobs}
                               responsive={true} sortField="hour" sortOrder={1}>
                        <Column field="hh" header={t('job.hour')} editor={hourEditor} />
                        <Column field="mm" header={t('job.minute')} editor={minuteEditor} />
                        <Column field="auto_price_update" header={t('job.auto_price_update')} body={autoPriceUpdateTemplate} editor={autoPriceUpdateEditor}/>
                        <Column field="notify" header={t('job.notify')} body={notifyTemplate} editor={notifyEditor}/>
                        <Column field="auto_suggested_price_update" header={t('job.auto_suggested_price_update')} body={autoSuggestedPriceUpdateTemplate} editor={autoSuggestedPriceUpdateEditor}/>
                        <Column field="headless_browser_enabled" header={t('job.headless_browser_enabled')} body={headlessBrowserEnabledTemplate} editor={headlessBrowserEnabledEditor}/>
                    </DataTable>}
                </div>
                <div className="p-col-12 p-xl-3">
                        <span className="p-float-label">
                            <InputNumber id="job_units" name="job_units" value={jobUnits} onValueChange={(e)=>{campaign.settings.job_units = getKeyValue(e).value; setJobUnits(getKeyValue(e).value);}}/>
                            <label htmlFor="job_units" >{t('campaign.job_units')}</label>
                         </span>
                </div>

                <div className="p-col-12 p-xl-3">
                        <span className="p-float-label">
                            <Dropdown id="job_priority" name="job_priority" value={jobPriority} options={jobPriorities} onChange={(e)=>{campaign.settings.job_priority = getKeyValue(e).value; setJobPriority(getKeyValue(e).value);}}/>
                            <label htmlFor="job_priority">{t('campaign.job_priority')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-3">
                        <span className="p-float-label">
                            <InputNumber id="divided_job_units" name="divided_job_units" value={dividedJobUnits} onValueChange={(e)=>{campaign.settings.divided_job_units = getKeyValue(e).value; setDividedJobUnits(getKeyValue(e).value);}}/>
                            <label htmlFor="divided_job_units">{t('campaign.divided_job_units')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-3">
                        <span className="p-float-label">
                            <InputNumber id="daily_price_check_count" name="daily_price_check_count" value={dailyPriceCheckCount} onValueChange={(e)=>{campaign.settings.daily_price_check_count = getKeyValue(e).value; setDailyPriceCheckCount(getKeyValue(e).value);}}/>
                            <label htmlFor="daily_price_check_count">{t('subscription.daily_price_check_count')}</label>
                        </span>
                </div>
        </div>
            <br></br>
            <div className="p-grid p-fluid p-shadow-3">
                <div className="p-col-12 p-xl-12"> </div>
                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <Dropdown id="usage_type" name="usage_type" value={usageType} options={usage_types(t)} onChange={(e)=>{campaign.settings.usage_type = getKeyValue(e).value; setUsageType(getKeyValue(e).value);}}/>
                            <label htmlFor="usage_type">{t('campaign.usage_type')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <Dropdown id="project_used_for_product_import" name="project_used_for_product_import" value={projectUsedForProductImport} options={scrapy_cloud_projects} onChange={(e)=>{campaign.settings.project_used_for_product_import = getKeyValue(e).value; setProjectUsedForProductImport(getKeyValue(e).value);}} />
                            <label htmlFor="project_used_for_product_import">{t('campaign.project_used_for_product_import')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <MultiSelect name="projects_used_for_price_update" value={projectsUsedForPriceUpdate} options={scrapy_cloud_projects} onChange={(e) => {campaign.settings.projects_used_for_price_update = e.value; setProjectsUsedForPriceUpdate(e.value)}}/>
                            <label htmlFor="projects_used_for_price_update">{t('campaign.projects_used_for_price_update')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <Dropdown id="singularity_type" name="singularity_type" value={singularityType} options={singularityTypes} onChange={(e)=>{campaign.settings.singularity_type = getKeyValue(e).value; setSingularityType(getKeyValue(e).value);}} />
                            <label htmlFor="singularity_type">{t('campaign.singularity_type')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <Dropdown id="matching_method" name="matching_method" value={matchingMethod} options={matchingMethods} onChange={(e)=>{campaign.settings.matching_method = getKeyValue(e).value; setMatchingMethod(getKeyValue(e).value);}} />
                            <label htmlFor="matching_method">{t('campaign.matching_method')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <InputText id="seller_code" name="seller_code" value={sellerCode} onChange={(e)=>{campaign.settings.seller_code = e.target.value; setSellerCode(e.target.value);}}/>
                            <label htmlFor="seller_code">{t('campaign.seller_code')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <InputNumber id="displayed_price_history_days" name="displayed_price_history_days" value={displayedPriceHistoryDays} onValueChange={(e)=>{campaign.settings.displayed_price_history_days = getKeyValue(e).value; setDisplayedPriceHistoryDays(getKeyValue(e).value);}}/>
                            <label htmlFor="displayed_price_history_days">{t('campaign.displayed_price_history_days')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <InputText id="website" name="website" value={website} onChange={(e)=>{campaign.settings.website = e.target.value; setWebsite(e.target.value);}}/>
                            <label htmlFor="website">{t('campaign.website')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <Dropdown id="currency" name="currency" value={currency} options={currencies} filter showClear onChange={(e)=>{campaign.settings.currency = getKeyValue(e).value; setCurrency(getKeyValue(e).value);}} />
                            <label htmlFor="currency">{t('campaign.currency')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <InputNumber id="mrp_coefficient" name="mrp_coefficient" value={mrpCoefficient}  mode="decimal" minFractionDigits={2}
                                         onValueChange={(e)=>{campaign.settings.mrp_coefficient = getKeyValue(e).value; setMrpCoefficient(getKeyValue(e).value);}}/>
                            <label htmlFor="mrp_coefficient">{t('campaign.mrp_coefficient')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <InputText id="country" name="country" value={country} onChange={(e)=>{campaign.settings.country = e.target.value; setCountry(e.target.value);}}/>
                            <label htmlFor="country">{t('user.country')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                    <span className="p-float-label">
                            <InputText id="brand" name="brand" value={brand} onChange={(e)=>{campaign.settings.brand = e.target.value; setBrand(e.target.value);}}/>
                            <label htmlFor="brand">{t('product.brand')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <InputNumber id="rerun_failed_job_number" name="rerun_failed_job_number" value={rerunFailedJobNumber}  mode="decimal" minFractionDigits={0}
                                         onValueChange={(e)=>{campaign.settings.rerun_failed_job_number = getKeyValue(e).value; setRerunFailedJobNumber(getKeyValue(e).value);}}/>
                            <label htmlFor="rerun_failed_job_number">{t('campaign.rerun_failed_job_number')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-6">
                        <span className="p-float-label">
                            <InputNumber id="rerun_failed_job_delay" name="rerun_failed_job_delay" value={rerunFailedJobDelay}  mode="decimal" minFractionDigits={0}
                                         onValueChange={(e)=>{campaign.settings.rerun_failed_job_delay = getKeyValue(e).value; setRerunFailedJobDelay(getKeyValue(e).value);}}/>
                            <label htmlFor="rerun_failed_job_delay">{t('campaign.rerun_failed_job_delay')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-12">
                    <span className="p-float-label">
                            <InputText id="websites_for_importing" name="websites_for_importing" value={websitesForImporting} onChange={(e)=>{campaign.settings.websites_for_importing = e.target.value; setWebsitesForImporting(e.target.value);}}/>
                            <label htmlFor="websites_for_importing">{t('campaign.websites_for_importing')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-12">
                    <span className="p-float-label">
                            <InputText id="url_for_map_updating" name="url_for_map_updating" value={urlForMapUpdating} onChange={(e)=>{campaign.settings.url_for_map_updating = e.target.value; setUrlForMapUpdating(e.target.value);}}/>
                            <label htmlFor="url_for_map_updating">{t('campaign.url_for_map_updating')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-6">
                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="auto_new_product_addition" name="auto_new_product_addition"
                                  style={{marginLeft: "0.5em"}}
                                  value={autoNewProductAddition}
                                  checked={autoNewProductAddition}
                                  onChange={(e)=> {
                                      campaign.settings.auto_new_product_addition = getKeyValue(e).value;
                                      setAutoNewProductAddition(getKeyValue(e).value);}}
                        />
                        <label htmlFor="auto_new_product_addition" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.auto_new_product_addition')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="variantImport" name="variantImport"
                                  style={{marginLeft: "0.5em"}}
                                  value={variantImport}
                                  checked={variantImport}
                                  onChange={(e)=> {
                                      campaign.settings.variant_import = getKeyValue(e).value;
                                      setVariantImport(getKeyValue(e).value);}}
                        />
                        <label htmlFor="variantImport" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.variant_import')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="hepsiburada_api_usage" name="hepsiburada_api_usage"
                                  style={{marginLeft: "0.5em"}}
                                  value={hepsiburadaApiUsage}
                                  checked={hepsiburadaApiUsage}
                                  onChange={(e)=> {campaign.settings.hepsiburada_api_usage = getKeyValue(e).value; setHepsiburadaApiUsage(getKeyValue(e).value);}}
                        />
                        <label htmlFor="hepsiburada_api_usage" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.hepsiburada_api_usage')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="stock_including_for_map" name="stock_including_for_map"
                                  style={{marginLeft: "0.5em"}}
                                  value={stockIncludingForMap}
                                  checked={stockIncludingForMap}
                                  onChange={(e)=> {campaign.settings.stock_including_for_map = getKeyValue(e).value; setStockIncludingForMap(getKeyValue(e).value);}}
                        />
                        <label htmlFor="stock_including_for_map" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.stock_including_for_map')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="only_new_violations_notification" name="only_new_violations_notification"
                                  style={{marginLeft: "0.5em"}}
                                  value={onlyNewViolationsNotification}
                                  checked={onlyNewViolationsNotification}
                                  onChange={(e)=> {campaign.settings.only_new_violations_notification = getKeyValue(e).value; setOnlyNewViolationsNotification(getKeyValue(e).value);}}
                        />
                        <label htmlFor="only_new_violations_notification" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.only_new_violations_notification')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="price_check_for_also_unavailable_urls" name="price_check_for_also_unavailable_urls"
                                  style={{marginLeft: "0.5em"}}
                                  value={priceCheckForAlsoUnavailableUrls}
                                  checked={priceCheckForAlsoUnavailableUrls}
                                  onChange={(e)=> {campaign.settings.price_check_for_also_unavailable_urls = getKeyValue(e).value; setPriceCheckForAlsoUnavailableUrls(getKeyValue(e).value);}}
                        />
                        <label htmlFor="price_check_for_also_unavailable_urls" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.price_check_for_also_unavailable_urls')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="match_products_from_catalog" name="match_products_from_catalog"
                                  style={{marginLeft: "0.5em"}}
                                  value={matchProductsFromCatalog}
                                  checked={matchProductsFromCatalog}
                                  onChange={(e)=> {campaign.settings.match_products_from_catalog = getKeyValue(e).value; setMatchProductsFromCatalog(getKeyValue(e).value);}}
                        />
                        <label htmlFor="match_products_from_catalog" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.match_products_from_catalog')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="show_price_violation_tab" name="show_price_violation_tab"
                                  style={{marginLeft: "0.5em"}}
                                  value={showPriceViolationTab}
                                  checked={showPriceViolationTab}
                                  onChange={(e)=> {campaign.settings.show_price_violation_tab = getKeyValue(e).value; setShowPriceViolationTab(getKeyValue(e).value);}}
                        />
                        <label htmlFor="show_price_violation_tab" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.show_price_violation_tab')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="send_product_data" name="send_product_data"
                                  style={{marginLeft: "0.5em"}}
                                  value={sendProductData}
                                  checked={sendProductData}
                                  onChange={(e)=> {campaign.settings.send_product_data = getKeyValue(e).value; setSendProductData(getKeyValue(e).value);}}
                        />
                        <label htmlFor="send_product_data" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.send_product_data')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="xml_source_all_campaigns" name="xml_source_all_campaigns"
                                  style={{marginLeft: "0.5em"}}
                                  value={xmlSourceAllCampaigns}
                                  checked={xmlSourceAllCampaigns}
                                  onChange={(e)=> {campaign.settings.xml_source_all_campaigns = getKeyValue(e).value; setXmlSourceAllCampaigns(getKeyValue(e).value);}}
                        />
                        <label htmlFor="xml_source_all_campaigns" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.xml_source_all_campaigns')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="scape_price_in_cart" name="scape_price_in_cart"
                                  style={{marginLeft: "0.5em"}}
                                  value={scapePriceInCart}
                                  checked={scapePriceInCart}
                                  onChange={(e)=> {campaign.settings.scape_price_in_cart = getKeyValue(e).value; setScapePriceInCart(getKeyValue(e).value);}}
                        />
                        <label htmlFor="scape_price_in_cart" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.scape_price_in_cart')}</label>
                    </div>


                </div>

                <div className="p-col-12 p-xl-6">
                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="owner_map" name="owner_map"
                                  style={{marginLeft: "0.5em"}}
                                  value={ownerMap}
                                  checked={ownerMap}
                                  onChange={(e)=> {campaign.settings.owner_map = getKeyValue(e).value; setOwnerMap(getKeyValue(e).value);}}
                        />
                        <label htmlFor="owner_map" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.owner_map')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="rerun_failed_job" name="rerun_failed_job"
                                  style={{marginLeft: "0.5em"}}
                                  value={rerunFailedJob}
                                  checked={rerunFailedJob}
                                  onChange={(e)=> {campaign.settings.rerun_failed_job = getKeyValue(e).value; setRerunFailedJob(getKeyValue(e).value);}}
                        />
                        <label htmlFor="rerun_failed_job" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.rerun_failed_job')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="use_sale_value" name="use_sale_value"
                                  style={{marginLeft: "0.5em"}}
                                  value={useSaleValue}
                                  checked={useSaleValue}
                                  onChange={(e)=> {campaign.use_sale_value = getKeyValue(e).value; setUseSaleValue(getKeyValue(e).value);}}
                        />
                        <label htmlFor="use_sale_value" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('reprice.sale_value')}</label>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="only_decrease_if_sale_value" name="only_decrease_if_sale_value"
                                  style={{marginLeft: "0.5em"}}
                                  value={onlyDecreaseIfSaleValue}
                                  checked={onlyDecreaseIfSaleValue}
                                  onChange={(e)=> {campaign.settings.only_decrease_if_sale_value = getKeyValue(e).value; setOnlyDecreaseIfSaleValue(getKeyValue(e).value);}}
                        />
                        <label htmlFor="only_decrease_if_sale_value" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('reprice.only_decrease_if_sale_value')}</label>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="compare_min_price_with_sale_value" name="compare_min_price_with_sale_value"
                                  style={{marginLeft: "0.5em"}}
                                  value={compareMinPriceWithSaleValue}
                                  checked={compareMinPriceWithSaleValue}
                                  onChange={(e)=> {campaign.settings.compare_min_price_with_sale_value = getKeyValue(e).value; setCompareMinPriceWithSaleValue(getKeyValue(e).value);}}
                        />
                        <label htmlFor="compare_min_price_with_sale_value" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('reprice.compare_min_price_with_sale_value')}</label>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="amazon_shipping_price_included" name="amazon_shipping_price_included"
                                  style={{marginLeft: "0.5em"}}
                                  value={amazonShippingPriceIncluded}
                                  checked={amazonShippingPriceIncluded}
                                  onChange={(e)=> {campaign.settings.amazon_shipping_price_included = getKeyValue(e).value; setAmazonShippingPriceIncluded(getKeyValue(e).value);}}
                        />
                        <label htmlFor="amazon_shipping_price_included" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.amazon_shipping_price_included')}</label>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="market_place" name="market_place"
                                  style={{marginLeft: "0.5em"}}
                                  value={marketPlace}
                                  checked={marketPlace}
                                  onChange={(e)=> {campaign.settings.market_place = getKeyValue(e).value; setMarketPlace(getKeyValue(e).value);}}
                        />
                        <label htmlFor="market_place" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.market_place')}</label>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="violation_strategy_schedule_enabled" name="violation_strategy_schedule_enabled"
                                  style={{marginLeft: "0.5em"}}
                                  value={violationStrategyScheduleEnabled}
                                  checked={violationStrategyScheduleEnabled}
                                  onChange={(e)=> {campaign.settings.violation_strategy_schedule_enabled = getKeyValue(e).value; setViolationStrategyScheduleEnabled(getKeyValue(e).value);}}
                        />
                        <label htmlFor="violation_strategy_schedule_enabled" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.violation_strategy_schedule_enabled')}</label>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="new_seller_automatically_activated" name="new_seller_automatically_activated"
                                  style={{marginLeft: "0.5em"}}
                                  value={newSellerAutomaticallyActivated}
                                  checked={newSellerAutomaticallyActivated}
                                  onChange={(e)=> {campaign.settings.new_seller_automatically_activated = getKeyValue(e).value; setNewSellerAutomaticallyActivated(getKeyValue(e).value);}}
                        />
                        <label htmlFor="new_seller_automatically_activated" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.new_seller_automatically_activated')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="shipping_prices_handled_separately" name="shipping_prices_handled_separately"
                                  style={{marginLeft: "0.5em"}}
                                  value={shippingPricesHandledSeparately}
                                  checked={shippingPricesHandledSeparately}
                                  onChange={(e)=> {campaign.settings.shipping_prices_handled_separately = getKeyValue(e).value; setShippingPricesHandledSeparately(getKeyValue(e).value);}}
                        />
                        <label htmlFor="shipping_prices_handled_separately" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.shipping_prices_handled_separately')}</label>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <Checkbox inputId="load_all_reviews_again" name="load_all_reviews_again"
                                  style={{marginLeft: "0.5em"}}
                                  value={loadAllReviewsAgain}
                                  checked={loadAllReviewsAgain}
                                  onChange={(e)=> {campaign.settings.load_all_reviews_again = getKeyValue(e).value; setLoadAllReviewsAgain(getKeyValue(e).value);}}
                        />
                        <label htmlFor="load_all_reviews_again" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.load_all_reviews_again')}</label>
                    </div>

                </div>

        </div>
            <br></br>
            <div className="p-grid p-fluid p-shadow-3">
                <div className="p-col-12 p-xl-12"> </div>
                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <Dropdown id="drop_shipping_country" name="drop_shipping_country" value={dropShippingCountry} options={dropShippingCountries} onChange={(e)=>{campaign.settings.drop_shipping_country = getKeyValue(e).value; setDropShippingCountry(getKeyValue(e).value);}} />
                            <label htmlFor="drop_shipping_country">{t('campaign.drop_shipping_country')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <InputNumber id="drop_shipping_commission_rate" name="drop_shipping_commission_rate" value={dropShippingCommissionRate} onValueChange={(e)=>{campaign.settings.drop_shipping_commission_rate = getKeyValue(e).value; setDropShippingCommissionRate(getKeyValue(e).value);}}/>
                            <label htmlFor="drop_shipping_commission_rate">{t('campaign.drop_shipping_commission_rate')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <InputNumber id="drop_shipping_min_rate" name="drop_shipping_min_rate" value={dropShippingMinRate} onValueChange={(e)=>{campaign.settings.drop_shipping_min_rate = getKeyValue(e).value; setDropShippingMinRate(getKeyValue(e).value);}}/>
                            <label htmlFor="drop_shipping_min_rate">{t('campaign.drop_shipping_min_rate')}</label>
                        </span>
                </div>

                <div className="p-col-12 p-xl-4">
                        <span className="p-float-label">
                            <InputNumber id="drop_shipping_max_rate" name="drop_shipping_max_rate" value={dropShippingMaxRate} onValueChange={(e)=>{campaign.settings.drop_shipping_max_rate = getKeyValue(e).value; setDropShippingMaxRate(getKeyValue(e).value);}}/>
                            <label htmlFor="drop_shipping_max_rate">{t('campaign.drop_shipping_max_rate')}</label>
                        </span>
                </div>
            </div>
        </React.Fragment>
    );

};
export default (JobSchedulingGenericSetting)
