import React, {useEffect, useState} from "react";

import {exportFile, formatCurrency, getHostName, getKeyValue, getProductDetailLink} from "../../utils/Utils";
import {toDateStringIgnoringTimezone, toDateTimeString} from "../../utils/TimeUtil";


import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {DataView} from "primereact/dataview";
import {Calendar} from "primereact/calendar";


import {ExportService} from "../../service/ExportService";
import {Button} from "primereact/button";
import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {AutoComplete} from "primereact/autocomplete";
import {InputText} from "primereact/inputtext";
import {CampaignContext} from "../../App";

const UnauthorizedSellers = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [violations, setViolations] = useState([]);
    const [date, setDate] = useState(toDateStringIgnoringTimezone(new Date()));

    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);
    const [product_name, setProductName] = useState("");
    const [competitor_name, setCompetitorName] = useState("");
    const [productSuggestions, setProductSuggestions] = useState(null);
    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect ( ()=>{
        search();
    },[selectedCampaign, date]);

    const suggestProducts = (event) => {
        if (violations != null) {
            let results = violations.filter((violation) => {
                return violation.name.toLowerCase().startsWith(event.query.toLowerCase());
            }).map(item => item.name);

            setProductSuggestions(results);
        }
    };

    const search = () => {
        if (selectedCampaign) {
            setLoading(true);
            dashboardService.getUnauthorizedSellers(selectedCampaign.id, date).then(violations=>{
                setViolations(violations);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    }

    const onExport = () => {
        setLoadingExcelExport(true);
        exportService.exportUnauthorizedSellers(selectedCampaign.id, toDateStringIgnoringTimezone(date)).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.unauthorized_sellers') + "_" + toDateStringIgnoringTimezone(date) + ".xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    }

    const previousDate = () => {
        setDate(toDateStringIgnoringTimezone((new Date(date)).setDate((new Date(date)).getDate()-1)));
    }
    const nextDate = () => {
        setDate(toDateStringIgnoringTimezone((new Date(date)).setDate((new Date(date)).getDate()+1)));
    }
    const selectDate = (e) => {
        setDate(toDateStringIgnoringTimezone(e.value));
    }

    const nameTemplate = (rowData) => {
        return<a target="_blank" rel="noopener noreferrer" href={rowData.product_url}>{rowData.name ? rowData.name : getHostName(rowData.product_url) }</a>;
    };

    const priceTemplate = (rowData) => {
        return <span>{formatCurrency(rowData.value, rowData.currency)}</span>;
    };

    const stockTemplate = (rowData) => {
        return <span>{rowData.stock}</span>;
    };

    const dateTemplate = (rowData) => {
        return toDateTimeString(rowData.scraped_time);
    };

    const productTemplate = (rowData) => {

        let states = rowData && rowData.competitor_unauthorized_states ?
            rowData.competitor_unauthorized_states.filter(competitor_unauthorized_state=>
                (competitor_name === "" ||
                    (competitor_unauthorized_state.name && competitor_unauthorized_state.name.toLowerCase().indexOf(competitor_name.toLowerCase()) !== -1) ||
                    (competitor_unauthorized_state.product_url && getHostName(competitor_unauthorized_state.product_url).toLowerCase().indexOf(competitor_name.toLowerCase()) !== -1) ) ): [];


        return states.length ?
            <div className="p-grid p-fluid" style={{border:"1px solid #c8c8c8", margin:"0.5em"}}>
                <div className="p-col-4 p-grid">
                    <div className="p-col-6">
                        <a target="_blank" rel="noopener noreferrer" href={rowData.product_url}>
                            <img referrerPolicy='no-referrer' className="img-fluid" src={rowData.image} alt=""/>
                        </a>
                    </div>
                    <div className="p-col-6">
                        <div className="p-col-12">
                            {getProductDetailLink(rowData.product_app_url.split("id=")[1], rowData.name ? rowData.name : t('scraping_request.product_id'))}
                        </div>
                    </div>
                </div>
                <div className="p-col-8">
                    <DataTable value={states} responsive={true}>
                        <Column body={nameTemplate} header={t('product_detail.competitor')}/>
                        <Column body={priceTemplate} header={t('product_detail.price')}/>
                        <Column body={stockTemplate} header={t('product_detail.stock')}/>
                        <Column body={dateTemplate} header={t('product_detail.date')}/>
                    </DataTable>
                </div>
            </div> : "";
    };

    const header = <div className="p-grid p-fluid">
        <div className="p-col-4">
            <label htmlFor="product_name">{t('product.product_name')}</label>
            <AutoComplete id="product_name" dropdown={true} value={product_name} onChange={(e) => {setProductName(getKeyValue(e).value)}}
                          suggestions={productSuggestions} completeMethod={suggestProducts}/>

        </div>
        <div className="p-col-4">
            <label htmlFor="competitor_name">{t('product_detail.competitor')}</label>
            <InputText id="competitor_name" value={competitor_name} onChange={(e) => {setCompetitorName( getKeyValue(e).value)}}/>
        </div>

    </div>;

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.unauthorized_sellers')}</h3>
                <hr></hr>
            </div>

            <div className="p-col-9">
                <Button tooltip={t('actions.previous')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left"
                        className="p-button-outlined" onClick={previousDate} style={{marginRight:'0.5em'}} />
                <Calendar id="date" name="date" readOnlyInput={true} value={new Date(date)} onChange={selectDate} showIcon={true} dateFormat="yy-mm-dd" style={{marginRight:'0.5em'}} />
                <Button tooltip={t('actions.next')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right"
                        className="p-button-outlined" onClick={nextDate} style={{marginRight:'0.5em'}}/>
                <Button icon="pi pi-file-excel" style={{marginRight:'0.5em'}} className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
            </div>

            <div className="p-col-12">
                {loading ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div> :
                    <DataView value={violations} paginator={true} rows={50} layout="grid"
                              emptyMessage={t('actions.empty_message')}
                              header={header} itemTemplate={productTemplate}/>}
            </div>

        </div>

    </React.Fragment>;
};
export default UnauthorizedSellers;
