import React, {useEffect, useRef} from "react";
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import {Rating} from "primereact/rating";
import {AppContext} from "../HomePage";
import {Tag} from "primereact/tag";
import {Messages} from "primereact/messages";
import { Translation } from 'react-i18next';


export const AuthInfo = (props) => {
    const {t, appName} = React.useContext(AppContext);
    return <div className="p-grid p-align-center p-justify-end p-nogutter p-fluid authInfo">
        <div className="p-col-12">
            <h1>{t('login.auth_info_msg_1', {appName: appName})}</h1>
            {appName === "PriceRest" ?
            <p>{t('login.auth_info_msg_2', {appName: appName})}<br/>{t('login.auth_info_msg_3')}</p> : <div></div>}
        </div>
    </div>;
};

export const Logo = (props) => {
    const {host} = React.useContext(AppContext);
    return <div className="logoContainer">
            <span>
                <img alt="logo" src={ host === "ecomscanner" ? "/assets/layout/images/logo_ecomscanner.png" : "/assets/layout/images/logo_gray.png"} width="250" />
            </span>
        </div>;
};

export const Tooltip = (props) => {
    return <Button className="p-button-outlined p-button-info imageButton" icon={"pi " + (props.icon ? props.icon : "pi-question-circle")} tooltip={props.tooltip} tooltipOptions={{position:"bottom"}} style={{color:"#007ad9"}} />;
};

export const Loading = (props) => {
    return <ProgressSpinner style={{verticalAlign:"sub", width: props.width ? props.width : '50px', height: props.height ? props.height : '50px'}} strokeWidth="4" animationDuration=".5s"/>;
};


export const OverallReviewRating = (rowData) => {
    return <Rating value={rowData.rate} readonly cancel={false} style={{color:'#007ad9'}}/>;
};



export const IndexTemplate = (data, props) => {
    return <Translation>
        {(t, { i18n }) =>
            <div style={{textAlign: 'right', whiteSpace: "nowrap"}}>
                <span className="p-column-title">{t('table_headers.index')}</span>{props.rowIndex + 1}
            </div>
        }
    </Translation>;
};

export const StockTemplate = (stock) => {
    if (stock && stock > 1)
        return <Tag style={{maxWidth: "7em", minWidth: "3em", textAlign: 'center'}} severity="success" value={stock} />;
    else if (stock && stock === 1)
        return <Tag style={{maxWidth: "7em", minWidth: "3em", textAlign: 'center'}} severity="success" icon="pi pi-check"/>;
    else
        return <Tag style={{maxWidth: "7em", minWidth: "3em", textAlign: 'center'}} severity="danger" icon="pi pi-times"/>;
};

export const positionTemplate = (position) => {
    return <Tag style={{maxWidth: "7em", minWidth: "3em", textAlign: 'center'}} severity={position === 1 ? "success" : "danger"} value={position}/>;
};

export const ProductNotFound = () =>{
    const {t} = React.useContext(AppContext);
    const msg = useRef(null);

    useEffect(() => {
        msg.current.show({severity: 'warn', sticky: true, closable: false, content: (
                <div className="p-text-center" style={{width:'100%'}}>
                    <i className="pi pi-ban" style={{'fontSize': '2em'}}></i>
                    <div className="p-m-3" style={{'fontSize': '1.5em'}}>{t('product.not_found')}</div>
                </div>
            )
        });
    }, []);

    return <Messages ref={msg} />;
};

// Hook
export const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
};
