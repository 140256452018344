import React from "react";
import { read, utils, writeFileXLSX } from 'xlsx';
import {Button} from "primereact/button";
import {AppContext} from "../../HomePage";

const JsonToExcel = (props) => {
    const {t, toast} = React.useContext(AppContext);
    const exportFile = () => {
        if (props.data && props.data.length > 0)
        {
            const ws = utils.json_to_sheet(props.data);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, props.fileName +".xlsx");
            toast.current.show({severity: 'info', summary: t('actions.export_completed'), detail: t('message_detail.successful')});
        }
        else
            toast.current.show({severity: 'warn', summary: t('actions.no_data_to_export'), detail: t('message_detail.failed')});
    };

    return <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" tooltip={t('actions.export_to_excel')} style={{marginRight: '.5em'}} onClick={() => exportFile()}/>;
};
export default JsonToExcel;