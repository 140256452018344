import {AuthService} from "./AuthService";

export class ProductMatchingService {

    url = `product_matches/`;

    matchCompetitorsUsingOtherCodes(campaign_id)
    {
        return AuthService.apiPut(this.url + "match_competitors_using_other_codes/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    matchCompetitorsUsingSelectedFields(campaign_id, selectedFields){
        selectedFields = selectedFields.map(item => item.name)
        return AuthService.apiPut(this.url + "match_competitors_using_selected_fields/", {"campaign_id": campaign_id, "selected_fields": selectedFields}).then(res => res.data.data);
    }
}
