import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";
import {TabPanel, TabView} from "primereact/tabview";

import {AuthService} from "../../service/AuthService";
import {LanguageService} from "../../service/LanguageService";
import {AccountService} from "../../service/AccountService";
import {isMainProfile, isSubProfile, isUserManaged, user, userId} from "../../utils/Utils";
import {getTimeZoneDisplay} from "../../utils/TimeUtil";

import Subscription from "./Subscription";
import BillingHistory from "./BillingHistory";

import moment from "moment";
import "moment-timezone";
import queryString from "query-string";
import Notification from "./Notification";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import {AppContext} from "../../HomePage";
import Currency from "./Currency";
import SignInLogs from "./SignInLogs";

const Account = (props) => {

    const rayacorpUserId = 'fb5bdc54-c094-4769-b49d-419d62a69187';

    const {t, toast, location, setError} = React.useContext(AppContext);

    const [account, setAccount] = useState({
                first_name:"",
                last_name:"",
                email:"",
                language:"",
                timezone:"",
            });
    const [tabIndex, setTabIndex] = useState(0);
    const [languages, setLanguages] = useState(LanguageService.getLanguages());
    const [disabled, setDisabled] = useState(false);
    const [timezones, setTimezones] = useState([]);
    const [timezoneSuggestions, setTimezoneSuggestions] = useState(null);

    const accountService = new AccountService();

    useEffect ( ()=>{

        if (location)
        {
            let params = queryString.parse(location.search);
            if (params && params.subscription && params.subscription === "1")
                setTabIndex(4);
        }

        let timeZones = moment.tz.names();

        setTimezones(timeZones);
        setAccount(user());
    },[]);


    const suggestTimezones = function(e) {
        if (timezones !== null)
        {
            let results = timezones.filter(timezone => {
                return getTimeZoneDisplay(timezone).toLowerCase().includes(e.query.toString().toLowerCase());
            });
            setTimezoneSuggestions(results);
        }
    };

    const save = () => {
        if (disabled) {
            return;
        }
        setDisabled(true);

        accountService.updateMyAccount(account).then(account_updated=>{
            AuthService.setUser(account_updated);
            AuthService.setLoggedInEmail(account_updated.email);
            toast.current.show({severity: 'success', summary: t('account.save_account'), detail: t('message_detail.successful')});
            setDisabled(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setDisabled(false);
        });
    };



    const timezoneTemplate = (e) =>{
        return getTimeZoneDisplay(e);
    };
    const languageTemplate = (e) => {
        return e.label;
    };

    const changeLanguage = (e) => {
        onChange(e);
        LanguageService.setLanguage(e.target.value);
    };

    const onChange = (e) => {
        const name = e.target.name;
        const value  = e.target.value;
        setAccount( {...account, [name] : value });
    };

    const accountTemplate = () => {
        return (
            <div className="p-grid alignCenter p-fluid">
                <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="first_name" name="first_name" value={account.first_name} onChange={onChange}/>
                                <label htmlFor="first_name">{t('user.first_name')}</label>
                            </span>
                </div>
                <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="last_name" name="last_name" value={account.last_name} onChange={onChange}/>
                                <label htmlFor="last_name">{t('user.last_name')}</label>
                            </span>
                </div>
                <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="email" name="email" value={account.email} autoComplete="off" onChange={onChange}/>
                                <label htmlFor="email">{t('user.email')}</label>
                            </span>
                </div>
                <div className="p-col-12">
                            <span className="p-float-label">
                                <Dropdown id="language" name="language" value={account.language} options={languages}
                                          onChange={changeLanguage}
                                          itemTemplate={languageTemplate}/>
                                <label htmlFor="language">{t('user.language')}</label>
                            </span>
                </div>
                <div className="p-col-12">
                            <span className="p-float-label">
                                <AutoComplete id="timezone" name="timezone" field="label" dropdown={true} value={account.timezone} suggestions={timezoneSuggestions}
                                              onChange={onChange}
                                              completeMethod={suggestTimezones}
                                              itemTemplate={timezoneTemplate}
                                />
                                <label htmlFor="timezone">{t('user.timezone')}</label>
                            </span>
                </div>
                <div className="p-col-12">
                    <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save}/>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {isMainProfile() &&
            <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}
                     style={{paddingTop: "0", paddingLeft: "0", paddingRight: "0"}}>
                <TabPanel header={t('menu_item.account')} leftIcon="pi pi-fw pi-user">
                    {accountTemplate()}
                </TabPanel>
                <TabPanel header={t('actions.change_password')} leftIcon="pi pi-fw pi-key">
                    <ChangePassword/>
                </TabPanel>
                <TabPanel header={t('account.headers.notification')} leftIcon="pi pi-fw pi-bell">
                    <Notification/>
                </TabPanel>
                <TabPanel header={t('account.headers.profile')} leftIcon="pi pi-fw pi-user-plus">
                    <Profile/>
                </TabPanel>
                <TabPanel header={t('account.headers.subscriptions')} leftIcon="pi pi-fw pi-shopping-cart">
                    {!isUserManaged() && <Subscription/>}
                </TabPanel>
                <TabPanel header={t('account.headers.billing_history')} leftIcon="pi pi-fw pi-money-bill">
                    {!isUserManaged() && <BillingHistory/>}
                </TabPanel>
                <TabPanel header={t('account.headers.currency')} leftIcon="pi pi-fw pi-dollar">
                    <Currency/>
                </TabPanel>
                <TabPanel header={t('account.headers.sign_in_logs')} leftIcon="pi pi-fw pi-sign-in">
                    <SignInLogs/>
                </TabPanel>
            </TabView>
            }
            {isSubProfile() && userId() === rayacorpUserId &&
            <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}
                     style={{paddingTop: "0", paddingLeft: "0", paddingRight: "0"}}>
                <TabPanel header={t('actions.change_password')} leftIcon="pi pi-fw pi-key">
                    <ChangePassword/>
                </TabPanel>

            </TabView>
            }
            {isSubProfile() && userId() !== rayacorpUserId &&
                <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}
                         style={{paddingTop: "0", paddingLeft: "0", paddingRight: "0"}}>
                    <TabPanel header={t('actions.change_password')} leftIcon="pi pi-fw pi-key">
                        <ChangePassword/>
                    </TabPanel>
                    <TabPanel header={t('account.headers.billing_history')} leftIcon="pi pi-fw pi-money-bill">
                        {!isUserManaged() && <BillingHistory/>}
                    </TabPanel>
                </TabView>
            }
        </React.Fragment>
    );
};
export default Account;
