import React from "react";

import {AppContext} from "../../HomePage";

const OverviewProduct = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);

    return <React.Fragment>
        <div className="p-col-3">
            <div className="card summary">
                <button className="p-link" onClick={() => {window.location = '#/product';}}>
                    <span className="title">{t('overview.total_products')}</span>
                </button>
                <span className="detail">{t('overview.total_products_detail')}</span>
                <span className="count blue">{props.overview.total_products}</span>
            </div>
        </div>
        <div className="p-col-3">
            <div className="card summary">
                {!props.isManufacturer &&
                    <div>
                        <button className="p-link" onClick={() => {window.location = '#/product?mystock=false';}}>
                            <span className="title">{t('overview.total_out_of_products')}</span>
                        </button>
                        <span className="detail">{t('overview.total_out_of_products_detail')}</span>
                        <span className="count red">{props.overview.total_out_of_products}</span>
                    </div>
                }
                {props.isManufacturer &&
                    <div>
                        <span className="title">{t('overview.total_no_defined_map')}</span>
                        <span className="detail">{t('overview.total_no_defined_map_detail')}</span>
                        <span className="count red">{props.overview.total_no_defined_map}</span>
                    </div>
                }
            </div>
        </div>

        <div className="p-col-3">
            <div className="card summary">
                <button className="p-link" onClick={() => {window.location = '#/product?new=true';}}>
                    <span className="title">{t('overview.total_new_products')}</span>
                </button>
                <span className="detail">{t('overview.total_new_products_detail')}</span>
                <span className="count green">{props.overview.total_new_products}</span>
            </div>
        </div>

        <div className="p-col-3">
            <div className="card summary">
                <button className="p-link" onClick={() => {window.location = '#/product?hasCompetitor=false';}}>
                    {!props.isManufacturer && <span className="title">{t('overview.total_products_without_competitors')}</span>}
                    {props.isManufacturer && <span className="title">{t('overview.total_products_without_resellers')}</span>}
                </button>
                {!props.isManufacturer && <span className="detail">{t('overview.total_products_without_competitors_detail')}</span>}
                {props.isManufacturer && <span className="detail">{t('overview.total_products_without_resellers_detail')}</span>}
                <span className="count yellow">{props.overview.total_products_without_competitors}</span>
            </div>
        </div>

        {props.isDropShipping &&
        <React.Fragment>
                <div className="p-col-3">
                    <div className="card summary">
                        <button className="p-link" onClick={() => {window.location = '#/product?drop_shipping_supplied=false';}}>
                            <span className="title">{t('overview.total_not_supplied_products')}</span>
                        </button>
                        <span className="detail">{t('overview.total_not_supplied_products_detail')}</span>
                        <span className="count red">{props.overview.total_not_supplied_products}</span>
                    </div>
                </div>
                <div className="p-col-3">
                    <div className="card summary">
                        <button className="p-link" onClick={() => {window.location = '#/report/drop_shipping_loss';}}>
                            <span className="title">{t('overview.total_drop_shipping_loss')}</span>
                        </button>
                        <span className="detail">{t('overview.total_drop_shipping_loss_detail')}</span>
                        <span className="count red">{props.overview.total_drop_shipping_loss}</span>
                    </div>
                </div>
                <div className="p-col-6">
                </div>
        </React.Fragment>
        }
    </React.Fragment>;
};
export default OverviewProduct;
