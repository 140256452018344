import React, {useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";




import {Button} from "primereact/button";
import {getKeyValue} from "../../utils/Utils";
import {InputNumber} from "primereact/inputnumber";
import {AppContext} from "../../HomePage";



const JobSchedulingTimeSetting = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const [webSearchJobHours, setWebSearchJobHours] = useState(props.job && props.job.hours ? props.job.hours : []);

    const [day, setDay] = useState(1);
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);

    const timeTemplate = (rowData) => <div>{`${rowData.hour}:${rowData.minute}`}</div>;

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <Button type="button" icon="pi pi-trash" className="p-button-danger" onClick={() => remove(rowData)}/>
        </React.Fragment>;
    };

    const add = () => {
        let time = hour.toString().padStart(2,'0') + ":" + minute.toString().padStart(2,'0')
        let webSearchJobHour = {"day": day, "hour": hour, "minute": minute, "time": time};
        if (props.job.hours == null) {
            props.job.hours = []
        }
        props.job.hours.push(webSearchJobHour);
        setWebSearchJobHours([...props.job.hours])
    }

    const remove = (rowData) => {
        props.job.hours = webSearchJobHours.filter(jobTime => jobTime !== rowData );
        setWebSearchJobHours([...props.job.hours])
    }

    return (
        <div className="p-grid p-fluid">
            {!props.isDaily &&
            <div className="p-col-12 p-xl-2">
                <span className="p-float-label">
                    <InputNumber id="day" name="day" value={day} style={{width:"80px"}}
                                 onValueChange={(e)=>{setDay(getKeyValue(e).value);}}
                                 min={1} max={31} showButtons buttonLayout="vertical"/>
                    <label htmlFor="day" >{t('suggestion.day')}</label>
                </span>
            </div>
            }

            <div className="p-col-12 p-xl-2">
                <span className="p-float-label">
                     <InputNumber id="hour" name="hour" value={hour} style={{width:"80px"}}
                                  onValueChange={(e)=>{setHour(getKeyValue(e).value);}}
                                  min={0} max={23} showButtons buttonLayout="vertical"/>
                    <label htmlFor="hour" >{t('suggestion.hour')}</label>
                </span>
            </div>

            <div className="p-col-12 p-xl-2">
                <span className="p-float-label">
                     <InputNumber id="minute" name="minute" value={minute} style={{width:"80px"}}
                                  onValueChange={(e)=>{setMinute(getKeyValue(e).value);}}
                                  min={0} max={59} step={10} showButtons buttonLayout="vertical"/>
                    <label htmlFor="minute" >{t('suggestion.minute')}</label>
                </span>
            </div>
            <div className="p-col-12 p-xl-2">
                <Button label={t('actions.add')} icon="pi pi-plus" className="p-button-outlined p-button-success" onClick={add}/>
            </div>

            <div className="p-col-12 p-xl-12">
                <DataTable value={webSearchJobHours} dataKey="day" responsive={true} sortField="day" sortOrder={1}>
                    {!props.isDaily && <Column header={t('suggestion.day')} field="day" /> }
                    <Column header={t('suggestion.time')} field="time" />
                    <Column body={actionTemplate} header={t('table_headers.action')} style={{textAlign:'center', width: '10em'}}/>
                </DataTable>
            </div>
        </div>
    );

};
export default (JobSchedulingTimeSetting)
