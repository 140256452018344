import {AuthService} from "./AuthService";
import {toDateStringIgnoringTimezone} from "../utils/TimeUtil";

export class DashboardService {
    url = `dashboards/`;
    url_dynamic_pricing = `dynamic_pricing/`;

    getOverview(campaign_id, selectedDate) {
        return AuthService.apiGet(this.url + "overview/",{
            params: {campaign_id: campaign_id, price_created_at: selectedDate}
        }).then(res => res.data.data);
    }

    getTrackedWebsitesOverview(campaign_id) {
        return AuthService.apiGet(this.url + "tracked_websites_overview/",{
            params: {campaign_id: campaign_id}
        }).then(res => res.data.data);
    }

    getTrackedDistinctWebsitesOverview(campaign_id) {
        return AuthService.apiGet(this.url + "tracked_distinct_websites_overview/",{
            params: {campaign_id: campaign_id}
        }).then(res => res.data.data);
    }

    getTop10CheaperSellers(campaign_id) {
        return AuthService.apiGet(this.url + "top_10_cheaper_sellers/",{
            params: {campaign_id: campaign_id}
        }).then(res => res.data.data);
    }

    getTopSellersViolatingMRP(campaign_id) {
        return AuthService.apiGet(this.url + "top_10_sellers_violating_mrp/",{
            params: {campaign_id: campaign_id}
        }).then(res => res.data.data);
    }

    getURLStatistics() {
        return AuthService.apiGet(this.url + "url_statistics/").then(res => res.data.data);
    }

    getUndefinedWebsites() {
        return AuthService.apiGet(this.url + "undefined_websites/").then(res => res.data.data);
    }

    getPriceStockChanges(campaign_id, selectedDate) {
        return AuthService.apiGet(this.url + "price_stock_changes/",{
            params: {campaign_id: campaign_id, price_created_at: selectedDate}
        }).then(res => res.data.data);
    }

    getPriceViolations(campaign_id, startDate, endDate) {
        return AuthService.apiGet(this.url + "price_violations/",{
            params: {campaign_id: campaign_id, startDate: startDate, endDate: endDate}
        }).then(res => res.data.data);
    }

    getUnauthorizedSellers(campaign_id, selectedDate) {
        return AuthService.apiGet(this.url + "unauthorized_sellers/",{
            params: {campaign_id: campaign_id, price_created_at: selectedDate}
        }).then(res => res.data.data);
    }

    getDropShippingLoss(campaign_id, selectedDate) {
        return AuthService.apiGet(this.url + "drop_shipping_loss/",{
            params: {campaign_id: campaign_id, price_created_at: selectedDate}
        }).then(res => res.data.data);
    }

    getDropShippingLossPrices(campaign_id, selectedDate){
        return AuthService.apiPost(this.url + "create_scraping_request_for_drop_shipping_loss/", {campaign_id: campaign_id, price_created_at: selectedDate}).then(res => res.data.scraping_requests_created);
    }

    getPriceVariations(campaign_id, startDate, endDate) {
        return AuthService.apiGet(this.url + "price_variation/",{
            params: {campaign_id: campaign_id, start_date: startDate, end_date: endDate}
        }).then(res => res.data.data);
    }

    getCompetitiveness(campaign_id, startDate, endDate) {
        return AuthService.apiGet(this.url + "competitiveness/",{
            params: {campaign_id: campaign_id, start_date: startDate, end_date: endDate}
        }).then(res => res.data.data);
    }

    getSales(campaign_id, startDate, endDate) {
        return AuthService.apiGet(this.url + "sales/",{
            params: {campaign_id: campaign_id, start_date: startDate, end_date: endDate}
        }).then(res => res.data.data);
    }

    getPriceComparison(campaign_id, otherCampaigns, showCheapestSellerInOtherCampaigns, showListPrices) {
        return AuthService.apiGet(this.url + "price_comparison/",
            {
                params:
                    {
                        campaign_id: campaign_id,
                        other_campaign_ids: otherCampaigns.join(","),
                        show_list_prices: showListPrices,
                        show_cheapest_seller_in_other_campaigns: showCheapestSellerInOtherCampaigns
                    }
            }).then(res => res.data.data);
    }

    getPriceComparisonSummary(campaign_id, otherCampaigns, includeShippingPrices) {
        return AuthService.apiGet(this.url + "price_comparison_summary/",
            {params: {campaign_id: campaign_id,
                    other_campaign_ids: otherCampaigns.join(","),
                    include_shipping_prices: includeShippingPrices
            }
            }).then(res => res.data.data);
    }

    getDailyPriceComparison(campaign_id, start_date, end_date) {
        return AuthService.apiGet(this.url + "daily_price_comparison/",{params: {campaign_id: campaign_id, start_date: start_date, end_date: end_date}}).then(res => res.data.data);
    }

    getWeeklyPriceComparison(campaign_id, startYear, startWeekNumber, endYear, endWeekNumber) {
        startWeekNumber = startYear + '-W' + startWeekNumber;
        endWeekNumber = endYear + '-W' + endWeekNumber;
        return AuthService.apiGet(this.url + "weekly_price_comparison/",{params: {campaign_id: campaign_id, start_week_range: startWeekNumber, end_week_range: endWeekNumber}}).then(res => res.data.data);
    }

    getNCheapestSellerPriceComparison(campaign_id, seller_number) {
        return AuthService.apiGet(this.url + "n_cheapest_sellers_price_comparison/",{params: {campaign_id: campaign_id, seller_number: seller_number}}).then(res => res.data.data);
    }

    getPriceComparisonWithFilteredSellers(campaign_id, competitor_names) {
        return AuthService.apiGet(this.url + "price_comparison_with_filtered_sellers/",{params: {campaign_id: campaign_id, competitor_names: competitor_names}}).then(res => res.data.data);
    }

    getCompetitorNames(campaign_id) {
        return AuthService.apiGet(this.url + "competitors/",{params: {campaign_id: campaign_id}}).then(res => res.data.data);
    }

    getDynamicPricing(user_id, campaign_id, startDate, endDate) {
        return AuthService.apiGet(this.url_dynamic_pricing + "applied_pricing_suggestions/",{
            params: {user_id: user_id, campaign_id: campaign_id, start_date: startDate, end_date: endDate}
        }).then(res => res.data.data);
    }

    takeScreenshots(campaign_id) {
        return AuthService.apiPost(this.url + "screenshots/capture/", {"campaign_id": campaign_id}).then(res => res.data.data);
    }

    activeOrDeactivateUndefinedWebsite(id, is_active, url){
        if (is_active)
            return AuthService.apiPut(this.url + id+'/undefined_websites/deactivate/', {"url": url}).then(res => res.data.message);
        else
            return AuthService.apiPut(this.url + id+'/undefined_websites/activate/').then(res => res.data.message);
    }

    getSellersSummary(campaign_id, selectedDate) {
        return AuthService.apiGet(this.url + "sellers_summary/",{
            params: {campaign_id: campaign_id, price_created_at: selectedDate}
        }).then(res => res.data.data);
    }

    getMonthlyAveragePriceComparison(campaign_ids, selectedDate, selectedBrands, selectedCategories) {
        let start_range = toDateStringIgnoringTimezone(selectedDate[0]).substring(0,7);
        let end_range = toDateStringIgnoringTimezone(selectedDate[1]).substring(0,7);
        return AuthService.apiGet(this.url + "monthly_average_price_comparison/",{
            params: {campaign_ids: campaign_ids.join(),
                start_range: start_range,
                end_range: end_range,
                selected_brands: (selectedBrands.length>0 ? selectedBrands.join("|") : null),
                selected_categories: (selectedCategories.length>0 ? selectedCategories.join("|") : null)}
        }).then(res => res.data.data);
    }

    getPriceIndexReport(campaign_id, selectedDate) {
        let start_date = toDateStringIgnoringTimezone(selectedDate[0]);
        let end_date = toDateStringIgnoringTimezone(selectedDate[1]);
        return AuthService.apiGet(this.url + "get_price_index_report/",{
            params: {campaign_id: campaign_id,
                start_date: start_date,
                end_date: end_date}
        }).then(res => res.data.data);
    }


}

