import {AuthService} from "./AuthService";
import {getHostName} from "../utils/Utils";

export class CleanupService {
    url = `product_cleanup/`;

    clearCompetitorSuggestions(campaign_id){
        return AuthService.apiPut(this.url + "clear_competitor_suggestions/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    clearSuggestionsOfCompetitor(campaign_id, host){
        let hosts = [];
        hosts.push(getHostName(host));
        return AuthService.apiPut(this.url + "clear_suggestions_with_hosts/", {"campaign_id": campaign_id, "hosts": hosts}).then(res => res.data.message);
    }

    removeCompetitors(campaign_id, host){
        return AuthService.apiPut(this.url + "remove_competitor_urls/", {"campaign_id": campaign_id, "host": getHostName(host)}).then(res => res.data.message);
    }

    removeProductsByOwnerHost(campaign_id, host){
        return AuthService.apiPut(this.url + "remove_products_by_owner_host/", {"campaign_id": campaign_id, "host": getHostName(host)}).then(res => res.data.message);
    }

    removeDeactivatedCompetitors(campaign_id, host){
        return AuthService.apiPut(this.url + "remove_deactivated_competitor_urls/", {"campaign_id": campaign_id, "host": getHostName(host)}).then(res => res.data.message);
    }

    activateCompetitorUrls(campaign_id, host){
        return AuthService.apiPut(this.url + "activate_competitor_urls/", {"campaign_id": campaign_id, "host": getHostName(host)}).then(res => res.data.message);
    }

    clearManuelShippingPrices(campaign_id, host){
        return AuthService.apiPut(this.url + "clear_manuel_shipping_prices/", {"campaign_id": campaign_id, "host": getHostName(host)}).then(res => res.data.message);
    }

    clearCompetitorSuggestionsUsedBasedSpider(campaign_id){
        return AuthService.apiPut(this.url + "clear_used_base_spider/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    clearNewCompetitorSuggestions(campaign_id){
        return AuthService.apiPut(this.url + "clear_new_competitor_suggestions/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    deactivateProductsWithoutDynamicPricingStrategy(campaign_id){
        return AuthService.apiPut(this.url + "deactivate_products_without_dynamic_pricing_strategy/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    activateOrDeactivateCompetitors(campaign_id, competitor_name, is_active){
        return AuthService.apiPut(this.url + "activate_deactivate_competitors/", {"campaign_id": campaign_id, "competitor_name": competitor_name, "is_active": is_active}).then(res => res.data.message);
    }

    activateOrDeactivateCampaignCompetitor(campaign_id, url, is_active){
        return AuthService.apiPut(this.url + "activate_deactivate_urls/", {"campaign_id": campaign_id, "url": url, "is_active": is_active}).then(res => res.data.message);
    }

    deleteDuplicateProducts(campaign_id, field){
        return AuthService.apiDelete(this.url + "delete_duplicated_products/", {"params":{"campaign_id": campaign_id, "field": field}}).then(res => res.data.message);
    }

    deleteDuplicateSellers(campaign_id){
        return AuthService.apiPut(this.url + "clear_competitors_with_duplicated_urls/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    deleteDuplicateSellersByName(campaign_id){
        return AuthService.apiPut(this.url + "clear_competitors_with_duplicated_names/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    deleteDuplicateSuggestions(campaign_id){
        return AuthService.apiPut(this.url + "clear_duplicated_suggestions/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    setSuggestionsUnmatched(campaign_id){
        return AuthService.apiPut(this.url + "set_suggestions_unmatched/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    clearViolationStrategies(campaign_id){
        return AuthService.apiPut(this.url + "clear_violation_strategies/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    removeIsMapIsMrpFromCompetitors(campaign_id){
        return AuthService.apiPut(this.url + "remove_is_map_is_mrp_from_competitors/", {"campaign_id": campaign_id}).then(res => res.data.message);
    }

    deactivateProductsWithNoLongerAvailableOwner(campaign_id, days){
        return AuthService.apiPut(this.url + "deactivate_products_with_no_longer_available_owner", {"campaign_id": campaign_id, "days": days}).then(res => res.data.message);
    }
}
