import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {DashboardService} from "../../service/DashboardService";

const URLStatistics = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);

    const [loading, setLoading] = useState(false);

    const dashboardService = new DashboardService();

    useEffect ( ()=>{
        search();
    }, []);

    const search = () => {
        setLoading(true);
        dashboardService.getURLStatistics().then(data=>{
            setItems(data.url_statistics);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    return <React.Fragment>


         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <DataTable value={items} emptyMessage={t('actions.empty_message')} paginator={true} rows={100}>
                 <Column body={IndexTemplate} style={{width:'3em'}} />
                 <Column field="user_name" style={{width:'30em'}} header={t('user.name')} sortable/>
                 <Column field="total_product_count" header={t('dashboard.total_product_count')} sortable/>
                 <Column field="total_url_count" header={t('dashboard.total_url_count')} sortable/>
                 <Column field="total_active_url_count" header={t('dashboard.total_active_url_count')} sortable/>
                 <Column field="total_inactive_url_count" header={t('dashboard.total_inactive_url_count')} sortable/>
                 <Column field="average_url_count_per_product" header={t('dashboard.average_url_count_per_product')} sortable/>
             </DataTable>}

    </React.Fragment>;
};
export default URLStatistics;
