import React, {useEffect, useState} from "react";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {CacheService} from "../../service/CacheService";
import {Dialog} from "primereact/dialog";
import {ListBox} from "primereact/listbox";
import {IndexTemplate} from "../../utils/InlineComponents";
import {QueueService} from "../../service/QueueService";

const Cache = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);
    const [itemsNotCacheUsage, setItemsNotCacheUsage] = useState([]);
    const [itemToBeCached, setItemToBeCached] = useState(null);

    const [showCacheDeleteModal, setCacheDeleteModal] = useState(false);
    const [showCacheAdditionModal, setCacheAdditionModal] = useState(false);
    const [rowData, setRowData] = useState(null);

    const [loading, setLoading] = useState(false);

    const cacheService = new CacheService();
    const queueService = new QueueService();

    useEffect ( ()=>{
        search();
    }, []);

    const search = () => {
        setLoading(true);
        cacheService.getCaches().then(data => {
            setItems(data.filter(item => item.cache_usage_id !== null));
            let itemsNotCacheUsage = data.filter(item => item.cache_usage_id === null).map
            (item => ({value:item.campaign_id, label:[item.email, item.campaign_name, item.campaign_id].join(' - ') })).sort
            ((a, b) => a.label.localeCompare(b.label), undefined, {sensitivity: 'base'})
            setItemsNotCacheUsage(itemsNotCacheUsage);
            setLoading(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const refreshCache = (rowData) => {
        cacheService.refreshCache(rowData).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.update'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const clearAllCache = () => {
        cacheService.clearAllCache().then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.clear_all_cache'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const consumeQueue = () => {
        queueService.consumeQueue().then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.consume_queue'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const deleteCache = () => {
        cacheService.deleteCache(rowData).then(()=>{
            setCacheDeleteModal(false);
            search()
            toast.current.show({severity: 'success', summary: t('actions.remove'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setCacheDeleteModal(false);
        });
    }

    const addCache = () => {
        cacheService.addCache(itemToBeCached).then(added_cache=>{
            setCacheAdditionModal(false);
            setItemToBeCached(null);
            search()
            toast.current.show({severity: 'success', summary: t('actions.add'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setCacheAdditionModal(false);
            setItemToBeCached(null);
        });

    }

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <div className="p-grid p-align-center">
                <Button icon="pi pi-refresh" className="p-button-info p-mr-1" tooltip={t('actions.refresh')} tooltipOptions={{position: 'bottom'}} onClick={() => {refreshCache(rowData);}}/>
                <Button icon="pi pi-trash" className="p-button-danger" tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => {setRowData(rowData); setCacheDeleteModal(true);}}/>
            </div>
        </React.Fragment>;

    }

    const modalCacheAddition = (
        <div className="p-grid alignCenter p-fluid">
            <ListBox id="itemsNotCacheUsage" name="itemsNotCacheUsage" value={itemToBeCached} options={itemsNotCacheUsage} onChange={(e) => setItemToBeCached(e.value)} filter optionLabel="label"
                     style={{width: '70rem'}} listStyle={{height: '450px'}} />
        </div>
    );

    const modalCacheAddCancelButtons = (
        <div>
            <Button label={t('actions.save')} icon="pi pi-plus" className="p-button-success" onClick={addCache} />
            <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-warning" onClick={() => {setCacheAdditionModal(false);}} />
        </div>
    );

    const modalYesNo = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" className="p-button-danger" onClick={deleteCache} />
            <Button label={t('yes_no.no')} icon="pi pi-times" className="p-button-warning" onClick={() => {setCacheDeleteModal(false); setRowData(null);}} />
        </div>
    );

    return <React.Fragment>
        <Dialog header={t('dialogs.confirm_cache_delete')} footer={modalYesNo} visible={showCacheDeleteModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setCacheDeleteModal(false); setRowData(null);}}>
            {t('dialogs.confirm_cache_delete_msg1')}
        </Dialog>

        <Dialog header={t('dialogs.cache_addition')} footer={modalCacheAddCancelButtons} visible={showCacheAdditionModal} style={{width: '40vw', minWidth: '20vw'}} modal={true} onHide={() => {setCacheAdditionModal(false);}}>
            {modalCacheAddition}
        </Dialog>

        <div className="p-grid">
            <div className="p-col-1" style={{textAlign:"left"}}>
                <Button label={t('actions.add')} icon="pi pi-plus" className="p-button-success" onClick={() => {setCacheAdditionModal(true);}} />
            </div>
            <div className="p-col-7" style={{textAlign:"right"}}>
            </div>
            <div className="p-col-2" style={{textAlign:"right"}}>
                <Button label={t('actions.consume_queue')} icon="pi pi-play" className="p-button-success" onClick={() => {consumeQueue();}} />
            </div>
            <div className="p-col-2" style={{textAlign:"right"}}>
                <Button label={t('actions.clear_all_cache')} icon="pi pi-refresh" className="p-button-info" onClick={() => {clearAllCache();}} />
            </div>
        </div>
         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <DataTable value={items}
                        emptyMessage={t('actions.empty_message')}
                        sortField="email" sortOrder={1}
                        paginator={true} rows={100}>
                 <Column body={IndexTemplate} headerStyle={{width:'3em'}} />
                 <Column field="email" header={t('user.email')} />
                 <Column field="campaign_id" header={t('common.id')} />
                 <Column field="campaign_name" header={t('campaign.name')} />
                 <Column header={t('table_headers.action')} body={actionTemplate} headerStyle={{textAlign:'center', width:'7em'}}/>
             </DataTable>}

    </React.Fragment>;
};
export default Cache;
