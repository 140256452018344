import Tour from "reactour";
import React, {useEffect, useState} from "react";
import {campaignTourSteps, productTourSteps} from "./Tours";
import {useTranslation} from "react-i18next";
import {user} from "./Utils";
import {AuthService} from "../service/AuthService";
import {AccountService} from "../service/AccountService";
import {AppContext} from "../HomePage";

const UserGuiding = (props) => {

    const {i18n} = useTranslation();
    const {t, appName} = React.useContext(AppContext);

    const [isTourOpen, setIsTourOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(user());
    const tourPage = props.tourPage;
    const accountService = new AccountService();

    useEffect ( ()=>{
        if (props.openTour)
            setIsTourOpen(true);
        else if (currentUser.completed_guiding_pages && currentUser.completed_guiding_pages.some(completed_guiding_page => completed_guiding_page === tourPage))
            setIsTourOpen(false);
        else
            setIsTourOpen(true);
    },[props.openTour, currentUser.completed_guiding_pages, tourPage]);

    const onCloseTour = () => {


        setIsTourOpen(false);
        props.closeTour();

        if (!currentUser.completed_guiding_pages || currentUser.completed_guiding_pages.findIndex(guiding=> guiding === tourPage) === -1)
        {
            accountService.saveGuidingCompleted(tourPage).then(()=>{
                let user = currentUser;
                user.completed_guiding_pages.unshift(tourPage);
                setCurrentUser(user);
                AuthService.setUser(user);
            }).catch(() =>{
            });
        }
    };

    let steps = [];
    if (tourPage === "campaign")
        steps = campaignTourSteps(t, i18n, onCloseTour, appName);
    else if (tourPage === "product")
        steps = productTourSteps(t, i18n, onCloseTour, appName );

    return <Tour
        closeWithMask={false}
        rounded={5}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={onCloseTour}
    />;
};
export default UserGuiding;