import React, {useState} from 'react';
import {AppContext} from "../../HomePage";
import {TabMenu} from "primereact/tabmenu";
import {isAdmin} from "../../utils/Utils";
import OverviewRequest from "./OverviewRequest";
import OverviewKPI from "./OverviewKPI";
import Income from "./Income";
import Expense from "./Expense";
import PaymentLog from "./PaymentLog";
import URLStatistics from "./URLStatistics";
import NewWebsites from "./NewWebsites";

const AdminDashboard = (props) => {

    const {t} = React.useContext(AppContext);
    const [activeIndex, setActiveIndex] = useState(0);
    let items = [
        {label: t('dashboard.overview_request'), icon: 'pi pi-window-maximize'},
        {label: t('dashboard.new_websites'), icon: 'pi pi-tablet'},
        {label: t('dashboard.url_statistics'), icon: 'pi pi-external-link'},
        {label: t('dashboard.overview_kpi'), icon: 'pi pi-chart-bar'},
        {label: t('menu_item.income'), icon: 'pi pi-fw pi-money-bill'},
        {label: t('menu_item.expense'), icon: 'pi pi-fw pi-credit-card'},
        {label: t('menu_item.payment_log'), icon: 'pi pi-fw pi-file-o'}
    ];

    return isAdmin &&
        <div className="p-grid card">
            <div className="p-col-12">
                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => {
                    setActiveIndex(items.indexOf(e.value));
                }}/>
            </div>
            <div className="p-col-12">
                {activeIndex === 0 && <OverviewRequest/>}
                {activeIndex === 1 && <NewWebsites/>}
                {activeIndex === 2 && <URLStatistics/>}
                {activeIndex === 3 && <OverviewKPI/>}
                {activeIndex === 4 && <Income/>}
                {activeIndex === 5 && <Expense/>}
                {activeIndex === 6 && <PaymentLog/>}
            </div>
        </div>;
};
export default AdminDashboard;
