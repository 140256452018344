import {AuthService} from "./AuthService";

export class SpecialFieldService {

    url = `special_fields/`;

    getSpecialFields(){
        return AuthService.apiGet(this.url,{
        }).then(res => res.data.data);
    }

    addSpecialField(headlessBrowser){
        return AuthService.apiPost(this.url, headlessBrowser).then(res => res.data.data);
    }

    updateSpecialField(headlessBrowser){
        return AuthService.apiPut(this.url + headlessBrowser.id + '/', headlessBrowser).then(res => res.data.data);
    }

    deleteSpecialField(id){
        return AuthService.apiDelete(this.url  + id + '/').then(res => res.data.data);
    }
}
