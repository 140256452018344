import React, {useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {ScraperService} from "../../service/ScraperService";
import {InputText} from "primereact/inputtext";
import {formValid, getHostName, getKeyValue, toTitleCase} from "../../utils/Utils";
import {Message} from "primereact/message";
import {AutoComplete} from "primereact/autocomplete";
import {Checkbox} from "primereact/checkbox";
import {AppContext} from "../../HomePage";
import {SelectButton} from "primereact/selectbutton";
import {InputTextarea} from "primereact/inputtextarea";
import {proxy_countries, proxy_types, scraping_request_types} from "../../utils/Constants";
import {Dropdown} from "primereact/dropdown";

const SiteDefinitionAdd = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const baseSpiders = [
        {spider_name:"jsonbase", country:"Universal", sector:"Generic", "is_site_searching_ready": false, group:"generic"},
        {spider_name:"itemprop_base", country:"Universal", sector:"Generic", "is_site_searching_ready": false, group:"generic"},
        {spider_name:"magento_base", country:"Universal", sector:"Generic", "is_site_searching_ready": false, group:"generic"},
        {spider_name:"shopify_base", country:"Universal", sector:"Generic", "is_site_searching_ready": false, group:"generic"},


        {spider_name:"tcmaxbase", country:"TR", sector:"Generic", "is_site_searching_ready": true, group:"tr1"},
        {spider_name:"tsoftbase", country:"TR", sector:"Generic", "is_site_searching_ready": true, group:"tr1"},
        {spider_name:"ideasoftbase", country:"TR", sector:"Generic", "is_site_searching_ready": true, group:"tr1"},
        {spider_name:"ideasoft_v2_base", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr1"},

        {spider_name:"akilliticaretbase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"akinsoftbase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"dokuzyazilimbase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"eticaretkurbase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"hipotenusbase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"imagazabase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"kobimasterbase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"marketyobase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"platinmarketbase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"softtrbase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},
        {spider_name:"yeditepesoftbase", country:"TR", sector:"Generic", "is_site_searching_ready": false, group:"tr"},

    ];

    const [siteDefinitions, setSiteDefinitions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [countrySuggestions, setCountrySuggestions] = useState([]);
    const [sectorSuggestions, setSectorSuggestions] = useState([]);

    const [editedDefinition, setEditedDefinition] = useState( props.selectedDefinition && baseSpiders.find(b=> b.spider_name === props.selectedDefinition.spider_name) ? {...baseSpiders.find(b=> b.spider_name === props.selectedDefinition.spider_name), ...props.selectedDefinition} : props.selectedDefinition ? props.selectedDefinition : {url: "", spider_name: "", country: "", proxy_type: "", proxy_country: "", sector: "", scraping_request_type: "", other_info: "", is_ignored: false, is_marketplace: false, is_proxy_used: false, is_headless_browser_used: false, is_headless_browser_used_for_importing_products: false, is_headless_browser_used_for_site_searching: false, is_site_searching_ready: false});
    const [formErrors, setFormErrors] = useState({url: "", spider_name: "", country: "", proxy_type: "", proxy_country: "", sector: "", scraping_request_type: "", other_info: "", is_ignored: "", is_marketplace: "", is_proxy_used: "", is_headless_browser_used: "", is_headless_browser_used_for_importing_products: "", is_headless_browser_used_for_site_searching: "", is_site_searching_ready: ""});

    const scrapingRequestTypes = scraping_request_types(t);
    const proxyCountries = proxy_countries(t);
    const proxyTypes = proxy_types(t);

    const [selectedBase, setSelectedBase] = useState("");


    const scraperService = new ScraperService();

    useEffect(() =>{
        list();
    }, []);

    const list = () => {
        scraperService.getSiteDefinitions(false).then(data => {
            setSiteDefinitions(data);
            setSectors([...new Set(data.map(p => p.sector))].sort((a, b) => (a ? a.toLowerCase() : "").localeCompare((b ? b.toLowerCase() : ""), undefined, {sensitivity: 'base'})));
            setCountries([...new Set(data.map(p => p.country))].sort((a, b) => (a ? a.toLowerCase() : "").localeCompare((b ? b.toLowerCase() : ""), undefined, {sensitivity: 'base'})));
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const cancel = () => {
        props.onComplete(true);
    };

    const save = (e) => {
        checkErrors(editedDefinition).then(formErrors=> {
            if (formValid(formErrors)) {

                if (editedDefinition.id) {
                    if (editedDefinition.url.split('||').length > 1)
                    {
                        toast.current.show({severity: 'warn', summary: t('site_definitions.enter_only_one_url_on_edit'), detail: t('message_detail.failed') });
                        return;
                    }
                    if (siteDefinitions.some(p => p.id !== editedDefinition.id && p.url === editedDefinition.url))
                    {
                        toast.current.show({severity: 'warn', summary: t('site_definitions.url_exists'), detail: t('message_detail.failed') });
                        return;
                    }

                    editedDefinition.url = getHostName(editedDefinition.url);

                    scraperService.saveSiteDefinition(editedDefinition).then(() => {
                        props.onComplete(false);
                        toast.current.show({severity: 'success', summary: t('actions.add'), detail: t('message_detail.successful') });
                    }).catch(error =>{
                        setError(error);
                        setError(null);
                    });
                }
                else{
                    let newDefinitions = [];
                    editedDefinition.url.split('||').forEach(u => {
                        let url = getHostName(u);
                        if (!siteDefinitions.some(p => (p.url.indexOf(url) !== -1)) && !newDefinitions.some(p => (p.url.indexOf(url) !== -1)))
                            newDefinitions.push({...editedDefinition, url: url, id: null});
                        else
                            toast.current.show({severity: 'warn', summary: t('site_definitions.url_exists') +  " " + url, detail: t('message_detail.failed') });
                    });

                    if (newDefinitions.length > 0)
                        scraperService.saveSiteDefinition(newDefinitions).then(() => {
                            props.onComplete(false);
                            toast.current.show({severity: 'success', summary: t('actions.add'), detail: t('message_detail.successful') });
                        }).catch(error =>{
                            setError(error);
                            setError(null);
                        });
                }
            }
            else
                setFormErrors(formErrors);
        });
    };

    const onChange = (e) =>{

        const { key, value } = getKeyValue(e);

        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setEditedDefinition({...editedDefinition, [key] : value});

    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0
    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "url") {
            errorText = value ? "" : t('validations.invalid_value');
        } else if (key === "spider_name") {
            errorText = value ? "" : t('validations.invalid_value');
        } else if (key === "country") {
            errorText = value ? "" : t('validations.invalid_value');
        } else if (key === "sector") {
            errorText = value ? "" : t('validations.invalid_value');
        }

        return errorText;
    };

    const checkErrors = async (siteDefinition) => {

        let errors = { ...formErrors };

        Object.entries(siteDefinition).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        setFormErrors(errors);
        return errors;
    };

    const suggestCountries = (event) => {
        if (countries)
        {
            setCountrySuggestions(countries.filter(country => {
                return country && toTitleCase(country).startsWith(toTitleCase(event.query));
            }));
        }
    };
    const suggestSectors = (event) => {
        if (sectors)
        {
            setSectorSuggestions(sectors.filter(sector => {
                return sector && toTitleCase(sector).startsWith(toTitleCase(event.query));
            }));
        }
    };

    const baseTemplate = (option) => {
        return <div style={{width:"6em"}}>{option.spider_name.replaceAll("base", "").replaceAll("_", " ").trim()}</div>;
    }

    return <div className="alignCenter ">
                <div className="p-grid card p-mt-0 p-mb-0">
                    <div className="p-col-12">
                        <SelectButton value={selectedBase} itemTemplate={baseTemplate}
                                      optionLabel="spider_name" optionValueLabel="spider_name" options={baseSpiders.filter(b=> b.group==="generic")}
                                      onChange={(e) => {
                                          const base = getKeyValue(e).value;
                                          setSelectedBase(base);
                                          setEditedDefinition({...editedDefinition, ...base});
                                      }}/>
                    </div>
                    <div className="p-col-12">
                        <SelectButton value={selectedBase} itemTemplate={baseTemplate}
                                      optionLabel="spider_name" optionValueLabel="spider_name" options={baseSpiders.filter(b=> b.group==="tr1")}
                                      onChange={(e) => {
                                          const base = getKeyValue(e).value;
                                          setSelectedBase(base);
                                          setEditedDefinition({...editedDefinition, ...base});
                                      }}/>
                    </div>
                    <div className="p-col-12">
                        <SelectButton value={selectedBase} itemTemplate={baseTemplate}
                                      optionLabel="spider_name" optionValueLabel="spider_name" options={baseSpiders.filter(b=> b.group==="tr")}
                                      onChange={(e) => {
                                          const base = getKeyValue(e).value;
                                          setSelectedBase(base);
                                          setEditedDefinition({...editedDefinition, ...base});
                                      }}/>
                    </div>
                </div>
                <div className="p-grid card p-fluid">

                    <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="url" name="url" value={editedDefinition.url ?? ""} onChange={onChange}
                                       className={errorClass("url")} autoFocus={true}/>
                            <label htmlFor="url">{t('site_definitions.url')}</label>
                        </span>
                        {errorClass("url") && (<Message severity="error" text={formErrors.url}/>)}
                    </div>
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="spider_name" name="spider_name" value={editedDefinition.spider_name ?? ""} onChange={onChange}
                                       className={errorClass("spider_name")}/>
                            <label htmlFor="spider_name">{t('site_definitions.spider')}</label>
                        </span>
                        {errorClass("spider_name") && (<Message severity="error" text={formErrors.spider_name}/>)}
                    </div>
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <AutoComplete id="country" name="country" dropdown={true} value={editedDefinition.country} onChange={onChange}
                                          suggestions={countrySuggestions} completeMethod={suggestCountries}
                                          className={errorClass("country")}/>
                            <label htmlFor="country">{t('site_definitions.country')}</label>
                        </span>
                        {errorClass("country") && (<Message severity="error" text={formErrors.country}/>)}
                    </div>
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <AutoComplete id="sector" name="sector" dropdown={true} value={editedDefinition.sector} onChange={onChange}
                                          suggestions={sectorSuggestions} completeMethod={suggestSectors}
                                          className={errorClass("sector")}/>
                            <label htmlFor="sector">{t('site_definitions.sector')}</label>
                        </span>
                        {errorClass("sector") && (<Message severity="error" text={formErrors.sector}/>)}
                    </div>

                    <div className="p-col-6">
                        <span className="p-float-label">
                            <Dropdown inputId="scraping_request_type" name="scraping_request_type" options={scrapingRequestTypes} value={editedDefinition?.scraping_request_type} onChange={onChange} size={5} editable />
                            <label htmlFor="scraping_request_type" >{t('site_definitions.scraping_request_type')}</label>
                        </span>
                    </div>


                    <div className="p-col-12">
                        <Checkbox inputId="is_site_searching_ready" name="is_site_searching_ready" value={editedDefinition.is_site_searching_ready} checked={editedDefinition.is_site_searching_ready} onChange={onChange}/>
                        <label htmlFor="is_site_searching_ready" className="p-checkbox-label">{t('site_definitions.is_site_searching_ready')}</label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox inputId="is_proxy_used" name="is_proxy_used" value={editedDefinition.is_proxy_used} checked={editedDefinition.is_proxy_used} onChange={onChange}/>
                        <label htmlFor="is_proxy_used" className="p-checkbox-label">{t('site_definitions.is_proxy_used')}</label>
                    </div>
                    {editedDefinition.is_proxy_used &&
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <Dropdown inputId="proxy_type" name="proxy_type" options={proxyTypes} value={editedDefinition?.proxy_type} onChange={onChange} size={5} />
                            <label htmlFor="proxy_type" >{t('site_definitions.proxy_type')}</label>
                        </span>
                    </div>}
                    {editedDefinition.is_proxy_used &&
                    <div className="p-col-6">
                        <span className="p-float-label">
                            <Dropdown inputId="proxy_country" name="proxy_country" options={proxyCountries} value={editedDefinition?.proxy_country} onChange={onChange} size={5} />
                            <label htmlFor="proxy_country" >{t('suggestion.proxy_country')}</label>
                        </span>
                    </div>}

                    <div className="p-col-12">
                        <Checkbox inputId="is_ignored" name="is_ignored" value={editedDefinition.is_ignored} checked={editedDefinition.is_ignored} onChange={onChange}/>
                        <label htmlFor="is_ignored" className="p-checkbox-label">{t('site_definitions.is_ignored')}</label>
                    </div>
                    <div className="p-col-12">
                        <Checkbox inputId="is_marketplace" name="is_marketplace" value={editedDefinition.is_marketplace} checked={editedDefinition.is_marketplace} onChange={onChange}/>
                        <label htmlFor="is_marketplace" className="p-checkbox-label">{t('site_definitions.is_marketplace')}</label>
                    </div>
                    <div className="p-col-12 p-grid">
                        <div className="p-col-12">
                            <Message severity="info" text={t('site_definitions.headless_browser_usage')} />
                        </div>
                        <div className="p-col-4">
                            <Checkbox inputId="is_headless_browser_used" name="is_headless_browser_used" value={editedDefinition.is_headless_browser_used} checked={editedDefinition.is_headless_browser_used} onChange={onChange}/>
                            <label htmlFor="is_headless_browser_used" className="p-checkbox-label">{t('site_definitions.is_headless_browser_used')}</label>
                        </div>
                        <div className="p-col-4">
                            <Checkbox inputId="is_headless_browser_used_for_importing_products" name="is_headless_browser_used_for_importing_products" value={editedDefinition.is_headless_browser_used_for_importing_products} checked={editedDefinition.is_headless_browser_used_for_importing_products} onChange={onChange}/>
                            <label htmlFor="is_headless_browser_used_for_importing_products" className="p-checkbox-label">{t('site_definitions.is_headless_browser_used_for_importing_products')}</label>
                        </div>
                        <div className="p-col-4">
                            <Checkbox inputId="is_headless_browser_used_for_site_searching" name="is_headless_browser_used_for_site_searching" value={editedDefinition.is_headless_browser_used_for_site_searching} checked={editedDefinition.is_headless_browser_used_for_site_searching} onChange={onChange}/>
                            <label htmlFor="is_headless_browser_used_for_site_searching" className="p-checkbox-label">{t('site_definitions.is_headless_browser_used_for_site_searching')}</label>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputTextarea id="other_info" name="other_info" value={editedDefinition?.other_info} onChange={onChange} className={errorClass("other_info")} rows={3} />
                            <label htmlFor="other_info" >{t('campaign.competitors.note')}</label>
                        </span>
                        {errorClass("other_info") && (<Message severity="other_info" text={formErrors.other_info}/>)}
                    </div>
                    <div className="p-col-6">
                        <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" style={{marginRight: '.5em'}} onClick={cancel}/>
                    </div>
                    <div className="p-col-6">
                        <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save}/>
                    </div>
                </div>
            </div>;


};
export default (SiteDefinitionAdd);
