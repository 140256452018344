import {AuthService} from "./AuthService";

export class DynoService {
    url = `dynos/`;

    restartDynos(){
        return AuthService.apiGet(this.url + "timeout/",{params: {}}).then(res => res.data.data);
    }


}
