import React, {useEffect, useState} from "react";

import {
    exportFile,
    formatCurrency,
    getKeyValue,
    getProductDetailLink,
    isCampaignManufacturer,
    isCampaignMarketSearch
} from "../../utils/Utils";
import {toDateStringIgnoringTimezone} from "../../utils/TimeUtil";

import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {ExportService} from "../../service/ExportService";
import {CampaignContext} from "../../App";
import queryString from "query-string";
import {MultiSelect} from "primereact/multiselect";

const FilteredSellersPriceComparison = (props) => {

    const {t, setError, location} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [items, setItems] = useState([]);

    const [competitorNames, setCompetitorNames] = useState(null);
    const [allCompetitorNames, setAllCompetitorNames] = useState([]);


    const [loading, setLoading] = useState(false);
    const [loadingCompetitorNames, setLoadingCompetitorNames] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);

    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect ( ()=>{
        setCompetitorNames(null)
        if (location !== undefined) {
            getCompetitorNames(false)
            let params = queryString.parse(location.search);
            if (params && params.competitorNames) {
                setCompetitorNames([params.competitorNames])
                search(params.competitorNames);
            }
        } else {
            getCompetitorNames(true)
        }
    },[selectedCampaign]);

    useEffect ( ()=>{
        if (location !== undefined && allCompetitorNames.length > 0) {
            let params = queryString.parse(location.search);
            if (params && params.competitorNames) {
                let selectedCompetitorName = params.competitorNames.replace(";", "")
                let foundCompetitorName = allCompetitorNames.filter(item => item.value === selectedCompetitorName)[0]
                if (foundCompetitorName) {
                    setCompetitorNames([foundCompetitorName.value])
                } else {
                    setCompetitorNames([])
                }
            }
        }
    },[allCompetitorNames]);

    const search = (competitorNames) => {
        if (selectedCampaign && competitorNames) {
            setLoading(true);
            let selectedSellers = competitorNames
            if (Array.isArray(competitorNames)) {
                selectedSellers = competitorNames.join(';')
            }
            dashboardService.getPriceComparisonWithFilteredSellers(selectedCampaign.id, selectedSellers).then(data=>{
                setItems(data);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const getCompetitorNames = (withLoading) => {
        if (selectedCampaign) {
            if (withLoading)
                setLoading(true);
                setLoadingCompetitorNames(true);
            dashboardService.getCompetitorNames(selectedCampaign.id).then(data=>{
                setAllCompetitorNames(data.map(item => ({label: item.name, value: item.name})));
                if (withLoading)
                    setLoading(false);
                    setLoadingCompetitorNames(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                if (withLoading)
                    setLoading(false);
                    setLoadingCompetitorNames(false);
            });
        }
    };

    const onExport = () => {
        if (selectedCampaign && competitorNames) {
            setLoadingExcelExport(true);
            let selectedSellers = competitorNames
            if (Array.isArray(competitorNames)) {
                selectedSellers = competitorNames.join(';')
            }
            exportService.exportPriceComparisonWithFilteredSellers(selectedCampaign.id, selectedSellers).then((response) => {
                exportFile(response, selectedCampaign.name + "_" + t('dashboard.filtered_seller_price_comparison') + "_" + toDateStringIgnoringTimezone(new Date()) + ".xlsx");
                setLoadingExcelExport(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoadingExcelExport(false);
            });
        }
    };

    const nameTemplate = (rowData) => {
        return getProductDetailLink(rowData.id, rowData.name);
    };

    const sellerNameTemplate = (rowData, props) => {
        return rowData[props.field + '_name'] ? <div className="p-grid" style={{color: rowData[props.field + '_is_mine'] ? rowData.competitorsMinPrice >= rowData.price ? 'green' : rowData.competitorsMaxPrice <= rowData.price ? 'red' : 'orange' : 'black'}}>
            {rowData[props.field + '_name']}
        </div> : '';
    };

    const sellerPriceTemplate = (rowData, props) => {
        let color = 'black'
        if (rowData.owner && rowData.owner.is_map && rowData.owner.price && rowData.owner.price.value > rowData[props.field + '_price']) {
            color = 'red'
        } else if (rowData.mrp_owner && rowData.mrp_owner.is_mrp && rowData.mrp_owner.price && rowData.mrp_owner.price.value < rowData[props.field + '_price']) {
            color = 'orange'
        }

        return rowData[props.field + '_price'] ? <div className="p-grid">
            <a target="_blank" rel="noopener noreferrer" href={rowData[props.field + '_url']} style={{color: color}}>
                {formatCurrency(rowData[props.field + '_price'], rowData.owner.price.currency)}
            </a>
        </div> : '';
    };


    const ownPriceTemplate = (rowData) => {
        if (rowData.owner && rowData.owner.is_map) {
            return <div className="p-grid p-justify-end">
                {formatCurrency(rowData.owner.price?.value, rowData.owner.price?.currency)}
            </div>
        } else if (rowData.owner) {
            return <div className="p-grid p-justify-end">
                <a target="_blank" rel="noopener noreferrer" href={rowData.owner.product_url}>
                    {formatCurrency(rowData.owner.price?.value, rowData.owner.price?.currency)}
                </a>
            </div>
        } else {
            return ''
        }
    };

    const mrpPriceTemplate = (rowData) => {
        return rowData.mrp_owner ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.mrp_owner.price?.value, rowData. mrp_owner.price?.currency)}
        </div> : '';
    };

    const differenceTemplate = (rowData, props) => {
        let difference = 0

        if (rowData[props.field + '_price'] > 0) {
            if (rowData.owner && rowData.owner.is_map && rowData.owner.price && rowData.owner.price.value > rowData[props.field + '_price']) {
                difference = rowData.owner.price.value - rowData[props.field + '_price']
            } else if (rowData.mrp_owner && rowData.mrp_owner.is_mrp && rowData.mrp_owner.price && rowData.mrp_owner.price.value < rowData[props.field + '_price']) {
                difference = rowData[props.field + '_price'] - rowData.mrp_owner.price.value
            }
        }

        if (difference > 0) {
            return <div className="p-grid">
                {formatCurrency(difference, rowData.owner.price.currency)}
            </div>;
        } else {
            return ''
        }
    };

    const dynamicColumns = () => {
        let columns = []
        columns.push(<Column key="product_code" field="product_code" header={t('product.product_code')} headerStyle={{width: '120px', textAlign:"left"}}/>);
        columns.push(<Column key="name" field="name" body={nameTemplate} header={t('product.product_name')} headerStyle={{width: '300px'}} sortable/>);
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="price" field="price" body={ownPriceTemplate} header={isCampaignManufacturer(selectedCampaign) ? t('price_violation.map_abbreviation') : t('product_detail.own_price')} headerStyle={{width: '120px', textAlign:"right"}} sortable/>);}
        {isCampaignManufacturer(selectedCampaign) && columns.push(<Column key="mrp_price" field="mrp_price" body={mrpPriceTemplate} header={t('price_violation.mrp_abbreviation')} headerStyle={{width: '120px', textAlign:"right"}} sortable/>);}
        columns.push(<Column key="0_seller_name" field="0_seller" header={t('product.seller_name_1')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="0_seller_price" field="0_seller" body={sellerPriceTemplate} header={t('product.seller_price_1')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {isCampaignManufacturer(selectedCampaign) && columns.push(<Column key="0_seller_difference" field="0_seller" body={differenceTemplate} header={t('product.seller_diff_1')} headerStyle={{width: '100px', textAlign:"left"}}/>);}

        columns.push(<Column key="1_seller_name" field="1_seller" header={t('product.seller_name_2')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="1_seller_price" field="1_seller" body={sellerPriceTemplate} header={t('product.seller_price_2')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {isCampaignManufacturer(selectedCampaign) && columns.push(<Column key="1_seller_difference" field="1_seller" body={differenceTemplate} header={t('product.seller_diff_2')} headerStyle={{width: '100px', textAlign:"left"}}/>);}

        columns.push(<Column key="2_seller_name" field="2_seller" header={t('product.seller_name_3')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="2_seller_price" field="2_seller" body={sellerPriceTemplate} header={t('product.seller_price_3')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {isCampaignManufacturer(selectedCampaign) && columns.push(<Column key="2_seller_difference" field="2_seller" body={differenceTemplate} header={t('product.seller_diff_3')} headerStyle={{width: '100px', textAlign:"left"}}/>);}

        columns.push(<Column key="3_seller_name" field="3_seller" header={t('product.seller_name_4')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="3_seller_price" field="3_seller" body={sellerPriceTemplate} header={t('product.seller_price_4')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {isCampaignManufacturer(selectedCampaign) && columns.push(<Column key="3_seller_difference" field="3_seller" body={differenceTemplate} header={t('product.seller_diff_4')} headerStyle={{width: '100px', textAlign:"left"}}/>);}

        columns.push(<Column key="4_seller_name" field="4_seller" header={t('product.seller_name_5')} body={sellerNameTemplate} headerStyle={{width: '200px', textAlign:"left"}}/>);
        columns.push(<Column key="4_seller_price" field="4_seller" body={sellerPriceTemplate} header={t('product.seller_price_5')} headerStyle={{width: '150px', textAlign:"left"}}/>);
        {isCampaignManufacturer(selectedCampaign) && columns.push(<Column key="4_seller_difference" field="4_seller" body={differenceTemplate} header={t('product.seller_diff_5')} headerStyle={{width: '100px', textAlign:"left"}}/>);}

        return columns.map((col, i) => {return col});
    };

    return <React.Fragment>

        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.filtered_seller_price_comparison')}</h3>
                <hr></hr>
            </div>
            <div className="p-col-12">
                {loadingCompetitorNames ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div>:
                <div className="p-grid p-fluid">
                    <div className="p-col-4">
                        <span className="p-float-label">
                            <MultiSelect name="competitorNames" value={competitorNames} options={allCompetitorNames} filter={true} onChange={(e) => {setCompetitorNames(getKeyValue(e).value);}}/>
                            <label htmlFor="competitorNames">{t('product_detail.seller')}</label>
                        </span>
                    </div>

                    <div className="p-col-2" style={{marginTop: '7px'}}>
                        <Button icon="pi pi-search" className="p-button-outlined p-button-info"
                                label={t('actions.search')} onClick={(e) => {
                            search(competitorNames);
                        }} loading={loading}/>
                    </div>

                    <div className="p-col-2" style={{marginTop: '7px'}}>
                        <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success"
                                label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport}/>
                    </div>

                    <div className="p-col-4">
                    </div>
                </div>}

                {loading ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div> :
                    <DataTable value={items} scrollable scrollHeight={(window.innerHeight - 410)+"px"}
                               emptyMessage={t('actions.empty_message')}
                               paginator={true}
                               rows={100}>
                        {dynamicColumns()}
                    </DataTable>}
            </div>
        </div>

    </React.Fragment>;
};
export default FilteredSellersPriceComparison;
