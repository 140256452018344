import React, {useEffect, useState} from "react";

import {
    exportFile,
    formatCurrency,
    formatPercentage,
    getKeyValue,
    getProductDetailLink,
    isAdmin, userId
} from "../../utils/Utils";
import {toDateStringIgnoringTimezone, toDateTimeString} from "../../utils/TimeUtil";


import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {DataView} from "primereact/dataview";
import {Calendar} from "primereact/calendar";


import {ExportService} from "../../service/ExportService";
import {Button} from "primereact/button";
import {Loading} from "../../utils/InlineComponents";
import {Dialog} from "primereact/dialog";
import {AuthService} from "../../service/AuthService";
import {AppContext} from "../../HomePage";
import {AutoComplete} from "primereact/autocomplete";
import {InputText} from "primereact/inputtext";
import {QueueService} from "../../service/QueueService";
import {CampaignContext} from "../../App";
import {SelectButton} from "primereact/selectbutton";

const PriceViolations = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);
    const [violations, setViolations] = useState([]);
    const [displayScreenshot, setDisplayScreenshot] = useState(false);
    const [screenshot, setScreenshot] = useState(false);
    const [date, setDate] = useState(toDateStringIgnoringTimezone(new Date()));
    const [endDate, setEndDate] = useState(toDateStringIgnoringTimezone(new Date()));

    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);
    const [loadingScreenshot, setLoadingScreenshot] = useState(false);

    const [product_code, setProductCode] = useState("");
    const [product_name, setProductName] = useState("");
    const [competitor_name, setCompetitorName] = useState("");
    const [violationType, setViolationType] = useState('map');
    const [productSuggestions, setProductSuggestions] = useState(null);
    const [productCodeSuggestions, setProductCodeSuggestions] = useState(null);
    const dashboardService = new DashboardService();
    const exportService = new ExportService();
    const queueService = new QueueService();
    const violationTypeOptions = [
        {label: t('price_violation.map_abbreviation'), value: 'map'},
        {label: t('price_violation.mrp_abbreviation'), value: 'mrp'}
    ];

    useEffect ( ()=>{
        search();
    },[selectedCampaign, date]);

    const suggestProducts = (event) => {
        if (violations != null) {
            let results = violations.filter((violation) => {
                return violation.name.toLowerCase().startsWith(event.query.toLowerCase());
            }).map(item => item.name);

            setProductSuggestions(results);
        }
    };

    const suggestProductCodes = (event) => {
        if (violations != null) {
            let results = violations.filter((violation) => {
                return violation.product_code && violation.product_code.toLowerCase().startsWith(event.query.toLowerCase());
            }).map(item => item.product_code);

            setProductCodeSuggestions(results);
        }
    };

    const search = () => {
        if (selectedCampaign) {
            setLoading(true);
            dashboardService.getPriceViolations(selectedCampaign.id, date, endDate).then(violations=>{
                setViolations(violations);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    }

    const onExport = () => {
        setLoadingExcelExport(true);
        if (date === endDate) {
            exportService.exportPriceViolations(selectedCampaign.id, toDateStringIgnoringTimezone(date)).then((response) => {
                exportFile(response, selectedCampaign.name + "_" + t('dashboard.violations') + "_" + toDateStringIgnoringTimezone(date) + ".xlsx");
                setLoadingExcelExport(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoadingExcelExport(false);
            });
        } else {
            exportService.exportPriceViolationsBulk(userId(), selectedCampaign.id, toDateStringIgnoringTimezone(date), toDateStringIgnoringTimezone(endDate)).then((response) => {
                exportFile(response, selectedCampaign.name + "_" + t('dashboard.violations') + "_bulk_" + toDateStringIgnoringTimezone(date) + "_" + toDateStringIgnoringTimezone(endDate) + ".xlsx");
                setLoadingExcelExport(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoadingExcelExport(false);
            });
        }
    }

    const sendEmail = () => {
        let param = {"campaign_id": selectedCampaign.id, "price_created_at": toDateStringIgnoringTimezone(date)}
        queueService.createQueueItem("send_price_violations", param).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.send_report'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const takeScreenshots = () => {

        dashboardService.takeScreenshots(selectedCampaign.id).then(()=>{
            toast.current.show({severity: 'success', summary: t('price_violation.take_screenshot'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const previousDate = () => {
        let previous_day = (new Date(date)).getDate() - 1
        let previous_date = new Date(new Date(date).setDate(previous_day));
        setDate(toDateStringIgnoringTimezone(previous_date));
        setEndDate(toDateStringIgnoringTimezone(previous_date));
    }

    const nextDate = () => {
        let next_day = (new Date(date)).getDate() + 1
        let next_date = new Date(new Date(date).setDate(next_day));
        setDate(toDateStringIgnoringTimezone(next_date));
        setEndDate(toDateStringIgnoringTimezone(next_date));
    }

    const selectDate = (e) => {
        setDate(toDateStringIgnoringTimezone(e.value));
    }

    const selectEndDate = (e) => {
        setEndDate(toDateStringIgnoringTimezone(e.value));
    }

    const nameTemplate = (rowData) => {
        return<a target="_blank" rel="noopener noreferrer" href={rowData.product_url}>{rowData.name}</a>;
    };

    const priceTemplate = (rowData) => {
        return <span>{formatCurrency(rowData.value, rowData.currency)}</span>;
    };

    const differenceTemplate = (rowData) => {
        if (violationType === 'map') {
            return <span>{formatPercentage(rowData.difference)}</span>;
        } else {
            return <span>{formatPercentage(rowData.mrp_difference)}</span>;
        }
    };

    const gapTemplate = (rowData) => {
        if (violationType === 'map') {
            return <span>{formatCurrency(rowData.gap)}</span>;
        } else {
            return <span>{formatCurrency(rowData.mrp_gap)}</span>;
        }

    };

    const stockTemplate = (rowData) => {
        return <span>{rowData.stock}</span>;
    };

    const violationDaysTemplate = (rowData) => {
        let tooltip = toDateTimeString(rowData.violation_since);
        return <div data-toggle="tooltip" title={tooltip}>
            <span className="p-column-title">{t('price_violation.days')}</span>
            {rowData.violation_days}
        </div>;
    };

    const screenshotTemplate = (rowData) => {
        return <div className="p-grid p-align-center p-justify-end">
            {rowData.screenshot && <Button icon="pi pi-external-link" label={t('price_violation.show_screenshot')} onClick={() => {setScreenshot(rowData.screenshot); setLoadingScreenshot(true); setDisplayScreenshot(true)}} />}
        </div>;
    };

    const imageLoaded = () => {
        setLoadingScreenshot(false);
    }

    const dateTemplate = (rowData) => {
        return toDateTimeString(rowData.scraped_time);
    };

    const filtered_violations = violations.filter(violation=>
        (product_code==="" || (violation.product_code && violation.product_code.toLowerCase().indexOf(product_code.toLowerCase()) !== -1))
        && (product_name==="" || violation.name.toLowerCase().indexOf(product_name.toLowerCase()) !== -1)
        && ((violationType === 'map' && violation.competitor_violation_states.filter(item => item.map_violated).length !== 0)
        || (violationType === 'mrp' && violation.competitor_violation_states.filter(item => item.mrp_violated).length !== 0))
    && (competitor_name==="" || violation.competitor_violation_states.filter(violation_state=>
                    (violation_state.name?.toLowerCase().indexOf(competitor_name.toLowerCase()) !== -1)).length !== 0

            )
    );

    const productTemplate = (rowData) => {

        let states = rowData && rowData.competitor_violation_states ?
            rowData.competitor_violation_states.filter(violation_state=>
                (competitor_name === "" || violation_state.name?.toLowerCase().indexOf(competitor_name.toLowerCase()) !== -1)) : [];


        return states.length ?
            <div className="p-grid p-fluid" style={{border:"1px solid #c8c8c8", margin:"0.5em"}}>
                <div className="p-col-4 p-grid">
                    <div className="p-col-6">
                        <a target="_blank" rel="noopener noreferrer" href={rowData.product_url}>
                            <img referrerPolicy='no-referrer' className="img-fluid" src={rowData.image} alt=""/>
                        </a>
                    </div>
                    <div className="p-col-6">
                        <div className="p-col-12">
                            {getProductDetailLink(rowData.product_app_url.split("id=")[1], rowData.name ? rowData.name : t('scraping_request.product_id'))}
                        </div>
                        <div className="p-col-12">
                            {violationType === 'map' && <span><b>{t('price_violation.map') + " : " }</b>{formatCurrency(rowData.min_value, rowData.currency)}</span>}
                            {violationType === 'mrp' && <span><b>{t('price_violation.mrp') + " : " }</b>{formatCurrency(rowData.max_value, rowData.currency)}</span>}
                        </div>
                    </div>
                </div>
                <div className="p-col-8">
                    <DataTable value={states} responsive={true}>
                        <Column body={nameTemplate} header={t('product_detail.competitor')}/>
                        <Column body={priceTemplate} header={t('product_detail.price')}/>
                        <Column body={gapTemplate} header={t('price_violation.gap')}/>
                        <Column body={differenceTemplate} header={t('price_violation.difference')}/>
                        <Column body={violationDaysTemplate} header={t('price_violation.days')}/>
                        <Column body={stockTemplate} header={t('product_detail.stock')}/>
                        <Column body={dateTemplate} header={t('product_detail.date')}/>
                        <Column body={screenshotTemplate} header={t('price_violation.screenshot')}/>
                    </DataTable>
                </div>
            </div> : "";
    };

    const header = <div className="p-grid p-fluid">
        <div className="p-col-4">
            <label htmlFor="product_code">{t('product.product_code')}</label>
            <AutoComplete id="product_code" dropdown={true} value={product_code} onChange={(e) => {setProductCode(getKeyValue(e).value)}}
                          suggestions={productCodeSuggestions} completeMethod={suggestProductCodes}/>

        </div>
        <div className="p-col-4">
            <label htmlFor="product_name">{t('product.product_name')}</label>
            <AutoComplete id="product_name" dropdown={true} value={product_name} onChange={(e) => {setProductName(getKeyValue(e).value)}}
                          suggestions={productSuggestions} completeMethod={suggestProducts}/>

        </div>
        <div className="p-col-4">
            <label htmlFor="competitor_name">{t('product_detail.competitor')}</label>
            <InputText id="competitor_name" value={competitor_name} onChange={(e) => {setCompetitorName( getKeyValue(e).value)}}/>
        </div>

    </div>;

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.violations')}</h3>
                <hr></hr>
            </div>

            <div className="p-col-9">
                <Button tooltip={t('actions.previous')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left"
                        className="p-button-outlined" onClick={previousDate} style={{marginRight:'0.5em'}} />
                <Calendar id="date" name="date" readOnlyInput={true} value={new Date(date)} onChange={selectDate} showIcon={true} dateFormat="yy-mm-dd" style={{marginRight:'0.5em'}} />
                <Calendar id="end_date" name="end_date" readOnlyInput={true} value={new Date(endDate)} onChange={selectEndDate} showIcon={true} dateFormat="yy-mm-dd" style={{marginRight:'0.5em'}} />
                <Button tooltip={t('actions.next')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right"
                        className="p-button-outlined" onClick={nextDate} style={{marginRight:'0.5em'}}/>
                <Button icon="pi pi-file-excel" style={{marginRight:'0.5em'}} className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
                <Button icon="pi pi-envelope" style={{marginRight:'0.5em'}} className="p-button-outlined p-button-success" label={t('actions.send_email')} onClick={sendEmail} />
                {isAdmin() && <Button icon="pi pi-image" className="p-button-outlined p-button-warning" label={t('price_violation.take_screenshot')} onClick={takeScreenshots} />}
            </div>
            <div className="p-col-3">
                <SelectButton value={violationType} optionLabel="label" options={violationTypeOptions}
                              onChange={(e) => {setViolationType( getKeyValue(e).value)}}
                              style={{ marginRight:'.5em', float: 'right'}}/>
            </div>

            <div className="p-col-12">
                {loading ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div> :
                    <DataView value={filtered_violations} paginator={true} rows={50} layout="grid"
                              emptyMessage={t('actions.empty_message')}
                              header={header} itemTemplate={productTemplate}/>}

                {displayScreenshot && <div className="card">
                    <Dialog header={t('price_violation.screenshot')} visible={displayScreenshot} modal={true} width="600px" onHide={() => setDisplayScreenshot(false)}>
                        {loadingScreenshot && <div className="p-col-12" style={{textAlign:'center'}}>
                            <Loading/>
                        </div>}
                        <img style={{height: '450px', width: '600px'}} onLoad={imageLoaded} src={`${AuthService.baseURL}files/price_violation_screenshot?file_id=${screenshot}`} alt="Screenshot" />
                    </Dialog>
                </div>}
            </div>

        </div>

    </React.Fragment>;
};
export default PriceViolations;
