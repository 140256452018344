import React, {useEffect, useState} from "react";

import {formatCurrency} from "../../utils/Utils";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {UserService} from "../../service/UserService";
import {Row} from "primereact/row";
import {ColumnGroup} from "primereact/columngroup";
import {toDateString, toDateStringIgnoringTimezone} from "../../utils/TimeUtil";

const Income = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);
    const [totalIncome, setTotalIncome] = useState([]);
    const [totalBalance, setTotalBalance] = useState([]);
    const [totalNetIncome, setTotalNetIncome] = useState([]);


    const [loading, setLoading] = useState(false);

    const userService = new UserService();

    useEffect ( ()=>{
        search();
    }, []);

    const search = () => {
        setLoading(true);
        userService.getIncome().then(data=>{
            setItems(data.incomes);
            setTotalIncome(data.total_income);
            setTotalBalance(data.total_balance);
            setTotalNetIncome(data.total_net_income);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const incomeTemplate = (rowData) => {
        return rowData.income ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.income, "USD")}
        </div> : '';
    };

    const balanceTemplate = (rowData) => {
        let balance = rowData.balance ? rowData.balance : 0
        return <div className="p-grid p-justify-end">
            {formatCurrency(balance, "USD")}
        </div>;
    };

    const netIncomeTemplate = (rowData) => {
        let balance = rowData.balance ? rowData.balance : 0
        let income = rowData.income ? rowData.income : 0
        let netIncome = income - balance
        return <div className="p-grid p-justify-end">
            {formatCurrency(netIncome, "USD")}
        </div>;
    };

    const footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Total:" colSpan={6} footerStyle={{textAlign: 'right'}}/>
            <Column footer={formatCurrency(totalIncome, "USD")} footerStyle={{textAlign: 'right'}}/>
            <Column footer={formatCurrency(totalBalance, "USD")} footerStyle={{textAlign: 'right'}}/>
            <Column footer={formatCurrency(totalNetIncome, "USD")} footerStyle={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>;

    const dateTemplate = (rowData) => <span>{toDateStringIgnoringTimezone(rowData.next_payment_date)}</span>;

    return <React.Fragment>


         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <DataTable value={items} footerColumnGroup={footerGroup} emptyMessage={t('actions.empty_message')} paginator={true} rows={100}>
                 <Column body={IndexTemplate} style={{width:'3em'}} />
                 <Column field="full_name" header={t('user.name')} sortable filter={true} filterMatchMode="contains"/>
                 <Column field="email" header={t('user.email')} sortable filter={true}  filterMatchMode="contains"/>
                 <Column field="subscription_name" header={t('subscription.plan')} sortable filter={true}  filterMatchMode="contains"/>
                 <Column field="payment_method_type" header={t('subscription.payment_method')} sortable filter={true}  filterMatchMode="contains"/>
                 <Column field="next_payment_date" body={dateTemplate} style={{ width: '160px'}} header={t('user.next_payment_date')} sortable/>
                 <Column key="income" field="income" body={incomeTemplate} header={t('subscription.monthly_billing')} sortable headerStyle={{width: '150px', textAlign:"right"}}/>
                 <Column key="balance" field="balance" body={balanceTemplate} header={t('user.balance')} sortable headerStyle={{width: '150px', textAlign:"right"}}/>
                 <Column body={netIncomeTemplate} header={t('subscription.monthly_net_billing')} sortable headerStyle={{width: '170px', textAlign:"right"}}/>
             </DataTable>}

    </React.Fragment>;
};
export default Income;
