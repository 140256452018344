import axios from 'axios';
import {LanguageService} from "./LanguageService";
import {user} from "../utils/Utils";
import {DateTime} from "luxon";

export class AuthService {
    static baseURL = `https://api.pricerest.com/`;
    static coreApiURL = `https://pricerest-core-api.herokuapp.com/`;
    static reportApiURL = `https://pricerest-report-api.herokuapp.com/`;
    static reportBaseURLs = ['dashboards/', 'product_export/', 'product_import_excel/', 'product_upload/', 'product_import_excel/']
    // static baseURL = `http://localhost:8000/`;
    // static coreApiURL = `http://localhost:8000/`;
    // static reportApiURL = `http://localhost:8000/`;

    static getAxios = (url) => {
        let baseURL = AuthService.coreApiURL;
        let timeout = 40000;
        if (AuthService.reportBaseURLs.some(baseURL => url.includes(baseURL))) {
            baseURL = AuthService.reportApiURL
            timeout = 100000;
        }
        return axios.create({
            baseURL: baseURL,
            timeout: timeout,
            headers: {'Authorization': 'Token ' + localStorage.getItem("id_token")},
            validateStatus: function (status) {
                return status >= 200 && status <= 226 ;
            }
        });
    };

    static logout = () => {
        window.location = '#/login';

        localStorage.removeItem("id_token");
        localStorage.removeItem("user");
        localStorage.removeItem("loggedInEmail");
        localStorage.removeItem("token_created_time");
        localStorage.removeItem("subscription");
        localStorage.removeItem("next_subscription");
        localStorage.removeItem("loginRetry");
        localStorage.removeItem("i18nextLng");
        localStorage.removeItem("language");
    };



    static apiGet(url, config)
    {
        return AuthService.getAxios(url).get(url, config).catch((error) => {throw error});
    }
    static apiPut(url, data, config)
    {
        return AuthService.getAxios(url).put(url, data, config).catch((error) => {throw error});
    }
    static apiPost(url, data, config)
    {
        return AuthService.getAxios(url).post(url, data, config).catch((error) => {throw error});
    }
    static apiDelete(url, config)
    {
        return AuthService.getAxios(url).delete(url, config).catch((error) => {throw error});
    }

    static setToken = idToken => {
        localStorage.setItem("id_token", idToken);
    };

    static getToken = () => {
        return localStorage.getItem("id_token");
    };

    static setUser (user) {
        localStorage.setItem("user", JSON.stringify(user));
    };

    static changeIsAdmin () {
        let u = user();
        u.is_admin = false;
        AuthService.setUser(u);
    };

    static setLoggedInEmail (email) {
        localStorage.setItem("loggedInEmail", email);
    };

    static setSubscription (subscription) {
        localStorage.setItem("subscription", JSON.stringify(subscription));
    };
    static setNextSubscription (next_subscription) {
        localStorage.setItem("next_subscription", JSON.stringify(next_subscription));
    };
    static setTokenCreatedTime = createdTime => {
        localStorage.setItem("token_created_time", createdTime.toMillis());
    };

    static getTokenCreatedTime = () => {
        return localStorage.getItem("token_created_time");
    };

    static loggedIn = () => {
        if (AuthService.getTokenCreatedTime())
        {
            const token = AuthService.getToken();
            let now = DateTime.now();
            let end = DateTime.fromMillis(+AuthService.getTokenCreatedTime());
            if (!end.isValid)
                return false;
            end = end.startOf('day').plus({days:1, hours: 3})
            return !!token && now < end;
        }
        return false;
    };

    static setUserAndToken(res) {
        AuthService.setToken(res.data.token);
        AuthService.setTokenCreatedTime(DateTime.now());
        AuthService.setLoggedInEmail(res.data.logged_in_email);
        AuthService.setUser(res.data.user);
        AuthService.setSubscription(res.data.subscription);
        AuthService.setNextSubscription(res.data.next_subscription);

        LanguageService.setLanguage(res.data.user.language);

    }
}
