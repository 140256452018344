import {AuthService} from "./AuthService";
import {isAdmin} from "../utils/Utils";
import {asyncLocalStorage, asyncSessionStorage} from "../utils/LocalStorageUtil";

export class UserService {

    url_account = `accounts/`;
    url_subscription = `subscriptions/`;

    async getUsers (get_subscription, force_update, all_user) {
        if (force_update || !sessionStorage.getItem("userList"))
            return AuthService.apiGet(this.url_account, {
                params: {
                    get_subscription: get_subscription,
                    all_user: all_user,
                }
            }).then(res => {
                if (isAdmin())
                    asyncSessionStorage.setItem("userList", JSON.stringify(res.data.data));
                return res.data.data
            });
        else
            return asyncSessionStorage.getItem("userList").then(d=>{
                return JSON.parse(d);
            });
    }

    updateUser(user){
        return AuthService.apiPut(this.url_account + user.id + "/", {user}).then(res => res.data.data);
    }

    deleteUser(user_id){
        return AuthService.apiDelete(this.url_account + user_id).then(res => res.data.message);
    }


    lock_unlock(user_id, locked){
        if (locked)
            return AuthService.apiPut(this.url_account + user_id + "/unlock/").then(res => res.data.data);
        else
            return AuthService.apiPut(this.url_account + user_id + "/lock/").then(res => res.data.data);
    }

    active_deactivate(user_id, activated){
        if (activated)
            return AuthService.apiPut(this.url_account + user_id + "/deactivate/").then(res => res.data.data);
        else
            return AuthService.apiPut(this.url_account + user_id + "/activate/").then(res => res.data.data);
    }

    approve(user_id){
        return AuthService.apiPut(this.url_account + user_id + "/approve/").then(res => res.data.data);
    }

    changeUserPassword(user_id, new_password){
        return AuthService.apiPut(this.url_account + user_id + "/change_password/", {old_password:"", new_password}).then(res => res.data.data);
    }

    updateSubscription(subscription){
        return AuthService.apiPut(this.url_subscription + subscription.id + "/", subscription).then(res => res.data.data);
    }

    convertSpecialSubscription(subscription, planId){
        return AuthService.apiPut(this.url_subscription + subscription.id + "/convert_special_subscription/", {plan_id: planId}).then(res => res.data.data);
    }

    getSubscriptionHistory(user_id){
        return AuthService.apiGet(this.url_account + user_id + "/subscriptions/").then(res => res.data.data);
    }

    getSpecialPlan(user_id){
        return AuthService.apiGet(this.url_account + user_id + "/special_plan/").then(res => res.data.data);
    }

    getIncome(){
        return AuthService.apiGet(this.url_account + "monthly_total_income/").then(res => res.data.data);
    }

    getKpis(){
        return AuthService.apiGet(this.url_account + "kpis/").then(res => res.data.data);
    }

    getPaymentLogs(startDate, endDate){
        return AuthService.apiGet(this.url_account + "payment_logs/",{
            params: {start_date: startDate, end_date: endDate}
        }).then(res => res.data.data);
    }

    deletePaymentLog(payment_log_id){
        return AuthService.apiDelete(this.url_account + payment_log_id + "/payment_logs/").then(res => res.data.data);
    }

    getExpenses(startDate, endDate){
        return AuthService.apiGet(this.url_account + "expenses/",{
            params: {start_date: startDate, end_date: endDate}
        }).then(res => res.data.data);
    }

    importExpenses(file){
        let formData = new FormData();
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return AuthService.apiPost(this.url_account + "import_expenses/", formData, config).then(res => res.data.data);
    }

    createExpense(expense){
        return AuthService.apiPost(this.url_account + "create_expense/", expense).then(res => res.data.data);
    }

    updateExpense(expense){
        return AuthService.apiPut(this.url_account  + expense.id + "/update_expense/", expense).then(res => res.data.data);
    }

    deleteExpense(expense_id){
        return AuthService.apiDelete(this.url_account + expense_id + "/delete_expense/").then(res => res.data.data);
    }

    getCurrencies(){
        return AuthService.apiGet(this.url_account + "currencies/").then(res => res.data.data);
    }

    getUserBasedCurrencies(){
        return AuthService.apiGet(this.url_account + "user_based_currencies/").then(res => res.data.data);
    }

    getAllUsers() {
        return AuthService.apiGet(this.url_account + "all_users/").then(res => res.data.data);
    }

    getTokens(startDate, endDate) {
        return AuthService.apiGet(this.url_account + "tokens/",{
            params: {start_date: startDate, end_date: endDate}
        }).then(res => res.data.data);
    }
}
