import React, {useEffect, useState} from "react";
import {AccountService} from "../../service/AccountService";
import {emailValidator, formValid, getKeyValue, isCampaignManufacturer, isCampaignSeller, user} from "../../utils/Utils";

import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {AuthService} from "../../service/AuthService";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";
import {MultiSelect} from "primereact/multiselect";
import {Loading} from "../../utils/InlineComponents";

const Notification = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const {campaigns} = React.useContext(CampaignContext);

    const defaultTrue = { active:true, email:user().email, campaigns:null, period: null};
    const defaultFalse = { active:false, email:user().email, campaigns:null, period: null};

    const types = [
        {value:'summary_competitor_price_list', label:t('dashboard.price_comparison')},
        {value:'price_comparison_summary', label:t('dashboard.price_comparison_summary')},
        {value:'n_cheapest_seller_price_comparison', label:t('dashboard.n_cheapest_seller_price_comparison')},
        {value:'competitor_price_list', label:t('dashboard.excel_competitor_list')},
        {value:'detailed_product_list', label:t('dashboard.detailed_product_list')},
        {value:'price_stock_changes', label:t('dashboard.changes')},
        {value:'price_violation', label:t('dashboard.violations')},
        {value:'unauthorized_sellers', label:t('dashboard.unauthorized_sellers')},
        {value:'inactive_urls', label:t('dashboard.inactive_urls')},
        {value:'products_without_code', label:t('dashboard.products_without_code')},
        {value:'new_sellers', label:t('dashboard.new_sellers')},
        {value:'applied_dynamic_pricing', label:t('dashboard.dynamic_pricing')}
    ];

    const periodOptions = [
        {value:'each_check', label:t('user.notification.periods.each_check')},
        {value:'daily', label:t('user.notification.periods.daily')},
        {value:'weekly', label:t('user.notification.periods.weekly')},
        {value:'monthly', label:t('user.notification.periods.monthly')}
    ];

    const [currentUser, setCurrentUser] = useState(user());
    const [notification, setNotification] = useState(null);
    const [newNotification, setNewNotification] = useState({email:""});
    const [formErrors, setFormErrors] = useState({email:""});
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);

    const accountService = new AccountService();

    useEffect(()=>{
        let nots = {};
        if(currentUser){
            let emails = [];
            emails.push(currentUser.email);
            if (currentUser.notification)
                Object.keys(currentUser.notification).forEach(n=>{
                    return currentUser.notification[n].forEach(n1=> {
                        if (emails.findIndex(email=> email === n1.email) === -1)
                            emails.push(n1.email);
                    });
                });
            types.forEach(t => {
                if (!campaigns.some(c=> isCampaignManufacturer(c)) && (t.value === "price_violation" || t.value === "unauthorized_sellers"))
                    return;
                else if (!campaigns.some(c=> isCampaignSeller(c)) && t.value === "applied_dynamic_pricing" )
                    return;

                let defaultNot = defaultFalse;
                if (t.value === "summary_competitor_price_list" || t.value === "price_stock_changes" || t.value === "price_violation" || t.value === "applied_dynamic_pricing")
                    defaultNot = defaultTrue;

                let not = [];
                emails.forEach(email =>{
                    if (currentUser.notification && currentUser.notification[t.value] && currentUser.notification[t.value].length !== 0 &&
                        currentUser.notification[t.value].findIndex(n=> n.email === email) !== -1)
                        not.push({...defaultNot, ...currentUser.notification[t.value].find(n=> n.email === email)});
                    else
                        not.push({...defaultNot, email: email});
                });
                nots[t.value] = not;
            });
        }
        setNotification(nots);
    },[currentUser])

    const add = () => {
        checkErrors(newNotification).then((formErrors)=>{
            if (formValid(formErrors)) {
                if (notifications().some(s => s.email === newNotification.email))
                {
                    toast.current.show({severity: 'warn', summary: t('user.notification.exist'), detail: ""});
                    return;
                }
                types.forEach(t=>{
                    if (!notification[t.value])
                        notification[t.value] = []
                    notification[t.value].push({...defaultFalse, email:newNotification.email});
                })
                setNewNotification({email:""});
                save(notification);
            }
            else
                setFormErrors(formErrors);
        })

    };

    const deleteMail = (rowData) => {

        types.forEach(t=>{
            notification[t.value] && notification[t.value].forEach(n=> {
                notification[t.value] = notification[t.value].filter(n => n.email !== rowData.email);
            });
        });

        save(notification);
    };

    const changeAndSave = (e, rowData) => {

        const { key, value } = getKeyValue(e);

        if (notification[rowData.type])
            notification[rowData.type][notification[rowData.type].findIndex(n => n.email === rowData.email)][key] = value;
        else
            notification[rowData.type].push({...rowData, key: value});

        save(notification);
    };

    const onChange = (e) =>{

        const { key, value } = getKeyValue(e);

        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setNewNotification({...newNotification, [key] : value});
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0 ? "p-error" : null
    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "email") {
            errorText =  value.length > 0 ? emailValidator(value, t) : t('validations.invalid_email');
        }

        return errorText;
    }

    const checkErrors = async (notification) => {

        let errors = { ...formErrors };

        Object.entries(notification).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        return errors;
    };

    const notifications = () => {
        if (!notification)
            return;

        let result = [];
        types.forEach(t=> {
            notification[t.value]?.forEach(obj=> {
                result.push({ ...obj, type: t.value });
            });
        });

        return result;
    };

    const save =(notification) => {

        currentUser.notification = notification;
        setLoading(true);
        accountService.updateMyAccount(currentUser).then(user_updated=>{
            setNotification({...notification});
            AuthService.setUser(user_updated);
            setCurrentUser(user_updated);
            toast.current.show({severity: 'success', summary: t('account.save_notification'), detail: t('message_detail.successful')});
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const emailTemplate = (rowData) => {
        if (rowData.email === currentUser.email)
            return rowData.email;
        return <><label>{rowData.email}</label> <Button icon="pi pi-trash" className="p-button-outlined p-button-rounded p-button-danger" tooltip={t('actions.remove')} onClick={() => deleteMail(rowData)} />
        </>;
    };

    const typeTemplate = (rowData) => {
        return <div className="p-ml-6">{types.find(type => type.value === rowData.type).label}</div>;
    };

    const activeTemplate = (rowData) => {
        return <Checkbox id="" name="active" value={rowData.active} checked={rowData.active === true} onChange={(e) => {changeAndSave(e, rowData)}} />;
    };

    const campaignsTemplate = (rowData) => {
        return <MultiSelect id="" name="campaigns" value={rowData.campaigns}
                            options={campaigns.sort((a, b) => (a.name ? a.name.toLowerCase() : "").localeCompare((b.name ? b.name.toLowerCase() : ""), undefined, {sensitivity: 'base'}))}
                            optionLabel="name" optionValue="id"
                            placeholder={t('user.notification.choose_campaigns')}
                            onChange={(e) => {changeAndSave(e, rowData)}} />;
    };

    const periodTemplate = (rowData) => {
        return <Dropdown id="" name="period" value={rowData.period ?? "each_check"} options={periodOptions} onChange={(e) => {changeAndSave(e, rowData)}} />;
    };

    return loading ?
        <div className="p-col-12" style={{textAlign: 'center'}}>
            <Loading/>
        </div> :
        <div className="p-grid ">

            <div className="p-col-12">
                <label htmlFor="state">{t('user.notification.email')}</label>
                <InputText id="email" name="email" value={newNotification.email} onChange={onChange}
                           className={errorClass("email") + " p-mr-1 p-ml-1"}/>
                {errorClass("email") && (<Message severity="error" text={formErrors.email}/>)}
                <Button label={t('actions.add')} icon="pi pi-plus" className="p-button-outlined p-button-success" onClick={add}/>
            </div>
            <DataTable value={notifications()}
                       rowGroupMode="subheader" sortField="email" sortOrder={1} groupField="email"
                       expandableRowGroups
                       expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                       rowGroupHeaderTemplate={emailTemplate} rowGroupFooterTemplate={()=>{return ""}} >
                <Column field="type" header={t('user.notification.type')} body={typeTemplate}/>
                <Column field="active" header={t('user.notification.active')} body={activeTemplate} style={{width: '6em'}}/>
                {/*<Column field="campaigns" header={t('user.notification.campaigns')} body={campaignsTemplate}/>*/}
                {/*<Column field="period" header={t('user.notification.period')} body={periodTemplate}/>*/}
            </DataTable>
        </div>
};
export default Notification
