import React, {useEffect, useState} from "react";

import {Line} from "react-chartjs-2";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";
import {DashboardService} from "../../service/DashboardService";
import {Calendar} from "primereact/calendar";
import {exportFile, formatCurrencyText, getDatePrice, getKeyValue, getProductDetailLink} from "../../utils/Utils";
import {Loading} from "../../utils/InlineComponents";
import {Button} from "primereact/button";
import {
    getFirstDayOfTheYear,
    getMonthsAgoFirstDay,
    getWeekNumber,
    getWeeksAgo,
    toDateString
} from "../../utils/TimeUtil";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {TabPanel, TabView} from "primereact/tabview";
import {ExportService} from "../../service/ExportService";
import {Chip} from "primereact/chip";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import {AutoComplete} from "primereact/autocomplete";

const PriceIndex = () => {

    const {t, setError, toast} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [dates, setDates] = useState([getWeeksAgo(1), new Date()]);
    const [selectedCampaigns] = useState(null)

    const [productSuggestions, setProductSuggestions] = useState(null);
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [discountTypes, setDiscountTypes] = useState([]);

    const [product_name, setProductName] = useState("");
    const [brand, setBrand] = useState([]);
    const [category, setCategory] = useState([]);
    const [discountType, setDiscountType] = useState([]);

    const [priceIndexes, setPriceIndexes] = useState(null);

    const [loading, setLoading] = useState(false);
    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect ( ()=>{
        search();
    },[selectedCampaign, dates[0], dates[1]]);

    const search = () => {
        if (dates[0] && dates[1] && selectedCampaign) {
            setLoading(true);
            dashboardService.getPriceIndexReport(selectedCampaign.id, dates).then(data=>{
                setPriceIndexes(data);

                let distBrands = [...new Set(data.map(item => item.brand ? item.brand : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                distBrands = distBrands.filter(brand => brand !== undefined && brand !== '')
                setBrands(distBrands.map(brand => ({label: brand, value: brand})))

                let distCategories = [...new Set(data.map(item => item.category ? item.category : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                distCategories = distCategories.filter(category => category !== undefined && category !== '')
                setCategories(distCategories.map(category => ({label: category, value: category})))

                let distDiscountTypes = [...new Set(data.map(item => item.discount_type ? item.discount_type : []))];
                let discountTypes = []
                discountTypes.push("");
                distDiscountTypes = distDiscountTypes.forEach(t=> {
                    t.forEach(t1 =>
                    {
                        if (!discountTypes.includes(t1))
                            discountTypes.push(t1);
                    })
                })
                setDiscountTypes(discountTypes);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    }

    const suggestProducts = (event) => {
        if (priceIndexes != null) {
            let results = priceIndexes.filter((priceIndex) => {
                return priceIndex.product_name.toLowerCase().startsWith(event.query.toLowerCase());
            }).map(item => item.product_name);

            setProductSuggestions([...new Set(results)]);
        }
    };

    const onExport = () => {
        if (dates[0] && dates[1]) {
            setLoading(true);
            exportService.exportProductPriceIndex(selectedCampaign.id, toDateString(dates[0]), toDateString(dates[1])).then((response) => {
                exportFile(response, selectedCampaign.name + "_" + t('dashboard.price_index.header') + "_" + toDateString(dates[0]) + " - " + toDateString(dates[1]) + ".xlsx");
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const chartData = () => {
        let startDate = dates[0];
        let endDate = dates[1];
        let days = [...new Set(filteredIndexes().map(s=> s.date))];

        let colors = ['#FF7F00', '#A6CEE3', '#B2DF8A', '#FC9A99', '#FDBF6F', '#CAB2D6', '#1E78B4', '#33A02C'];


        let dataCompetitors = [];
        let i = 0;
        Object.keys(filteredIndexes()[0]).forEach((key)=>{
            if (key.endsWith('_index'))
                if (key.startsWith('1_'))
                    dataCompetitors.push({name: key, color: "red", data: []})
                else if (key.startsWith('2_')) {
                    dataCompetitors.push({name: key, color: colors[i++], data: []});
                }
        });

        days.forEach(day=>{
            dataCompetitors.forEach(comp=>{
                let days_data = filteredIndexes().filter(p=>{
                    return p.date === day
                }).map(p=> p[comp.name]).filter(p=> p);
                let average = days_data.reduce((total, next) => total + next, 0) / days_data.length;
                comp.data.push({x: day, y: parseFloat(average)})
            });
        });

        let datasets = [];
        dataCompetitors.forEach((c, i )=> {
                datasets.push({
                    type: 'line',
                    label: c.name.replace('_index', ''),
                    borderColor: c.color,
                    borderWidth: 2,
                    fill: false,
                    data: c.data,
                    lineTension: 0
                });
            });

        return {
            labels: days,
            datasets: datasets
        };

    };

    const chartOptions = () => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend:{
                labels: {
                    filter: function(legendItem) {
                        return  !(legendItem.datasetIndex === 1);
                    }
                }
            },
            scales: {
                y: {
                    display: true,
                    ticks: {
                        fontSize: 10
                    }
                },
                y1: {
                    display: true,
                    grid:{
                        display: false,
                    },
                    ticks: {
                        fontSize: 10,

                    }
                }

            },
            plugins: {
                labels: false,
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    titleFontSize:20,
                    bodyFontSize:15,
                    bodySpacing:5,
                    displayColors:true,
                    callbacks:{
                        label: function(item){
                            if (item.dataset.label === t('product_detail.price'))
                                return item.dataset.label + " : " + formatCurrencyText(item.raw.y, item.raw.currency)
                                    + (item.raw.list_price && item.raw.list_price !== item.raw.y ? " / "
                                    + (item.raw.discount_rate ? "(%" + item.raw.discount_rate.toFixed(2) + ") " : "")
                                    + formatCurrencyText(item.raw.list_price, item.raw.currency) : "");
                            else
                                return item.dataset.label + " : " + item.raw.y;
                        }
                    },
                },
            }

        };
    };

    const yearMonthTemplate = (rowData) => {
        return rowData.date.substring(0,7);
    }
    const weekTemplate = (rowData) => {
        return getWeekNumber(rowData.date);
    }

    const discountTypeTemplate = (rowData) =>{
        if (rowData.discount_type)
            return rowData.discount_type.map(t => {
                    return <Chip label={t} />
                })
        return "";
    }

    const headerGroup = (
        priceIndexes ? <ColumnGroup>
            <Row>
                <Column rowSpan={2} header={t('date.year')+"-"+t('date.month')} headerStyle={{width: '80px'}}/>
                <Column rowSpan={2} header={t('date.week')} headerStyle={{width: '60px'}}/>
                <Column rowSpan={2} header={t('common.created_at')} headerStyle={{width: '100px'}}/>
                <Column rowSpan={2} header={t('product.product_code')} headerStyle={{width: '80px'}}/>
                <Column rowSpan={2} header={t('product.product_name')} headerStyle={{width: '200px'}}/>
                <Column rowSpan={2} header={t('product.brand')} headerStyle={{width: '100px'}}/>
                <Column rowSpan={2} header={t('product.category')} headerStyle={{width: '100px'}}/>
                <Column rowSpan={2} header={t('product.discount_type')} headerStyle={{width: '100px'}}/>
                <Column header={t('common.price')} colSpan={Object.keys(priceIndexes[0]).filter(key=>{
                        return (key.startsWith('1_') || key.startsWith('2_')) && key.endsWith('_data');

                    }).length} />
                <Column header={t('common.index')} colSpan={Object.keys(priceIndexes[0]).filter(key=>{
                        return (key.startsWith('1_') || key.startsWith('2_')) && key.endsWith('_index');

                    }).length} />
                <Column header={t('common.average')} colSpan={2} />
            </Row>
            <Row>
                {
                    Object.keys(priceIndexes[0]).sort().map(key=>{
                        if ((key.startsWith('1_') || key.startsWith('2_')) && key.endsWith('_data'))
                            return <Column field={key} header={key.substring(2, key.length).replace("_data", "")}/>
                    })
                }
                {
                    Object.keys(priceIndexes[0]).sort().map(key=>{
                        if ((key.startsWith('1_') || key.startsWith('2_')) && key.endsWith('_index'))
                            return <Column field={key} header={key.substring(2, key.length).replace("_data", "")}/>
                    })
                }
                {
                    Object.keys(priceIndexes[0]).sort().map(key=>{
                        if (key.startsWith('average'))
                            return <Column field={key} header={t('common.'+(key === "average" ? "price" : "index"))}/>
                    })
                }
            </Row>
        </ColumnGroup>
            :<></>
    );

    const filteredIndexes = () => {
        return priceIndexes.filter(p=> {
            return (category.length === 0 || (category.includes(p.category ?? "" )) ) &&
                (brand.length === 0 || (brand.includes(p.brand ?? ""))  ) &&
            (discountType.length === 0 || ((p.discount_type.length === 0 && discountType.includes(""))
             || (( p.discount_type.some(t => discountType.indexOf(t) !== -1 ))))) &&
                (product_name==="" || p.product_name.toLowerCase().indexOf(product_name.toLowerCase()) !== -1)
        });
    }

    return <React.Fragment>

        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.price_index.header')}</h3>
                <hr></hr>
            </div>
            <div className="p-col-12">
                <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" dateFormat="yy/mm/dd" value={dates} onChange={(e) => setDates(getKeyValue(e).value)} showIcon={true} showButtonBar={true}/>
                <Button icon="pi pi-file-excel" className="p-ml-1 p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loading} />
            </div>
            <div className="p-col-3 p-fluid">
                <span className="p-float-label">
                    <AutoComplete id="product_name" dropdown={true} value={product_name} onChange={(e) => {setProductName(getKeyValue(e).value)}}
                              suggestions={productSuggestions} completeMethod={suggestProducts}/>
                    <label htmlFor="product_name">{t('product.product_name')}</label>
                </span>
            </div>
            <div className="p-col-3 p-fluid">
                <span className="p-float-label">
                    <MultiSelect name="brand" value={brand} options={brands} onChange={(e) => {setBrand(getKeyValue(e).value)}} filter={true} maxSelectedLabels={2}/>
                    <label htmlFor="brand">{t('product.brand')}</label>
                </span>
            </div>
            <div className="p-col-3 p-fluid">
                <span className="p-float-label">
                    <MultiSelect name="category" value={category} options={categories} onChange={(e) => {setCategory(getKeyValue(e).value)}} filter={true} maxSelectedLabels={2}/>
                    <label htmlFor="category">{t('product.category')}</label>
                </span>
            </div>
            <div className="p-col-3 p-fluid">
                <span className="p-float-label">
                    <MultiSelect name="discount_type" value={discountType} options={discountTypes} onChange={(e) => {setDiscountType(getKeyValue(e).value)}} filter={true} maxSelectedLabels={2}/>
                    <label htmlFor="discount_type">{t('product.discount_type')}</label>
                </span>
            </div>
            {loading ?
                <div className="p-col-12" style={{textAlign:'center'}}>
                    <Loading/>
                </div> :
                (priceIndexes ?
                <div className="p-col-12">
                    <TabView>
                        <TabPanel header={t('dashboard.price_index.table')}>
                            <div className="p-col-12 p-grid">
                                <DataTable value={filteredIndexes()} responsive={true} sortField="date" sortOrder={1}
                                headerColumnGroup={headerGroup}>
                                    <Column field="date" body={yearMonthTemplate} />
                                    <Column field="date" body={weekTemplate}/>
                                    <Column field="date"/>
                                    <Column field="product_code"/>
                                    <Column field="product_name"/>
                                    <Column field="brand"/>
                                    <Column field="category"/>
                                    <Column field="discount_type" body={discountTypeTemplate}/>
                                    {
                                        Object.keys(priceIndexes[0]).sort().map(key=>{
                                            if ((key.startsWith('1_') || key.startsWith('2_')) && key.endsWith('_data'))
                                                return <Column field={key} header={key.substring(2, key.length).replace("_data", "")}/>
                                        })
                                    }

                                    {
                                        Object.keys(priceIndexes[0]).sort().map(key=>{
                                            if ((key.startsWith('1_') || key.startsWith('2_')) && key.endsWith('_index'))
                                                return <Column field={key} header={key.substring(2, key.length).replace("_data", "")}/>
                                        })
                                    }
                                    {
                                        Object.keys(priceIndexes[0]).sort().map(key=>{
                                            if (key.startsWith('average'))
                                                return <Column field={key} header={key}/>
                                        })
                                    }
                                </DataTable>
                            </div>
                        </TabPanel>
                        <TabPanel header={t('dashboard.price_index.daily_graph')}>
                            <Line data={chartData} options={chartOptions} />
                        </TabPanel>
                    </TabView>
                </div> : <></>)
            }
        </div>

    </React.Fragment>;
};
export default PriceIndex;
