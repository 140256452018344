import React, {useEffect, useState} from "react";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";
import {SpecialFieldService} from "../../service/SpecialFieldService";
import {PickList} from "primereact/picklist";
import {Loading} from "../../utils/InlineComponents";
import {Button} from "primereact/button";
import {ProductMatchingService} from "../../service/ProductMatchingService";

const ProductMatching = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);
    const [loading, setLoading] = useState(false);
    const [sourceFields, setSourceFields] = useState([]);
    const [targetFields, setTargetFields] = useState([]);

    const specialFieldService = new SpecialFieldService();
    const productMatchingService = new ProductMatchingService();

    useEffect ( ()=>{
        setLoading(true);
        specialFieldService.getSpecialFields().then(data => {
            let productCodes = [{name: 'sku'}, {name: 'mpn'}, {name: 'ean'}, {name: 'upc'}, {name: 'asin'}, {name: 'barcode'}, {name: 'search_key'}, {name: 'brand'}, {name: 'category'}]
            setSourceFields(productCodes.concat(data));
            setLoading(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoading(false);
        });
    },[]);

    const onChange = (event) => {
        setSourceFields(event.source);
        setTargetFields(event.target);
    }

    const matchCompetitorsUsingSelectedFields = () =>{
        productMatchingService.matchCompetitorsUsingSelectedFields(selectedCampaign.id, targetFields).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.auto_match_suggestions'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const itemTemplate = (item) => {
        return (
            <div>
                {item.name}
            </div>
        );
    }

    return <React.Fragment>
        {loading ?
            <div className="p-col-12" style={{textAlign: 'center'}}>
                <Loading/>
            </div> :
            <div className="p-grid p-fluid p-align-center">
                <div className="p-col-12">
                </div>
                <div className="p-col-12">
                    <h2>{t('actions.match_suggestions_using_selected_fields')}</h2>
                </div>
                <div className="p-col-12">
                    <PickList source={sourceFields} target={targetFields} itemTemplate={itemTemplate}
                              showSourceControls={false} showTargetControls={false}
                              sourceHeader="Available" targetHeader="Selected"
                              sourceStyle={{height: '342px'}} targetStyle={{height: '342px'}}
                              onChange={onChange}/>
                </div>
                <div className="p-col-12">
                    <Button icon="pi pi-refresh" className="p-button-outlined p-button-success" style={{marginRight: '.5em'}}
                            label={t('actions.match_suggestions_using_selected_fields')} onClick={matchCompetitorsUsingSelectedFields}/>
                </div>
            </div>
        }

    </React.Fragment>;
};
export default ProductMatching
