import React, {useEffect, useState} from 'react';


import {CampaignService} from "../../service/CampaignService";





import * as moment from "moment";
import {toTitleCase} from "../../utils/Utils";
import {getCurrentDate, toDateTimeString} from "../../utils/TimeUtil";
import JobSchedulingCalendarView from "./JobSchedulingCalendarView";
import JobSchedulingTable from "./JobSchedulingTable";
import {TabPanel, TabView} from "primereact/tabview";
import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";



const JobScheduling = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);

    const campaignService = new CampaignService();

    useEffect(() =>{
        list();
    }, []);

    const list = () => {
        setLoading(true);
        campaignService.scrapingJobSettings().then(jobList => {
            jobList = jobList.map(jl=> ({ ...jl, user_full_name: toTitleCase(jl.user.first_name) + ' ' + toTitleCase(jl.user.last_name)}));
            setJobs(jobList);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const durationTemplate = (rowData) => {
        return rowData.scraping_request && rowData.scraping_request.started_at && rowData.scraping_request.completed_at ? moment.utc(moment(rowData.scraping_request.completed_at).diff(moment(rowData.scraping_request.started_at))).format("HH:mm:ss") : "-";
    };

    const startedAtTemplate = (rowData) => {
        return rowData.scraping_request && rowData.scraping_request.started_at ? toDateTimeString(rowData.scraping_request.started_at) : "-";
    };

    const finishedAtTemplate = (rowData) => {
        return rowData.scraping_request && rowData.scraping_request.completed_at ? toDateTimeString(rowData.scraping_request.completed_at) : "-";
    };

    const plannedAtTemplate = (rowData) => {
        return rowData.scraping_job_setting && rowData.scraping_job_setting.hour ? toDateTimeString(getCurrentDate() + ' ' + rowData.scraping_job_setting.hour + ':00') : "-";
    };

    return (
        <div className="p-grid card">



            {loading
                ?
                <div className="p-grid p-col-12" style={{textAlign: 'center'}}>
                    <Loading/>
                </div>
                :
                <div className="p-col-12">
                    <TabView>
                        <TabPanel header="Job Scheduling Table">
                            <JobSchedulingTable jobs={jobs} list={list} startedAtTemplate={startedAtTemplate} durationTemplate={durationTemplate}/>
                        </TabPanel>
                        <TabPanel header="Planned Job Scheduling">
                            <JobSchedulingCalendarView jobs={jobs} list={list} type='plan' plannedAtTemplate={plannedAtTemplate} startedAtTemplate={startedAtTemplate} finishedAtTemplate={finishedAtTemplate} durationTemplate={durationTemplate}/>
                        </TabPanel>
                        <TabPanel header="Real Job Scheduling">
                            <JobSchedulingCalendarView jobs={jobs} list={list} type='real' startedAtTemplate={startedAtTemplate} finishedAtTemplate={finishedAtTemplate} durationTemplate={durationTemplate}/>
                        </TabPanel>
                    </TabView>
                </div>
            }
        </div>
    );

};
export default (JobScheduling)
