import React, {useEffect, useState} from "react";

import {Button} from "primereact/button";
import {Message} from "primereact/message";
import {Dropdown} from "primereact/dropdown";
import {formValid, getKeyValue} from "../../utils/Utils";
import Number from "../Common/Number";
import {getViolationCurrencyList} from "../../utils/Constants";
import {AppContext} from "../../HomePage";
import {ProductViolationService} from "../../service/ProductViolationService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {toDateString} from "../../utils/TimeUtil";

const ProductPriceViolation = (props) => {

    const {t, setError} = React.useContext(AppContext);

    let selectedProduct = {...props.selectedProduct};

    const defaultStrategy = {min_value : "", max_value : "", currency : selectedProduct.currency};

    const currencies = getViolationCurrencyList(selectedProduct);

    const [violation_strategy, setViolationStrategy] = useState({...defaultStrategy});

    const [violationSchedule, setViolationSchedule] = useState([]);
    const [formErrors, setFormErrors] = useState({});

    const productViolationService =new ProductViolationService();

    useEffect ( ()=>{
        selectedProduct = {...props.selectedProduct};

        if (selectedProduct)
        {
            if (selectedProduct.violation_strategy)
                setViolationStrategy({...selectedProduct.violation_strategy});
            else
                setViolationStrategy({...defaultStrategy});


            productViolationService.getProductViolationStrategySchedule(selectedProduct.id).then((schedule)=>{
                setViolationSchedule(schedule);
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        }
    },[props.selectedProduct]);

    const onChange = (e) =>{

        const { key, value } = getKeyValue(e);

        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setViolationStrategy({...violation_strategy, [key] : value});
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0
    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "min_value") {
            errorText =  value < 0 ? t('validations.invalid_value') : "";
        }
        else if (key === "max_value") {
            errorText =  value < 0 ? t('validations.invalid_value') : "";
        }
        else if (key === "currency") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        return errorText;
    };

    const checkErrors = async (violation_strategy) => {

        let errors = { ...formErrors };

        Object.entries(violation_strategy).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        return errors;
    };


    const savePriceViolation = () => {

        checkErrors(violation_strategy).then(formErrors=> {
            if (formValid(formErrors)) {

                props.savePriceViolation(violation_strategy);
            }
            else
                setFormErrors(formErrors);
        });

    };


    const dateTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{t('actions.startDate')}</span>
            {toDateString(rowData.started_at)}
        </div>;
    };


    return(
        <div className="p-grid">
            <div className="p-col-5 p-grid">
                <div className="p-col-4">
                    <label htmlFor="min_value">{t('price_violation.map')}</label>
                </div>
                <div className="p-col-8">
                    <Number id="min_value" name="min_value" value={violation_strategy.min_value}
                            onChange={onChange} className={errorClass("min_value")}/>
                    {errorClass("min_value") && (<Message severity="error" text={formErrors.min_value}/>)}
                </div>
                <div className="p-col-4">
                    <label htmlFor="max_value">{t('price_violation.mrp')}</label>
                </div>
                <div className="p-col-8">
                    <Number id="max_value" name="max_value" value={violation_strategy.max_value}
                            onChange={onChange} className={errorClass("max_value")}/>
                    {errorClass("max_value") && (<Message severity="error" text={formErrors.max_value}/>)}
                </div>
                <div className="p-col-4">
                    <label htmlFor="currency">{t('product.currency')}</label>
                </div>
                <div className="p-col-8">
                    <Dropdown id="currency" name="currency" value={violation_strategy.currency}
                                 options={currencies} onChange={onChange}
                              className={errorClass("currency")}/>
                    {errorClass("currency") && (<Message severity="error" text={formErrors.currency}/>)}
                </div>
                <div className="p-col-4">
                </div>
                <div className="p-col-8">
                    <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={savePriceViolation}/>
                </div>
            </div>
            <div className="p-col-7">
                {violationSchedule && violationSchedule.length > 0 && <DataTable value={violationSchedule} sortField="started_at" sortOrder={1}
                           header={t('price_violation.schedules')}
                           emptyMessage={t('actions.empty_message')}
                           paginator={true}
                           rows={10}>
                    <Column field="started_at" body={dateTemplate} header={t('actions.startDate')}/>
                    <Column field="min_value" header={t('price_violation.map')}/>
                    <Column field="max_value" header={t('price_violation.mrp')} />
                    <Column field="currency" header={t('product.currency')} />
                </DataTable>}
            </div>
        </div>
    );

};
export default ProductPriceViolation;
