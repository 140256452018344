import React from 'react';
import {Button} from "primereact/button";
import "moment-timezone";
import {AppContext} from "../../HomePage";
import {userId} from "../../utils/Utils";
import {Badge} from "primereact/badge";
import {CampaignContext} from "../../App";

const Contact = (props) => {

    const {t} = React.useContext(AppContext);
    const {toggleMessages, contactMessages} = React.useContext(CampaignContext);

    const unreadCount = contactMessages ? contactMessages.filter(c=> c.sender_id !== userId() && c.is_read === false).length : 0;

    const badge = unreadCount > 0 ? <Badge value={unreadCount} severity="danger" />:""

    return (
        <React.Fragment>
            <Button className="p-button-text" onClick={(e) => {toggleMessages(true);}} label={t('menu_item.contact_us')} tooltip={t('contact.question_request')} tooltipOptions={{position: 'bottom'}}  >
                &nbsp;<i className="pi pi-envelope p-overlay-badge" >{badge}</i>
            </Button>
        </React.Fragment>
    );
};
export default Contact;