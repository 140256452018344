import React, {Component, useState} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from 'primereact/checkbox';

import {UnAuthService} from "../../service/UnAuthService";
import {LanguageService} from "../../service/LanguageService";

import {Password} from "primereact/password";

import moment from "moment";
import {emailValidator, formValid, getKeyValue} from "../../utils/Utils";
import {getTimeZoneDisplay} from "../../utils/TimeUtil";
import {Message} from "primereact/message";
import {CampaignService} from "../../service/CampaignService";
import {AuthInfo, Loading, Logo} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {Dialog} from "primereact/dialog";


const Register = (props) =>  {

    const {t, toast, navigate, setError} = React.useContext(AppContext);

    const [user, setUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_again: '',
        agree: false,
        language: LanguageService.getLanguage(),
        timezone: 'Europe/Istanbul'
    });

    const [formErrors, setFormErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_again: '',
        agree: '',
        language: '',
        timezone: ''
    });

    const [showTerms, setShowTerms] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [captchaValid, setCaptchaValid] = useState(true);
    const [loading, setLoading] = useState(false);

    const languages = LanguageService.getLanguages();
    const timezones = moment.tz.names();
    const [timezoneSuggestions, setTimezoneSuggestions] = useState(null);

    const campaignService = new CampaignService();

    const captchaResponse = () => {
        setCaptchaValid(true);
    }

    const register = () => {

        setLoading(true);

        if(!captchaValid)
        {
            setLoading(false);
            toast.current.show({severity: "error", summary: t('login.captcha_summary'), detail: t('login.captcha_detail')});
            return;
        }

        checkErrors(user).then(formErrors=> {
            if (formValid(formErrors)) {
                UnAuthService.register(user).then( data => {
                    if (data.is_approved)
                        UnAuthService.login(user.email, user.password).then(() => {
                            const name = t('campaign.trial_name');
                            campaignService.addCampaign(name).then(() => {
                                navigate("/product");
                            });
                        }).catch(error =>{
                            setError(error);
                            setError(null);
                            setLoading(false);
                        });
                    else
                        navigate("/login?register=1");
                }).catch(error =>{
                    setError(error);
                    setError(null);
                    setLoading(false);
                });
            }
            else
            {
                setFormErrors(formErrors);
                setLoading(false);
            }
        });
    }

    const onChange = (e, a) => {

        const { key, value } = getKeyValue(e);

        if (key === "language")
            LanguageService.setLanguage(value);

        setFormErrors({...formErrors, [key]: checkError(key, value)});

        setUser({...user, [key] : value});
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0 ? "p-error" : null
    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "first_name") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "last_name") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "email") {
            errorText =  value.length > 0 ? emailValidator(value, t) : t('validations.invalid_email');
        }
        else if (key === "password") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "password_again") {
            errorText =  value === "" ? t('validations.invalid_value') : "";
        }
        else if (key === "agree") {
            errorText =  !value ? t('validations.invalid_value') : "";
        }
        else if (key === "language") {
            errorText =  value && (value.toString().toLowerCase() === "en" || value.toString().toLowerCase() === "tr") ? "" : t('validations.invalid_value');
        }
        else if (key === "timezone") {
            if (value === "")
                errorText =  t('validations.invalid_value');
            else if (!timezones.some(timezone=>timezone.toLowerCase() === value.toLowerCase()))
                errorText =  t('validations.invalid_value');
        }

        return errorText;
    }

    const checkErrors = async (user) => {

        let errors = { ...formErrors };

        Object.entries(user).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        if (user.password !== user.password_again)
        {
            errors.password_again = t('validations.password_no_match');
        }

        return errors;
    };

    const suggestTimezones = (event) => {
        if (timezones !== null)
        {
            let results = timezones.filter(timezone => {
                return getTimeZoneDisplay(timezone).toLowerCase().includes(event.query.toString().toLowerCase());
            });
            setTimezoneSuggestions(results);
        }
    };

    const timezoneTemplate = (e) => {
        return getTimeZoneDisplay(e);
    };

    const languageTemplate = (e) => {
        return e.label;
    };

    return <div className="p-grid p-nogutter" style={{height: "100vh"}}>

        <div className="p-grid p-col-12 p-lg-7 authInfoContainer" >
            <AuthInfo/>
        </div>

        <div className="p-grid p-col-12 p-lg-5 p-nogutter" style={{ backgroundColor:"white"}} >
            {loading ? <div className="p-grid p-align-center p-fluid" style={{margin:"auto", width:"400px"}}>
                <Loading/>
            </div> :
            <div className="p-grid p-align-center p-fluid" style={{margin:"auto", width:"400px"}}>
                <div className="p-col-12">
                    <Logo />
                </div>
                <div className="p-col-12">
                    <h4>{t('menu_item.register')}</h4>
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText id="first_name" name="first_name" value={user.first_name} onChange={onChange}
                                   className={errorClass("first_name")}/>
                        <label htmlFor="first_name">{t('user.first_name')}</label>
                    </span>
                    {errorClass("first_name") && (<Message severity="error" text={formErrors.first_name}/>)}
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText id="last_name" name="last_name" value={user.last_name} onChange={onChange}
                                   className={errorClass("last_name")}/>
                        <label htmlFor="last_name">{t('user.last_name')}</label>
                    </span>
                    {errorClass("last_name") && (<Message severity="error" text={formErrors.last_name}/>)}
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText id="email" name="email" value={user.email} onChange={onChange}
                                   className={errorClass("email")}/>
                        <label htmlFor="email">{t('user.email')}</label>
                    </span>
                    {errorClass("email") && (<Message severity="error" text={formErrors.email}/>)}
                </div>

                <div className="p-col-12">
                    <span className="p-float-label">
                        <Password id="password" name="password" value={user.password} onChange={onChange} feedback={true}
                                  className={errorClass("password")}/>
                        <label htmlFor="password">{t('user.password')}</label>
                    </span>
                    {errorClass("password") && (<Message severity="error" text={formErrors.password}/>)}
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <Password id="password_again" name="password_again" value={user.password_again} onChange={onChange} feedback={false}
                                  className={errorClass("password_again")}/>
                        <label htmlFor="password_again">{t('user.password_again')}</label>
                    </span>
                    {errorClass("password_again") && (<Message severity="error" text={formErrors.password_again}/>)}
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <Dropdown id="language" name ="language" value={user.language} options={languages}
                              onChange={onChange}
                              itemTemplate={languageTemplate}
                              placeholder={t('user.language')} style={{width:'100%'}}
                              className={errorClass("language")}/>
                        <label htmlFor="language">{t('user.language')}</label>
                    </span>
                    {errorClass("language") && (<Message severity="error" text={formErrors.language}/>)}
                </div>
                {/*<div className="p-col-12">*/}
                {/*    <span className="p-float-label">*/}
                {/*        <AutoComplete id="timezone" name="timezone" field="label" dropdown={true}*/}
                {/*                      dropdownMode="current"*/}
                {/*                      value={user.timezone} suggestions={timezoneSuggestions}*/}
                {/*                      onChange={onChange}*/}
                {/*                      completeMethod={suggestTimezones.bind(this)}*/}
                {/*                      itemTemplate={timezoneTemplate}*/}
                {/*                      className={errorClass("timezone")}/>*/}
                {/*        <label htmlFor="timezone">{t('user.timezone')}</label>*/}
                {/*    </span>*/}
                {/*    {errorClass("timezone") && (<Message severity="error" text={formErrors.timezone}/>)}*/}
                {/*</div>*/}
                <div className="p-col-12 p-grid">
                    <div className="p-col-fixed">
                        <Checkbox inputId="agree" name="agree" value={user.agree} checked={user.agree} onChange={onChange}
                                  className={errorClass("agree")}/>
                    </div>
                    <div className="p-col">
                        <label htmlFor="agree" className="p-checkbox-label">
                            <Button label={t("user.terms_conditions")} className="p-button-link" style={{padding:"0", textAlign:"left"}} onClick={()=> {setShowTerms(true);}} />
                        </label>
                    </div>
                    <div className="p-col-12">
                        {errorClass("agree") && (<Message severity="error" text={formErrors.agree}/>)}
                    </div>
                </div>
                {/*<div className="p-col-12">*/}
                {/*    <Captcha siteKey="6LfwsuYUAAAAAHfOxoAxTJYehxxhfLO_SSJZDaaL" onResponse={captchaResponse} />*/}
                {/*</div>*/}
                <div className="p-col-6 ">
                    <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={e=>(window.location = '#/login')}/>
                </div>
                <div className="p-col-6 ">
                    <Button label={t('actions.sign_up')} icon="pi pi-user-plus" className="p-button-outlined p-button-success" onClick={register}/>
                </div>
            </div>}
        </div>
        {showTerms &&
        <Dialog visible={true} style={{ width: '50vw', minHeight: '50vh', overflow:"auto"}} maximizable={true} modal={true} onHide={() => {setShowTerms(false);}}>

            <iframe src={user.language.toLowerCase() === "tr" ? "https://www.pricerest.com/tr/user-agreement.pdf" : "https://www.pricerest.com/user-agreement.pdf"} height="800px" width="100%"></iframe>

        </Dialog>}
    </div>;

};
export default Register;
