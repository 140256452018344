import React, {useEffect, useState} from "react";

import {formatCurrency, getKeyValue} from "../../utils/Utils";
import {
    getMonthsAgoFirstDay,
    getMonthsAgoFirstDayOfDate,
    getMonthsAgoLastDayOfDate, getNextMonthFirstDayOfDate, getNextMonthLastDayOfDate,
    toDateStringIgnoringTimezone,
    toDateTimeString
} from "../../utils/TimeUtil";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {UserService} from "../../service/UserService";
import {Row} from "primereact/row";
import {ColumnGroup} from "primereact/columngroup";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {AccountService} from "../../service/AccountService";
import {Calendar} from "primereact/calendar";
import {SelectButton} from "primereact/selectbutton";

const PaymentLog = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);
    const [onlyFailedPayment, setOnlyFailedPayment] = useState(false);

    const paymentOptions = [
        {className:"all", name: t('actions.all_payments'), value: false},
        {className:"admin", name: t('actions.only_failed_payments'), value: true}
    ];

    const [dates, setDates] = useState([getMonthsAgoFirstDay(0), new Date()]);

    const [totalUSDIncome, setTotalUSDIncome] = useState([]);
    const [totalTRYIncome, setTotalTRYIncome] = useState([]);
    const [showRechargeModal, setShowRechargeModal] = useState(false);
    const [rowData, setRowData] = useState(null);


    const [loading, setLoading] = useState(false);

    const userService = new UserService();
    const accountService = new AccountService();

    useEffect ( ()=>{
        search();
    }, [dates]);

    const search = () => {
        if (dates[0] && dates[1]) {
            setLoading(true);
            userService.getPaymentLogs(toDateStringIgnoringTimezone(dates[0]), toDateStringIgnoringTimezone(dates[1])).then(data => {
                setItems(data);
                setTotalUSDIncome(data.filter(item => item.currency === '840' && item.is_successful).map(item => (item.amount)).reduce((a, b) => a + b, 0))
                setTotalTRYIncome(data.filter(item => item.currency === '949' && item.is_successful).map(item => (item.amount)).reduce((a, b) => a + b, 0))
                setLoading(false);
            }).catch(error => {
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const incomeTemplate = (rowData) => {
        let currency = rowData.currency === '840' ? 'USD' : 'TRY'
        return rowData.amount ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.amount, currency)}
        </div> : '';
    };

    const fullNameTemplate = (rowData) => <div>{rowData.first_name + " " + rowData.last_name}</div>;

    const footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Total Charged:" colSpan={8} footerStyle={{textAlign: 'right'}}/>
            <Column footer={formatCurrency(totalUSDIncome, "USD")} footerStyle={{textAlign: 'right'}}/>
            <Column footer={formatCurrency(totalTRYIncome, "TRY")} footerStyle={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>;

    const decisionColor = (rowData) => {
        if (!rowData.is_successful)
            return { 'drop_shipping' : true };
        return "";
    };

    const dateTemplate = (rowData) => <span>{toDateTimeString(rowData.created_at)}</span>;

    const reChargeActionTemplate = (rowData) => {
        return <React.Fragment>
            {!rowData.is_successful &&
            <div className="p-text-center">
                <Button icon="pi pi-trash" className="p-button-danger p-mr-1" tooltip={t('actions.remove')} onClick={() => {deletePaymentLog(rowData);}}/>
                <Button icon="pi pi-money-bill" className="p-button-warning" tooltip={t('actions.try_charge_again')} onClick={() => {setShowRechargeModal(true); setRowData(rowData);}}/>
            </div>}
        </React.Fragment>;

    }

    const deletePaymentLog = (rowData) => {
        userService.deletePaymentLog(rowData.id).then(result=>{
            toast.current.show({severity: 'success', summary: t('actions.remove'), detail: t('message_detail.successful')});
            setRowData(null);
            search();
        }).catch(error => {
            setError(error);
            search();
            setError(null);
            setRowData(null);
        });

    }

    const tryChargeAgain = () => {
        accountService.tryChargeAgain(rowData.user_id).then(result=>{
            toast.current.show({severity: 'success', summary: t('actions.pay'), detail: t('message_detail.successful')});
            setShowRechargeModal(false);
            setRowData(null);
            search();
        }).catch(error => {
            setError(error);
            search();
            setError(null);
            setShowRechargeModal(false);
            setRowData(null);
        });
    };

    const modalYesNo = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" onClick={tryChargeAgain} />
            <Button label={t('yes_no.no')} icon="pi pi-times" onClick={() => {setShowRechargeModal(false); setRowData(null);}} />
        </div>
    );

    const previousDate = () => {
        let startDate = getMonthsAgoFirstDayOfDate(dates[0])
        let finishDate = getMonthsAgoLastDayOfDate(startDate)
        setDates([startDate, finishDate])
    }

    const nextDate = () => {
        let startDate = getNextMonthFirstDayOfDate(dates[0])
        let finishDate = getNextMonthLastDayOfDate(startDate)
        setDates([startDate, finishDate])
    }

    return <React.Fragment>

        <Dialog header={t('dialogs.confirm_try_recharge')} footer={modalYesNo} visible={showRechargeModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setShowRechargeModal(false); setRowData(null);}}>
            {t('dialogs.confirm_try_recharge_msg1')}
        </Dialog>

        <div className="p-grid">
            <div className="p-col-9">
                <Button tooltip={t('actions.previous')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left" className="p-button-outlined" onClick={previousDate} style={{marginRight:'0.5em'}} />
                <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={dates} onChange={(e) => setDates(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em', width:"23%"}}/>
                <Button tooltip={t('actions.next')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right" className="p-button-outlined" onClick={nextDate} style={{marginRight:'0.5em'}}/>
                <Button label={t('actions.search')} icon="pi pi-search" className="p-button-success" onClick={search} style={{marginRight:'0.5em'}}/>
            </div>

            <div className="p-col-3">
                <SelectButton value={onlyFailedPayment} optionLabel="name" options={paymentOptions}
                              onChange={(e) => {
                                  setOnlyFailedPayment(getKeyValue(e).value)
                              }}
                              style={{ marginRight:'.5em', float: 'right'}}/>
            </div>
        </div>

         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <DataTable value={items.filter(item => ((onlyFailedPayment && !item.is_successful) || !onlyFailedPayment))}
                        rowClassName={decisionColor}
                        footerColumnGroup={footerGroup}
                        emptyMessage={t('actions.empty_message')}
                        paginator={true} rows={100}>
                 <Column body={IndexTemplate} style={{width:'3em'}} />
                 <Column field="order_id" header={t('user.order_id')} />
                 <Column field="first_name" body={fullNameTemplate} header={t('user.first_name')} />
                 <Column field="credit_card_number" header={t('user.credit_card')} />
                 <Column field="error_message" header={t('message_detail.error')} />
                 <Column field="bank_internal_response_code" header={t('subscription.bank_internal_response_code')} />
                 <Column field="bank_internal_response_sub_code" header={t('subscription.bank_internal_response_sub_code')} />
                 <Column key="amount" field="amount" body={incomeTemplate} header={t('reprice.amount')} headerStyle={{width: '150px', textAlign:"right"}}/>
                 <Column field="created_at" body={dateTemplate} header={t('billing.created_at')} />
                 <Column header={t('table_headers.action')} body={reChargeActionTemplate} headerStyle={{textAlign:'center', width:'10em'}}/>
             </DataTable>}

    </React.Fragment>;
};
export default PaymentLog;
