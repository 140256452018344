import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import {AutoComplete} from "primereact/autocomplete";
import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {formValid, getKeyValue, isCampaignSeller, toTitleCase} from "../../utils/Utils";
import {InputNumber} from "primereact/inputnumber";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";
import {Chips} from "primereact/chips";
import {Dropdown} from "primereact/dropdown";
import {ToggleButton} from "primereact/togglebutton";

const ProductEdit = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const {campaigns} = React.useContext(CampaignContext);

    let selectedCampaign = {...campaigns.find(c=> c.id===props.selectedProduct.campaign_id)};

    const [product, setProduct] = useState({
        id: props.selectedProduct.id,
        name: props.selectedProduct.name,
        category: props.selectedProduct.category,
        sub_category: props.selectedProduct.sub_category,
        type: props.selectedProduct.type,
        brand: props.selectedProduct.brand,
        tags: props.selectedProduct.tags??[],
        barcode: props.selectedProduct.barcode,
        sku: props.selectedProduct.sku,
        mpn: props.selectedProduct.mpn,
        ean: props.selectedProduct.ean,
        upc: props.selectedProduct.upc,
        asin: props.selectedProduct.asin,
        ref_id: props.selectedProduct.ref_id,
        vat: props.selectedProduct.vat,
        search_key: props.selectedProduct.search_key,
        properties: props.selectedProduct.properties,
        campaign_id: props.selectedProduct.campaign_id,
        is_active: props.selectedProduct.is_active
    });
    const [formErrors, setFormErrors] = useState({
        name: "",
        category: "",
        sub_category: "",
        type: "",
        brand:"",
        tags:[],
        barcode: "",
        sku: "",
        mpn: "",
        ean: "",
        upc: "",
        asin: "",
        ref_id: "",
        vat: "",
        search_key: "",
        properties: "",
    });

    useEffect(()=>{

        let properties = props.selectedProduct.properties ? props.selectedProduct.properties : [];

        selectedCampaign = {...campaigns.find(c=> c.id===props.selectedProduct.campaign_id)};

        if(selectedCampaign && selectedCampaign.settings && selectedCampaign.settings.special_fields)
        {
            selectedCampaign?.settings?.special_fields?.forEach(sf=> {
                if (sf.selected && sf.name.startsWith("properties.") && !properties.some(p=> p.name.toLowerCase() === sf.name.toLowerCase().replace("properties.", "")))
                    properties.push({name:sf.name.replace("properties.", ""), value:""});
            });
        }
        setProduct({
            id: props.selectedProduct.id,
            name: props.selectedProduct.name,
            category: props.selectedProduct.category,
            sub_category: props.selectedProduct.sub_category,
            type: props.selectedProduct.type,
            brand: props.selectedProduct.brand,
            tags: props.selectedProduct.tags??[],
            barcode: props.selectedProduct.barcode,
            sku: props.selectedProduct.sku,
            mpn: props.selectedProduct.mpn,
            ean: props.selectedProduct.ean,
            upc: props.selectedProduct.upc,
            asin: props.selectedProduct.asin,
            ref_id: props.selectedProduct.ref_id,
            vat: props.selectedProduct.vat,
            search_key: props.selectedProduct.search_key,
            properties: properties,
            campaign_id: props.selectedProduct.campaign_id,
            is_active: props.selectedProduct.is_active
        });

    }, [props.selectedProduct]);

    const [categorySuggestions, setCategorySuggestions] = useState([]);
    const [brandSuggestions, setBrandSuggestions] = useState([]);

    const update = () => {
        checkErrors(product).then(formErrors=> {
            if (formValid(formErrors))
                props.update(product);
            else
                setFormErrors(formErrors);
        });
    }

    const suggestCategories = function(e) {
        if (props.categories != null) {
            let results = props.categories.filter((category) => {
                return category && category.startsWith(e.query);
            });
            setCategorySuggestions(results);
        }
    };

    const suggestBrands = function(e) {
        if (props.brands != null) {
            let results = props.brands.filter((brand) => {
                return brand && brand.startsWith(e.query);
            });
            setBrandSuggestions(results);
        }
    };

    const onChange = (e) =>{
        const { key, value } = getKeyValue(e);
        if (key.startsWith('properties.'))
        {
            let p = product.properties ? product.properties : [];
            p.forEach(pp => {
                if (pp.name === key.split('.')[1])
                    pp.value = value;
            })
            setProduct({...product, ['properties'] : p});
        }
        else if (key === "properties")
        {
            setProduct({...product, [key] : JSON.parse(value)});
        }
        else {
            setFormErrors({...formErrors, [key]: checkError(key, value)});
            setProduct({...product, [key] : value});
        }
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0
    };

    const checkError = (key, value) => {
        let errorText = "";
        if (key === "name") {
            errorText =  value ? "" : t('validations.invalid_value');
        }
        return errorText;
    };

    const checkErrors = async (product) => {

        let errors = { ...formErrors };

        Object.entries(product).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        setFormErrors(errors);
        return errors;
    };

    return <React.Fragment>
        <h3>{t('product.edit_product')}</h3>
        <div className="p-grid alignCenter p-fluid">
            {campaigns && campaigns.length > 1 &&
            <div className="p-col-12">
                <span className="p-float-label">
                    <Dropdown id="campaign_id" name="campaign_id"
                          options={campaigns.sort((a, b) => (a.name ? a.name.toLowerCase() : "").localeCompare((b.name ? b.name.toLowerCase() : ""), undefined, {sensitivity: 'base'}))}
                          optionLabel="name" optionValue="id"
                          value={product.campaign_id}
                          onChange={onChange}
                    />
                    <label htmlFor="campaign_id">{t('campaign.campaign')}</label>

                </span>
            </div>}
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="name" name="name" value={product.name ?? ""} onChange={onChange}
                               className={errorClass("name")} autoFocus={true}/>
                    <label htmlFor="name">{t('product.product_name')}</label>
                </span>
                {errorClass("name") && (<Message severity="error" text={formErrors.name} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="search_key" name="search_key" value={product.search_key ?? ""} onChange={onChange}
                               className={errorClass("search_key")}/>
                    <label htmlFor="search_key">{t('product.search_key')}</label>
                </span>
                {errorClass("search_key") && (<Message severity="error" text={formErrors.search_key} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <AutoComplete id="category" name="category" dropdown={true} value={product.category} onChange={onChange}
                                  suggestions={categorySuggestions} completeMethod={suggestCategories}
                                  className={errorClass("category")}/>
                    <label htmlFor="category">{t('product.category')}</label>
                </span>
                {errorClass("category") && (<Message severity="error" text={formErrors.category} />)}
            </div>

            {selectedCampaign.settings?.special_fields?.find(sf => sf.name==="sub_category" && sf.selected) &&
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="sub_category" name="sub_category" value={product.sub_category ?? ""} onChange={onChange}
                               className={errorClass("sub_category")}/>
                    <label htmlFor="sub_category">{t('product.sub_category')}</label>
                </span>
                {errorClass("sub_category") && (<Message severity="error" text={formErrors.sub_category} />)}
            </div>}

            {selectedCampaign.settings?.special_fields?.find(sf => sf.name==="type" && sf.selected) &&
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="type" name="type" value={product.type ?? ""} onChange={onChange}
                               className={errorClass("type")}/>
                    <label htmlFor="type">{t('product.type')}</label>
                </span>
                {errorClass("type") && (<Message severity="error" text={formErrors.type} />)}
            </div>}

            <div className="p-col-12">
                <span className="p-float-label">
                    <AutoComplete id="brand" name="brand" dropdown={true} value={product.brand} onChange={onChange}
                                  suggestions={brandSuggestions} completeMethod={suggestBrands}
                                  className={errorClass("brand")}/>
                    <label htmlFor="brand">{t('product.brand')}</label>
                </span>
                {errorClass("brand") && (<Message severity="error" text={formErrors.brand} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <Chips id="tags" name="tags" value={product.tags} onChange={onChange} onBlur={(e) =>{
                        if (e.target.value){
                            let tags = [...product.tags];
                            tags.push(e.target.value);
                            e.target.value = "";
                            setProduct({...product, "tags" : tags});
                        }
                    }} />
                    <label htmlFor="tags">{t('product.tags')}</label>
                </span>
                {errorClass("brand") && (<Message severity="error" text={formErrors.brand} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="barcode" name="barcode" value={product.barcode ?? ""} onChange={onChange}
                               className={errorClass("barcode")}/>
                    <label htmlFor="barcode">{t('product.barcode')}</label>
                </span>
                {errorClass("barcode") && (<Message severity="error" text={formErrors.barcode} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="sku" name="sku" value={product.sku ?? ""} onChange={onChange}
                               className={errorClass("sku")}/>
                    <label htmlFor="sku">{t('product.sku')}</label>
                </span>
                {errorClass("sku") && (<Message severity="error" text={formErrors.sku} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="mpn" name="mpn" value={product.mpn ?? ""} onChange={onChange}
                               className={errorClass("mpn")}/>
                    <label htmlFor="mpn">{t('product.mpn')}</label>
                </span>
                {errorClass("mpn") && (<Message severity="error" text={formErrors.mpn} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="ean" name="ean" value={product.ean ?? ""} onChange={onChange}
                               className={errorClass("ean")}/>
                    <label htmlFor="ean">{t('product.ean')}</label>
                </span>
                {errorClass("ean") && (<Message severity="error" text={formErrors.ean} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="upc" name="upc" value={product.upc ?? ""} onChange={onChange}
                               className={errorClass("upc")}/>
                    <label htmlFor="upc">{t('product.upc')}</label>
                </span>
                {errorClass("upc") && (<Message severity="error" text={formErrors.upc} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="asin" name="asin" value={product.asin ?? ""} onChange={onChange}
                               className={errorClass("asin")}/>
                    <label htmlFor="asin">{t('product.asin')}</label>
                </span>
                {errorClass("asin") && (<Message severity="error" text={formErrors.asin} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="ref_id" name="ref_id" value={product.ref_id ?? ""} onChange={onChange}
                               className={errorClass("ref_id")}/>
                    <label htmlFor="ref_id">{t('product.ref_id')}</label>
                </span>
                {errorClass("ref_id") && (<Message severity="error" text={formErrors.ref_id} />)}
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputNumber id="vat" name="vat" value={product.vat ?? 0} onValueChange={onChange}
                                 mode="decimal" showButtons min={0} max={40}/>
                    <label htmlFor="vat">{t('product.vat')}</label>
                </span>
                {errorClass("vat") && (<Message severity="error" text={formErrors.vat} />)}
            </div>
            {product.properties && product.properties.map(prop=> {
                return <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText id={"properties." + prop.name} name={"properties." + prop.name} value={prop.value ?? ""} onChange={onChange}/>
                        <label htmlFor={"properties." + prop.name}>{t("product.properties." + prop.name.toLowerCase()).replace('product.properties.', '')}</label>
                    </span>
                </div>})}
            <div className="p-col-12">
                <ToggleButton name="is_active" checked={product.is_active} className={product.is_active ? "" : "p-button-danger"}
                              onLabel={t('actions.active')} offLabel={t('actions.passive')} onIcon="pi pi-check" offIcon="pi pi-times" onChange={onChange} />
            </div>
            <div className="p-col-6">
                <Button id="cancel" label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={props.cancel}/>
            </div>
            <div className="p-col-6">
                <Button id="edit" label={t('actions.edit')} icon="pi pi-pencil" className="p-button-outlined p-button-success" onClick={update}/>
            </div>
        </div>
    </React.Fragment>;
};
export default ProductEdit;
