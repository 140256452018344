import React, {useEffect, useState} from "react";

import {
    exportFile,
    formatCurrency,
    getKeyValue,
    getProductDetailLink,
    toTitleCase,
    userId
} from "../../utils/Utils";
import {toDateString, toDateTimeString} from "../../utils/TimeUtil";


import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {ExportService} from "../../service/ExportService";
import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";

const DynamicPricing = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [appliedPricing, setAppliedPricing] = useState([]);
    const [dates, setDates] = useState([new Date(), new Date()]);

    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);

    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect ( ()=>{
        search();
    },[selectedCampaign, dates[0], dates[1]]);

    const search = () => {
        if (dates[0] && dates[1] && selectedCampaign) {
            setLoading(true);
            dashboardService.getDynamicPricing(userId(), selectedCampaign.id, toDateString(dates[0]), toDateString(dates[1])).then(appliedPricing => {
                setAppliedPricing(appliedPricing.map(ap => ({
                    ...ap, user_full_name: toTitleCase(ap.user_first_name) + ' ' + toTitleCase(ap.user_last_name),
                    difference: parseFloat(ap.suggested_value) - parseFloat(ap.owner_value)
                })));
                setLoading(false);
            }).catch(error => {
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const onExport = () => {
        if (dates[0] && dates[1]) {
            setLoadingExcelExport(true);
            exportService.exportDynamicPricing(selectedCampaign.id, toDateString(dates[0]), toDateString(dates[1])).then((response) => {
                exportFile(response, selectedCampaign.name + "_" + t('dashboard.dynamic_pricing') + "_" + toDateString(dates[0]) + " - " + toDateString(dates[1]) + ".xlsx");
                setLoadingExcelExport(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoadingExcelExport(false);
            });
        }
    };

    const groupHeaderTemplate = (rowData) => {
        return <div className="p-grid p-align-center">
            <div className="p-col-6">
                <a target="_blank" rel="noopener noreferrer" href={rowData.owner_product_url}>
                    <img referrerPolicy='no-referrer' className="img-fluid" src={rowData.product.image} alt=""/>
                </a>
            </div>
            <div className="p-col-6">
                {getProductDetailLink(rowData.product_id, rowData.product ? rowData.product.name : t('scraping_request.product_id'))}
            </div>
        </div>;
    }

    const productTemplate = (rowData) => {
        return getProductDetailLink(rowData.product_id, rowData.product ? rowData.product.name : t('scraping_request.product_id'));
    };

    const ownerTemplate = (rowData) => {
        return <a target="_blank" rel="noopener noreferrer" href={rowData.owner_product_url}>{rowData.owner_product_url}</a>;
    };
    const ownerPriceTemplate = (rowData) => {
        return formatCurrency(rowData.owner_value, rowData.owner_currency);
    };

    const suggestedPriceTemplate = (rowData) => {

        let suggestion_direction = "";
        if (rowData.suggested_value) {
            if(rowData.owner_value)
            {
                if (parseFloat(rowData.owner_value) > parseFloat(rowData.suggested_value))
                    suggestion_direction = "down";
                else if (parseFloat(rowData.owner_value) < parseFloat(rowData.suggested_value))
                    suggestion_direction = "up";
            }
        }

        return <div className="p-col-8"
             style={{color: suggestion_direction === "down" ? "red" : (suggestion_direction === "up" ? "green" : "")}}>
            <label>{formatCurrency(rowData.suggested_value, rowData.owner_currency)}</label>
            &nbsp;<i className={suggestion_direction === "down" ? "pi pi-arrow-down" : (suggestion_direction === "up" ? "pi pi-arrow-up" : "")}/>
        </div>;
    };

    const differenceTemplate = (rowData) => {
        return <label>{formatCurrency(rowData.difference, rowData.owner_currency)}</label>;
    };

    const competitorTemplate = (rowData) => {
        return<a target="_blank" rel="noopener noreferrer" href={rowData.competitor_product_url}>{rowData.competitor_min_name}</a>;
    };
    const competitorPriceTemplate = (rowData) => {
        return formatCurrency(rowData.competitor_min_value, rowData.owner_currency);
    };

    const createdAtTemplate = (rowData) => <span>{toDateTimeString(rowData.created_at)}</span>;

    return <React.Fragment>

        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.dynamic_pricing')}</h3>
                <hr></hr>
            </div>

            <div className="p-col-12">
                <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={dates} onChange={(e) => setDates(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} />
                <Button icon="pi pi-file-excel" className="p-ml-1 p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
            </div>

            <div className="p-col-12">
                {loading ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div>:
                    <DataTable value={appliedPricing} responsive emptyMessage={t('actions.empty_message')}
                               rowGroupMode="rowspan" groupField="product.id"
                               sortField="product.id" sortOrder="1">

                        <Column body={IndexTemplate} style={{width:'3em'}} />
                        <Column field="product.name" body={groupHeaderTemplate} header={t('product.product')} filter filterMatchMode="contains" sortable />
                        {/*<Column field="owner_product_url" body={ownerTemplate} header={t('product.product_url')} filter filterMatchMode="contains" sortable/>*/}
                        <Column field="owner_value" body={ownerPriceTemplate} header={t('product.myprice')} filter filterMatchMode="contains" sortable/>
                        <Column field="suggested_value" header={t('reprice.applied_price')} body={suggestedPriceTemplate} filter filterMatchMode="contains" sortable/>
                        <Column field="difference" header={t('reprice.difference')} body={differenceTemplate} filter filterMatchMode="contains" sortable/>
                        <Column field="competitor_min_name" body={competitorTemplate} header={t('product.comp')} filter filterMatchMode="contains" sortable/>
                        <Column field="competitor_min_value" body={competitorPriceTemplate} header={t('product.comp_min')} filter filterMatchMode="contains" sortable/>
                        <Column field="created_at" header={t('reprice.applied_at')} body={createdAtTemplate} filter filterMatchMode="contains" sortable/>
                    </DataTable>}
            </div>
        </div>


    </React.Fragment>;
};
export default DynamicPricing;
