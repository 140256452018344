import React, {useEffect, useState} from "react";
import {AppContext} from "../../HomePage";
import {Bar} from "react-chartjs-2";
import {exportFile, formatCurrencyText, getDatePrice, getKeyValue} from "../../utils/Utils";
import {MultiSelect} from "primereact/multiselect";
import {Toolbar} from "primereact/toolbar";
import {SelectButton} from "primereact/selectbutton";
import {getMonthsAgo, getWeeksAgo, getYearsAgo, toDateString} from "../../utils/TimeUtil";
import {Loading, usePrevious} from "../../utils/InlineComponents";
import {Calendar} from "primereact/calendar";
import {CampaignContext} from "../../App";
import {Button} from "primereact/button";
import {ExportService} from "../../service/ExportService";

const ProductPriceGraph = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const getFullCompetitorList = (competitors) =>{
        return [...new Set(competitors.filter(c => c.is_parent_url !== true && !c.is_drop_shipping).map(c => {
            return {url: c.is_map ? "map_url" :
                    c.is_mrp ? "mrp_url"
                        : c.product_url,
                name:(c.is_mine ? t('product_detail.own_price') :
                    c.is_map ? t('price_violation.map_abbreviation') :
                        c.is_mrp ? t('price_violation.mrp_abbreviation')
                            : (c.name ? c.name : ""))}
        }))]
    };

    const {campaigns} = React.useContext(CampaignContext);
    let selectedCampaign = campaigns.find(c=> c.id===props.selectedProduct.campaign_id);

    const [data, setData] = useState({});
    const [options, setOptions] = useState({});
    const [competitorList, setCompetitorList] = useState([]);
    const [selectedCompetitors, setSelectedCompetitors] = useState(((selectedCampaign && selectedCampaign.settings &&
        selectedCampaign.settings.displayed_price_history_days) || props.selectedProduct.competitors.filter(c => c.is_parent_url !== true && !c.is_drop_shipping).length < 6 ) ?
        getFullCompetitorList(props.selectedProduct.competitors).map(c=>c.url) : []);

    const [selectedDateRange, setSelectedDateRange] = useState('week');

    const [selectedDates, setSelectedDates] = useState([selectedCampaign && selectedCampaign.settings &&
    selectedCampaign.settings.displayed_price_history_days ? new Date(getWeeksAgo(selectedCampaign.settings.displayed_price_history_days/7)) : new Date(getWeeksAgo(2)), new Date()]);

    const prevSelectedDates = usePrevious(selectedDates);

    const [loading, setLoading] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const [graphMax, setGraphMax] = useState(0);
    const [xLabels, setXLabels] = useState([]);
    const [dataOwner, setDataOwner] = useState([]);
    const [dataMap, setDataMap] = useState([]);
    const [dataMrp, setDataMrp] = useState([]);

    const [dataRangeComp, setDataRangeComp] = useState([]);

    const [dataCompetitors, setDataCompetitors] = useState([]);

    const exportService = new ExportService();

    useEffect(() => {
        //setSelectedDateRange(selectedCampaign && selectedCampaign.settings && selectedCampaign.settings.displayed_price_history_days ? 'custom' : 'week');
        setLoading(true);
        generateGraphData(props.selectedProduct.competitors).then(() => {
            setLoading(false);
        });
        selectedCampaign = campaigns.find(c=> c.id===props.selectedProduct.campaign_id);

    }, [props.selectedProduct.competitors]);

    useEffect(() => {
        updateGraph();
    }, [xLabels, dataOwner, dataMap, dataMrp, dataRangeComp, dataCompetitors, JSON.stringify(selectedCompetitors)]);

    useEffect(() => {
        if (prevSelectedDates && prevSelectedDates.length === 2 && selectedDates.length === 2 &&
            //prevSelectedDates[0] && prevSelectedDates[1] &&
            selectedDates[0] && selectedDates[1] &&
            (prevSelectedDates[0] !== selectedDates[0] || prevSelectedDates[1] !== selectedDates[1])) {

            setLoading(true);
            props.getCompetitors(props.selectedProduct.id, selectedDates[0], selectedDates[1]).then(() =>{
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });


        }
    }, [selectedDates[0], selectedDates[1]]);

    const setDateRange = (range) => {
        setSelectedDateRange(range);
        if (range === "week")
            setSelectedDates([getWeeksAgo(2),new Date()]);
        else if (range === "month")
            setSelectedDates([getMonthsAgo(1),new Date()]);
        else if (range === "quarter")
            setSelectedDates([getMonthsAgo(3),new Date()]);
        else if (range === "year")
            setSelectedDates([getYearsAgo(1),new Date()]);
    }



    const generateGraphData = async (competitors) => {

        setCompetitorList(getFullCompetitorList(competitors));

        let dataOwner = [];
        let dataMap = [];
        let dataMrp = [];
        let dataRangeComp = [];
        let dataCompetitors = [];

        let colors = ['#FF7F00', '#A6CEE3', '#B2DF8A', '#FC9A99', '#FDBF6F', '#CAB2D6', '#1E78B4', '#33A02C'];

        competitors.forEach((c, i) => {
            if (c.is_mine)
                dataCompetitors.push({url:c.product_url, name: t('product_detail.own_price'), color: 'green', data: []})
            else if (c.is_map)
                dataCompetitors.push({url:"map_url", name: t('price_violation.map_abbreviation'), color: 'red', data: []})
            else if (c.is_mrp)
                dataCompetitors.push({url:"mrp_url", name: t('price_violation.mrp_abbreviation'), color: 'red', data: []})
            else
                dataCompetitors.push({url:c.product_url, name: c.name, color: colors[i], data: []})
        });

        let startDate = new Date(selectedDates[0]);
        let endDate = new Date(selectedDates[1]);
        let days = [];
        let graphMax = 0;

        while (startDate < endDate)
        {
            const endDateString = toDateString(endDate);
            days.unshift(endDateString);

            let prevDate = new Date();
            prevDate.setDate(endDate.getDate() - 1);

            let minPrice = 9999999999;
            let maxPrice = 0;

            let currency = "";

            for (let j = 0; j < competitors.length; j++) {
                let competitor = competitors[j];

                let price = getDatePrice(competitor.prices, endDateString)

                let obj = {x:endDateString};
                if (price && price.value && price.stock)
                {
                    obj = {x:endDateString, y:parseFloat(price.value), list_price: parseFloat(price.sale_value), discount_rate: parseFloat(price.discount_rate), currency:price.currency};

                    if (!competitor.is_mine && !competitor.is_map && !competitor.is_mrp) {
                        if (parseFloat(price.value) < parseFloat(minPrice)) {
                            minPrice = parseFloat(price.value);
                            currency = price.currency
                        }
                        if (parseFloat(price.value) > parseFloat(maxPrice)) {
                            maxPrice = parseFloat(price.value);
                            currency = price.currency
                        }

                        if (graphMax < price.value)
                            graphMax = price.value;
                    }
                }

                let url = competitor.product_url;
                if (competitor.is_mine){
                    dataOwner.push(obj);
                }
                else if (competitor.is_map) {
                    url = "map_url";
                    dataMap.push(obj);
                }
                else if (competitor.is_mrp) {
                    url = "mrp_url";
                    dataMrp.push(obj);
                }

                let index = dataCompetitors.findIndex(c => c.url === url);
                dataCompetitors[index].data.push(obj);

            }

            if (minPrice === 9999999999 && maxPrice === 0) {
                dataRangeComp.push(null);
            } else {
                if (minPrice === 9999999999)
                    minPrice = 0;

                dataRangeComp.push({x:endDateString, y:[minPrice.toFixed(2), maxPrice.toFixed(2)], currency:currency});
            }

            endDate.setDate(endDate.getDate() - 1);
        }

        setGraphMax(graphMax);
        setXLabels([...days]);
        setDataOwner([...dataOwner]);
        setDataMap([...dataMap]);
        setDataMrp([...dataMrp]);
        setDataRangeComp([...dataRangeComp]);
        setDataCompetitors([...dataCompetitors]);

    };



    const updateGraph = () => {
        let priceData = {
            labels: xLabels,
            datasets: []
        };

        let spanGaps = selectedCampaign && selectedCampaign.settings &&
            selectedCampaign.settings.displayed_price_history_days ?
            true : false;

        if (selectedCompetitors.length !== 0 && selectedCompetitors.length < 9) {
            dataCompetitors.filter(c => selectedCompetitors.find(sc => sc === c.url)).forEach((c, i )=> {
                priceData.datasets.push({
                    type: 'line',
                    label: i.toString() + " " + c.name,
                    borderColor: c.color,
                    borderWidth: 2,
                    fill: false,
                    data: c.data,
                    lineTension: 0
                });
            });
        } else {

            if (dataOwner.length !== 0) {
                priceData.datasets.push({
                    type: 'line',
                    label: t('product_detail.own_price'),
                    borderColor: 'green',
                    borderWidth: 2,
                    fill: false,
                    data: dataOwner,
                    lineTension: 0
                });
            }
            if (dataMap.length !== 0) {
                priceData.datasets.push({
                    type: 'line',
                    label: t('price_violation.map_abbreviation'),
                    borderColor: 'red',
                    borderWidth: 2,
                    fill: false,
                    data: dataMap,
                    lineTension: 0
                });
            }
            if (dataMrp.length !== 0) {
                priceData.datasets.push({
                    type: 'line',
                    label: t('price_violation.mrp_abbreviation'),
                    borderColor: 'red',
                    borderWidth: 2,
                    fill: false,
                    data: dataMrp,
                    lineTension: 0
                });
            }
            priceData.datasets.push({
                type: 'bar',
                label: t('product_detail.comp_prices'),
                backgroundColor: 'lightblue',
                data: dataRangeComp,
                minBarLength: 3
            });


        }


        let max = 0;
        if (selectedCompetitors.length !== 0 && selectedCompetitors.length < 9)
            priceData.datasets.forEach(function(x, i){
                max = Math.max(max, Math.max.apply(null, x.data));
            });
        else
            max = graphMax;

        let priceOptions = {
            responsive: true,
            spanGaps: spanGaps,
            maintainAspectRatio: false,
            scales: {
                x: {
                    stacked: false,
                    ticks: {
                        fontSize: 10
                    }
                },
                y: {
                    stacked: false,
                    //type: 'logarithmic',
                    ticks: {
                        fontSize: 10,

                    }
                }

            },

            plugins: {
                labels: false,
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    titleFontSize: 20,
                    bodyFontSize: 15,
                    bodySpacing: 5,
                    displayColors: true,
                    itemSort: function(a, b){
                        return b.raw - a.raw;
                    },
                    callbacks:{
                        label: function(item){
                            if (Array.isArray(item.raw.y))
                                return item.dataset.label + " : " + item.raw.y.map((r) => {return formatCurrencyText(r, item.raw.currency)});
                            else
                                return item.dataset.label + " : " + formatCurrencyText(item.raw.y, item.raw.currency)
                                    + (item.raw.list_price && item.raw.list_price !== item.raw.y ? " / "
                                        + (item.raw.discount_rate ? "(%" + item.raw.discount_rate.toFixed(2) + ") " : "")
                                        + formatCurrencyText(item.raw.list_price, item.raw.currency) : "");

                        }
                    },
                },
            }
        };

        setData({...priceData});
        setOptions({...priceOptions});
    }

    const ranges = [{label: t('date.twoweek'), value: 'week'},
        {label: t('date.month'), value: 'month'},
        {label: t('date.quarter'), value: 'quarter'},
        {label: t('date.year'), value: 'year'},
        {label: t('date.custom'), value: 'custom'}
    ];

    const selectedCompetitorsHeaderTemplate = (options)=>{
        let button = <div className="p-multiselect-header"><Button icon="pi pi-star" className="p-button-outlined p-button-success" tooltip={t('actions.top_5_competitors')} tooltipOptions={{position: 'bottom'}} onClick={() =>{
            setSelectedCompetitors(competitorList.slice(0,6).map(c=>c.url));
        }} /></div>;
        return <div style={{display:"flex"}}>{options.element}{button}</div>;
    }

    const selectedCompetitorsFooterTemplate = () => {
        return<div className="p-py-2 p-px-3">
            <b>{selectedCompetitors.length}</b>{t('actions.items_selected')}
        </div>;
    }

    const exportPriceHistory = () => {
        setLoadingExcel(true);
        exportService.exportProductPriceHistory(props.selectedProduct.id, selectedDates[0], selectedDates[1]).then((response)=>{
            exportFile(response, props.selectedProduct.name + "_" + t('product_detail.price_history') + ".xlsx");
            setLoadingExcel(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcel(false);
        });
    }

    const toolbarLeftContents = <React.Fragment>
        <MultiSelect name="selectedCompetitors" value={selectedCompetitors} options={competitorList.sort(function(a, b){return (((a && b) && a.name === t('product_detail.own_price') ? a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}): 1));})}
                     onChange={(e) => {setSelectedCompetitors(getKeyValue(e).value)}} filter={true} placeholder={t('product.competitors')} maxSelectedLabels={2}
                     panelFooterTemplate={selectedCompetitorsFooterTemplate}
                     panelHeaderTemplate={selectedCompetitorsHeaderTemplate}
                     fixedPlaceholder={true}
                     optionLabel="name" optionValue="url"
                     style={{
            width: '13em',
            marginRight: '0.5em'
        }}/>

        <SelectButton name="selectedDateRange" value={selectedDateRange} options={ranges} onChange={(e) => {
            setDateRange(getKeyValue(e).value);
        }} style={{marginRight: '0.5em'}}/>
        <div style={{display: selectedDateRange === 'custom' ? "block" : "none"}}>
            <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={selectedDates} onChange={(e) => setSelectedDates(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em'}}/>
        </div>
    </React.Fragment>;

    const toolbarRightContents = <React.Fragment>
            <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" tooltip={t('product_detail.export_price_history')} tooltipOptions={{position: 'bottom'}}
                    onClick={exportPriceHistory} loading={loadingExcel} />
    </React.Fragment>;


    return loading ?
        <div className="p-col-12" style={{textAlign: 'center'}}>
            <Loading/>
        </div> :
        <React.Fragment>
            <Toolbar left={toolbarLeftContents} right={toolbarRightContents}/>
            <div style={{height: "350px"}}>

                {data.datasets &&
                <Bar id="comparison" data={data} options={options} height={350}/>}

            </div>
        </React.Fragment>;
};
export default ProductPriceGraph;
