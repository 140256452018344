import React, {useEffect} from "react";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {getHostName} from "../../utils/Utils";

import {AppContext} from "../../HomePage";
import {IndexTemplate} from "../../utils/InlineComponents";

const CompetitorDetection = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    let selectedProduct = {...props.selectedProduct};

    useEffect ( ()=>{
        selectedProduct = {...props.selectedProduct};
    },[props.selectedProduct]);

    const detectedCompetitorsProductUrlTemplate = (rowData) => {

        let l = document.createElement("a");
        l.href = rowData.product_url;

        return <div>
            <a target="_blank" rel="noopener noreferrer" href={rowData.product_url} title={getHostName(rowData.product_url)}> {getHostName(rowData.product_url)} </a>
        </div>;
    };

    return(
        <DataTable value={selectedProduct.new_competitor_suggestions} responsive={true} resizableColumns={true} columnResizeMode="fit">
            <Column body={IndexTemplate} style={{width:'0.3em', textAlign:'left'}} />
            <Column field="product_url" body={detectedCompetitorsProductUrlTemplate} header={t('table_headers.site')}/>
            {/*<Column body={suggestionActionTemplate} header={t('table_headers.action')} style={{textAlign:'center', width:'0.3em'}}/>*/}
        </DataTable>
    );
};
export default CompetitorDetection;
