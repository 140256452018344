import {AuthService} from "./AuthService";
import {userId} from "../utils/Utils";

export class BillingService {
    url = `balance_histories/`;
    url_account = `accounts/`;


    listBalanceHistory(startDate, endDate){
        return AuthService.apiGet(this.url,{params: {start_date: startDate, end_date: endDate}}).then(res => res.data.data);
    }

    getUserBalanceHistory(){
        return AuthService.apiGet(this.url + "get_balance_histories/").then(res => res.data.data);
    }

    updateInvoiceNumber(balance_history_id, invoice_number){
        return AuthService.apiPut(this.url + balance_history_id + "/", {invoice_number: invoice_number}).then(res => res.data.data);
    }

    downloadInvoice(balance_history_id){
        return AuthService.apiGet(this.url_account  + "invoice",{responseType: 'blob', params: {balance_history_id: balance_history_id}}).then(res => res);
    }

    sendInvoice(balance_history_id){
        return AuthService.apiPost(this.url_account + balance_history_id + "/send_invoice/").then(res => res.data.data);
    }

    deleteBalanceHistory(balance_history_id){
        return AuthService.apiDelete(this.url + balance_history_id).then(res => res.data.data);
    }

}
