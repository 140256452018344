import React, {useEffect, useState} from "react";


import {AppContext} from "../../HomePage";
import {UserService} from "../../service/UserService";
import {IndexTemplate, Loading} from "../../utils/InlineComponents";
import {formatCurrency, isAdmin} from "../../utils/Utils";
import {Panel} from "primereact/panel";
import {Line} from "react-chartjs-2";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";

const OverviewKPI = (props) =>{

    const {t, setError} = React.useContext(AppContext);


    const [loading, setLoading] = useState(false);
    const [kpis, setKpis] = useState([]);
    const [lastKpi, setLastKpi] = useState(null);
    const [incomeDataSets, setIncomeDataSets] = useState(null);
    const [userDataSets, setUserDataSets] = useState(null);
    const [gainedLostUserDataSets, setGainedLostUserDataSets] = useState(null);
    const [netGainedUserDataSets, setNetGainedUserDataSets] = useState(null);

    const userService = new UserService();

    useEffect ( ()=>{
        getKpis()
    },[]);

    const getKpis = () => {
        setLoading(true);
        userService.getKpis().then(data=>{
            setKpis(data);
            setIncomeDataSets(
                {
                    labels: data.map(kpi => kpi.date),
                    datasets: [
                        {
                            label: t('user.kpi.total_income'),
                            data: data.map(kpi => kpi.total_monthly_income),
                            fill: false,
                            borderColor: '#42A5F5'
                        },
                        {
                            label: t('user.kpi.total_expense'),
                            data: data.map(kpi => kpi.total_monthly_expense),
                            fill: false,
                            borderColor: '#FFA726'
                        },
                        {
                            label: t('user.kpi.total_net_income'),
                            data: data.map(kpi => kpi.total_monthly_net_income),
                            fill: false,
                            borderColor: '#66BB6A'
                        }
                    ]
                }

            )
            setUserDataSets(
                {
                    labels: data.map(kpi => kpi.date),
                    datasets: [
                        {
                            label: t('user.kpi.total_user'),
                            data: data.map(kpi => kpi.total_user_number),
                            fill: false,
                            borderColor: '#42A5F5'
                        },
                        {
                            label: t('user.kpi.total_local_user'),
                            data: data.map(kpi => kpi.total_local_user_number),
                            fill: false,
                            borderColor: '#66BB6A'
                        },
                        {
                            label: t('user.kpi.total_foreign_user'),
                            data: data.map(kpi => kpi.total_foreign_user_number),
                            fill: false,
                            borderColor: '#FFA726'
                        }
                    ]
                }

            )
            setNetGainedUserDataSets(
                {
                    labels: data.map(kpi => kpi.date),
                    datasets: [
                        {
                            label: t('user.kpi.net_gained_users'),
                            data: data.map(kpi => kpi.total_gained_user_number - kpi.total_lost_user_number),
                            fill: false,
                            borderColor: '#FFA726'
                        },
                    ]
                }
            )
            setGainedLostUserDataSets(
                {
                    labels: data.map(kpi => kpi.date),
                    datasets: [
                        {
                            label: t('user.kpi.gained_users'),
                            data: data.map(kpi => kpi.total_gained_user_number),
                            fill: false,
                            borderColor: '#66BB6A'
                        },
                        {
                            label: t('user.kpi.lost_users'),
                            data: data.map(kpi => kpi.total_lost_user_number),
                            fill: false,
                            borderColor: '#f53438'
                        }
                    ]
                }

            )
            setLastKpi(data[data.length - 1])
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const basicOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: '#495057'
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor: '#495057'
                }
            }]
        }
    };

    const gainedUsersTemplate = (rowData) => {
        let listItems = []
        rowData.foreign_gained_users.forEach(item => {
            listItems.push(<li><span style={{textAlign: 'center', marginBottom:"5px"}} className={"p-tag p-tag-success"}>{item}</span></li>)
        })
        rowData.local_gained_users.forEach(item => {
            listItems.push(<li><span style={{textAlign: 'center', marginBottom:"5px"}} className={"p-tag p-tag-info"}>{item}</span></li>)
        })
        return listItems;
    }

    const lostUsersTemplate = (rowData) => {
        let listItems = []
        rowData.foreign_lost_users.forEach(item => {
            listItems.push(<li><span style={{textAlign: 'center', marginBottom:"5px"}} className={"p-tag p-tag-danger"}>{item}</span></li>)
        })
        rowData.local_lost_users.forEach(item => {
            listItems.push(<li><span style={{textAlign: 'center', marginBottom:"5px"}} className={"p-tag p-tag-warning"}>{item}</span></li>)
        })
        return listItems;
    }

    return <React.Fragment>

        {loading ?
            <div className="p-col-12" style={{textAlign:'center'}}>
                <Loading/>
            </div> :
            <React.Fragment>
                <div className="p-grid p-fluid dashboard card">
                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_net_income')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_net_income_detail')}</span>
                            <span className="count green">{formatCurrency(lastKpi?.total_monthly_net_income, 'USD')}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_income')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_income_detail')}</span>
                            <span className="count blue">{formatCurrency(lastKpi?.total_monthly_income, 'USD')}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_expense')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_expense_detail')}</span>
                            <span className="count red">{formatCurrency(lastKpi?.total_monthly_expense, 'USD')}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_basic_income')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_basic_income_detail')}</span>
                            <span className="count green">{formatCurrency(lastKpi?.total_charged_of_subscription_types?.basic, 'USD')}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_professional_income')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_professional_income_detail')}</span>
                            <span className="count blue">{formatCurrency(lastKpi?.total_charged_of_subscription_types?.professional, 'USD')}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_premium_income')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_premium_income_detail')}</span>
                            <span className="count yellow">{formatCurrency(lastKpi?.total_charged_of_subscription_types?.premium, 'USD')}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_user')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_user_detail')}</span>
                            <span className="count blue">{lastKpi?.total_user_number}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_foreign_user')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_foreign_user_detail')}</span>
                            <span className="count yellow">{lastKpi?.total_foreign_user_number}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_local_user')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_local_user_detail')}</span>
                            <span className="count green">{lastKpi?.total_local_user_number}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_basic_user')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_basic_user_detail')}</span>
                            <span className="count green">{lastKpi?.number_of_subscription_types?.basic}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_professional_user')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_professional_user_detail')}</span>
                            <span className="count blue">{lastKpi?.number_of_subscription_types?.professional}</span>
                        </div>
                    </div>

                    <div className="p-col-4">
                        <div className="card summary">
                            <button className="p-link" onClick={() => {}}>
                                <span className="title">{t('user.kpi.total_premium_user')}</span>
                            </button>
                            <span className="detail">{t('user.kpi.total_premium_user_detail')}</span>
                            <span className="count yellow">{lastKpi?.number_of_subscription_types?.premium}</span>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6 contacts">
                        <Panel header={t('user.kpi.total_income')}>
                            <div className="card">
                                {incomeDataSets &&
                                <Line data={incomeDataSets} options={basicOptions} />}
                            </div>
                        </Panel>
                    </div>

                    <div className="p-col-12 p-lg-6 contacts">
                        <Panel header={t('user.kpi.user_numbers')}>
                            <div className="card">
                                {userDataSets &&
                                <Line data={userDataSets} options={basicOptions} />}
                            </div>
                        </Panel>
                    </div>

                    {/*<div className="p-col-12 p-lg-6 contacts">*/}
                    {/*    <Panel header={t('user.kpi.net_gained_users')}>*/}
                    {/*        <div className="card">*/}
                    {/*            {netGainedUserDataSets &&*/}
                    {/*            <Line data={netGainedUserDataSets} options={basicOptions} />}*/}
                    {/*        </div>*/}
                    {/*    </Panel>*/}
                    {/*</div>*/}

                    {/*<div className="p-col-12 p-lg-6 contacts">*/}
                    {/*    <Panel header={t('user.kpi.gained_lost_user_numbers')}>*/}
                    {/*        <div className="card">*/}
                    {/*            {gainedLostUserDataSets &&*/}
                    {/*            <Line data={gainedLostUserDataSets} options={basicOptions} />}*/}
                    {/*        </div>*/}
                    {/*    </Panel>*/}
                    {/*</div>*/}

                    <div className="p-col-12 p-lg-8 contacts">
                        <DataTable value={kpis} sortField="date" sortOrder={-1}>
                            <Column field="date" header={t('user.kpi.date')} style={{width:'10em'}}/>
                            <Column body={gainedUsersTemplate} header={t('user.kpi.gained_users')} />
                            <Column body={lostUsersTemplate} header={t('user.kpi.lost_users')} />
                        </DataTable>
                    </div>

                    <div className="p-col-12 p-lg-1 contacts">
                    </div>

                    <div className="p-col-12 p-lg-3 contacts">
                        <DataTable value={lastKpi?.user_number_of_countries}>
                            <Column header={t('table_headers.index')} style={{width: '5em'}} body={IndexTemplate}/>
                            <Column field="country" header={t('suggestion.country')} style={{width:'10em'}}/>
                            <Column field="count" header={t('product.count')} style={{width:'10em'}}/>
                        </DataTable>
                    </div>

                </div>

            </React.Fragment>
        }

    </React.Fragment>;
};
export default OverviewKPI;
