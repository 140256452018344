import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import {isAdmin, isCampaignDropShipping, isCampaignManufacturer, isCampaignSeller, isManager} from "./utils/Utils";
import {AppContext} from "./HomePage";
import {CampaignContext} from "./App";

const AppSubmenu = (props) => {

    const [activeIndex, setActiveIndex] = useState(null)

    const onMenuItemClick = (event, item, index) => {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        if (index === activeIndex)
            setActiveIndex(null);
        else
            setActiveIndex(index);

        if (props.onMenuItemClick) {
            props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    const renderLinkContent = (item) => {
        let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

        return (
            <React.Fragment>
                <i className={item.icon}></i>
                <span>{item.label}</span>
                {submenuIcon}
                {badge}
            </React.Fragment>
        );
    }

    const renderLink = (item, i) => {
        let content = renderLinkContent(item);

        if (item.to) {
            return (
                <NavLink activeClassName="active-route" to={item.to} onClick={(e) => onMenuItemClick(e, item, i)} exact target={item.target}>
                    {content}
                </NavLink>
            )
        }
        else {
            return (
                <a href={item.url} onClick={(e) => onMenuItemClick(e, item, i)} target={item.target}>
                    {content}
                </a>
            );
        }
    }

    let items = props.items && props.items.map((item, i) => {
        let active = activeIndex === i;
        let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active && !item.to });

        return (
            <li className={styleClass} key={i}>
                {item.items && props.root === true && <div className='arrow'></div>}
                {renderLink(item, i)}
                <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={active} unmountOnExit>
                    <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
                </CSSTransition>
            </li>
        );
    });

    return items ? <ul className={props.className}>{items}</ul> : null;
}

const AppMenu = (props) => {

    const {t} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const createMenu = () => {

        let reportItems = []

        if (isCampaignManufacturer(selectedCampaign)) {
            reportItems.push({label: t('dashboard.violations'), icon: 'pi pi-ban',  command: () => {window.location = '#/report/violations'} })
            reportItems.push({label: t('dashboard.sellers_summary'), icon: 'pi pi-chart-bar',  command: () => {window.location = '#/report/sellers_summary'} })
            reportItems.push({label: t('dashboard.unauthorized_sellers'), icon: 'pi pi-times',  command: () => {window.location = '#/report/unauthorized_sellers'} })
        } else if (isCampaignDropShipping(selectedCampaign)) {
            reportItems.push({label: t('dashboard.drop_shipping_loss'), icon: 'pi pi-times',  command: () => {window.location = '#/report/drop_shipping_loss'} })
        }

        reportItems.push.apply(reportItems, [
            {label: t('dashboard.price_comparison'), icon: 'pi pi-th-large',  command: () => {window.location = '#/report/price_comparison'} },
            {label: t('dashboard.price_comparison_summary'), icon: 'pi pi-clone',  command: () => {window.location = '#/report/price_comparison_summary'} },
            {label: t('dashboard.n_cheapest_seller_price_comparison'), icon: 'pi pi-dollar',  command: () => {window.location = '#/report/n_cheapest_seller_price_comparison'} },
            {label: t('dashboard.daily_price_comparison'), icon: 'pi pi-calendar-plus',  command: () => {window.location = '#/report/daily_price_comparison'} },
            {label: t('dashboard.weekly_price_comparison'), icon: 'pi pi-calendar-plus',  command: () => {window.location = '#/report/weekly_price_comparison'} },
            {label: t('dashboard.filtered_seller_price_comparison'), icon: 'pi pi-filter-slash',  command: () => {window.location = '#/report/filtered_seller_price_comparison'} },
            {label: t('dashboard.price_stock_changes'), icon: 'pi pi-tags',  command: () => {window.location = '#/report/price_changes'} },
            {label: t('dashboard.sales'), icon: 'pi pi-user',  command: () => {window.location = '#/report/sales'} },
            {label: t('dashboard.brand_comparison'), icon: 'pi pi-chart-line',  command: () => {window.location = '#/report/brand_comparison'} },
            {label: t('dashboard.price_index.header'), icon: 'pi pi-chart-bar',  command: () => {window.location = '#/report/price_index'} },
            {label: t('dashboard.excel_reports'), icon: 'pi pi-file-excel',  command: () => {window.location = '#/report/excel_reports'} },

        ])

        if (isCampaignSeller(selectedCampaign)){
            reportItems.push({label: t('dashboard.dynamic_pricing'), icon: 'pi pi-compass',  command: () => {window.location = '#/report/dynamic_pricing'} })
        }

        let menu = [];

        menu.push({label: t('menu_item.dashboard'), icon: 'pi pi-fw pi-eye', command: () => {window.location = '#/'}});
        menu.push({label: t('menu_item.campaigns'), icon: 'pi pi-fw pi-tags', command: () => {window.location = '#/campaign'}});
        menu.push({label: t('menu_item.products'), icon: 'pi pi-fw pi-list', command: () => {window.location = '#/product'}});
        menu.push({label: t('menu_item.reports'), icon: 'pi pi-fw pi-chart-bar', items: reportItems});
        (isAdmin() || isManager()) && menu.push({label: t('menu_item.impersonation'), icon: 'pi pi-fw pi-users', command: () => {window.location = '#/impersonation'}});
        isAdmin() && menu.push({label: t('menu_item.management'), icon: 'pi pi-fw pi-key',
            items: [
                {label: t('menu_item.dashboard'), icon: 'pi pi-fw pi-eye',  command: () => {window.location = '#/admin_dashboard'} },
                {label: t('menu_item.users'), icon: 'pi pi-fw pi-user',  command: () => {window.location = '#/user'} },
                {label: t('menu_item.web_sites'), icon: 'pi pi-fw pi-star', command: () => {window.location = '#/websites'}},
                {label: t('menu_item.cache'), icon: 'pi pi-fw pi-save', command: () => {window.location = '#/cache'}},
                {label: t('menu_item.headless_browsers'), icon: 'pi pi-fw pi-mobile', command: () => {window.location = '#/headless_browsers'}},
                {label: t('menu_item.special_fields'), icon: 'pi pi-fw pi-box', command: () => {window.location = '#/special_fields'}},
                {label: t('menu_item.scraping_jobs'), icon: 'pi pi-fw pi-cog', command: () => {window.location = '#/scraping_job'}},
                {label: t('menu_item.job_scheduling'), icon: 'pi pi-fw pi-calendar', command: () => {window.location = '#/job_scheduling'}},
                {label: t('menu_item.error'), icon: 'pi pi-fw pi-exclamation-triangle', command: () => {window.location = '#/error'}},
                {label: t('menu_item.message'), icon: 'pi pi-fw pi-send',  command: () => {window.location = '#/message'} }
            ]
        });
        // this.menu.push({
        //     label: 'Menu Modes', icon: 'pi pi-fw pi-cog',
        //     items: [
        //         {label: 'Static Menu', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutMode: 'static'}) },
        //         {label: 'Overlay Menu', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutMode: 'overlay'}) }
        //     ]
        // });
        // this.menu.push({
        //     label: 'Menu Colors', icon: 'pi pi-fw pi-align-left',
        //     items: [
        //         {label: 'Dark', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutColorMode: 'dark'}) },
        //         {label: 'Light', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutColorMode: 'light'}) }
        //     ]
        // });

        return menu;
    }

    return (
        <div className="layout-menu-container">
            <AppSubmenu items={createMenu()} className="layout-menu" onMenuItemClick={props.onMenuItemClick} root={true} />
        </div>
    );
};
export default AppMenu;
