import {LanguageService} from "../service/LanguageService";
import React from "react";
import {usage_types_values} from "./Constants";
import {getDateString, toDateString} from "./TimeUtil";

const urlRegex = RegExp(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{0,2}[a-z0-9ı]+)*\.[a-z]{2,7}(:[0-9]{1,5})?(\/.*)?$/
);

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const decimalRegex = RegExp(/^[0-9]*\.?[0-9]?[0-9]?$/);

//pick some fields from object
export const pick = (...props) => o => props.reduce((a, e) => ({ ...a, [e]: o[e] }), {});

export const getLastPrice = (prices) => {
    return prices && prices.length!==0 ? prices.reduce((a,b) => {
        return new Date(a.created_at) > new Date(b.created_at) ? a : b;
    }) : null;
};

export const getDatePrice = (prices, p_dateString) => {
    //TODO:getDateString  toDateString ile değiştirilecek. performans sorunu düzeltilince.
    const datePrices = prices?.filter(p=> getDateString(p.created_at) === p_dateString);
    if (datePrices.length !== 0)
        return datePrices[datePrices.length -1];
    else
        return null;
};

export const urlValidator =(url, t)=>{

    let error = "";
    url = url.toLowerCase();
    if (!(url.startsWith("http://") || url.startsWith("https://")))
        error = t('validations.regex_url');
    else {
        error = urlRegex.test(url) ? "" : t('validations.invalid_url');
    }

    return error;
};

export const emailValidator = (email, t) => {
    return emailRegex.test(email) ? "" : t('validations.invalid_email');
};

export const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
};

export const formValid = (formErrors) => {

    let isValid = true;

    if (formErrors !== null)
    {
        // validate form errors being empty
        Object.values(formErrors).forEach(val => {
            if (val.length > 0) isValid = false;
        });
    }
    return isValid;
};


export const getHostName = (url) => {
    if (url == null)
        return null
    if (!(url.startsWith("http://") || url.startsWith("https://")))
        url = "https://" + url;
    let match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
    }
    else {
        return null;
    }
};


export const currencyFormatter = new Intl.NumberFormat(LanguageService.getLanguage(), {
    style: 'decimal', minimumFractionDigits : 2, maximumFractionDigits: 2
});

export const formatDecimal = (value) => {
    if (value === undefined || value === null || value === "" || value === "-")
        return "-";
    return currencyFormatter.format(value);
};

export const formatCurrency = (value, currency) => {
    if (value === undefined || value === null || value === "" || value === "-")
        return "-";

    if (currency === "$")
        return currency + currencyFormatter.format(value);
    return <span className="p-text-nowrap p-text-right"><span>{currencyFormatter.format(value)}</span>&nbsp;<sup style={{fontSize:'.7em'}}>{" " + (currency ? currency:"")}</sup></span>;
};

export const formatPercentage = (value) => {
    if (value === undefined || value === null || value === "" || value === "-")
        return "-";

        return  value + ' %';
};



export const formatCurrencyText = (value, currency) => {
    if (value === undefined || value === null || value === "" || value === "-")
        return "-";

    if (currency === "$")
        return currency + currencyFormatter.format(value);
    return currencyFormatter.format(value) +" " + (currency ? currency:"");
};


export const loggedInEmail = () => {return localStorage.getItem("loggedInEmail") ? localStorage.getItem("loggedInEmail") : null};
export const user = () => {return localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null};
export const subscription = () => (localStorage.getItem("subscription") ? JSON.parse(localStorage.getItem("subscription")) : null);
export const next_subscription = () => (localStorage.getItem("next_subscription") ? JSON.parse(localStorage.getItem("next_subscription")) : null);
export const isAdmin = ()=> (user() ? user().is_admin : false);
export const isManager = ()=> (user() ? user().is_manager : false);
export const isUserManaged = ()=> (user() ? user().managed_by !== null : false);
export const isSubProfile = ()=> (user() ? loggedInEmail().toLowerCase() !== user().email.toLowerCase() : false);
export const isMainProfile = ()=> (user() ? loggedInEmail().toLowerCase() === user().email.toLowerCase() : false);
export const isCampaignSeller = (campaign)=> (campaign?.settings?.usage_type ? campaign?.settings?.usage_type === usage_types_values[0].value : (user() && user().usage_type && user().usage_type === usage_types_values[0].value));
export const isCampaignManufacturer = (campaign)=> (campaign?.settings?.usage_type ? campaign?.settings?.usage_type === usage_types_values[1].value : (user() && user().usage_type && user().usage_type === usage_types_values[1].value));
export const isCampaignMarketSearch = (campaign)=> (campaign?.settings?.usage_type ? campaign?.settings?.usage_type === usage_types_values[2].value : (user() && user().usage_type && user().usage_type === usage_types_values[2].value));
export const isCampaignDropShipping = (campaign)=> (campaign?.settings?.usage_type ? campaign?.settings?.usage_type === usage_types_values[3].value : (user() && user().usage_type && user().usage_type === usage_types_values[3].value));
export const isPriceViolationTabShown = (campaign)=> (campaign?.settings?.show_price_violation_tab ? campaign?.settings?.show_price_violation_tab : false);
export const isShippingPricesHandledSeparately = (campaign)=> (campaign?.settings?.shipping_prices_handled_separately ? !campaign?.settings?.shipping_prices_handled_separately : true);
export const userId = () => (user() ? user().id : "");

export const getKeyValue = (e) => {
    if (e.target) {
        if (e.target.type) {
            if (e.target.type === "checkbox")
                return {key: e.target.name, value: e.target.checked};
            else
                return {key: e.target.name, value: e.target.value};
        } else
            return {key: e.target.name, value: e.target.value};
    }
    return {key: null, value: null};
};

export const getLinkFromUrl = (url) => {
    return url ? <a target="_blank" rel="noopener noreferrer" href={url}>{url}
    </a> : null;
};

export const getProductDetailLink = (id, label) => {
    return <a target="_blank" rel="noopener noreferrer" href={"/#/productdetail?id="+id}>
        <h6>{label}</h6>
    </a>;
};

export const  toTitleCase = (phrase) => {
    return phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const getLanguageImage = (language) =>{
    return <img src={"assets/layout/flags/"+language+".svg"}  height="28" width="28" alt={language}/>;
};

export const isPreventSelect = (e) => {
    let result = false;

    while (e)
    {
        if (e.className && e.className.indexOf("preventSelect") !== -1) {
            result = true;
            break;
        }
        e = e.parentNode;
    }
    return result;
}

export const exportFile = (response, fileName) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
};

export const populateInactiveSellers = (campaignData) => {
    campaignData.forEach(item => {
        item.new_seller_automatically_activated = item.settings && item.settings.new_seller_automatically_activated !== null && item.settings.new_seller_automatically_activated !== undefined ? item.settings.new_seller_automatically_activated : true
        item.inactive_sellers = new Set()
        item.active_sellers = new Set()
        item?.competitors?.forEach(competitor => {
            if (competitor?.sellers?.length === 0) {
                let hostname = getHostName(competitor.url)
                if (item.new_seller_automatically_activated) {
                    if (competitor.is_active !== undefined && !competitor.is_active){
                        item.inactive_sellers.add(hostname)
                    }
                } else {
                    if (competitor.is_active) {
                        item.active_sellers.add(hostname)
                    }
                }
            } else {
                competitor?.sellers?.forEach(seller => {
                    if (item.new_seller_automatically_activated) {
                        if (seller.is_active !== undefined && !seller.is_active){
                            item.inactive_sellers.add(seller.seller_name)
                        }
                    } else {
                        if (seller.is_active) {
                            item.active_sellers.add(seller.seller_name)
                        }
                    }
                })
            }

        })
    })
}


