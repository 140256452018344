import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {RadioButton} from "primereact/radiobutton";

import {AuthService} from "../../service/AuthService";
import {AccountService} from "../../service/AccountService";
import {PricingPlanService} from "../../service/PricingPlanService";
import {formatCurrency, user as base_user, subscription as base_subscription, toTitleCase, isAdmin, user} from "../../utils/Utils";
import {toDateString} from "../../utils/TimeUtil";



import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faCashRegister, faHandHoldingUsd} from "@fortawesome/free-solid-svg-icons";


import BillingAdress from "./BillingAddress";
import PaymentMethod from "./PaymentMethod";
import {Dialog} from "primereact/dialog";
import {Loading} from "../../utils/InlineComponents";
import {ToggleButton} from "primereact/togglebutton";
import {AppContext} from "../../HomePage";


const Subscription = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const [plansLoading, setPlansLoading] = useState(false);

    const [user, setUser] = useState(base_user());
    const [subscription, setSubscription] = useState(base_subscription());
    const [pricing_plans, setPricing_plans] = useState([]);
    const [added_additional_features, setAdded_additional_features] = useState([]);
    const [additional_features, setAdditional_features] = useState([]);


    const [subscriptionMonthlyPrice, setSubscriptionMonthlyPrice] = useState(0);
    const [total_additional_feature_price, setTotal_additional_feature_price] = useState(0);
    const [billing_address, setBilling_address] = useState(null);
    const [payment_method, setPayment_method] = useState(null);

    const [show_billing_address, setShow_billing_address] = useState(false);
    const [show_payment_method, setShow_payment_method] = useState(false);

    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [currentPlanId, setCurrentPlanId] = useState(null);

    const [calculatedDebit, setCalculatedDebit] = useState(null);

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);


    const accountService = new AccountService();
    const pricingPlanService = new PricingPlanService();

    useEffect(() => {
        loadPage();
    }, [currentPlanId]);

    const loadPage = () =>{
        setPlansLoading(true);
        pricingPlanService.getPricingPlans().then(pricing_plans => {
            if (pricing_plans)
                pricing_plans.sort(function (a, b) {return (parseFloat(a.monthly_price) - parseFloat(b.monthly_price))});
            let currentPlanId = subscription ? subscription.pricing_plan_id : null;
            let currentPlan = pricing_plans[0];
            if (currentPlanId) {
                currentPlan = pricing_plans.filter(plan => plan.id === currentPlanId)[0];
                if (currentPlan === undefined) {
                    currentPlan = pricing_plans[0];
                    currentPlanId = null;
                }
            }

            let additional_features = currentPlan.additional_features

            if (currentPlanId) {
                subscription.additional_features.forEach(item => {
                    let additional_feature = additional_features.filter(innerItem => innerItem.name === item.name)[0];
                    additional_feature.is_selected = true;
                    additional_feature.price = item.price;
                })
            }

            let subscriptionMonthlyPrice = null;
            let total_additional_feature_price = 0;
            if (currentPlanId) {
                additional_features.forEach(item => {
                    if (item.is_selected) {
                        total_additional_feature_price += item.price;
                    }
                })
                subscriptionMonthlyPrice = subscription.plan_price - total_additional_feature_price;
            }
            setPricing_plans(pricing_plans);
            setSubscriptionMonthlyPrice(subscriptionMonthlyPrice);
            setSelectedPlanId(currentPlanId);
            setCurrentPlanId(currentPlanId);
            setAdditional_features(currentPlan.additional_features.filter(item => item.is_active));
            setTotal_additional_feature_price(total_additional_feature_price);
            setPlansLoading(false);

        }).catch(error => {
            setError(error);
            setError(null);
            setPlansLoading(false);
        });


        accountService.getPaymentMethod().then(payment_method => {
            setPayment_method(payment_method);
            setShow_payment_method(true);
        }).catch(error => {
            setError(error);
            setError(null);
        });


        accountService.getBillingAddress().then(billing_address => {
            setBilling_address(billing_address);
            setShow_billing_address(true);
        }).catch(error => {
            setError(error);
            setError(null);
        });
    };

    const selectPlan = (planId) => {

        let selectedPlan = pricing_plans.filter(plan => plan.id === planId)[0]
        if (selectedPlanId !== planId) {
            let total_additional_feature_price = 0
            let added_additional_features = []
            additional_features.forEach(item => {
                if (item.is_selected) {
                    let additional_feature = selectedPlan.additional_features.filter(innerItem => innerItem.name === item.name)[0];
                    total_additional_feature_price += additional_feature.price;
                    added_additional_features.push(additional_feature);
                    additional_feature.is_selected = true;
                    item.is_selected = false;
                }
            })

            setTotal_additional_feature_price(total_additional_feature_price);
            setAdded_additional_features(added_additional_features);
        }
        setSelectedPlanId(planId);
        setAdditional_features(selectedPlan.additional_features.filter(item => item.is_active));

    };

    const cancelPlan = () =>{
        setSelectedPlanId(null);
        accountService.calculateDebitForPlan(null,"").then(result=> {
            setCalculatedDebit(result);
            setShowModal(true);
        }).catch(error => {
            setError(error);
            setError(null);
        });
    };

    const changeBillingAddress = (billing_address) => {
        setBilling_address(billing_address);
    };

    const changePaymentMethod = (payment_method) =>{
        setPayment_method(payment_method);
    };

    const checkBillingInfo = () =>{
        if (!billing_address)
        {
            toast.current.show({severity: 'warn', summary: t('account.no_billing_address'), detail: t('message_detail.required')});
            return false;
        }
        if (!payment_method)
        {
            toast.current.show({severity: 'warn', summary: t('account.payment_method'), detail: t('message_detail.required')});
            return false;
        }
        return true;
    }

    const applyFinalSubscription = (result, _subscription) => {
        user.balance = result.balance ? result.balance : 0;

        AuthService.setUser({...user});
        AuthService.setSubscription( _subscription);

        setSubscription(_subscription);
        setUser({...user});
        setCalculatedDebit(null);
        setSelectedPlanId(_subscription ? _subscription.pricing_plan_id : null);
        setCurrentPlanId(_subscription ? _subscription.pricing_plan_id : null);
        setShowModal(false);
        setLoading(false);
    }

    const applyChanges = () => {

        if (checkBillingInfo()) {
            setLoading(true);

            if (selectedPlanId) //Update or Renew
            {
                //renew
                if (selectedPlanId === currentPlanId &&
                    (payment_method.method_type === "manuel_credit_card" || (isAdmin() && payment_method.method_type === "money_order") ))
                {
                    accountService.renewMyPlan().then(result=>{

                        applyFinalSubscription(result, result.subscription);

                        toast.current.show({severity: 'success', summary: payment_method.method_type === "manuel_credit_card" ? t('actions.pay') : t('actions.renew_subscription'), detail: t('message_detail.successful')});
                    }).catch(error => {
                        setError(error);
                        setError(null);
                        setLoading(false);
                    });
                }
                else//update
                {
                    let added_additional_feature_names = added_additional_features.map(item => item.name)
                    accountService.changeMyPlan(selectedPlanId, added_additional_feature_names).then(result => {

                        applyFinalSubscription(result, result.subscription);

                        toast.current.show({severity: 'success', summary: t('subscription.change_plan'), detail: t('message_detail.successful')});

                    }).catch(error =>{
                        setError(error);
                        setError(null);
                        setLoading(false);
                    });
                }
            } else //cancel
            {
                accountService.cancelMyPlan().then(result => {

                    applyFinalSubscription(result, null);

                    toast.current.show({severity: 'success', summary: t('subscription.cancel_plan'), detail: t('message_detail.successful')});

                }).catch(error =>{
                    setError(error);
                    setError(null);
                    setLoading(false);
                });
            }
        }
    }

    const addOrRemoveAdditionalFeature = (event, plan, additional_feature) => {
        additional_feature.is_selected = event.value;
        let additional_feature_price = additional_feature.price;
        if (!additional_feature.is_selected) {
            additional_feature_price = -additional_feature_price;

            setAdded_additional_features([...added_additional_features.filter(item => item !== additional_feature)]);
            setTotal_additional_feature_price(total_additional_feature_price + additional_feature_price);

        }
        else {
            added_additional_features.push(additional_feature);
            setAdded_additional_features([...added_additional_features]);
            setTotal_additional_feature_price(total_additional_feature_price + additional_feature_price);
        }
    }

    let planList = "";

    let className = pricing_plans.length === 4 ? "p-col-2" : "p-col-3";
    let classNameRemaining = pricing_plans.length === 4 ? "p-col-10" : "p-col-9";
    let headerClassName = pricing_plans.length === 4 ? "p-col-4" : "p-col-3";

        planList = pricing_plans.map(plan => {
            return<React.Fragment key={plan.id}>
                <div className={className} onClick={(e) => selectPlan(plan.id)} style={{minWidth: '10em'}}>
                    <div className={selectedPlanId === plan.id ? "p-grid plan plan-active " + plan.type : "p-grid plan " + plan.type}>
                        <div className="p-col-12 plan-header">{toTitleCase(plan.type)}</div>
                        <div className="p-col-12 plan-row">
                            {plan.monthly_price ? (subscription && subscription.pricing_plan_id === plan.id && subscriptionMonthlyPrice !== plan.monthly_price) ? (<span><s>{"$" + plan.monthly_price}</s><span style={{color:'red'}}>{"$" + subscriptionMonthlyPrice}</span></span>) : "$" + plan.monthly_price : "-"}
                            {total_additional_feature_price !== 0 && selectedPlanId === plan.id ? <span style={{fontSize:'15px'}}> {" + $" + total_additional_feature_price.toFixed(2)} </span> : ""}
                        </div>
                        {/*<div className="p-col-12 plan-row">{plan.annual_price ? plan.annual_price : "-"}</div>*/}
                        <div className="p-col-12 plan-row">{plan.total_competitor_count ? (subscription && subscription.pricing_plan_id === plan.id && subscription.total_competitor_count !== plan.total_competitor_count) ? (<span><s>{plan.total_competitor_count}</s><span style={{color:'red'}}>{subscription.total_competitor_count}</span></span>) : plan.total_competitor_count : t('subscription.unlimited')}</div>
                        <div className="p-col-12 plan-row">{plan.total_product_count ? (subscription && subscription.pricing_plan_id === plan.id && subscription.total_product_count !== plan.total_product_count) ? (<span><s>{plan.total_product_count}</s><span style={{color:'red'}}>{subscription.total_product_count}</span></span>) : plan.total_product_count : "-"}</div>
                        <div className="p-col-12 plan-row">{plan.daily_price_check_count ? (subscription && subscription.pricing_plan_id === plan.id && subscription.daily_price_check_count !== plan.daily_price_check_count) ? (<span><s>{plan.daily_price_check_count}</s><span style={{color:'red'}}>{subscription.daily_price_check_count}</span></span>) : plan.daily_price_check_count : "-"}</div>
                        <div className="p-col-12 plan-row">{plan.manuel_price_check_count || plan.manuel_price_check_count === 0 ? (subscription && subscription.pricing_plan_id === plan.id && subscription.manuel_price_check_count !== plan.manuel_price_check_count) ? (<span><s>{plan.manuel_price_check_count}</s><span style={{color:'red'}}>{subscription.manuel_price_check_count}</span></span>) : plan.manuel_price_check_count : "-"}</div>
                        <div className="p-col-12 plan-row">{plan.instant_price_check_enabled ? <i className="pi pi-check-circle" /> : "-"}</div>
                        <div className="p-col-12 plan-row">{plan.sale_history_enabled ? <i className="pi pi-check-circle" /> : "-"}</div>
                        <div className="p-col-12 plan-row">{plan.dynamic_pricing_enabled ? <i className="pi pi-check-circle" /> : "-"}</div>
                        {/*<div className="p-col-12 plan-row">{plan.product_variant_tracking_enabled ? <i className="pi pi-check-circle" /> : "-"}</div>*/}

                    {additional_features.map((additional_feature) => {
                        return selectedPlanId === plan.id ? <div className="p-col-12 plan-row">
                            <ToggleButton checked={additional_feature.is_selected}
                                          onChange={(e) => addOrRemoveAdditionalFeature(e, plan, additional_feature)}
                                          offLabel={t('actions.add') + " ($" + additional_feature.price + ')'}
                                          onLabel={t('actions.delete') + " ($" + additional_feature.price + ')'} offIcon="pi pi-check" onIcon="pi pi-times"
                                          style={{minWidth:'8em', maxWidth:'11em', bottom:'2.5px', height:'1.5em'}} />
                        </div> : <div className="p-col-12 plan-row">-</div>
                    })}

                    <div className="p-col-12 plan-select">
                        <RadioButton inputId="decision_all" value={plan.id} onChange={(e) => selectPlan(plan.id)} checked={selectedPlanId === plan.id}/>
                        <label className="p-radiobutton-label">{
                            currentPlanId === plan.id ? t('actions.current') : (
                            selectedPlanId === plan.id ? t('actions.selected') : t('actions.select'))}</label>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    });

        planList.unshift(<React.Fragment key="0">
            <div className={headerClassName}>
                <div className="p-grid plan-legend">
                    <div className="p-col-12 plan-row">{t('subscription.plan')}</div>
                    <div className="p-col-12 plan-row">{t('subscription.monthly_price')}</div>
                    {/*<div className="p-col-12 plan-row">{t('subscription.annual_price')}</div>*/}
                    <div className="p-col-12 plan-row">{t('subscription.total_competitor_count')}</div>
                    <div className="p-col-12 plan-row">{t('subscription.total_product_count')}</div>
                    <div className="p-col-12 plan-row">{t('subscription.daily_price_check_count')}</div>
                    <div className="p-col-12 plan-row">{t('subscription.manuel_price_check_count')}</div>
                    <div className="p-col-12 plan-row">{t('subscription.instant_price_check_enabled')}</div>
                    <div className="p-col-12 plan-row">{t('subscription.sale_history_enabled')}</div>
                    <div className="p-col-12 plan-row">{t('subscription.dynamic_pricing_enabled')}</div>
                    {/*<div className="p-col-12 plan-row">{t('subscription.product_variant_tracking_enabled')}</div>*/}
                    {additional_features.map((additional_feature) => { return <div className="p-col-12 plan-row">{t('subscription.' + additional_feature.name)}</div>})}
                    <div className="p-col-12 plan-row">{t('subscription.choose_plan')}</div>
                </div>
            </div>
        </React.Fragment>);

    let today = new Date();
    let first28 = today.getDate() < 28 ? today.setDate(28) : new Date(today.setMonth(today.getMonth()+1)).setDate(28);
    let next_start_at = toDateString(first28);

    const modalYesNo = (
        !loading ? <div>
            <Button label={t('actions.confirm')} icon="pi pi-check" className="p-button-outlined p-button-success" onClick={applyChanges} />
            <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-outlined p-button-warning" onClick={() => {setShowModal(false);}}/>
        </div> : <div> </div>
    );


    const changePlan = () => {
        let added_additional_feature_names = added_additional_features.map(item => item.name);
        accountService.calculateDebitForPlan(selectedPlanId, added_additional_feature_names.join(",")).then(result=> {
            setCalculatedDebit(result);
            setShowModal(true);
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const moneyOrderMsg = () => {
        let msg = t('subscription.payment.remainder_money_order');
        return msg.toString().split('newline').map(s => {
            return <p>{s}</p>;
        });
    }

    const cancelMsg = () => {
      let msg = t('subscription.confirm_cancel_message');
       return msg.toString().split('newline').map(s => {
           return <p>{s}</p>;
       });
    }

    return <React.Fragment>

        <br/>

        {plansLoading ? <div className="p-col-12" style={{textAlign: 'center'}}>
                <Loading/>
            </div> :

            <div className="p-grid">
                <div className="p-col" style={{minWidth: '47em'}}>
                    <div className="p-grid p-col-12 p-fluid">
                        {planList}
                        <div className={className}>
                        </div>
                        <div className={classNameRemaining + " p-grid "} style={{fontSize: "0.9em"}}>
                            <div className="p-col-12 p-text-bold">
                                {t('subscription.note.header')}
                            </div>
                            <div className="p-col-12">
                                {t('subscription.note.seller_count')}
                            </div>
                            <div className="p-col-12">
                                {t('subscription.note.api')}
                            </div>
                            <div className="p-col-12">
                                {t('subscription.note.in_site_search')}
                            </div>
                            <div className="p-col-12">
                                {t('subscription.note.variant')}
                            </div>
                            <div className="p-col-12">
                                {t('subscription.note.shipping_price')}
                            </div>
                        </div>
                    </div>

                </div>
                <div className="p-col-fixed p-fluid" style={{width: '25em'}}>

                    {show_billing_address &&
                        <BillingAdress billing_address={billing_address} onDataChange={changeBillingAddress}/>}

                    {show_payment_method &&
                        <PaymentMethod payment_method={payment_method} onDataChange={changePaymentMethod}/>}

                    <div className="p-grid card">
                        <div className="p-grid p-col-12" style={{borderBottom: "1px solid black"}}>
                            <div className="p-col">
                                <h4><FontAwesomeIcon icon={faHandHoldingUsd}/> {t('subscription.current_balance')}</h4>
                            </div>
                            <div className="p-col-fixed" style={{width: '6em', textAlign: 'right'}}>
                                <h4>{formatCurrency(user.balance, "$")}</h4>
                            </div>
                        </div>
                    </div>

                    {((subscription && subscription.type !== "trial") || selectedPlanId) &&
                        <div className="p-grid card">
                            <div className={"p-grid p-col-12 " + pricing_plans.find(plan => plan.id === selectedPlanId)?.type ?? ""} style={{
                                height: "50px",
                                borderBottom: "1px solid black",
                                color: "white"
                            }}>
                                <div className="p-col">
                                    <h4><FontAwesomeIcon icon={faCalendarAlt}/> {t('subscription.monthly_billing')}</h4>
                                </div>
                                <div className="p-col-fixed" style={{width: '6em', textAlign: 'right'}}>
                                    <h4>{subscription && subscription.plan_price && selectedPlanId === subscription.pricing_plan_id ? formatCurrency(subscription.plan_price, "$") :
                                        pricing_plans && (pricing_plans.find(plan => plan.id === selectedPlanId) ?
                                            formatCurrency(pricing_plans.find(plan => plan.id === selectedPlanId).monthly_price + total_additional_feature_price, "$") : "$0.00")}</h4>
                                </div>
                            </div>
                            <div className="p-col-8">{t('subscription.next_billing_date')}</div>
                            <div className="p-col-4" style={{textAlign: 'right'}}>{subscription.finish_at}</div>
                        </div>
                    }

                    {selectedPlanId !== null && selectedPlanId !== currentPlanId &&
                        <div className="p-col-12">
                            <Button label={currentPlanId ? t('actions.changePlan') : t('actions.getPlan')} icon="pi pi-check" className="p-button-outlined p-button-success" onClick={changePlan}/>
                        </div>}

                    {selectedPlanId !== null && selectedPlanId === currentPlanId && payment_method && (payment_method.method_type === "manuel_credit_card" || (isAdmin() && payment_method.method_type === "money_order")) &&
                        <div className="p-col-12">
                            <Button label={payment_method.method_type === "manuel_credit_card" ? t('actions.pay') : t('actions.renew_subscription')} icon="pi pi-money-bill" className="p-button-outlined p-button-success" onClick={() => {
                                setShowModal(true);
                            }}/>
                        </div>
                    }

                    {subscription && subscription.type !== "" && subscription.type !== "trial" &&
                        <div className="p-col-12" style={{textAlign: "right"}}>
                            <Button label={t('actions.cancel_subscription')} icon="pi pi-times" className="p-button-outlined p-button-danger" onClick={cancelPlan} style={{width: '15em'}}/>
                        </div>}


                </div>
                {/*{user.country === "TR" &&*/}
                {/*<div className="p-col-12 p-text-center">*/}
                {/*    <i className="pi pi-home p-mr-1"/>*/}
                {/*    Hacılar Mahallesi 1470/1 Sokak No:15 Gölbaşı / Ankara, TR*/}
                {/*    <i className="pi pi-phone p-ml-4 p-mr-1"/>*/}
                {/*    +90 312 444 4 996*/}
                {/*</div>}*/}
            </div>
        }
        {showModal &&
        <Dialog header={selectedPlanId !== null ? t('subscription.confirm_payment') : t('actions.cancel_subscription')}  footer={modalYesNo} visible={showModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true}
                onHide={() => {setShowModal(false);}}>

            {loading ? <div className="p-col-12" style={{textAlign: 'center'}}>
                    <Loading/>
                </div> :
                selectedPlanId !== null ?
                    (selectedPlanId === currentPlanId && (payment_method.method_type === "manuel_credit_card" || (isAdmin() && payment_method.method_type === "money_order")))
                        ? <div>
                            {moneyOrderMsg()}
                        </div> :
                        <div className="p-grid card">
                            <div className="p-col-12" style={{borderBottom: "1px solid black"}}>
                                <h4><FontAwesomeIcon icon={faCashRegister}/> {t('subscription.due_day')}</h4>
                            </div>
                            <div className="p-col-8">{t('subscription.next_billing_period')}</div>
                            <div className="p-col-4" style={{textAlign: 'right'}}>{toDateString(new Date())} {next_start_at}</div>

                            {(calculatedDebit.current_user_balance !== calculatedDebit.new_user_balance) &&
                            <div className="p-grid p-col-12 p-nogutter">
                                <div className="p-col-8">{t('subscription.current_balance')}</div>
                                <div className="p-col-4" style={{textAlign: 'right'}}>{formatCurrency(calculatedDebit.current_user_balance, "$")}</div>
                                <div className="p-col-8">{t('subscription.new_balance')}</div>
                                <div className="p-col-4" style={{textAlign: 'right'}}>{formatCurrency(calculatedDebit.new_user_balance, "$")}</div>
                            </div>}
                            {!calculatedDebit.debit_tl && calculatedDebit.debit !== 0 &&
                            <div className="p-grid p-col-12 p-nogutter">
                                <div className="p-col-8">{t('subscription.upgrade_fee')}</div>
                                <div className="p-col-4" style={{textAlign: 'right'}}>{formatCurrency(calculatedDebit.debit, "$")}</div>
                            </div>}
                            {(!calculatedDebit.debit_tl && calculatedDebit.debit !== 0 && calculatedDebit.debit_with_tax !== 0 &&
                                calculatedDebit.debit !== calculatedDebit.debit_with_tax) &&
                            <div className="p-grid p-col-12 p-nogutter">
                                <div className="p-col-8">{t('subscription.upgrade_fee_with_tax')}</div>
                                <div className="p-col-4" style={{textAlign: 'right'}}>{formatCurrency(calculatedDebit.debit_with_tax, "$")}</div>
                            </div>}

                            {calculatedDebit.debit_tl && calculatedDebit.debit_tl !== 0 &&
                            <div className="p-grid p-col-12 p-nogutter">
                                <div className="p-col-8">{t('subscription.upgrade_fee')}</div>
                                <div className="p-col-4" style={{textAlign: 'right'}}>{formatCurrency(calculatedDebit.debit_tl, "TL")}</div>
                            </div>}
                            {(calculatedDebit.debit_tl && calculatedDebit.debit_tl !== 0 && calculatedDebit.debit_with_tax_tl !== 0 &&
                                calculatedDebit.debit_tl !== calculatedDebit.debit_with_tax_tl) &&
                            <div className="p-grid p-col-12 p-nogutter">
                                <div className="p-col-8">{t('subscription.upgrade_fee_with_tax')}</div>
                                <div className="p-col-4" style={{textAlign: 'right'}}>{formatCurrency(calculatedDebit.debit_with_tax_tl, "TL")}</div>
                            </div>}

                        </div> :
                    <div>
                        {cancelMsg()}
                    </div>
            }
        </Dialog>}
    </React.Fragment>;

};
export default Subscription;
