import React, {useState, useEffect, useRef} from 'react';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {BillingService} from "../../service/BillingService";

import {exportFile, formatCurrency, getKeyValue, isAdmin, toTitleCase, user} from "../../utils/Utils";
import {getMonthsAgo, toDateString, toDateTimeString} from "../../utils/TimeUtil";

import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {UserService} from "../../service/UserService";
import {MultiSelect} from "primereact/multiselect";
import {Tag} from "primereact/tag";
import CopyToClipboard from "../../utils/CopyToClipboard";

const BillingHistory = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const op = useRef(null);

    const [balanceHistory, setBalanceHistory] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});
    const [users, setUsers] = useState([]);

    const [loadingInvoice, setLoadingInvoice] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState("");

    const [user_id, setUserID] = useState([]);

    const [dates, setDates] = useState([getMonthsAgo(1), new Date()]);

    const billingService = new BillingService();
    const userService = new UserService();

    useEffect(() =>{
        if (isAdmin() && dates[0] && dates[1]) {
            if (users.length>0) {
                billingService.listBalanceHistory(toDateString(dates[0]), toDateString(dates[1])).then(balance_history => {
                    setBalanceHistory(balance_history);
                }).catch(error => {
                    setError(error);
                    setError(null);
                });
            }
            else{
                userService.getUsers(false, false, false).then(users => {
                    setUsers(users.map(user => ({...user,full_name: toTitleCase(user.first_name) + ' ' + toTitleCase(user.last_name)})).sort((a, b) => a.full_name.localeCompare(b.full_name), undefined, {sensitivity: 'base'}));
                    billingService.listBalanceHistory(toDateString(dates[0]), toDateString(dates[1])).then(balance_history => {
                        setBalanceHistory(balance_history);
                    }).catch(error => {
                        setError(error);
                        setError(null);
                    });
                }).catch(error => {
                    setError(error);
                    setError(null);
                });
            }
        }
        else
            billingService.getUserBalanceHistory().then(balance_history => {
                setBalanceHistory(balance_history);
            }).catch(error => {
                setError(error);
                setError(null);
            });
    }, [dates[0], dates[1]]);

    const updateInvoiceNumber = () => {
        billingService.updateInvoiceNumber(rowData.id, invoiceNumber).then(()=>{

            const index = balanceHistory.findIndex(c => c.id === rowData.id);

            balanceHistory[index].invoice_number = invoiceNumber;
            setBalanceHistory([...balanceHistory]);
            setInvoiceNumber("");
            op.current.hide();
            toast.current.show({severity: 'success', summary: t('billing.set_invoice_number'), detail: t('message_detail.successful')});

        }).catch(error => {
            setError(error);
            setError(null);
        });
    };

    const sendInvoice = (balance_history_id) =>{
        setLoadingInvoice(balance_history_id);
        billingService.sendInvoice(balance_history_id).then((response) =>{
            toast.current.show({severity: 'success', summary: t('actions.send_invoice'), detail: t('message_detail.successful')});
            setLoadingInvoice(null);
        }).catch(error => {
            setLoadingInvoice(null);
            setError(error);
            setError(null);
        });
    }

    const download = (balance_history_id, invoice_number) =>{
        setLoadingInvoice(balance_history_id);
        billingService.downloadInvoice(balance_history_id).then((response) =>{
            exportFile(response, t("actions.pricerest_invoice")+"_" + invoice_number + ".pdf");
            setLoadingInvoice(null);
        }).catch(error => {
            setLoadingInvoice(null);
            setError(error);
            setError(null);
        });
    }
    const userTemplate = (rowData) => {
        if (users.length > 0)
            return users.find(u => u.id === rowData.user_id)?.full_name;
        return "";
    }

    const operationTypeTemplate = (rowData) => {
        return t('billing.' +rowData.operation_type)
    }
    const dateTemplate = (rowData) => <span>{toDateTimeString(rowData.created_at)}</span>;

    const isTaxAvailable = (user_id) =>{
        if(isAdmin())
            return users.length > 0 && users.find(u=> u.id === user_id)?.currency === "TRY";
        else
            return user().currency === "TRY";
        return true;
    }

    const billedAmountTemplate = (rowData) => {
        let billed_amount = rowData.billed_amount;
        let currency = "USD";
        let charged_amount = rowData.billed_amount;

        if (isTaxAvailable(rowData.user_id))
        {
            if (rowData.payment_log && rowData.payment_log.currency_code !== "USD"  && rowData.payment_log.amount_without_tax) {
                billed_amount = rowData.billed_amount / 1.18;
                charged_amount = rowData.payment_log.amount_without_tax;
                currency = rowData.payment_log.currency_code;
            }
            else
            {
                billed_amount = rowData.billed_amount / 1.18;
            }
        }

        return <React.Fragment>
            {charged_amount !== billed_amount && formatCurrency(billed_amount, "USD")}
            <Tag severity="success" value={formatCurrency(charged_amount, currency)} rounded>
                <CopyToClipboard textToCopy={charged_amount.toString().replaceAll(".", ",")} />
            </Tag>
        </React.Fragment>
    }

    const billedAmountWithTaxTemplate = (rowData) => {

        const billed_amount = rowData.billed_amount;
        let currency = "USD";
        let charged_amount = rowData.billed_amount;

        if (isTaxAvailable(rowData.user_id) && rowData.payment_log && rowData.payment_log.currency_code !== "USD"  && rowData.payment_log.amount)
        {
            charged_amount = rowData.payment_log.amount;
            currency = rowData.payment_log.currency_code;
        }

        return <React.Fragment>
                {charged_amount !== billed_amount && formatCurrency(billed_amount, "USD")}
                <Tag severity="success" value={formatCurrency(charged_amount, currency)} rounded>
                    <CopyToClipboard textToCopy={charged_amount.toString().replaceAll(".", ",")} />
                </Tag>
        </React.Fragment>
    }

    const deleteBalanceHistory = (rowData) => {
        billingService.deleteBalanceHistory(rowData.id).then(result=>{
            toast.current.show({severity: 'success', summary: t('actions.remove'), detail: t('message_detail.successful')});
            setRowData(null);
        }).catch(error => {
            setError(error);
            setError(null);
            setRowData(null);
        });

    }

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            {isAdmin() &&<Button icon="pi pi-trash" className="p-button-outlined p-button-rounded p-button-danger p-mr-1"
                                 tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => {deleteBalanceHistory(rowData);}}/>}
            {(rowData.operation_type.includes('subscription') || (isAdmin() && user().country === "TR")) && <React.Fragment>
                {isAdmin() &&
                <React.Fragment>
                    <Button icon="pi pi-pencil" className="p-button-outlined p-button-rounded p-button-success p-mr-1"
                            tooltip={t('billing.set_invoice_number')} tooltipOptions={{position: 'bottom'}}
                            onClick={(e) => {
                                setRowData(rowData);
                                op.current.toggle(e);
                            }}/>

                </React.Fragment>
                }

                {rowData.invoice_number  && rowData.invoice_number !== '-' ? <Button icon="pi pi-file-pdf" className="p-button-outlined p-button-rounded p-button-help p-mr-1" tooltip={t('actions.get_invoice')} tooltipOptions={{position: 'bottom'}}
                                                       onClick={() => {download(rowData.id, rowData.invoice_number)}} loading={loadingInvoice && rowData.id === loadingInvoice} /> : <></>}

                {isAdmin() && rowData.invoice_number && rowData.billing_address.country.toLowerCase() !== 'türkiye' && rowData.billing_address.country.toLowerCase() !== 'turkey' && rowData.invoice_number !== '-' ? <Button icon="pi pi-send" className="p-button-outlined p-button-rounded p-button-success p-mr-1" tooltip={t('actions.send_invoice')} tooltipOptions={{position: 'bottom'}}
                                                  onClick={() => {sendInvoice(rowData.id)}} loading={loadingInvoice && rowData.id === loadingInvoice} /> : <></>}

            </React.Fragment>}
        </React.Fragment> ;
    };

    const filteredBalanceHistory = isAdmin() ? balanceHistory.filter(b=> (user_id.length === 0 || user_id.includes(b.user_id)) && new Date(b.created_at) >= new Date(dates[0]) && new Date(b.created_at) <= new Date(dates[1])) : balanceHistory;

    return <React.Fragment>
        <OverlayPanel ref={op} style={{ width:"30em"}}>
            <div className="p-col-12"><b>{t('common.id')}: </b> {rowData?.id}<CopyToClipboard textToCopy={rowData?.id} /></div>
            <br/>
            <InputText id="invoice_number" name="invoice_number" value={invoiceNumber} onChange={(e) => {setInvoiceNumber(getKeyValue(e).value)}} style={{ width:"20em"}} placeHolder={t('billing.invoice_number')}/>
            <Button icon="pi pi-pencil" className="p-button-outlined p-button-success" label={t('actions.update')}
                    style={{marginLeft: '.5em'}} onClick={updateInvoiceNumber}/>
        </OverlayPanel>

        {isAdmin() && <div className="p-grid">
            <div className="p-col-12">
                <MultiSelect id="user" name ="user" value={user_id} options={users.filter(u => balanceHistory.some(b=> b.user_id === u.id))}
                             filter={true}
                          onChange={(e) => setUserID(getKeyValue(e).value)}
                          placeholder={t('user.name')}
                          optionLabel="full_name" optionValue="id" style={{marginRight: '0.5em'}}/>
                <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={dates} onChange={(e) => setDates(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em'}}/>
            </div>
        </div>}

        <DataTable value={filteredBalanceHistory} responsive={true}
                   selectionMode="single"
                   selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
        sortField="created_at" sortOrder={-1}>
            {isAdmin() && <Column body={userTemplate} header={t('user.name')} />}
            {isAdmin() && <Column field="billing_address.company" header={t('user.company')} />}
            <Column field="operation_type" body={operationTypeTemplate} header={t('billing.operation_type')} />
            <Column field="created_at" body={dateTemplate} header={t('billing.created_at')} />
            <Column field="old_user_balance" header={t('billing.old_user_balance')} style={{textAlign:"right"}} />
            <Column field="new_user_balance" header={t('billing.new_user_balance')} style={{textAlign:"right"}}/>
            <Column field="description" header={t('billing.description')}/>
            <Column field="billed_amount" className="p-text-right" body={billedAmountTemplate} header={t('billing.billed_amount')}/>
            <Column field="billed_amount_with_tax" className="p-text-right" body={billedAmountWithTaxTemplate} header={t('billing.billed_amount_with_tax')}/>
            {isAdmin() && <Column field="invoice_number" className="p-text-right" header={t('billing.invoice_number')}/>}
            <Column body={actionTemplate} style={{textAlign: 'center'}}  style={{width:"13em"}}/>
        </DataTable>

    </React.Fragment>;

};
export default BillingHistory;
