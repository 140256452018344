import React, {useEffect, useState} from "react";

import {AppContext} from "../../HomePage";
import {Panel} from "primereact/panel";
import {DashboardService} from "../../service/DashboardService";
import {Loading} from "../../utils/InlineComponents";

import {CampaignContext} from "../../App";

const OverviewUrl = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [loading, setLoading] = useState(false);

    const [trackedWebsitesOverview, setTrackedWebsitesOverview] = useState(null);
    const [websites, setWebsites] = useState([]);
    const [activeUrls, setActiveUrls] = useState([]);
    const [inactiveUrls, setInactiveUrls] = useState([]);
    const [childUrls, setChildUrls] = useState([]);

    const dashboardService = new DashboardService();

    useEffect ( ()=>{
        search();
    },[selectedCampaign]);

    const search = () => {
        setLoading(true);

        dashboardService.getTrackedWebsitesOverview(selectedCampaign.id).then(overview=>{
            setTrackedWebsitesOverview(overview);
            setWebsites(overview.websites.map((website, index) => {
                return (
                    <li>
                        <button className="p-link">
                            <span className="count blue">{website.total}</span>
                            <span className="title">{website.name}</span>
                        </button>
                    </li>
                );
            }))
            setActiveUrls(overview.active_urls.map((website, index) => {
                return (
                    <li>
                        <button className="p-link">
                            <span className="count green">{website.total}</span>
                            <span className="title">{website.name}</span>
                        </button>
                    </li>
                );
            }))
            setInactiveUrls(overview.inactive_urls.map((website, index) => {
                return (
                    <li>
                        <button className="p-link">
                            <span className="count red">{website.total}</span>
                            <span className="title">{website.name}</span>
                        </button>
                    </li>
                );
            }))
            setChildUrls(overview.child_urls.map((website, index) => {
                return (
                    <li>
                        <button className="p-link">
                            <span className="count yellow">{website.total}</span>
                            <span className="title">{website.name}</span>
                        </button>
                    </li>
                );
            }))
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    }

    return <React.Fragment>

        {loading ?
            <div className="p-col-12" style={{textAlign:'center'}}>
                <Loading/>
            </div> :
            trackedWebsitesOverview && <div className="p-grid p-fluid dashboard card">
                <div className="p-col-3">
                    <div className="card summary">
                        <button className="p-link">
                            <span className="title">{t('overview.total_website_count')}</span>
                        </button>
                        <span className="detail">{t('overview.total_website_count_detail')}</span>
                        <span className="count blue">{trackedWebsitesOverview.total_website_count}</span>
                    </div>
                </div>
                <div className="p-col-3">
                    <div className="card summary">
                        <button className="p-link">
                            <span className="title">{t('overview.total_url_count')}</span>
                        </button>
                        <span className="detail">{t('overview.total_url_count_detail')}</span>
                        <span className="count blue">{trackedWebsitesOverview.total_url_count}</span>
                    </div>
                </div>
                <div className="p-col-3">
                    <div className="card summary">
                        <button className="p-link">
                            <span className="title">{t('overview.total_active_url_count')}</span>
                        </button>
                        <span className="detail">{t('overview.total_active_url_count_detail')}</span>
                        <span className="count green">{trackedWebsitesOverview.total_active_url_count}</span>
                    </div>
                </div>
                <div className="p-col-3">
                    <div className="card summary">
                        <button className="p-link">
                            <span className="title">{t('overview.total_inactive_url_count')}</span>
                        </button>
                        <span className="detail">{t('overview.total_inactive_url_count_detail')}</span>
                        <span className="count red">{trackedWebsitesOverview.total_inactive_url_count}</span>
                    </div>
                </div>

                <div className="p-col-12 p-lg-3 contacts fixed_list">
                    <Panel header={t('overview.websites')} style={{height:'100%'}}>
                        <ul className="activity-list">{websites}</ul>
                    </Panel>
                </div>

                <div className="p-col-12 p-lg-3 contacts fixed_list">
                    <Panel header={t('overview.active_urls')} style={{height:'100%'}}>
                        <ul className="activity-list">{activeUrls}</ul>
                    </Panel>
                </div>

                <div className="p-col-12 p-lg-3 contacts fixed_list">
                    <Panel header={t('overview.market_place_seller_urls')} style={{height:'100%'}}>
                        <ul className="activity-list">{childUrls}</ul>
                    </Panel>
                </div>

                <div className="p-col-12 p-lg-3 contacts fixed_list">
                    <Panel header={t('overview.inactive_urls')} style={{height:'100%'}}>
                        <ul className="activity-list">{inactiveUrls}</ul>
                    </Panel>
                </div>
            </div>}
    </React.Fragment>;
};
export default OverviewUrl;
