import {getKeyValue} from "../../utils/Utils";
import {RadioButton} from "primereact/radiobutton";
import {InputText} from "primereact/inputtext";
import React, {useEffect, useState} from "react";

import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import {InputNumber} from "primereact/inputnumber";
import {AppContext} from "../../HomePage";
import {UserService} from "../../service/UserService";
import {proxy_countries} from "../../utils/Constants";

const WebSearchSetting = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const [isGoogleShoppingSearch, setIsGoogleShoppingSearch] = useState(props.isGoogleShoppingSearch);

    const [page_number, setPageNumber] = useState(props.webSearchSetting.page_number ? props.webSearchSetting.page_number : 3);
    const [proxy_country, setProxyCountry] = useState(props.webSearchSetting.proxy_country ? props.webSearchSetting.proxy_country : "");
    const [country, setCountry] = useState(props.webSearchSetting.country ? props.webSearchSetting.country : "");
    const [language, setLanguage] = useState(props.webSearchSetting.language ? props.webSearchSetting.language : "");
    const [google_shopping, setGoogleShopping] = useState(props.webSearchSetting.google_shopping ? props.webSearchSetting.google_shopping : isGoogleShoppingSearch === undefined ? false : isGoogleShoppingSearch);
    const [extraSearchWord, setExtraSearchWord] = useState(props.webSearchSetting.extra_search_word ? props.webSearchSetting.extra_search_word : "");
    const [excludedProducts, setExcludedProducts] = useState(props.webSearchSetting && props.webSearchSetting.exclude_products_with_suggestions !== null && props.webSearchSetting.exclude_products_with_suggestions !== undefined ? props.webSearchSetting.exclude_products_with_suggestions : false);
    const [alsoFindNewWebsites, setAlsoFindNewWebsites] = useState(props.webSearchSetting && props.webSearchSetting.also_find_new_websites !== null && props.webSearchSetting.also_find_new_websites !== undefined ? props.webSearchSetting.also_find_new_websites : false);
    const [useHeadless, setUseHeadless] = useState(props.webSearchSetting && props.webSearchSetting.use_headless !== null && props.webSearchSetting.use_headless !== undefined ? props.webSearchSetting.use_headless : false);
    const [runAutoMatch, setRunAutoMatch] = useState(props.webSearchSetting && props.webSearchSetting.run_auto_match !== null && props.webSearchSetting.run_auto_match !== undefined ? props.webSearchSetting.run_auto_match : false);
    const [searchMatchedWebsitesToo, setSearchMatchedWebsitesToo] = useState(props.webSearchSetting && props.webSearchSetting.search_matched_websites_too !== null && props.webSearchSetting.search_matched_websites_too !== undefined ? props.webSearchSetting.search_matched_websites_too : false);
    const [searchWithoutFilteringWebsites, setSearchWithoutFilteringWebsites] = useState(props.webSearchSetting && props.webSearchSetting.search_without_filtering_websites !== null && props.webSearchSetting.search_without_filtering_websites !== undefined ? props.webSearchSetting.search_without_filtering_websites : false);
    const [selectedFields, setSelectedFields] = useState(props.webSearchSetting.selected_fields ? props.webSearchSetting.selected_fields : null);
    const [jobUnits, setJobUnits] = useState(props.webSearchSetting.job_units ? props.webSearchSetting.job_units : 1);
    const [dividedCompetitorsNo, setDividedCompetitorsNo] = useState(props.webSearchSetting.divided_competitors_no ? props.webSearchSetting.divided_competitors_no : 0);
    const [jobPriority, setJobPriority] = useState(props.webSearchSetting.job_priority ? props.webSearchSetting.job_priority : 2);
    const [sellerFilter, setSellerFilter] = useState(props.webSearchSetting.seller_filter ? props.webSearchSetting.seller_filter : 'seller_filter_while_searching');
    const [currency, setCurrency] = useState(props.webSearchSetting.currency ? props.webSearchSetting.currency : null);

    const [currencies, setCurrencies] = useState([]);
    const userService = new UserService();

    useEffect ( ()=>{
        props.webSearchSetting.page_number = page_number
        props.webSearchSetting.proxy_country = proxy_country
        props.webSearchSetting.country = country
        props.webSearchSetting.language = language
        props.webSearchSetting.google_shopping = google_shopping
        props.webSearchSetting.extra_search_word = extraSearchWord
        props.webSearchSetting.exclude_products_with_suggestions = excludedProducts
        props.webSearchSetting.also_find_new_websites = alsoFindNewWebsites
        props.webSearchSetting.use_headless = useHeadless
        props.webSearchSetting.run_auto_match = runAutoMatch
        props.webSearchSetting.search_matched_websites_too = searchMatchedWebsitesToo
        props.webSearchSetting.search_without_filtering_websites = searchWithoutFilteringWebsites
        props.webSearchSetting.selected_fields = selectedFields
        props.webSearchSetting.job_units = jobUnits
        props.webSearchSetting.divided_competitors_no = dividedCompetitorsNo
        props.webSearchSetting.job_priority = jobPriority
        props.webSearchSetting.seller_filter = sellerFilter

        if (currencies.length === 0) {
            userService.getCurrencies().then(data=>{
                setCurrencies(data);
            })
        }
    });

    const proxyCountries = proxy_countries(t);

    const countries = [
        {name: 'All', code: null},
        {name: 'Australia', code: 'countryAU'},
        {name: 'Bulgaria', code: 'countryBG'},
        {name: 'Canada', code: 'countryCA'},
        {name: 'China', code: 'countryCN'},
        {name: 'Denmark', code: 'countryDK'},
        {name: 'European Union', code: 'countryEU'},
        {name: 'France', code: 'countryFR'},
        {name: 'Germany', code: 'countryDE'},
        {name: 'Greece', code: 'countryGR'},
        {name: 'Hong Kong', code: 'countryHK'},
        {name: 'India', code: 'countryIN'},
        {name: 'Indonesia', code: 'countryID'},
        {name: 'Italy', code: 'countryIT'},
        {name: 'Japan', code: 'countryJP'},
        {name: 'Netherlands', code: 'countryNL'},
        {name: 'New Zealand', code: 'countryNZ'},
        {name: 'Poland', code: 'countryPL'},
        {name: 'Saudi Arabia', code: 'countrySA'},
        {name: 'Spain', code: 'countryES'},
        {name: 'Sweden', code: 'countrySE'},
        {name: 'Switzerland', code: 'countryCH'},
        {name: 'Turkey', code: 'countryTR'},
        {name: 'United Arab Emirates', code: 'countryAE'},
        {name: 'United Kingdom', code: 'countryUK'},
        {name: 'United States', code: 'countryUS'},
    ];

    const languages = [
        {name: 'All', code: null},
        {name: 'Arabic', code: 'lang_ar'},
        {name: 'Bulgarian', code: 'lang_bg'},
        {name: 'Chinese (Simplified)', code: 'lang_zh-CN'},
        {name: 'Croatian', code: 'lang_hr'},
        {name: 'Czech', code: 'lang_cs'},
        {name: 'Danish', code: 'lang_da'},
        {name: 'Dutch', code: 'lang_nl'},
        {name: 'English', code: 'lang_en'},
        {name: 'French', code: 'lang_fr'},
        {name: 'German', code: 'lang_de'},
        {name: 'Greek', code: 'lang_el'},
        {name: 'Italian', code: 'lang_it'},
        {name: 'Japanese', code: 'lang_ja'},
        {name: 'Korean', code: 'lang_ko'},
        {name: 'Latvian', code: 'lang_lv'},
        {name: 'Lithuanian', code: 'lang_lt'},
        {name: 'Norwegian', code: 'lang_no'},
        {name: 'Polish', code: 'lang_pl'},
        {name: 'Romanian', code: 'lang_ro'},
        {name: 'Russian', code: 'lang_ru'},
        {name: 'Slovenian', code: 'lang_sl'},
        {name: 'Spanish', code: 'lang_es'},
        {name: 'Swedish', code: 'lang_sv'},
        {name: 'Turkish', code: 'lang_tr'},
    ];

    const fields = [
        {name: 'Brand', code: 'brand'},
        {name: 'Name', code: 'name'},
        {name: 'Searh Key', code: 'search_key'},
        {name: 'Barcode', code: 'barcode'},
        {name: 'SKU', code: 'sku'},
        {name: 'MPN', code: 'mpn'},
        {name: 'EAN', code: 'ean'},
        {name: 'UPC', code: 'upc'},
        {name: 'Other Codes', code: 'other_codes'},
        {name: 'Cross SKU List', code: 'cross_sku'}
    ];

    const jobPriorities = [{value:0, label:'Lowest'}, {value:1, label:'Low'}, {value:2, label:'Normal'}, {value:3, label:'High'}, {value:4, label:'Highest'}];
    const sellerFilterOptions = [{value:'seller_filter_while_searching', label:t('suggestion.seller_filter_while_searching')},
        {value:'seller_filter_after_searching', label:t('suggestion.seller_filter_after_searching')},
        {value:'no_seller_filter', label:t('suggestion.no_seller_filter')}
    ];

    return <div className="p-grid p-fluid">
        <div className="p-grid p-col-12">
            <div className="p-col-fixed" style={{width:"10em"}}>
                <label>{t('suggestion.searching_website')}</label>
            </div>
            <div className="p-col">
                {
                    !isGoogleShoppingSearch && <div>
                        <RadioButton inputId="google" value={false} name="google_shopping" onChange={(e) => {props.webSearchSetting.google_shopping = getKeyValue(e).value; setGoogleShopping(getKeyValue(e).value)}} checked={google_shopping === false}/>
                        <label htmlFor="google" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('suggestion.google')}</label>
                    </div>
                }
                {
                    (isGoogleShoppingSearch || isGoogleShoppingSearch === undefined) && <div>
                        <RadioButton inputId="google_shopping" value={true} name="google_shopping" onChange={(e) => {props.webSearchSetting.google_shopping = getKeyValue(e).value; setGoogleShopping(getKeyValue(e).value)}} checked={google_shopping === true}/>
                        <label htmlFor="google_shopping" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('suggestion.google_shopping')}</label>
                    </div>
                }

            </div>
        </div>
        <div className="p-col-12 p-xl-6">
                <span className="p-float-label">
                    <Dropdown inputId="proxy_country" name="proxy_country" options={proxyCountries} value={proxy_country} onChange={(e) => {props.webSearchSetting.proxy_country = getKeyValue(e).value; setProxyCountry(getKeyValue(e).value)}} size={5} />
                    <label htmlFor="proxy_country" >{t('suggestion.proxy_country')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-6">
                <span className="p-float-label">
                    <Dropdown inputId="country" name="country" options={countries} optionLabel="name" value={country} onChange={(e) => {props.webSearchSetting.country = getKeyValue(e).value; setCountry(getKeyValue(e).value)}} size={50} />
                    <label htmlFor="country" >{t('suggestion.country')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-6">
                <span className="p-float-label">
                    <Dropdown inputId="language" name="language" options={languages} optionLabel="name" value={language} onChange={(e) => {props.webSearchSetting.language = getKeyValue(e).value; setLanguage(getKeyValue(e).value)}} size={50} />
                    <label htmlFor="language" >{t('suggestion.language')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-6">
                <span className="p-float-label">
                    <InputNumber name="page_number" value={page_number} onValueChange={(e) => {props.webSearchSetting.page_number = e.value; setPageNumber(e.value)}} showButtons={true} min={1} max={10} size={1} />
                    <label htmlFor="page_number" >{t('suggestion.page_number')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-6">
                <span className="p-float-label">
                    <MultiSelect name="search_fields" value={selectedFields} options={fields} onChange={(e) => {props.webSearchSetting.selected_fields = e.value; setSelectedFields(e.value)}} optionLabel="name"  />
                    <label htmlFor="search_fields" >{t('suggestion.search_fields')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-6">
                <span className="p-float-label">
                    <InputText id="extraSearchWord" name="extraSearchWord" value={extraSearchWord} onChange={(e) => {props.webSearchSetting.extra_search_word = e.target.value; setExtraSearchWord(e.target.value)}} style={{width:"100%"}}/>
                    <label htmlFor="extra_search_word" >{t('suggestion.extra_search_word')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-6">
                <span className="p-float-label">
                    <Dropdown id="job_priority" name="job_priority" value={jobPriority} options={jobPriorities} onChange={(e)=>{props.webSearchSetting.job_priority = getKeyValue(e).value; setJobPriority(getKeyValue(e).value);}} />
                    <label htmlFor="job_priority" >{t('suggestion.job_priority')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-3">
                <span className="p-float-label">
                    <InputNumber id="job_units" name="job_units" value={jobUnits} style={{width:"60px"}} onValueChange={(e)=>{props.webSearchSetting.job_units = getKeyValue(e).value; setJobUnits(getKeyValue(e).value);}}/>
                    <label htmlFor="job_units" >{t('suggestion.job_units')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-3">
                <span className="p-float-label">
                    <InputNumber id="divided_competitors_no" name="divided_competitors_no" value={dividedCompetitorsNo} style={{width:"60px"}} onValueChange={(e)=>{props.webSearchSetting.divided_competitors_no = getKeyValue(e).value; setDividedCompetitorsNo(getKeyValue(e).value);}}/>
                    <label htmlFor="divided_competitors_no" >{t('suggestion.divided_competitors_no')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-6">
                <span className="p-float-label">
                    <Dropdown id="seller_filter" name="seller_filter" value={sellerFilter} options={sellerFilterOptions} onChange={(e)=>{props.webSearchSetting.seller_filter = getKeyValue(e).value; setSellerFilter(getKeyValue(e).value);}} />
                    <label htmlFor="seller_filter" >{t('suggestion.seller_filter')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-6">
                <span className="p-float-label">
                    <Dropdown id="currency" name="currency" value={currency} options={currencies} filter showClear  onChange={(e)=>{props.webSearchSetting.currency = getKeyValue(e).value; setCurrency(getKeyValue(e).value);}} />
                    <label htmlFor="currency" >{t('suggestion.currency')}</label>
                </span>
        </div>

        <div className="p-col-12 p-xl-6">
            <Checkbox inputId="excludedProducts" name="excludedProducts" value={{}} checked={excludedProducts} onChange={(e) => {props.webSearchSetting.exclude_products_with_suggestions = e.target.checked; setExcludedProducts(e.target.checked)}}/>
            <label htmlFor="excludedProducts" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('suggestion.excluded_products')}</label>
        </div>

        <div className="p-col-12 p-xl-6">
            <Checkbox inputId="alsoFindNewWebsites" name="alsoFindNewWebsites" value={{}} checked={alsoFindNewWebsites} onChange={(e) => {props.webSearchSetting.also_find_new_websites = e.target.checked; setAlsoFindNewWebsites(e.target.checked)}}/>
            <label htmlFor="alsoFindNewWebsites" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('suggestion.also_find_new_websites')}</label>
        </div>

        <div className="p-col-12 p-xl-6">
            <Checkbox inputId="useHeadless" name="useHeadless" value={{}} checked={useHeadless} onChange={(e) => {props.webSearchSetting.use_headless = e.target.checked; setUseHeadless(e.target.checked)}}/>
            <label htmlFor="useHeadless" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('suggestion.use_headless')}</label>
        </div>

        <div className="p-col-12 p-xl-6">
            <Checkbox inputId="searchMatchedWebsitesToo" name="searchMatchedWebsitesToo" value={{}} checked={searchMatchedWebsitesToo} onChange={(e) => {props.webSearchSetting.search_matched_websites_too = e.target.checked; setSearchMatchedWebsitesToo(e.target.checked)}}/>
            <label htmlFor="searchMatchedWebsitesToo" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('suggestion.search_matched_websites_too')}</label>
        </div>

        {/*<div className="p-col-12 p-xl-4">*/}
        {/*    <Checkbox inputId="searchWithoutFilteringWebsites" name="searchWithoutFilteringWebsites" value={{}} checked={searchWithoutFilteringWebsites} onChange={(e) => {props.webSearchSetting.search_without_filtering_websites = e.target.checked; setSearchWithoutFilteringWebsites(e.target.checked)}}/>*/}
        {/*    <label htmlFor="searchWithoutFilteringWebsites" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('suggestion.search_without_filtering_websites')}</label>*/}
        {/*</div>*/}

        <div className="p-col-12 p-xl-4">
            <Checkbox inputId="runAutoMatch" name="runAutoMatch" value={{}} checked={runAutoMatch} onChange={(e) => {props.webSearchSetting.run_auto_match = e.target.checked; setRunAutoMatch(e.target.checked)}}/>
            <label htmlFor="runAutoMatch" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('suggestion.run_auto_match')}</label>
        </div>

    </div>;

};
export default WebSearchSetting
