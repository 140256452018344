import {
    isCampaignDropShipping,
    isCampaignManufacturer,
    isCampaignMarketSearch,
    isCampaignSeller,
    isManufacturer,
    user
} from "./Utils";

export const exportableFields = (t, campaign) => {

    let fields = [];
    fields.push({label:t('product.id'), value:"id", default: true});
    fields.push({label:t('product.product_name'), value:"name", default: true});
    fields.push({label:t('product.search_key'), value:"search_key", default: false});
    fields.push({label:t('product.properties.'), value:"properties", default: false});
    fields.push({label:t('product.category'), value:"category", default: true});
    fields.push({label:t('product.brand'), value:"brand", default: true});
    fields.push({label:t('product.tags'), value:"tags", default: false});
    fields.push({label:t('product.sku'), value:"sku", default: true});
    fields.push({label:t('product.mpn'), value:"mpn", default: false});
    fields.push({label:t('product.ean'), value:"ean", default: false});
    fields.push({label:t('product.upc'), value:"upc", default: false});
    fields.push({label:t('product.asin'), value:"asin", default: false});
    fields.push({label:t('product.barcode'), value:"barcode", default: false});
    fields.push({label:t('product.status'), value:"is_active", default: false});
    fields.push({label:t('product.image'), value:"image", default: false});
    fields.push({label:t('product.ref_id'), value:"ref_id", default: false});
    fields.push({label:t('price_violation.map_abbreviation'), value:"violation_strategy.min_value", default: false});
    fields.push({label:t('price_violation.mrp_abbreviation'), value:"violation_strategy.max_value", default: false});

    if (!isCampaignMarketSearch(campaign)) {
        fields.push({label: t('product.price'), value: "price", default: true});
        fields.push({label: t('product.currency'), value: "currency", default: true});
        fields.push({label: t('product.stock'), value: "stock", default: false});
    }

    if (isCampaignDropShipping(campaign)) {
        fields.push({label:t('product.drop_shipping_price'), value:"drop_shipping_price", default: false});
        fields.push({label:t('product.drop_shipping_cost'), value:"drop_shipping_cost", default: false});
        fields.push({label:t('product.drop_shipping_review'), value:"drop_shipping.rating_count", default: false});
        fields.push({label:t('product.drop_shipping_rate'), value:"drop_shipping.rating", default: false});
        fields.push({label:t('product.drop_shipping_best_sellers_rank'), value:"drop_shipping.best_sellers_rank", default: false});
    }

    if (isCampaignSeller(campaign) || isCampaignDropShipping(campaign)) {
        fields.push({label: t('product.product_url'), value: "product_url", default: false});
        fields.push({label: t('product.position'), value: "position", default: false});
        fields.push({label: t('reprice.min_value'), value: "pricing_strategy_min_value", default: false});
        fields.push({label: t('reprice.max_value'), value: "pricing_strategy_max_value", default: false});
        fields.push({label: t('product.target_price'), value: "target_price", default: true});
        fields.push({label: t('product.suggested_price'), value: "suggested_price", default: false});
    }

    fields.push({label:t('product.comp_count'), value:"competitorsCount", default: false});
    fields.push({label:t('product.url_count'), value:"url_count", default: false});
    fields.push({label:t('product.comp_min'), value:"competitorsMinPrice", default: true});
    fields.push({label:t('product.comp_min_shipping_price'), value:"competitorsMinShippingPrice", default: false});
    fields.push({label:t('product.comp_avg'), value:"competitorsAvgPrice", default: false});
    if (isCampaignSeller(campaign) || isCampaignDropShipping(campaign)) {
        fields.push({label:t('product.comp_avg_diff'), value:"competitorsAvgPriceDiff", default: false});
    }
    fields.push({label:t('product.comp_max'), value:"competitorsMaxPrice", default: false});
    fields.push({label:t('product.comp_max_shipping_price'), value:"competitorsMaxShippingPrice", default: false});
    fields.push({label:t('product.suggest_count'), value:"competitorSuggestionsCount", default: false});
    fields.push({label:t('product.comp_min_name'), value:"competitorsMinName", default: true});
    fields.push({label:t('product.comp_max_name'), value:"competitorsMaxName", default: false});
    fields.push({label:t('product.shelve_position'), value:"shelve_position", default: false});

    fields.push({label:t('product.review_number'), value:"review_number", default: false});

    campaign?.settings?.special_fields?.forEach(sf=> {
        if (sf.selected && !sf.name.startsWith('competitors.'))
            fields.push({label:t("product."+sf.name), value:sf.name, default: false});
    });

    return fields;
};

export const productListColumns = (t, campaign) => {

    let fields = [];

    fields.push({label:t('product.image'), value:"image", default: true});
    fields.push({label:t('product.website'), value:"website", default: false});
    fields.push({label:t('product.sku'), value:"sku", default: false});
    fields.push({label:t('product.mpn'), value:"mpn", default: false});
    fields.push({label:t('product.ean'), value:"ean", default: false});
    fields.push({label:t('product.upc'), value:"upc", default: false});
    fields.push({label:t('product.asin'), value:"asin", default: false});
    fields.push({label:t('product.barcode'), value:"barcode", default: false});
    fields.push({label:t('product.product_name'), value:"name", default: true});

    fields.push({label:t('product.properties.'), value:"properties", default: false});
    fields.push({label:t('product.search_key'), value:"search_key", default: false});
    fields.push({label:t('product.category'), value:"category", default: false});
    fields.push({label:t('product.brand'), value:"brand", default: false});
    fields.push({label:t('product.tags'), value:"tags", default: false});

    fields.push({label:t('product.rate'), value:"rate", default: false});
    fields.push({label:t('product.ref_id'), value:"ref_id", default: false});
    fields.push({label:t('product.vat'), value:"vat", default: false});
    fields.push({label:t('price_violation.map_abbreviation'), value:"map", default: false});
    fields.push({label:t('price_violation.mrp_abbreviation'), value:"mrp", default: false});

    if (!isCampaignMarketSearch(campaign))
        fields.push({label:t('product.price'), value:"price", default: true});

    if (isCampaignSeller(campaign) || isCampaignDropShipping(campaign)) {
        fields.push({label: t('product.position'), value: "position", default: false});
        fields.push({label: t('reprice.min_value'), value: "pricing_strategy_min_value", default: false});
        fields.push({label: t('reprice.max_value'), value: "pricing_strategy_max_value", default: false});
        fields.push({label: t('product.target_price'), value: "target_price", default: true});
        fields.push({label: t('product.suggested_price'), value: "suggested_price", default: false});
        fields.push({label: t('product.discount_type'), value: "discount_type", default: false});
    }

    if (isCampaignDropShipping(campaign)) {
        fields.push({label: t('product.drop_shipping_price'), value: "drop_shipping_price", default: false});
        fields.push({label: t('product.drop_shipping_cost'), value: "drop_shipping_cost", default: false});
        fields.push({label: t('product.drop_shipping_review'), value: "drop_shipping_review", default: false});
        fields.push({label: t('product.drop_shipping_rate'), value: "drop_shipping_rate", default: false});
        fields.push({label: t('product.drop_shipping_best_sellers_rank'), value: "drop_shipping_best_sellers_rank", default: false});
    }

    fields.push({label:t('product.comp_min'), value:"competitorsMinPrice", default: true});
    fields.push({label:t('product.comp_min_shipping_price'), value:"competitorsMinShippingPrice", default: false});
    fields.push({label:t('product.comp_avg'), value:"competitorsAvgPrice", default: false});
    if (isCampaignSeller(campaign) || isCampaignDropShipping(campaign)) {
        fields.push({label:t('product.comp_avg_diff'), value:"competitorsAvgPriceDiff", default: false});
    }
    fields.push({label:t('product.comp_max'), value:"competitorsMaxPrice", default: true});
    fields.push({label:t('product.comp_max_shipping_price'), value:"competitorsMaxShippingPrice", default: false});
    fields.push({label:t('product.comp_count'), value:"competitorsCount", default: true});
    fields.push({label:t('product.url_count'), value:"url_count", default: false});
    fields.push({label:t('product.suggest_count'), value:"competitorSuggestionsCount", default: true});
    fields.push({label:t('product.comp_min_name'), value:"competitorsMinName", default: false});
    fields.push({label:t('product.comp_max_name'), value:"competitorsMaxName", default: false});
    fields.push({label:t('product.status'), value:"is_active", default: false});
    fields.push({label:t('product.shelve_position'), value:"shelve_position", default: false});

    fields.push({label:t('product.review_number'), value:"review_number", default: false});

    campaign?.settings?.special_fields?.forEach(sf=> {
        if (sf.selected && !sf.name.startsWith('competitors.'))
            fields.push({label:t("product."+sf.name), value:sf.name, default: false});
    });

    return fields;
};

export const getSelectedExportableFields = (t, campaign, refresh) => {
    const allList = exportableFields(t, campaign);
    if (localStorage.getItem("exportFields") && !refresh) {
        try {
            let fields = JSON.parse(localStorage.getItem("exportFields"));
            return fields.filter(i => allList.findIndex(a=> a.value === i) !== -1)
        } catch (e) {
            return  allList.filter(e => e.default).map(e2 => {return e2.value});
        }
    }
    else
        return allList.filter(e => e.default).map(e2 => {return e2.value});
};

export const getSelectedProductListColumns = (t, campaign, refresh) => {
    const allList = productListColumns(t, campaign);
    if (localStorage.getItem("selectedColumns") && !refresh) {
        try {
            let fields = JSON.parse(localStorage.getItem("selectedColumns"));
            return fields.filter(i => allList.findIndex(a=> a.value === i) !== -1);
        } catch (e) {
            return  allList.filter(e => e.default).map(e2 => {return e2.value});
        }
    }
    else
        return  allList.filter(e => e.default).map(e2 => {return e2.value});
};

export const job_states = {
    in_preparation : "in preparation",
    todo: "todo",
    in_progress: "in progress",
    done: "done",
    failed: "failed"
};

export const proxy_types = (t) => {
    return  ['None', 'SmartProxyManager', 'ProxyFuel', 'Operia', 'ScraperAPI', 'Oxylabs', 'OxylabsRealtime', 'OxylabsNGRP',
        'IDataClickTeam', 'Zenrows', 'ZyteData', 'BrightData', 'NetNutRes', 'ZyteFetch', 'ProxyCrawl', 'SmartProxy']
};

export const proxy_countries = (t) => {
    return  ['ALL', 'APAC', 'AT', 'AU', 'BR', 'BE', 'CA', 'DE', 'EU', 'ES', 'FR', 'IE', 'IN', 'IT', 'MY', 'MX',
        'NL', 'PL', 'RU', 'SE', 'SG', 'TR', 'UK', 'US', 'UAE']
};

export const scraping_request_types = (t) => {
    return  ['limited_concurrent_requests']
};

export const yes_no = (t) => {

    return  [
        {label: t('yes_no.all'), value: null},
        {label: t('yes_no.yes'), value: true},
        {label: t('yes_no.no'), value: false}];
};

export const product_codes = (t) => {

    return  [
        {label: t('product.asin'), value: "asin"},
        {label: t('product.ean'), value: "ean"},
        {label: t('product.upc'), value: "upc"},
        {label: t('product.barcode'), value: "barcode"},
        {label: t('product.ref_id'), value: "ref_id"},
        {label: t('product.sku'), value: "sku"},
        {label: t('product.mpn'), value: "mpn"}];
};


export const getViolationCurrencyList = (selectedProduct) => {

    let currencies = [];

    if (selectedProduct.currency && selectedProduct.currency !== "")
        currencies.push({label: selectedProduct.currency, value : selectedProduct.currency});
    if (currencies.findIndex(c=> c.value === user().currency) === -1)
        currencies.push({label: user().currency, value: user().currency});

    if (currencies.findIndex(c=> c.value === 'USD') === -1)
        currencies.push({label: 'USD', value: 'USD'});
    if (currencies.findIndex(c=>  c.value === 'EUR') === -1)
        currencies.push({label: 'EUR', value: 'EUR'});

    if (selectedProduct.violation_strategy && currencies.findIndex(c=>  selectedProduct.violation_strategy && c.value === selectedProduct.violation_strategy.currency) === -1)
        currencies.push({label: selectedProduct.violation_strategy.currency, value: selectedProduct.violation_strategy.currency});

    return currencies;
    //{label:'CAD', value:'CAD'},
    // {label:'HKD', value:'HKD'},
    // {label:'ISK', value:'ISK'},
    // {label:'PHP', value:'PHP'},
    // {label:'DKK', value:'DKK'},
    // {label:'HUF', value:'HUF'},
    // {label:'CZK', value:'CZK'},
    // {label:'GBP', value:'GBP'},
    // {label:'RON', value:'RON'},
    // {label:'SEK', value:'SEK'},
    // {label:'IDR', value:'IDR'},
    // {label:'INR', value:'INR'},
    // {label:'BRL', value:'BRL'},
    // {label:'RUB', value:'RUB'},
    // {label:'HRK', value:'HRK'},
    // {label:'JPY', value:'JPY'},
    // {label:'THB', value:'THB'},
    // {label:'CHF', value:'CHF'},
    // {label:'MYR', value:'MYR'},
    // {label:'BGN', value:'BGN'},
    // {label:'CNY', value:'CNY'},
    // {label:'NOK', value:'NOK'},
    // {label:'NZD', value:'NZD'},
    // {label:'ZAR', value:'ZAR'},
    // {label:'MXN', value:'MXN'},
    // {label:'SGD', value:'SGD'},
    // {label:'AUD', value:'AUD'},
    // {label:'ILS', value:'ILS'},
    // {label:'KRW', value:'KRW'},
    // {label:'PLN', value:'PLN'}
};

export const user_types = (t) => {

    return  [
        {label: t('user.market_research'), value: null},
        {label: t('user.manufacturer'), value: true},
        {label: t('user.seller'), value: false}];
};

export const usage_types_values = [
    {value:"seller", label:'Seller'},
    {value:"manufacturer", label:'Manufacturer'},
    {value:"market_search", label:'Market Search'},
    {value:'drop_shipping', label:'Drop Shipping'}
    ];

export const scrapy_cloud_projects = [
    {value:"scraper", label:'Scraper'},
    {value:"selenium", label:'Selenium'},
    {value:"puppeteer", label:'Puppeteer'},
];

export const usage_types = (t) => {
    return [
        {value:"seller", label:t('campaign.usage_type_competitive')},
        {value:"manufacturer", label:t('campaign.usage_type_map_monitoring')},
        {value:"market_search", label:t('campaign.usage_type_market_research')},
        {value:'drop_shipping', label:t('campaign.usage_type_drop_shipping')}
    ];
};

export const tagList = [
    "Alabilir",
    "Durumu Değerlendirelim",
    "Durum Bilgisi Al",
    "Teklif Yapıldı",
    "Demo Yapıldı",
    "Dönüş Bekleniyor",
    "Demo Yapılacak",
    "Mail Atılacak",
    "Aranacak",
    "Hesap Kurulacak",
    "Spiderlar Yazılacak",
    "Eski Müşteri"];

export const protectionStrategies = (t) => {
    return [{label:t('reprice.suggest_min_value'), value: "min_value"},
             {label:t('reprice.suggest_optimum_value'), value:"optimum_value"},
             {label:t('reprice.do_nothing'), value:"do_nothing"}];};

