import React, {useEffect, useState} from "react";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

import {formatCurrency, formatDecimal, getHostName, getKeyValue, isAdmin, isPreventSelect, user} from "../../utils/Utils";
import {toDateTimeString} from "../../utils/TimeUtil";

import {AppContext} from "../../HomePage";
import {SelectButton} from "primereact/selectbutton";
import {StockTemplate, IndexTemplate} from "../../utils/InlineComponents";
import {Toolbar} from "primereact/toolbar";

const CompetitorSuggestion = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [filters, setFilters] = useState({
        is_matched: null,
        name: "",
        used_base_spider: localStorage.getItem("usedBaseSpiderFilter") !== null ? JSON.parse(localStorage.getItem("usedBaseSpiderFilter")) : null
    });

    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestions, setSelectedSuggestions] = useState([]);

    const matchedOptions = [
        {name: t('yes_no.all'), value: "all"},
        {name: t('actions.decision_not_made'), value: null},
        {name: t('actions.seller_added'), value: true, className: "green"},
        {name: t('actions.seller_ignored'), value: false, className: "yellow"}
    ];

    const usedBaseSpiderOptions = [
        {name: t('yes_no.all'), value: null},
        {name: "From Base Spider", value: true, className: "green"},
        {name: "Has Own Spider", value: false, className: "yellow"}
    ];

    useEffect(() => {

        const suggestions = props.selectedProduct.competitor_suggestions ? props.selectedProduct.competitor_suggestions.filter(suggestion =>
            ((!filters.name || (filters.name && suggestion.name && suggestion.name.toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.product_url && getHostName(suggestion.product_url).toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.sku && suggestion.sku.toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.ean && suggestion.ean.toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.mpn && suggestion.mpn.toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.upc && suggestion.upc.toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.barcode && suggestion.barcode.toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.price && suggestion.price.value && suggestion.price.value.toString().toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.price && suggestion.price.value && formatDecimal(suggestion.price.value).toString().toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.price && suggestion.price.currency && suggestion.price.currency.toLowerCase().includes(filters.name.toLowerCase()))) ||
                (!filters.name || (filters.name && suggestion.asin && suggestion.asin.toLowerCase().includes(filters.name.toLowerCase())))) &&
                (filters.used_base_spider === null || (filters.used_base_spider === true && suggestion.used_base_spider) || (filters.used_base_spider === false && !suggestion.used_base_spider)) &&

            (filters.is_matched === "all" ||
                (filters.is_matched === null && !suggestion.is_match_decision_made) ||
                (filters.is_matched === true && suggestion.is_match_decision_made && suggestion.is_matched) ||
                (filters.is_matched === false && suggestion.is_match_decision_made && !suggestion.is_matched)
            )) : [];

        setSuggestions([...suggestions]);

    }, [props.selectedProduct, filters]);

    const onChange = (e) => {
        const {key, value} = getKeyValue(e);
        if (key==="used_base_spider")
            localStorage.setItem("usedBaseSpiderFilter", value);
        setFilters({...filters, [key]: value});
    };

    const addAsCompetitor = (rowData) => {
        let urls = [];
        urls.push({product_url: rowData.product_url});
        props.addCompetitor(urls, null);
    };
    const ignoreCompetitorSuggestion = (rowData) => {
        let urls = [];
        urls.push({product_url: rowData.product_url});
        props.ignoreCompetitorSuggestion(urls);
    };
    const addAllAsCompetitor = () => {
        let urls = [];
        let ss = selectedSuggestions.length > 0 ? selectedSuggestions : suggestions;
        ss.forEach(suggestion => {
            if (!suggestion.is_match_decision_made)
                urls.push({product_url: suggestion.product_url});
        });
        if (urls.length !== 0) {
            props.addCompetitor(urls, null);
            setSelectedSuggestions([]);
        }
    };
    const ignoreAllCompetitorSuggestion = () => {
        let urls = [];
        let ss = selectedSuggestions.length > 0 ? selectedSuggestions : suggestions;
        ss.forEach(suggestion => {
            if (!suggestion.is_match_decision_made)
                urls.push({product_url: suggestion.product_url});
        });

        if (urls.length !== 0) {
            props.ignoreCompetitorSuggestion(urls);
            setSelectedSuggestions([]);
        }

    };

    const suggestionProductUrlTemplate = (rowData) => {

        let l = document.createElement("a");
        l.href = rowData.product_url;

        return <div>
            <span className="p-column-title">{t('table_headers.site')}</span>
            <a target="_blank" rel="noopener noreferrer" href={rowData.product_url} title={getHostName(rowData.product_url)}> {getHostName(rowData.product_url)} </a>
        </div>;
    };
    const suggestionImageTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{t('product.image')}</span>
            <img referrerPolicy='no-referrer' className="img-fluid-thumb" src={rowData.image} alt=""/>
        </div>;
    }
    const suggestionNameTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{t('table_headers.name')}</span>
            {rowData.name}
            {rowData.brand && <div><b>{t('product.brand')} : </b>{rowData.brand}</div>}
            {rowData.category && <div><b>{t('product.category')} : </b>{rowData.category}</div>}
            {rowData.sku && <div><b>{t('product.sku')} : </b>{rowData.sku}</div>}
            {rowData.mpn && <div><b>{t('product.mpn')} : </b>{rowData.mpn}</div>}
            {rowData.ean && <div><b>{t('product.ean')} : </b>{rowData.ean}</div>}
            {rowData.upc && <div><b>{t('product.upc')} : </b>{rowData.upc}</div>}
            {rowData.asin && <div><b>{t('product.asin')} : </b>{rowData.asin}</div>}
            {rowData.barcode && <div><b>{t('product.barcode')} : </b>{rowData.barcode}</div>}
        </div>;
    };
    const suggestionCreatedTemplate = (rowData) => <div className="p-text-right">
        <span className="p-column-title">{t('table_headers.match_time')}</span>
        {rowData.price && toDateTimeString(rowData.price.created_at)}
    </div>;
    const suggestionPriceTemplate = (rowData) => <div className="p-text-nowrap p-text-right">
        <span className="p-column-title">{t('product_detail.price')}</span>
        {rowData.price && rowData.price.value ? (formatCurrency(rowData.price.value, rowData.price.currency)) : ""}
    </div>;
    const suggestionStockTemplate = (rowData) => {
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product_detail.stock')}</span>
            {rowData.used_base_spider && user().is_admin ? <Button icon="pi pi-exclamation-triangle" className="imageButton" tooltip={rowData.used_base_spider} style={{color: 'orange'}}/> : null}
            {StockTemplate(rowData.price ? rowData.price.stock : 0)}
        </div>
    }
    const suggestionScoreTemplate = (rowData) =>
        <div className="p-grid p-justify-end">{rowData.match_score ? formatDecimal(rowData.match_score * 100) : "0"}</div>;
    const suggestionActionTemplate = (rowData) => {
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('table_headers.action')}</span>
            <Button icon="pi pi-thumbs-up" className="p-button-outlined p-button-rounded p-button-success" tooltip={t('actions.add_as_competitor')} tooltipOptions={{position: 'bottom'}} style={{marginRight: '.5em'}} onClick={() => addAsCompetitor(rowData)}/>
            <Button icon="pi pi-thumbs-down" className="p-button-outlined p-button-rounded p-button-warning" tooltip={t('actions.mark_not_competitor')} tooltipOptions={{position: 'bottom'}} onClick={() => ignoreCompetitorSuggestion(rowData)}/>
        </div>;
    }

    const decisionColor = (rowData) => {
        if (selectedSuggestions.some(s => s.product_url === rowData.product_url))
            return {'orange': true};
        else if (rowData.is_match_decision_made && rowData.is_matched)
            return {'green': true};
        else if (rowData.is_match_decision_made && !rowData.is_matched)
            return {'yellow': true};
        return "";
    };

    const toolbarLeftContents = <React.Fragment>
        <i className="pi pi-filter p-toolbar-separator"/>
        <InputText id="name" name="name" value={filters.name} onChange={onChange} style={{
            width: '10em',
            marginRight: '.5em'
        }} placeholder={t('product.globalFilter')}/>
        <SelectButton name="is_matched" value={filters.is_matched} options={matchedOptions} optionLabel="name" onChange={onChange}/>
    </React.Fragment>;

    const toolbarRightContents = <div className="p-align-right">
        <Button icon="pi pi-thumbs-up" className="p-button-outlined p-button-success p-mr-1" label={t('actions.add_as_competitor_all', {number: ' (' + (selectedSuggestions.length ? selectedSuggestions.length : suggestions.length) + ')'})} onClick={addAllAsCompetitor}/>
        <Button icon="pi pi-thumbs-down" className="p-button-outlined p-button-warning" label={t('actions.mark_not_competitor_all', {number: ' (' + (selectedSuggestions.length ? selectedSuggestions.length : suggestions.length) + ')'})} onClick={ignoreAllCompetitorSuggestion}/>
    </div>;

    return (

        <React.Fragment>
            {isAdmin() &&
            <SelectButton name="used_base_spider" value={filters.used_base_spider} options={usedBaseSpiderOptions} optionLabel="name" onChange={onChange}/>
            }
            <br/>
            <Toolbar left={toolbarLeftContents} right={toolbarRightContents}/>

            <br/>

            <DataTable value={suggestions}
                       responsive={true}
                       resizableColumns={true} columnResizeMode="fit"
                       sortField="match_score" sortOrder={-1}
                       rowClassName={decisionColor}
                       selectionMode="multiple" metaKeySelection={false}
                       selection={selectedSuggestions} onSelectionChange={(e) => {
                if (!isPreventSelect(e.originalEvent.target))
                    setSelectedSuggestions(e.value)
            }}>
                <Column body={IndexTemplate} headerStyle={{width: '3em'}}/>
                <Column field="product_url" body={suggestionProductUrlTemplate} header={t('table_headers.site')} headerStyle={{width: '12%'}}/>
                <Column field="image" body={suggestionImageTemplate} header={t('product.image')} headerStyle={{width: '12%'}}/>
                <Column field="name" body={suggestionNameTemplate} header={t('table_headers.name')} headerStyle={{width: '32%'}}/>
                <Column field="created_At" body={suggestionCreatedTemplate} header={t('table_headers.match_time')} headerStyle={{textAlign: "right", width: '10%'}}/>
                <Column field="price" body={suggestionPriceTemplate} header={t('product_detail.price')} headerStyle={{textAlign: "right", width: '8%'}}/>
                <Column field="stock" body={suggestionStockTemplate} header={t('product_detail.stock')} headerStyle={{textAlign: "right", width: '8%'}}/>
                {/*<Column field="match_score" body={suggestionScoreTemplate}  header={t('product_detail.match_score')} headerStyle={{textAlign:"right"}} style={{width:'8%'}}/>*/}
                <Column body={suggestionActionTemplate} header={t('table_headers.action')} headerStyle={{textAlign: 'right',width: '7em'}} className="preventSelect"/>
            </DataTable>


        </React.Fragment>

    );

};
export default CompetitorSuggestion;
