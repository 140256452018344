import React, {useEffect, useState} from "react";

import {Button} from "primereact/button";
import {Message} from "primereact/message";

import {formatCurrency, formValid, getKeyValue} from "../../utils/Utils";
import {Loading, Tooltip, IndexTemplate} from "../../utils/InlineComponents";

import {Dialog} from "primereact/dialog";
import {ProductService} from "../../service/ProductService";

import Number from "../Common/Number";
import {AppContext} from "../../HomePage";
import {Dropdown} from "primereact/dropdown";
import {protectionStrategies} from "../../utils/Constants";
import {SelectButton} from "primereact/selectbutton";
import {getWeeksAgo, toDateTimeString} from "../../utils/TimeUtil";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

const ProductReprice = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const productService = new ProductService();

    let selectedProduct = {...props.selectedProduct};

    const defaultStrategy = {
        scope: "campaign",
        min_value : 0,
        protection_strategy: "min_value",
        optimum_value : 0,
        reduction_type : "ratio",
        reduction_direction: 1,
        reduction_value : 0,
        is_active : false,
        is_auto_update : false,
        ignore_some_competitors: false,
    };

    const [pricingStrategy, setPricingStrategy] = useState({...defaultStrategy});
    const [appliedSuggestions, setAppliedSuggestions] = useState([]);
    const [showHistory, setShowHistory] = useState(false);

    const [formErrors, setFormErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);



    const scopes = [{name:t('reprice.campaign'), value: 'campaign'}, {name: t('reprice.product'), value: 'product'}];
    const reductionTypes = [{name:t('reprice.amount'), value: 'amount'}, {name: t('reprice.ratio'), value: 'ratio'}];
    const reductionDirections = [{name:t('reprice.low'), value: 1}, {name: t('reprice.high'), value: -1}];
    const competitors = [{name:t('reprice.all'), value: false}, {name: t('reprice.only_specified'), value: true}];
    const statuses = [{name:t('actions.passive'), value: false}, {name: t('actions.active'), value: true}];
    const updateTypes = [{name:t('reprice.manuel_update'), value: false}, {name: t('reprice.auto_update'), value: true}];

    useEffect ( ()=>{
        selectedProduct = {...props.selectedProduct};

        if (selectedProduct)
        {
            if (selectedProduct.pricing_strategy)
                setPricingStrategy({...selectedProduct.pricing_strategy,
                    is_active: selectedProduct.pricing_strategy.is_active ?? false,
                    is_auto_update: selectedProduct.pricing_strategy.is_auto_update ?? false,
                    ignore_some_competitors: selectedProduct.pricing_strategy.ignore_some_competitors ?? false,
                    scope: selectedProduct.pricing_strategy.reduction_type === "campaign" ? "campaign" : "product",
                    reduction_type: selectedProduct.pricing_strategy.reduction_type === "campaign" ? "ratio" : selectedProduct.pricing_strategy.reduction_type,
                    reduction_direction: selectedProduct.pricing_strategy.reduction_value >= 0 ? 1 : -1,
                    reduction_value: Math.abs(selectedProduct.pricing_strategy.reduction_value)
                });
            else
                setPricingStrategy({...defaultStrategy});
        }
    },[props.selectedProduct]);

    const sendPriceToSite = async () => {
        setLoading(true);
        productService.applyPriceSuggestion(selectedProduct.id).then(message=>{
            setLoading(false);
            setShowModal(false);
            toast.current.show({severity: 'success', summary: t('reprice.send_price_to_site'), detail: t('message_detail.successful')});
        }).catch(error => {
            setLoading(false);
            setShowModal(false);
            setError(error);
            setError(null);
        });
    };

    const getAppliedPriceSuggestions = (e) => {
        productService.getAppliedPriceSuggestions(selectedProduct.id, new Date(getWeeksAgo(2)), new Date()).then(result => {
            setAppliedSuggestions(result.map(ap => ({
                ...ap, difference: parseFloat(ap.suggested_value) - parseFloat(ap.owner_value)
            })));

            setShowHistory(true);
        });
    }

    const savePricingStrategy = () => {

        checkErrors(pricingStrategy).then(formErrors=> {
            if (formValid(formErrors)) {

                setLoading(true);

                let psToSave = {...pricingStrategy};
                if (psToSave.scope === "campaign") {
                    psToSave.reduction_type = "campaign";
                    psToSave.reduction_value = null;
                    psToSave.is_active = false;
                    psToSave.is_auto_update = false;
                    delete psToSave.scope;

                    setPricingStrategy({...pricingStrategy, reduction_value: null, is_active: false, is_auto_update: false});

                }
                else
                {
                    delete psToSave.scope;
                }

                psToSave.reduction_value = psToSave.reduction_value * psToSave.reduction_direction;
                delete psToSave.reduction_direction;

                props.savePricingStrategy(psToSave).then(()=>{
                    setLoading(false);
                }).catch(error => {
                    setLoading(false);
                    setError(error);
                    setError(null);
                });
            }
            else
                setFormErrors(formErrors);
        });
    };

    const onChange = (e) =>{

        const { key, value } = getKeyValue(e);

        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setPricingStrategy({...pricingStrategy, [key] : value});
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0
    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "scope") {
            errorText = value !== "campaign" && value !== "product" ? t('validations.invalid_type') : "";
        } else if (key === "min_value") {
            errorText = value < 0 ? t('validations.invalid_value') : "";
        } else if (key === "optimum_value") {
            errorText = showOptimumValue && value < 0 ? t('validations.invalid_value') : "";
        } else if (key === "reduction_type") {
            errorText = value !== "amount" && value !== "ratio" ? t('validations.invalid_type') : "";
        } else if (key === "reduction_direction") {
            errorText = !(value === 1 || value === -1) ? t('validations.invalid_value') : "";
        } else if (key === "reduction_value") {
            errorText = pricingStrategy.scope === "campaign" ? "" : (value < 0 ? t('validations.invalid_value') : "");
        } else if (key === "protection_strategy") {
            errorText = value === "" ? t('validations.invalid_value') : "";
        } else if (key === "is_active") {
            errorText = value !== true && value !== false ? t('validations.invalid_value') : "";
        } else if (key === "is_auto_update") {
            errorText = value !== true && value !== false ? t('validations.invalid_value') : "";
        } else if (key === "ignore_some_competitors") {
            errorText = value === "" ? t('validations.invalid_value') : "";
        }
        return errorText;
    };

    const checkErrors = async (pricingStrategy) => {

        let errors = { ...formErrors };

        Object.entries(pricingStrategy).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        return errors;
    };

    let suggestion_direction = "";
    let difference = "";
    if (selectedProduct.target_price) {
        if(selectedProduct.myPrice)
        {
            if (parseFloat(selectedProduct.myPrice) > parseFloat(selectedProduct.target_price))
                suggestion_direction = "down";
            else if (parseFloat(selectedProduct.myPrice) < parseFloat(selectedProduct.target_price))
                suggestion_direction = "up";

            difference =  formatCurrency(Math.abs(parseFloat(selectedProduct.myPrice) - parseFloat(selectedProduct.target_price)), selectedProduct.currency) ;
        }
    }

    const modalYesNo = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" onClick={sendPriceToSite} />
            <Button label={t('yes_no.no')} icon="pi pi-times" onClick={() => setShowModal(false)}/>
        </div>
    );

    const showOptimumValue = (pricingStrategy.scope === "campaign" && selectedProduct.campaign &&
        selectedProduct.campaign.pricing_strategy && selectedProduct.campaign.pricing_strategy.protection_strategy === "optimum_value")
    || (pricingStrategy.scope !== "campaign" && pricingStrategy.protection_strategy === "optimum_value")

    const selectButtonTemplate = (option) => {
        if (option.icon)
            return <><i className={option.icon}/><span className="p-button-label p-c"> {option.name}</span></>;
        else
            return <span className="p-button-label p-c">{option.name}</span>;
    };

    const ownerPriceTemplate = (rowData) => {
        return formatCurrency(rowData.owner_value, rowData.owner_currency);
    };
    const suggestedPriceTemplate = (rowData) => {

        let suggestion_direction = "";
        if (rowData.suggested_value) {
            if(rowData.owner_value)
            {
                if (parseFloat(rowData.owner_value) > parseFloat(rowData.suggested_value))
                    suggestion_direction = "down";
                else if (parseFloat(rowData.owner_value) < parseFloat(rowData.suggested_value))
                    suggestion_direction = "up";
            }
        }

        return <div className="p-col-8"
                    style={{color: suggestion_direction === "down" ? "red" : (suggestion_direction === "up" ? "green" : "")}}>
            <label>{formatCurrency(rowData.suggested_value, rowData.owner_currency)}</label>
            &nbsp;<i className={suggestion_direction === "down" ? "pi pi-arrow-down" : (suggestion_direction === "up" ? "pi pi-arrow-up" : "")}/>
        </div>;
    };
    const differenceTemplate = (rowData) => {
        return <label>{formatCurrency(rowData.difference, rowData.owner_currency)}</label>;
    };
    const competitorTemplate = (rowData) => {
        return<a target="_blank" rel="noopener noreferrer" href={rowData.competitor_product_url}>{rowData.competitor_min_name}</a>;
    };
    const competitorPriceTemplate = (rowData) => {
        return formatCurrency(rowData.competitor_min_value, rowData.owner_currency);
    };
    const createdAtTemplate = (rowData) => <span>{toDateTimeString(rowData.created_at)}</span>;

    return <div className="p-grid">



        {showHistory &&
        <Dialog visible={true} style={{ width: '50vw', height: '50vh', overflow:"auto"}} onHide={() => setShowHistory(false)} >
            <DataTable value={appliedSuggestions} responsive emptyMessage={t('actions.empty_message')}
                       sortField="created_at" sortOrder="-1">
                <Column body={IndexTemplate} style={{width:'3em'}} />
                <Column field="owner_value" body={ownerPriceTemplate} header={t('product.myprice')} />
                <Column field="suggested_value" header={t('reprice.applied_price')} body={suggestedPriceTemplate} />
                <Column field="difference" header={t('reprice.difference')} body={differenceTemplate} />
                <Column field="competitor_min_name" body={competitorTemplate} header={t('product.comp')} />
                <Column field="competitor_min_value" body={competitorPriceTemplate} header={t('product.comp_min')} />
                <Column field="created_at" header={t('reprice.applied_at')} body={createdAtTemplate} />
            </DataTable>
        </Dialog >}

        {loading ?
            <div className="p-col-12" style={{textAlign:'center'}}>
                <Loading/>
            </div>
            :
            <div className="p-grid">
                <div className="p-col-4">
                </div>
                <div className="p-col-8">
                    <SelectButton name="scope" value={pricingStrategy.scope} options={scopes} optionLabel="name" onChange={onChange} style={{float:"left"}} />
                    <Tooltip tooltip={t('reprice.product_message')} />
                </div>

                <div className="p-col-4">
                    <label htmlFor="min_value">{t('reprice.min_value')}</label>
                    <Tooltip tooltip={t('reprice.min_value_tooltip')}/>
                </div>
                <div className="p-col-8">
                    <Number id="min_value" name="min_value" value={pricingStrategy.min_value} onChange={(e) => onChange(e)} className={errorClass("min_value")}/>
                    {errorClass("min_value") && (<Message severity="error" text={formErrors.min_value}/>)}
                </div>

                <div className="p-col-4">
                    <label htmlFor="max_value">{t('reprice.max_value')}</label>
                    <Tooltip tooltip={t('reprice.max_value_tooltip')}/>
                </div>
                <div className="p-col-8">
                    <Number id="max_value" name="max_value" value={pricingStrategy.max_value} onChange={(e) => onChange(e)} className={errorClass("max_value")}/>
                    {errorClass("max_value") && (<Message severity="error" text={formErrors.max_value}/>)}
                </div>

                {pricingStrategy.scope === "product" &&
                <React.Fragment>
                    <div className="p-col-4">
                        <label>{t('reprice.protection_strategy')}</label>
                        <Tooltip tooltip={t('reprice.protection_strategy_tooltip')}/>
                    </div>
                    <div className="p-col-8">
                        <Dropdown inputId="protection_strategy" name="protection_strategy" options={protectionStrategies(t)} value={pricingStrategy.protection_strategy} onChange={onChange} />
                        {errorClass("protection_strategy") && (<Message severity="error" text={formErrors.protection_strategy}/>)}
                    </div>
                </React.Fragment>}

                {
                    showOptimumValue ?
                        <React.Fragment>
                            <div className="p-col-4">
                                <label htmlFor="optimum_value">{t('reprice.optimum_value')}</label>
                                <Tooltip tooltip={t('reprice.optimum_value_tooltip')}/>
                            </div>
                            <div className="p-col-8">
                                <Number id="optimum_value" name="optimum_value" value={pricingStrategy.optimum_value} onChange={(e) => onChange(e)} className={errorClass("optimum_value")}/>
                                {errorClass("optimum_value") && (<Message severity="error" text={formErrors.optimum_value}/>)}
                            </div>
                        </React.Fragment>
                        : null
                }


                {pricingStrategy.scope === "product" &&
                <React.Fragment>
                    <div className="p-col-4">
                        <label>{t('reprice.reduction_type')}</label>
                    </div>
                    <div className="p-col-8">
                        <SelectButton name="reduction_type" value={pricingStrategy.reduction_type} options={reductionTypes} optionLabel="name" onChange={onChange} />
                        {errorClass("reduction_type") && (<Message severity="error" text={formErrors.reduction_type}/>)}
                    </div>
                </React.Fragment>}
                {pricingStrategy.scope === "product" &&
                <React.Fragment>
                <div className="p-col-4">
                    <label>{t('reprice.reduction_direction')}</label>
                    </div>
                    <div className="p-col-8">
                        <SelectButton name="reduction_direction" value={pricingStrategy.reduction_direction} options={reductionDirections} optionLabel="name" onChange={onChange} />
                        {errorClass("reduction_direction") && (<Message severity="error" text={formErrors.reduction_direction}/>)}
                    </div>
                </React.Fragment>}
                {pricingStrategy.scope === "product" &&
                <React.Fragment>
                    <div className="p-col-4">
                        <label htmlFor="reduction_value">{t('reprice.reduction_value')}</label>
                    </div>
                    <div className="p-col-8">
                        <Number id="reduction_value" name="reduction_value" value={pricingStrategy.reduction_value} onChange={(e) => onChange(e)} className={errorClass("reduction_value")}/>
                        {errorClass("reduction_value") && (<Message severity="error" text={formErrors.reduction_value}/>)}
                    </div>
                </React.Fragment>}


                {pricingStrategy.scope === "product" &&
                <React.Fragment>
                    <div className="p-col-4">
                        <label>{t('reprice.is_active')}</label>
                    </div>
                    <div className="p-col-8">
                        <SelectButton name="is_active" value={pricingStrategy.is_active} options={statuses} optionLabel="name" onChange={onChange} />
                        {errorClass("is_active") && (<Message severity="error" text={formErrors.is_active}/>)}
                    </div>
                </React.Fragment>}
                {pricingStrategy.scope === "product" &&
                <React.Fragment>
                    <div className="p-col-4">
                        <label>{t('reprice.is_auto_update')}</label>
                        <Tooltip tooltip={t('reprice.send_price_to_site_tooltip')}/>
                    </div>
                    <div className="p-col-8">
                        <SelectButton name="is_auto_update" value={pricingStrategy.is_auto_update} options={updateTypes} optionLabel="name" onChange={onChange} />
                        {errorClass("is_auto_update") && (<Message severity="error" text={formErrors.is_auto_update}/>)}
                    </div>
                </React.Fragment>}


                <div className="p-col-4">
                    <label>{t('reprice.ignore_some_competitors')}</label>
                    <Tooltip tooltip={t('reprice.ignore_some_competitors_tooltip')}/>
                </div>
                <div className="p-col-8">
                    <SelectButton name="ignore_some_competitors" value={pricingStrategy.ignore_some_competitors} options={competitors} optionLabel="name" onChange={onChange} />
                    {errorClass("ignore_some_competitors") && (<Message severity="error" text={formErrors.ignore_some_competitors}/>)}
                </div>

                {selectedProduct.suggested_price !== null && selectedProduct.suggested_price !== undefined ?
                    <React.Fragment>


                        {selectedProduct.pricing_suggestion &&
                            <React.Fragment>
                                <div className="p-col-4">
                                    <label>{t('reprice.min_seller')}</label>
                                </div>
                                <div className="p-col-8">
                                    <label className="p-tag p-tag-warning">
                                        {selectedProduct.pricing_suggestion.competitor_min_name}&nbsp;&nbsp;->&nbsp;&nbsp;{formatCurrency(selectedProduct.pricing_suggestion.competitor_min_value, selectedProduct.currency)}
                                    </label>
                                </div>
                            </React.Fragment>

                        }

                        <div className="p-col-4">
                            <label>{t('reprice.target_price')}</label>
                        </div>
                        <div className="p-col-8">
                            <label className={suggestion_direction === "down" ? "p-tag p-tag-danger" : (suggestion_direction === "up" ? "p-tag p-tag-success" : "")}>
                                {formatCurrency(selectedProduct.target_price, selectedProduct.currency)}
                                &nbsp;<i className={suggestion_direction === "down" ? "pi pi-arrow-down" : (suggestion_direction === "up" ? "pi pi-arrow-up" : "")}/>
                                &nbsp;&nbsp;&nbsp;<label>({difference})</label>
                            </label>

                            {selectedProduct.suggested_price === selectedProduct.target_price &&
                            <Button label={t('reprice.send_price_to_site')} tooltip={t('reprice.send_price_to_site_tooltip')}
                                    icon="pi pi-globe" className="p-button-outlined p-button-info" style={{marginLeft: '0.5em'}}
                                    onClick={() => setShowModal(true)}/>
                            }
                        </div>

                        {selectedProduct.suggested_price !== selectedProduct.target_price &&
                            <React.Fragment>
                                <div className="p-col-4">
                                    <label>{t('reprice.suggested_price')}</label>
                                    {<Tooltip tooltip={t('product.suggested_price_tooltip')}/>}
                                </div>
                                <div className="p-col-8">
                                    <label className={suggestion_direction === "down" ? "p-tag p-tag-danger" : (suggestion_direction === "up" ? "p-tag p-tag-success" : "")}>
                                        {formatCurrency(selectedProduct.suggested_price, selectedProduct.currency)}
                                        &nbsp;{t('reprice.in_site_discount', {discount:((1-(selectedProduct.target_price / selectedProduct.suggested_price)) * 100).toFixed(2)})}
                                    </label>

                                    <Button label={t('reprice.send_price_to_site')} tooltip={t('reprice.send_price_to_site_tooltip')}
                                            icon="pi pi-globe" className="p-button-outlined p-button-info" style={{marginLeft: '0.5em'}}
                                            onClick={() => setShowModal(true)}/>
                                </div>
                            </React.Fragment>

                        }

                    </React.Fragment> : ""}
                <div className="p-col-4">
                </div>

                <Dialog header={t('reprice.send_price_to_site')} footer={modalYesNo} visible={showModal}
                        style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => setShowModal(false)}>
                    {t('dialogs.confirm_send_price_message')}
                </Dialog>

                <div className="p-col-8">
                    <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success p-mr-1"
                            onClick={savePricingStrategy}/>
                    <Button icon="pi pi-compass" className="p-button-outlined p-button-info" label={t('dashboard.dynamic_pricing')} onClick={(e) => getAppliedPriceSuggestions(e)} />

                </div>
            </div>
        }</div>;

};
export default ProductReprice;
