import React, {useEffect, useState} from 'react';


import {CampaignService} from "../../service/CampaignService";
import {AppContext} from "../../HomePage";
import {Checkbox} from "primereact/checkbox";
import {getKeyValue} from "../../utils/Utils";
import {Loading} from "../../utils/InlineComponents";
import {SpecialFieldService} from "../../service/SpecialFieldService";


const SpecialFieldSetting = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const campaign = props.campaign;
    const [loading, setLoading] = useState(false);
    const [specialFields, setSpecialFields] = useState(campaign.settings.special_fields ? campaign.settings.special_fields : []);

    const campaignService = new CampaignService();
    const specialFieldService = new SpecialFieldService();

    useEffect ( ()=>{
        if (campaign.id) {
            setLoading(true);
            campaignService.getCampaignSpecialFields(campaign.id).then((special_fields) => {
                campaign.settings.special_fields = special_fields
                setSpecialFields(special_fields);
                setLoading(false);
            });
        } else if (!campaign.settings.special_fields) {
            setLoading(true);
            specialFieldService.getSpecialFields().then(special_fields => {
                campaign.settings.special_fields = special_fields
                setSpecialFields(special_fields);
                setLoading(false);
            }).catch(error => {
                setError(error);
                setError(null);
                setLoading(false);
            });
        }


    },[]);

    const specialFieldsCheckBoxes = () => {
        let result = specialFields.map(item => {
            return <div className="p-col-12 p-xl-12">
                <Checkbox inputId={item['name']} name={item['name']}
                          style={{marginLeft: "0.5em"}}
                          value={item['selected']}
                          checked={item['selected']}
                          onChange={(e)=> {
                              let special_field = specialFields.filter(innerItem => innerItem.name === item.name)[0];
                              special_field.selected = getKeyValue(e).value;
                              campaign.settings.special_fields = specialFields;
                              setSpecialFields([...specialFields]);}}
                />
                <label htmlFor={item['name']} style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{item['name']}</label>
            </div>
        });
        return result
    }

    return (
        <div className="p-grid p-fluid p-shadow-3">
            {loading ?
                <div className="p-col-12" style={{textAlign: 'center'}}>
                    <Loading/>
                </div> :
                specialFields.length > 0 && specialFieldsCheckBoxes()
            }
        </div>
    );

};
export default (SpecialFieldSetting)
