import {AuthService} from "./AuthService";
import {isAdmin, isManager, userId} from "../utils/Utils";

export class AccountService {
    url_account = `accounts/`;
    url_billing_address = `billing_addresses/`;
    url_payment_method = `payment_methods/`;

    updateMyAccount(user){
        return AuthService.apiPut(this.url_account + userId() + "/", {user}).then(res => res.data.data);
    }

    changeMyPassword(old_password, new_password){
        return AuthService.apiPut(this.url_account + userId() + "/change_password/", {old_password, new_password}).then(res => res.data.data);
    }

    saveGuidingCompleted(guiding_page){
        return AuthService.apiPut(this.url_account + userId() + "/complete_guiding/", {completed_guiding_page: guiding_page}).then(res => res.data.data);
    }


    calculateDebitForPlan(planId, added_additional_feature_names=''){
        return AuthService.apiGet(this.url_account + userId() + "/debit/",{params: {pricing_plan_id: planId, added_additional_feature_names: added_additional_feature_names}}).then(res => res.data.data);
    }

    changeMyPlan(pricing_plan_id, added_additional_feature_names = ''){
        let params = {
            pricing_plan_id: pricing_plan_id,
            added_additional_feature_names: added_additional_feature_names
        };

        return AuthService.apiPost(this.url_account + userId() + "/subscribe/", params).then(res => res.data.data);
    }

    cancelMyPlan(){
        return AuthService.apiPut(this.url_account + userId() + "/cancel_subscription/").then(res => res.data.data);
    }

    renewMyPlan(){
        return AuthService.apiPost(this.url_account + userId() + "/renew_subscription/").then(res => res.data.data);
    }

    tryChargeAgain(user_id){
        return AuthService.apiPost(this.url_account + user_id + "/try_charge_again/").then(res => res.data.data);
    }

    chargeUser(user_id, amount){
        let params = {amount: amount};
        return AuthService.apiPost(this.url_account + user_id + "/charge/", params).then(res => res.data.data);
    }

    getBillingAddress(){
        return AuthService.apiGet(this.url_billing_address,{params: {user_id: userId()}}).then(res => res.data.data);
    }

    getPaymentMethod(){
        return AuthService.apiGet(this.url_payment_method,{params: {user_id: userId()}}).then(res => res.data.data);
    }

    saveBillingAddress(billing_address){
        if(billing_address.id) {
            return AuthService.apiPut(this.url_billing_address + billing_address.id + "/", billing_address).then(res => res.data.data);
        } else {
            return AuthService.apiPost(this.url_billing_address, billing_address).then(res => res.data.data);
        }
    }

    savePaymentMethod(payment_method){
        if(payment_method.id) {
            return AuthService.apiPut(this.url_payment_method + payment_method.id + "/", payment_method).then(res => res.data.data);
        } else {
            return AuthService.apiPost(this.url_payment_method, payment_method).then(res => res.data.data);
        }
    }

    impersonate(user_id){
        return AuthService.apiPut(this.url_account + user_id + "/impersonate/").then(res => {
            if(isAdmin()) {
                res.data.user.is_admin = true
            } else if (isManager()) {
                res.data.user.is_manager = true
            }
            AuthService.setUserAndToken(res);
            return res;
        });
    }

    sendMessage(user_id, message){
        let params = {message: message};
        return AuthService.apiPost(this.url_account + user_id +  "/send_message/", params).then(res => res.data.data);
    }

    listMessages(user_id){
        return AuthService.apiGet(this.url_account + user_id + "/messages/").then(res => res.data.data);
    }

    listAllMessages(){
        return AuthService.apiGet(this.url_account + "all_messages/").then(res => res.data.data);
    }

    setAllMessagesRead(){
        return AuthService.apiPost(this.url_account + userId() + "/read_messages/").then(res => res.data.data);
    }

}
