import {RadioButton} from "primereact/radiobutton";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useEffect, useRef, useState} from "react";

import ScrapeStatus from "../Product/ScrapeStatus";
import {
    exportFile,
    formValid,
    getKeyValue,
    isAdmin, isCampaignDropShipping, isCampaignManufacturer, isCampaignMarketSearch, isCampaignSeller,
    urlValidator, userId
} from "../../utils/Utils";
import {IndexTemplate} from "../../utils/InlineComponents";
import {ScraperService} from "../../service/ScraperService";
import {Message} from "primereact/message";
import {Messages} from "primereact/messages";
import {AppContext} from "../../HomePage";
import {FileUpload} from "primereact/fileupload";
import {ProductExcelImportService} from "../../service/ProductExcelImportService";
import {Loading} from "../../utils/InlineComponents";
import {ExportService} from "../../service/ExportService";
import {CampaignContext} from "../../App";
import {MultiSelect} from "primereact/multiselect";
import {job_states} from "../../utils/Constants";
import {TemplateService} from "../../service/TemplateService";

const CampaignUrl = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign, loadCampaigns} = React.useContext(CampaignContext);

    const messages = useRef(null);

    const [loadingExcelExport, setLoadingExcelExport] = useState(false);
    const [loadingProductImport, setLoadingProductImport] = useState(false);
    const [scrapingRequest, setScrapingRequest] = useState([]);
    const [partialProductUpdateScrapingRequest, setPartialProductUpdateScrapingRequest] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);

    const scraperService = new ScraperService();
    const templateService = new TemplateService();
    const productExcelImportService = new ProductExcelImportService();

    const [importData, setImportData] = useState({import_method: "url", url:""});
    const [formErrors, setFormErrors] = useState({});

    const fields = [
        {name: 'Name', code: 'name'},
        {name: 'Brand', code: 'brand'},
        {name: 'Category', code: 'category'},
        {name: 'Description', code: 'description'},
        {name: 'Reviews', code: 'reviews'},
        {name: 'Image', code: 'image'},
        {name: 'Sku', code: 'sku'},
        {name: 'Mpn', code: 'mpn'},
        {name: 'Ean', code: 'ean'},
        {name: 'Upc', code: 'upc'},
        {name: 'Barcode', code: 'barcode'},
        {name: 'Ref ID', code: 'ref_id'},
        {name: 'Other Codes', code: 'other_codes'},
    ];

    useEffect ( ()=>{
        let message=<React.Fragment> {t('campaign.import_message_1')} <br/> {t('campaign.import_message_2')} </React.Fragment>;
        messages.current.show({sticky: true, closable: false, severity: 'info', summary:'', detail: message });

        scraperService.getLastScrapingRequest(selectedCampaign.id, 'bulk_product_import').then(scrapingRequests=>{
            setScrapingRequest(scrapingRequests[0])
        }).catch(error =>{
            setError(error);
            setError(null);
        });

        scraperService.getLastScrapingRequest(selectedCampaign.id, 'products_update_partial').then(scrapingRequests=>{
            setPartialProductUpdateScrapingRequest(scrapingRequests[0])
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    },[selectedCampaign]);

    const onChange = (e) =>{
        const { key, value } = getKeyValue(e);
        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setImportData({...importData, [key] : value});
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0
    };

    const checkError = (key, value) =>{
        let errorText = "";

        if (key === "url") {
            if (value === "")
                errorText = t('validations.invalid_value');
            else
            {
                const urls = value.trim().split("||");

                for (let i = 0; i < urls.length; i++) {
                    if (urls[i] !== "") {
                        errorText = urlValidator(urls[i].trim(), t);
                        if (errorText !== "") {
                            break;
                        }
                    }
                }
            }
        }

        return errorText;
    };

    const checkErrors = async (competitor) => {

        let errors = { ...formErrors };

        Object.entries(competitor).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        return errors;
    };




    const addUrl = () => {
        checkErrors(importData).then(formErrors=> {
            if (formValid(formErrors)) {
                if(importData.import_method === "url")
                {
                    let urls = importData.url;
                    urls.split("||").forEach(url => {
                        scraperService.saveScrapingRequest({ url: url, type: 'products_create', campaign_id: selectedCampaign.id}).then(savedRequest => {
                            if (selectedCampaign.scraping_requests)
                                selectedCampaign.scraping_requests.unshift(savedRequest)
                            else{
                                let requests = [];
                                requests.unshift(savedRequest)
                                selectedCampaign.scraping_requests = requests;
                            }

                            setImportData({import_method: "url", url:""});
                            toast.current.show({severity: 'success', summary: t('actions.request_price_check'), detail: t('message_detail.successful') });
                        }).catch(error =>{
                            setError(error);
                            setError(null);
                        });
                    });

                }
                else//xml
                {
                    scraperService.saveProductImport(selectedCampaign.id, importData.url).then(data => {
                        selectedCampaign.product_imports.unshift(data);
                        setImportData({import_method: "url", url:""});
                        toast.current.show({severity: 'success', summary: t('actions.request_price_check'), detail: t('message_detail.successful') });
                    }).catch(error =>{
                        setError(error);
                        setError(null);
                    });
                }

                props.updateCampaignList(selectedCampaign);
            }
            else
                setFormErrors(formErrors);
        });
    };
    const deleteUrl = (rowData) => {

        if (rowData.import_method === "url"){
            scraperService.deleteProductImportUrl(rowData.id).then(() => {
                selectedCampaign.scraping_requests.splice(selectedCampaign.scraping_requests.findIndex(c=> c.id === rowData.id),1);
                setImportData({import_method: "url", url:""});
                toast.current.show({severity: 'success', summary: t('actions.delete_request'), detail: t('message_detail.successful') });
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        }
        else
        {
            scraperService.deleteProductImport(rowData.id).then(() => {
                selectedCampaign.product_imports.splice(selectedCampaign.product_imports.findIndex(c=> c.id === rowData.id),1);
                setImportData({import_method: "url", url:""});
                toast.current.show({severity: 'success', summary: t('actions.delete_request'), detail: t('message_detail.successful') });
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        }
        props.updateCampaignList(selectedCampaign);
    };

    const importMethodTemplate = (rowData) => t('scraping_request.from_' + rowData.import_method);
    const urlTemplate = (rowData) => <a target="_blank" rel="noopener noreferrer" href={rowData.url}>{rowData.url}</a>;
    const statusTemplate = (rowData) => {

        //row bazlı
        //toplu olanıda yap, for la çalıştır
        const newProductRequest = {
            url: rowData.url,
            type: "products_update",
            spider_name: rowData.spider_name,
            campaign_id: rowData.campaign_id};

        return rowData.import_method === "url" ?
            <ScrapeStatus type="product" scraping_request={rowData}
                          campaign_id={rowData.campaign_id}
                          product_id={null}
                          competitor_url={null}
                          newRequestCompleted={() => {loadCampaigns(true)}}
                          newRequest={newProductRequest}/> : null;
    };

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <Button icon="pi pi-trash" className="p-button-outlined p-button-danger" tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => deleteUrl(rowData)} />
        </React.Fragment>;
    };

    const getBulkImport = () => {
        let bulkImport = []
        if (selectedCampaign)
        {
            if (selectedCampaign.scraping_requests)
                selectedCampaign.scraping_requests.forEach(s => {
                    bulkImport.unshift({...s, "import_method": "url"})
                });

            if (selectedCampaign.product_imports)
                selectedCampaign.product_imports.forEach(p => {
                    bulkImport.unshift({...p, "import_method": "xml"})
                });
        }
        return bulkImport;
    };

    const importProductsFromUrls = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.importProductsFromUrls(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.import_products'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
            scraperService.getLastScrapingRequest(selectedCampaign.id, 'bulk_product_import').then(scrapingRequests=>{
                setScrapingRequest(scrapingRequests[0])
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const importProductsFromCustomFile = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.importProductsFromCustomFile(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.import_products'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const importDropShippingProducts = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.importDropShippingProducts(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.import_products'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
            scraperService.getLastScrapingRequest(selectedCampaign.id, 'bulk_product_import').then(scrapingRequests=>{
                setScrapingRequest(scrapingRequests[0])
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const importManufacturerProducts = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.importManufacturerProducts(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.import_products'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const importSellerProducts = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.importSellerProducts(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.import_products'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const importProductsWithSellers = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.importProductsWithSellers(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.upload_product_with_sellers'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const updateProductMaps = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.updateProductMaps(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.update_product_map'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const importViolationStrategySchedule = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.importViolationStrategySchedule(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.import_violation_strategy_schedule'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const importUpdateProducts = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.importUpdateProducts(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.update_product_fields'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const updateProductSellerURLs = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.updateProductSellerURLs(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.update_product_seller_urls'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const updateProductSellerURLsColumn = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.updateProductSellerURLsColumn(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.update_product_seller_urls'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const activateProducts = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.activateProducts(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.activate_products'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const deactivateProducts = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.deactivateProducts(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.deactivate_products'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const deleteProducts = ({files}) => {
        setLoadingProductImport(true);
        const [file] = files;
        productExcelImportService.deleteProducts(selectedCampaign.id, file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.products_deletion'), detail: t('message_detail.successful') });
            setLoadingProductImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingProductImport(false);
        });
    };

    const exportProductImportFromUrlsExcelTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportProductImportFromUrlsExcelTemplate().then((response) => {
            exportFile(response, "product_import_from_urls_excel_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportProductImportWithSellersExcelTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportProductImportWithSellersExcelTemplate().then((response) => {
            exportFile(response, "product_import_with_sellers_excel_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportProductSellersURLsUpdateExcelTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportProductSellersURLsUpdateExcelTemplate().then((response) => {
            exportFile(response, "product_sellers_urls_update_row_excel_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportProductSellersURLsUpdateColumnExcelTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportProductSellersURLsUpdateColumnExcelTemplate().then((response) => {
            exportFile(response, "product_sellers_urls_update_column_excel_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportDropShippingProductImportExcelTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportDropShippingProductImportExcelTemplate().then((response) => {
            exportFile(response, "drop_shipping_product_import_excel_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportSellerProductImportExcelTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportSellerProductImportExcelTemplate().then((response) => {
            exportFile(response, "seller_product_import_excel_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportProductMapUpdateTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportProductMapUpdateTemplate().then((response) => {
            exportFile(response, "product_map_updating_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportViolationStrategyScheduleTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportViolationStrategyScheduleTemplate().then((response) => {
            exportFile(response, "violation_strategy_schedule_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportUpdateProductsTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportUpdateProductsTemplate().then((response) => {
            exportFile(response, "update_products_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportProductActivationTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportProductActivationTemplate().then((response) => {
            exportFile(response, "product_activation_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportProductDeletionTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportProductDeletionTemplate().then((response) => {
            exportFile(response, "product_deletion_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const exportManufacturerProductImportExcelTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportManufacturerProductImportExcelTemplate().then((response) => {
            exportFile(response, "manufacturer_product_import_excel_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const productsUpdatePartialRequest = () => {
        return {
            spider_name: "products_update_partial",
            type: "products_update_partial",
            fields: selectedFields.map(item => item['code']),
            campaign_id: selectedCampaign.id};
    };

    const productsReviewUpdatePartialRequest = () => {
        return {
            spider_name: "products_update_review",
            type: "products_update_partial",
            fields: ["reviews"],
            campaign_id: selectedCampaign.id};
    };

    const productsFailedReviewUpdatePartialRequest = () => {
        return {
            spider_name: "products_update_failed_review",
            type: "products_update_partial",
            fields: ["reviews"],
            campaign_id: selectedCampaign.id};
    };

    return <React.Fragment>
        <h3>{t('campaign.my_urls') + ' - ' + selectedCampaign.name}</h3>

        <Messages ref={messages}/>
        <div className="p-grid" >
            <div className="p-col-12">
                <label style={{ marginRight:"0.5em"}}>{t('campaign.import_method')}</label>
                <RadioButton inputId="import_method_url" value="url" name="import_method" onChange={onChange} checked={importData.import_method === "url"}/>
                <label htmlFor="import_method_url" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('scraping_request.from_url')}</label>
                <RadioButton inputId="import_method_xml" value="xml" name="import_method" onChange={onChange} checked={importData.import_method === "xml"}/>
                <label htmlFor="import_method_xml" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('scraping_request.from_xml')}</label>
                <RadioButton inputId="import_method_excel" value="excel" name="import_method" onChange={onChange} checked={importData.import_method === "excel"}/>
                <label htmlFor="import_method_excel" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('scraping_request.from_excel')}</label>
                {isAdmin() && <React.Fragment>
                    <RadioButton inputId="partial_product_update" value="partial_product_update" name="import_method" onChange={onChange} checked={importData.import_method === "partial_product_update"}/>
                    <label htmlFor="partial_product_update" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('scraping_request.partial_product_update')}</label>
                </React.Fragment>}
            </div>

            <div className="p-grid p-col-12 p-fluid p-nogutter" >
                {(importData.import_method === "url" || importData.import_method === "xml") &&
                <React.Fragment>
                    <div className="p-col" style={{marginRight:"0.5em"}}>
                        <span className="p-float-label">
                            <InputText id="url" name="url" value={importData.url} onChange={onChange}
                                       className={errorClass("url")}/>
                            {errorClass("url") && (<Message severity="error" text={formErrors.url}/>)}
                            <label htmlFor="url">{t('campaign.link_address')}</label>
                        </span>
                    </div>
                    <div className="p-col-fixed" style={{width:"10em", marginTop:"0.5em"}}>
                        <Button label={t('actions.add')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={addUrl}/>
                    </div>
                </React.Fragment>}

                {(userId() === '59b88caf-3041-49f5-b5bd-15aff385f0de' || userId() === '6e904a56-dd36-4717-95e8-23dd5402cde4') && importData.import_method === "excel" &&
                    <React.Fragment>
                        <div className="p-grid p-col-12" style={{marginTop:"2em", marginBottom:"2em"}}>
                            <React.Fragment>
                                {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                    <FileUpload customUpload={true}
                                                mode="basic"
                                                auto={true}
                                                chooseLabel={t('actions.upload_product_from_custom_file')}
                                                uploadHandler={importProductsFromCustomFile}/>}
                            </React.Fragment>
                        </div>
                    </React.Fragment>
                }


                {importData.import_method === "excel" && isCampaignDropShipping(selectedCampaign) &&
                    <React.Fragment>
                        <div className="p-grid" style={{marginTop:"1em"}}>
                            <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_template')} onClick={exportDropShippingProductImportExcelTemplate} loading={loadingExcelExport} />
                            {(!scrapingRequest || scrapingRequest.state === job_states.done || scrapingRequest.state === job_states.failed) &&
                                <React.Fragment>
                                    {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                        <FileUpload customUpload={true}
                                                    mode="basic"
                                                    auto={true}
                                                    chooseLabel={t('actions.upload')}
                                                    uploadHandler={importDropShippingProducts}/>}
                                </React.Fragment>
                            }
                            {scrapingRequest &&
                                <span style={{marginLeft:"2em"}}>
                                <ScrapeStatus scraping_request={scrapingRequest}
                                          campaign_id={selectedCampaign.id}
                                          product_id={null}
                                          competitor_url={null}
                                          newRequest={null}
                                          newRequestCompleted={null} />
                                </span>
                            }
                        </div>
                    </React.Fragment>
                }

                {importData.import_method === "excel" && (isCampaignManufacturer(selectedCampaign) || isCampaignMarketSearch(selectedCampaign)) &&
                    <React.Fragment>
                        <div className="p-grid" style={{marginTop:"1em"}}>
                            <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_template')} onClick={exportManufacturerProductImportExcelTemplate} loading={loadingExcelExport} />
                                <React.Fragment>
                                    {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                        <FileUpload customUpload={true}
                                                    mode="basic"
                                                    auto={true}
                                                    chooseLabel={t('actions.upload_product_from_template')}
                                                    uploadHandler={importManufacturerProducts}
                                        />
                                    }
                                </React.Fragment>
                        </div>
                    </React.Fragment>
                }

                {importData.import_method === "excel" && isCampaignSeller(selectedCampaign) &&
                    <React.Fragment>
                        <div className="p-grid" style={{marginTop:"1em"}}>
                            <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_template')} onClick={exportSellerProductImportExcelTemplate} loading={loadingExcelExport} />
                              <React.Fragment>
                                    {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                        <FileUpload customUpload={true}
                                                    mode="basic"
                                                    auto={true}
                                                    chooseLabel={t('actions.upload_product_from_template')}
                                                    uploadHandler={importSellerProducts}
                                        />
                                    }
                              </React.Fragment>
                        </div>
                    </React.Fragment>
                }

                {importData.import_method === "excel" && !isCampaignDropShipping(selectedCampaign) &&
                    <React.Fragment>
                        <div className="p-grid p-col-12" style={{marginTop:"1em"}}>
                            <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_import_product_from_url_template')} onClick={exportProductImportFromUrlsExcelTemplate} loading={loadingExcelExport} />
                            {/*{(!scrapingRequest || scrapingRequest.state === job_states.done || scrapingRequest.state === job_states.failed) &&*/}
                            <React.Fragment>
                                {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                    <FileUpload customUpload={true}
                                                mode="basic"
                                                auto={true}
                                                chooseLabel={t('actions.upload_product_from_url')}
                                                uploadHandler={importProductsFromUrls}/>}
                            </React.Fragment>
                            {/*}*/}
                            {scrapingRequest &&
                            <span style={{marginLeft:"2em"}}>
                                    <ScrapeStatus scraping_request={scrapingRequest}
                                                  campaign_id={selectedCampaign.id}
                                                  product_id={null}
                                                  competitor_url={null}
                                                  newRequest={null}
                                                  newRequestCompleted={null} />
                                    </span>
                            }
                        </div>
                    </React.Fragment>
                }

                {isAdmin() && importData.import_method === "excel" && !isCampaignDropShipping(selectedCampaign) &&
                    <React.Fragment>
                        <div className="p-grid p-col-12" style={{marginTop:"1em"}}>
                            <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_import_product_with_sellers_template')} onClick={exportProductImportWithSellersExcelTemplate} loading={loadingExcelExport} />
                            <React.Fragment>
                                {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                    <FileUpload customUpload={true}
                                                mode="basic"
                                                auto={true}
                                                chooseLabel={t('actions.upload_product_with_sellers')}
                                                uploadHandler={importProductsWithSellers}/>}
                            </React.Fragment>
                        </div>
                    </React.Fragment>
                }

                {isAdmin() && importData.import_method === "excel" && !isCampaignDropShipping(selectedCampaign) &&
                <React.Fragment>
                    <div className="p-grid p-col-12" style={{marginTop:"1em"}}>
                        <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_update_sellers_urls_of_products_template')} onClick={exportProductSellersURLsUpdateExcelTemplate} loading={loadingExcelExport} />
                        <React.Fragment>
                            {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                <FileUpload customUpload={true}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel={t('actions.update_sellers_urls_of_products')}
                                            uploadHandler={updateProductSellerURLs}/>}
                        </React.Fragment>
                    </div>
                </React.Fragment>
                }

                {isAdmin() && importData.import_method === "excel" && !isCampaignDropShipping(selectedCampaign) &&
                <React.Fragment>
                    <div className="p-grid p-col-12" style={{marginTop:"1em"}}>
                        <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_update_sellers_urls_of_products_column_template')} onClick={exportProductSellersURLsUpdateColumnExcelTemplate} loading={loadingExcelExport} />
                        <React.Fragment>
                            {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                <FileUpload customUpload={true}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel={t('actions.update_sellers_urls_of_products_column')}
                                            uploadHandler={updateProductSellerURLsColumn}/>}
                        </React.Fragment>
                    </div>
                </React.Fragment>
                }

                {importData.import_method === "excel" &&
                <React.Fragment>
                    <div className="p-grid p-col-12" style={{marginTop:"1em"}}>
                        <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_product_map_update_template')} onClick={exportProductMapUpdateTemplate} loading={loadingExcelExport} />
                        <React.Fragment>
                            {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                <FileUpload customUpload={true}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel={t('actions.update_product_map')}
                                            uploadHandler={updateProductMaps}/>}
                        </React.Fragment>
                    </div>
                </React.Fragment>
                }

                {importData.import_method === "excel" && isCampaignManufacturer(selectedCampaign) &&
                <React.Fragment>
                    <div className="p-grid p-col-12" style={{marginTop:"1em"}}>
                        <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_violation_strategy_schedule_template')} onClick={exportViolationStrategyScheduleTemplate} loading={loadingExcelExport} />
                        <React.Fragment>
                            {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                <FileUpload customUpload={true}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel={t('actions.import_violation_strategy_schedule')}
                                            uploadHandler={importViolationStrategySchedule}/>}
                        </React.Fragment>
                    </div>
                </React.Fragment>
                }

                {importData.import_method === "excel" &&
                <React.Fragment>
                    <div className="p-grid p-col-12" style={{marginTop:"1em"}}>
                        <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_update_products_template')} onClick={exportUpdateProductsTemplate} loading={loadingExcelExport} />
                        <React.Fragment>
                            {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                <FileUpload customUpload={true}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel={t('actions.update_product_fields')}
                                            uploadHandler={importUpdateProducts}/>}
                        </React.Fragment>
                    </div>
                </React.Fragment>
                }

                {importData.import_method === "excel" &&
                <React.Fragment>
                    <div className="p-grid p-col-12" style={{marginTop:"1em"}}>
                        <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_product_activation_template')} onClick={exportProductActivationTemplate} loading={loadingExcelExport} />
                        <div className="p-mr-1">
                            {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                <FileUpload customUpload={true}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel={t('actions.activate')}
                                            uploadHandler={activateProducts}/>}
                        </div>
                        <div>
                            {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                <FileUpload customUpload={true}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel={t('actions.deactivate')}
                                            uploadHandler={deactivateProducts}/>}
                        </div>
                    </div>
                </React.Fragment>
                }

                {importData.import_method === "excel" &&
                <React.Fragment>
                    <div className="p-grid p-col-12" style={{marginTop:"1em"}}>
                        <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-danger" label={t('actions.download_product_deletion_template')} onClick={exportProductDeletionTemplate} loading={loadingExcelExport} />
                        <React.Fragment>
                            {loadingProductImport ? <span> <Loading width="25px" height="25px"/> </span> :
                                <FileUpload customUpload={true}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel={t('actions.delete_products')}
                                            uploadHandler={deleteProducts}/>}
                        </React.Fragment>
                    </div>
                </React.Fragment>
                }

                {isAdmin() && importData.import_method === "partial_product_update" &&
                <React.Fragment>
                    <div className="p-col-6 p-mt-1">
                        <MultiSelect style={{marginRight: "1em"}} value={selectedFields} options={fields} onChange={(e) => setSelectedFields(e.value)} optionLabel="name" placeholder="Select a Field" />
                    </div>
                    <div className="p-col-6  p-mt-1">
                        <React.Fragment>
                            <ScrapeStatus type="product" scraping_request={partialProductUpdateScrapingRequest}
                                          text="Update Product Fields"
                                          campaign_id={selectedCampaign.id}
                                          product_id={null}
                                          competitor_url={null}
                                          newRequest={productsUpdatePartialRequest()}
                                          newRequestCompleted={null}/>
                        </React.Fragment>
                    </div>
                    <div className="p-col-6 p-mt-1">
                    </div>
                    <div className="p-col-6 p-mt-1">
                        <ScrapeStatus type="reviews"
                                      text="Get Product Reviews"
                                      campaign_id={selectedCampaign.id}
                                      product_id={null}
                                      competitor_url={null}
                                      newRequest={productsReviewUpdatePartialRequest()}
                                      newRequestCompleted={null}/>
                    </div>
                    <div className="p-col-6 p-mt-1">
                    </div>
                    <div className="p-col-6 p-mt-1">
                        <ScrapeStatus type="failed_reviews"
                                      text="Get Product Failed Reviews"
                                      campaign_id={selectedCampaign.id}
                                      product_id={null}
                                      competitor_url={null}
                                      newRequest={productsFailedReviewUpdatePartialRequest()}
                                      newRequestCompleted={null}/>
                    </div>
                </React.Fragment>
                }

            </div>
            {(importData.import_method === "url" || importData.import_method === "xml") &&
                <div className="p-col-12">
                    <DataTable value={getBulkImport()} responsive={true} sortField="url" sortOrder="1">
                        <Column header={t('table_headers.index')} style={{width: '5em'}} body={IndexTemplate}/>
                        <Column body={importMethodTemplate} header={t('campaign.import_method')} style={{width: '9em'}}/>
                        <Column body={urlTemplate} field="url" header={t('campaign.link_address')}/>
                        <Column body={statusTemplate} header={t('campaign.last_import_status')} style={{width: '10.5em'}}/>
                        <Column body={actionTemplate} header={t('table_headers.action')} style={{textAlign: 'center', width: '4em'}}/>
                    </DataTable>
                </div>
            }

        </div>
    </React.Fragment>;
};
export default CampaignUrl
