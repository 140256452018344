import {AuthService} from "./AuthService";
import {isAdmin} from "../utils/Utils";
import HeadlessBrowser from "../components/Admin/HeadlessBrowser";

export class HeadlessBrowserService {

    url = `headless_browsers/`;

    getHeadlessBrowsers(){
        return AuthService.apiGet(this.url,{
        }).then(res => res.data.data);
    }

    addHeadlessBrowser(headlessBrowser){
        return AuthService.apiPost(this.url, headlessBrowser).then(res => res.data.data);
    }

    updateHeadlessBrowser(headlessBrowser){
        return AuthService.apiPut(this.url + headlessBrowser.id + '/', headlessBrowser).then(res => res.data.data);
    }

    deleteHeadlessBrowser(id){
        return AuthService.apiDelete(this.url  + id + '/').then(res => res.data.data);
    }

    activeOrDeactivate(id, is_active){
        if (is_active)
            return AuthService.apiPut(this.url + id+'/deactivate/').then(res => res.data.message);
        else
            return AuthService.apiPut(this.url + id+'/activate/').then(res => res.data.message);
    }
}
