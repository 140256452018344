import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";

import {LanguageService} from "../../service/LanguageService";
import {emailValidator, formValid, getKeyValue} from "../../utils/Utils";
import {getTimeZoneDisplay} from "../../utils/TimeUtil";




import moment from "moment";
import "moment-timezone";

import {Dialog} from "primereact/dialog";
import {Message} from "primereact/message";
import {UserService} from "../../service/UserService";
import {Checkbox} from "primereact/checkbox";
import {TabPanel, TabView} from "primereact/tabview";
import {InputTextarea} from "primereact/inputtextarea";
import {MultiSelect} from "primereact/multiselect";
import {product_codes, tagList, usage_types} from "../../utils/Constants";
import {AppContext} from "../../HomePage";
import {Calendar} from "primereact/calendar";
import JobSchedulingSetting from "./JobSchedulingSetting";


const UpdateUser = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const [user, setUser] = useState({...props.selectedUser});
    const [variantImport, setVariantImport] = useState(user.settings.variant_import !== null &&  user.settings.variant_import !== undefined ? user.settings.variant_import : false);
    const [useStorePrice, setUseStorePrice] = useState(user.settings.use_store_price !== null &&  user.settings.use_store_price !== undefined ? user.settings.use_store_price : false);
    const [allowCurrencyEntry, setAllowCurrencyEntry] = useState(user.settings.allow_currency_entry !== null &&  user.settings.allow_currency_entry !== undefined ? user.settings.allow_currency_entry : false);
    const [newSellerAutomaticallyActivated, setNewSellerAutomaticallyActivated] = useState(user.settings.new_seller_automatically_activated !== null &&  user.settings.new_seller_automatically_activated !== undefined ? user.settings.new_seller_automatically_activated : true);
    const [sellerCode, setSellerCode] = useState(user.settings.seller_code ? user.settings.seller_code : null);
    const [decimalSeparator, setDecimalSeparator] = useState(user.settings.decimal_separator ? user.settings.decimal_separator : '.');
    const [defaultProductCode, setDefaultProductCode] = useState(user.settings.default_product_code ? user.settings.default_product_code : null);
    const [violationEmail, setViolationEmail] = useState(user.settings.violation_email ? user.settings.violation_email : null);
    const [formErrors, setFormErrors] = useState({});

    const [disabled, setDisabled] = useState(false);

    const languages = LanguageService.getLanguages();
    const timezones = moment.tz.names();
    const [timezoneSuggestions, setTimezoneSuggestions] = useState(null);


    const [activeIndex, setActiveIndex] = useState(0);
    const [allUsers, setAllUsers] = useState([]);

    const userService = new UserService();

    useEffect(() => {
        if (!user.default_campaign || !user.default_campaign.settings) {
            user.default_campaign = {settings: {scraping_jobs:[]}}
        }
        userService.getAllUsers().then(allUsers=>{
            setAllUsers(allUsers.map(user => ({label: user.email, value: user.id})));
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    }, []);

    const suggestTimezones = (event) => {
        if (timezones !== null)
        {
            let results = timezones.filter(timezone => {
                return getTimeZoneDisplay(timezone).toLowerCase().includes(event.query.toString().toLowerCase());
            });
            setTimezoneSuggestions(results);
        }
    };

    const save = () => {

        if (disabled) {
            return;
        }
        setDisabled(true);

        checkErrors(user).then(formErrors=> {
            if (formValid(formErrors)) {
                userService.updateUser(user).then(user_updated=>{
                    setDisabled(false);
                    props.userUpdateCompleted(user);
                }).catch(error =>{
                    setError(error);
                    setError(null);
                    setDisabled(false);
                });
            }
            else
                setFormErrors(formErrors);
        });
    };

    const timezoneTemplate = (e) => {
        return getTimeZoneDisplay(e);
    };

    const languageTemplate = (e) => {
        return e.label;
    };

    const onChange = (e, a) => {

        const { key, value } = getKeyValue(e);

        setFormErrors({...formErrors, [key]: checkError(key, value)});

        if (a) {
            a[key] = value;
            setUser({...user});
        }
        else
            setUser({...user, [key] : value});
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0 ? "p-error" : null
    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "first_name") {
            errorText =  value.length > 0 ? "" : t('validations.invalid_value');
        }
        else if (key === "last_name") {
            errorText =  value.length > 0 ? "" : t('validations.invalid_value');
        }
        else if (key === "email") {
            errorText =  value.length > 0 ? emailValidator(value, t) : t('validations.invalid_email');
        }
        else if (key === "language") {
            errorText =  value.length > 0 ? "" : t('validations.invalid_value');
        }
        else if (key === "timezone") {
            errorText =  value.length > 0 ? "" : t('validations.invalid_value');
        }
        // else if (key === "country") {
        //     errorText =  value.length > 0 ? "" : t('validations.invalid_value');
        // }
        // else if (key === "currency") {
        //     errorText =  value.length > 0 ? "" : t('validations.invalid_value');
        // }
        else if (key === "manufacturer") {
            errorText =  value === true || value === false ? "" : t('validations.invalid_value');
        }

        return errorText;
    }

    const checkErrors = async (user) => {

        let errors = { ...formErrors };

        Object.entries(user).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        return errors;
    };


    const editModalButtons = (
        <div>
            <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => props.hideDialog()} />
            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save} />
        </div>
    );

    return (
        <Dialog header={t('user.update_user')}  footer={editModalButtons} visible={true} style={{maxWidth: '80vw', minWidth: '40vw'}} modal={true} onHide={() => props.hideDialog()}>


            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel  header={t('user.administration')}>
                    <div className="p-grid alignCenter p-fluid">
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <MultiSelect id="tags" name="tags" value={user.tags} options={tagList} onChange={onChange}/>
                                <label htmlFor="tags">{t('user.tags')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputTextarea id="note" name="note" value={user.note} autoComplete="off" onChange={onChange} className={errorClass("note")} rows={5} cols={30}/>
                                {errorClass("note") && (<Message severity="error" text={formErrors.note}/>)}
                                <label htmlFor="note">{t('user.note')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">

                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel  header={t('user.settings.settings')}>
                    <div className="p-grid alignCenter p-fluid">
                        <div className="p-col-12 p-xl-12">
                            <Checkbox inputId="variantImport" name="variantImport"
                                      style={{marginLeft: "0.5em"}}
                                      value={variantImport}
                                      checked={variantImport}
                                      onChange={(e)=> {
                                          user.settings.variant_import = getKeyValue(e).value;
                                          setVariantImport(getKeyValue(e).value);}}
                            />
                            <label htmlFor="variantImport" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.variant_import')}</label>
                        </div>
                        <div className="p-col-12 p-xl-12">
                            <Checkbox inputId="use_store_price" name="use_store_price"
                                      style={{marginLeft: "0.5em"}}
                                      value={useStorePrice}
                                      checked={useStorePrice}
                                      onChange={(e)=> {
                                          user.settings.use_store_price = getKeyValue(e).value;
                                          setUseStorePrice(getKeyValue(e).value);}}
                            />
                            <label htmlFor="use_store_price" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('reprice.sale_value')}</label>
                        </div>
                        <div className="p-col-12 p-xl-12">
                            <Checkbox inputId="new_seller_automatically_activated" name="new_seller_automatically_activated"
                                      style={{marginLeft: "0.5em"}}
                                      value={newSellerAutomaticallyActivated}
                                      checked={newSellerAutomaticallyActivated}
                                      onChange={(e)=> {
                                          user.settings.new_seller_automatically_activated = getKeyValue(e).value;
                                          setNewSellerAutomaticallyActivated(getKeyValue(e).value);}}
                            />
                            <label htmlFor="new_seller_automatically_activated" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('campaign.new_seller_automatically_activated')}</label>
                        </div>
                        <div className="p-col-12 p-xl-12">
                            <Checkbox inputId="allow_currency_entry" name="allow_currency_entry"
                                      style={{marginLeft: "0.5em"}}
                                      value={allowCurrencyEntry}
                                      checked={allowCurrencyEntry}
                                      onChange={(e)=> {
                                          user.settings.allow_currency_entry = getKeyValue(e).value;
                                          setAllowCurrencyEntry(getKeyValue(e).value);}}
                            />
                            <label htmlFor="allow_currency_entry" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.settings.allow_currency_entry')}</label>
                        </div>
                        <div className="p-col-12 p-xl-12">
                            <span className="p-float-label">
                                <InputText id="seller_code" name="seller_code" value={sellerCode} onChange={(e)=>{user.settings.seller_code = e.target.value; setSellerCode(e.target.value);}}/>
                                <label htmlFor="seller_code">{t('campaign.seller_code')}</label>
                            </span>
                        </div>
                        <div className="p-col-12 p-xl-12">
                            <span className="p-float-label">
                                <InputText id="violation_email" name="violation_email" value={violationEmail} onChange={(e)=>{user.settings.violation_email = e.target.value; setViolationEmail(e.target.value);}}/>
                                <label htmlFor="violation_email">{t('user.violation_email')}</label>
                            </span>
                        </div>
                        <div className="p-col-12 p-xl-12">
                            <span className="p-float-label">
                                <InputText id="decimal_separator" name="decimal_separator" value={decimalSeparator} onChange={(e)=>{user.settings.decimal_separator = e.target.value; setDecimalSeparator(e.target.value);}}/>
                                <label htmlFor="decimal_separator">{t('campaign.decimal_separator')}</label>
                            </span>
                        </div>
                        <div className="p-col-12 p-xl-12">
                            <span className="p-float-label">
                                <Dropdown name="default_product_code" value={defaultProductCode} options={product_codes(t)} onChange={(e)=>{user.settings.default_product_code = getKeyValue(e).value; setDefaultProductCode(getKeyValue(e).value);}}/>
                                <label htmlFor="default_product_code">{t('user.default_product_code')}</label>
                            </span>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header={t('user.user')}>
                    <div className="p-grid alignCenter p-fluid">
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <InputText id="first_name" name="first_name" value={user.first_name} onChange={onChange} className={errorClass("first_name")}/>
                                {errorClass("first_name") && (<Message severity="error" text={formErrors.first_name}/>)}
                                <label htmlFor="first_name">{t('user.first_name')}</label>
                            </span>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <InputText id="last_name" name="last_name" value={user.last_name} onChange={onChange} className={errorClass("last_name")}/>
                                {errorClass("last_name") && (<Message severity="error" text={formErrors.last_name}/>)}
                                <label htmlFor="last_name">{t('user.last_name')}</label>
                            </span>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <InputText id="email" name="email" value={user.email} autoComplete="off" onChange={onChange} className={errorClass("email")}/>
                                {errorClass("email") && (<Message severity="error" text={formErrors.email}/>)}
                                <label htmlFor="email">{t('user.email')}</label>
                            </span>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <Dropdown id="usage_type" name="usage_type" value={user.usage_type} options={usage_types(t)} onChange={onChange} />
                                <label htmlFor="usage_type">{t('campaign.usage_type')}</label>
                            </span>
                        </div>



                        <div className="p-col-6">
                            <span className="p-float-label">
                                <Dropdown id="language" name="language" value={user.language} options={languages} onChange={onChange} itemTemplate={languageTemplate} style={{width:'100%'}} className={errorClass("language")}/>
                                <label htmlFor="language">{t('user.language')}</label>
                                {errorClass("language") && (<Message severity="error" text={formErrors.language}/>)}
                            </span>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <AutoComplete id="timezone" name="timezone" field="label" dropdown={true} value={user.timezone} suggestions={timezoneSuggestions} onChange={onChange} completeMethod={suggestTimezones.bind(this)} itemTemplate={timezoneTemplate} className={errorClass("timezone")}/>
                                {errorClass("timezone") && (<Message severity="error" text={formErrors.timezone}/>)}
                                <label htmlFor="timezone">{t('user.timezone')}</label>
                            </span>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <InputText id="country" name="country" value={user.country} autoComplete="off" onChange={onChange} className={errorClass("country")}/>
                                {errorClass("country") && (<Message severity="error" text={formErrors.country}/>)}
                                <label htmlFor="country">{t('user.country')}</label>
                            </span>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <InputText id="currency" name="currency" value={user.currency} autoComplete="off" onChange={onChange} className={errorClass("currency")}/>
                                {errorClass("currency") && (<Message severity="error" text={formErrors.currency}/>)}
                                <label htmlFor="currency">{t('product.currency')}</label>
                            </span>
                        </div>

                        <div className="p-col-12">
                            <Message severity="info" text={"Payment"}/>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <InputText id="payment_cycle" name="payment_cycle" value={user.payment_cycle} autoComplete="off" onChange={onChange}/>
                                <label htmlFor="payment_cycle">{t('user.payment_cycle')}</label>
                            </span>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <Calendar id="next_payment_date" name="next_payment_date" value={user.next_payment_date ? new Date(user.next_payment_date) : null} onChange={onChange} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true}/>
                                <label htmlFor="next_payment_date">{t('user.next_payment_date')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="balance" name="balance" value={user.balance} autoComplete="off" onChange={onChange} className={errorClass("balance")}/>
                                {errorClass("balance") && (<Message severity="error" text={formErrors.balance}/>)}
                                <label htmlFor="balance">{t('user.balance')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <Message severity="info" text={"Referrer"}/>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <Dropdown id="referred_by" name="referred_by" filter showClear value={user.referred_by} options={allUsers} onChange={onChange} />
                                <label htmlFor="referred_by">{t('user.referred_by')}</label>
                            </span>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <InputText id="referring_rate" name="referring_rate" value={user.referring_rate} autoComplete="off" onChange={onChange}/>
                                <label htmlFor="referring_rate">{t('user.referring_rate')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <Message severity="info" text={"Management"}/>
                        </div>
                        <div className="p-col-6">
                            <span className="p-float-label">
                                <Dropdown id="managed_by" name="managed_by" filter showClear value={user.managed_by} options={allUsers} onChange={onChange} />
                                <label htmlFor="managed_by">{t('user.managed_by')}</label>
                            </span>
                        </div>
                        <div className="p-col-6">
                            <Checkbox inputId="is_manager" name="is_manager"
                                      className="p-mt-3"
                                      value={user.is_manager}
                                      checked={user.is_manager}
                                      onChange={onChange}
                            />
                            <label htmlFor="is_manager" className="p-ml-1 p-mr-1">{t('user.is_manager')}</label>
                        </div>

                    </div>
                </TabPanel>
                <TabPanel header={t('user.api.api_definition')}>
                    <div className="p-grid alignCenter p-fluid">
                        <div className="p-col-12">
                            <Checkbox inputId="integration_hepsiburada" name="hepsiburada" value={{}} checked={user.api_integration.hepsiburada} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, hepsiburada:{merchant_id:"", username:"", password:"" }};
                                else
                                    delete user.api_integration.hepsiburada;
                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_hepsiburada" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.hepsiburada')}</label>
                        </div>
                        {user.api_integration && user.api_integration.hepsiburada &&
                        <React.Fragment>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="merchant_id" name="merchant_id" value={user.api_integration.hepsiburada.merchant_id} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.hepsiburada)}}/>
                                <label htmlFor="merchant_id">{t('user.api.merchant_id')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="username" name="username" value={user.api_integration.hepsiburada.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.hepsiburada)}}/>
                                <label htmlFor="username">{t('user.api.user_name')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="password" name="password" value={user.api_integration.hepsiburada.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.hepsiburada)}}/>
                                <label htmlFor="password">{t('user.api.password')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputTextarea id="price_code_map" name="price_code_map" value={user.api_integration.hepsiburada.price_code_map} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.hepsiburada)}} rows={5} cols={30}/>
                                <label htmlFor="price_code_map">{t('user.api.price_code_map')}</label>
                            </span>
                            </div>
                        </React.Fragment>
                        }
                        <div className="p-col-12">
                            <Checkbox inputId="integration_trendyol" name="trendyol" value={{}} checked={user.api_integration.trendyol} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, trendyol:{merchant_id:"", username:"", password:"" }};
                                else
                                    delete user.api_integration.trendyol;
                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_trendyol" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.trendyol')}</label>
                        </div>
                        {user.api_integration && user.api_integration.trendyol &&
                        <React.Fragment>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="merchant_id" name="merchant_id" value={user.api_integration.trendyol.merchant_id} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.trendyol)}}/>
                                <label htmlFor="merchant_id">{t('user.api.merchant_id')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="username" name="username" value={user.api_integration.trendyol.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.trendyol)}}/>
                                <label htmlFor="username">{t('user.api.user_name')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="password" name="password" value={user.api_integration.trendyol.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.trendyol)}}/>
                                <label htmlFor="password">{t('user.api.password')}</label>
                            </span>
                            </div>
                        </React.Fragment>
                        }
                        <div className="p-col-12">
                            <Checkbox inputId="integration_tsoft" name="tsoft" value={{}} checked={user.api_integration.tsoft} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, tsoft:{url:"", username:"", password:"", price_code_map:"" }};
                                else
                                    delete user.api_integration.tsoft;

                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_tsoft" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.tsoft')}</label>
                        </div>
                        {user.api_integration && user.api_integration.tsoft &&
                        <React.Fragment>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="url" name="url" value={user.api_integration.tsoft.url} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.tsoft)}}/>
                                <label htmlFor="url">{t('user.api.url')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="username" name="username" value={user.api_integration.tsoft.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.tsoft)}}/>
                                <label htmlFor="username">{t('user.api.user_name')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="password" name="password" value={user.api_integration.tsoft.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.tsoft)}}/>
                                <label htmlFor="password">{t('user.api.password')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputTextarea id="price_code_map" name="price_code_map" value={user.api_integration.tsoft.price_code_map} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.tsoft)}} rows={5} cols={30}/>
                                <label htmlFor="price_code_map">{t('user.api.price_code_map')}</label>
                            </span>
                            </div>
                        </React.Fragment>
                        }
                        <div className="p-col-12">
                            <Checkbox inputId="integration_ticimax" name="ticimax" value={{}} checked={user.api_integration.ticimax} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, ticimax:{url:"", uye_kodu:""}};
                                else
                                    delete user.api_integration.ticimax;

                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_ticimax" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.ticimax')}</label>
                        </div>
                        {user.api_integration && user.api_integration.ticimax &&
                        <React.Fragment>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="url" name="url" value={user.api_integration.ticimax.url} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.ticimax)}}/>
                                <label htmlFor="url">{t('user.api.url')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="uye_kodu" name="uye_kodu" value={user.api_integration.ticimax.uye_kodu} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.ticimax)}}/>
                                <label htmlFor="uye_kodu">{t('user.api.uye_kodu')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputTextarea id="price_code_map" name="price_code_map" value={user.api_integration.ticimax.price_code_map} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.ticimax)}} rows={5} cols={30}/>
                                <label htmlFor="price_code_map">{t('user.api.price_code_map')}</label>
                            </span>
                            </div>
                        </React.Fragment>
                        }

                        <div className="p-col-12">
                            <Checkbox inputId="integration_entegra" name="entegra" value={{}} checked={user.api_integration.entegra} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, entegra:{url:"", username:"", password:"", price_code_map:"" }};
                                else
                                    delete user.api_integration.entegra;

                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_entegra" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.entegra')}</label>
                        </div>
                        {user.api_integration && user.api_integration.entegra &&
                            <React.Fragment>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="url" name="url" value={user.api_integration.entegra.url} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.entegra)}}/>
                                        <label htmlFor="url">{t('user.api.url')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="username" name="username" value={user.api_integration.entegra.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.entegra)}}/>
                                        <label htmlFor="username">{t('user.api.user_name')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="password" name="password" value={user.api_integration.entegra.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.entegra)}}/>
                                        <label htmlFor="password">{t('user.api.password')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputTextarea id="price_code_map" name="price_code_map" value={user.api_integration.entegra.price_code_map} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.entegra)}} rows={5} cols={30}/>
                                        <label htmlFor="price_code_map">{t('user.api.price_code_map')}</label>
                                    </span>
                                </div>
                            </React.Fragment>
                        }

                        <div className="p-col-12">
                            <Checkbox inputId="integration_coddepo" name="coddepo" value={{}} checked={user.api_integration.coddepo} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, coddepo:{url:"", username:"", password:"", price_code_map:"" }};
                                else
                                    delete user.api_integration.coddepo;

                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_coddepo" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.coddepo')}</label>
                        </div>
                        {user.api_integration && user.api_integration.coddepo &&
                            <React.Fragment>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="url" name="url" value={user.api_integration.coddepo.url} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.coddepo)}}/>
                                        <label htmlFor="url">{t('user.api.url')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="username" name="username" value={user.api_integration.coddepo.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.coddepo)}}/>
                                        <label htmlFor="username">{t('user.api.user_name')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="password" name="password" value={user.api_integration.coddepo.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.coddepo)}}/>
                                        <label htmlFor="password">{t('user.api.password')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputTextarea id="price_code_map" name="price_code_map" value={user.api_integration.coddepo.price_code_map} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.coddepo)}} rows={5} cols={30}/>
                                        <label htmlFor="price_code_map">{t('user.api.price_code_map')}</label>
                                    </span>
                                </div>
                            </React.Fragment>
                        }
                        <div className="p-col-12">
                            <Checkbox inputId="integration_ideasoft" name="ideasoft" value={{}} checked={user.api_integration.ideasoft} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, ideasoft:{url:"", username:"", password:"", price_code_map:"" }};
                                else
                                    delete user.api_integration.ideasoft;

                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_ideasoft" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.ideasoft')}</label>
                        </div>
                        {user.api_integration && user.api_integration.ideasoft &&
                            <React.Fragment>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="url" name="url" value={user.api_integration.ideasoft.url} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.ideasoft)}}/>
                                        <label htmlFor="url">{t('user.api.url')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="username" name="username" value={user.api_integration.ideasoft.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.ideasoft)}}/>
                                        <label htmlFor="username">{t('user.api.user_name')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="password" name="password" value={user.api_integration.ideasoft.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.ideasoft)}}/>
                                        <label htmlFor="password">{t('user.api.password')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputTextarea id="price_code_map" name="price_code_map" value={user.api_integration.ideasoft.price_code_map} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.ideasoft)}} rows={5} cols={30}/>
                                        <label htmlFor="price_code_map">{t('user.api.price_code_map')}</label>
                                    </span>
                                </div>
                            </React.Fragment>
                        }

                        <div className="p-col-12">
                            <Checkbox inputId="integration_shopify" name="shopify" value={{}} checked={user.api_integration.shopify} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, shopify:{store_name:"", username:"", password:"", api_version:""}};
                                else
                                    delete user.api_integration.shopify;
                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_shopify" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.shopify')}</label>
                        </div>
                        {user.api_integration && user.api_integration.shopify &&
                        <React.Fragment>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="store_name" name="store_name" value={user.api_integration.shopify.store_name} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.shopify)}}/>
                                <label htmlFor="store_name">{t('user.api.store_name')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="username" name="username" value={user.api_integration.shopify.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.shopify)}}/>
                                <label htmlFor="username">{t('user.api.user_name')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="password" name="password" value={user.api_integration.shopify.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.shopify)}}/>
                                <label htmlFor="password">{t('user.api.password')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="api_version" name="api_version" value={user.api_integration.shopify.api_version} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.shopify)}}/>
                                <label htmlFor="api_version">{t('user.api.api_version')}</label>
                            </span>
                            </div>
                        </React.Fragment>
                        }
                        <div className="p-col-12">
                            <Checkbox inputId="integration_woocommerce" name="woocommerce" value={{}} checked={user.api_integration.woocommerce} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, woocommerce:{url:"", username:"", password:"" }};
                                else
                                    delete user.api_integration.woocommerce;
                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_woocommerce" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.woocommerce')}</label>
                        </div>
                        {user.api_integration && user.api_integration.woocommerce &&
                        <React.Fragment>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="url" name="url" value={user.api_integration.woocommerce.url} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.woocommerce)}}/>
                                <label htmlFor="url">{t('user.api.url')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="username" name="username" value={user.api_integration.woocommerce.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.woocommerce)}}/>
                                <label htmlFor="username">{t('user.api.user_name')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="password" name="password" value={user.api_integration.woocommerce.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.woocommerce)}}/>
                                <label htmlFor="password">{t('user.api.password')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="api_version" name="api_version" value={user.api_integration.woocommerce.api_version} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.woocommerce)}}/>
                                <label htmlFor="api_version">{t('user.api.api_version')}</label>
                            </span>
                            </div>
                            <div className="p-col-12">
                            <span className="p-float-label">
                                <InputTextarea id="price_code_map" name="price_code_map" value={user.api_integration.woocommerce.price_code_map} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.woocommerce)}} rows={5} cols={30}/>
                                <label htmlFor="price_code_map">{t('user.api.price_code_map')}</label>
                            </span>
                            </div>
                        </React.Fragment>
                        }
                        <div className="p-col-12">
                            <Checkbox inputId="integration_aaro" name="aaro" value={{}} checked={user.api_integration.aaro} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, aaro:{username:"", password:"" }};
                                else
                                    delete user.api_integration.aaro;
                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_aaro" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.aaro')}</label>
                        </div>
                        {user.api_integration && user.api_integration.aaro &&
                            <React.Fragment>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="username" name="username" value={user.api_integration.aaro.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.aaro)}}/>
                                        <label htmlFor="username">{t('user.api.user_name')}</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText id="password" name="password" value={user.api_integration.aaro.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.aaro)}}/>
                                        <label htmlFor="password">{t('user.api.password')}</label>
                                    </span>
                                </div>
                            </React.Fragment>
                        }

                        <div className="p-col-12">
                            <Checkbox inputId="integration_maropost" name="maropost" value={{}} checked={user.api_integration.maropost} onChange={(e) => {
                                if (getKeyValue(e).value)
                                    user.api_integration = {...user.api_integration, maropost:{url:"", username:"", password:"" }};
                                else
                                    delete user.api_integration.maropost;
                                setUser({...user});
                            }}/>
                            <label htmlFor="integration_maropost" style={{marginLeft:"0.5em", marginRight:"0.5em"}}>{t('user.api.maropost')}</label>
                        </div>
                        {user.api_integration && user.api_integration.maropost &&
                            <React.Fragment>
                                <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="url" name="url" value={user.api_integration.maropost.url} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.maropost)}}/>
                                <label htmlFor="url">{t('user.api.url')}</label>
                            </span>
                                </div>
                                <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="username" name="username" value={user.api_integration.maropost.username} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.maropost)}}/>
                                <label htmlFor="username">{t('user.api.user_name')}</label>
                            </span>
                                </div>
                                <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="password" name="password" value={user.api_integration.maropost.password} autoComplete="off" onChange={(e) => {onChange(e, user.api_integration.maropost)}}/>
                                <label htmlFor="password">{t('user.api.password')}</label>
                            </span>
                                </div>

                            </React.Fragment>
                        }
                    </div>
                </TabPanel>
                <TabPanel header={t('user.campaign_settings')} >
                    <div className="p-grid alignCenter p-fluid" style={{minWidth: "55vw"}}>
                        <div className="p-col-12">
                            <JobSchedulingSetting campaign={user.default_campaign} user={user}/>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </Dialog>
    );
};
export default UpdateUser
