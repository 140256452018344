import React, {useState} from 'react';


import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction'
import {CampaignService} from "../../service/CampaignService";
import {AppContext} from "../../HomePage";

const JobSchedulingCalendarView = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [events, setEvents] = useState(null);
    const campaignService = new CampaignService();
    let resources = new Map();

    let jobEvents = props.jobs && props.jobs.map((job, index) => {
        let resourceId = `${job.user.email} - ${job.campaign.name}`
        if (resources.get(resourceId) == null)
            resources.set(resourceId, {"id":resourceId, "user": `${job.user.first_name} ${job.user.last_name}`, "title": job.campaign.name, "eventColor": "#f90"})
        if (props.type === 'plan') {
            return {
                "id": index,
                "resourceId": resourceId,
                "campaign": job.campaign,
                "title": `Job`,
                "start": props.plannedAtTemplate(job)
            };
        } else {
            return {
                "id": index,
                "resourceId": resourceId,
                "title": `Job - ${props.durationTemplate(job)}`,
                "start": props.startedAtTemplate(job),
                "end": props.finishedAtTemplate(job),
            };
        }

    });

    function jobHours(date) {
        let h = (date.getHours()<10?'0':'') + date.getHours();
        let m = (date.getMinutes()<10?'0':'') + date.getMinutes();
        return h + ':' + m;
    }

    const handleEventClick = (clickInfo) => {
        let filteredEvents = events.filter(event => event.extendedProps.campaign.id === clickInfo.event.extendedProps.campaign.id);
        let job_hours = filteredEvents.map(event => jobHours(event.start));

        campaignService.updateScrapingJobHours(clickInfo.event.extendedProps.campaign.id, job_hours).then(message=>{
            toast.current.show({
                severity: 'success',
                summary: message,
                detail: null
            });
            // props.list();
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const handleEvents = (events) => {
        setEvents(events)
    }

    const planOptions = {
        // https://fullcalendar.io/docs/timeline-view
        plugins: [ resourceTimelinePlugin, interactionPlugin ],
        initialView: 'resourceTimeline',
        resourceAreaWidth: '20%',
        resourceGroupField: 'user',
        resources: Array.from(resources.values()),
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: ''
        },
        aspectRatio: 1.5,
        editable: true,
        eventsSet: handleEvents,
        eventChange: handleEventClick
    };

    const realOptions = {
        plugins: [ resourceTimelinePlugin],
        initialView: 'resourceTimeline',
        resourceAreaWidth: '20%',
        resourceGroupField: 'user',
        resources: Array.from(resources.values()),
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: ''
        },
        aspectRatio: 1.5,
    };

    return (
        <div className="p-col-12">

            <div className="p-col-12">
                { props.type === 'plan' && <FullCalendar events={jobEvents} options={planOptions}/>}
                { props.type === 'real' && <FullCalendar events={jobEvents} options={realOptions}/>}
            </div>
        </div>
    );

};
export default (JobSchedulingCalendarView)
