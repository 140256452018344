import React, {useEffect, useState} from "react";

import {
    formatCurrency,
    getProductDetailLink,
    isCampaignManufacturer,
    isCampaignMarketSearch
} from "../../utils/Utils";

import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {IndexTemplate, Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";

const SellerDetail = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [items, setItems] = useState([]);


    const [loading, setLoading] = useState(false);

    const dashboardService = new DashboardService();

    useEffect ( ()=>{
        search();
    },[props.competitor_name]);

    const search = () => {
        if (selectedCampaign && props.competitor_name) {
            setLoading(true);
            dashboardService.getPriceComparisonWithFilteredSellers(selectedCampaign.id, props.competitor_name).then(data=>{
                let violations = [];
                data.forEach(d=>{
                    Object.keys(d).forEach(key => {
                        if (key.endsWith("_seller_price") && d[key] !== null)
                            violations.push({...d, "seller_price" : d[key],
                            "seller_url": d[key.replace("_seller_price", "_seller_url")]
                            })
                    });

                })
                setItems(violations);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const nameTemplate = (rowData) => {
        return getProductDetailLink(rowData.id, rowData.name);
    };

    const sellerPriceTemplate = (rowData, props) => {
        let color = 'black'
        if (rowData.owner && rowData.owner.is_map && rowData.owner.price && rowData.owner.price.value > rowData['seller_price']) {
            color = 'red'
        } else if (rowData.mrp_owner && rowData.mrp_owner.is_mrp && rowData.mrp_owner.price && rowData.mrp_owner.price.value < rowData['seller_price']) {
            color = 'orange'
        }

        return rowData['seller_price'] ? <div className="p-grid">
            <a target="_blank" rel="noopener noreferrer" href={rowData['seller_url']} style={{color: color}}>
                {formatCurrency(rowData['seller_price'], rowData.owner.price?.currency)}
            </a>
        </div> : '';
    };


    const ownPriceTemplate = (rowData) => {
        if (rowData.owner && rowData.owner.is_map) {
            return <div className="p-grid p-justify-end">
                {formatCurrency(rowData.owner.price?.value, rowData.owner.price?.currency)}
            </div>
        } else if (rowData.owner) {
            return <div className="p-grid p-justify-end">
                <a target="_blank" rel="noopener noreferrer" href={rowData.owner.product_url}>
                    {formatCurrency(rowData.owner.price?.value, rowData.owner.price?.currency)}
                </a>
            </div>
        } else {
            return ''
        }
    };

    const mrpPriceTemplate = (rowData) => {
        return rowData.mrp_owner ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.mrp_owner.price?.value, rowData. mrp_owner.price?.currency)}
        </div> : '';
    };

    const differenceTemplate = (rowData, props) => {
        let difference = 0

        if (rowData['seller_price'] > 0) {
            if (rowData.owner && rowData.owner.is_map && rowData.owner.price && rowData.owner.price.value > rowData['seller_price']) {
                difference = rowData.owner.price.value - rowData['seller_price']
            }
        }

        if (difference > 0) {
            return <div className="p-grid">
                {formatCurrency(difference, rowData.owner.price.currency)}
            </div>;
        } else {
            return ''
        }
    };

    const dynamicColumns = () => {
        let columns = []
        columns.push(<Column body={IndexTemplate} style={{width:'3em'}} />);
        columns.push(<Column key="product_code" field="product_code" header={t('product.product_code')} headerStyle={{width: '120px', textAlign:"left"}}/>);
        columns.push(<Column key="name" field="name" body={nameTemplate} header={t('product.product_name')} headerStyle={{width: '300px'}} sortable/>);
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="price" field="price" body={ownPriceTemplate} header={isCampaignManufacturer(selectedCampaign) ? t('price_violation.map_abbreviation') : t('product_detail.own_price')} headerStyle={{width: '120px', textAlign:"right"}} sortable/>);}
        {isCampaignManufacturer(selectedCampaign) && columns.push(<Column key="mrp_price" field="mrp_price" body={mrpPriceTemplate} header={t('price_violation.mrp_abbreviation')} headerStyle={{width: '120px', textAlign:"right"}} sortable/>);}
        columns.push(<Column key="seller_price" field="seller_price" body={sellerPriceTemplate} header={t('product.price')} headerStyle={{width: '150px', textAlign:"left"}} sortable/>);
        {isCampaignManufacturer(selectedCampaign) && columns.push(<Column key="seller_difference" field="seller_diff" body={differenceTemplate} header={t('product.difference')} headerStyle={{width: '100px', textAlign:"left"}}/>);}

        return columns.map((col, i) => {return col});
    };

    return <React.Fragment>

        <div className="p-grid card">
            <div className="p-col-12">
                {loading ?
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Loading/>
                    </div> :
                    <DataTable value={items} scrollable scrollHeight={(window.innerHeight - 410)+"px"}
                               emptyMessage={t('actions.empty_message')}
                               paginator={true}
                               rows={100}>
                        {dynamicColumns()}
                    </DataTable>}
            </div>
        </div>

    </React.Fragment>;
};
export default SellerDetail;
