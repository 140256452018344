import React, { useState} from 'react';
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {CampaignService} from "../../service/CampaignService";


const UserOperation = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const user = props.user;

    const campaignService = new CampaignService();

    const copyDefaultCampaignSettings = () => {
        campaignService.copyDefaultCampaignSettings(user.id).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.copy_default_campaign'), detail: t('message_detail.successful')});
        });
    };

    return <React.Fragment>
        <div className="p-grid">
            <div className="p-col-12">
                <Button label={t('actions.copy_default_campaign_settings')} style={{marginRight:'0.5em'}} icon="pi pi-copy" className="p-button-outlined p-button-success" onClick={copyDefaultCampaignSettings} />
            </div>

        </div>
    </React.Fragment>;

};
export default (UserOperation)
