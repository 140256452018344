import {Galleria} from "primereact/galleria";
import {Panel} from "primereact/panel";
import React, {useState} from "react";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";
import {SelectButton} from "primereact/selectbutton";
import {getKeyValue} from "../../utils/Utils";
import {Calendar} from "primereact/calendar";
import {toDateString} from "../../utils/TimeUtil";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {CampaignService} from "../../service/CampaignService";

const Banner = (props) => {

    const campaignService = new CampaignService();

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [banner, setBanner] = useState({});
    const [showEdit, setShowEdit] = useState(false);

    const [activeIndex, setActiveIndex] = useState(null);
    const [filters, setFilters] = useState({
        is_active: true,
        dates: null
    });

    const isActiveOptions = [
        {label: t('actions.active'), value: true},
        {label: t('actions.passive'), value: false}
    ];

    const onFilterChange = (e) =>{
        const { key, value } = getKeyValue(e);

        setFilters({...filters, [key]:value});
    };

    const galleriaTemplate = (item) => {
        return <div style={{width: "100%", height: "400px"}}><img src={item.url} style={{maxWidth: "100%", maxHeight:"400px"}}/></div>
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.url} style={{maxWidth:"120px", maxHeight:"50px"}} />
    }

    const captionTemplate = (item) => {
        return <div className="p-grid p-nogutter">
            <div className="p-col">
                <span className="p-mr-2">{t('campaign.banner.started_at') + " " + toDateString(new Date(item.started_at))}</span>
                {item.finished_at && <span>{t('campaign.banner.finished_at') + " " + toDateString(new Date(item.finished_at))}</span>}
                <br/>
                {item.note && <span>{item.note}</span>}
            </div>
            <div className="p-col-fixed">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary" tooltip={t('actions.edit')} onClick={() => {setBanner(item);setShowEdit(true)}} />
            </div>
        </div>;
    }

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 6
        },
        {
            breakpoint: '768px',
            numVisible: 5
        },
        {
            breakpoint: '560px',
            numVisible: 3
        }
    ];

    const onChange = (e) =>{
        const { key, value } = getKeyValue(e);
        setBanner({...banner, [key]: value});
    };

    const save = () => {

        campaignService.saveBanner(selectedCampaign.id, banner).then(() => {

            let banners = selectedCampaign.banners;
            const index = banners.findIndex(b=> b.url !== banner.url);

            banners[index].started_at = banner.started_at;
            banners[index].finished_at = banner.finished_at;
            banners[index].note = banner.note;

            selectedCampaign.banners = [...banners];

            toast.current.show({severity: 'success', summary: t('actions.save'), detail: t('message_detail.successful')});
            setShowEdit(false);
        }).catch(error =>{
            setError(error);
            setError(null);
        });


    };

    const editModalButtons = (
        <div>
            <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => setShowEdit(false)} />
            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save} />
        </div>
    );

    const getFilterResult = () =>{

        const result = selectedCampaign?.banners ? selectedCampaign.banners.filter(banner =>
        {
            const filter_start = filters.dates && filters.dates[0] ? filters.dates[0] : new Date(-8640000000000000);
            const filter_end = filters.dates && filters.dates[1] ? filters.dates[1] : new Date(8640000000000000);
            const started_at = banner.started_at;
            const finished_at = banner.finished_at ? banner.finished_at : Date.now();


            return ((filters.is_active && !banner.finished_at) || (!filters.is_active && !!banner.finished_at)) &&
                (filters.dates === null || (
                    (new Date(started_at) >= new Date(filter_start) && new Date(started_at) <= new Date(filter_end)) ||
                    (new Date(finished_at) >= new Date(filter_start) && new Date(finished_at) <= new Date(filter_end))
                ))
        }) : null;

        return result;
    }

    let filteredBanners = getFilterResult();

    return selectedCampaign?.banners.length > 0 ?
        <Panel header={t("campaign.banners")} toggleable className="p-mb-2">
            <div className="p-formgroup-inline">
                <div className="p-field">
                    <SelectButton name="is_active" value={filters.is_active} optionLabel="label" options={isActiveOptions}
                                  onChange={(e) => {onFilterChange({target:{name:"is_active", value:e.value}})}}
                                  className="p-mr-1"/>
                </div>
                <div className="p-field">
                    <Calendar id="dates" name="dates" readOnlyInput={true} selectionMode="range" numberOfMonths={2} value={filters.dates} onChange={onFilterChange} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true}/>
                </div>
            </div>

            <Galleria value={filteredBanners} item={galleriaTemplate} showItemNavigators //showThumbnails={false} showIndicators
                      thumbnail={thumbnailTemplate}
                      responsiveOptions={responsiveOptions}
                      caption={captionTemplate}
                      circular  autoPlay={false} transitionInterval={10000}/>

            <Dialog header={t('actions.update')}  footer={editModalButtons} visible={showEdit} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => setShowEdit(false)}>
                <div className="p-grid alignCenter p-fluid p-mt-2">
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <Calendar id="started_at" name="started_at" value={new Date(banner.started_at)} onChange={onChange} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true}/>
                            <label htmlFor="started_at">{t('campaign.banner.started_at')}</label>
                        </span>
                    </div>
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <Calendar id="finished_at" name="finished_at" value={banner.finished_at && new Date(banner.finished_at)} onChange={onChange} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true}/>
                            <label htmlFor="finished_at">{t('campaign.banner.finished_at')}</label>
                        </span>
                    </div>
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputTextarea id="note" name="note" value={banner.note} autoComplete="off" onChange={onChange} rows={5} cols={30}/>
                            <label htmlFor="note">{t('campaign.banner.note')}</label>
                        </span>
                    </div>
                </div>
            </Dialog>
        </Panel> : "";
}
export default Banner;