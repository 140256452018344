import React, {useEffect, useState} from "react";

import {formatCurrency, getKeyValue} from "../../utils/Utils";
import {
    getMonthsAgoFirstDay,
    getMonthsAgoFirstDayOfDate,
    getMonthsAgoLastDayOfDate, getNextMonthFirstDayOfDate, getNextMonthLastDayOfDate,
    toDateStringIgnoringTimezone
} from "../../utils/TimeUtil";
import {IndexTemplate} from "../../utils/InlineComponents";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {UserService} from "../../service/UserService";
import {Row} from "primereact/row";
import {ColumnGroup} from "primereact/columngroup";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {FileUpload} from "primereact/fileupload";
import {ProductExcelImportService} from "../../service/ProductExcelImportService";

const Expense = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);
    const [dates, setDates] = useState([getMonthsAgoFirstDay(0), new Date()]);

    const [totalUSDIncome, setTotalUSDIncome] = useState([]);
    const [showExpenseDeleteModal, setExpenseDeleteModal] = useState(false);
    const [showExpenseAdditionModal, setExpenseAdditionModal] = useState(false);
    const [showExpenseUpdateModal, setExpenseUpdateModal] = useState(false);
    const [rowData, setRowData] = useState(getNewExpense());


    const [loading, setLoading] = useState(false);

    const userService = new UserService();
    const productExcelImportService = new ProductExcelImportService();

    useEffect ( ()=>{
        search();
    }, [dates]);

    function getNewExpense() {
        return {description:"", amount:0.0, created_at: toDateStringIgnoringTimezone(new Date())}
    }

    const search = () => {
        if (dates[0] && dates[1]) {
            setLoading(true);
            userService.getExpenses(toDateStringIgnoringTimezone(dates[0]), toDateStringIgnoringTimezone(dates[1])).then(data => {
                setItems(data);
                setTotalUSDIncome(data.map(item => (item.amount)).reduce((a, b) => a + b, 0))
                setLoading(false);
            }).catch(error => {
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const incomeTemplate = (rowData) => {
        return rowData.amount ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.amount, 'USD')}
        </div> : '';
    };

    const footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Total Charged:" colSpan={4} footerStyle={{textAlign: 'right'}}/>
            <Column footer={formatCurrency(totalUSDIncome, "USD")} footerStyle={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>;

    const dateTemplate = (rowData) => <span>{toDateStringIgnoringTimezone(rowData.created_at)}</span>;

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <div className="p-grid p-align-center">
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-1" tooltip={t('actions.edit')} tooltipOptions={{position: 'bottom'}} onClick={() => {setRowData(rowData); setExpenseUpdateModal(true);}}/>
                <Button icon="pi pi-trash" className="p-button-danger" tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => {setRowData(rowData); setExpenseDeleteModal(true);}}/>
            </div>
        </React.Fragment>;

    }

    const deleteExpense = () => {
        userService.deleteExpense(rowData.id).then(()=>{
            let filteredItems = items.filter(i => i.id !== rowData.id);
            setItems(filteredItems);
            setRowData(getNewExpense());
            setExpenseDeleteModal(false);
            toast.current.show({severity: 'success', summary: t('actions.remove'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setExpenseDeleteModal(false);
            setRowData(getNewExpense());
        });

    }

    const addExpense = () => {
        userService.createExpense(rowData).then(added_expense=>{
            let filteredItems = items.filter(i => i.id !== added_expense.id);
            filteredItems.unshift(added_expense);
            setItems(filteredItems);
            setRowData(getNewExpense());
            setExpenseAdditionModal(false);
            toast.current.show({severity: 'success', summary: t('actions.add'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setExpenseAdditionModal(false);
            setRowData(getNewExpense());
        });

    }

    const updateExpense = () => {
        userService.updateExpense(rowData).then(()=>{
            let filteredItems = items.filter(i => i.id !== rowData.id);
            filteredItems.unshift(rowData);
            setItems(filteredItems);
            setRowData(getNewExpense());
            setExpenseUpdateModal(false);
            toast.current.show({severity: 'success', summary: t('actions.update'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setExpenseUpdateModal(false);
            setRowData(getNewExpense());
        });

    }

    const importExpenses = ({files}) => {
        const [file] = files;
        productExcelImportService.importExpenses(file).then((result) =>{
            toast.current.show({severity: 'success', summary: t('actions.add'), detail: t('message_detail.successful') });
            search();
        }).catch(error => {
            setError(error);
            setError(null);
            search();
        });
    };

    const onChange = (e) => {
        const { key, value } = getKeyValue(e);
        setRowData({...rowData, [key] : value});
    };

    const modalExpenseAddition = (
        <div className="p-grid alignCenter p-fluid">
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="description" name="description" value={rowData.description} onChange={onChange}/>
                    <label htmlFor="description">{t('product.description')}</label>
                </span>
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                    <Calendar id="created_at" name="created_at" value={new Date(rowData.created_at)} onChange={onChange} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em'}}/>
                    <label htmlFor="created_at">{t('user.kpi.date')}</label>
                </span>
            </div>
            <div className="p-col-12">
                <span className="p-float-label">
                      <InputNumber id="amount" name="amount" value={rowData.amount} style={{width:"200px"}}
                                   mode="decimal" minFractionDigits={2}
                                   onValueChange={onChange}/>
                      <label htmlFor="amount">{t('user.charge_amount')}</label>
                </span>
            </div>
        </div>
    );

    const modalExpenseAddCancelButtons = (
        <div>
            <Button label={t('actions.save')} icon="pi pi-plus" className="p-button-success" onClick={addExpense} />
            <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-warning" onClick={() => {setExpenseAdditionModal(false);}} />
        </div>
    );

    const modalExpenseUpdateCancelButtons = (
        <div>
            <Button label={t('actions.update')} icon="pi pi-save" className="p-button-success" onClick={updateExpense} />
            <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-warning" onClick={() => {setExpenseUpdateModal(false);}} />
        </div>
    );

    const modalYesNo = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" className="p-button-danger" onClick={deleteExpense} />
            <Button label={t('yes_no.no')} icon="pi pi-times" className="p-button-warning" onClick={() => {setExpenseDeleteModal(false); setRowData(getNewExpense());}} />
        </div>
    );

    const previousDate = () => {
        let startDate = getMonthsAgoFirstDayOfDate(dates[0])
        let finishDate = getMonthsAgoLastDayOfDate(startDate)
        setDates([startDate, finishDate])
    }

    const nextDate = () => {
        let startDate = getNextMonthFirstDayOfDate(dates[0])
        let finishDate = getNextMonthLastDayOfDate(startDate)
        setDates([startDate, finishDate])
    }

    return <React.Fragment>


        <Dialog header={t('dialogs.confirm_expense_delete')} footer={modalYesNo} visible={showExpenseDeleteModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setExpenseDeleteModal(false); setRowData(getNewExpense());}}>
            {t('dialogs.confirm_expense_delete_msg1')}
        </Dialog>

        <Dialog header={t('dialogs.expense_addition')} footer={modalExpenseAddCancelButtons} visible={showExpenseAdditionModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setExpenseAdditionModal(false);}}>
            {modalExpenseAddition}
        </Dialog>

        <Dialog header={t('dialogs.expense_update')} footer={modalExpenseUpdateCancelButtons} visible={showExpenseUpdateModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setExpenseUpdateModal(false); setRowData(getNewExpense());}}>
            {modalExpenseAddition}
        </Dialog>

        <div className="p-grid">
            <div className="p-col-10">
                <Button tooltip={t('actions.previous')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-left" className="p-button-outlined" onClick={previousDate} style={{marginRight:'0.5em'}} />
                <Calendar id="dates" name="dates" selectionMode="range" numberOfMonths={2} value={dates} onChange={(e) => setDates(getKeyValue(e).value)} showIcon={true} dateFormat="yy-mm-dd" showButtonBar={true} style={{marginRight:'0.5em', width:"23%"}}/>
                <Button tooltip={t('actions.next')} tooltipOptions={{position: 'bottom'}} icon="pi pi-angle-right" className="p-button-outlined" onClick={nextDate} style={{marginRight:'0.5em'}}/>
                <Button label={t('actions.search')} icon="pi pi-search" className="p-button-success" style={{marginRight:'0.5em'}} onClick={search} />
            </div>
            <div className="p-col-1" style={{textAlign:"right"}}>
                <Button label={t('actions.add')} icon="pi pi-plus" className="p-button-success" onClick={() => {setExpenseAdditionModal(true);}} />
            </div>
            <div className="p-col-1" style={{textAlign:"right"}}>
                <FileUpload customUpload={true}
                            mode="basic"
                            auto={false}
                            chooseLabel={t('actions.upload')}
                            uploadHandler={importExpenses}
                />
            </div>
        </div>

         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <DataTable value={items}
                        footerColumnGroup={footerGroup}
                        emptyMessage={t('actions.empty_message')}
                        paginator={true} rows={100}>
                 <Column body={IndexTemplate} headerStyle={{width:'3em'}} />
                 <Column field="description" header={t('product.description')} filter />
                 <Column field="amount" body={incomeTemplate} header={t('reprice.amount')} headerStyle={{textAlign:"right"}} filter filterHeaderStyle={{textAlign:"right"}}/>
                 <Column field="created_at" body={dateTemplate} header={t('billing.created_at')} filter/>
                 <Column header={t('table_headers.action')} body={actionTemplate} headerStyle={{textAlign:'center', width:'7em'}}/>
             </DataTable>}

    </React.Fragment>;
};
export default Expense;
