import i18n from "../i18n.js"

const languages = [
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Türkçe',
        value: 'tr'
    },
];

export class LanguageService {

    static getLanguages() {
        return languages;
    }

    static setLanguage(lng) {
        if (lng.toLowerCase() !== "en" && lng.toLowerCase() !== "tr")
            lng = i18n.fallbackLng;
        i18n.changeLanguage(lng);
        localStorage.setItem("i18nextLng", lng);
    }

    static getLanguage() {
        let language = (localStorage.getItem("i18nextLng") !== undefined &&  localStorage.getItem("i18nextLng") !== null) ? localStorage.getItem("i18nextLng") : i18n.fallbackLng;

        return language === 'tr' ? language :'en' ;
    }
}
