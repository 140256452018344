import React, {useEffect, useState} from "react";
import {CampaignService} from "../../service/CampaignService";
import JobSchedulingSetting from "../Admin/JobSchedulingSetting";
import {Button} from "primereact/button";

import {AppContext} from "../../HomePage";
import CopyToClipboard from "../../utils/CopyToClipboard";
import {CampaignContext} from "../../App";

const CampaignSettings = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const campaignService = new CampaignService();

    const [jobHours, setJobHours] = useState([]);

    useEffect ( ()=>{
        campaignService.scrapingJobHours(selectedCampaign.id).then(jobHours => {
            selectedCampaign.settings.scraping_jobs = jobHours
            setJobHours(jobHours);
        })
    },[]);

    const save = () => {
        campaignService.saveCampaignSettings(selectedCampaign.id, selectedCampaign.use_sale_value, selectedCampaign.settings).then(data =>{
            toast.current.show({
                severity: 'success',
                summary: t('reprice.save_pricing_strategy'),
                detail: t('message_detail.successful')
            });
        });
    };

    return <React.Fragment>
        <h3>{t('campaign.settings') + ' - ' + selectedCampaign.name}</h3>
        <p></p>

        {jobHours.length > 0 && <JobSchedulingSetting campaign={selectedCampaign} jobs={jobHours}/>}

        <div style={{textAlign: 'right'}}>
            <Button label={t('actions.cancel')} style={{marginRight:'0.5em'}} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => props.hideDialog()} />
            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save} />
        </div>

        <div className="p-grid p-fluid">
            <div className="p-col-12">
                <b>User ID: </b> {selectedCampaign.user_id}<CopyToClipboard textToCopy={selectedCampaign.user_id} />
            </div>

            <div className="p-col-12">
                <b>Campaign ID: </b> {selectedCampaign.id}<CopyToClipboard textToCopy={selectedCampaign.id} />
            </div>
        </div>

    </React.Fragment>;
};
export default CampaignSettings
