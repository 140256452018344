import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Captcha} from 'primereact/captcha';
import {Password} from "primereact/password";
import {AuthService} from "../../service/AuthService";
import {UnAuthService} from "../../service/UnAuthService";
import queryString from 'query-string';
import {AuthInfo, Logo} from "../../utils/InlineComponents";
import {getKeyValue} from "../../utils/Utils";
import {AppContext} from "../../HomePage";


const Recovery = (props) => {

    const {t, toast, setError, location, navigate} = React.useContext(AppContext);

    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const[password_again, setPasswordAgain] = useState('');
    const[isToken, setIsToken] = useState(false);
    const[captchaValid, setCaptchaValid] = useState(false);

    useEffect(() => {
        const url = location.search;
        const params = queryString.parse(url);
        if(params.token) {
            setIsToken( true );
        }
        if(AuthService.loggedIn()) {
            navigate("/");
        }
    }, [])

    const captchaResponse = () => {
        setCaptchaValid(true);
    };

    const sendRecoveryLink = () => {

        if(!captchaValid)
        {
            toast.current.show({severity: "error", summary: t('login.captcha_summary'), detail: t('login.captcha_detail')});
            return;
        }

        UnAuthService.sendRecoveryLink(email).then(
            navigate("/login?recover=1")
        ).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const resetPassword = () => {

        if (password !== password_again)
        {
            toast.current.show({severity: "error", summary: t('message_detail.error'), detail: t('validations.password_no_match')});
            return;
        }

        const url = location.search;
        const params = queryString.parse(url);

        UnAuthService.resetPassword(password, params.token).then(
            navigate("/login?recover=2")
        ).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    return <div className="p-grid p-nogutter" style={{height: "100vh"}}>
        <div className="p-grid p-col-12 p-lg-7 authInfoContainer" >
            <AuthInfo/>
        </div>

        <div className="p-grid p-col-12 p-lg-5 p-nogutter" style={{ backgroundColor:"white"}} >
            {!isToken &&
            <div className="p-grid p-align-center p-fluid" style={{margin:"auto", width:"400px"}}  >
                <div className="p-col-12">
                    <Logo/>
                </div>
                <div className="p-col-12">
                    <h4>{t('menu_item.reset_password')}</h4>
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText id="email" name="email" keyfilter="email" value={email} onChange={(e) => setEmail(getKeyValue(e).value)}/>
                        <label htmlFor="email">{t('user.email')}</label>
                    </span>
                </div>
                <div className="p-col-12">
                    <Captcha siteKey="6LfwsuYUAAAAAHfOxoAxTJYehxxhfLO_SSJZDaaL" onResponse={captchaResponse}/>
                </div>
                <div className="p-col-6">
                    <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={(e) => (window.location = '#/login')}/>
                </div>
                <div className="p-col-6">
                    <Button label={t('user.send_recovery_link')} icon="pi pi-envelope" className="p-button-outlined p-button-success" onClick={sendRecoveryLink}/>
                </div>
            </div>}
            {isToken &&
            <div className="p-grid p-align-center p-fluid" style={{margin:"auto", width:"400px"}}  >
                <div className="p-col-12">
                    <Logo/>
                </div>
                <div className="p-col-12">
                    <h4>{t('menu_item.reset_password')}</h4>
                </div>
                <div className="p-col-12">
                <span className="p-float-label">
                    <Password id="password" name="password" value={password} onChange={(e) => setPassword(getKeyValue(e).value)} feedback={true}/>
                    <label htmlFor="password">{t('user.password')}</label>
                </span>
                </div>
                <div className="p-col-12">
                <span className="p-float-label">
                    <Password id="password_again" name="password_again" value={password_again} onChange={(e) => setPasswordAgain(getKeyValue(e).value)} feedback={false}/>
                    <label htmlFor="password_again">{t('user.password_again')}</label>
                </span>
                </div>

                <div className="p-col-12">
                    <Button label={t('user.reset_password')} icon="pi pi-key" className="p-button-outlined p-button-success" onClick={resetPassword}/>
                </div>
            </div>}
        </div>
    </div>;
};
export default Recovery;
