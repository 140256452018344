import React, {useEffect, useState} from "react";



import {Panel} from "primereact/panel";
import {Pie} from "react-chartjs-2";
import {AppContext} from "../../HomePage";
import ChartDataLabels from "chartjs-plugin-datalabels";

const OverviewPrice = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const [overview, setOverview] =useState({});

    useEffect(() => {
        setOverview(props.overview);
    }, [props.overview]);

    const pieData = {
        labels: [t('overview.total_highest'), t('overview.total_higher'), t('overview.total_same'), t('overview.total_cheapest'),],
            datasets: [
            {
                data: [overview.total_highest, overview.total_higher,  overview.total_same, overview.total_cheapest],
                backgroundColor: [
                    "#e32340",
                    "#f9c851",
                    "#4c72e7",
                    "#20d077",
                ],
                hoverBackgroundColor: [
                    "#d45064",
                    "#d0af5d",
                    "#4f70aa",
                    "#86e0b1",
                ]
            }]
    };

    const pieDataForMAP = {
        labels: [t('overview.total_map_violation'), t('overview.total_no_map_violation')],
        datasets: [
            {
                data: [overview.total_higher + overview.total_highest, overview.total_cheapest + overview.total_same],
                backgroundColor: [
                    "#e32340",
                    "#20d077",
                ],
                hoverBackgroundColor: [
                    "#d45064",
                    "#86e0b1",
                ]
            }]
    };

    function hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'right'
        },
        plugins: {
            datalabels: {
                align: 'bottom',
                anchor: 'center',
                color: 'white',
                formatter: function(_value, context) {
                    if (_value < 1)
                        return "";
                    return _value;
                }
            }
        }
    };


    return <React.Fragment>
        <div className="p-col-12 p-lg-4 contacts">
            <Panel header={props.isManufacturer ? t('overview.price_violation') : t('overview.price')} style={{height:'100%'}}>
                <ul className="activity-list">

                    {!props.isManufacturer &&
                    <li>
                        <button className="p-link" onClick={() => {
                            window.location = '#/product?myprice=highest';
                        }}>
                            <span className="count red">{overview.total_highest}</span>
                            <span className="title">{t('overview.total_highest')}</span>
                            <span className="subtitle">{t('overview.total_highest_detail')}</span>
                        </button>
                    </li>
                    }

                    <li>
                        <button className="p-link" onClick={() => {window.location = '#/product?myprice=high';}}>
                            {!props.isManufacturer && <span className="count yellow">{overview.total_higher}</span>}
                            {props.isManufacturer && <span className="count red">{overview.total_higher + overview.total_highest}</span>}
                            {!props.isManufacturer && <span className="title">{t('overview.total_higher')}</span>}
                            {props.isManufacturer && <span className="title">{t('overview.total_map_violation')}</span>}
                            {!props.isManufacturer && <span className="subtitle">{t('overview.total_higher_detail')}</span>}
                            {props.isManufacturer && <span className="subtitle">{t('overview.total_map_violation_detail')}</span>}
                        </button>
                    </li>

                    {props.isManufacturer &&
                        <li>
                            <button className="p-link" onClick={() => {window.location = '#/product?violatedMRP';}}>
                                <span className="count red">{overview.total_violated_mrp}</span>
                                <span className="title">{t('overview.total_mrp_violation')}</span>
                                <span className="subtitle">{t('overview.total_mrp_violation_detail')}</span>
                            </button>
                        </li>
                    }


                    {!props.isManufacturer &&
                    <li>
                        <button className="p-link" onClick={() => {
                            window.location = '#/product?myprice=same';
                        }}>
                            <span className="count blue">{overview.total_same}</span>
                            <span className="title">{t('overview.total_same')}</span>
                            <span className="subtitle">{t('overview.total_same_detail')}</span>
                        </button>
                    </li>
                    }

                    <li>
                        <button className="p-link" onClick={() => {window.location = '#/product?myprice=lowest';}}>
                            {!props.isManufacturer && <span className="count green">{overview.total_cheapest}</span>}
                            {props.isManufacturer && <span className="count green">{overview.total_cheapest + overview.total_same}</span>}
                            {!props.isManufacturer && <span className="title">{t('overview.total_cheapest')}</span>}
                            {props.isManufacturer && <span className="title">{t('overview.total_no_map_violation')}</span>}
                            {!props.isManufacturer && <span className="subtitle">{t('overview.total_cheapest_detail')}</span>}
                            {props.isManufacturer && <span className="subtitle">{t('overview.total_no_map_violation_detail')}</span>}
                        </button>
                    </li>

                    {!props.isManufacturer &&
                        <li>
                            <button className="p-link" onClick={() => {window.location = '#/product?myprice=avg_lower';}}>
                                <span className="count green">{overview.total_avg_cheaper}</span>
                                <span className="title">{t('overview.total_avg_cheaper')}</span>
                                <span className="subtitle">{t('overview.total_avg_cheaper_detail')}</span>
                            </button>
                        </li>
                    }
                    {!props.isManufacturer &&
                        <li>
                            <button className="p-link" onClick={() => {window.location = '#/product?myprice=avg_higher';}}>
                                <span className="count red">{overview.total_avg_higher}</span>
                                <span className="title">{t('overview.total_avg_higher')}</span>
                                <span className="subtitle">{t('overview.total_avg_higher_detail')}</span>
                            </button>
                        </li>
                    }

                </ul>
            </Panel>
        </div>

        <div className="p-col-12 p-lg-4 contacts">
            <Panel header={props.isManufacturer ? t('overview.price_violation') : t('overview.price')} style={{height:'100%'}}>
                <div className="card">
                    <Pie id="comparison" data={props.isManufacturer ? pieDataForMAP : pieData}
                         plugins={[ChartDataLabels]} options={pieOptions}
                         height={props.isManufacturer ? 120 : 155}/>
                </div>
            </Panel>
        </div>

        {!props.isManufacturer &&
        <div className="p-col-12 p-lg-4 contacts">
            <Panel header={t('overview.suggested_price')} style={{height:'100%'}}>
                <ul className="activity-list">
                    <li>
                        <button className="p-link" onClick={() => {window.location = '#/product?reprice=false';}}>
                            <span className="count green">{overview.total_higher_suggested_price}</span>
                            <span className="title">{t('overview.total_higher_suggested_price')}</span>
                            <span className="subtitle">{t('overview.total_higher_suggested_price_detail')}</span>
                        </button>
                    </li>

                    <li>
                        <button className="p-link" onClick={() => {window.location = '#/product?reprice=true';}}>
                            <span className="count yellow">{overview.total_lower_suggested_price}</span>
                            <span className="title">{t('overview.total_lower_suggested_price')}</span>
                            <span className="subtitle">{t('overview.total_lower_suggested_price_detail')}</span>
                        </button>
                    </li>

                    <li>
                        <button className="p-link" onClick={() => {window.location = '#/product?variant_matching_needed=true';}}>
                            <span className="count blue">{overview.total_variant_matching_needed}</span>
                            <span className="title">{t('overview.total_variant_matching_needed')}</span>
                            <span className="subtitle">{t('overview.total_variant_matching_needed_detail')}</span>
                        </button>
                    </li>
                </ul>
            </Panel>
        </div>
        }

    </React.Fragment>;
};
export default OverviewPrice;
