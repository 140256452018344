import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {IndexTemplate, Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {toDateTimeString} from "../../utils/TimeUtil";
import {DashboardService} from "../../service/DashboardService";
import {getHostName} from "../../utils/Utils";
import {Button} from "primereact/button";
import SiteDefinitionAdd from "./SiteDefinitionAdd";
import {InputSwitch} from "primereact/inputswitch";
import {SelectButton} from "primereact/selectbutton";
import {Dialog} from "primereact/dialog";

const NewWebsites = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);

    const [loading, setLoading] = useState(false);

    const [showAddSiteDefinition, setShowAddSiteDefinition] = useState(false);
    const [selectedWebSite, setSelectedWebSite] = useState(null);

    const options = [
        {label: t('actions.active'), value: true},
        {label: t('actions.passive'), value: false}
    ];

    const [activeItems, setActiveItems] = useState(true);

    const dashboardService = new DashboardService();

    useEffect ( ()=>{
        search();
    }, []);

    const search = () => {
        setLoading(true);
        dashboardService.getUndefinedWebsites().then(data=>{
            setItems(data);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const dateTemplate = (rowData) => <span>{toDateTimeString(rowData.created_date)}</span>;

    const urlTemplate = (rowData) => {

        let l = document.createElement("a");
        l.href = rowData.url;

        return <div >
            <span className="p-column-title">{t('table_headers.site')}</span>
            <a target="_blank" rel="noopener noreferrer" href={rowData.url} title={getHostName(rowData.url)}> {getHostName(rowData.url)} </a>
        </div>;
    };

    const actionTemplate = (rowData) => {
        return <div>
                <InputSwitch checked={rowData.is_active} onChange={() => changeIsActive(rowData)} tooltipOptions={{position: 'bottom'}} tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } style={{marginRight:"0.5em"}} />
                <Button icon="pi pi-plus" className="p-button-outlined p-button-success" tooltip={t('actions.edit')} tooltipOptions={{position: 'bottom'}} onClick={() => {
                    setSelectedWebSite(rowData);
                    setShowAddSiteDefinition(true);
                }}/>
            </div>;
    };

    const changeIsActive = (rowData) => {
        dashboardService.activeOrDeactivateUndefinedWebsite(rowData.id, rowData.is_active, rowData.url).then(() => {
            // let item = items.find(item => item.id === rowData.id)
            // item.is_active = !rowData.is_active;
            let items_ = items.filter(item => item.url === rowData.url);
            items_.forEach(i=>{
                i.is_active = !i.is_active;
            });

            setItems([...items]);
            toast.current.show({severity: 'success', summary: rowData.is_active ? t('product.activated') : t('product.deactivated'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    return <React.Fragment>

        {loading ?
            <div className="p-col-12" style={{textAlign: 'center'}}>
                <Loading/>
            </div> :

            <div className="p-grid">
                <div className="p-col-12">
                    <SelectButton value={activeItems} optionLabel="label" options={options}
                                  onChange={(e) => {
                                      setActiveItems(e.value)
                                  }}
                                  style={{marginRight: '.5em', float: 'right'}}/>
                </div>
                <div className="p-col-12">
                    <DataTable value={items.filter(item => item.is_active === activeItems)}
                               selectionMode="single"
                               selection={selectedWebSite}
                               onSelectionChange={(e) =>{setSelectedWebSite(e.value)}}
                               emptyMessage={t('actions.empty_message')} paginator={true} rows={100}
                               sortField="url" sortOrder={1}
                               rowGroupMode="rowspan"
                               groupRowsBy="url">
                        <Column body={IndexTemplate} style={{width: '3em'}}/>
                        <Column field="url" body={urlTemplate} style={{width: '20em'}} header={t('scraping_request.hostname')} sortable filter={true} filterMatchMode="contains" group/>
                        <Column field="user_email" style={{width: '20em'}} header={t('user.email')} sortable filter={true} filterMatchMode="contains"/>
                        <Column field="user_name" style={{width: '15m'}} header={t('user.name')} sortable filter={true} filterMatchMode="contains"/>
                        <Column field="campaign_name" style={{width: '15em'}} header={t('campaign.campaign')} sortable filter={true} filterMatchMode="contains"/>
                        <Column field="used_base_spider" style={{width: '15em'}} header={t('campaign.competitors.base_spider')} sortable filter={true} filterMatchMode="contains"/>
                        <Column field="created_date" body={dateTemplate} header={t('billing.created_at')} sortable/>
                        <Column header={t('table_headers.action')} body={actionTemplate}/>
                    </DataTable>
                </div>
            </div>
        }
        {showAddSiteDefinition &&
            <Dialog visible={true} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setShowAddSiteDefinition(false);}}>
                <SiteDefinitionAdd selectedDefinition={{url: getHostName(selectedWebSite.url),
                                                        spider_name: selectedWebSite.used_base_spider,
                                                        country:"Universal", sector:"Generic"}}
                                   onComplete={(isCancel)=>{
                                        setShowAddSiteDefinition(false);
                                        if (!isCancel)
                                            changeIsActive(selectedWebSite);
                                   }} />
            </Dialog>
        }
    </React.Fragment>;
};
export default NewWebsites;
