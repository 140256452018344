import React from 'react';
import {AppContext} from "./HomePage";
import packageJson from '../package.json';

const AppFooter = () => {

    const {appName, companyName} = React.useContext(AppContext);

    return  (
        <div className="layout-footer">
            &copy; <>2019 - {(new Date()).getFullYear()} {companyName ? companyName : appName}</> <b>{packageJson.version}</b>
            {/*<a href={"mailto:"+supportEmail}>{supportEmail}</a>*/}
        </div>
    );
};
export default AppFooter;
