import {AuthService} from "./AuthService";

export class ErrorService {
    url = `errors/`;

    listErrors(state, start_date, end_date){
        return AuthService.apiGet(this.url, {
            params: {
                state: state,
                start_date: start_date,
                end_date: end_date
            }
        }).then(res => res.data.data);
    }
    addError(scraping_request_id, campaign_id, product_id, competitor_url, message, type){
        return AuthService.apiPost(this.url, {
            "scraping_request_id":scraping_request_id,
            "campaign_id": campaign_id,
            "product_id": product_id,
            "competitor_url": competitor_url,
            "message": message,
            "type": type
        }).then(res => res.data.data);
    }
    updateError(id, state){
        return AuthService.apiPut(this.url + id + "/", {
            "state": state,
        }).then(res => res.data.data);
    }
    deleteError(id){
        return AuthService.apiDelete(this.url + id+ "/").then(res => res.data.data);
    }

    addComment(id, message){
        return AuthService.apiPost(this.url + id + "/comments/", {
            "message": message,
        }).then(res => res.data.data);
    }
    updateComment(id, message, comment_id){
        return AuthService.apiPut(this.url + id + "/comments/update/", {
            "message": message,
            "comment_id": comment_id,
        }).then(res => res.data.data);
    }
    deleteComment(id, comment_id){
        return AuthService.apiPut(this.url + id + "/comments/delete/", {
            "comment_id": comment_id,
        }).then(res => res.data.message);
    }
}