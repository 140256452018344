import React from 'react';
import {loggedInEmail, user} from "./utils/Utils";
import {Avatar} from "primereact/avatar";

const AppInlineProfile = () =>  {

    const onClick = () => {
        window.location = "#/account"
    };

    const name = () => {
        if (!loggedInEmail() || loggedInEmail().toLowerCase() === user().email.toLowerCase())
            return user() ? user().first_name + " " + user().last_name : "";
        else {
            const profile = user().profile.find(p => p.email === loggedInEmail());
            return profile ? profile.name : "";
        }
    };

    return <div className="layout-profile">

             <button className="p-link layout-profile-link" onClick={onClick}>
                 {name() && <div className="p-text-center">
                     <Avatar  label={name().split(' ').slice(0, 2).reduce((response,word)=> response+=word.slice(0,1).toUpperCase(),'')}
                              size="large" shape="circle" style={{backgroundColor:"#F78222", color:"white"}}/>
                 </div>}
                <i className="pi pi-fw pi-user" />
                <span style={{marginLeft: '.25em', verticalAlign: 'middle'}}>{name()}</span>
             </button>
         </div>;
};
export default AppInlineProfile;
