import React, {useEffect, useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {CampaignService} from "../../service/CampaignService";
import {Button} from "primereact/button";
import WebSearchSetting from "./WebSearchSetting";
import {Panel} from "primereact/panel";
import JobSchedulingTimeSetting from "./JobSchedulingTimeSetting";
import {AppContext} from "../../HomePage";
import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {InputSwitch} from "primereact/inputswitch";

const JobSchedulingWebSearchSetting = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const [campaign, setCampaign] = useState(props.campaign);
    const [isGoogleShoppingSearch, setIsGoogleShoppingSearch] = useState(props.isGoogleShoppingSearch);
    const [loading, setLoading] = useState(false);

    const [searchCompetitors, setSearchCompetitors] =
        useState(isGoogleShoppingSearch ? campaign.settings && campaign.settings.google_shopping_search_job && campaign.settings.google_shopping_search_job.competitor_urls ? [...campaign.settings.google_shopping_search_job.competitor_urls.map(c => ({url: c}))] : [] :
        campaign.settings && campaign.settings.web_search_job && campaign.settings.web_search_job.competitor_urls ? [...campaign.settings.web_search_job.competitor_urls.map(c => ({url: c}))] : []);

    const [excludedSearchCompetitors, setExcludedSearchCompetitors] =
        useState(isGoogleShoppingSearch ? campaign.settings && campaign.settings.google_shopping_search_job && campaign.settings.google_shopping_search_job.excluded_competitor_urls ? [...campaign.settings.google_shopping_search_job.excluded_competitor_urls.map(c => ({url: c}))] : [] :
        campaign.settings && campaign.settings.web_search_job && campaign.settings.web_search_job.excluded_competitor_urls ? [...campaign.settings.web_search_job.excluded_competitor_urls.map(c => ({url: c}))] : []);

    const [campaignCompetitors, setCampaignCompetitors] = useState(null)
    const campaignService = new CampaignService();

    let web_search_job = isGoogleShoppingSearch ?
        campaign.settings && campaign.settings.google_shopping_search_job ? campaign.settings.google_shopping_search_job : {} :
        campaign.settings && campaign.settings.web_search_job ? campaign.settings.web_search_job : {}

    useEffect ( ()=>{
        if (isGoogleShoppingSearch) {
            campaign.settings.google_shopping_search_job = web_search_job
        } else {
            campaign.settings.web_search_job = web_search_job
        }

        if (campaign.id) {
            setLoading(true);
            campaignService.getCampaignCompetitors(campaign.id).then((competitors) => {
                setCampaignCompetitors(competitors);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }

    },[]);

    const isURLExcluded = (rowData) => {
        return excludedSearchCompetitors.some(item => item.url === rowData.url)
    }

    const excludeURL = (rowData) => {
        if (isURLExcluded(rowData)) {
            let filteredURLs = excludedSearchCompetitors.filter(item => item.url !== rowData.url)
            setExcludedSearchCompetitors(filteredURLs)
            web_search_job.excluded_competitor_urls = filteredURLs.map(s => s.url)
        } else {
            excludedSearchCompetitors.push({url: rowData.url})
            setExcludedSearchCompetitors([...excludedSearchCompetitors])
            web_search_job.excluded_competitor_urls = excludedSearchCompetitors.map(s => s.url)
        }
    }

    const urlTemplate = (rowData) => <a target="_blank" rel="noopener noreferrer" href={rowData.url}>{rowData.url}</a>;

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <InputSwitch checked={isURLExcluded(rowData)} onChange={() => excludeURL(rowData)}
                         tooltipOptions={{position: 'bottom'}} style={{marginRight:"0.5em"}}
                         tooltip={isURLExcluded(rowData) ? t('actions.suggest_from_host') : t('actions.not_suggest_from_host') } />
            {rowData.is_site_searching_ready && <Button type="button" icon="pi pi-check" className="p-button-rounded p-button-success p-button-text"/>}
            {!rowData.is_site_searching_ready && <Button type="button" icon="pi pi-minus" className="p-button-rounded p-button-danger p-button-text"/>}
        </React.Fragment>;
    };

    return (
        <div className="p-grid p-fluid">
            <Panel header={t('suggestion.time_settings')} style={{height:'100%'}}>
                <JobSchedulingTimeSetting job={web_search_job}/>
            </Panel>

            <p></p>

            <Panel header={t('campaign.generic_setting')} style={{height:'100%'}}>
                <WebSearchSetting webSearchSetting={web_search_job} isGoogleShoppingSearch={isGoogleShoppingSearch}/>
            </Panel>

            <p></p>
            {loading ?
                <div className="p-col-12" style={{textAlign: 'center'}}>
                    <Loading/>
                </div> :
                <Panel header={t('suggestion.competitor_settings')} style={{height:'100%'}}>
                        <div className="p-col-12 p-xl-12">
                            <DataTable value={campaignCompetitors} dataKey="url" responsive={true}
                                       sortField="url" sortOrder={1}
                                       selection={searchCompetitors}
                                       onSelectionChange={e => {
                                           web_search_job.competitor_urls = e.value.map(s => s.url);
                                           setSearchCompetitors(e.value)
                                       }}>
                                <Column selectionMode="multiple" style={{width: '3em'}}/>
                                <Column header={t('table_headers.index')} style={{width: '5em'}} body={IndexTemplate}/>
                                <Column field="url" body={urlTemplate} header={t('campaign.competitors.url')} sortable
                                       />
                                <Column body={actionTemplate} header={t('actions.search_competitors_on_website')}
                                        style={{textAlign: 'center', width: '10em'}}/>
                            </DataTable>
                        </div>
                </Panel>
            }
        </div>
    );

};
export default (JobSchedulingWebSearchSetting)
