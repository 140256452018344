import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {getKeyValue} from "../../utils/Utils";
import {SpecialFieldService} from "../../service/SpecialFieldService";

const SpecialFields = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);

    const [showSpecialFieldDeleteModal, setSpecialFieldDeleteModal] = useState(false);
    const [showSpecialFieldAdditionModal, setSpecialFieldAdditionModal] = useState(false);
    const [showSpecialFieldUpdateModal, setSpecialFieldUpdateModal] = useState(false);
    const [rowData, setRowData] = useState(null);

    const [loading, setLoading] = useState(false);

    const specialFieldService = new SpecialFieldService();

    useEffect ( ()=>{
        search();
    }, []);

    function getNewSpecialField() {
        return {name:null}
    }

    const onChange = (e) => {
        const { key, value } = getKeyValue(e);
        setRowData({...rowData, [key] : value});
    };

    const search = () => {
        setLoading(true);
        specialFieldService.getSpecialFields().then(data => {
            setItems(data);
            setLoading(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const addSpecialField = () => {
        specialFieldService.addSpecialField(rowData).then(addedSpecialField=>{
            let filteredItems = items.filter(i => i.id !== addedSpecialField.id);
            filteredItems.unshift(addedSpecialField);
            setItems(filteredItems);
            setRowData(getNewSpecialField());
            setSpecialFieldAdditionModal(false);
            toast.current.show({severity: 'success', summary: t('actions.add'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setSpecialFieldAdditionModal(false);
            setRowData(getNewSpecialField());
        });

    }

    const updateSpecialField = () => {
        specialFieldService.updateSpecialField(rowData).then(()=>{
            let filteredItems = items.filter(i => i.id !== rowData.id);
            filteredItems.unshift(rowData);
            setItems(filteredItems);
            setRowData(getNewSpecialField());
            setSpecialFieldUpdateModal(false);
            toast.current.show({severity: 'success', summary: t('actions.update'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setSpecialFieldUpdateModal(false);
            setRowData(getNewSpecialField());
        });

    }

    const deleteSpecialField = () => {
        specialFieldService.deleteSpecialField(rowData.id).then(()=>{
            let filteredItems = items.filter(i => i.id !== rowData.id);
            setItems(filteredItems);
            setRowData(getNewSpecialField());
            setSpecialFieldDeleteModal(false);
            toast.current.show({severity: 'success', summary: t('actions.remove'), detail: t('message_detail.successful')});
        }).catch(error => {
            setError(error);
            setError(null);
            setSpecialFieldDeleteModal(false);
            setRowData(getNewSpecialField());
        });

    }

    const modalYesNo = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" className="p-button-danger" onClick={deleteSpecialField} />
            <Button label={t('yes_no.no')} icon="pi pi-times" className="p-button-warning" onClick={() => {setSpecialFieldDeleteModal(false); setRowData(getNewSpecialField());}} />
        </div>
    );

    const modalSpecialFieldAddCancelButtons = (
        <div>
            <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-warning" onClick={() => {setSpecialFieldAdditionModal(false);}} />
            <Button label={t('actions.save')} icon="pi pi-plus" className="p-button-success" onClick={addSpecialField} />
        </div>
    );

    const modalSpecialFieldUpdateCancelButtons = (
        <div>
            <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-warning" onClick={() => {setSpecialFieldUpdateModal(false);}} />
            <Button label={t('actions.update')} icon="pi pi-save" className="p-button-success" onClick={updateSpecialField} />
        </div>
    );

    const modalSpecialFieldAddition = (
        <div className="p-grid alignCenter p-fluid">
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText id="name" name="name" value={rowData?.name} onChange={onChange}/>
                    <label htmlFor="name">{t('special_field.name')}</label>
                </span>
            </div>
        </div>
    );

    const actionTemplate = (rowData) => {
        return <React.Fragment>
            <div className="p-grid p-align-center">
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-1" tooltip={t('actions.edit')} tooltipOptions={{position: 'bottom'}} onClick={() => {setRowData(rowData); setSpecialFieldUpdateModal(true);}} style={{marginRight:"0.5em"}}/>
                <Button icon="pi pi-trash" className="p-button-danger" tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => {setRowData(rowData); setSpecialFieldDeleteModal(true);}}/>
            </div>
        </React.Fragment>;
    }

    return <React.Fragment>


        <Dialog header={t('dialogs.confirm_special_field_delete')} footer={modalYesNo} visible={showSpecialFieldDeleteModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setSpecialFieldDeleteModal(false); setRowData(getNewSpecialField());}}>
            {t('dialogs.confirm_special_field_delete_msg1')}
        </Dialog>

        <Dialog header={t('dialogs.special_field_addition')} footer={modalSpecialFieldAddCancelButtons} visible={showSpecialFieldAdditionModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setSpecialFieldAdditionModal(false);}}>
            {modalSpecialFieldAddition}
        </Dialog>

        <Dialog header={t('dialogs.special_field_update')} footer={modalSpecialFieldUpdateCancelButtons} visible={showSpecialFieldUpdateModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setSpecialFieldUpdateModal(false); setRowData(getNewSpecialField());}}>
            {modalSpecialFieldAddition}
        </Dialog>

        <div className="p-grid">
            <div className="p-col-1" style={{textAlign:"left"}}>
                <Button label={t('actions.add')} icon="pi pi-plus" className="p-button-success" onClick={() => {setSpecialFieldAdditionModal(true);}} />
            </div>
            <div className="p-col-11" style={{textAlign:"right"}}>
            </div>
        </div>

         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <DataTable value={items}
                        emptyMessage={t('actions.empty_message')}
                        sortField="name" sortOrder={1}
                        paginator={true} rows={100}>
                 <Column body={IndexTemplate} headerStyle={{width:'3em'}} />
                 <Column field="name" header={t('special_field.name')} />
                 <Column header={t('table_headers.action')} body={actionTemplate} headerStyle={{textAlign:'center', width:'11em'}}/>
             </DataTable>}

    </React.Fragment>;
};
export default SpecialFields;
