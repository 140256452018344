import {AuthService} from "./AuthService";

export class QueueService {
    url = `queues/`;

    createQueueItem(process_type, param){
        return AuthService.apiPost(this.url, {"process_type": process_type, "param": param}).then(res => res.data);
    }

    consumeQueue(){
        return AuthService.apiPut(this.url + 'consume_queue/', {}).then(res => res.data);
    }
}
