import React, {useEffect, useState} from "react";
import {toTitleCase, userId} from "../../utils/Utils";
import {toDateTimeString} from "../../utils/TimeUtil";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {AccountService} from "../../service/AccountService";
import {InputTextarea} from "primereact/inputtextarea";

const PriceRestMessage = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);
    const [messages, setMessages] = useState([]);


    const [loading, setLoading] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [answer, setAnswer] = useState("");

    const accountService = new AccountService();

    useEffect ( ()=>{
        listAllMessages();
    }, []);

    const listAllMessages = () => {
        setLoading(true);
        accountService.listAllMessages().then(data=>{
            setItems(data);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const listMessages = (rowData) => {
        setShowMessageModal(true);
        setRowData(rowData);
        accountService.listMessages(rowData.user_id).then(data => {
            setMessages(data);
        });
    }

    const sendAnswer = () =>{
        accountService.sendMessage(rowData.user_id, answer).then(result=>{
            setMessages([...messages, result])
            toast.current.show({severity: 'success', summary:t('contact.message_sent')});
            setAnswer("")
        });
    };

    const hideMessageModal = () => {
        setShowMessageModal(false);
        setRowData(null);
        setMessages([]);
    }

    const dateTemplate = (rowData) => <span>{toDateTimeString(rowData.created_at)}</span>;

    const actionTemplate = (rowData) => {
        return <div className="p-grid p-align-center"><Button icon="pi pi-send" className="p-button-success" label={t('actions.send_answer')} onClick={() => {listMessages(rowData);}}/></div>;
    }

    return <React.Fragment>


        <Dialog header={t('menu_item.message') + ' - ' + rowData?.user_full_name} visible={showMessageModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={hideMessageModal}>
            <div className="p-grid" style={{width:'28em'}}>

                <div className="speech-wrapper" style={{backgroundImage: "url(/assets/layout/images/message_back.jpg)"}} >
                    {messages.map(c=>{
                        const alt = c.sender_id === userId() ? " alt" : ""
                        return <div className="p-grid p-col-12">
                            {alt !== "" && <div className="p-col-fixed" style={{width:'5em'}}/>}
                            <div key={c.id} className={"p-col bubble" + alt}>
                                <div className={"txt" + alt}>
                                    <p className={"name" + alt}>{toTitleCase(c.sender_full_name)}</p>
                                    <div className={"message" + alt}> {c.message}</div>
                                    <div className={"timestamp" + alt}>{toDateTimeString(c.created_at)}</div>
                                </div>
                                <div className={"bubble-arrow" + alt}/>
                            </div>
                            {alt === "" && <div className="p-col-fixed" style={{width:'5em'}}/>}
                        </div>;
                    })}
                </div>

                <div className="p-col-12">
                    <InputTextarea rows={5} cols={50} value={answer} onChange={(e) => setAnswer(e.target.value)} placeholder={t('contact.answer_question')}/>
                </div>
                <div className="p-col-12" style={{textAlign:'right'}}>
                    <Button label={t('contact.send')} icon="pi pi-envelope" className="p-button-success" onClick={sendAnswer}/>
                </div>
            </div>
        </Dialog>

         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <DataTable value={items}
                        emptyMessage={t('actions.empty_message')}
                        paginator={true} rows={100}>
                 <Column body={IndexTemplate} style={{width:'3em'}} />
                 <Column field="user_full_name" header={t('user.name')} />
                 <Column field="message" header={t('menu_item.message')} />
                 <Column field="created_at" body={dateTemplate} header={t('billing.created_at')} />
                 <Column header={t('table_headers.action')} body={actionTemplate} style={{textAlign:'center', width:'15em'}}/>
             </DataTable>}

    </React.Fragment>;
};
export default PriceRestMessage;
