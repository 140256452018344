import React, {useEffect, useState} from "react";

import {
    exportFile,
    formatCurrency,
    getKeyValue,
    getProductDetailLink,
    isCampaignMarketSearch
} from "../../utils/Utils";
import {toDateString} from "../../utils/TimeUtil";


import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ExportService} from "../../service/ExportService";
import {Dropdown} from "primereact/dropdown";
import {CampaignContext} from "../../App";
import {MultiSelect} from "primereact/multiselect";
import {Checkbox} from "primereact/checkbox";

const PriceComparisonSummary = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const {campaigns, selectedCampaign, loadCampaigns} = React.useContext(CampaignContext);

    const [otherCampaigns, setOtherCampaigns] = useState([]);
    const [selectedOtherCampaigns, setSelectedOtherCampaigns] = useState([])

    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);

    const [includeShippingPrices, setIncludeShippingPrices] = useState(false)

    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);

    const [brand, setBrand] = useState("");
    const [category, setCategory] = useState(null);
    const [product_name, setProductName] = useState("");

    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect ( ()=>{
        let otherCampaigns = campaigns.filter(item => item.id !== selectedCampaign.id).sort((a, b) => (a.name ? a.name.toLowerCase() : "").localeCompare((b.name ? b.name.toLowerCase() : ""), undefined, {sensitivity: 'base'}))
        setOtherCampaigns(otherCampaigns.map(otherCampaign => ({label: otherCampaign.name, value: otherCampaign.id})))
        search();
    },[selectedCampaign]);

    useEffect(() =>{
        setFilteredItems( items.filter(item=> (
            (product_name==="" || (item.name && item.name.toLowerCase().indexOf(product_name.toLowerCase()) !== -1)) &&
            (brand==="" || brand == null || (item.brand && item.brand.toLowerCase().indexOf(brand.toLowerCase()) !== -1)) &&
            (category==="" || category == null || (item.category && item.category.toLowerCase().indexOf(category.toLowerCase()) !== -1))
        )));
    }, [items, product_name, brand, category]);

    const search = () => {
        if (selectedCampaign) {
            setLoading(true);
            dashboardService.getPriceComparisonSummary(selectedCampaign.id, selectedOtherCampaigns, includeShippingPrices).then(data=>{
                setItems(data);
                let distBrands = [...new Set(data.map(allProducts => allProducts.brand ? allProducts.brand : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                distBrands = distBrands.filter(brand => brand !== undefined && brand !== '')
                setBrands(distBrands.map(brand => ({label: brand, value: brand})))
                let distCategories = [...new Set(data.map(allProducts => allProducts.category ? allProducts.category : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                distCategories = distCategories.filter(category => category !== undefined && category !== '')
                setCategories(distCategories.map(category => ({label: category, value: category})))
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const onExport = () => {
        setLoadingExcelExport(true);
        exportService.exportPriceComparisonSummary(selectedCampaign.id, selectedOtherCampaigns, includeShippingPrices).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.price_comparison_summary') + "_" + toDateString(new Date()) + ".xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const nameTemplate = (rowData) => {
        return getProductDetailLink(rowData.id, rowData.name);
    };

    const ownPriceTemplate = (rowData) => {
        return rowData.price ? <div className="p-grid p-justify-end">
            <a target="_blank" rel="noopener noreferrer" href={rowData.product_url} style={{color: rowData.competitorsMinPrice >= rowData.price ? 'green' : rowData.competitorsMaxPrice <= rowData.price ? 'red' : 'orange'}}>
                {formatCurrency(rowData.price, rowData.currency)}
            </a>
        </div> : '';
    };

    const sellerMinPriceTemplate = (rowData) => {
        return rowData.competitorsMinPrice ? <div className="p-grid p-justify-end">
            <a target="_blank" rel="noopener noreferrer" href={rowData.competitorsMinUrl}>
                {formatCurrency(rowData.competitorsMinPrice, rowData.currency)}
            </a>
        </div> : '';
    };

    const sellerAvgPriceTemplate = (rowData) => {
        return rowData.competitorsAvgPrice ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.competitorsAvgPrice, rowData.currency)}
        </div> : '';
    };

    const sellerMaxPriceTemplate = (rowData) => {
        return rowData.competitorsMaxPrice ? <div className="p-grid p-justify-end">
            <a target="_blank" rel="noopener noreferrer" href={rowData.competitorsMaxUrl}>
                {formatCurrency(rowData.competitorsMaxPrice, rowData.currency)}
            </a>
        </div> : '';
    };

    const priceDifferenceTemplate = (rowData) => {
        return rowData.price_difference ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.price_difference)}
        </div> : '';
    };

    const otherCampaignMinPriceTemplate = (rowData, props) => {
        return rowData[props.field] ? <div className="p-grid p-justify-end">
            <a target="_blank" rel="noopener noreferrer" href={rowData[props.field].product.competitorsMinUrl}>
                {formatCurrency(rowData[props.field].product.competitorsMinPrice, rowData[props.field].product.currency)}
            </a>
        </div> : '';
    };

    const otherCampaignMaxPriceTemplate = (rowData, props) => {
        return rowData[props.field] ? <div className="p-grid p-justify-end">
            <a target="_blank" rel="noopener noreferrer" href={rowData[props.field].product.competitorsMaxUrl}>
                {formatCurrency(rowData[props.field].product.competitorsMaxPrice, rowData[props.field].product.currency)}
            </a>
        </div> : '';
    };

    const dynamicColumns = () => {
        let columns = []
        columns.push(<Column key="product_code" field="product_code" header={t('product.product_code')} headerStyle={{width: '200px'}}/>);
        columns.push(<Column key="brand" field="brand" header={t('product.brand')} headerStyle={{width: '150px'}}/>);
        columns.push(<Column key="category" field="category" header={t('product.category')} headerStyle={{width: '150px'}}/>);
        columns.push(<Column key="name" field="name" body={nameTemplate} header={t('product.product_name')} headerStyle={{width: '300px'}} sortable/>);
        columns.push(<Column key="competitorsCount" field="competitorsCount" header={t('product_detail.seller_number')} headerStyle={{width: '70px', textAlign:"center"}} style={{textAlign:'center'}} sortable/>);
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="position" field="position" header={t('product_detail.position')} headerStyle={{width: '50px', textAlign:"center"}} style={{textAlign:'center'}} sortable/>);}
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="price" field="price" body={ownPriceTemplate} header={t('product_detail.own_price')} headerStyle={{width: '150px', textAlign:"right"}}/>);}
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="price_difference" field="price_difference" body={priceDifferenceTemplate} header={t('product.difference')} headerStyle={{width: '150px', textAlign:"right"}}/>);}
        columns.push(<Column key="competitorsMinName" field="competitorsMinName" header={t('product.comp_min_name')} headerStyle={{width: '200px'}}/>);
        columns.push(<Column key="competitorsMinPrice" field="competitorsMinPrice" body={sellerMinPriceTemplate} header={t('product.comp_min')} headerStyle={{width: '150px', textAlign:"right"}}/>);
        columns.push(<Column key="competitorsAvgPrice" field="competitorsAvgPrice" body={sellerAvgPriceTemplate} header={t('product.comp_avg')} headerStyle={{width: '150px', textAlign:"right"}}/>);
        columns.push(<Column key="competitorsMinPrice" field="competitorsMinPrice" body={sellerMaxPriceTemplate} header={t('product.comp_max')} headerStyle={{width: '150px', textAlign:"right"}}/>);
        columns.push(<Column key="competitorsMaxName" field="competitorsMaxName" header={t('product.comp_max_name')} headerStyle={{width: '200px'}}/>);
        selectedOtherCampaigns.forEach((selectedOtherCampaign,i) => {columns.push(<Column key={selectedOtherCampaign} field={selectedOtherCampaign} body={otherCampaignMinPriceTemplate} header={otherCampaigns.filter(otherCampaign => otherCampaign.value === selectedOtherCampaign)[0].label + " - Min"} headerStyle={{width: '150px',textAlign:"right"}}/>)});
        selectedOtherCampaigns.forEach((selectedOtherCampaign,i) => {columns.push(<Column key={selectedOtherCampaign} field={selectedOtherCampaign} body={otherCampaignMaxPriceTemplate} header={otherCampaigns.filter(otherCampaign => otherCampaign.value === selectedOtherCampaign)[0].label + " - Max"} headerStyle={{width: '150px',textAlign:"right"}}/>)});
        return columns.map((col, i) => {return col});
    };

    const headerTemplate = <div className="p-grid p-fluid">
        <div className="p-col-4">
                    <span className="p-float-label">
                        <InputText id="product_name" value={product_name} onChange={(e) => {setProductName(getKeyValue(e).value)}}/>
                        <label htmlFor="product_name">{t('product.product_name')}</label>
                    </span>
        </div>
        <div className="p-col-4">
                    <span className="p-float-label">
                        <Dropdown name="brand" value={brand} options={brands} onChange={(e) => {setBrand(getKeyValue(e).value)}} filter showClear />
                        <label htmlFor="brand">{t('product.brand')}</label>
                    </span>
        </div>
        <div className="p-col-4">
                    <span className="p-float-label">
                        <Dropdown name="category" value={category} options={categories} onChange={(e) => {setCategory(getKeyValue(e).value)}} filter showClear />
                        <label htmlFor="category">{t('product.category')}</label>
                    </span>
        </div>
    </div>;

    return <React.Fragment>


        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.price_comparison_summary')}</h3>
                <hr></hr>
            </div>

            {loading ?
                <div className="p-col-12" style={{textAlign:'center'}}>
                    <Loading/>
                </div> :
                <div className="p-col-12 p-grid p-fluid">
                    { otherCampaigns.length > 0 &&
                        <div className="p-col-3">
                            <span className="p-float-label">
                                <MultiSelect name="other_campaigns" value={selectedOtherCampaigns}
                                             options={otherCampaigns}
                                             onChange={(e) => {setSelectedOtherCampaigns(e.value)}}/>
                                <label htmlFor="other_campaigns">{t('campaign.other_campaigns')}</label>
                            </span>
                        </div>
                    }
                    <div className="p-col-2 p-mt-3">
                        <Checkbox inputId="include_shipping_prices" name="include_shipping_prices"
                                  style={{marginLeft: "0.5em"}}
                                  value={includeShippingPrices}
                                  checked={includeShippingPrices}
                                  onChange={(e)=> {setIncludeShippingPrices(getKeyValue(e).value);}}
                        />
                        <label htmlFor="include_shipping_prices" className="p-ml-2 p-mr-1">{t('campaign.include_shipping_prices')}</label>
                    </div>
                    <div className="p-col-2 p-mt-2">
                        <Button icon="pi pi-search" className="p-button-outlined p-button-info" label={t('actions.search')} onClick={search} loading={loadingExcelExport} />
                    </div>
                    <div className="p-col-3 p-mt-2">
                        <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
                    </div>
                    <div className="p-col-12">
                        <DataTable value={filteredItems} scrollable scrollHeight={(window.innerHeight - 430)+"px"}
                                   emptyMessage={t('actions.empty_message')}
                                   paginator={true}
                                   rows={100}
                                   first={first}
                                   rowsPerPageOptions={[10, 20, 50, 100]}
                                   onPage={(e) => {
                                       setFirst(e.first);
                                       setRows(e.rows);
                                   }}
                                   header={headerTemplate}>
                            {dynamicColumns()}
                        </DataTable>
                    </div>
                </div>
                }
        </div>

    </React.Fragment>;
};
export default PriceComparisonSummary;
