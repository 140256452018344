import {formValid, getHostName, getKeyValue, isAdmin, isCampaignManufacturer, subscription, urlValidator, user} from "../../utils/Utils";
import {IndexTemplate} from "../../utils/InlineComponents";
import ScrapeStatus from "../Product/ScrapeStatus";
import {RadioButton} from "primereact/radiobutton";
import {InputNumber} from 'primereact/inputnumber';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useEffect, useRef, useState} from "react";
import {CampaignService} from "../../service/CampaignService";
import {Message} from "primereact/message";
import {ProductService} from "../../service/ProductService";
import {ScraperService} from "../../service/ScraperService";
import {AppContext} from "../../HomePage";
import WebSearchSetting from "../Admin/WebSearchSetting";
import {CleanupService} from "../../service/CleanupService";
import {Dialog} from "primereact/dialog";
import {Loading} from "../../utils/InlineComponents";
import {CampaignContext} from "../../App";
import {Dropdown} from "primereact/dropdown";
import CopyToClipboard from "../../utils/CopyToClipboard";
import {toDateTimeString} from "../../utils/TimeUtil";
import {Checkbox} from "primereact/checkbox";
import {ToggleButton} from "primereact/togglebutton";
import {InputTextarea} from "primereact/inputtextarea";
import {OverlayPanel} from "primereact/overlaypanel";
import {TabMenu} from "primereact/tabmenu";
import {InputSwitch} from "primereact/inputswitch";
import {Editor} from "primereact/editor";
import SiteDefinitionAdd from "../Admin/SiteDefinitionAdd";
import ProductMatching from "./ProductMatching";
import {ProductMatchingService} from "../../service/ProductMatchingService";
import {ProductSuggestionService} from "../../service/ProductSuggestionService";

const CampaignCompetitor = (props) => {

    const {t, toast, setError, supportEmail} = React.useContext(AppContext);
    const {campaigns, selectedCampaign, loadCampaigns} = React.useContext(CampaignContext);

    const op = useRef(null);
    const opSeller = useRef(null);

    const campaignService = new CampaignService();
    const productService = new ProductService();
    const productMatchingService = new ProductMatchingService()
    const scraperService = new ScraperService();
    const cleanupService = new CleanupService();
    const productSuggestionService = new ProductSuggestionService();

    const [campaignTo, setCampaignTo] = useState(null);
    const [suggestionResults, setSuggestionResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMarketPlaceSellers, setShowMarketPlaceSellers] = useState(false);
    const [loadingCompetitorSuggestions, setLoadingCompetitorSuggestions] = useState(false);

    const [match_method, setMatchMethod] = useState("");
    const [min_match_score, setMinMatchScore] = useState(70);

    const initialCompetitor = {url:"", is_authorized:false, is_notified:false, email: "", phone: "", address: "", note: "", shipping_price: null, shipping_price_threshold: null};

    const [competitor, setCompetitor] = useState({...initialCompetitor});
    const [seller, setSeller] = useState(null);
    const [formErrors, setFormErrors] = useState({url:"", is_authorized:"", is_notified:"", email: "", phone: "", address: "", note: "", shipping_price: null, shipping_price_threshold: null});

    const [searchCompetitors, setSearchCompetitors] = useState(selectedCampaign.settings && selectedCampaign.settings.competitor_suggestion && selectedCampaign.settings.competitor_suggestion.competitor_urls ? [...selectedCampaign.settings.competitor_suggestion.competitor_urls.map(c => ({url: c}))] : []);
    const [excludedSearchCompetitors, setExcludedSearchCompetitors] = useState(selectedCampaign.settings && selectedCampaign.settings.competitor_suggestion && selectedCampaign.settings.competitor_suggestion.excluded_competitor_urls ? [...selectedCampaign.settings.competitor_suggestion.excluded_competitor_urls.map(c => ({url: c}))] : []);

    const [showCompetitorSuggestionClearModal, setShowCompetitorSuggestionClearModal] = useState(false);
    const [showCompetitorSuggestionUsedBaseSpiderClearModal, setShowCompetitorSuggestionUsedBaseSpiderClearModal] = useState(false);
    const [showNewCompetitorSuggestionClearModal, setShowNewCompetitorSuggestionClearModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    let competitor_suggestion = selectedCampaign.settings && selectedCampaign.settings.competitor_suggestion ? selectedCampaign.settings.competitor_suggestion : {}

    const [showAddSiteDefinition, setShowAddSiteDefinition] = useState(false);
    const [selectedWebSite, setSelectedWebSite] = useState(null);

    useEffect ( ()=>{
    },[]);

    useEffect ( ()=>{
        setLoading(true);
        campaignService.getCampaignCompetitors(selectedCampaign.id).then(competitors => {
            selectedCampaign.competitors = competitors
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
        setLoadingCompetitorSuggestions(true)
        campaignService.getCampaignCompetitorSuggestions(selectedCampaign.id).then(campaign => {
            selectedCampaign.competitor_suggestions = campaign.competitor_suggestions
            setLoadingCompetitorSuggestions(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingCompetitorSuggestions(false);
        });

        productSuggestionService.getStatistics(selectedCampaign.id).then(suggestionResults => {
            setSuggestionResults(suggestionResults)
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    },[selectedCampaign]);

    const onChange = (e) =>{

        const { key, value } = getKeyValue(e);

        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setCompetitor({...competitor, [key]: value});

    };

    const onChangeSeller = (e) =>{

        const { key, value } = getKeyValue(e);

        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setSeller({...seller, [key]: value});

    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0
    };

    const checkError = (key, value) =>{
        let errorText = "";

        if (key === "url") {
            if (value === "")
                errorText = t('validations.invalid_value');
            else
            {
                const urls = value.trim().split("||");

                for (let i = 0; i < urls.length; i++) {
                    if (urls[i] !== "") {
                        errorText = urlValidator(urls[i].trim(), t);
                        if (errorText !== "") {
                            break;
                        }
                    }
                }
            }
        }

        return errorText;
    };

    const checkErrors = async (competitor) => {

        let errors = { ...formErrors };

        Object.entries(competitor).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        return errors;
    };

    const addCompetitor = () => {

        checkErrors(competitor).then(formErrors=> {

            if (formValid(formErrors)) {

                let campaign_competitors = [];

                competitor.url.split("||").forEach(url => {
                    url = url.trim();
                    if (url)
                    {
                        if (selectedCampaign.competitors.some(competitor => competitor.url.toLowerCase() === url.toLowerCase()))
                            toast.current.show({severity: 'warn', summary: t('campaign.competitors.duplicate'), detail: t('campaign.competitors.duplicate_detail')});
                        else
                            campaign_competitors.push({url: url});
                    }
                });
                if (campaign_competitors.length !== 0)
                {
                    addCampaignCompetitor(campaign_competitors);
                }
            }
            else
                setFormErrors(formErrors);
        });
    };

    const updateCompetitor = () => {
        let compToSave = {...competitor};
        delete compToSave.is_headless_browser_used;
        delete compToSave.is_spider_ready;
        delete compToSave.is_site_searching_ready;

        campaignService.updateCampaignCompetitor(selectedCampaign.id, compToSave).then(() => {
            selectedCampaign.competitors.splice(selectedCampaign.competitors.findIndex(c=> c.url === compToSave.url),1);
            selectedCampaign.competitors.unshift(competitor);
            props.updateCampaignList(selectedCampaign);
            setCompetitor({...initialCompetitor});
            op.current.hide();
            toast.current.show({severity: 'success', summary: t('campaign.competitors.update'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const updateSeller = () => {
        let compToSave = {...competitor};
        let sellerToSave = {...seller};
        delete compToSave.is_headless_browser_used;
        delete compToSave.is_spider_ready;
        delete compToSave.is_site_searching_ready;

        compToSave.sellers.splice(compToSave.sellers.findIndex(c=> c.seller_name === sellerToSave.seller_name),1);
        compToSave.sellers.unshift(sellerToSave);
        compToSave.sellers.sort((a,b) => a.seller_name.toLowerCase() > b.seller_name.toLowerCase() ? 1 : -1)

        campaignService.updateCampaignCompetitor(selectedCampaign.id, compToSave).then(() => {
            selectedCampaign.competitors.splice(selectedCampaign.competitors.findIndex(c=> c.url === compToSave.url),1);
            selectedCampaign.competitors.unshift(competitor);
            props.updateCampaignList(selectedCampaign);
            setSeller(null);
            opSeller.current.hide();
            toast.current.show({severity: 'success', summary: t('campaign.competitors.update'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const activateOrDeactivateSeller = (rowData) => {
        let compToSave = {...competitor};
        let sellerToSave = {...rowData};
        delete compToSave.is_headless_browser_used;
        delete compToSave.is_spider_ready;
        delete compToSave.is_site_searching_ready;

        sellerToSave.is_active = !sellerToSave.is_active;

        compToSave.sellers.splice(compToSave.sellers.findIndex(c=> c.seller_name === sellerToSave.seller_name),1);
        compToSave.sellers.unshift(sellerToSave);
        compToSave.sellers.sort((a,b) => a.seller_name.toLowerCase() > b.seller_name.toLowerCase() ? 1 : -1)

        cleanupService.activateOrDeactivateCompetitors(selectedCampaign.id, sellerToSave.seller_name, sellerToSave.is_active).then(() => {
            campaignService.updateCampaignCompetitor(selectedCampaign.id, compToSave).then(() => {
                selectedCampaign.competitors.splice(selectedCampaign.competitors.findIndex(c=> c.url === compToSave.url),1);
                selectedCampaign.competitors.unshift(competitor);
                props.updateCampaignList(selectedCampaign);
                setSeller(null);
                toast.current.show({severity: 'success', summary: t('campaign.competitors.update'), detail: t('message_detail.successful')});
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const isURLExcluded = (rowData) => {
        return excludedSearchCompetitors.some(item => item.url === rowData.url)
    }

    const excludeURL = (rowData) => {
        if (isURLExcluded(rowData)) {
            setExcludedSearchCompetitors(excludedSearchCompetitors.filter(item => item.url !== rowData.url))
        } else {
            excludedSearchCompetitors.push({url: rowData.url})
            setExcludedSearchCompetitors([...excludedSearchCompetitors])
        }
    }

    const activateOrDeactivateSellerURL = (rowData) => {
        let compToSave = {...rowData};
        delete compToSave.is_headless_browser_used;
        delete compToSave.is_spider_ready;
        delete compToSave.is_site_searching_ready;
        compToSave.is_active = !compToSave.is_active;
        compToSave.sellers?.forEach(item => item.is_active = compToSave.is_active)

        cleanupService.activateOrDeactivateCampaignCompetitor(selectedCampaign.id, compToSave.url, compToSave.is_active).then(() => {
            campaignService.updateCampaignCompetitor(selectedCampaign.id, compToSave).then(() => {
                selectedCampaign.competitors.splice(selectedCampaign.competitors.findIndex(c=> c.url === compToSave.url),1);
                rowData.is_active = !rowData.is_active
                selectedCampaign.competitors.unshift(rowData);
                props.updateCampaignList(selectedCampaign);
                setCompetitor({...initialCompetitor});
                op.current.hide();
                opSeller.current.hide();
                toast.current.show({severity: 'success', summary: t('campaign.competitors.update'), detail: t('message_detail.successful')});
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const changeIsAuthorized = (rowData) => {
        let compToSave = {...rowData};
        delete compToSave.is_headless_browser_used;
        delete compToSave.is_spider_ready;
        delete compToSave.is_site_searching_ready;
        compToSave.is_authorized = !compToSave.is_authorized;
        compToSave.sellers.forEach(item => item.is_authorized = compToSave.is_authorized)

        campaignService.updateCampaignCompetitor(selectedCampaign.id, compToSave).then(() => {
            selectedCampaign.competitors.splice(selectedCampaign.competitors.findIndex(c=> c.url === compToSave.url),1);
            rowData.is_authorized = !rowData.is_authorized
            selectedCampaign.competitors.unshift(rowData);
            props.updateCampaignList(selectedCampaign);
            setCompetitor({...initialCompetitor});
            op.current.hide();
            opSeller.current.hide();
            toast.current.show({severity: 'success', summary: t('campaign.competitors.update'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const changeIsNotified = (rowData) => {
        let compToSave = {...rowData};
        delete compToSave.is_headless_browser_used;
        delete compToSave.is_spider_ready;
        delete compToSave.is_site_searching_ready;
        compToSave.is_notified = !compToSave.is_notified;
        compToSave.sellers.forEach(item => {
            item.is_notified = compToSave.is_notified
        })

        campaignService.updateCampaignCompetitor(selectedCampaign.id, compToSave).then(() => {
            selectedCampaign.competitors.splice(selectedCampaign.competitors.findIndex(c=> c.url === compToSave.url),1);
            rowData.is_notified = !rowData.is_notified
            selectedCampaign.competitors.unshift(rowData);
            props.updateCampaignList(selectedCampaign);
            setCompetitor({...initialCompetitor});
            op.current.hide();
            opSeller.current.hide();
            toast.current.show({severity: 'success', summary: t('campaign.competitors.update'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const changeIsAuthorizedSeller = (rowData) => {
        let sellerToSave = {...rowData};
        let compToSave = {...competitor};
        sellerToSave.is_authorized = !sellerToSave.is_authorized;
        delete compToSave.is_headless_browser_used;
        delete compToSave.is_spider_ready;
        delete compToSave.is_site_searching_ready;
        compToSave.is_authorized = false;

        compToSave.sellers.splice(compToSave.sellers.findIndex(c=> c.seller_name === sellerToSave.seller_name),1);
        compToSave.sellers.unshift(sellerToSave);
        compToSave.sellers.sort((a,b) => a.seller_name.toLowerCase() > b.seller_name.toLowerCase() ? 1 : -1)

        campaignService.updateCampaignCompetitor(selectedCampaign.id, compToSave).then(() => {

            selectedCampaign.competitors.splice(selectedCampaign.competitors.findIndex(c=> c.url === compToSave.url),1);
            selectedCampaign.competitors.unshift(compToSave);
            props.updateCampaignList(selectedCampaign);
            setCompetitor({...compToSave});
            toast.current.show({severity: 'success', summary: t('campaign.competitors.update'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const changeIsNotifiedSeller = (rowData) => {
        let sellerToSave = {...rowData};
        let compToSave = {...competitor};
        sellerToSave.is_notified = !sellerToSave.is_notified;
        delete compToSave.is_headless_browser_used;
        delete compToSave.is_spider_ready;
        delete compToSave.is_site_searching_ready;
        compToSave.is_notified = false;

        compToSave.sellers.splice(compToSave.sellers.findIndex(c=> c.seller_name === sellerToSave.seller_name),1);
        compToSave.sellers.unshift(sellerToSave);
        compToSave.sellers.sort((a,b) => a.seller_name.toLowerCase() > b.seller_name.toLowerCase() ? 1 : -1)

        campaignService.updateCampaignCompetitor(selectedCampaign.id, compToSave).then(() => {

            selectedCampaign.competitors.splice(selectedCampaign.competitors.findIndex(c=> c.url === compToSave.url),1);
            selectedCampaign.competitors.unshift(compToSave);
            props.updateCampaignList(selectedCampaign);
            setCompetitor({...compToSave});
            toast.current.show({severity: 'success', summary: t('campaign.competitors.update'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };



    const deleteCompetitor = (rowData) => {

        campaignService.deleteCampaignCompetitor(selectedCampaign.id, rowData).then(() => {
            selectedCampaign.competitors.splice(selectedCampaign.competitors.findIndex(c=> c.url === rowData.url),1);
            setCompetitor({...initialCompetitor});
            props.updateCampaignList(selectedCampaign);
            toast.current.show({severity: 'success', summary: t('campaign.competitors.delete'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    function addCampaignCompetitor(campaign_competitors) {
        campaignService.addCampaignCompetitor(selectedCampaign.id, campaign_competitors).then(() => {

            campaign_competitors.forEach(competitor => selectedCampaign.competitors.unshift(competitor));
            setCompetitor({...initialCompetitor});

            props.updateCampaignList(selectedCampaign);

            toast.current.show({
                severity: 'success',
                summary: t('campaign.competitors.add'),
                detail: t('message_detail.successful') + " "
            });
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    function getCampaignCompetitors() {
        let selectedURLs = selectedCampaign.competitors.map(item => item.url)
        return selectedURLs.join("\n")
    }

    function getCampaignCompetitorSuggestions() {
        let selectedURLs = selectedCampaign.competitor_suggestions.map(item => item.url)
        return selectedURLs.join("\n")
    }

    function copyCampaignCompetitors() {
        campaignService.copyCampaignCompetitors(selectedCampaign.id, campaignTo).then(() => {
            toast.current.show({
                severity: 'success',
                summary: t('actions.copy_competitor_urls'),
                detail: t('message_detail.successful') + " "
            });
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const addCompetitorSuggestion = (rowData) => {
        let campaign_competitors = [{url: rowData['url']}];
        addCampaignCompetitor(campaign_competitors);
        campaignService.deleteCampaignCompetitorSuggestion(selectedCampaign.id, rowData).then(() => {
            selectedCampaign.competitor_suggestions.splice(selectedCampaign.competitor_suggestions.findIndex(c=> c.url === rowData.url),1);
            setCompetitor({...initialCompetitor});
            props.updateCampaignList(selectedCampaign);
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const deleteCompetitorSuggestion = (rowData) => {
        campaignService.deleteCampaignCompetitorSuggestion(selectedCampaign.id, rowData).then(() => {
            selectedCampaign.competitor_suggestions.splice(selectedCampaign.competitor_suggestions.findIndex(c=> c.url === rowData.url),1);
            setCompetitor({...initialCompetitor});
            toast.current.show({severity: 'success', summary: t('campaign.competitor.delete'), detail: t('message_detail.successful')});
            props.updateCampaignList(selectedCampaign);
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const saveSettings = () => {
        competitor_suggestion.competitor_urls = searchCompetitors.map(s => s.url)
        competitor_suggestion.excluded_competitor_urls = excludedSearchCompetitors.map(s => s.url)
        selectedCampaign.settings.competitor_suggestion = competitor_suggestion
        campaignService.saveCampaignSettings(selectedCampaign.id, selectedCampaign.use_sale_value, selectedCampaign.settings).then(() => {
            props.updateCampaignList(selectedCampaign);
            toast.current.show({severity: 'success', summary: t('campaign.save_settings'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    };
    const autoMatchAllSuggestions = () => {

        if (match_method !== "sku" && match_method !== "mpn" && match_method !== "ean" &&
            match_method !== "upc" && match_method !== "asin" && match_method !== "barcode" &&
            match_method !== "name_exact" && match_method !== "name_in" && match_method !== "match_score" )
        {
            toast.current.show({severity: 'error', summary: t('suggestion.select_match_method'), detail: ""});
            return;
        }

        productService.autoMatchAllSuggestions(selectedCampaign.id, match_method, min_match_score).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.auto_match_suggestions'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const matchCompetitorsUsingOtherCodes = () =>{
        productMatchingService.matchCompetitorsUsingOtherCodes(selectedCampaign.id).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.auto_match_suggestions'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const urlTemplate = (rowData) => <a target="_blank" rel="noopener noreferrer" href={rowData.url}>{rowData.url}</a>;

    const sellersTemplate = (rowData) => {
        return <React.Fragment>
            {rowData?.sellers?.length > 0 &&
            <Button icon="pi pi-arrow-right" className="p-button-danger" label={t('campaign.competitor')}
                    onClick={(e) => {
                        setCompetitor(rowData);
                        rowData.sellers.sort((a,b) => a.seller_name.toLowerCase() > b.seller_name.toLowerCase() ? 1 : -1)
                        setShowMarketPlaceSellers(true)
                    }} />
            }
        </React.Fragment>
    };

    const baseSpiderTemplate = (rowData) => {
        return !rowData.is_spider_ready ? <Button label={rowData.used_base_spider ?? t("actions.add")} onClick={() => {
            setSelectedWebSite(rowData);
            setShowAddSiteDefinition(true);
        }} /> : "";
    }

    const isAuthorizedTemplate = (rowData) => {
        return <Checkbox name="is_authorized" value={rowData.is_authorized} checked={rowData.is_authorized === true} onChange={() => {changeIsAuthorized(rowData)}} />;
    }
    const isNotifiedTemplate = (rowData) => {
        return <Checkbox name="is_notified" value={rowData.is_notified} checked={rowData.is_notified === true} onChange={() => {changeIsNotified(rowData)}} />;
    }
    const isSellerAuthorizedTemplate = (rowData) => {
        return <Checkbox name="is_authorized_seller" value={rowData.is_authorized} checked={rowData.is_authorized === true} onChange={() => {changeIsAuthorizedSeller(rowData)}} />;
    }
    const isSellerNotifiedTemplate = (rowData) => {
        return <Checkbox name="is_notified_seller" value={rowData.is_notified} checked={rowData.is_notified === true} onChange={() => {changeIsNotifiedSeller(rowData)}} />;
    }
    const updateHostBasedPrices = (rowData) => {
        let urls = [];
        urls.push(getHostName(rowData.url));
        return {
        campaign_id: selectedCampaign.id,
        urls: urls,
        type: 'prices_host_based_update_run'};
    };

    const updateSuggestions = (rowData) => {
        let urls = [];
        urls.push(getHostName(rowData.url));
        return {
            campaign_id: selectedCampaign.id,
            urls: urls,
            type: 'suggestions_update'};
    };

    const updateReviews = (rowData) => {
        let urls = [];
        urls.push(getHostName(rowData.url));
        return {
            spider_name: "products_update_review",
            fields: ["reviews"],
            campaign_id: selectedCampaign.id,
            urls: urls,
            type: 'products_update_partial'};
    };

    const updateFailedReviews = (rowData) => {
        let urls = [];
        urls.push(getHostName(rowData.url));
        return {
            spider_name: "products_update_failed_review",
            fields: ["reviews"],
            campaign_id: selectedCampaign.id,
            urls: urls,
            type: 'products_update_partial'};
    };

    const actionTemplate = (rowData) => {
        return <div className="p-grid">
            <div className="p-col-12">
                <Button icon="pi pi-trash" className="p-button-outlined p-button-danger p-mr-1" tooltip={t('actions.remove_url')} tooltipOptions={{position: 'bottom'}} onClick={() => deleteCompetitor(rowData)} />
            </div>
            <div className="p-col-12">
                <InputSwitch checked={isURLExcluded(rowData)} onChange={() => excludeURL(rowData)}
                     tooltipOptions={{position: 'bottom'}} className="p-mr-1"
                     tooltip={isURLExcluded(rowData) ? t('actions.suggest_from_host') : t('actions.not_suggest_from_host') } />
            </div>
        </div>;
    };

    const scrapeTemplate = (rowData) => {
        return <div className="p-grid p-col-12">
                <div className="p-col-4">
                    <ScrapeStatus type="price" scraping_request={null}
                                  campaign_id={selectedCampaign.id}
                                  product_id={null}
                                  competitor_url={null}
                                  newRequest={updateHostBasedPrices(rowData)}
                    />
                </div>
                <div className="p-col-4">
                    <ScrapeStatus type="price" scraping_request={null}
                                  text="Update Suggestion Prices"
                                  campaign_id={selectedCampaign.id}
                                  product_id={null}
                                  competitor_url={null}
                                  newRequest={updateSuggestions(rowData)}/>
                </div>
                <div className="p-col-4">
                    <ScrapeStatus type="reviews" scraping_request={null}
                              text="Get Product Reviews"
                              campaign_id={selectedCampaign.id}
                              product_id={null}
                              competitor_url={null}
                              newRequest={updateReviews(rowData)}/>
                </div>
                <div className="p-col-4">
                    <ScrapeStatus type="failed_reviews" scraping_request={null}
                              text="Get Product Failed Reviews"
                              campaign_id={selectedCampaign.id}
                              product_id={null}
                              competitor_url={null}
                              newRequest={updateFailedReviews(rowData)}/>
                </div>
                <div className="p-col-4">
                    <Button icon="pi pi-search-plus" className="p-button-outlined p-button-info p-mr-1" tooltip={t('actions.search_competitors_on_website')} tooltipOptions={{position: 'bottom'}}
                                    disabled={!rowData.is_site_searching_ready} onClick={() => searchCompetitorsOnWebsite(rowData)}/>
                </div>
                <div className="p-col-4">
                </div>
        </div>;
    };

    const criticalTemplate = (rowData) => {
        return <div className="p-grid">
            <div className="p-col-12">
                <Button icon="pi pi-tags" className="p-button-outlined p-button-danger p-mr-1" tooltip={t('actions.remove_competitor_urls')} tooltipOptions={{position: 'bottom'}} onClick={() => removeCompetitors(rowData)} />
                <Button icon="pi pi-tablet" className="p-button-outlined p-button-danger p-mr-1" tooltip={t('actions.remove_products_by_owner_host')} tooltipOptions={{position: 'bottom'}} onClick={() => removeProductsByOwnerHost(rowData)} />
                <Button icon="pi pi-tag" className="p-button-outlined p-button-danger p-mr-1" tooltip={t('actions.remove_deactivated_competitor_urls')} tooltipOptions={{position: 'bottom'}} onClick={() => removeDeactivatedCompetitors(rowData)} />
                <Button icon="pi pi-google" className="p-button-outlined p-button-danger p-mr-1" tooltip={t('actions.clear_competitor_suggestions')} tooltipOptions={{position: 'bottom'}} onClick={() => clearSuggestionsOfCompetitor(rowData)} />
            </div>
            <div className="p-col-12">
                <Button icon="pi pi-check" className="p-button-outlined p-button-success p-mr-1" tooltip={t('actions.activate_competitor_urls')} tooltipOptions={{position: 'bottom'}} onClick={() => activateCompetitorUrls(rowData)} />
                <Button icon="pi pi-shopping-cart" className="p-button-outlined p-button-danger p-mr-1" tooltip={t('actions.clear_manuel_shipping_prices')} tooltipOptions={{position: 'bottom'}} onClick={() => clearManuelShippingPrices(rowData)} />
            </div>
        </div>;
    };
    const editTemplate = (rowData) => {
        if (selectedCampaign.new_seller_automatically_activated && (rowData.is_active === undefined || rowData.is_active ===  null)) {
            rowData.is_active = true
        }
        return <React.Fragment>
            <Button icon="pi pi-pencil" className="p-button-outlined p-button-success" style={{marginRight: '.5em'}}
                    tooltip={t('actions.edit')} tooltipOptions={{position: 'bottom'}} onClick={(e) => {
                op.current.toggle(e);
                setCompetitor(rowData);
            }}/>
            <InputSwitch checked={rowData.is_active} onChange={() => activateOrDeactivateSellerURL(rowData)}
                         tooltipOptions={{position: 'bottom'}}
                         tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } />
        </React.Fragment>;
    };

    const editSellerTemplate = (rowData) => {
        if (selectedCampaign.new_seller_automatically_activated && (rowData.is_active === undefined || rowData.is_active ===  null)) {
            rowData.is_active = true
        }
        return <React.Fragment>
            {isCampaignManufacturer(selectedCampaign) && <Button icon="pi pi-pencil" className="p-button-outlined p-button-success" style={{marginRight: '.5em'}}
                    tooltip={t('actions.edit')} tooltipOptions={{position: 'bottom'}} onClick={(e) => {
                opSeller.current.toggle(e);
                setCompetitor(competitor);
                setSeller(rowData);
            }} />}
            <InputSwitch checked={rowData.is_active} onChange={() => activateOrDeactivateSeller(rowData)} tooltipOptions={{position: 'bottom'}} tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } />
        </React.Fragment>;
    };

    const competitorSuggestionActionTemplate = (rowData) => {
        return <React.Fragment>
            <Button icon="pi pi-plus-circle" className="p-button-outlined p-button-info" tooltip={t('actions.add')} tooltipOptions={{position: 'bottom'}}
                    style={{marginRight: '.5em'}} /*disabled={!rowData.is_spider_ready}*/
                    onClick={() => addCompetitorSuggestion(rowData)}/>
            {isAdmin() && <Button icon="pi pi-trash" className="p-button-outlined p-button-danger" tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}}
                                  onClick={() => deleteCompetitorSuggestion(rowData)} />}
        </React.Fragment>;
    };


    //campaign bazlı
    const newSuggestRequest = (selectedCampaign) => {
        return { url: (selectedCampaign.competitor_suggestion_scraping_request ? selectedCampaign.competitor_suggestion_scraping_request.url : " "),
            type: "competitor_suggestion",
            campaign_id: selectedCampaign.id};
    };

    const findNewCompetitors = () => {
        scraperService.saveScrapingRequest({type: "new_competitor_suggestion", campaign_id: selectedCampaign.id}).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.auto_match_suggestions'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const findBaseSpiders = () => {
        scraperService.saveScrapingRequest({type: "spider_finder", campaign_id: selectedCampaign.id}).then(()=>{
            toast.current.show({severity: 'success', summary: t('campaign.competitors.base_spider'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const searchCompetitorsOnWebsite = (rowData) => {
        scraperService.saveScrapingRequest({type: "site_search_campaign", campaign_id: selectedCampaign.id, url: rowData.url}).then(()=>{
            toast.current.show({severity: 'success', summary: t('actions.search_competitors_on_website'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const clearSuggestionsOfCompetitor = (rowData) => {
        cleanupService.clearSuggestionsOfCompetitor(selectedCampaign.id, rowData.url).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.clear_competitor_suggestions'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const removeCompetitors = (rowData) => {
        cleanupService.removeCompetitors(selectedCampaign.id, rowData.url).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.remove_competitor_urls'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const removeProductsByOwnerHost = (rowData) => {
        cleanupService.removeProductsByOwnerHost(selectedCampaign.id, rowData.url).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.remove_products_by_owner_host'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const removeDeactivatedCompetitors = (rowData) => {
        cleanupService.removeDeactivatedCompetitors(selectedCampaign.id, rowData.url).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.remove_deactivated_competitor_urls'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const activateCompetitorUrls = (rowData) => {
        cleanupService.activateCompetitorUrls(selectedCampaign.id, rowData.url).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.activate_competitor_urls'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const clearManuelShippingPrices = (rowData) => {
        cleanupService.clearManuelShippingPrices(selectedCampaign.id, rowData.url).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.activate_competitor_urls'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    const clearCompetitorSuggestions = () => {
        cleanupService.clearCompetitorSuggestions(selectedCampaign.id).then(() => {
            setShowCompetitorSuggestionClearModal(false);
            toast.current.show({severity: 'success', summary: t('actions.clear_competitor_suggestions'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const clearCompetitorSuggestionsUsedBaseSpider = () => {
        cleanupService.clearCompetitorSuggestionsUsedBasedSpider(selectedCampaign.id).then(() => {
            setShowCompetitorSuggestionUsedBaseSpiderClearModal(false);
            toast.current.show({severity: 'success', summary: t('actions.clear_competitor_suggestions_used_base_spider'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const clearNewCompetitorSuggestions = () => {
        cleanupService.clearNewCompetitorSuggestions(selectedCampaign.id).then(() => {
            setShowNewCompetitorSuggestionClearModal(false);
            toast.current.show({severity: 'success', summary: t('actions.clear_new_competitor_suggestions'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const clearSuggestionsModalFooter = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" onClick={clearCompetitorSuggestions} />
            <Button label={t('yes_no.no')} icon="pi pi-times" onClick={() => {
                setShowCompetitorSuggestionClearModal(false);
            }} />
        </div>
    );

    const clearSuggestionsUsedBaseSpiderModalFooter = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" onClick={clearCompetitorSuggestionsUsedBaseSpider} />
            <Button label={t('yes_no.no')} icon="pi pi-times" onClick={() => {
                setShowCompetitorSuggestionUsedBaseSpiderClearModal(false);
            }} />
        </div>
    );

    const clearNewSuggestionsModalFooter = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" onClick={clearNewCompetitorSuggestions} />
            <Button label={t('yes_no.no')} icon="pi pi-times" onClick={() => {
                setShowNewCompetitorSuggestionClearModal(false);
            }} />
        </div>
    );

    const decisionColor = (rowData) => {
        if (isAdmin() && rowData.is_headless_browser_used)
            return { 'drop_shipping' : true };
        else if (isAdmin() && !rowData.is_spider_ready)
            return { 'orange' : true };
        return "";
    };

    const dateTemplate = (rowData) => <span>{toDateTimeString(rowData.created_at)}</span>;

    let topMenuItems = [{key:"competitor", label: t('campaign.competitor')},
        {key:"new_competitors", label: t('campaign.new_competitors')}];

    if (isCampaignManufacturer(selectedCampaign))
        topMenuItems.push({key:"violation_email_text", label: t('campaign.violation_email_text')});

    if (isAdmin()) {
        topMenuItems.push({key:"settings", label: t('suggestion.settings')});
        topMenuItems.push({key:"searching", label: t('suggestion.searching')});
        topMenuItems.push({key:"suggestion_result", label: t('suggestion.suggestion_result')});
        topMenuItems.push({key:"auto_match", label: t('suggestion.auto_match')});
    }

    const showCampaignCompetitorURLsDiv = (
        <React.Fragment>
            {isAdmin() &&
            <div className="p-grid p-fluid" >
                <div className="p-col">
                    <span className="p-float-label">
                        <InputText id="url" name="url" value={competitor.url} onChange={onChange} className={errorClass("url")}/>
                        {errorClass("url") && (<Message severity="error" text={formErrors.url}/>)}
                        <label htmlFor="url" >{t('campaign.competitors.url')}</label>
                    </span>
                </div>
                <div className="p-col-fixed" style={{width:"10em", marginTop:"0.5em"}}>
                    <Button label={t('actions.add')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={addCompetitor}/>
                </div>
            </div>

            }

            {loading ?
                <div className="p-col-12" style={{textAlign: 'center'}}>
                    <Loading/>
                </div> :
                <DataTable value={selectedCampaign.competitors} dataKey="url" responsive={true}
                           sortField="url" sortOrder={1}
                           rowClassName={decisionColor}>
                    <Column header={t('table_headers.index')} style={{width: '3em'}} body={IndexTemplate}/>
                    <Column field="url" body={urlTemplate} header={t('campaign.competitors.url')} sortable />
                    {isCampaignManufacturer(selectedCampaign) &&
                    <Column header={t('campaign.competitors.is_authorized')} style={{textAlign: 'center', width: '5em'}} body={isAuthorizedTemplate}/>}
                    {isCampaignManufacturer(selectedCampaign) &&
                    <Column header={t('campaign.competitors.is_notified')} style={{textAlign: 'center', width: '6em'}} body={isNotifiedTemplate}/>}
                    <Column body={editTemplate} header={t('table_headers.action')} style={{textAlign: 'center', width: '8em'}}/>
                    <Column body={sellersTemplate} header={t('campaign.competitor')} style={{textAlign: 'center', width: '8.5em'}}/>
                    {isAdmin() &&
                    <Column body={baseSpiderTemplate} field="used_base_spider" header={t('campaign.competitors.base_spider')} sortable  />}
                </DataTable>
            }

            {isAdmin() &&
                <div className="p-grid p-fluid" >
                <div className="p-col" style={{marginRight:"0.5em", marginTop:"1.5em"}}>
                    <span className="p-float-label">
                        <Dropdown id="other_campaigns" name="other_campaigns" className="campaignSelect" style={{verticalAlign:"inherit", marginLeft:"0.5em"}}
                                  options={campaigns.filter(item => item.id !== selectedCampaign.id).sort((a, b) => (a.name ? a.name.toLowerCase() : "").localeCompare((b.name ? b.name.toLowerCase() : ""), undefined, {sensitivity: 'base'}))}
                                  optionLabel="name" optionValue="id"
                                  value={campaignTo}
                                  onChange={(e) => {setCampaignTo(getKeyValue(e).value);}}
                        />
                        <label htmlFor="other_campaigns">{t('campaign.other_campaigns')}</label>
                    </span>

                </div>
                <div className="p-col" style={{width: "10em", marginTop: "2em"}}>
                    <Button label={t('actions.copy_competitor_urls')} icon="pi pi-copy" className="p-button-outlined p-button-success" onClick={copyCampaignCompetitors}/>
                </div>
            </div>
            }

            {isAdmin() &&
                <div>
                    <div className="p-grid p-fluid" >
                        <div className="p-col" style={{width:"10em", marginTop:"0.5em"}}>
                            <CopyToClipboard isButton={true} buttonLabel={t('actions.copy_competitor_urls_to_clipboard')} textToCopy={getCampaignCompetitors()} />
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );

    const showMarketPlaceSellersDiv = (
        <React.Fragment>
            <div className="p-grid p-fluid" >
                <div className="p-col-fixed" style={{width:"10em", marginTop:"0.5em"}}>
                    <Button label={t('actions.back')} icon="pi pi-arrow-left" className="p-button-outlined p-button-success"
                            onClick={(e) => {setShowMarketPlaceSellers(false)}}/>
                </div>
            </div>

            <DataTable value={competitor?.sellers} dataKey="seller_name" responsive={true}
                       sortField="seller_name" sortOrder={1}>
                <Column header={t('table_headers.index')} style={{width: '5em'}} body={IndexTemplate}/>
                <Column field="seller_name" header={t('campaign.competitor')} sortable/>
                {isCampaignManufacturer(selectedCampaign) &&
                <Column header={t('campaign.competitors.is_authorized')} style={{width: '8em'}} body={isSellerAuthorizedTemplate}/>}
                {isCampaignManufacturer(selectedCampaign) &&
                <Column header={t('campaign.competitors.is_notified')} style={{width: '8em'}} body={isSellerNotifiedTemplate}/>}
                <Column body={editSellerTemplate} header={t('table_headers.action')} style={{textAlign: 'center', width: '9em'}}/>
            </DataTable>
        </React.Fragment>
    )

    return <React.Fragment>
        <h3>{t('campaign.competitor_urls') + ' - ' + selectedCampaign.name}</h3>

        <TabMenu model={topMenuItems}
                 activeIndex={activeIndex} onTabChange={(e) => {
            setActiveIndex(topMenuItems.findIndex(i => i.key.toLowerCase() === e.value.key.toLowerCase()));
        }}
        style={{paddingTop: "0", paddingLeft: "0", paddingRight: "0"}}
        />

        {(topMenuItems[activeIndex]).key === "competitor" ?
        <div>
            <br/>
            <Message severity="info" text={t('campaign.competitors.message_1') + "\n" + t('campaign.competitors.message_2', {supportEmail: supportEmail})} />

            {showMarketPlaceSellers ? showMarketPlaceSellersDiv : showCampaignCompetitorURLsDiv}

        </div> : (topMenuItems[activeIndex]).key === "new_competitors" ?
        <div>
            <br/>
            <Message severity="info" text={t('campaign.competitors.new_sellers_message_1') + "\n" + t('campaign.competitors.new_sellers_message_2', {supportEmail: supportEmail})} />

                    {loadingCompetitorSuggestions ?
                        <div className="p-col-12" style={{textAlign: 'center'}}>
                            <Loading/>
                        </div> :
                        <DataTable value={selectedCampaign.competitor_suggestions} responsive={true} sortField="url"
                                   sortOrder={1}>
                            <Column header={t('table_headers.index')} style={{width: '5em'}} body={IndexTemplate}/>
                            <Column field="url" body={urlTemplate} header={t('campaign.competitors.url')} sortable
                            />
                            {
                                user().is_admin &&
                                <Column field="spider_name" header={t('scraping_request.spider_name')} sortable
                                />
                            }

                            <Column field="created_at" body={dateTemplate} header={t('billing.created_at')} />

                            <Column body={competitorSuggestionActionTemplate} header={t('table_headers.action')}
                                    style={{textAlign: 'center', width: '10em'}}/>
                        </DataTable>
                    }

                    {isAdmin() &&
                    <div>
                        <div className="p-grid p-fluid" >
                            <div className="p-col" style={{width:"10em", marginTop:"0.5em"}}>
                                <CopyToClipboard isButton={true} buttonLabel={t('actions.copy_competitor_urls_to_clipboard')} textToCopy={getCampaignCompetitorSuggestions()} />
                            </div>

                        </div>
                    </div>

                    }
                </div> : (topMenuItems[activeIndex]).key === "violation_email_text" ?
        <div>
            <div className="p-col-12">
                <Editor style={{height:'500px'}}
                        value={selectedCampaign.settings?.violation_email_text} onTextChange={(e) => {
                    selectedCampaign.settings.violation_email_text = e.htmlValue
                }} />
            </div>
            <div className="p-col-12">
                <Button icon="pi pi-refresh" className="p-button-outlined p-button-success" style={{width:"20%", marginLeft: '.5em', float: 'right'}}
                        label={t('actions.save')} onClick={saveSettings}/>
            </div>
        </div> : (topMenuItems[activeIndex]).key === "settings" ?
        <div>
            <br/>
            <div className="p-grid">
                <WebSearchSetting webSearchSetting={competitor_suggestion}/>

                <div className="p-col-12">
                    <Button icon="pi pi-refresh" className="p-button-outlined p-button-success" style={{width:"20%", marginLeft: '.5em', float: 'right'}}
                            label={t('actions.save')} onClick={saveSettings}/>
                </div>

                {loading ?
                    <div className="p-col-12" style={{textAlign: 'center'}}>
                        <Loading/>
                    </div> :
                    <DataTable value={selectedCampaign.competitors} dataKey="url" responsive={true}
                               sortField="url" sortOrder={1}
                               rowClassName={decisionColor}
                               selection={searchCompetitors}
                               onSelectionChange={e => setSearchCompetitors(e.value)}>
                        <Column selectionMode="multiple" style={{width: '3em'}}/>
                        <Column header={t('table_headers.index')} style={{width: '5em'}} body={IndexTemplate}/>
                        <Column field="url" body={urlTemplate} header={t('campaign.competitors.url')} sortable/>
                        <Column body={actionTemplate} header={t('table_headers.action')} style={{textAlign: 'center'}}/>
                        <Column body={scrapeTemplate} header={t('actions.scrape')} style={{textAlign: 'center'}}/>
                        <Column body={criticalTemplate} header={t('actions.critical')} style={{textAlign: 'center'}}/>
                    </DataTable>
                }

            </div>
        </div> : (topMenuItems[activeIndex]).key === "searching" ?
        <div>
            <br/>
            <div className="p-grid p-fluid p-align-center">
                <div className="p-col-4">
                    <h7 style={{marginRight:"0.05em"}}>{t('table_headers.find_matches_status')}</h7>
                </div>

                <div className="p-col-2">
                    <ScrapeStatus type="suggest" scraping_request={selectedCampaign.competitor_suggestion_scraping_request}
                                  campaign_id={selectedCampaign.id}
                                  product_id={null}
                                  competitor_url={null}
                                  newRequest={newSuggestRequest(selectedCampaign)}
                                  newRequestCompleted={() => {loadCampaigns(true)}}/>
                </div>

                <div className="p-col-3">
                    <Button icon="pi pi-undo" className="p-button-outlined p-button-danger"
                            style={{marginRight: '.5em'}}
                            label={t('actions.clear_competitor_suggestions')} onClick={setShowCompetitorSuggestionClearModal}/>
                </div>

                <div className="p-col-3">
                    <Button icon="pi pi-undo" className="p-button-outlined p-button-warning"
                            style={{marginRight: '.5em'}}
                            label={t('actions.clear_competitor_suggestions_used_base_spider')} onClick={setShowCompetitorSuggestionUsedBaseSpiderClearModal}/>
                </div>

                <div className="p-col-4">
                    <h7 style={{marginRight:"0.05em"}}>{t('table_headers.competitor_suggestion_on_site')}</h7>
                </div>
                <div className="p-col-4">
                    <Button icon="pi pi-refresh" className="p-button-outlined p-button-success"
                            style={{marginRight: '.5em'}}
                            label={t('actions.search_competitors_on_website')} onClick={() => searchCompetitorsOnWebsite({"url": ""})}/>

                </div>

                <div className="p-col-4">
                </div>

                <div className="p-col-4">
                    <h7 style={{marginRight:"0.05em"}}>{t('table_headers.new_competitors')}</h7>
                </div>
                <div className="p-col-4">
                    <Button icon="pi pi-refresh" className="p-button-outlined p-button-success"
                            style={{marginRight: '.5em'}}
                            label={t('actions.find_new_competitors')} onClick={findNewCompetitors}/>
                </div>
                <div className="p-col-4">
                    <Button icon="pi pi-undo" className="p-button-outlined p-button-danger"
                            style={{marginRight: '.5em'}}
                            label={t('actions.clear_new_competitor_suggestions')} onClick={setShowNewCompetitorSuggestionClearModal}/>
                </div>

                <div className="p-col-4">
                    <h7 style={{marginRight:"0.05em"}}>{t('campaign.competitors.base_spider')}</h7>
                </div>
                <div className="p-col-4">
                    <Button icon="pi pi-search" className="p-button-outlined p-button-warning"
                            style={{marginRight: '.5em'}}
                            label={t('actions.find_base_spiders')} onClick={findBaseSpiders}/>
                </div>
                <div className="p-col-4">
                </div>

            </div>
        </div> : (topMenuItems[activeIndex]).key === "suggestion_result" ?
        <div>

            <DataTable value={suggestionResults} dataKey="url" responsive={true}>
                <Column field="url" body={urlTemplate} header={t('suggestion.url')} sortable/>
                <Column field="total_count" header={t('suggestion.total_count')} style={{width: '10%'}} />
                <Column field="total_matched_count" header={t('suggestion.total_matched_count')} style={{width: '10%'}} />
                <Column field="total_unmatched_count" header={t('suggestion.total_unmatched_count')} style={{width: '12%'}} />
                <Column field="total_undecided_count" header={t('suggestion.total_undecided_count')} style={{width: '12%'}} />
                <Column field="total_suggested_product_count" header={t('suggestion.total_suggested_product_count')} style={{width: '12%'}} />
                <Column field="total_not_suggested_product_count" header={t('suggestion.total_not_suggested_product_count')} style={{width: '12%'}} />
            </DataTable>


        </div> : (topMenuItems[activeIndex]).key === "auto_match" ?
        <div>
            <br/>
            <div className="p-grid p-fluid p-align-center">

                <div className="p-col-12">
                    <RadioButton inputId="match_method_sku" value="sku" name="match_method" onChange={() => {setMatchMethod("sku")}} checked={match_method === "sku"}/>
                    <label htmlFor="match_method_sku" className="p-ml-1 p-mr-1">{t('product.sku')}</label>

                    <RadioButton inputId="match_method_mpn" value="mpn" name="match_method" onChange={() => {setMatchMethod("mpn")}} checked={match_method === "mpn"}/>
                    <label htmlFor="match_method_mpn" className="p-ml-1 p-mr-1">{t('product.mpn')}</label>

                    <RadioButton inputId="match_method_ean" value="ean" name="match_method" onChange={() => {setMatchMethod("ean")}} checked={match_method === "ean"}/>
                    <label htmlFor="match_method_ean" className="p-ml-1 p-mr-1">{t('product.ean')}</label>

                    <RadioButton inputId="match_method_upc" value="upc" name="match_method" onChange={() => {setMatchMethod("upc")}} checked={match_method === "upc"}/>
                    <label htmlFor="match_method_upc" className="p-ml-1 p-mr-1">{t('product.upc')}</label>

                    <RadioButton inputId="match_method_asin" value="asin" name="match_method" onChange={() => {setMatchMethod("asin")}} checked={match_method === "asin"}/>
                    <label htmlFor="match_method_asin" className="p-ml-1 p-mr-1">{t('product.asin')}</label>

                    <RadioButton inputId="match_method_barcode" value="barcode" name="match_method" onChange={() => {setMatchMethod("barcode")}} checked={match_method === "barcode"}/>
                    <label htmlFor="match_method_barcode" className="p-ml-1 p-mr-1">{t('product.barcode')}</label>

                    <RadioButton inputId="match_method_name_exact" value="name_exact" name="match_method" onChange={() => {setMatchMethod("name_exact")}} checked={match_method === "name_exact"}/>
                    <label htmlFor="match_method_name_exact" className="p-ml-1 p-mr-1" >{t('suggestion.name_exact')}</label>

                    <RadioButton inputId="match_method_name_in" value="name_in" name="match_method" onChange={() => {setMatchMethod("name_in")}} checked={match_method === "name_in"}/>
                    <label htmlFor="match_method_name_in" className="p-ml-1 p-mr-1">{t('suggestion.name_in')}</label>

                    <RadioButton inputId="match_method_match_score" value="match_score" name="match_method" onChange={() => {setMatchMethod("match_score")}} checked={match_method === "match_score"}/>
                    <label htmlFor="match_method_match_score" className="p-ml-1 p-mr-1">{t('suggestion.match_score')}</label>
                    {match_method === "match_score" && <InputNumber name="min_match_score" value={min_match_score} onValueChange={(e) => {setMinMatchScore(e.value)}} showButtons min={0} max={100} size={1} style={{marginRight:"0.3em", width: '10%'}} /> }

                </div>

                <div className="p-col-12">
                    <Button icon="pi pi-refresh" className="p-button-outlined p-button-success" style={{marginRight: '.5em', width: '40%'}}
                            label={t('actions.auto_match_suggestions')} onClick={autoMatchAllSuggestions}/>
                </div>

                <div className="p-col-12">
                    <Button icon="pi pi-refresh" className="p-button-outlined p-button-success" style={{marginRight: '.5em', width: '40%'}}
                            label={t('actions.match_suggestions_using_other_codes')} onClick={matchCompetitorsUsingOtherCodes}/>
                </div>

                <div className="p-col-12">
                    <ProductMatching />
                </div>


            </div>
        </div> : ""}

        {showCompetitorSuggestionClearModal &&
        <Dialog header={t('dialogs.confirm_campaign_competitor_suggestion_clear')}
                footer={clearSuggestionsModalFooter} visible={true}
                style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true}
                onHide={() => {setShowCompetitorSuggestionClearModal(false);}}>
            {t('dialogs.confirm_campaign_competitor_suggestion_clear_msg0')}<b>{(selectedCampaign ? selectedCampaign.name : "")}</b>
        </Dialog>}

        {showCompetitorSuggestionUsedBaseSpiderClearModal &&
        <Dialog header={t('dialogs.confirm_campaign_competitor_suggestion_used_base_spider_clear')}
                footer={clearSuggestionsUsedBaseSpiderModalFooter} visible={true}
                style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true}
                onHide={() => {setShowCompetitorSuggestionUsedBaseSpiderClearModal(false);}}>
            {t('dialogs.confirm_campaign_competitor_suggestion_used_base_spider_clear_msg0')}<b>{(selectedCampaign ? selectedCampaign.name : "")}</b>
        </Dialog>}

        {showNewCompetitorSuggestionClearModal &&
        <Dialog header={t('dialogs.confirm_campaign_new_competitor_suggestion_clear')}
                footer={clearNewSuggestionsModalFooter} visible={true}
                style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true}
                onHide={() => {setShowNewCompetitorSuggestionClearModal(false);}}>
            {t('dialogs.confirm_campaign_new_competitor_suggestion_clear_msg0')}<b>{(selectedCampaign ? selectedCampaign.name : "")}</b>
        </Dialog>}

        <OverlayPanel ref={op} style={{ width:"30em"}}>
            <div className="p-grid p-fluid" >
                <div className="p-col-12" style={{fontSize:"2em"}}>
                    <a target="_blank" rel="noopener noreferrer" href={competitor.url}>{competitor.url}</a>
                </div>
                {isCampaignManufacturer(selectedCampaign) &&
                    <div className="p-col-6">
                        <ToggleButton className="p-mt-2" onLabel={t('campaign.competitors.authorized')}
                                      offLabel={t('campaign.competitors.not_authorized')} onIcon="pi pi-check"
                                      offIcon="pi pi-times"
                                      inputId="is_authorized" name="is_authorized" value={competitor.is_authorized}
                                      onChange={onChange} checked={competitor.is_authorized}/>
                    </div>
                }
                {isCampaignManufacturer(selectedCampaign) &&
                    <div className="p-col-6">
                        <ToggleButton className="p-mt-2" onLabel={t('campaign.competitors.notified')} offLabel={t('campaign.competitors.not_notified')} onIcon="pi pi-check" offIcon="pi pi-times"
                                      inputId="is_notified" name="is_notified" value={competitor.is_notified} onChange={onChange} checked={competitor.is_notified} />
                    </div>
                }

                <div className="p-col-6">
                    <span className="p-float-label">
                        <InputText id="email" name="email" value={competitor.email} onChange={onChange} className={errorClass("email")}
                        tooltip={t('actions.multiple_email_format')}/>
                        {errorClass("email") && (<Message severity="error" text={formErrors.email}/>)}
                        <label htmlFor="email" >{t('campaign.competitors.email')}</label>
                    </span>
                </div>
                <div className="p-col-6">
                    <span className="p-float-label">
                        <InputText id="phone" name="phone" value={competitor.phone} onChange={onChange} className={errorClass("phone")}/>
                        {errorClass("phone") && (<Message severity="error" text={formErrors.phone}/>)}
                        <label htmlFor="phone" >{t('campaign.competitors.phone')}</label>
                    </span>
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText id="address" name="address" value={competitor.address} onChange={onChange} className={errorClass("address")}/>
                        {errorClass("address") && (<Message severity="error" text={formErrors.address}/>)}
                        <label htmlFor="address" >{t('campaign.competitors.address')}</label>
                    </span>
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputTextarea id="note" name="note" value={competitor.note} onChange={onChange} className={errorClass("note")} rows={5} />
                        {errorClass("note") && (<Message severity="error" text={formErrors.note}/>)}
                        <label htmlFor="note" >{t('campaign.competitors.note')}</label>
                    </span>
                </div>

                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputNumber id="shipping_price" name="shipping_price" value={competitor.shipping_price}  mode="decimal" minFractionDigits={2}
                                     onValueChange={onChange} className={errorClass("shipping_price")}/>
                        {errorClass("shipping_price") && (<Message severity="error" text={formErrors.shipping_price}/>)}
                        <label htmlFor="shipping_price" >{t('product_detail.shipping_price')}</label>
                    </span>
                </div>

                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputNumber id="shipping_price_threshold" name="shipping_price_threshold" value={competitor.shipping_price_threshold}  mode="decimal" minFractionDigits={2}
                                     onValueChange={onChange} className={errorClass("shipping_price_threshold")}/>
                        {errorClass("shipping_price_threshold") && (<Message severity="error" text={formErrors.shipping_price_threshold}/>)}
                        <label htmlFor="shipping_price_threshold" >{t('product_detail.shipping_price_threshold')}</label>
                    </span>
                </div>

                <div className="p-col-6">
                    <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-outlined warning" onClick={() => {
                        op.current.hide();
                        setCompetitor({...initialCompetitor});
                    }}/>
                </div>
                <div className="p-col-6">
                    <Button label={t('actions.update')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={updateCompetitor}/>
                </div>
            </div>
        </OverlayPanel>

        <OverlayPanel ref={opSeller} style={{ width:"30em"}}>
            <div className="p-grid p-fluid" >
                <div className="p-col-6">
                    <ToggleButton className="p-mt-2" onLabel={t('campaign.competitors.authorized')} offLabel={t('campaign.competitors.not_authorized')} onIcon="pi pi-check" offIcon="pi pi-times"
                                  inputId="is_authorized" name="is_authorized" value={seller?.is_authorized} onChange={onChangeSeller} checked={seller?.is_authorized} />
                </div>
                <div className="p-col-6">
                    <ToggleButton className="p-mt-2" onLabel={t('campaign.competitors.notified')} offLabel={t('campaign.competitors.not_notified')} onIcon="pi pi-check" offIcon="pi pi-times"
                                  inputId="is_notified" name="is_notified" value={seller?.is_notified} onChange={onChangeSeller} checked={seller?.is_notified} />
                </div>
                <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="email" name="email" value={seller?.email} onChange={onChangeSeller} className={errorClass("email")}/>
                            {errorClass("email") && (<Message severity="error" text={formErrors.email}/>)}
                            <label htmlFor="email" >{t('campaign.competitors.email')}</label>
                        </span>
                </div>
                <div className="p-col-6">
                        <span className="p-float-label">
                            <InputText id="phone" name="phone" value={seller?.phone} onChange={onChangeSeller} className={errorClass("phone")}/>
                            {errorClass("phone") && (<Message severity="error" text={formErrors.phone}/>)}
                            <label htmlFor="phone" >{t('campaign.competitors.phone')}</label>
                        </span>
                </div>
                <div className="p-col-12">
                        <span className="p-float-label">
                            <InputText id="address" name="address" value={seller?.address} onChange={onChangeSeller} className={errorClass("address")}/>
                            {errorClass("address") && (<Message severity="error" text={formErrors.address}/>)}
                            <label htmlFor="address" >{t('campaign.competitors.address')}</label>
                        </span>
                </div>
                <div className="p-col-12">
                        <span className="p-float-label">
                            <InputTextarea id="note" name="note" value={seller?.note} onChange={onChangeSeller} className={errorClass("note")} rows={5} />
                            {errorClass("note") && (<Message severity="error" text={formErrors.note}/>)}
                            <label htmlFor="note" >{t('campaign.competitors.note')}</label>
                        </span>
                </div>

                <div className="p-col-6">
                    <Button label={t('actions.cancel')} icon="pi pi-times" className="p-button-outlined warning" onClick={() => {
                        opSeller.current.hide();
                        setSeller(null);
                    }}/>
                </div>
                <div className="p-col-6">
                    <Button label={t('actions.update')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={updateSeller}/>
                </div>
            </div>
        </OverlayPanel>
        {showAddSiteDefinition &&
            <Dialog visible={true} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setShowAddSiteDefinition(false);}}
            header={t('site_definitions.add')}>
                <SiteDefinitionAdd selectedDefinition={{url: getHostName(selectedWebSite.url), spider_name: selectedWebSite.used_base_spider}}
                                   onComplete={(isCancel)=>{
                                       setShowAddSiteDefinition(false);
                                       if (!isCancel){
                                           const index = selectedCampaign.competitors.findIndex(c=> c.url === selectedWebSite.url);
                                           selectedCampaign.competitors[index].is_spider_ready = true;
                                       }
                                   }} />
            </Dialog>
        }
    </React.Fragment>;

};
export default CampaignCompetitor;
