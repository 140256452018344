import React, {useEffect, useState} from "react";
import {AppContext} from "../../HomePage";
import {Bubble} from "react-chartjs-2";
import {getDatePrice} from "../../utils/Utils";
import {getMonthsAgo, toDateString} from "../../utils/TimeUtil";

const ProductSaleGraph = (props) => {

    const {t} = React.useContext(AppContext);

    let selectedProduct = {...props.selectedProduct};
    let myProduct = {...props.myProduct};

    const [data, setData] = useState({});
    const [options, setOptions] = useState({});
    const [yLabels, setYLabels] = useState([]);

    useEffect(() => {
    }, [])

    useEffect ( ()=>{
        selectedProduct = {...props.selectedProduct};

        generateGraphData(selectedProduct);
    },[JSON.stringify(props.selectedProduct)]);

     const generateGraphData = async (product) => {

        let yLabels = [];
        let stockData = [];
        let startDate = getMonthsAgo(1);
        let endDate = new Date();
        let prevDate = new Date();
        let days = [];

         while (startDate < endDate) {
             days.unshift(toDateString(endDate));
             endDate.setDate(endDate.getDate() - 1);
         }

         endDate = new Date();
         while (startDate < endDate) {

            prevDate.setDate(endDate.getDate() - 1);
            const endDateString = toDateString(endDate);
            const prevDateString = toDateString(prevDate);

            for(let j = 0 ; j < product.competitors.length ; j++)
            {
                let competitor = product.competitors[j];

                let price = getDatePrice(competitor.prices, endDateString)
                let previousPrice = getDatePrice(competitor.prices, prevDateString)

                let stockChange = 0;

                if (price && previousPrice &&
                    price.stock && previousPrice.stock &&
                    price.stock < previousPrice.stock)
                {
                    stockChange = previousPrice.stock - price.stock;
                }

                if (stockChange !== 0)
                {
                    if (yLabels.indexOf(competitor.name) === -1)
                        yLabels.push(competitor.name);

                    stockData.unshift({x: days.indexOf(prevDateString), y: yLabels.indexOf(competitor.name), r: stockChange === 1 ? 3 : (Math.log2(stockChange) * 3), rr: stockChange});
                }
            }

            endDate.setDate(endDate.getDate() - 1);
        }

        stockData = {
            datasets:[ {
                data: stockData,
                fill: false,
                backgroundColor: 'red',
                borderColor: 'lightgray',
            }]
        };

        let stockOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: 'linear',
                    position: 'bottom',
                    ticks: {
                        stepSize: 1,
                        callback: function(value) {
                            return days[value];
                        },
                    },
                },
                y: {
                    type: 'linear',
                    ticks: {
                        reverse: true,
                        stepSize: 1,
                        callback: function(value) {
                            return yLabels[value];
                        },
                    },
                }
            },
            plugins: {
                labels: false,
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    titleFontSize:20,
                    bodyFontSize:15,
                    bodySpacing:5,
                    displayColors:false,
                    callbacks: {
                        label: function(item) {
                                return t('product_detail.seller') + " : " + yLabels[item.raw.y] + " " + t('product_detail.sold_count') + " : " + item.raw.rr;
                        }
                    },
                },
                legend: {
                    display: false
                }
            },
        };
        setData(stockData);
        setOptions(stockOptions);
        setYLabels(yLabels);
    };

    return <div style={{height:(yLabels && yLabels.length * 30 > 350 ? yLabels.length * 30 : 350)+"px"}}>
        {data.datasets &&
        <Bubble id="stock" data={data} options={options} height={yLabels && yLabels.length * 30 > 350 ? yLabels.length * 30 : 350}/>}
    </div>;
};
export default ProductSaleGraph;
