import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {IndexTemplate, Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {UserService} from "../../service/UserService";

const Currency = (props) => {

    const {t, setError} = React.useContext(AppContext);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const userService = new UserService();

    useEffect ( ()=>{
        search();
    }, []);

    const search = () => {
        setLoading(true);
        userService.getUserBasedCurrencies().then(data=>{
            setItems(data);
            setLoading(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    return <React.Fragment>
         {loading ?
             <div className="p-col-12" style={{textAlign:'center'}}>
                 <Loading/>
             </div> :
             <DataTable value={items} emptyMessage={t('actions.empty_message')} paginator={true} rows={100}>
                 <Column body={IndexTemplate} style={{width:'3em'}} />
                 <Column field="label" header={t('account.headers.currency')} sortable style={{width:'8em'}}/>
                 <Column field="value" header={t('account.headers.currency_value')} sortable/>
             </DataTable>}

    </React.Fragment>;
};
export default Currency;
