import React, {useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ScraperService} from "../../service/ScraperService";
import {InputText} from "primereact/inputtext";

import {Dialog} from "primereact/dialog";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {AppContext} from "../../HomePage";
import {Loading, IndexTemplate} from "../../utils/InlineComponents";
import SiteDefinitionAdd from "./SiteDefinitionAdd";

const SiteDefinitions = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);

    let dt = useRef(null);

    const [siteDefinitions, setSiteDefinitions] = useState([]);
    const [editedDefinition, setEditedDefinition] = useState({url: "", spider_name: "", country: "", sector: "", scraping_request_type: "", is_ignored: false, other_info: "", proxy_type: "", proxy_country: "", is_marketplace: false, is_proxy_used: false, is_headless_browser_used: false, is_headless_browser_used_for_importing_products: false, is_headless_browser_used_for_site_searching: false, is_site_searching_ready: false});
    const [isEditPage, setIsEditPage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [formErrors, setFormErrors] = useState({url: "", spider_name: "", country: "", sector: "", scraping_request_type: "", is_ignored: "", other_info: "", proxy_type: "", proxy_country: "", is_marketplace: "", is_proxy_used: "", is_headless_browser_used: "", is_headless_browser_used_for_importing_products: "", is_headless_browser_used_for_site_searching: "", is_site_searching_ready: ""});

    const [filters, setFilters] = useState( {url:"", spider_name:"", country:"", sector:"", scraping_request_type: "", is_ignored: null, other_info: null, proxy_type: null, proxy_country: null, is_marketplace: null, is_proxy_used: null, is_headless_browser_used:null, is_headless_browser_used_for_importing_products:null, is_headless_browser_used_for_site_searching:null, is_site_searching_ready:null});


    const [loading, setLoading] = useState(false);

    const [forceRefresh, setForceRefresh] = useState(0);

    const scraperService = new ScraperService();

    useEffect(() =>{
        list();
    }, [forceRefresh]);

    const isIgnoredTemplate = (rowData) => <div>{rowData.is_ignored ? t('trueFalse.true') : t('trueFalse.false')}</div>;
    const isMarketplaceTemplate = (rowData) => <div>{rowData.is_marketplace ? t('trueFalse.true') : t('trueFalse.false')}</div>;
    const isProxyUsedTemplate = (rowData) => <div>{rowData.is_proxy_used ? t('trueFalse.true') : t('trueFalse.false')}</div>;
    const isHeadlessBrowserUsedTemplate = (rowData) => <div>{rowData.is_headless_browser_used ? t('trueFalse.true') : t('trueFalse.false')}</div>;
    const isHeadlessBrowserUsedForImportingTemplate = (rowData) => <div>{rowData?.is_headless_browser_used_for_importing_products ? t('trueFalse.true') : t('trueFalse.false')}</div>;
    const isHeadlessBrowserUsedForSiteSearchingTemplate = (rowData) => <div>{rowData?.is_headless_browser_used_for_site_searching ? t('trueFalse.true') : t('trueFalse.false')}</div>;
    const isSiteSearchingReadyTemplate = (rowData) => <div>{rowData.is_site_searching_ready ? t('trueFalse.true') : t('trueFalse.false')}</div>;
    const actionTemplate = (rowData) => {
        return <div>
            <Button icon="pi pi-pencil" className="p-button-outlined p-button-success" tooltip={t('actions.edit')} tooltipOptions={{position: 'bottom'}} style={{marginRight: '.5em'}} onClick={() => {setIsEditPage(true); setEditedDefinition(rowData); setFormErrors({url: "", spider_name: "", country: "", sector: "", scraping_request_type: ""});}}/>
            <Button icon="pi pi-trash" className="p-button-outlined p-button-danger" tooltip={t('actions.remove')} tooltipOptions={{position: 'bottom'}} onClick={() => {setShowModal(true); setRowData(rowData);}}/>
        </div>;
    };

    const list = () => {
        setLoading(true);
        scraperService.getSiteDefinitions(forceRefresh!==0).then(data => {
            setSiteDefinitions(data);
            setLoading(false);
            setForceRefresh(0);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoading(false);
        });
    };

    const add = () => {
        setEditedDefinition({url: "", spider_name: "", country: "", sector: "", scraping_request_type: "", other_info: "", proxy_type: "", proxy_country: "", is_ignored: false, is_marketplace: false, is_proxy_used: false, is_headless_browser_used: false, is_headless_browser_used_for_importing_products: false, is_headless_browser_used_for_site_searching: false, is_site_searching_ready: false});
        setFormErrors({url: "", spider_name: "", country: "", sector: "", scraping_request_type: "", other_info: "", proxy_type: "", proxy_country: "", is_ignored: "", is_marketplace: "", is_proxy_used: "", is_headless_browser_used: "", is_headless_browser_used_for_importing_products: "", is_headless_browser_used_for_site_searching: "", is_site_searching_ready: ""});
        setIsEditPage(true);
    };

    const onDelete = () => {

        scraperService.deleteSiteDefinition(rowData).then(() => {
            list();
            setShowModal(false);
            setRowData(null);
            toast.current.show({severity: 'success', summary: t('actions.delete'), detail: t('message_detail.successful') });
        }).catch(error =>{
            setError(error);
            setError(null);
        });

    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "url") {
            errorText = value ? "" : t('validations.invalid_value');
        } else if (key === "spider_name") {
            errorText = value ? "" : t('validations.invalid_value');
        } else if (key === "country") {
            errorText = value ? "" : t('validations.invalid_value');
        } else if (key === "sector") {
            errorText = value ? "" : t('validations.invalid_value');
        }

        return errorText;
    };

    const checkErrors = async (siteDefinition) => {

        let errors = { ...formErrors };

        Object.entries(siteDefinition).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        setFormErrors(errors);
        return errors;
    };

    const modalYesNo = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" onClick={onDelete} />
            <Button label={t('yes_no.no')} icon="pi pi-times" onClick={() => {setShowModal(false);
                setRowData(null)}} />
        </div>
    );

    const onFilterChange = (event) => {
        const {value, name } = event.target;

        filters[name] = value;
        setFilters({...filters});
    };

    const urlFilter = <InputText name="url" value={filters.url} onChange={onFilterChange}  />;
    const spiderNameFilter = <InputText name="spider_name" value={filters.spider_name} onChange={onFilterChange}  />;
    const countryFilter = <InputText name="country" value={filters.country} onChange={onFilterChange}  />;
    const sectorFilter = <InputText name="sector" value={filters.sector} onChange={onFilterChange}  />;
    const scrapingRequestTypeFilter = <InputText name="scraping_request_type" value={filters.scraping_request_type} onChange={onFilterChange}  />;

    const isIgnoredFilter = <React.Fragment>
        <TriStateCheckbox name="is_ignored" value={filters.is_ignored} onChange={onFilterChange} />
        {filters.is_ignored === null ? t('actions.all') : filters.is_ignored ? t('trueFalse.true') : t('trueFalse.false') }
    </React.Fragment>;

    const isMarketplaceFilter = <React.Fragment>
        <TriStateCheckbox name="is_marketplace" value={filters.is_marketplace} onChange={onFilterChange} />
        {filters.is_marketplace === null ? t('actions.all') : filters.is_marketplace ? t('trueFalse.true') : t('trueFalse.false') }
    </React.Fragment>;

    const isProxyUsedFilter = <React.Fragment>
        <TriStateCheckbox name="is_proxy_used" value={filters.is_proxy_used} onChange={onFilterChange} />
        {filters.is_proxy_used === null ? t('actions.all') : filters.is_proxy_used ? t('trueFalse.true') : t('trueFalse.false') }
    </React.Fragment>;

    const isHeadlessBrowserUsedFilter = <React.Fragment>
        <TriStateCheckbox name="is_headless_browser_used" value={filters.is_headless_browser_used} onChange={onFilterChange} />
        {filters.is_headless_browser_used === null ? t('actions.all') : filters.is_headless_browser_used ? t('trueFalse.true') : t('trueFalse.false') }
    </React.Fragment>;

    const isHeadlessBrowserUsedForImportingFilter = <React.Fragment>
        <TriStateCheckbox name="is_headless_browser_used_for_importing_products" value={filters.is_headless_browser_used_for_importing_products} onChange={onFilterChange} />
        {filters.is_headless_browser_used_for_importing_products === null ? t('actions.all') : filters.is_headless_browser_used_for_importing_products ? t('trueFalse.true') : t('trueFalse.false') }
    </React.Fragment>;

    const isHeadlessBrowserUsedForSiteSearching = <React.Fragment>
        <TriStateCheckbox name="is_headless_browser_used_for_site_searching" value={filters.is_headless_browser_used_for_site_searching} onChange={onFilterChange} />
        {filters.is_headless_browser_used_for_site_searching === null ? t('actions.all') : filters.is_headless_browser_used_for_site_searching ? t('trueFalse.true') : t('trueFalse.false') }
    </React.Fragment>;

    const isSiteSearchingReadyFilter = <React.Fragment>
        <TriStateCheckbox name="is_site_searching_ready" value={filters.is_site_searching_ready} onChange={onFilterChange} />
        {filters.is_site_searching_ready === null ? t('actions.all') : filters.is_site_searching_ready ? t('trueFalse.true') : t('trueFalse.false') }
    </React.Fragment>;

    return (
        <div>
            {!isEditPage ?
                <div className="card">
                    <div className="p-col-12">
                        <Button icon="pi pi-list" className="p-button-outlined" label={t('site_definitions.list')} onClick={()=> {
                            setForceRefresh(forceRefresh + 1);
                        }} style={{marginRight: '0.5em'}}/>
                        <Button icon="pi pi-plus" className="p-button-outlined p-button-success" label={t('site_definitions.add')} onClick={add}/>
                    </div>

                    {loading ?
                        <div className="p-grid p-col-12" style={{textAlign: 'center'}}>
                            <Loading/>
                        </div>
                        :
                        <div className="p-col-12">

                            <DataTable ref={dt} value={siteDefinitions.filter(s => {
                                return (filters.url === "" || s.url.toLowerCase().includes(filters.url.toLowerCase())) &&
                                    (filters.spider_name === "" || s.spider_name.toLowerCase().includes(filters.spider_name.toLowerCase())) &&
                                    (filters.country === "" || (s.country && s.country.toLowerCase().includes(filters.country.toLowerCase()))) &&
                                    (filters.sector === "" || (s.sector && s.sector.toLowerCase().includes(filters.sector.toLowerCase()))) &&
                                (filters.is_ignored === null || (s.is_ignored && s.is_ignored === filters.is_ignored)) &&
                                (filters.is_marketplace === null || s.is_marketplace === filters.is_marketplace) &&
                                (filters.is_proxy_used === null || s.is_proxy_used === filters.is_proxy_used) &&
                                (filters.is_headless_browser_used === null || s.is_headless_browser_used === filters.is_headless_browser_used) &&
                                (filters.is_headless_browser_used_for_importing_products === null || s.is_headless_browser_used_for_importing_products === filters.is_headless_browser_used_for_importing_products) &&
                                (filters.is_headless_browser_used_for_site_searching === null || s.is_headless_browser_used_for_site_searching === filters.is_headless_browser_used_for_site_searching) &&
                                (filters.is_site_searching_ready === null || s.is_site_searching_ready === filters.is_site_searching_ready)
                            })} responsive={true} sortField="spider_name" sortOrder={1}
                                       paginator={true} rows={20}>
                                <Column body={IndexTemplate} style={{width: '3em'}}/>
                                <Column field="url" header={t('site_definitions.url')} filter filterElement={urlFilter} filterMatchMode="contains"/>
                                <Column field="spider_name" header={t('site_definitions.spider')} filter filterElement={spiderNameFilter} filterMatchMode="contains"/>
                                <Column field="country" header={t('site_definitions.country')} />
                                <Column field="sector" header={t('site_definitions.sector')} />
                                <Column field="scraping_request_type" header={t('site_definitions.scraping_request_type')}/>
                                <Column field="is_ignored" header={t('site_definitions.is_ignored')} body={isIgnoredTemplate} filter filterElement={isIgnoredFilter} filterMatchMode="contains"/>
                                <Column field="is_marketplace" header={t('site_definitions.is_marketplace')} body={isMarketplaceTemplate} filter filterElement={isMarketplaceFilter} filterMatchMode="contains"/>
                                <Column field="is_proxy_used" header={t('site_definitions.is_proxy_used')} body={isProxyUsedTemplate} filter filterElement={isProxyUsedFilter} filterMatchMode="contains"/>
                                <Column field="is_headless_browser_used" header={t('site_definitions.is_headless_browser_used')} body={isHeadlessBrowserUsedTemplate} filter filterElement={isHeadlessBrowserUsedFilter} filterMatchMode="contains"/>
                                <Column field="is_headless_browser_used_for_importing_products" header={t('site_definitions.is_headless_browser_used_for_importing_products')} body={isHeadlessBrowserUsedForImportingTemplate} filter filterElement={isHeadlessBrowserUsedForImportingFilter} filterMatchMode="contains"/>
                                <Column field="is_headless_browser_used_for_site_searching" header={t('site_definitions.is_headless_browser_used_for_site_searching')} body={isHeadlessBrowserUsedForSiteSearchingTemplate} filter filterElement={isHeadlessBrowserUsedForSiteSearching} filterMatchMode="contains"/>
                                <Column field="is_site_searching_ready" header={t('site_definitions.is_site_searching_ready')} body={isSiteSearchingReadyTemplate} filter filterElement={isSiteSearchingReadyFilter} filterMatchMode="contains"/>
                                <Column body={actionTemplate} style={{width: '8em'}}/>
                            </DataTable>
                        </div>}
                </div>
                :
                <SiteDefinitionAdd selectedDefinition={editedDefinition} onComplete={(isCancel)=>{
                    setIsEditPage(false);
                    list();
                }} />
            }
            <Dialog header={t('site_definitions.confirm_delete')}  footer={modalYesNo} visible={showModal} style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {setShowModal(false); setRowData(null);}}>
                {t('site_definitions.confirm_delete_msg')}
            </Dialog>
        </div>

    );

};
export default (SiteDefinitions);
