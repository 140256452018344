import {AuthService} from "./AuthService";
import {isAdmin} from "../utils/Utils";
import {asyncLocalStorage, asyncSessionStorage} from "../utils/LocalStorageUtil";
export class ScraperService {
    url = `price_xpaths/`;
    url_scrapingRequests = `scraping_requests/`;
    url_productImports = 'product_import/';


    //#region PriceXPaths

    async getSiteDefinitions (force_update) {
        if (force_update || !localStorage.getItem("siteDefinitionList"))
            return AuthService.apiGet(this.url).then(res => {
                asyncLocalStorage.setItem("siteDefinitionList", JSON.stringify(res.data.price_xpaths));
                return res.data.price_xpaths
            });
        else
            return asyncLocalStorage.getItem("siteDefinitionList").then(d=>{
                return JSON.parse(d);
            });
    }


    saveSiteDefinition(price_xpath) {
        if(price_xpath.id) {
            return AuthService.apiPut(this.url + price_xpath.id + '/', {"price_xpath": price_xpath}).then(res => {
                let savedDefinition = res.data.price_xpath_updated
                return this.getSiteDefinitions(false).then(data => {
                    let filteredSiteDefinitions = data.filter(sd => sd.id && sd.id !== savedDefinition.id);
                    filteredSiteDefinitions.unshift(savedDefinition);
                    return asyncLocalStorage.setItem("siteDefinitionList", JSON.stringify(filteredSiteDefinitions));
                });
            });
        } else {
            return AuthService.apiPost(this.url, {"price_xpath": price_xpath}).then(res => {
                let savedDefinitions = res.data.price_xpath_created
                return this.getSiteDefinitions(false).then(data => {
                    return asyncLocalStorage.setItem("siteDefinitionList", JSON.stringify([...data.concat(savedDefinitions)]));
                });
            });
        }
    }

    deleteSiteDefinition(price_xpath) {
        return AuthService.apiDelete(this.url + price_xpath.id + '/').then(res => {
            return this.getSiteDefinitions(false).then(data => {
                let filteredSiteDefinitions = data.filter(sd => sd.id && sd.id !== price_xpath.id);
                return asyncLocalStorage.setItem("siteDefinitionList", JSON.stringify(filteredSiteDefinitions)).then(()=>{
                });
            });
        });
    }

    getMarketplaces() {
        return AuthService.apiGet(this.url + 'market_places/').then(res => res.data.data);
    }





    //#endregion PriceXPaths

    //#region ScrapingRequests

    getScrapingRequests(campaign_id) {
        return AuthService.apiGet(this.url_scrapingRequests, {
            params: {
                request_type: 'products_create',
                campaign_id: campaign_id
            }
        }).then(res => res.data.scraping_requests);
    }

    getScrapingRequest(id) {
        return AuthService.apiGet(this.url_scrapingRequests + id).then(res => res.data.data);
    }

    getLastScrapingRequest(campaign_id, request_type) {
        return AuthService.apiGet(this.url_scrapingRequests + 'last/', {
            params: {
                request_type: request_type,
                campaign_id: campaign_id
            }
        }).then(res => res.data.data);
    }

    getTodayScrapingRequests(campaign_id) {
        return AuthService.apiGet(this.url_scrapingRequests + 'today/', {
            params: {
                campaign_id: campaign_id
            }
        }).then(res => res.data.data);
    }

    getScrapingRequestStatus(id, job_key) {
        return AuthService.apiGet(this.url_scrapingRequests + id + '/scraping_request_status/', {
            params: {
                job_key: job_key
            }
        }).then(res => res.data.data);
    }

    listScrapingJobs(state, start_date, end_date) {
        return AuthService.apiGet(this.url_scrapingRequests + "all/", {
            params: {
                state: state,
                start_date: start_date,
                end_date: end_date
            }
        }).then(res => res.data.data);
    }

    getJobsInfo(id) {
        return AuthService.apiGet(this.url_scrapingRequests + id + "/jobs_info/"
        ).then(res => res.data.data);
    }

    retrieveFailedRequestsDigest(id) {
        return AuthService.apiGet(this.url_scrapingRequests + id + "/retrieve_failed_requests_digest/"
        ).then(res => res.data.data);
    }

    listFailedRequestsDigest(start_date, end_date) {
        return AuthService.apiGet(this.url_scrapingRequests + "failed_requests_digest/", {
            params: {
                start_date: start_date,
                end_date: end_date
            }
        }).then(res => res.data.data);
    }

    listFailedRequestsBasedHostname(start_date, end_date) {
        return AuthService.apiGet(this.url_scrapingRequests + "failed_requests_based_hostname/", {
            params: {
                start_date: start_date,
                end_date: end_date
            }
        }).then(res => res.data.data);
    }

    listJobsRunTime(start_date, end_date) {
        return AuthService.apiGet(this.url_scrapingRequests + "jobs_run_time/", {
            params: {
                start_date: start_date,
                end_date: end_date
            }
        }).then(res => res.data.data);
    }

    listNewWebsites(start_date, end_date) {
        return AuthService.apiGet(this.url_scrapingRequests + "requests_in_preparation/", {
            params: {
                start_date: start_date,
                end_date: end_date
            }
        }).then(res => res.data.data);
    }

    listNewWebsitesOverView() {
        return AuthService.apiGet(this.url_scrapingRequests + "requests_in_preparation_overview/", {
        }).then(res => res.data.data);
    }

    rerun(id) {
        return AuthService.apiPost(this.url_scrapingRequests+ id + "/rerun/").then(res => res.data.data);
    }

    saveScrapingRequest(scraping_request) {
        return AuthService.apiPost(this.url_scrapingRequests, {"scraping_request": scraping_request}).then(res => res.data.scraping_requests_created);
    }

    getShippingPrices(scraping_request){
        return AuthService.apiPost(this.url_scrapingRequests + "shipping_prices/", {"scraping_request": scraping_request}).then(res => res.data.scraping_requests_created);
    }

    deleteScrapingRequest(id) {
        return AuthService.apiDelete(this.url_scrapingRequests + id).then(res => res.data.message);
    }

    deleteProductImportUrl(id) {
        return AuthService.apiDelete(this.url_scrapingRequests + id + '/delete_product_import_url/').then(res => res.data.message);
    }

    reportScrapingRequest(id, reportType, reportMessage) {
        return AuthService.apiPost(this.url_scrapingRequests + id + "/report_error/", {"type": reportType, "message": reportMessage}).then(res => res.data.message);
    }

    //#endregion ScrapingRequests


    //#region ProductImports

    getProductImports(campaign_id) {
        return AuthService.apiGet(this.url_productImports, {
            params: {
                campaign_id: campaign_id
            }
        }).then(res => res.data.data);
    }

    saveProductImport(campaign_id, url) {
        return AuthService.apiPost(this.url_productImports, {"url": url, "campaign_id": campaign_id}).then(res => res.data.data);
    }

    deleteProductImport(id) {
        return AuthService.apiDelete(this.url_productImports + id).then(res => res.data.data);
    }

    getNotDoneProductScrapingRequests(campaign_id) {
        return AuthService.apiGet(this.url_scrapingRequests + 'not_done_product_scraping_requests/', {
            params: {
                campaign_id: campaign_id
            }
        }).then(res => res.data.data);
    }

    //#endregion ProductImports

}
