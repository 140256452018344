import React, {useState, useEffect, useRef} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputSwitch} from 'primereact/inputswitch';
import {MultiSelect} from "primereact/multiselect";
import {Toolbar} from 'primereact/toolbar';
import {SelectButton} from "primereact/selectbutton";
import {Sidebar} from "primereact/sidebar";
import {OverlayPanel} from "primereact/overlaypanel";
import {Calendar} from "primereact/calendar";
import {Skeleton} from "primereact/skeleton";
import {ScrollPanel} from "primereact/scrollpanel";
import {ProgressBar} from "primereact/progressbar";
import {Tag} from "primereact/tag";
import {ListBox} from "primereact/listbox";
import {confirmDialog} from "primereact/confirmdialog";

import {exportFile, formatCurrency, getHostName, getKeyValue, isCampaignDropShipping,
    isCampaignManufacturer, isCampaignSeller, isPreventSelect, subscription, toTitleCase, user,
} from "../../utils/Utils";
import {toDateString} from "../../utils/TimeUtil";
import {Loading, Tooltip, usePrevious} from "../../utils/InlineComponents";
import {
    exportableFields,
    getSelectedExportableFields,
    getSelectedProductListColumns,
    productListColumns,
    job_states
} from "../../utils/Constants";
import CopyToClipboard from "../../utils/CopyToClipboard";

import Banner from "./Banner";
import ProductAdd from "./ProductAdd";
import ProductBulkOperations from "./ProductBulkOperations";
import ProductDetail from "./ProductDetail";
import ProductEdit from "./ProductEdit";
import ScrapeStatus from "./ScrapeStatus";
import Number from "../Common/Number";

import {ExportService} from "../../service/ExportService";
import {ProductService} from '../../service/ProductService';
import {ScraperService} from "../../service/ScraperService";

import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";
import { scroller, Element} from 'react-scroll'
import queryString from "query-string";
import ProductMerge from "./ProductMerge";

const Product = (props) => {

    const {t, toast, location, setError} = React.useContext(AppContext);

    const {selectedCampaign} = React.useContext(CampaignContext);

    const op = useRef(null);
    const opExcelFields = useRef(null);
    const dtProducts = useRef(null);

    const skeletonArray = new Array(5);

    const pages = {
        list: 'list',
        add: 'add',
        edit: 'edit',
        detail: 'detail'
    };

    const defaultFilters = {
        is_active: true,
        category: [],
        brand: [],
        tags: [],
        discount_types: [],
        website: [],
        name: "",
        ref_id: "",
        codes: "",
        mystock: null,
        cheapestCompetitor: null,
        hasCompetitor: null,
        drop_shipping_supplied: null,
        drop_shipping_loss: null,
        reprice: null,
        variant_matching_needed: null,
        myprice: [],
        created_at_start: null,
        created_at_end: null,
        globalFilter:""
    };

    const [products, setProducts] = useState([]);
    const [productsToMerge, setProductsToMerge] = useState([]);

    const [filteredProducts, setFilteredProducts] = useState([]);

    const [lastResultCount, setLastResultCount] = useState(-1);

    const [scrapingRequests, setScrapingRequests] = useState([]);
    const [filters, setFilters] = useState(defaultFilters);

    const prevFiltersIsActive = usePrevious(filters.is_active);




    const [brands, setBrands] = useState(null);
    const [categories, setCategories] = useState(null);
    const [filterWebSites, setFilterWebSites] = useState(null);
    const [filterBrands, setFilterBrands] = useState(null);
    const [filterCategories, setFilterCategories] = useState(null);

    const [tags, setTags] = useState(null);
    const [filterTags, setFilterTags] = useState(null);

    const [discount_types, setDiscountTypes] = useState(null);
    const [filterDiscountTypes, setFilterDiscountTypes] = useState(null);

    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedProductId, setSelectedProductId] = useState("");
    const [exportFields, setExportFields] = useState(getSelectedExportableFields(t, selectedCampaign, false));
    const [selectedColumns, setSelectedColumns] = useState(getSelectedProductListColumns(t, selectedCampaign, false));

    const [page, setPage] = useState(pages.list);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(localStorage.getItem("paginator") ? parseInt(localStorage.getItem("paginator")) : 10);
    const [sortField, setSortField] = useState(subscription().type.toLowerCase() === 'trial' ? "competitorsCount" : "name");
    const [sortOrder, setSortOrder] = useState(subscription().type.toLowerCase() === 'trial' ? -1 : 1);

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [showBulkOperations, setShowBulkOperations] = useState(false);
    const [showMergePanel, setShowMergePanel] = useState(false);

    const [editingPrice_productID, setEditingPrice_productID] = useState("");
    const [editingMinPrice_productID, setEditingMinPrice_productID] = useState("");
    const [editingMaxPrice_productID, setEditingMaxPrice_productID] = useState("");
    const [pricingStrategyMinValue, setPricingStrategyMinValue] = useState(0);
    const [pricingStrategyMaxValue, setPricingStrategyMaxValue] = useState(0);
    const [violationStrategyMinValue, setViolationStrategyMinValue] = useState(0);

    const productService = new ProductService();
    const scraperService = new ScraperService();
    const exportService = new ExportService();

    const setFiltersFromQueryString = () => {
        if (location)
        {
            let params = queryString.parse(location.search);
            if (params) {
                if (params.myprice)
                    filters.myprice = [params.myprice];

                if (params.reprice && params.reprice === 'false')
                    filters.reprice = false;
                else if (params.reprice && params.reprice === 'true')
                    filters.reprice = true;

                if (params.mystock && params.mystock === 'false')
                    filters.mystock = false;
                else if (params.mystock && params.mystock === 'true')
                    filters.mystock = true;

                if (params.new && params.new === 'true') {
                    filters.created_at_start = new Date(new Date().setDate(new Date().getDate() - 7));
                    filters.created_at_end = new Date();
                }

                if (params.cheapestCompetitor)
                    filters.cheapestCompetitor = params.cheapestCompetitor;

                if (params.hasCompetitor && params.hasCompetitor === 'false')
                    filters.hasCompetitor = false;
                else if (params.hasCompetitor && params.hasCompetitor === 'true')
                    filters.hasCompetitor = true;

                if (params.drop_shipping_supplied && params.drop_shipping_supplied === 'false')
                    filters.drop_shipping_supplied = false;
                else if (params.drop_shipping_supplied && params.drop_shipping_supplied === 'true')
                    filters.drop_shipping_supplied = true;

                if (params.drop_shipping_loss && params.drop_shipping_loss === 'false')
                    filters.drop_shipping_loss = false;
                else if (params.drop_shipping_loss && params.drop_shipping_loss === 'true')
                    filters.drop_shipping_loss = true;

                if (params.variant_matching_needed && params.variant_matching_needed === 'false')
                    filters.variant_matching_needed = false;
                else if (params.variant_matching_needed && params.variant_matching_needed === 'true')
                    filters.variant_matching_needed = true;

                setFilters(filters);
            }
        }
    };

    useEffect(() =>{
        setFiltersFromQueryString();
    }, []);

    useEffect(() =>{
        if (prevFiltersIsActive === filters.is_active)
            onClear();
        refresh(0);
    }, [selectedCampaign, filters.is_active]);

    useEffect(() =>{
        if (lastResultCount === 500)
            refresh(products.length);
        else if(lastResultCount !== 500 && lastResultCount !== -1) {
            setLoadingMessage(false);
            setLoading(false);
        }
        if (lastResultCount > 0)
            setLoading(false);

        applyFilters();
    }, [products]);

    useEffect(() =>{
        applyFilters();
    }, [filters, sortOrder, sortField]);

    useEffect(() =>{
        if(page === pages.list)
            scroller.scrollTo(selectedProductId.split("-").join(""), {
                duration:0,
                smooth: "easeInOutQuart",
                delay:0,
                offset: -200
            });
    }, [page]);

    const applyFilters = () => {
        const result = products.filter(product =>
        {

            return (filters.category.length === 0 || (filters.category.includes(product.category ?? "" )) ) &&
                (filters.brand.length === 0 || (filters.brand.includes(product.brand ?? ""))  ) &&
                (filters.tags.length === 0 || (product.tags && filters.tags.some(tag => product.tags.includes(tag)))) &&
                (filters.discount_types.length === 0 || (product.discount_types && filters.discount_types.some(discount_type => product.discount_types.includes(discount_type)))) &&
                (filters.website.length === 0 || (filters.website.includes(product.website ?? ""))  ) &&
                (filters.name === "" || (product.name && product.name.toLowerCase().indexOf(filters.name.toLowerCase()) !== -1)) &&
                (filters.codes === ""
                    || (product.ref_id && product.ref_id.toString().toLowerCase().toString().indexOf(filters.codes.toLowerCase()) !== -1)
                    || (product.sku && product.sku.toString().toLowerCase().toString().indexOf(filters.codes.toLowerCase()) !== -1)
                    || (product.mpn && product.mpn.toString().toLowerCase().toString().indexOf(filters.codes.toLowerCase()) !== -1)
                    || (product.ean && product.ean.toString().toLowerCase().toString().indexOf(filters.codes.toLowerCase()) !== -1)
                    || (product.upc && product.upc.toString().toLowerCase().toString().indexOf(filters.codes.toLowerCase()) !== -1)
                    || (product.asin && product.asin.toString().toLowerCase().toString().indexOf(filters.codes.toLowerCase()) !== -1)
                    || (product.barcode && product.barcode.toString().toLowerCase().toString().indexOf(filters.codes.toLowerCase()) !== -1)) &&
                (filters.mystock === null || (filters.mystock && product.stock && product.stock > 0) || (!filters.mystock && (!product.stock || product.stock <= 0) )) &&
                (filters.reprice === null || (product.price && product.suggested_price && ((filters.reprice && product.price > product.target_price ) || (!filters.reprice && product.price < product.target_price)))) &&
                (filters.myprice.length === 0 || (product.price && (
                    (filters.myprice.includes('highest') && product.competitorsMaxPrice && product.price > product.competitorsMaxPrice ) ||
                    (filters.myprice.includes('same') && product.competitorsMinPrice && product.price === product.competitorsMinPrice ) ||
                    (filters.myprice.includes('high') && product.competitorsMaxPrice && product.competitorsMinPrice && product.price > product.competitorsMinPrice && product.price <= product.competitorsMaxPrice) ||
                    (filters.myprice.includes('avg_higher') && product.competitorsAvgPrice && product.price > product.competitorsAvgPrice) ||
                    (filters.myprice.includes('avg_lower') && product.competitorsAvgPrice && product.price < product.competitorsAvgPrice) ||
                    (filters.myprice.includes('lowest') && product.competitorsMinPrice && product.price < product.competitorsMinPrice)))) &&
                (filters.created_at_start === null || (product.created_at && (toDateString(new Date(product.created_at)) >= toDateString(new Date(filters.created_at_start))))) &&
                (filters.created_at_end === null || (product.created_at && (toDateString(new Date(product.created_at)) <= toDateString(new Date(filters.created_at_end))))) &&

                (filters.drop_shipping_supplied === null || (filters.drop_shipping_supplied && product.drop_shipping && product.drop_shipping.stock && product.drop_shipping.stock > 0) || (!filters.drop_shipping_supplied && product.drop_shipping && product.drop_shipping.stock === 0 )) &&
                (filters.drop_shipping_loss === null || (filters.drop_shipping_loss && product.drop_shipping && product.drop_shipping_cost && product.price && product.drop_shipping_cost > product.price) || (!filters.drop_shipping_loss && product.drop_shipping && product.drop_shipping_cost && product.price && product.drop_shipping_cost <= product.price)) &&

                (filters.hasCompetitor === null || (filters.hasCompetitor && product.competitorsCount !== 0) || (!filters.hasCompetitor && product.competitorsCount === 0)) &&
                (filters.variant_matching_needed === null || (((filters.variant_matching_needed && product.variant_matching_needed ) || (!filters.variant_matching_needed && !product.variant_matching_needed)))) &&
                (filters.cheapestCompetitor === null || (product.price && product.competitorsMinPrice && product.competitorsMinName.toLowerCase().indexOf(filters.cheapestCompetitor.toLowerCase()) !== -1  && product.price > product.competitorsMinPrice)) &&
                (filters.globalFilter === "" || (product.name && (new RegExp('^' + ("*"+filters.globalFilter+"*").replace(/\*/g, '.*') + '$').test(product.name.toLowerCase()) || product.name.toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1))
                    || (product.description && product.description.toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.category && product.category.toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.brand && product.brand.toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.ref_id && product.ref_id.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.sku && product.sku.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.mpn && product.mpn.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.ean && product.ean.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.upc && product.upc.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.asin && product.asin.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.barcode && product.barcode.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.competitorsMinName && product.competitorsMinName.toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.competitorsMaxName && product.competitorsMaxName.toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.price && product.price.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.competitorsMinPrice && product.competitorsMinPrice.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.competitorsMaxPrice && product.competitorsMaxPrice.toString().toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)
                    || (product.properties && JSON.stringify(product.properties.map(p=> p.value)).toLowerCase().indexOf(filters.globalFilter.toLowerCase()) !== -1)


                ) ;
        })

        if (result.length < first)
            setFirst(Math.floor(result.length/rows)*rows);

        if (sortField === "price" ||
            sortField === "map" ||
            sortField === "mrp" ||
            sortField === "position" ||
            sortField === "ref_id" ||
            sortField === "pricing_strategy.min_value" ||
            sortField === "pricing_strategy.max_value" ||
            sortField === "suggested_price" ||
            sortField === "target_price" ||
            sortField === "review.rating_count" ||
            sortField === "review.rating" ||
            sortField === "competitorsMinPrice" ||
            sortField === "competitorsAvgPrice" ||
            sortField === "competitorsAvgPriceDiff" ||
            sortField === "competitorsMaxPrice" ||
            sortField === "competitorsCount" ||
            sortField === "drop_shipping_price" ||
            sortField === "drop_shipping_cost" ||
            sortField === "drop_shipping_review" ||
            sortField === "drop_shipping_rate" ||
            sortField === "drop_shipping_best_sellers_rank" ||
            sortField === "competitorSuggestionsCount")
        {
            if (sortField.includes('.'))
            {
                const col = sortField.split('.')[0];
                const field = sortField.split('.')[1];
                result.sort(function (a, b) {return sortOrder * (parseFloat(!(a && a[col] && a[col][field]) ? 0 : a[col][field].toString().split(" ")[0]) - parseFloat(!(b && b[col] && b[col][field]) ? 0 : b[col][field].toString().split(" ")[0]));});
            }
            else
                result.sort(function (a, b) {return sortOrder * (parseFloat(!(a && a[sortField]) ? 0 : a[sortField].toString().split(" ")[0]) - parseFloat(!(b && b[sortField]) ? 0 : b[sortField].toString().split(" ")[0]));});

        }
        else if (sortField === "properties")
            result.sort(function(a, b){return sortOrder * (!(a && a[sortField]) ? "" : JSON.stringify(a[sortField])).localeCompare((!(b && b[sortField]) ? "" : JSON.stringify(b[sortField])), undefined, {sensitivity: 'base'});});
            // else if (sortField.startsWith("properties."))
            // {
            //     const col = sortField.split('.')[0];
            //     const field = sortField.split('.')[1];
            //     result.sort(function(a, b){
            //         let aValue = a?[col].filter(p=>p.name.toLowerCase()===field.toLowerCase())?[0].value?? "";
            //         let bValue = b?[col].filter(p=>p.name.toLowerCase()===field.toLowerCase())?[0].value?? "";
            //         return sortOrder * aValue.localeCompare(bValue), undefined, {sensitivity: 'base'};
            //     });
        // }
        else
            result.sort(function(a, b){return sortOrder * (!(a && a[sortField]) ? "" : a[sortField].toString()).localeCompare((!(b && b[sortField]) ? "" : b[sortField].toString()) , undefined, {sensitivity: 'base'});});

        setFilteredProducts(result);
    }

    //#region Add-Edit Product

    const showAdd = () => {
        setPage(pages.add);
    };

    const showUpdate = (rowData) => {
        setSelectedProduct(rowData);
        setPage(pages.edit);
    };

    const cancel = () => {
        setSelectedProduct({});
        setPage(pages.list);
    }

    const detail = (rowData) => {
        setSelectedProductId(rowData.id);
        setPage(pages.detail);
    }

    const add = (new_products) => {

        new_products.product_url.split('||').forEach(product_url=> {

            let is_exists = products.some(p => p.product_url.toLowerCase() === product_url.toLowerCase());

            if (is_exists) {
                toast.current.show({
                    severity: 'warn',
                    summary: t('product.product_exist_summary'),
                    detail: t('product.product_exist_detail')
                });
                return;
            }
            let addedProduct = {campaign_id: selectedCampaign.id, competitors: []};

            addedProduct.competitors.push({
                product_url: product_url,
                is_mine: true,
            });

            productService.addProduct(addedProduct).then(addedProduct => {
                addedProduct.product_url = product_url;
                products.push(addedProduct);
                scrapingRequests[addedProduct.id] = addedProduct.scraping_request;
                setProducts([...products]);
                setScrapingRequests({...scrapingRequests});
                setPage(pages.list);

                toast.current.show({
                    severity: 'success',
                    summary: t('product.add_product'),
                    detail: t('message_detail.successful')
                });
            }).catch(error => {
                setError(error);
                setError(null);
            });
        })


    }

    const update = (product) =>{

        productService.updateProduct(product.id, product).then(updatedProduct => {

            let index = products.findIndex(p=> p.id === product.id);

            products[index].name = product.name;
            products[index].brand = product.brand;
            products[index].category = product.category;
            products[index].barcode = product.barcode;
            products[index].sku = product.sku;
            products[index].mpn = product.mpn;
            products[index].ean = product.ean;
            products[index].upc = product.upc;
            products[index].asin = product.asin;
            products[index].tags = product.tags;
            products[index].ref_id = product.ref_id;
            products[index].vat = product.vat;
            products[index].search_key = product.search_key;
            products[index].properties = product.properties;
            products[index].campaign_id = product.campaign_id;
            products[index].is_active = product.is_active;

            setProducts([...products]);
            setPage(pages.list);


            toast.current.show({
                severity: 'success',
                summary: t('product.edit_product'),
                detail: t('message_detail.successful')
            });
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const mergeProducts = () => {
        productService.mergeProducts(productsToMerge).then(mergedProducts=>{
            setProducts([...products.filter((product)=>productsToMerge.findIndex(p=> p.keepAsMine === false && p.id===product.id) === -1)]);
            setProductsToMerge([]);
            toast.current.show({
                severity: 'success',
                summary: t('product.merge_product'),
                detail: t('message_detail.successful')
            });
        }).catch(error => {
            setError(error);
            setError(null);
        });
    }

    const onFilterChange = (e) =>{
        const { key, value } = getKeyValue(e);

        setFilters({...filters, [key]:value});
    };
    //#endregion Add-Edit Product

    //#region List Actions

    const getNotDoneProductScrapingRequests = () => {
        if (selectedCampaign && selectedCampaign.id) {
            scraperService.getNotDoneProductScrapingRequests(selectedCampaign.id).then(scrapingRequests => {
                setScrapingRequests(scrapingRequests);
            }).catch(error => {
                setError(error);
                setError(null);
            });
        }
    }

    const refresh = (start_index) => {
        if (selectedCampaign && selectedCampaign.id)
        {
            if (start_index === 0){
                setLoading(true);
                setLoadingMessage(true);
                setLastResultCount(-1);
                setProducts([]);
                setProductsToMerge([]);
                getNotDoneProductScrapingRequests();
            }

            productService.getProducts("", "", filters.is_active, "", selectedCampaign.id, start_index).then( pList => {

                setLastResultCount(pList.length);

                pList = pList.map(p=> {
                    return {...p, "website" : getHostName(p.product_url && p.product_url !== "no url" ? p.product_url : p.competitors?.find(c=> c.is_mine === true)?.product_url)}
                })

                const allProducts = pList.concat(start_index === 0 ? [] : products);

                const distBrands = [...new Set(allProducts.map(allProducts => allProducts.brand ? allProducts.brand : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                const distFilterBrands = [...new Set(allProducts.map(allProducts => allProducts.brand ? allProducts.brand : ""))].map(brand => {return {"label":brand, "value": brand}}).sort((a, b) => (a ? a.label : "").localeCompare((b ? b.label : ""), undefined, {sensitivity: 'base'}));

                const distCategories = [...new Set(allProducts.map(allProducts => allProducts.category ? allProducts.category : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                const distFilterCategories = [...new Set(allProducts.map(allProducts => allProducts.category ? allProducts.category : ""))].map(category => {return {"label":category, "value": category}}).sort((a, b) => (a ? a.label : "").localeCompare((b ? b.label : ""), undefined, {sensitivity: 'base'}));

                const distFilterWebSites = [...new Set(allProducts.map(allProducts => allProducts.website ? allProducts.website : ""))].map(website => {return {"label":website, "value": website}}).sort((a, b) => (a ? a.label : "").localeCompare((b ? b.label : ""), undefined, {sensitivity: 'base'}));

                const flattenTags = [... new Set(allProducts.flatMap(product => product.tags))]
                const distTags = flattenTags ? flattenTags.filter(a=> a !== null).sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'})) : [];
                const distFilterTags = flattenTags ? flattenTags.filter(a=> a !== null).map(tag => {return {"label":tag, "value": tag}}).sort((a, b) => (a ? a.label : "").localeCompare((b ? b.label : ""), undefined, {sensitivity: 'base'})) : [];

                const flattenDiscountTypes = [... new Set(allProducts.flatMap(product => product.discount_types))]
                const distDiscountTypes = flattenDiscountTypes ? flattenDiscountTypes.filter(a=> a !== null).sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'})) : [];
                const distFilterDiscountTypes = flattenDiscountTypes ? flattenDiscountTypes.filter(a=> a !== null).map(discount_type => {return {"label":discount_type, "value": discount_type}}).sort((a, b) => (a ? a.label : "").localeCompare((b ? b.label : ""), undefined, {sensitivity: 'base'})) : [];

                setProducts([...allProducts]);
                setCategories(distCategories);
                setFilterCategories(distFilterCategories);
                setBrands(distBrands);
                setFilterBrands(distFilterBrands);
                setFilterWebSites(distFilterWebSites);

                setTags(distTags);
                setFilterTags(distFilterTags);

                setDiscountTypes(distDiscountTypes);
                setFilterDiscountTypes(distFilterDiscountTypes);

            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
                setLoadingMessage(false);
                setProducts([]);
            });
        }
    };

    const onClear = () => {
        setFilters({...defaultFilters});
    };

    const deleteProduct = (rowData) => {
        productService.deleteProduct(rowData).then(() => {
            setProducts(products.filter(product => product.id !== rowData.id));
            toast.current.show({severity: 'success', summary: t('actions.delete'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const changeIsActive = (rowData) => {
        productService.updateProductIsActive(rowData.id, rowData.is_active).then(() => {
            products.find(product => product.id === rowData.id).is_active = !rowData.is_active;
            setProducts([...products]);
            toast.current.show({severity: 'success', summary: rowData.is_active ? t('product.activated') : t('product.deactivated'), detail: t('message_detail.successful')})
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const selectProduct = (e) => {
        if (!loading && !isPreventSelect(e.originalEvent.target)){
            if (e.data)
                detail(e.data);
            //else if (selectedProductId)
            //    detail(selectedProductRow());
        }
    };

    const selectedProductRow = () => {
        if (selectedProductId)
            return products.find(product=> product.id === selectedProductId);
        else
            return null;
    };

    const detailComplete = () => {
        setPage(pages.list);
    };

    const goToPrevious = () => {
        let index = filteredProducts.findIndex(p=> p.id === selectedProductId);
        let newIndex = (index === 0 ? filteredProducts.length - 1 : index - 1);

        setSelectedProductId(filteredProducts[newIndex].id);
        setFirst(Math.floor(newIndex/rows)*rows);
    };

    const goToNext = () => {
        let index = filteredProducts.findIndex(p=> p.id === selectedProductId);
        let newIndex = (index === filteredProducts.length - 1 ? 0 : index + 1);

        setSelectedProductId(filteredProducts[newIndex].id);
        setFirst(Math.floor(newIndex/rows)*rows);
    };

    const exportList = () => {
        exportService.exportProductList(selectedCampaign.id, exportFields, exportableFields(t, selectedCampaign), filters).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('menu_item.products') + "_" + toDateString(new Date()) + ".xlsx");
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    }

    //#endregion ListAction

    //#region DataTable Items

    const indexTemplate = (data, props) => {
        if (loading)
            return <Skeleton />;
        return <Element name={data.id.toString().split("-").join("")} style={{textAlign:'right'}}>{props.rowIndex + 1}</Element>;
    }

    const productImageTemplate = (rowData) => {
        if (loading)
            return <Skeleton size="7rem"/>;
        return <div>
            <span className="p-column-title">{t('product.image')}</span>
            <img referrerPolicy='no-referrer' className="img-fluid-thumb" src={rowData.image} alt=""/>
        </div>;
    }

    const productWebsiteTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title">{t('product.website')}</span>
            {rowData.website}
        </div>;
    };

    const asinTemplate = (rowData) => {
        const style = selectedColumns.includes("asin") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return rowData.asin ? <React.Fragment>
            <span className="p-column-title" style={style}>{t('product.asin')}</span>
            {rowData.asin}
            <CopyToClipboard textToCopy={rowData.asin} />
        </React.Fragment> : null;
    };

    const eanTemplate = (rowData) => {
        const style = selectedColumns.includes("ean") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return rowData.ean ? <React.Fragment>
            <span className="p-column-title" style={style}>{t('product.ean')}</span>
            {rowData.ean}
            <CopyToClipboard textToCopy={rowData.ean} />
        </React.Fragment> : null;
    };

    const mpnTemplate = (rowData) => {
        const style = selectedColumns.includes("mpn") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return rowData.mpn ? <React.Fragment>
            <span className="p-column-title" style={style}>{t('product.mpn')}</span>
            {rowData.mpn}
            <CopyToClipboard textToCopy={rowData.mpn} />
        </React.Fragment> : null;
    };

    const upcTemplate = (rowData) => {
        const style = selectedColumns.includes("upc") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return rowData.upc ? <React.Fragment>
            <span className="p-column-title" style={style}>{t('product.upc')}</span>
            {rowData.upc}
            <CopyToClipboard textToCopy={rowData.upc} />
        </React.Fragment> : null;
    };

    const skuTemplate = (rowData) => {
        const style = selectedColumns.includes("sku") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return rowData.sku ? <React.Fragment>
            <span className="p-column-title" style={style}>{t('product.sku')}</span>
            {rowData.sku}
            <CopyToClipboard textToCopy={rowData.sku} />
        </React.Fragment> : null;
    };

    const barcodeTemplate = (rowData) => {
        const style = selectedColumns.includes("barcode") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return rowData.barcode ? <React.Fragment>
            <span className="p-column-title" style={style}>{t('product.barcode')}</span>
            {rowData.barcode}
            <CopyToClipboard textToCopy={rowData.barcode} />
        </React.Fragment> : null;
    };

    const productNameTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        const scraping_request = scrapingRequests[rowData.id]
        return  <React.Fragment>
            <span className="p-column-title">{t('product.product_name')}</span>
            {rowData.name && rowData.name !== "" ? rowData.name :
                <span style={{color: scraping_request && scraping_request.state && scraping_request.state === job_states.in_preparation ? "deepskyblue" : "orange"}}>{
                    t(scraping_request && scraping_request.state && scraping_request.state === job_states.in_preparation ? 'product.site_to_be_scraped' : 'product.product_to_be_scraped', {website: getHostName(scraping_request?.url)})}</span>
                }
            {rowData.name && <CopyToClipboard textToCopy={rowData.name} />}
            {!selectedColumns.includes("category") && rowData.category && <div>{categoryTemplate(rowData)}</div>}
            {!selectedColumns.includes("sub_category") && rowData.sub_category && <div>{subCategoryTemplate(rowData)}</div>}
            {!selectedColumns.includes("type") && rowData.type && <div>{typeTemplate(rowData)}</div>}
            {!selectedColumns.includes("brand") && rowData.brand && <div>{brandTemplate(rowData)}</div>}
            {!selectedColumns.includes("sku") && rowData.sku && <div>{skuTemplate(rowData)}</div>}
            {!selectedColumns.includes("mpn") && rowData.mpn && <div>{mpnTemplate(rowData)}</div>}
            {!selectedColumns.includes("ean") && rowData.ean && <div>{eanTemplate(rowData)}</div>}
            {!selectedColumns.includes("upc") && rowData.upc && <div>{upcTemplate(rowData)}</div>}
            {!selectedColumns.includes("asin") && rowData.asin && <div>{asinTemplate(rowData)}</div>}
            {!selectedColumns.includes("barcode") && rowData.barcode && <div>{barcodeTemplate(rowData)}</div>}
            {!selectedColumns.includes("properties") && rowData.properties && <div>{variantTemplate(rowData)}</div>}
        </React.Fragment>;
    };

    const variantTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        let variantList = null;
        if (rowData.properties !== null) {
            variantList = rowData.properties.map(property => {



                if (property.value !== "" && !selectedCampaign?.settings?.special_fields?.some(sf=> sf.name === "properties." + property.name.toLowerCase() && sf.selected)
                && property.name.toLowerCase() !== "brand" && property.name.toLowerCase() !== "category" && property.name.toLowerCase() !== "description")
                    return <div key={property.name}><span className="p-column-title" style={{display:"inline-block"}}>{t("product.properties." + property.name.toLowerCase()).replace('product.properties.', '')}</span>{property.value}</div>;
            });
        }
        return variantList;
    };

    const productDescriptionTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <ScrollPanel style={{height: '110px', fontSize:"0.8em"}}>
            <span className="p-column-title">{t('product.description')}</span>
            <div dangerouslySetInnerHTML={{__html: rowData.description}}></div>
        </ScrollPanel>;
    };

    const searchKeyTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <React.Fragment>
            <span className="p-column-title">{t('product.search_key')}</span>
            {rowData.search_key}
        </React.Fragment>;
    };

    const categoryTemplate = (rowData) => {
        const style = selectedColumns.includes("category") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return <React.Fragment>
            <span className="p-column-title" style={style}>{t('product.category')}</span>
            {rowData.category}
        </React.Fragment>;
    };

    const subCategoryTemplate = (rowData) => {
        const style = selectedColumns.includes("sub_category") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title" style={style}>{t('product.sub_category')}</span>
            {rowData.sub_category}
        </div>;
    };

    const typeTemplate = (rowData) => {
        const style = selectedColumns.includes("type") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title" style={style}>{t('product.type')}</span>
            {rowData.type}
        </div>;
    };

    const brandTemplate = (rowData) => {
        const style = selectedColumns.includes("brand") ? {} : {display:"inline-block"};
        if (loading)
            return <Skeleton />;
        return <React.Fragment>
            <span className="p-column-title" style={style}>{t('product.brand')}</span>
            {rowData.brand}
        </React.Fragment>;
    };

    const tagsTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <React.Fragment>
            {rowData.tags?.map(t => {
                return <Tag value={t} className="p-mr-1" rounded/>
            })}
        </React.Fragment>;
    };

    const productDiscountTypeTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <React.Fragment>
            {rowData.discount_types?.map(t => {
                return t && <Tag value={t} className="p-mr-1" rounded/>
            })}
        </React.Fragment>;
    };

    const refIdTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <React.Fragment>
            <span className="p-column-title">{t('product.ref_id')}</span>
            {rowData.ref_id}
        </React.Fragment>;
    };

    const vatTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <React.Fragment>
            <span className="p-column-title">{t('product.vat')}</span>
            {rowData.vat}
        </React.Fragment>;
    };

    const productPriceTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        let color = "black";
        if (rowData.price && rowData.competitorsMinPrice && rowData.price < rowData.competitorsMinPrice )
            color= "green";
        else if (rowData.price && rowData.competitorsMinPrice && rowData.competitorsMaxPrice && rowData.price > rowData.competitorsMinPrice && rowData.price < rowData.competitorsMaxPrice )
            color= "firebrick";
        // else if (rowData.price && rowData.competitorsMinPrice && rowData.competitorsAvgPrice && rowData.price > rowData.competitorsAvgPrice && rowData.price < rowData.competitorsMaxPrice)
        //     color= "red";
        else if (rowData.price && rowData.competitorsMaxPrice && rowData.price > rowData.competitorsMaxPrice )
            color= "firebrick";

        return editingPrice_productID === rowData.id ?
            <div className="p-grid" style={{textAlign:'center'}}>
                <Loading/>
            </div> :
            <div className="p-text-nowrap p-text-right" style={{color: color}}>
                <span className="p-column-title">{t('product.price')}</span>
                {formatCurrency(rowData.price, rowData.currency)}
            </div>;
    };

    const violationStrategyEditor = (props) =>{

        const currency = props.rowData.violation_strategy && props.rowData.violation_strategy.currency ? props.rowData.violation_strategy.currency
            : props.rowData.currency && props.rowData.currency !== "" ? props.rowData.currency : user().currency;

        return <div>
            {productPriceTemplate(props.rowData)}
                <div>
                <Number id="min_value" name="min_value" value={violationStrategyMinValue}
                        onChange={(e) => {setViolationStrategyMinValue(getKeyValue(e).value);}} />
                     {currency !== props.rowData.currency && currency}
                </div>
        </div>;
    };

    const onViolationStrategyEditorCancel = () => {
        setViolationStrategyMinValue(0);
    }

    const onViolationStrategyEditorSubmit = (e) => {
        let product = {...[...e.columnProps.value][e.columnProps.rowIndex]};

        let vs = product['violation_strategy'];

        if ((vs && vs.min_value === parseFloat(violationStrategyMinValue)) || parseFloat(violationStrategyMinValue) === 0) {
            setViolationStrategyMinValue(0);
            return;
        }

        if (vs && 'min_value' in vs)
            vs.min_value = violationStrategyMinValue;
        else
        {
            if (!vs)
                vs = {};
            vs.min_value = violationStrategyMinValue;
            vs.currency = product.currency && product.currency !== "" ? product.currency : user().currency;
        }

        setEditingPrice_productID(product.id);
        productService.savePriceViolation(product.id, vs).then(r => {

            products.find(p => p.id === product.id).violation_strategy = r.violation_strategy;
            products.find(p => p.id === product.id).price = r.calculated_violation_min_price;

            setProducts([...products])
            setViolationStrategyMinValue(0);

            toast.current.show({
                severity: 'success',
                summary: t('product_detail.save_violation_strategy'),
                detail: t('message_detail.successful')
            });
            setEditingPrice_productID("");
        }).catch(error => {
            setError(error);
            setError(null);
            setEditingPrice_productID("");
        });
    };



    const pricingStrategyMinValueTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return editingMinPrice_productID === rowData.id ?
            <div className="p-grid" style={{textAlign:'center'}}>
                <Loading/>
            </div> :
        <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('reprice.min_value')}</span>
            {rowData.pricing_strategy ? formatCurrency(rowData.pricing_strategy.min_value, rowData.currency): ""}
        </div>;
    };

    const pricingStrategyEditor = (props) =>{
        return <div>
            {pricingStrategyMinValueTemplate(props.rowData)}
            <Number id="min_value" name="min_value" value={pricingStrategyMinValue} onChange={(e) => {setPricingStrategyMinValue(getKeyValue(e).value);}} />
        </div>;
    };

    const onPricingStrategyEditorCancel = () => {
        setPricingStrategyMinValue(0);
    }

    const onPricingStrategyEditorSubmit = (e) => {
        let product = {...[...e.columnProps.value][e.columnProps.rowIndex]};

        let ps = product['pricing_strategy'];

        if ((ps && ps.min_value === parseFloat(pricingStrategyMinValue)) || parseFloat(pricingStrategyMinValue) === 0) {
            setPricingStrategyMinValue(0);
            return;
        }

        if (ps && 'min_value' in ps)
            ps.min_value = pricingStrategyMinValue;
        else
        {
            if (!ps)
                ps = {};
            ps.ignore_some_competitors = false;
            ps.is_active = false;
            ps.is_auto_update = false;
            ps.optimum_value = 0;
            ps.protection_strategy = "min_value";
            ps.reduction_type = "campaign";
            ps.reduction_value = 0;
            ps.min_value = pricingStrategyMinValue;
        }

        setEditingMinPrice_productID(product.id);

        productService.savePricingStrategy(product.id, ps).then(r => {

            products.find(p => p.id === product.id).pricing_strategy = ps;
            products.find(p => p.id === product.id).suggested_price = r.suggested_price;
            products.find(p => p.id === product.id).target_price = r.target_price;

            setProducts([...products]);

            setPricingStrategyMinValue(0);
            toast.current.show({
                severity: 'success',
                summary: t('reprice.save_pricing_strategy'),
                detail: t('message_detail.successful')
            });
            setEditingMinPrice_productID("");
        }).catch(error => {
            setError(error);
            setError(null);
            setEditingMinPrice_productID("");
        });
    }


    const pricingStrategyMaxValueTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return editingMaxPrice_productID === rowData.id ?
            <div className="p-grid" style={{textAlign:'center'}}>
                <Loading/>
            </div> :
            <div className="p-text-nowrap p-text-right">
                <span className="p-column-title">{t('reprice.max_value')}</span>
                {rowData.pricing_strategy ? formatCurrency(rowData.pricing_strategy.max_value, rowData.currency): ""}
            </div>;
    };

    const pricingStrategyMaxEditor = (props) =>{
        return <div>
            {pricingStrategyMaxValueTemplate(props.rowData)}
            <Number id="max_value" name="max_value" value={pricingStrategyMaxValue} onChange={(e) => {setPricingStrategyMaxValue(getKeyValue(e).value);}} />
        </div>;
    };

    const onPricingStrategyMaxEditorCancel = () => {
        setPricingStrategyMaxValue(0);
    };

    const onPricingStrategyMaxEditorSubmit = (e) => {

        let product = {...[...e.columnProps.value][e.columnProps.rowIndex]};

        let ps = product['pricing_strategy'];

        if ((ps && ps.max_value === parseFloat(pricingStrategyMaxValue)) || parseFloat(pricingStrategyMaxValue) === 0) {
            setPricingStrategyMaxValue(0);
            return;
        }

        if (ps && 'min_value' in ps)
            ps.max_value = pricingStrategyMaxValue;
        else
        {
            if (!ps)
                ps = {};
            ps.ignore_some_competitors = false;
            ps.is_active = false;
            ps.is_auto_update = false;
            ps.optimum_value = 0;
            ps.protection_strategy = "min_value";
            ps.reduction_type = "campaign";
            ps.reduction_value = 0;
            ps.min_value = 0;
            ps.max_value = pricingStrategyMaxValue;
        }

        setEditingMaxPrice_productID(product.id);
        productService.savePricingStrategy(product.id, ps).then(r => {

            products.find(p => p.id === product.id).pricing_strategy = ps;
            products.find(p => p.id === product.id).suggested_price = r.suggested_price;
            products.find(p => p.id === product.id).target_price = r.target_price;

            setProducts([...products]);
            setPricingStrategyMaxValue(0);
            toast.current.show({
                severity: 'success',
                summary: t('reprice.save_pricing_strategy'),
                detail: t('message_detail.successful')
            });
            setEditingMaxPrice_productID("");
        }).catch(error => {
            setError(error);
            setError(null);
            setEditingMaxPrice_productID("");
        });
    }

    const productTargetPriceTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        let suggestion_direction = "";
        if (parseFloat(rowData.price) > parseFloat(rowData.target_price))
            suggestion_direction = "down";
        else if (parseFloat(rowData.price) < parseFloat(rowData.target_price))
            suggestion_direction = "up";

        return <div data-tour="step6" className="p-text-nowrap p-text-right" style={{color: suggestion_direction ==="down" ? "red" : (suggestion_direction ==="up" ? "green" : "")}} >
            <span className="p-column-title">{t('product.target_price')}</span>
            {formatCurrency(rowData.target_price, rowData.currency)}&nbsp;<span className={suggestion_direction ==="down" ? "pi pi-arrow-down" : (suggestion_direction ==="up" ? "pi pi-arrow-up" : "")}/>
        </div>;
    };

    const productSuggestedPriceTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div data-tour="step6" style={{whiteSpace: 'nowrap'}} >
            <span className="p-column-title">{t('product.suggested_price')}</span>
            {formatCurrency(rowData.suggested_price, rowData.currency)}
        </div>;
    };

    const productMAPTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('price_violation.map_abbreviation')}</span>
            {formatCurrency(rowData?.violation_strategy?.min_value, rowData?.violation_strategy?.currency)}
        </div>;
    };

    const productMRPTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('price_violation.mrp_abbreviation')}</span>
            {formatCurrency(rowData?.violation_strategy?.max_value, rowData?.violation_strategy?.currency)}
        </div>;
    };

    const productCompMinNameTemplate = (rowData) => {
        if (loading)
            return <Skeleton/>;
        return <React.Fragment>
            <span className="p-column-title">{t('product.comp_min_name')}</span>
            {rowData.competitorsMinName}
        </React.Fragment>;
    };

    const productCompMinTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        let tooltip = selectedColumns.includes("competitorsMinName") ? "" : rowData.competitorsMinName;
        return <div data-tour="step7" data-toggle="tooltip" title={tooltip} className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.comp_min')}</span>
            {formatCurrency(rowData.competitorsMinPrice, rowData.currency)}
        </div>;
    };
    const productCompAvgTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.comp_avg')}</span>
            {formatCurrency(rowData.competitorsAvgPrice, rowData.currency)}
        </div>;
    };
    const productCompAvgDiffTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div className="p-text-nowrap p-text-right">{formatCurrency(rowData.competitorsAvgPriceDiff, rowData.currency)}</div>;
    };

    const productCompMinShippingPriceTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div className="p-text-nowrap p-text-right">{formatCurrency(rowData.competitorsMinShippingPrice, rowData.currency)}</div>;
    };

    const productCompMaxShippingPriceTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div className="p-text-nowrap p-text-right">{formatCurrency(rowData.competitorsMaxShippingPrice, rowData.currency)}</div>;
    };

    const productCompMaxNameTemplate = (rowData) => {
        if (loading)
            return <Skeleton/>;
        return <React.Fragment>
            <span className="p-column-title">{t('product.comp_max_name')}</span>
            {rowData.competitorsMaxName}
        </React.Fragment>;
    };

    const productCompMaxTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        let tooltip = selectedColumns.includes("competitorsMaxName") ? "" : rowData.competitorsMaxName;
        return <div data-tour="step8" data-toggle="tooltip" title={tooltip} className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.comp_max')}</span>
            {formatCurrency(rowData.competitorsMaxPrice, rowData.currency)}
        </div>;
    };

    const getPropertyTemplate = (rowData, e) =>{
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title">{t('product.' + e.field)}</span>
            {rowData.properties?.find(p=> p.name.toLowerCase()===e.field.replace('properties.', ''))?.value}
        </div>;
    }

    const reviewNumberTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title">{t('product.review_number')}</span>
            {rowData.review_number}
        </div>;
    };

    const reviewTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title">{t('product.review')}</span>
            {rowData.review && rowData.review.rating_count}
        </div>;
    };

    const rateTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title">{t('product.rate')}</span>
            {rowData.review && rowData.review.rating}
        </div>;
    };

    const productDropShippingPriceTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.drop_shipping_price')}</span>
            {formatCurrency(rowData.drop_shipping_price, rowData.currency)}
        </div>;
    };

    const productDropShippingCostTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.drop_shipping_cost')}</span>
            {formatCurrency(rowData.drop_shipping_cost, rowData.currency)}
        </div>;
    };

    const productDropShippingReviewTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title">{t('product.drop_shipping_review')}</span>
            {rowData.drop_shipping && rowData.drop_shipping.rating_count}
        </div>;
    };

    const productDropShippingRateTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title">{t('product.drop_shipping_rate')}</span>
            {rowData.drop_shipping && rowData.drop_shipping.rating}
        </div>;
    };

    const productDropShippingBestSellersRankTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div>
            <span className="p-column-title">{t('product.drop_shipping_best_sellers_rank')}</span>
            {rowData.drop_shipping && rowData.drop_shipping.best_sellers_rank}
        </div>;
    };

    const positionTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <React.Fragment>
            <span className="p-column-title">{t('product.drop_shipping_best_sellers_rank')}</span>
            {rowData.position}
        </React.Fragment>;
    };

    const shelvePositionTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <React.Fragment>
            <span className="p-column-title">{t('product.shelve_position')}</span>
            {rowData.current_shelve_position}
        </React.Fragment>;
    };

    const competitorsCountTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;

        return <div data-tour="step9" className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.comp_count')}</span>
            {rowData.variant_matching_needed ? <Button icon="pi pi-images" className="p-button-text p-button-danger imageButton" tooltip={t('product.variant_available')} /> : null}
            {rowData.competitorsCount}
        </div>;
    };
    const urlCountTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;

        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.url_count')}</span>
            {rowData.url_count}
        </div>;
    };
    const competitorSuggestionsCountTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div data-tour="step10" className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.suggest_count')}</span>
            {rowData.competitorSuggestionsCount}
        </div>;
    }
    const statusTemplate = (rowData) => {
        if (loading)
            return <Skeleton />;
        return <div className="p-text-nowrap p-text-right">
            <span className="p-column-title">{t('product.status')}</span>
            {rowData.is_active ? t('actions.active') : t('actions.passive')}
        </div>;
    };

    const actionTemplate = (rowData) => {
        if (loading)
            return <div className="p-grid">
                <Skeleton shape="circle" width="2.5rem" height="1.5rem" />
                <Skeleton shape="circle" width="2.5rem" height="2.5rem" className="p-ml-2"/>
                <Skeleton shape="circle" width="2.5rem" height="2.5rem" />
                <Skeleton shape="circle" width="2.5rem" height="2.5rem" className="p-ml-2"/>
        </div>;
        const scraping_request = scrapingRequests[rowData.id]
        let newProductRequest = null
        if (scraping_request) {
            newProductRequest = {
                url: scraping_request.url,
                urls: [scraping_request.url],
                type: "manuel_product_create",
                campaign_id: rowData.campaign_id,
                product_id: rowData.id};
        }

        return <div>
            <span className="p-column-title">{t('table_headers.action')}</span>
            <InputSwitch checked={rowData.is_active} data-tour="step11" onChange={() => changeIsActive(rowData)} tooltipOptions={{position: 'bottom'}} tooltip={rowData.is_active ? t('actions.deactivate') : t('actions.activate') } />
            {rowData.name ?
                <Button icon="pi pi-pencil" data-tour="step12" className="p-button-outlined p-button-rounded p-button-success p-ml-1" tooltipOptions={{position: 'bottom'}} tooltip={t('actions.edit')} onClick={() => showUpdate(rowData)}/>
                :
                <ScrapeStatus type="product" scraping_request={scraping_request}
                campaign_id={rowData.campaign_id}
                product_id={rowData.id}
                competitor_url={scraping_request?.url}
                newRequest={newProductRequest}
                newRequestCompleted={null} />
            }


            <Button icon="pi pi-clone" className="p-button-outlined p-button-rounded p-button p-mt-2 p-ml-1 p-mr-1" tooltipOptions={{position: 'bottom'}} tooltip={t('actions.merge')} onClick={() => {
                if (productsToMerge.findIndex(p=> p.id === rowData.id) === -1) {
                    productsToMerge.push({...rowData, keepAsMine: productsToMerge.length === 0});
                    setProductsToMerge([...productsToMerge]);
                }
                setShowMergePanel(true);
            }}/>
            <Button icon="pi pi-trash" data-tour="step13" className="p-button-outlined p-button-rounded p-button-danger p-mt-2 p-ml-2" tooltipOptions={{position: 'bottom'}} tooltip={t('actions.delete')} onClick={() => {
                confirmDialog({
                    message: t('dialogs.product.delete_msg'),
                    header: t('dialogs.product.delete'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptClassName: 'p-button-danger',
                    accept: () => deleteProduct(rowData),
                    acceptLabel: t('yes_no.yes'),
                    rejectLabel: t('yes_no.no')
                });
            }}/>

        </div>;
    };

    const onSort = (e) =>{
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }
    //#endregion DataTable Items

   const mystock = [
        {label: t('actions.all'), value: null},
        {label: t('product.stock_in'), value: true},
        {label: t('product.stock_out'), value: false},
    ];

    const reprice = [
        {label: t('actions.all'), value: null},
        {label: t('product.reprice_dec'), value: true},
        {label: t('product.reprice_inc'), value: false},
    ];

    const myprice = [
        {label: t('product.myprice_highest'), value: 'highest'},
        {label: t('product.myprice_high'), value: 'high'},
        {label: t('product.myprice_avg_higher'), value: 'avg_higher'},
        {label: t('product.myprice_avg_lower'), value: 'avg_lower'},
        {label: t('product.myprice_lowest'), value: 'lowest'},
        {label: t('product.myprice_same'), value: 'same'},
    ];

    const isActiveOptions = [
        {label: t('actions.active'), value: true},
        {label: t('actions.passive'), value: false}
    ];

    const isDropShippingLoss = [
        {label: t('actions.all'), value: null},
        {label: t('product.loss'), value: true},
        {label: t('product.profit'), value: false}
    ];

    const isDropShippingSupplied = [
        {label: t('actions.all'), value: null},
        {label: t('product.stock_in'), value: true},
        {label: t('product.stock_out'), value: false}
    ];

    const hasCompetitors = [
        {label: t('actions.all'), value: null},
        {label: t('product.has_competitor'), value: true},
        {label: t('product.no_competitor'), value: false}
    ];

    const isVariantMatchingNeeded = [
        {label: t('actions.all'), value: null},
        {label: t('product.needs_variant_matching'), value: true},
        {label: t('product.no_variant_matching'), value: false}
    ];

    const priceHeader = <div>
        <Tooltip tooltip={t('product.violation_price_edit')} icon="pi-pencil"/>
        {t('product.price')}
    </div>;

    const protectionLimitHeader = <div>
        <Tooltip tooltip={t('product.min_price_edit')} icon="pi-pencil"/>
        {t('reprice.min_value')}
    </div>;

    const protectionLimitMaxHeader = <div>
        <Tooltip tooltip={t('product.max_price_edit')} icon="pi-pencil"/>
        {t('reprice.max_value')}
    </div>;

    const suggestedPriceHeader = <div>
        <Tooltip tooltip={t('product.suggested_price_tooltip')}/>
        {t('product.suggested_price')}
    </div>;

    const selectColumnsButton = <Button icon="pi pi-bars" className="p-button-outlined" tooltip={t('product.fields_to_show')} tooltipOptions={{position: 'bottom'}} onClick={(e) => op.current.toggle(e)} />;

    const toolbarLeftContents = loadingMessage ?
        <div style={{ height: '35px' }} >
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
            {t('product.items_loaded', {count: products.length > 0 ? products.length.toString() : ""})}
        </div>
        :
        <React.Fragment>
            <Button icon="pi pi-plus" data-tour="step4" label={t('product.add_product')}
                    tooltipOptions={{position: 'bottom'}} className="p-button-outlined p-button-success"
                    onClick={showAdd} style={{ marginRight:'.5em', top:'4px'}}/>

            <span className="p-float-label">
                <InputText id="globalFilter" name="globalFilter" value={filters.globalFilter}
                           onChange={onFilterChange} style={{width:'20em', marginRight:'.5em'}}/>
                <label htmlFor="globalFilter">{t('product.globalFilter')}</label>
            </span>

            <Button icon="pi pi-filter" data-tour="step1" tooltip={t('product.filter')}
                    tooltipOptions={{position: 'bottom'}} className={JSON.stringify(filters) !== JSON.stringify(defaultFilters) ? "p-button" : "p-button-outlined"}
                    style={{ marginRight:'.5em', top:'4px'}}
                    onClick={() => {setShowFilters(true);}}/>

            <Button icon="pi pi-circle-off" tooltip={t('actions.clear_filters')}
                    tooltipOptions={{position: 'bottom'}} className="p-button-outlined"
                    onClick={onClear} style={{ marginRight:'.5em', top:'4px'}}/>

            <Button icon="pi pi-refresh" data-tour="step5" tooltip={t('actions.refresh')}
                    tooltipOptions={{position: 'bottom'}} className="p-button-outlined"
                    onClick={() => {refresh(0);}} style={{ marginRight:'.5em', top:'4px'}}/>
        </React.Fragment>;

    const toolbarRightContents = !loadingMessage && <React.Fragment>

         <SelectButton value={filters.is_active} optionLabel="label" options={isActiveOptions}
                      onChange={(e) => {onFilterChange({target:{name:"is_active", value:e.value}})}}
                      style={{ marginRight:'.5em'}}/>
        <Button icon="pi pi-sort-alt" tooltip={t('actions.bulk_operations')} tooltipOptions={{position: 'bottom'}}
                className="p-button-outlined p-button-warning"
                onClick={() => setShowBulkOperations(true)}
                style={{ marginRight:'.5em'}}/>

        <Button icon="pi pi-file-excel" data-tour="step3" tooltip={t('actions.excel')}
                tooltipOptions={{position: 'bottom'}}
                className="p-button-outlined p-button-success"
                style={{marginRight:'.5em'}}
                onClick={(e) => opExcelFields.current.toggle(e)} />

        <OverlayPanel ref={opExcelFields} style={{width:"25em", height: '30em'}}>
            <div className="p-grid">
                <div className="p-col-8">
                <ListBox value={exportFields}
                             filterPlaceholder={t('product.fields_to_export')} fixedPlaceholder={true}
                             options={exportableFields(t, selectedCampaign)}
                         multiple filter
                         listStyle={{maxHeight:'24em'}}
                         maxSelectedLabels={0}
                             onChange={(e) => {
                                 localStorage.setItem("exportFields", JSON.stringify(e.value));
                                 setExportFields(e.value);
                             }} />
                </div>
                <div className="p-col-4">

                <Button icon="pi pi-refresh" tooltip={t('actions.reset')}
                        tooltipOptions={{position: 'bottom'}} className="p-button-outlined p-button-help p-mr-1"
                        onClick={()=>{
                            const exportFields = getSelectedExportableFields(t, selectedCampaign, true);
                            localStorage.setItem("exportFields", JSON.stringify(exportFields));
                            setExportFields(exportFields);
                        }} />
                <Button icon="pi pi-file-excel" tooltip={t('actions.excel')}
                        tooltipOptions={{position: 'bottom'}} className="p-button-outlined p-button-success"
                        onClick={exportList} />
                </div>
            </div>

        </OverlayPanel>

    </React.Fragment>;



    return <React.Fragment>

            <div className="card" style={{marginBottom:(showMergePanel && productsToMerge.length > 0) ? "250px" : ""}} hidden={page === pages.detail} >

                <Banner/>

                <Toolbar left={toolbarLeftContents} right={toolbarRightContents} className="p-mb-2" />

                <div data-tour="step2">
                    <DataTable value={loading ? skeletonArray : filteredProducts}
                               paginator={true}
                               responsive={true}
                               rows={rows}
                               first={first}
                               rowsPerPageOptions={[10, 20, 50, 100]}
                               onPage={(e) => {
                                   localStorage.setItem("paginator", JSON.stringify(e.rows));
                                   setFirst(e.first);
                                   setRows(e.rows);
                               }}
                               resizableColumns={true}
                               columnResizeMode="fit"
                               footer={t('product.footer', {count: products.length.toString(), filteredCount: filteredProducts.length.toString()})}
                               selectionMode="single"
                               selection={selectedProductRow()}
                               onRowClick={selectProduct}
                               sortField={sortField}
                               sortOrder={sortOrder}
                               onSort={onSort}
                               ref={dtProducts}
                               editMode="cell">
                        <Column body={indexTemplate} header={selectColumnsButton} headerStyle={{width:'4em'}} />
                        {selectedColumns.includes("image") && <Column field="image" header={t('product.image')} body={productImageTemplate} headerStyle={{width:'10em'}}/>}
                        {selectedColumns.includes("website") && <Column field="website" header={t('product.website')} body={productWebsiteTemplate} sortable  />}
                        {selectedColumns.includes("sku") && <Column field="sku" header={t('product.sku')} body={skuTemplate} sortable  />}
                        {selectedColumns.includes("mpn") && <Column field="mpn" header={t('product.mpn')} body={mpnTemplate} sortable  />}
                        {selectedColumns.includes("ean") && <Column field="ean" header={t('product.ean')} body={eanTemplate} sortable  />}
                        {selectedColumns.includes("upc") && <Column field="upc" header={t('product.upc')} body={upcTemplate} sortable  />}
                        {selectedColumns.includes("asin") && <Column field="asin" header={t('product.asin')} body={asinTemplate} sortable  />}
                        {selectedColumns.includes("barcode") && <Column field="barcode" header={t('product.barcode')} body={barcodeTemplate} sortable />}
                        {selectedColumns.includes("name") && <Column field="name" header={t('product.product_name')} body={productNameTemplate} sortable  headerStyle={{width:'20%'}} />}
                        {selectedColumns.includes("properties") && <Column field="properties" header={t('product.properties.')} body={variantTemplate} sortable  style={{width:'10%'}} />}
                        {selectedColumns.includes("description") && <Column field="description" header={t('product.description')} body={productDescriptionTemplate} sortable  style={{width:'20%'}} />}
                        {selectedColumns.includes("search_key") && <Column field="search_key" header={t('product.search_key')} body={searchKeyTemplate} sortable  style={{width:'10%'}} />}
                        {selectedColumns.includes("category") && <Column field="category" header={t('product.category')} body={categoryTemplate} sortable  />}
                        {selectedColumns.includes("sub_category") && <Column field="sub_category" header={t('product.sub_category')} body={subCategoryTemplate} sortable  />}
                        {selectedColumns.includes("type") && <Column field="type" header={t('product.type')} body={typeTemplate} sortable  />}
                        {selectedColumns.includes("brand") && <Column field="brand" header={t('product.brand')} body={brandTemplate} sortable  />}
                        {selectedColumns.includes("tags") && <Column field="tags" header={t('product.tags')} body={tagsTemplate} style={{textAlign:"center"}} sortable  />}
                        {selectedColumns.includes("properties.size") && <Column field="properties.size" header={t('product.properties.size')} body={getPropertyTemplate} sortable  />}
                        {selectedColumns.includes("properties.color") && <Column field="properties.color" header={t('product.properties.color')} body={getPropertyTemplate} sortable  />}
                        {selectedColumns.includes("properties.variation_number") && <Column field="properties.variation_number" header={t('product.properties.variation_number')} body={getPropertyTemplate} sortable  />}
                        {selectedColumns.includes("properties.package_type") && <Column field="properties.package_type" header={t('product.properties.package_type')} body={getPropertyTemplate} sortable  />}
                        {selectedColumns.includes("properties.presentation") && <Column field="properties.presentation" header={t('product.properties.presentation')} body={getPropertyTemplate} sortable  />}

                        {selectedColumns.includes("reviews") && <Column field="review.rating_count" header={t('product.reviews')} headerStyle={{textAlign:"center"}} body={reviewTemplate} sortable  />}
                        {selectedColumns.includes("rate") && <Column field="review.rating" header={t('product.rate')} headerStyle={{textAlign:"center"}} body={rateTemplate} sortable  />}
                        {selectedColumns.includes("review_number") && <Column field="review_number" header={t('product.review_number')} headerStyle={{textAlign:"center"}} body={reviewNumberTemplate} sortable  />}
                        {selectedColumns.includes("ref_id") && <Column field="ref_id" header={t('reprice.ref_id')} body={refIdTemplate} sortable  />}
                        {selectedColumns.includes("vat") && <Column field="vat" header={t('product.vat')} body={vatTemplate} sortable  />}
                        {isCampaignDropShipping(selectedCampaign) && selectedColumns.includes("drop_shipping_price") && <Column field="drop_shipping_price" header={t('product.drop_shipping_price')} headerStyle={{textAlign:"right"}} body={productDropShippingPriceTemplate} sortable  />}
                        {isCampaignDropShipping(selectedCampaign) &&  selectedColumns.includes("drop_shipping_cost") && <Column field="drop_shipping_cost" header={t('product.drop_shipping_cost')} headerStyle={{textAlign:"right"}} body={productDropShippingCostTemplate} sortable  />}
                        {isCampaignDropShipping(selectedCampaign) &&  selectedColumns.includes("drop_shipping_review") && <Column field="drop_shipping_review"  header={t('product.drop_shipping_review')} headerStyle={{textAlign:"center"}} body={productDropShippingReviewTemplate} sortable  />}
                        {isCampaignDropShipping(selectedCampaign) &&  selectedColumns.includes("drop_shipping_rate") && <Column field="drop_shipping_rate"  header={t('product.drop_shipping_rate')} headerStyle={{textAlign:"center"}} body={productDropShippingRateTemplate} sortable  />}
                        {isCampaignDropShipping(selectedCampaign) &&  selectedColumns.includes("drop_shipping_best_sellers_rank") && <Column field="drop_shipping_best_sellers_rank"  header={t('product.drop_shipping_best_sellers_rank')} headerStyle={{textAlign:"left"}} body={productDropShippingBestSellersRankTemplate} sortable  />}
                        {(isCampaignSeller(selectedCampaign) || isCampaignDropShipping(selectedCampaign)) && selectedColumns.includes("position") && <Column field="position" header={t('product.position')} body={positionTemplate} sortable  />}
                        {selectedColumns.includes("shelve_position") && <Column field="current_shelve_position" header={t('product.shelve_position')} body={shelvePositionTemplate} sortable  />}
                        {selectedColumns.includes("map") && <Column field="map" header={t('price_violation.map_abbreviation')} headerStyle={{textAlign:"right"}} body={productMAPTemplate} sortable  />}
                        {selectedColumns.includes("mrp") && <Column field="mrp" header={t('price_violation.mrp_abbreviation')} headerStyle={{textAlign:"right"}} body={productMRPTemplate} sortable  />}
                        {isCampaignManufacturer(selectedCampaign) && selectedColumns.includes("price") && <Column field="price" header={priceHeader} body={productPriceTemplate} headerStyle={{textAlign:"right"}} sortable  className="preventSelect" editor={violationStrategyEditor} onEditorSubmit={onViolationStrategyEditorSubmit} onEditorCancel={onViolationStrategyEditorCancel} />}
                        {(isCampaignSeller(selectedCampaign) || isCampaignDropShipping(selectedCampaign)) && selectedColumns.includes("price") && <Column field="price" header={t('product.price')} body={productPriceTemplate} headerStyle={{textAlign:"right"}} sortable />}
                        {(isCampaignSeller(selectedCampaign) || isCampaignDropShipping(selectedCampaign)) && selectedColumns.includes("pricing_strategy_min_value") && <Column field="pricing_strategy.min_value" header={protectionLimitHeader} headerStyle={{textAlign:"right"}} body={pricingStrategyMinValueTemplate} sortable  className="preventSelect" editor={pricingStrategyEditor} onEditorSubmit={onPricingStrategyEditorSubmit} onEditorCancel={onPricingStrategyEditorCancel} />}
                        {(isCampaignSeller(selectedCampaign) || isCampaignDropShipping(selectedCampaign)) && selectedColumns.includes("pricing_strategy_max_value") && <Column field="pricing_strategy.max_value" header={protectionLimitMaxHeader} headerStyle={{textAlign:"right"}} body={pricingStrategyMaxValueTemplate} sortable  className="preventSelect" editor={pricingStrategyMaxEditor} onEditorSubmit={onPricingStrategyMaxEditorSubmit} onEditorCancel={onPricingStrategyMaxEditorCancel}/>}
                        {(isCampaignSeller(selectedCampaign) || isCampaignDropShipping(selectedCampaign)) && selectedColumns.includes("target_price") && <Column field="target_price" header={t('product.target_price')} headerStyle={{textAlign:"right"}} body={productTargetPriceTemplate} sortable  />}
                        {(isCampaignSeller(selectedCampaign) || isCampaignDropShipping(selectedCampaign)) && selectedColumns.includes("suggested_price") && <Column field="suggested_price" header={suggestedPriceHeader} headerStyle={{textAlign:"right"}} body={productSuggestedPriceTemplate} sortable  />}


                        {selectedColumns.includes("discount_type") && <Column field="discount_types" header={t('product.discount_type')} body={productDiscountTypeTemplate} sortable  />}
                        {selectedColumns.includes("competitorsMinName") && <Column field="competitorsMinName" header={t('product.comp_min_name')} body={productCompMinNameTemplate} sortable  />}
                        {selectedColumns.includes("competitorsMinPrice") && <Column field="competitorsMinPrice" header={t('product.comp_min')} headerStyle={{textAlign:"right"}} body={productCompMinTemplate} sortable  />}
                        {selectedColumns.includes("competitorsMinShippingPrice") && <Column field="competitorsMinShippingPrice" header={t('product.comp_min_shipping_price')} headerStyle={{textAlign:"right"}} body={productCompMinShippingPriceTemplate} sortable  />}
                        {selectedColumns.includes("competitorsAvgPrice") && <Column field="competitorsAvgPrice" header={t('product.comp_avg')} headerStyle={{textAlign:"right"}} body={productCompAvgTemplate} sortable  />}
                        {selectedColumns.includes("competitorsAvgPriceDiff") && <Column field="competitorsAvgPriceDiff" header={t('product.comp_avg_diff')} headerStyle={{textAlign:"right"}} body={productCompAvgDiffTemplate} sortable  />}
                        {selectedColumns.includes("competitorsMaxName") && <Column field="competitorsMaxName" header={t('product.comp_max_name')} body={productCompMaxNameTemplate} sortable  />}
                        {selectedColumns.includes("competitorsMaxPrice") && <Column field="competitorsMaxPrice" header={t('product.comp_max')} headerStyle={{textAlign:"right"}} body={productCompMaxTemplate} sortable  />}
                        {selectedColumns.includes("competitorsMaxShippingPrice") && <Column field="competitorsMaxShippingPrice" header={t('product.comp_max_shipping_price')} headerStyle={{textAlign:"right"}} body={productCompMaxShippingPriceTemplate} sortable  />}
                        {selectedColumns.includes("competitorsCount") && <Column field="competitorsCount" header={t('product.comp_count')} headerStyle={{textAlign:"right"}} body={competitorsCountTemplate} sortable  />}
                        {selectedColumns.includes("url_count") && <Column field="url_count" header={t('product.url_count')} headerStyle={{textAlign:"right"}} body={urlCountTemplate} sortable  />}
                        {selectedColumns.includes("competitorSuggestionsCount") && <Column field="competitorSuggestionsCount" header={t('product.suggest_count')} headerStyle={{textAlign:"right"}} body={competitorSuggestionsCountTemplate} sortable  />}
                        {selectedColumns.includes("is_active") && <Column field="is_active" header={t('product.status')} headerStyle={{textAlign:"right", width:'7em'}} body={statusTemplate} />}
                        <Column body={actionTemplate} header={t('table_headers.action')} headerStyle={{textAlign:"center", width:'8em'}} className="preventSelect"/>
                    </DataTable>
                </div>

                <OverlayPanel ref={op} style={{width:"25em", height: '30em'}}>
                    <div className="p-grid">
                        <div className="p-col-10">
                        <ListBox value={selectedColumns}
                                 filterPlaceholder={t('product.fields_to_show')} fixedPlaceholder={true} options={productListColumns(t, selectedCampaign)} filter={true}
                                     multiple filter
                                     listStyle={{maxHeight:'24em'}}
                                     maxSelectedLabels={0}
                                     onChange={(e) => {
                                         localStorage.setItem("selectedColumns", JSON.stringify(e.value));
                                         setSelectedColumns(e.value);}} />
                        </div>
                        <div className="p-col-2">
                        <Button icon="pi pi-refresh" tooltip={t('actions.reset')}
                                tooltipOptions={{position: 'bottom'}} className="p-button-outlined p-button-help"
                                onClick={()=>{
                                    const selectedColumns = getSelectedProductListColumns(t, selectedCampaign, true);
                                    localStorage.setItem("selectedColumns", JSON.stringify(selectedColumns));
                                    setSelectedColumns(selectedColumns);
                                }} />
                        </div>
                    </div>
                </OverlayPanel>

                <Sidebar showCloseIcon={false} visible={showFilters === true} style={{width:'35em', overflow: "scroll"}} position="right" onHide={()=> {setShowFilters(false);}}>
                    <h3>{t('product.filter')}</h3>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <MultiSelect id="website_filter" name="website" value={filters.website} options={filterWebSites} onChange={onFilterChange} filter={true} maxSelectedLabels={2}/>
                                <label htmlFor="website">{t('product.website')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="name_filter" name="name" value={filters.name} onChange={onFilterChange} autoFocus={true}/>
                                <label htmlFor="name">{t('product.product_name')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="codes_filter" name="codes" value={filters.codes} onChange={onFilterChange}/>
                                <label htmlFor="codes">{t('product.codes')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <MultiSelect name="category" value={filters.category} options={filterCategories} onChange={onFilterChange} filter={true} maxSelectedLabels={2}/>
                                <label htmlFor="category">{t('product.category')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <MultiSelect name="brand" value={filters.brand} options={filterBrands} onChange={onFilterChange} filter={true} maxSelectedLabels={2}/>
                                <label htmlFor="brand">{t('product.brand')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <MultiSelect name="tags" value={filters.tags} options={filterTags} onChange={onFilterChange} filter={true} maxSelectedLabels={2}/>
                                <label htmlFor="tags">{t('product.tags')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <MultiSelect name="discount_types" value={filters.discount_types} options={filterDiscountTypes} onChange={onFilterChange} filter={true} maxSelectedLabels={2}/>
                                <label htmlFor="discount_types">{t('product.discount_type')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <MultiSelect name="myprice" value={filters.myprice} options={myprice} onChange={onFilterChange} filter={true} />
                                <label htmlFor="myprice">{t('product.myprice')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <SelectButton value={filters.mystock} optionLabel="label" options={mystock}
                                          onChange={(e) => {onFilterChange({target:{name:"mystock", value:e.value}})}}/>
                        </div>
                        <div className="p-col-12">
                            <SelectButton value={filters.reprice} optionLabel="label" options={reprice}
                                          onChange={(e) => {onFilterChange({target:{name:"reprice", value:e.value}})}}/>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <Calendar name="created_at_start" value={filters.created_at_start} onChange={onFilterChange} showButtonBar={true}  />
                                <label htmlFor="created_at_start">{t('product.created_at_start')}</label>
                            </span>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <Calendar name="created_at_end" value={filters.created_at_end} onChange={onFilterChange} showButtonBar={true} />
                                <label htmlFor="created_at_end">{t('product.created_at_end')}</label>
                            </span>
                        </div>
                        {isCampaignDropShipping(selectedCampaign) &&
                        <div className="p-col-12">
                            <SelectButton value={filters.drop_shipping_loss} optionLabel="label" options={isDropShippingLoss}
                                          onChange={(e) => {onFilterChange({target:{name:"drop_shipping_loss", value:e.value}})}}/>
                        </div>
                        }
                        {isCampaignDropShipping(selectedCampaign) &&
                        <div className="p-col-12">
                            <SelectButton value={filters.drop_shipping_supplied} optionLabel="label" options={isDropShippingSupplied}
                                          onChange={(e) => {onFilterChange({target:{name:"drop_shipping_supplied", value:e.value}})}}/>
                        </div>
                        }
                        <div className="p-col-12">
                            <SelectButton value={filters.hasCompetitor} optionLabel="label" options={hasCompetitors}
                                          onChange={(e) => {onFilterChange({target:{name:"hasCompetitor", value:e.value}})}}/>
                        </div>
                        <div className="p-col-12">
                            <SelectButton value={filters.variant_matching_needed} optionLabel="label" options={isVariantMatchingNeeded}
                                          onChange={(e) => {onFilterChange({target:{name:"variant_matching_needed", value:e.value}})}}/>
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText id="cheapestCompetitor" name="cheapestCompetitor" value={filters.cheapestCompetitor} onChange={onFilterChange}/>
                                <label htmlFor="cheapestCompetitor">{t('product.cheapestCompetitor')}</label>
                            </span>
                        </div>
                    </div>
                </Sidebar>

                <Sidebar showCloseIcon={false} visible={page === pages.add} style={{width:'25em', overflow: "scroll"}} position="right" onHide={cancel}>
                    <ProductAdd cancel={cancel}  add={add} />
                </Sidebar>

                <Sidebar showCloseIcon={false} visible={page === pages.edit} style={{width:'25em', overflow: "scroll"}} position="right" onHide={cancel}>
                    <ProductEdit selectedProduct={selectedProduct}
                                 categories={categories}
                                 brands={brands}
                                 cancel={cancel} update={update} />
                </Sidebar>

            </div>

            {page === pages.detail &&
                <ProductDetail product_id={selectedProductId} onDetailComplete={detailComplete} goToPrevious={goToPrevious} goToNext={goToNext} />
            }


            {showBulkOperations &&
                <ProductBulkOperations filteredProducts={filteredProducts} refreshProducts={()=> {
                    getNotDoneProductScrapingRequests();
                    refresh(0);}}
                                       sortField={dtProducts.current && dtProducts.current.state ? dtProducts.current.state.sortField : ""}
                                       sortOrder={dtProducts.current && dtProducts.current.state ? dtProducts.current.state.sortOrder : "1"}
                                       hideDialog={() => {setShowBulkOperations(false);}} />}

            {showMergePanel && productsToMerge.length > 0 &&
                <Sidebar visible={true} position="bottom" baseZIndex={100000}
                         style={{height:'20em', overflow: "scroll"}}
                         onHide={() => setShowMergePanel(false)}>
                    <ProductMerge productsToMerge={productsToMerge}
                                  removeFromMerge={(p)=>{
                                      setProductsToMerge(productsToMerge.filter(product => product.id !== p.id));
                                  }}
                                  setAsInitial={(p)=>{
                                      productsToMerge.forEach(product=>{
                                          product.keepAsMine = product.id === p.id;
                                      });
                                      setProductsToMerge([...productsToMerge]);
                                  }}
                                  cancel={() => {
                                      setShowMergePanel(false);
                                      setProductsToMerge([]);
                                  }}
                                  merge={() => {
                                      confirmDialog({
                                          message: t('dialogs.product.merge_msg'),
                                          header: t('dialogs.product.merge'),
                                          icon: 'pi pi-exclamation-triangle',
                                          acceptClassName: 'p-button-danger',
                                          accept: () => mergeProducts(),
                                          acceptLabel: t('yes_no.yes'),
                                          rejectLabel: t('yes_no.no')
                                      });
                                  }}
                    />
                </Sidebar>
            }



        </React.Fragment>
}
export default Product;
