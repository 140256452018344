export const asyncSessionStorage = {
    setItem: async function (key, value) {
        await null;
        return sessionStorage.setItem(key, value);
    },
    getItem: async function (key) {
        await null;
        return sessionStorage.getItem(key);
    },
    remove: async function (key) {
        await null;
        return sessionStorage.removeItem(key);
    },
};


export const asyncLocalStorage = {
    setItem: async function (key, value) {
        await null;
        return localStorage.setItem(key, value);
    },
    getItem: async function (key) {
        await null;
        return localStorage.getItem(key);
    },
    remove: async function (key) {
        await null;
        return localStorage.removeItem(key);
    },
};