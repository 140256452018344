import React, {useEffect} from 'react';
import {AuthService} from "../service/AuthService";
import {isAdmin, toTitleCase} from "./Utils";
import {AppContext} from "../HomePage";
import {DynoService} from "../service/DynoService";


const ErrorHandler = (props) => {

    const dynoService = new DynoService()

    const {t, toast, setError} = React.useContext(AppContext);

    useEffect ( ()=>{
        if (props.error)
            handleError(props.error);
    }, [props.error]);

    const blobToData = (blob) => {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsText(blob)
        })
    }

    const handleError = async (error) =>
    {
        let summary = "", detail = "", more_detail = "";
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */

            if(error.response.status === 401){
                AuthService.logout();
                window.location = '#/login?expire=1';
                return;
            }
            summary = error.response.status ? t('api.summary.'+error.response.status) : "";
            if (error.response.data instanceof Blob) {
                const data = JSON.parse(await blobToData(error.response.data));
                detail = data.error_key ? t(data.error_key) : data.error ?? data.detail ?? error.response.status ? t('api.detail.'+error.response.status) : "";
            }
            else
                detail = error.response.data.error_key ? t(error.response.data.error_key) : error.response.data.error ?? error.response.data.detail ?? error.response.status ? t('api.detail.'+error.response.status) : "";
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */

            summary = t('api.summary.408');
            detail = t('api.detail.408');
            // dynoService.restartDynos().then(() => {
            // }).catch(error =>{
            //     setError(error);
            //     setError(null);
            // });
        } else {
            // Something happened in setting up the request and triggered an Error
            summary = t('api.summary.500');
            detail = t('api.detail.500');
        }
        more_detail = error

        summary = summary ? toTitleCase(summary) : "";
        detail = detail  ? toTitleCase(detail) : "";

        toast.current.show({severity: "error", summary: summary, detail: detail + (isAdmin() ? more_detail : "")});
    };

    return "";
};
export default ErrorHandler;
